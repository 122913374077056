import React, { useMemo, useRef, useEffect, MouseEvent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { getMinutesFromHour, getStartDateTime } from '../utils';
import { ExtendedAppointment } from 'pages/Appointments';
import { ISettings } from '../interfaces/ISettings';
import { IEventLabelColors } from '../interfaces/IEventLabelColors';
import { PositionedAppointment } from '../interfaces/PositionedAppointment';
import { EventTypes } from '../enums/EventTypes';
import { Mixpanel } from 'shared/utils/mixpanel';

const timeFormat = 'h:mm a';

interface IHourlyAppointmentsProps {
  appointments: ExtendedAppointment[];
  scheduleTimeBlockInMinutes: number;
  selectedAppointmentId: string | null;
  onAppointmentClicked: (e: MouseEvent, appointment: ExtendedAppointment) => void;
  onDayClicked: (e: MouseEvent, startDate: Date, durationInMinutes: number) => void;
  currentDay: Date;
  settings: ISettings;
}

const HourlyAppointments = ({
  appointments,
  scheduleTimeBlockInMinutes,
  selectedAppointmentId,
  onAppointmentClicked,
  onDayClicked,
  currentDay,
  settings,
}: IHourlyAppointmentsProps): JSX.Element => {
  const canvasRef = useRef<HTMLDivElement | null>(null);
  const currentTimeRef = useRef<HTMLDivElement | null>(null);
  const selectedAppointmentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (selectedAppointmentRef && selectedAppointmentRef?.current) {
      selectedAppointmentRef.current?.scrollIntoView();
    }
  }, [selectedAppointmentId, selectedAppointmentRef]);

  const positionedAppointments = useMemo(() => {
    const positionedList = appointments.map((appointment: ExtendedAppointment): PositionedAppointment => {
      return {
        ...appointment,
        height: appointment.durationInMinutes,
        width: 95,
        top: getMinutesFromHour(parseISO(appointment.startAt)),
        left: 0,
      };
    });

    const reduced = positionedList.reduce(
      (
        accumulator: PositionedAppointment[],
        currentValue: PositionedAppointment,
        currentIndex: number,
        array: PositionedAppointment[],
      ) => {
        // find collision items total in original array to calculate width
        const originalCollisionItems = array.filter((positionedItem) => {
          return (
            (positionedItem.top >= currentValue.top && positionedItem.top < currentValue.top + currentValue.height) ||
            (currentValue.top >= positionedItem.top && currentValue.top < positionedItem.top + positionedItem.height)
          );
        });

        // find collision items in accumulator array to determine left position
        const accumulatorCollisionItems = accumulator.filter((positionedItem) => {
          return (
            (positionedItem.top >= currentValue.top && positionedItem.top < currentValue.top + currentValue.height) ||
            (currentValue.top >= positionedItem.top && currentValue.top < positionedItem.top + positionedItem.height)
          );
        });

        let left = 0;
        // Left position is alway 0 unless there are collisions
        if (accumulatorCollisionItems.length > 0) {
          left =
            accumulatorCollisionItems[accumulatorCollisionItems.length - 1].left +
            currentValue.width / originalCollisionItems.length;
        }

        return [
          ...accumulator,
          {
            ...currentValue,
            width:
              originalCollisionItems.length > 0
                ? currentValue.width / originalCollisionItems.length
                : currentValue.width,
            left,
          },
        ];
      },
      [],
    );

    // Sort by top so that the scroll position is correct
    return reduced.sort((a, b) => a.top - b.top);
  }, [appointments]);

  useEffect(() => {
    if (!selectedAppointmentId) {
      setTimeout(() => {
        currentTimeRef.current?.scrollIntoView();
      }, 500);
    }
  }, [positionedAppointments, selectedAppointmentId]);

  const onClickCanvas = (e: MouseEvent): void => {
    e.stopPropagation();
    const canvas = canvasRef.current?.getBoundingClientRect();
    if (canvas) {
      const startAt = getStartDateTime(currentDay, e.clientY - canvas?.y, scheduleTimeBlockInMinutes);
      onDayClicked(e, startAt, scheduleTimeBlockInMinutes);
      Mixpanel.track('New appointment from side panel canvas double');
    }
  };

  return (
    <AppointmentsContainer onClick={onClickCanvas} ref={canvasRef}>
      <AppointmentsContent>
        <Appointments>
          <CurrentTimeScrollTo key="currentTime" ref={currentTimeRef} top={getMinutesFromHour(new Date())} />
          {positionedAppointments.map((appointment: PositionedAppointment, index: number) => {
            return (
              <Appointment
                key={`${appointment.startAt.toString()}-${index}`}
                className={selectedAppointmentId === appointment.id ? 'selected' : ''}
                ref={selectedAppointmentId === appointment.id ? selectedAppointmentRef : null}
                appointment={appointment}
                index={index}
                eventType={EventTypes.Curbside}
                labelColorSettings={settings.eventLabelColors}
                onClick={(e: MouseEvent): void => {
                  e.stopPropagation();
                  onAppointmentClicked(e, appointment);
                }}
                data-mixpanel-name="Existing appointment on side panel"
                data-testid="existing-appointment-item"
              >
                <AppointmentContent>
                  <Title>{appointment.clinicPet?.name}</Title>
                  <Time> {format(parseISO(appointment.startAt), timeFormat)} </Time>
                </AppointmentContent>
              </Appointment>
            );
          })}
        </Appointments>
      </AppointmentsContent>
    </AppointmentsContainer>
  );
};

HourlyAppointments.propTypes = {
  appointments: PropTypes.array.isRequired,
  scheduleTimeBlockInMinutes: PropTypes.number.isRequired,
  onAppointmentClicked: PropTypes.func.isRequired,
  onDayClicked: PropTypes.func.isRequired,
  currentDay: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
};

export default HourlyAppointments;

interface ICurrentTimeScrollTo {
  top: number;
}

const CurrentTimeScrollTo = styled.div<ICurrentTimeScrollTo>`
  display: hidden;
  position: absolute;
  top: ${({ top }: ICurrentTimeScrollTo): number => top}px;
  width: 100%;
`;

const AppointmentsContainer = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
`;

const AppointmentsContent = styled.div`
  border-right: white 1px solid;
  box-sizing: border-box;
  flex: 1 0 auto;
  min-width: 129px;
  outline: none;
  overflow: visible;
  padding-right: 12px;
  z-index: 6;
`;

const Appointments = styled.div`
  height: 100%;
  width: 100%;
`;

interface IAppointmentProps {
  eventType: EventTypes;
  labelColorSettings: IEventLabelColors;
  appointment: PositionedAppointment;
  index: number;
}

const Appointment = styled.div<IAppointmentProps>`
  align-items: center;
  background-color: #eaf1fe;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  outline: none;
  position: absolute;
  z-index: 5;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  top: ${({ appointment }: IAppointmentProps): number => appointment.top}px;
  left: ${({ appointment }: IAppointmentProps): string => `${appointment.left}%`};
  width: ${({ appointment }: IAppointmentProps): string => `${appointment.width}%`};
  height: ${({ appointment }: IAppointmentProps): number => appointment.height}px;

  &.selected {
    border-left: 2px solid #72abff;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.35);
    left: 0;
    width: 95%;
    z-index: 6;
  }

  &:hover {
    border-left: 2px solid #72abff;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.35);
    z-index: 6;
  }

  &:after {
    -webkit-border-radius: 5px;
    -webkit-box-sizing: border-box;
    border-radius: 5px;
    box-sizing: border-box;
    content: '';
    height: -webkit-calc(100% + 2px);
    height: calc(100% + 2px);
    left: -1px;
    pointer-events: none;
    position: absolute;
    top: -1px;
    width: -webkit-calc(100% + 2px);
    width: calc(100% + 2px);
  }
`;

const AppointmentContent = styled.div`
  font-size: 14px;
  max-height: 100%;
  overflow: hidden;
  padding-left: 8px;
  text-align: left;
  user-select: none;
`;

const Title = styled.div`
  align-items: center;
  color: var(--chakra-colors-text-default);
  display: flex;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 15px;
  max-height: 15px;
  max-width: 100%;
  overflow: hidden;
  padding-top: 4px;
  text-align: left;
  user-select: none;
  white-space: nowrap;
  word-wrap: break-word;
`;

const Time = styled.div`
  align-items: center;
  color: var(--chakra-colors-text-default);
  display: flex;
  font-size: 12px;
  letter-spacing: 0.1px;
  line-height: 15px;
  max-width: 100%;
  overflow: hidden;
  text-align: left;
  user-select: none;
  white-space: nowrap;
`;
