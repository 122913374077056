import React, { useMemo } from 'react';
import { format, parseISO } from 'date-fns';
import { SmsNotificationStatusType, SmsNotificationStatusFragment } from 'shared/types/graphql';
import { formatPhoneNumber } from 'shared/utils';
import { getSmsNotificationMessageFromErrorType } from '../../../utils';
import { INotificationErrorTextProps } from '../../ChannelView';
import { HStack, Icon, Tooltip, Text } from '@televet/kibble-ui';

interface ISmsNotificationStatusProps {
  smsNotificationStatus: SmsNotificationStatusFragment | undefined;
  isLoading: boolean;
  phoneWarnings: INotificationErrorTextProps[];
}

const SmsNotificationStatusIndicator = ({
  smsNotificationStatus,
  isLoading,
  phoneWarnings,
}: ISmsNotificationStatusProps): JSX.Element => {
  let tooltipContent = '';

  const messageStatusLiteral = useMemo((): string => {
    if (phoneWarnings.length) {
      return 'Error';
    }
    if (!smsNotificationStatus) {
      return 'Sending';
    }
    switch (smsNotificationStatus.status) {
      case SmsNotificationStatusType.Delivered:
        return 'Delivered';
      case SmsNotificationStatusType.Error:
        return 'Error';
      case SmsNotificationStatusType.SendError:
        return 'Error';
      case SmsNotificationStatusType.Sent:
        return 'Sent';
      default:
        return 'Sending';
    }
  }, [smsNotificationStatus, phoneWarnings]);

  if (smsNotificationStatus) {
    const { status, receivingNumber, errorType, provider } = smsNotificationStatus;

    const isErrored = status === SmsNotificationStatusType.Error || status === SmsNotificationStatusType.SendError;

    if (isErrored) {
      tooltipContent += 'Error sending message';
    } else {
      tooltipContent += `Message ${status.toLocaleLowerCase()}`;
    }

    tooltipContent += smsNotificationStatus
      ? ` to ${formatPhoneNumber(receivingNumber)} on ${format(
          parseISO(smsNotificationStatus?.updatedAt),
          "cccc',' PP 'at' p",
        )}`
      : '';

    if (isErrored && errorType) {
      tooltipContent += ` for the following reason:\n\n${getSmsNotificationMessageFromErrorType(errorType, provider)}`;
    }
  }

  const shouldShowMessage = !isLoading || !!phoneWarnings.length || !!smsNotificationStatus;

  return (
    <Tooltip label={tooltipContent} isDisabled={!smsNotificationStatus}>
      <HStack spacing={1}>
        <Icon size="xs" variant="subtle" name="cellphone" />
        <Text size="xs" variant="subtle">
          {shouldShowMessage ? messageStatusLiteral : ''}
        </Text>
      </HStack>
    </Tooltip>
  );
};

export default SmsNotificationStatusIndicator;
