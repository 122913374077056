import { GraphQLFetchPolicies } from 'shared/enums';
import {
  SmsNotificationStatusType,
  SmsNotificationStatusFragment,
  SubscribeToSmsNotificationStatusDocument,
  SubscribeToSmsNotificationStatusSubscription,
  SubscribeToSmsNotificationStatusSubscriptionVariables,
  useGetSmsNotificationStatusQuery,
} from 'shared/types/graphql';

interface IUseSmsNotificationStatusProps {
  messageId: string;
  skip?: boolean;
}

const useSmsNotificationStatus = (
  props: IUseSmsNotificationStatusProps,
): {
  smsNotificationStatus: SmsNotificationStatusFragment | undefined;
  isLoading: boolean;
} => {
  const { messageId, skip } = props;

  const {
    data: smsNotificationStatusData,
    loading: isLoading,
    subscribeToMore: subscribeToSmsNotificationStatuses,
  } = useGetSmsNotificationStatusQuery({
    variables: { where: { sentChannelMessageId: { equals: messageId } } },
    skip,
    fetchPolicy: GraphQLFetchPolicies.CacheAndNetwork,
    onCompleted: ({ findFirstSmsNotificationStatus }) => {
      const status = findFirstSmsNotificationStatus?.status;

      // Subscribe to updates if the SMS message hasn't been delivered
      if (status !== SmsNotificationStatusType.Delivered) {
        subscribeToSmsNotificationStatuses<
          SubscribeToSmsNotificationStatusSubscription,
          SubscribeToSmsNotificationStatusSubscriptionVariables
        >({
          document: SubscribeToSmsNotificationStatusDocument,
          variables: { where: { node: { sentChannelMessageId: { equals: messageId } } } },
          updateQuery: (prev, { subscriptionData }) => {
            const updatedSmsNotificationStatus = subscriptionData?.data?.smsNotificationStatusChanged;
            if (!updatedSmsNotificationStatus) {
              return prev;
            }
            return { findFirstSmsNotificationStatus: updatedSmsNotificationStatus.node };
          },
        });
      }
    },
  });

  const smsNotificationStatus = smsNotificationStatusData?.findFirstSmsNotificationStatus || undefined;

  return {
    smsNotificationStatus,
    isLoading,
  };
};

export default useSmsNotificationStatus;
