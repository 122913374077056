import { AutomationRunActionPromptType, ConditionAttribute } from 'shared/types/graphql';
import { AutomationEditorView } from './AutomationEditorView';

type NameError = 'nameEmpty';
type TypeError = 'typeEmpty';
type SendConditionContainerError = 'sendConditionsEmpty';
type SendConditionError =
  | 'appointmentTypesEmpty'
  | 'appointmentProvidersEmpty'
  | 'appointmentRoomsEmpty'
  | 'patientSpeciesEmpty'
  | 'patientAgeEmpty';
type FormError = 'formsEmpty';
type RequestTypeError = 'requestsEmpty';
type NotificationMessageError = 'notificationMessageEmpty';
type PromptContainerError = 'promptsEmpty';
export type PromptQuestionOrMessageError = 'questionOrMessageEmpty';
export type PromptNextStepError =
  | 'nextPromptMissing'
  | 'unreachablePrompts'
  | 'automationDeleted'
  | 'statementUnreachablePrompts';
export type PromptFollowupStepError = PromptNextStepError;
export type PromptInvoiceError = 'invoiceEmpty' | 'invoiceAmount';
export type PromptFormError = 'formEmpty' | 'formDeleted';
export type PromptButtonContainerError = 'buttonsEmpty';
type PromptButtonTextError = 'nextPromptMissing' | 'unreachablePrompts' | 'buttonTextEmpty';

export type ErrorType =
  | NameError
  | TypeError
  | SendConditionContainerError
  | SendConditionError
  | FormError
  | RequestTypeError
  | NotificationMessageError
  | PromptContainerError
  | PromptQuestionOrMessageError
  | PromptNextStepError
  | PromptInvoiceError
  | PromptButtonContainerError
  | PromptFormError
  | PromptButtonTextError;

export enum ValidationActionWord {
  Saving = 'save',
  Activating = 'activate',
}

export interface ButtonErrorDetail {
  nextStep: PromptNextStepError[];
  followupStep: PromptNextStepError[];
  buttonText: PromptButtonTextError[];
  buttonIndex: number;
}

export interface PromptErrorDetail {
  promptIndex: number;
  promptType: AutomationRunActionPromptType;
  questionOrMessage: PromptQuestionOrMessageError[];
  nextStep: PromptNextStepError[];
  followupStep: PromptFollowupStepError[];
  invoice: PromptInvoiceError[];
  form: PromptFormError[];
  buttonContainer: PromptButtonContainerError[];
  buttons: ButtonErrorDetail[];
}

export interface SendConditionErrorDetail {
  [ConditionAttribute.AppointmentTypeId]: SendConditionError[];
  [ConditionAttribute.ClinicEmployeeId]: SendConditionError[];
  [ConditionAttribute.ClinicRoomId]: SendConditionError[];
  [ConditionAttribute.SpeciesType]: SendConditionError[];
  [ConditionAttribute.PetAgeInYears]: SendConditionError[];
}

export interface Errors {
  automationName: NameError[];
  automationType: TypeError[];
  sendConditionContainer: SendConditionContainerError[];
  sendConditions: SendConditionErrorDetail;
  forms: FormError[];
  requestTypes: RequestTypeError[];
  notificationMessage: NotificationMessageError[];
  promptContainer: PromptContainerError[];
  prompts: PromptErrorDetail[];
}

export const initialErrorsState: Errors = {
  automationName: [],
  automationType: [],
  sendConditionContainer: [],
  sendConditions: {
    [ConditionAttribute.AppointmentTypeId]: [],
    [ConditionAttribute.ClinicEmployeeId]: [],
    [ConditionAttribute.ClinicRoomId]: [],
    [ConditionAttribute.SpeciesType]: [],
    [ConditionAttribute.PetAgeInYears]: [],
  },
  forms: [],
  requestTypes: [],
  notificationMessage: [],
  promptContainer: [],
  prompts: [],
};

type ErrorDetail = {
  errorMessage: string;
  location: AutomationEditorView;
  formattedFieldName?: string;
};

export const errorDetails: Record<ErrorType, ErrorDetail> = {
  nameEmpty: {
    errorMessage: 'Please enter an automation name',
    formattedFieldName: 'Automation Name',
    location: AutomationEditorView.Settings,
  },
  typeEmpty: {
    errorMessage: 'Please select an automation type',
    formattedFieldName: 'Automation Type',
    location: AutomationEditorView.Settings,
  },
  sendConditionsEmpty: {
    errorMessage: 'Please add at least one condition',
    formattedFieldName: 'Send Conditions',
    location: AutomationEditorView.Settings,
  },
  appointmentProvidersEmpty: {
    errorMessage: 'Please add at least one appointment provider or remove this condition',
    formattedFieldName: 'Send Condition',
    location: AutomationEditorView.Settings,
  },
  appointmentRoomsEmpty: {
    errorMessage: 'Please add at least one appointment room or remove this condition',
    formattedFieldName: 'Send Condition',
    location: AutomationEditorView.Settings,
  },
  appointmentTypesEmpty: {
    errorMessage: 'Please add at least one appointment type or remove this condition',
    formattedFieldName: 'Send Condition',
    location: AutomationEditorView.Settings,
  },
  patientSpeciesEmpty: {
    errorMessage: 'Please add at least one patient species or remove this condition',
    formattedFieldName: 'Send Condition',
    location: AutomationEditorView.Settings,
  },
  patientAgeEmpty: {
    errorMessage: 'Please add a patient age greater than 0 or remove this condition',
    formattedFieldName: 'Send Condition',
    location: AutomationEditorView.Settings,
  },
  formsEmpty: {
    errorMessage: 'Please add at least one form',
    formattedFieldName: 'Forms',
    location: AutomationEditorView.Settings,
  },
  requestsEmpty: {
    errorMessage: 'Please add at least one request option',
    formattedFieldName: 'Request Types',
    location: AutomationEditorView.Settings,
  },
  notificationMessageEmpty: {
    errorMessage: 'Please enter a notification message',
    formattedFieldName: 'Notification Message',
    location: AutomationEditorView.Compose,
  },
  promptsEmpty: {
    errorMessage: 'Please add at least one prompt',
    formattedFieldName: 'Prompts',
    location: AutomationEditorView.Compose,
  },
  questionOrMessageEmpty: {
    errorMessage: 'Please enter a question or message',
    location: AutomationEditorView.Compose,
  },
  nextPromptMissing: {
    errorMessage: 'Next prompt is missing',
    location: AutomationEditorView.Compose,
  },
  statementUnreachablePrompts: {
    errorMessage: 'Prompts exist after this that are inaccessible; please remove all prompts following this one',
    location: AutomationEditorView.Compose,
  },
  unreachablePrompts: {
    errorMessage:
      'Prompts exist after this that are inaccessible; please update this field or remove all prompts following this one',
    location: AutomationEditorView.Compose,
  },
  invoiceEmpty: {
    errorMessage: 'Please specifiy an amount for this invoice',
    location: AutomationEditorView.Compose,
  },
  invoiceAmount: {
    errorMessage: 'Invoice amount must be greater or equal to $0.50',
    location: AutomationEditorView.Compose,
  },
  buttonTextEmpty: {
    errorMessage: 'Please enter button text',
    location: AutomationEditorView.Compose,
  },
  buttonsEmpty: {
    errorMessage: 'Please add at least one button',
    location: AutomationEditorView.Compose,
  },
  formEmpty: {
    errorMessage: 'Please select a form',
    location: AutomationEditorView.Compose,
  },
  automationDeleted: {
    errorMessage: 'This automation no longer exists',
    location: AutomationEditorView.Compose,
  },
  formDeleted: {
    errorMessage: 'This form no longer exists',
    location: AutomationEditorView.Compose,
  },
};

export const DELETED = 'deleted';
