import React, { useState, useCallback, useMemo, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { Tooltip } from '@televet/kibble-ui/build/components/Tooltip';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { Menu, MenuItemProps } from '@televet/kibble-ui/build/components/Menu';
import { Alert } from '@televet/kibble-ui/build/components/Alert';
import { NotificationCounter } from '@televet/kibble-ui/build/components/NotificationCounter';
import { Tag } from '@televet/kibble-ui/build/components/Tag';
import { Spinner } from '@televet/kibble-ui/build/components/Spinner';
import { useToast } from '@televet/kibble-ui/build/components/Toast';
import { useDisclosure, Box, HStack, VStack, Flex, Collapse } from '@televet/kibble-ui/build/chakra';
import styled from 'styled-components/macro';
import useClinicUser from 'shared/hooks/useClinicUser';
import { GET_PET_PARENT_SIDE_PANEL_CHANNELS, getClinicChannels } from 'pages/Conversations/graphql/queries';
import { useLazyQuery } from '@apollo/client';
import { VideoCallIcon, CallIcon, ChatIcon, PencilIcon, CreditCardIcon } from 'assets/icons';
import PetParentAvatar from 'shared/components/Avatars/PetParentAvatar';
import ClinicPetParentNamePopover from 'shared/components/ClinicPetParentNamePopover';
import {
  useTriggerMessageEventMutation,
  useJobChainCompletedSubscription,
  SqsQueueNames,
  PhoneNumberLookupRequestStatus,
  PermissionType,
} from 'shared/types/graphql';
import { useHistory } from 'react-router-dom';
import { usePopover } from 'shared/providers/PopoverWindowProvider';
import { PopoverNames } from 'shared/enums/PopoverNames';
import useUnreadMessageCount, { getChannelUnreadMessageCount } from 'shared/hooks/useUnreadMessageCount';
import {
  cleanPhoneNumber,
  formatPhoneNumber,
  getClinicPetParentDisplayName,
  selectPrimaryPhoneNumberBestGuess,
} from 'shared/utils';
import { useSidePanel } from 'shared/components/SidePanel/state/providers/SidePanelProvider';
import { Mixpanel } from 'shared/utils/mixpanel';
import Popover, { PopoverPlacement } from 'shared/components/Popover';
import ClientPhoneNumberPopover from 'shared/components/ClientPhoneNumberPopover';
import { GraphQLErrorPolicies } from 'shared/enums/GraphQLErrorPolicies';
import { GraphQLFetchPolicies } from 'shared/enums/GraphQLFetchPolicies';
import { useSidePanelSearch } from '../../state/providers/SearchPanelProvider';
import { replaceParam, RouteDefinitions } from 'routes';
import {
  Maybe,
  PaymentMedium,
  ClinicEntityCreationSource,
  Channel,
  ChannelStatusAction,
  SortOrder,
  useGetDirectedCareLoginLinkMutation,
  useGetOrCreateActiveClientChannelMutation,
  useGetSidePanelPetParentProfileQuery,
  LoyaltyProgramStatus,
  useGetLoyaltyProgramBasicInfoQuery,
} from 'shared/types/graphql';
import { SidePanelContactInfoSync } from './components/SidePanelContactInfoSync';
import { PanelViews } from 'shared/components/SidePanel/shared/types/enums/PanelViews';
import { Mode } from '../../shared/types/enum/Mode';
import SidePanelContactOptions from './components/SidePanelContactOptions';
import { useResolutionProvider } from 'shared/providers/ResolutionProvider';
import ClientEmailPopover from 'shared/components/ClientEmailPopover';
import checkBrowserSupport, {
  getTooltipMessage,
} from 'shared/components/UnsupportedBrowserPopover/checkBrowserSupport';
import { CallTypes } from 'shared/enums/CallTypes';
import { InvoiceStatus, useStripeTerminalProvider } from 'shared/providers/StripeTerminalProvider';
import DevModeDisplayJSON from 'pages/Settings/components/DevModeDisplayJSON';
import SidePanelConversationsSection from './components/SidePanelConversationsSection';
import { IPopover } from 'shared/providers/PopoverWindowProvider/interfaces/IPopover';
import { useGetParentPets } from '../../shared/hooks/useGetParentPets';
import HasFeature from 'shared/components/HasFeature';
import { FeatureFlagName } from 'shared/enums/FeatureFlagName';
import useFeatureFlag from 'shared/hooks/useFeatureFlag';
import { SidePanelLoyaltyProgram } from '../LoyaltyProgram';

import MergeProfilesModal from 'shared/components/MergeProfiles/components/MergeProfilesModal';
import { getSidePanelMergeDisabledReason, MergeDisabledReason } from 'shared/components/MergeProfiles/utils';
import { MessageEvents } from 'shared/enums/MessageEvents';
import { useIntegrationsProvider } from 'shared/providers/IntegrationsProvider';
import { PetsList } from './components/PetsList';
import useGA from 'shared/hooks/useGA';
import { GA4Events } from 'shared/enums/GA4Events';
import TrupanionProfileCheck from 'shared/components/Trupanion/TrupanionProfileCheck';
import { LoginType } from '@televet/shared-types/JsonTypes/LoginType';

interface MoreMenuOption {
  value: string;
  label: string;
  show: boolean;
  isDisabled?: boolean;
}

interface ISidePanelPetParentProfileProps {
  clinicPetParentId: string | null;
  goToPetParent: (id: string) => void;
  addUserToChannel: (petParentId: string) => Promise<void>;
  isValidChannel?: boolean;
}

const IconCallToActionComponent = ({
  actionText,
  dataTestId,
}: {
  actionText: string;
  dataTestId?: string;
}): JSX.Element => {
  return (
    <Text as="p" mt={1} size="xs" data-testid={dataTestId}>
      {actionText}
    </Text>
  );
};

const SidePanelPetParentProfile = ({
  clinicPetParentId,
  goToPetParent,
  addUserToChannel,
  isValidChannel = false,
}: ISidePanelPetParentProfileProps): JSX.Element | null => {
  const { gaTrack } = useGA();
  const history = useHistory();
  const { openPopover, popover } = usePopover();
  const toast = useToast();
  const { clinicUser, currentClinicId, isOttoTeamMember, userHasPermission } = useClinicUser();
  const { allIntegrations, primaryIntegrationName, isContactSyncSupported, isMergeExistingPimsProfileSupported } =
    useIntegrationsProvider();
  const hasIntegrations = !!allIntegrations?.length;
  const { isFeatureEnabled } = useFeatureFlag();
  const mergeProfilesDisclosure = useDisclosure();
  const [triggerMessageEvent, { data: messageEventData }] = useTriggerMessageEventMutation();
  const { isClientIdSupported } = useIntegrationsProvider();
  const [jobChainIdFromResponse, setJobChainIdFromResponse] = useState('');

  const { data: useJobChainCompletedData } = useJobChainCompletedSubscription({
    variables: { jobChainId: messageEventData?.triggerMessageEvent?.jobChainId || '' },
  });

  const { viewClinicPetParent, searchPanelMode, setSearchPanelMode, currentClinicPetId, setCurrentClinicPetId } =
    useSidePanelSearch();

  const { data: loyaltyProgramData } = useGetLoyaltyProgramBasicInfoQuery({
    variables: {
      where: {
        participatingClinics: {
          some: {
            id: {
              equals: currentClinicId,
            },
          },
        },
        status: {
          notIn: [LoyaltyProgramStatus.Archived, LoyaltyProgramStatus.Draft],
        },
      },
    },
    skip: !currentClinicId,
  });

  const { data: clinicPetParentData, refetch: refetchGetSidePanelPetParentProfile } =
    useGetSidePanelPetParentProfileQuery({
      variables: { clinicPetParentId: clinicPetParentId || '', clinicId: currentClinicId || '' },
      skip: !clinicPetParentId || !currentClinicId,
      fetchPolicy: GraphQLFetchPolicies.CacheAndNetwork,
      onCompleted: (data) => {
        // TODO: Move this logic to a central location so we don't have side effects like this
        // scattered in different places
        setCareLink('');
        if (!currentClinicPetId || !data?.clinicPetParent?.pets.some(({ id }) => id === currentClinicPetId)) {
          const firstPetId = data?.clinicPetParent?.pets?.[0]?.id;
          if (firstPetId) {
            setCurrentClinicPetId(firstPetId);
          } else {
            setCurrentClinicPetId('');
          }
        }
        const shouldTriggerMessageEvent = data.clinicPetParent?.phoneNumbers.filter(
          (number) => !number.isDeleted && number.phoneNumberLookupRequest === null,
        );

        const isReprioritizationRequired = !data.clinicPetParent?.phoneNumbers.some(
          (number) =>
            !number.isDeleted &&
            number?.phoneNumberLookupRequest &&
            number?.phoneNumberLookupRequest?.status !== PhoneNumberLookupRequestStatus.Failed,
        );

        if (
          !!data.clinicPetParent?.id &&
          shouldTriggerMessageEvent?.length !== 0 &&
          jobChainIdFromResponse == '' &&
          (clinicUser?.isAdmin || userHasPermission(PermissionType.WriteClinicPetParent))
        ) {
          triggerMessageEvent({
            variables: {
              event: MessageEvents.BANDWIDTH_PHONE_NUMBER_LOOKUP,
              queueName: SqsQueueNames.PimsSyncJobs,
              data: {
                canReprioritize: isReprioritizationRequired,
                clinicPetParentId: data.clinicPetParent.id,
              },
              useSubscription: true,
            },
          });
        }
      },
    });

  const clinicPetParent = clinicPetParentData?.clinicPetParent;

  const { pets: currentParentPets } = useGetParentPets(clinicPetParent?.id);

  const { setIsOpen, setCurrentView: setCurrentSidePanelView } = useSidePanel();
  const { isMobile } = useResolutionProvider();
  const [isLoading, setIsLoading] = useState(false);
  const [careLink, setCareLink] = useState<string | null | undefined>('');
  const [isPrimaryPhoneNumberUpdated, setIsPrimaryPhoneNumberUpdated] = useState(false);

  const {
    setCurrentPaymentMedium: setCurrentInvoicingPaymentMedium,
    setSuggestedPetParentOptions: setSuggestedInvoicingPetParentOptions,
    setSelectedPetParent: setSelectedInvoicingPetParent,
    setCurrentInvoiceStatus,
    terminalOptions,
    currentInvoiceStatus,
    setInvoiceIds,
    setIsBalancePreSelected,
  } = useStripeTerminalProvider();

  const channelId = location.pathname.split('/')[2];

  const { isBrowserSupported, OS } = useMemo(() => {
    return checkBrowserSupport();
  }, []);

  const [
    getChannels,
    {
      data: channelsData,
      loading: isChannelsDataLoading,
      variables: getChannelVariables,
      fetchMore: fetchMoreChannels,
    },
  ] = useLazyQuery<{
    findManyChannel: Channel[];
    findManyChannelCount: number;
  }>(GET_PET_PARENT_SIDE_PANEL_CHANNELS, {
    fetchPolicy: GraphQLFetchPolicies.NetworkOnly,
    errorPolicy: GraphQLErrorPolicies.All,
  });

  const [getCareUrlLink] = useGetDirectedCareLoginLinkMutation({
    variables: {
      data: {
        loginType: LoginType.Flow,
        clinicPetParentId: clinicPetParent?.id || '',
      },
    },
  });

  useEffect(() => {
    if (clinicPetParent?.id) {
      getChannels({
        variables: {
          where: {
            isActive: { equals: true },
            channelMembers: {
              some: { clinicPetParentId: { equals: clinicPetParent.id }, removedAt: { equals: null } },
            },
          },
          orderBy: { updatedAt: SortOrder.Desc },
          take: 10,
          skip: 0,
        },
      });
    }
  }, [clinicPetParent?.id, getChannels, triggerMessageEvent]);

  useEffect(() => {
    if (useJobChainCompletedData) {
      const isPrimaryNumberChanged = useJobChainCompletedData?.jobChainCompleted?.payload?.[0].isPrimaryChanged;
      setIsPrimaryPhoneNumberUpdated(isPrimaryNumberChanged);
      if (isPrimaryNumberChanged) {
        refetchGetSidePanelPetParentProfile();
      }
    }
  }, [refetchGetSidePanelPetParentProfile, useJobChainCompletedData]);

  const allLoaded = useMemo(() => {
    if (channelsData?.findManyChannelCount && getChannelVariables) {
      return channelsData.findManyChannelCount <= getChannelVariables.skip + 10;
    }
    return true;
  }, [channelsData?.findManyChannelCount, getChannelVariables]);

  const phoneTooltipMessage = useMemo(() => {
    if (!isBrowserSupported) {
      return getTooltipMessage(CallTypes.Phone, OS);
    }

    return 'Begin phone call';
  }, [isBrowserSupported, OS]);

  const [fetchActiveFamilyChannel] = useGetOrCreateActiveClientChannelMutation({
    variables: { data: { clinicPetParentIds: clinicPetParent ? [clinicPetParent?.id] : [] } },
    refetchQueries: [getClinicChannels, 'getChannelListChannels', getChannelUnreadMessageCount],
  });

  const isNotMergablePimsClient = !isMergeExistingPimsProfileSupported && !!clinicPetParent?.pimsId;

  const goToChannel = useCallback(
    async (param?: string) => {
      setIsLoading(true);
      try {
        const { data } = await fetchActiveFamilyChannel();
        const channelId = data?.getOrCreateActiveClientChannel?.id;
        if (channelId) {
          history.push(
            replaceParam(RouteDefinitions.Conversations, ':channelId', `${channelId}${param ? `?${param}=true` : ''}`),
          );
        }

        if (isMobile) setIsOpen(false);
        // eslint-disable-next-line
      } catch (e: any) {
        Sentry.captureException(e);
        toast({
          status: 'info',
          description: e?.message,
        });
        console.error(e);
      }
      setIsLoading(false);
    },
    [history, fetchActiveFamilyChannel, toast, setIsOpen, isMobile],
  );

  const activeConversationId = useMemo((): string | null => {
    if (channelsData?.findManyChannel) {
      for (const channel of channelsData.findManyChannel) {
        if (channel.channelStatus?.channelStatusAction === ChannelStatusAction.Active) {
          return channel.id;
        }
      }
    }
    return null;
  }, [channelsData]);

  const {
    data: { unreadChannelArray: unreadChannelIds },
  } = useUnreadMessageCount();

  const openChatWithParent = useCallback(async (): Promise<void> => {
    goToChannel();
  }, [goToChannel]);

  const sendVideoCallRequest = useCallback(async (): Promise<void> => {
    goToChannel('sendVideo');
  }, [goToChannel]);

  const startPhoneCall = useCallback(async (): Promise<void> => {
    if (isMobile) setIsOpen(false);

    // TODO check if a call is already in progress before executing the next line
    openPopover(isBrowserSupported ? PopoverNames.PhoneCall : PopoverNames.UnsupportedBrowser, {
      clinicPetParent: clinicPetParent,
    });
  }, [isBrowserSupported, isMobile, clinicPetParent, openPopover, setIsOpen]);

  const sendToSidePanel = useCallback((): void => {
    if (currentInvoiceStatus !== InvoiceStatus.Pending) {
      setIsOpen(false);
      setTimeout(() => {
        setCurrentSidePanelView(PanelViews.Invoicing);
        setIsOpen(true);
        if (terminalOptions && terminalOptions.length > 0) {
          setCurrentInvoicingPaymentMedium(PaymentMedium.StripeTerminal);
        } else {
          setCurrentInvoicingPaymentMedium(PaymentMedium.StripeVirtualTerminal);
        }
        setCurrentInvoiceStatus(InvoiceStatus.Default);
        setSelectedInvoicingPetParent(clinicPetParent || undefined);
        setSuggestedInvoicingPetParentOptions([]);
        setInvoiceIds([]);
        setIsBalancePreSelected(false);
      }, 0);
    }
  }, [
    clinicPetParent,
    currentInvoiceStatus,
    setCurrentInvoiceStatus,
    setCurrentInvoicingPaymentMedium,
    setCurrentSidePanelView,
    setInvoiceIds,
    setIsBalancePreSelected,
    setIsOpen,
    setSelectedInvoicingPetParent,
    setSuggestedInvoicingPetParentOptions,
    terminalOptions,
  ]);

  const primaryPhoneNumber = useMemo(() => {
    const primary = selectPrimaryPhoneNumberBestGuess(clinicPetParent?.phoneNumbers || []);
    if (!primary) return;

    return formatPhoneNumber(cleanPhoneNumber(primary));
  }, [clinicPetParent]);

  const displayName = useMemo(() => {
    if (!clinicPetParent) return '';
    const { firstName, lastName, creationSource, phoneNumbers } = clinicPetParent;
    if (creationSource === ClinicEntityCreationSource.InboundSms && !firstName && !lastName) {
      return '';
    }
    return getClinicPetParentDisplayName({
      firstName,
      lastName,
      creationSource,
      phoneNumbers,
    });
  }, [clinicPetParent]);

  const onJoinSelectedConversation = useCallback(async (): Promise<void> => {
    try {
      if (!clinicPetParent) throw new Error('No pet parent available');
      await addUserToChannel(clinicPetParent.id);

      getChannels({
        variables: {
          where: {
            isActive: { equals: true },
            channelMembers: {
              some: { clinicPetParentId: { equals: clinicPetParent.id }, removedAt: { equals: null } },
            },
          },
          orderBy: { updatedAt: SortOrder.Desc },
        },
      });
      toast({
        description: `${clinicPetParent.firstName} ${clinicPetParent.lastName} was successfully added to the conversation`,
      });
      Mixpanel.track('Pet parent Join Conversation Clicked');
    } catch (e) {
      Sentry.captureException(e);
      toast({
        status: 'error',
        description: 'An error occurred in linking this client',
        duration: 10000,
      });
      console.error(e);
    }

    setSearchPanelMode(Mode.Default);
  }, [toast, addUserToChannel, clinicPetParent, getChannels, setSearchPanelMode]);

  /**
   * Ensure this client does not already have an active conversation
   * before adding them to the selected conversation
   */
  const isInActiveConversation = useMemo(() => {
    if (!channelsData?.findManyChannel && channelsData?.findManyChannel?.length !== 0) return true;

    const activeChannels = channelsData.findManyChannel.filter(
      (c) => c.channelStatus?.channelStatusAction === ChannelStatusAction.Active,
    );

    return !!activeChannels.length;
  }, [channelsData]);

  const loadMoreChannels = useCallback(() => {
    if (clinicPetParent?.id && fetchMoreChannels) {
      const skip = channelsData?.findManyChannel?.length || 0;
      fetchMoreChannels({
        variables: {
          skip,
        },
      });
    }
  }, [clinicPetParent?.id, fetchMoreChannels, channelsData?.findManyChannel?.length]);

  const onCopyToClipboard = async (linkValue: string): Promise<void> => {
    await navigator.clipboard.writeText(linkValue);
    toast({
      description: 'Copied to clipboard',
      duration: 2000,
    });
  };

  const handleCopyToClipboard = async (): Promise<void> => {
    Mixpanel.track('Copy Pet Portal Link clicked', { isOttoTeamMember });
    gaTrack(GA4Events.PET_PROFILE_TAB_PET_PORTAL_LINK_CTA, {
      utm_source: 'profile tab',
      utm_medium: 'flow',
    });
    try {
      if (!!careLink) {
        await onCopyToClipboard(careLink);
      } else {
        const { data } = await getCareUrlLink();
        setCareLink(data?.getDirectedCareLoginLink.link);
        if (data) {
          await onCopyToClipboard(data?.getDirectedCareLoginLink.link);
        }
      }
    } catch (error) {
      toast({
        status: 'error',
        description: 'Something went wrong!!',
        duration: 2000,
      });
    }
  };

  // only enabled if not on mobile, not in a PIMS other than AVImark, there are no pets with Care plan enrollments, and the client does not have any loyalty points or redemptions
  const isEligibleForMergeProfiles = useMemo(() => {
    return (
      !isMobile &&
      !isNotMergablePimsClient &&
      !currentParentPets?.some((pet) => pet.organizationPet?.carePlanEnrollments.length) &&
      !clinicPetParent?.loyaltyAccountHolder?.account?.pointBalance &&
      !clinicPetParent?.loyaltyAccountHolder?.account?.rewardRedemptions?.length &&
      !clinicPetParent?.isTest
    );
  }, [clinicPetParent, currentParentPets, isMobile, isNotMergablePimsClient]);

  const moreMenuOptions: Record<string, MoreMenuOption> = useMemo(() => {
    return {
      MergeProfiles: {
        value: 'mergeProfiles',
        label: 'Merge Profiles',
        show: true,
        isDisabled: !isEligibleForMergeProfiles,
      },
    };
  }, [isEligibleForMergeProfiles]);

  const handleMenuOptionClicked = (menuOption: MoreMenuOption, disabledReason?: MergeDisabledReason): void => {
    // currently just for tracking when a user tries to click on the disabled merge profiles option
    if (!(menuOption.isDisabled && menuOption.value === 'mergeProfiles' && !!disabledReason)) return;

    const { code } = disabledReason;
    if (code === 3) {
      Mixpanel.track('Loyalty Points Merge Attempt');
    }
    if (code === 5) {
      Mixpanel.track('Care Membership Merge Attempt');
    }
  };

  const menuOptions = useMemo(() => {
    if (!clinicPetParent) return [];
    const filteredMenuOptions = Object.keys(moreMenuOptions)
      .map((optionKey) => moreMenuOptions[optionKey])
      .filter((option) => option.show);
    const optionsArray: MenuItemProps[] = filteredMenuOptions.map((menuOption: MoreMenuOption) => {
      const disabledReason = getSidePanelMergeDisabledReason(
        clinicPetParent,
        clinicPetParent.pimsId ?? undefined,
        !!isMergeExistingPimsProfileSupported,
        primaryIntegrationName,
        currentParentPets,
      );
      return {
        label: menuOption.label,
        value: menuOption.value,
        isDisabled: menuOption.isDisabled,
        children: menuOption.isDisabled ? (
          <Tooltip label={disabledReason?.reason || ''} titleCaseLabel={false}>
            <Box textAlign="left" onClick={(): void => handleMenuOptionClicked(menuOption, disabledReason)}>
              <Text>{menuOption.label}</Text>
            </Box>
          </Tooltip>
        ) : (
          <Box textAlign="left">
            <Text>{menuOption.label}</Text>
          </Box>
        ),
      };
    });

    return optionsArray;
  }, [
    clinicPetParent,
    isMergeExistingPimsProfileSupported,
    moreMenuOptions,
    primaryIntegrationName,
    currentParentPets,
  ]);

  const hasActiveChannel = useMemo(() => {
    return !!channelsData?.findManyChannel.some(
      (channel) => channel?.channelStatus?.channelStatusAction === ChannelStatusAction.Active,
    );
  }, [channelsData?.findManyChannel]);

  const handleMergeProfilesModalOpen = useCallback(() => {
    Mixpanel.track('Merge Profile Initiated');
    mergeProfilesDisclosure.onOpen();
  }, [mergeProfilesDisclosure]);

  useEffect(() => {
    if (clinicPetParentId) {
      setIsPrimaryPhoneNumberUpdated(false);
    }
  }, [clinicPetParentId]);

  if (!clinicPetParent) return null;

  const { email, pimsId } = clinicPetParent;
  const isPimsPetParent = !!clinicPetParent.pimsId;

  return (
    <Container popover={popover}>
      <Collapse in={isPrimaryPhoneNumberUpdated} animateOpacity={true}>
        <Box mx={1} mb={1}>
          <Alert
            title="Primary phone number updated"
            status="info"
            hideIcon
            descriptionProps={{
              fontSize: '14px',
              maxWidth: '275px',
              children: 'We updated the primary number to the one we detected as the mobile number.',
            }}
            onClose={(): void => setIsPrimaryPhoneNumberUpdated(false)}
          />
        </Box>
      </Collapse>
      {mergeProfilesDisclosure.isOpen && (
        <MergeProfilesModal
          isOpen={mergeProfilesDisclosure.isOpen}
          onClose={mergeProfilesDisclosure.onClose}
          clinicPetParent={clinicPetParent}
          hasActiveConversation={hasActiveChannel}
        />
      )}
      <PetParentTopDiv>
        {!!menuOptions.length && (
          <Flex w="100%" justify="flex-end" p={1}>
            <Menu
              buttonProps={{
                leftIconName: 'dotsVertical',
                variant: 'ghostNeutral',
                showRightIcon: false,
                size: 'sm',
                width: 'fit-content',
                position: 'relative',
                top: 0,
                right: 0,
              }}
              listProps={{
                menuItems: menuOptions,
                onSelect: (selectedItem: MenuItemProps): void => {
                  switch (selectedItem.value) {
                    case moreMenuOptions.MergeProfiles.value:
                      handleMergeProfilesModalOpen();
                      break;
                    default:
                      break;
                  }
                },
                zIndex: 10,
              }}
            />
          </Flex>
        )}
        <ParentPhoto>
          <PetParentAvatar clinicPetParent={clinicPetParent} size="lg" />
        </ParentPhoto>
        {!!clinicPetParent.isTest && (
          <Tooltip
            label="This is a Test Client generated by a Clinic User with the same name. Test Clients help you explore and test Flow features without bothering actual clients."
            titleCaseLabel={false}
          >
            <Box mt={4}>
              <Tag variant="warning" size="md" label="Test Client" />
            </Box>
          </Tooltip>
        )}
        <Flex align="center" justify="center" my={2}>
          {isPimsPetParent ? (
            <>
              <ParentName isEmpty={!displayName} isDisabled={true}>
                <Text fontWeight="bold" size="xl">
                  {displayName || 'Add client name'}
                </Text>
              </ParentName>
              {isClientIdSupported && pimsId && <Text size="sm">{`ID: ${pimsId}`}</Text>}
            </>
          ) : (
            <ClinicPetParentNamePopover
              clinicPetParent={clinicPetParent}
              anchor={
                <ParentName isEmpty={!displayName} isDisabled={false}>
                  <Text fontWeight="bold" size="xl">
                    {displayName || 'Add client name'}
                  </Text>
                  <PencilIconContainer>
                    <StyledPencilIcon />
                  </PencilIconContainer>
                </ParentName>
              }
              onReloadClinicPetParent={(clinicPetParentId: string): void => viewClinicPetParent({ clinicPetParentId })}
            />
          )}
        </Flex>
        <DevModeDisplayJSON src={clinicPetParent} />
        {isLoading ? (
          <VStack align="center" justify="center" spacing={4}>
            Loading chat channel...
            <Spinner />
          </VStack>
        ) : (
          <>
            <IconSet>
              <IconWrapper onClick={openChatWithParent}>
                <Badge>
                  {unreadChannelIds && activeConversationId
                    ? unreadChannelIds.indexOf(activeConversationId) >= 0 && <NotificationCounter size="sm" />
                    : null}
                </Badge>
                <StyledIcon
                  $width="13px"
                  data-mixpanel-name="Chat icon in side panel"
                  data-testid="side-panel-chat-button"
                >
                  <ChatIcon />
                </StyledIcon>
                <IconCallToActionComponent
                  actionText={activeConversationId ? 'Join Chat' : 'Start Chat'}
                  data-testid="side-panel-pet-parent-profile-chat-button"
                />
              </IconWrapper>

              <IconWrapper onClick={sendVideoCallRequest}>
                <StyledIcon
                  $width="15px"
                  data-mixpanel-name="Video call icon in side panel"
                  data-testid="side-panel-send-call-request-button"
                >
                  <VideoCallIcon />
                </StyledIcon>
                <IconCallToActionComponent actionText="Video Chat" />
              </IconWrapper>
              <IconWrapper onClick={startPhoneCall}>
                <Tooltip
                  label={
                    !selectPrimaryPhoneNumberBestGuess(clinicPetParent.phoneNumbers || [])
                      ? `${clinicPetParent.firstName} ${clinicPetParent.lastName} does not have a saved phone number.`
                      : phoneTooltipMessage
                  }
                >
                  <StyledIcon
                    $height="14px"
                    data-mixpanel-name="Phone call icon in side panel"
                    data-testid="side-panel-call-button"
                    $isInvalid={
                      !isBrowserSupported || !selectPrimaryPhoneNumberBestGuess(clinicPetParent.phoneNumbers || [])
                    }
                  >
                    <CallIcon />
                  </StyledIcon>
                </Tooltip>
                <IconCallToActionComponent actionText="Phone Call" />
              </IconWrapper>
              <IconWrapper onClick={sendToSidePanel}>
                <Tooltip label="Request Payment">
                  <StyledIcon
                    $height="14px"
                    data-mixpanel-name="Payment icon in side panel"
                    data-testid="side-panel-payment-button"
                  >
                    <CreditCardIcon />
                  </StyledIcon>
                </Tooltip>
                <IconCallToActionComponent actionText="Payment" />
              </IconWrapper>
            </IconSet>
            {searchPanelMode === Mode.LinkToChannel && isValidChannel && (
              <Tooltip
                label={
                  isInActiveConversation
                    ? 'Please archive or remove this client from any active conversions before adding this client to the selected conversation.'
                    : ''
                }
              >
                <span>
                  <Button size="xs" onClick={onJoinSelectedConversation} disabled={isInActiveConversation}>
                    Join Selected Conversation
                  </Button>
                </span>
              </Tooltip>
            )}
          </>
        )}
      </PetParentTopDiv>
      <Box className="PetParentProfile__ContactInfo" bg="background.subtle">
        <HStack flex="0 0 auto" justifyContent="space-between" p={5}>
          <Box className="PetParentProfile__PhoneEmail" flex="0 0 auto" mr="3">
            <Text size="sm" fontWeight="bold">
              Primary Phone
            </Text>
            <Popover
              isLazy
              anchor={
                <PhoneInfo
                  data-testid="petparent-primary-phonenumber"
                  onClick={(): void => {
                    Mixpanel.track('Side panel edit client primary phone number clicked');
                  }}
                >
                  <Text size="sm">{primaryPhoneNumber || '###-###-####'}</Text>
                  <ClientDetailsPencilIcon />
                </PhoneInfo>
              }
              placement={PopoverPlacement.BottomEnd}
              offset={[0, 5]}
            >
              <ClientPhoneNumberPopover
                clinicPetParent={clinicPetParent}
                goToPetParent={goToPetParent}
                setIsPrimaryPhoneNumberUpdated={setIsPrimaryPhoneNumberUpdated}
                setJobChainIdFromResponse={setJobChainIdFromResponse}
                refetchGetSidePanelPetParentProfile={refetchGetSidePanelPetParentProfile}
              />
            </Popover>
          </Box>
          <Box className="PetParentProfile__Survey" flex="1 1 auto">
            {hasIntegrations && !!clinicPetParent.pimsId ? (
              <>
                <Text as="p" size="sm" fontWeight="bold">
                  Email
                </Text>
                <Text isTruncated as="p" size="sm">
                  {email || ''}
                </Text>
              </>
            ) : (
              <>
                <Text as="p" size="sm" fontWeight="bold">
                  Email
                </Text>
                <Popover
                  anchor={
                    <EmailInfo
                      onClick={(): void => {
                        Mixpanel.track('Side panel edit client email number clicked');
                      }}
                    >
                      <Text isTruncated size="sm">
                        {email || ''}
                      </Text>
                      <ClientDetailsPencilIcon />
                    </EmailInfo>
                  }
                  placement={PopoverPlacement.BottomEnd}
                  offset={[0, 5]}
                >
                  <ClientEmailPopover clinicPetParent={clinicPetParent} goToPetParent={goToPetParent} />
                </Popover>
              </>
            )}
          </Box>
        </HStack>
        <SidePanelContactOptions petParentSetting={clinicPetParent.petParentSetting} petParentId={clinicPetParent.id} />
        <HStack className="PetParentProfile__PortalLink" px={5} pb={5} justifyContent="space-between">
          <Text size="sm" fontWeight="bold">
            Pet Portal Link
          </Text>
          <Button
            onClick={async (): Promise<void> => await handleCopyToClipboard()}
            size="sm"
            variant="ghost"
            iconName="twoSquares"
          >
            Copy Link
          </Button>
        </HStack>
        {isContactSyncSupported && <SidePanelContactInfoSync clinicPetParent={clinicPetParent} />}
      </Box>
      <Box py={4}>
        <TrupanionProfileCheck clinicPetParentId={clinicPetParent.id} />
        <PetsList petParent={clinicPetParent} />
      </Box>
      <HasFeature name={FeatureFlagName.LoyaltyProgram}>
        {!!loyaltyProgramData?.findFirstLoyaltyProgram && (
          <SidePanelLoyaltyProgram petParentName={clinicPetParent.firstName} petParentId={clinicPetParent.id} />
        )}
      </HasFeature>
      <SidePanelConversationsSection
        channels={channelsData?.findManyChannel || []}
        channelId={channelId}
        isMobile={isMobile}
        setIsOpen={setIsOpen}
        isChannelsDataLoading={isChannelsDataLoading}
        loadMoreChannels={loadMoreChannels}
        allLoaded={allLoaded}
        isLoyaltyProgramEnabled={
          isFeatureEnabled(FeatureFlagName.LoyaltyProgram) && !!loyaltyProgramData?.findFirstLoyaltyProgram
        }
      />
    </Container>
  );
};

const ClientDetailsPencilIcon = styled(PencilIcon)`
  margin: 0 2px 0 4px;
  flex: 0 0 auto;
  opacity: 0.5;

  & * {
    fill: #575d7c;
  }
`;

const PhoneInfo = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 4px;
  margin: -4px 0 0 -4px;
  border-radius: 5px;
  border: 1px solid transparent;

  &:hover {
    border-color: #ddd;

    ${ClientDetailsPencilIcon} {
      opacity: 1;
    }
  }
`;

const PencilIconContainer = styled.div`
  width: 24px;
  height: auto;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const StyledPencilIcon = styled(PencilIcon)`
  * {
    fill: #ccc;
  }
`;

interface IParentNameProps {
  isEmpty: boolean;
  isDisabled: boolean;
}

const ParentName = styled.div<IParentNameProps>`
  display: flex;
  cursor: ${({ isDisabled }): string => (isDisabled ? 'auto' : 'pointer')};
  padding: 3px;
  border: 1px solid transparent;
  border-radius: 4px;
  position: relative;

  ${({ isDisabled }): string =>
    !isDisabled
      ? `
  :hover {
    border-color: #ddd;
  }

  :hover ${StyledPencilIcon} * {
    fill: #575d7c;
  }
`
      : ''}

  > div:first-child {
    border-bottom: ${({ isEmpty }): string => (isEmpty ? '1px dashed #ccc' : 'none')};
    font-weight: ${({ isEmpty }): string => (isEmpty ? '600' : '700')};
  }
`;

const EmailInfo = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  padding: 4px;
  margin: -4px 0 0 -4px;
  border-radius: 5px;
  border: 1px solid transparent;
  min-height: 21px;
  &:hover {
    border-color: #ddd;

    ${ClientDetailsPencilIcon} {
      opacity: 1;
    }
  }
`;

const PetParentTopDiv = styled.div`
  margin: 0px 0 30px 0;
  text-align: center;
  width: 100%;
`;

const ParentPhoto = styled.div`
  display: flex;
  justify-content: center;
`;

const Container = styled.div<{ popover: IPopover }>`
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const IconSet = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 12px 16px;
  margin: 0;
`;

interface IStyledIconProps {
  $height?: string;
  $width?: string;
  $isInvalid?: boolean;
}

const StyledIcon = styled.div<IStyledIconProps>`
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${({ $isInvalid }): number => ($isInvalid ? 0.5 : 1)};
  cursor: ${({ $isInvalid }): string => ($isInvalid ? ' not-allowed' : 'pointer')};
  padding: 5px;
  border-radius: 7px;
  background-color: var(--chakra-colors-background-alpha);

  &:hover {
    background-color: var(--chakra-colors-background-alpha-hover);
  }

  & svg {
    width: ${({ $width }): string => $width || 'auto'};
    height: ${({ $height }): string => $height || 'auto'};
  }

  & path {
    fill: var(--chakra-colors-text-interactive);
    transition: all 0.1s ease-in-out;
  }
`;

const PetRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 26px;
  cursor: pointer;
  opacity: ${(props: DeceasedState): string => (props.isDeceased ? '0.6' : '1')};
  &:hover {
    background: #f9f9f9;
  }
`;

interface DeceasedState {
  isDeceased: Maybe<boolean | undefined>;
}
PetRow.defaultProps = {
  isDeceased: false,
};

const IconWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Badge = styled.div`
  position: absolute;
  top: -5px;
  right: 3px;
`;

export default SidePanelPetParentProfile;
