/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useState } from 'react';
import { GetRoutingKeysFromQueueName, Job } from '@televet/shared-types';
import { SqsQueueNames } from '@televet/shared-types/SqsQueueNames';
import {
  useJobChainCompletedSubscription,
  useJobChainStatusQuery,
  useTriggerMessageEventMutation,
} from 'shared/types/graphql';

export type TriggerMessageEventStatus = 'pending' | 'completed' | 'errored';

export type TriggerMessageEventPayload<T> = {
  payload?: T[] | null;
  error?: string;
  status?: TriggerMessageEventStatus;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useTriggerMessageEventAndSubscribe<T>(longPollIntervalInMilleseconds = 5000) {
  const [payload, setPayload] = useState<TriggerMessageEventPayload<T> | null | undefined>();

  const [triggerMessageEventRaw, { data: messageEventData, loading: isTriggerMessageEventLoading }] =
    useTriggerMessageEventMutation();

  const jobChainId = messageEventData?.triggerMessageEvent?.jobChainId;

  const { stopPolling } = useJobChainStatusQuery({
    variables: { jobChainId: jobChainId || '' },
    skip: !jobChainId,
    pollInterval: longPollIntervalInMilleseconds,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data.jobChainStatus) {
        const { payload, error, status } = data.jobChainStatus;
        if (status === 'completed') {
          stopPolling();
        }
        setPayload((value) => ({
          ...value,
          payload,
          error,
          status: status as TriggerMessageEventStatus,
        }));
      }
    },
  });

  async function triggerMessageEvent<
    Q extends SqsQueueNames,
    R extends GetRoutingKeysFromQueueName<Q>,
    M extends Extract<Job, { queueName: Q; routingKey: R }>['message'],
  >(options: {
    variables: { useSubscription?: boolean | undefined; event: R; queueName: Q; data: M | M[] };
    skip?: boolean | undefined;
  }): Promise<ReturnType<typeof triggerMessageEventRaw>> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return triggerMessageEventRaw(options as any);
  }

  useJobChainCompletedSubscription({
    variables: { jobChainId: jobChainId || '' },
    skip: !jobChainId || payload?.status === 'completed',
    onSubscriptionData: ({ subscriptionData }) => {
      const data = subscriptionData.data?.jobChainCompleted;
      if (data) {
        // TODO: Should we refetch the query, too?
        stopPolling();
        setPayload((value) => ({ ...value, payload: data.payload, error: data.error, status: 'completed' }));
      }
    },
  });

  return { triggerMessageEvent, payload, isTriggerMessageEventLoading };
}
