import { WorkflowEvent } from 'shared/types/graphql';
import { TimeIncrement } from '../../types/AutomationDraft';

export const inMinutes = {
  Minutes: 1,
  Hours: 60,
  Days: 1440, // 60 * 24
  Weeks: 10080, // 1440 * 7
  Months: 43200, // 1440 * 30
} as Record<TimeIncrement, number>;

export const convertTime = (value: number, originalUnit: TimeIncrement, newUnit: TimeIncrement): number => {
  const minutes = value * inMinutes[originalUnit];
  return Math.floor(minutes / inMinutes[newUnit]);
};

/**
 * Convert a time in minutes to a reasonable display value with time unit in minutes, hours, days, weeks, or months
 * If *_NOT_* using the lapsed pet parent automation type, only convert value to days if time of day is specified
 * Generic automation type examples:
 * - If input is 120, this returns as "2 hours"
 * - If input is 1440, this returns as "24 hours"
 * - If input 1440 and time of day is specified, this returns as "1 day"
 * Lapsed pet parent automation type examples:
 * - If input is 40320, this returns as "1 month"
 * - If input is 1440, this returns as "1 day"
 */
export const convertMinutesToTimeDisplay = ({
  timeInMinutes,
  automationType,
  isTimeOfDaySpecified = false,
}: {
  timeInMinutes: number;
  automationType: WorkflowEvent;
  isTimeOfDaySpecified: boolean;
}): { timeValue: number; timeIncrement: TimeIncrement } => {
  const timeInHours = timeInMinutes / 60;
  const timeInDays = timeInHours / 24;
  const timeInWeeks = timeInDays / 7;
  const timeInMonths = timeInDays / 30;

  let timeValue = timeInMinutes;
  let timeIncrement = TimeIncrement.Minutes;

  if (automationType === WorkflowEvent.LapsedPetParent) {
    if (timeInMonths % 1 === 0) {
      timeValue = timeInMonths;
      timeIncrement = TimeIncrement.Months;
    } else if (timeInWeeks % 1 === 0) {
      timeValue = timeInWeeks;
      timeIncrement = TimeIncrement.Weeks;
    } else if (timeInDays % 1 === 0) {
      timeValue = timeInDays;
      timeIncrement = TimeIncrement.Days;
    }
  } else {
    if (timeInDays % 1 === 0 && isTimeOfDaySpecified) {
      timeValue = timeInDays;
      timeIncrement = TimeIncrement.Days;
    } else if (timeInHours % 1 === 0) {
      timeValue = timeInHours;
      timeIncrement = TimeIncrement.Hours;
    }
  }

  return {
    timeValue,
    timeIncrement,
  };
};
