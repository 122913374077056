import { useEffect, useMemo } from 'react';
import { GraphQLFetchPolicies } from 'shared/enums/GraphQLFetchPolicies';
import { ClinicCarePlanFragment, useGetCareEnrollmentInfoQuery } from 'shared/types/graphql';
import { useManageMembershipContext } from '../components/ManageMembership/context/ManageMembershipContext';
import { PaymentsMade } from '../components/ManageMembership/types';
import { centsToDollars } from '../utils/currency';
import { getRenewalCycleInMonths } from '../utils/planRenewalCycle';

interface UseUpgradeCalculatorInput {
  enrollmentId: string;
  selectedPlanId: string;
  plans: ClinicCarePlanFragment[];
}

interface PlanInfo {
  id: string;
  title?: string;
  monthlyCost: number;
}

interface UseUpgradeCalculatorOutput {
  currentPlan: PlanInfo;
  selectedPlan: PlanInfo;
  paymentsMade: PaymentsMade;
  differenceBetweenPlans: number;
  upgradeFee: number;
  isLoading: boolean;
}

export const useUpgradeCalculator = ({
  enrollmentId,
  selectedPlanId,
  plans,
}: UseUpgradeCalculatorInput): UseUpgradeCalculatorOutput => {
  const { setBalanceDue } = useManageMembershipContext();

  const { data, loading: isLoading } = useGetCareEnrollmentInfoQuery({
    variables: {
      enrollmentId,
    },
    fetchPolicy: GraphQLFetchPolicies.NetworkOnly,
  });

  const upgradeCalcInfo = data?.findUniqueCarePlanEnrollment;
  const currentCarePlan = upgradeCalcInfo?.plan;

  const currentPlan: PlanInfo = useMemo(() => {
    return {
      id: currentCarePlan?.id || '',
      title: currentCarePlan?.flowAlias || currentCarePlan?.title,
      monthlyCost: currentCarePlan?.pricePerRenewal
        ? centsToDollars(currentCarePlan?.pricePerRenewal) / getRenewalCycleInMonths(currentCarePlan?.renewalCycle)
        : 0,
    };
  }, [currentCarePlan]);

  const selectedPlan: PlanInfo = useMemo(() => {
    const plan = plans.find((plan) => plan.id === selectedPlanId);
    return {
      id: plan?.id || '',
      title: plan?.flowAlias || plan?.title,
      monthlyCost: plan?.pricePerRenewal
        ? centsToDollars(plan?.pricePerRenewal) / getRenewalCycleInMonths(plan?.renewalCycle)
        : 0,
    };
  }, [plans, selectedPlanId]);

  const paymentsMade: PaymentsMade = useMemo(() => {
    const payments = upgradeCalcInfo?.enrollmentPayments;
    const planPrice = currentCarePlan?.pricePerRenewal
      ? centsToDollars(currentCarePlan?.pricePerRenewal) / getRenewalCycleInMonths(currentCarePlan?.renewalCycle)
      : 0;
    const totalPaid = payments?.reduce((prev, curr) => curr.amount + prev, 0) || 0;
    const totalPaidInDollars = centsToDollars(totalPaid);

    return {
      total: totalPaidInDollars,
      price: planPrice,
      quantity: totalPaidInDollars / planPrice,
    };
  }, [currentCarePlan?.pricePerRenewal, currentCarePlan?.renewalCycle, upgradeCalcInfo?.enrollmentPayments]);

  const differenceBetweenPlans = useMemo(
    () => selectedPlan.monthlyCost - currentPlan.monthlyCost,
    [currentPlan.monthlyCost, selectedPlan.monthlyCost],
  );

  const upgradeFee = useMemo(
    () => differenceBetweenPlans * paymentsMade.quantity,
    [differenceBetweenPlans, paymentsMade.quantity],
  );

  useEffect(() => {
    setBalanceDue(upgradeFee >= 0 ? upgradeFee : undefined);
  }, [setBalanceDue, upgradeFee]);

  return {
    currentPlan,
    selectedPlan,
    differenceBetweenPlans,
    upgradeFee,
    paymentsMade,
    isLoading,
  };
};
