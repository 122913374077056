import React, { ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { IIntegrationsContext, IntegrationsContext, SyncStatus } from './';
import useFeatureFlag from 'shared/hooks/useFeatureFlag';
import { FeatureFlagName, GraphQLFetchPolicies } from 'shared/enums';
import { RouteBasePaths, SettingsRoutes } from 'routes';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { HStack } from '@televet/kibble-ui/build/chakra';
import { useToast } from '@televet/kibble-ui/build/components/Toast';
import {
  BitwerxSystem,
  ClinicPimsIntegrationType,
  useGetMostRecentSyncDataLazyQuery,
  useSubscribeToSyncOutagesSubscription,
  VetDataIntegrationSystem,
} from 'shared/types/graphql';
import useClinicUser from 'shared/hooks/useClinicUser';
import { useHistory } from 'react-router-dom';
import { SYNC_STATUS_HELP_ARTICLE_URL } from 'pages/Settings/pages/SyncStatusSettings';
import { Mixpanel } from 'shared/utils';
import { IntegrationSystemDisplayNameType } from '../types';
import { getHasPrescriptionCapabilities } from '../utils/getHasPrescriptionCapabilities';
import { parseISO, add, differenceInMilliseconds } from 'date-fns';
import { clinicWithinSyncWindow } from '../utils/timeFuncs';

export const useIntegrationsProvider = (): IIntegrationsContext =>
  useContext<IIntegrationsContext>(IntegrationsContext);

interface IntegrationsProviderProps {
  children: ReactNode;
}

export const IntegrationsProvider = ({ children }: IntegrationsProviderProps): JSX.Element => {
  const { currentClinic } = useClinicUser();
  const { isFeatureEnabled, isFeatureFlagsLoading } = useFeatureFlag();
  const [hasSyncOutage, setHasSyncOutage] = useState(false);
  const [isSyncPaused, setIsSyncPaused] = useState(false);

  const history = useHistory();
  const toast = useToast();

  const allIntegrations = useMemo(() => {
    return currentClinic?.integrations || [];
  }, [currentClinic]);

  const allActiveIntegrations = useMemo(() => {
    return allIntegrations.filter((integration) => integration.isActive);
  }, [allIntegrations]);

  const primaryIntegration = useMemo(() => {
    const integration = currentClinic?.integrations.find(
      ({ type, isActive }) =>
        type !== ClinicPimsIntegrationType.Bitwerx && type !== ClinicPimsIntegrationType.Ppc && isActive,
    );
    return integration || null;
  }, [currentClinic]);

  const hasAppointmentCreateWritebackCapability = useMemo(() => {
    if (!primaryIntegration) return false;
    if ([ClinicPimsIntegrationType.Neo, ClinicPimsIntegrationType.Ezyvet].includes(primaryIntegration.type)) {
      return true;
    }
    const bitwerxIntegration = allActiveIntegrations.find(
      (integration) => integration.type === ClinicPimsIntegrationType.Bitwerx,
    );
    if (
      bitwerxIntegration?.bitwerx?.system &&
      [BitwerxSystem.Avimark, BitwerxSystem.Cornerstone].includes(bitwerxIntegration.bitwerx?.system)
    ) {
      return true;
    }
    if (
      primaryIntegration?.vetdata?.system &&
      [VetDataIntegrationSystem.Avimark, VetDataIntegrationSystem.Impromed].includes(
        primaryIntegration?.vetdata?.system,
      )
    ) {
      return true;
    }
    return false;
  }, [primaryIntegration, allActiveIntegrations]);

  const hasOfficeHoursSyncFlag = useMemo(() => {
    return !isFeatureFlagsLoading && isFeatureEnabled(FeatureFlagName.OfficeHourSync);
  }, [isFeatureEnabled, isFeatureFlagsLoading]);

  const [getMostRecentSyncData, { data: syncData, refetch: refetchSyncData }] = useGetMostRecentSyncDataLazyQuery({
    variables: {
      where: {
        integrationId: {
          equals: primaryIntegration?.id,
        },
      },
    },
    fetchPolicy: GraphQLFetchPolicies.CacheAndNetwork,
    onCompleted: ({ findFirstIntegrationOutageHistoryEntry }) => {
      const syncError = findFirstIntegrationOutageHistoryEntry?.status === SyncStatus.SyncProblem;
      setHasSyncOutage(false);

      const isClinicInSyncWindow = clinicWithinSyncWindow(currentClinic);
      setIsSyncPaused(!syncError && !isClinicInSyncWindow && hasOfficeHoursSyncFlag);
      if (!syncError && !isClinicInSyncWindow && hasOfficeHoursSyncFlag) {
        if (toast.isActive('sync-status')) return;
        toast({
          title: `Syncs to your ${primaryIntegration?.displayName} server are currently paused and will resume 1 hour before your clinic opens. You can force a refresh by visiting the link below.`,
          status: 'warning',
          id: 'sync-status',
          duration: 15000, // set to 1500 for demo, revert to shorter value
          children: (
            <HStack mt={2} spacing={1}>
              <Button
                size="xs"
                isOnContrast
                variant="secondary"
                onClick={(): void => {
                  Mixpanel.track('Sync Status: Sync status settings page link clicked');
                  history.push(`${RouteBasePaths.Settings}${SettingsRoutes.SyncStatus}`);
                  toast.close('sync-status');
                }}
              >
                View Sync Status Page
              </Button>
            </HStack>
          ),
        });
      }

      if (syncError) {
        const errorTimeStamp = parseISO(findFirstIntegrationOutageHistoryEntry?.createdAt);
        const fortyFiveMinuteDelayTimeStamp = add(errorTimeStamp, { minutes: 45 });
        const timeLeftInMilliseconds = differenceInMilliseconds(new Date(fortyFiveMinuteDelayTimeStamp), new Date());

        /*
         * Only show the outage notification when the outage lasts longer than 45 minutes
         * This should help to prevent unnecessary concern/confusion for clinics if the outage is resolved in a timely manner
         */
        setTimeout(
          () => {
            setHasSyncOutage(true);
            if (toast.isActive('sync-status')) return;
            Mixpanel.track('Sync Status: Sync outage occurred');
            toast({
              title: `We are currently having issues communicating with your ${primaryIntegration?.displayName} server`,
              status: 'warning',
              id: 'sync-status',
              duration: 3000,
              children: (
                <HStack mt={2} spacing={1}>
                  <Button
                    size="xs"
                    isOnContrast
                    variant="secondary"
                    onClick={(): void => {
                      Mixpanel.track('Sync Status: Sync status settings page link clicked');
                      history.push(`${RouteBasePaths.Settings}${SettingsRoutes.SyncStatus}`);
                    }}
                  >
                    View Sync Status
                  </Button>
                  <Button
                    size="xs"
                    isOnContrast
                    variant="secondary"
                    onClick={(): void => {
                      Mixpanel.track('Sync Status: Knowledge base link clicked');
                      window.open(SYNC_STATUS_HELP_ARTICLE_URL);
                    }}
                  >
                    See Troubleshooting Steps
                  </Button>
                </HStack>
              ),
            });
          },
          timeLeftInMilliseconds >= 0 ? timeLeftInMilliseconds : 0,
        );
      }
    },
  });

  useSubscribeToSyncOutagesSubscription({
    variables: {
      where: {
        integrationId: {
          equals: primaryIntegration?.id,
        },
      },
    },
    skip: !primaryIntegration?.id,
    onData: () => {
      if (refetchSyncData) {
        refetchSyncData();
      }
    },
  });

  const syncStatus: SyncStatus = useMemo(
    (): SyncStatus => syncData?.findFirstIntegrationOutageHistoryEntry?.status as SyncStatus,
    [syncData],
  );

  const lastSyncTime = useMemo(() => {
    return syncData?.findFirstIntegrationOutageHistoryEntry?.createdAt || null;
  }, [syncData]);

  const hasPrescriptionCapabilities = useMemo(() => {
    return getHasPrescriptionCapabilities(allActiveIntegrations);
  }, [allActiveIntegrations]);

  useEffect(() => {
    // Needed to let data populate fully after switching clinics
    setTimeout(() => {
        getMostRecentSyncData();
    }, 2000);
  }, [getMostRecentSyncData]);

  return (
    <IntegrationsContext.Provider
      value={{
        syncStatus: {
          hasSyncOutage,
          currentStatus: syncStatus,
          lastSyncTime,
          isSyncPaused,
          primaryIntegration,
          timezoneName: currentClinic?.timezoneName,
        },
        allIntegrations,
        allActiveIntegrations,
        primaryIntegration,
        hasAppointmentCreateWritebackCapability,
        primaryIntegrationName: primaryIntegration?.displayName as IntegrationSystemDisplayNameType,
        isInvoicePreviewSupported: primaryIntegration?.isInvoicePreviewSupported || false,
        isTriggerPimsSyncSupported: primaryIntegration?.isTriggerPimsSyncSupported || false,
        isClientIdSupported: primaryIntegration?.isClientIdSupported || false,
        isExportToPimsSupported: primaryIntegration?.isExportToPimsSupported || false,
        isContactSyncSupported: primaryIntegration?.isContactSyncSupported || false,
        isInvoiceDiscoverySupported: primaryIntegration?.isInvoiceDiscoverySupported || false,
        arePostcardsSupported: primaryIntegration?.arePostcardsSupported || false,
        isBillingCodeSupported: primaryIntegration?.isBillingCodeSupported || false,
        isServiceCodeSupported: primaryIntegration?.isServiceCodeSupported || false,
        isPatientIdSupported: primaryIntegration?.isPatientIdSupported || false,
        isMergeExistingPimsProfileSupported: primaryIntegration?.isMergeExistingPimsProfileSupported || false,
        hasPrescriptionCapabilities,
      }}
    >
      {children}
    </IntegrationsContext.Provider>
  );
};
