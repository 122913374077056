import { PaymentMedium, PimsWritebackStatus, StripePaymentMethodType } from 'shared/types/graphql';
import { QUICKSIGHT_DASHBOARD_URL } from '../QuickSightReports/embedDashboard';

export const stripePaymentMethodLabels = {
  [StripePaymentMethodType.CardPresent]: 'Terminal',
  [StripePaymentMethodType.CardNotPresent]: 'Digital',
};

export const stripePaymentMediumLabels = {
  [PaymentMedium.StripeTerminal]: 'Terminal',
  [PaymentMedium.StripeVirtualTerminal]: 'Digital',
  [PaymentMedium.StripeOnline]: 'Digital',
};

export enum AuxiliaryPimsWritebackStatus {
  Disabled = 'Disabled',
  Invalid = 'Invalid',
}

export enum WritebackStatusDisplay {
  Disabled = 'Disabled',
  Invalid = 'Invalid',
  Failed = 'Failed',
  Pending = 'Pending',
  Retrying = 'Retrying',
  Success = 'Success',
}

export const writebackStatusDisplayValue = {
  [PimsWritebackStatus.Failure]: WritebackStatusDisplay.Failed,
  [PimsWritebackStatus.Pending]: WritebackStatusDisplay.Pending,
  [PimsWritebackStatus.PendingRetryAutomatic]: WritebackStatusDisplay.Retrying,
  [PimsWritebackStatus.PendingRetryManual]: WritebackStatusDisplay.Retrying,
  [PimsWritebackStatus.Success]: WritebackStatusDisplay.Success,
  [AuxiliaryPimsWritebackStatus.Disabled]: WritebackStatusDisplay.Disabled,
  [AuxiliaryPimsWritebackStatus.Invalid]: WritebackStatusDisplay.Invalid,
};

// TODO: Remove once Adobe turns CarePlans FF back on
export const careClinics = [
  'ckxz7rxc88r1s081528r7ybq3', // Adobe Animal Hospital
  'ckuq08rrt00260769t812ncws', // ImproMed Test Organization -- QA
];

// allow URLs to go directly to these reports
// other reports will redirect to the Payments Report
// TODO: [Rebranding] This will be updated in a future phase
export const allowedReportDeepLinks = [
  'televet_embed-postappointment_survey_dashboard',
  'televet_embed-clinic_performance_report',
  QUICKSIGHT_DASHBOARD_URL.Clinic_Performance,
  QUICKSIGHT_DASHBOARD_URL.CSAT_Post_Appointment_Survey,
  QUICKSIGHT_DASHBOARD_URL.NPS_Post_Appointment_Survey,
  QUICKSIGHT_DASHBOARD_URL.LapsedPetParentDashboard,
  QUICKSIGHT_DASHBOARD_URL.EmailCampaigns,
  'otto_embed-postappointment_survey_dashboard',
  'otto_embed-clinic_performance_report',
];
