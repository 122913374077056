import React, { useEffect, ReactNode, MouseEvent, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { useModal } from '@televet/televet-ui';
import {
  UiRefreshType,
  useGetClinicUserQuery,
  useSubscribeToUiRefreshSubscription,
  useClearUserInvitationMutation,
} from 'shared/types/graphql';
import useClinicUser from 'shared/hooks/useClinicUser';
import { ModalNames } from 'shared/enums/ModalNames';
import AppointmentModal from 'pages/Appointments/components/Modals/AppointmentModal';
import CreatePasswordModal from 'shared/components/CreatePasswordModal';
import { IOption } from 'shared/components/Dropdown';
import Sidebar from './components/Sidebar';
import TopToolbar from './components/TopToolbar';
import SidePanel from 'shared/components/SidePanel';
import { useAuth } from 'shared/providers/AuthProvider';
import { usePopover } from 'shared/providers/PopoverWindowProvider';
import PhoneCallPopover from 'shared/components/PhoneCallPopover';
import { PopoverNames } from 'shared/enums/PopoverNames';
import VideoCall from 'pages/VideoCall';
import useUpdateCurrentClinic from 'shared/hooks/useUpdateCurrentClinic';
import useInAppNotifications from 'shared/hooks/useInAppNotifications';
import { useHistory } from 'react-router-dom';
import { FormExportModal } from 'pages/Conversations/components/ChannelMessage/MessageBubble/Attachments/Form/FormExport/FormExportModal';
import StripeTerminalProvider from 'shared/providers/StripeTerminalProvider';
import { loadStripe } from '@stripe/stripe-js';
import { Elements as StripeElementsProvider } from '@stripe/react-stripe-js';
import PhoneDialerPopover from 'shared/components/PhoneDialerPopover';
import UnsupportedBrowserPopover from 'shared/components/UnsupportedBrowserPopover';
import useAutoPageReload from 'shared/hooks/useAutoPageReload';
import { env } from 'env';
import { Mixpanel } from 'shared/utils/mixpanel';
import MicrophoneBlockedPopover from 'shared/components/MicrophoneBlockedPopover/MicrophoneBlockedPopover';
import { Alert } from '@televet/kibble-ui/build/components/Alert';
import { Flex, useColorMode } from '@televet/kibble-ui/build/chakra';
import { useResolutionProvider } from 'shared/providers/ResolutionProvider';
import { PersistedStateKeys } from 'shared/enums/PersistedStateKeys';
import usePersistedState from 'shared/hooks/usePersistedState';
import { IntegrationsProvider } from 'shared/providers/IntegrationsProvider';
import { GraphQLFetchPolicies } from 'shared/enums';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { updateCurrentClinicState } from 'state/currentClinic/currentClinicSlice';
import ChannelOverlay from './components/ChannelOverlay';
import ExportOrDownloadChannelModal from 'pages/Conversations/components/Modals/ExportOrDownloadChannel';

interface IProps {
  children: ReactNode;
}

const stripe = loadStripe(env.REACT_APP_STRIPE_API_KEY || '');
const disableAutoReloadPopovers: string[] = [PopoverNames.PhoneDialer, PopoverNames.PhoneCall, PopoverNames.VideoCall];

const DefaultAuthenticated = ({ children }: IProps): JSX.Element => {
  const { logout, isImpersonating } = useAuth();
  const history = useHistory();
  const { modal, openModal } = useModal();
  const { popover, closePopover } = usePopover();
  const { clinicUser, currentClinicId, currentClinic } = useClinicUser();

  const { updateCurrentClinic } = useUpdateCurrentClinic();
  const [suggestRefreshMessage, setSuggestRefreshMessage] = useState<string>();
  const [, setNewClinicId] = usePersistedState(PersistedStateKeys.NewClinic, { id: '' });
  const { toggleColorMode, colorMode } = useColorMode();
  const [isReaderModeEnabled] = usePersistedState(PersistedStateKeys.IsReaderModeEnabled, false);

  useEffect(() => {
    if (isReaderModeEnabled && colorMode !== 'dark') {
      toggleColorMode();
    }

    if (!isReaderModeEnabled && colorMode === 'dark') {
      toggleColorMode();
    }
  }, [isReaderModeEnabled, colorMode, toggleColorMode]);

  const { isMobile } = useResolutionProvider();
  const refreshPage = (): void => {
    Mixpanel.track('UI Refreshed from Notification', { currentClinicId });
    window.location.reload();
  };

  useSubscribeToUiRefreshSubscription({
    onData: ({ data }) => {
      if (data.data?.uiRefresh) {
        const { refreshType, message } = data.data?.uiRefresh;

        if (refreshType === UiRefreshType.HardRefresh) {
          const randomMilliseconds = Math.floor(Math.random() * 240 * 1000) + 1;
          setTimeout(() => {
            refreshPage();
          }, randomMilliseconds);
        } else {
          if (message) {
            setSuggestRefreshMessage(message);
          }
        }
      }
    },
  });

  useInAppNotifications();

  const isAutoReloadDisabled = useMemo(
    () => !!(popover?.name && disableAutoReloadPopovers.includes(popover.name)),
    [popover?.name],
  );
  useAutoPageReload({ history, isDisabled: isAutoReloadDisabled });

  const [clearUserInvitation] = useClearUserInvitationMutation();
  const dispatch = useAppDispatch();
  const impersonationClinicId = useAppSelector((state) => state.currentClinic.clinicId);
  const channleViewState = useAppSelector((state) => state.conversations.channelViewData);

  useGetClinicUserQuery({
    fetchPolicy: GraphQLFetchPolicies.CacheFirst,
    onCompleted: (data) => {
      const currentClinicId = data.clinicUser?.vetInfo?.currentClinic?.id;
      if (currentClinicId && !(isImpersonating && impersonationClinicId)) {
        dispatch(updateCurrentClinicState({ clinicId: currentClinicId }));
      }

      /**
       * Ensure the user's invitation is moved out of "pending" state once
       * they log in, since they may have reached the app from an origin
       * other than their invitation email.
       */
      if (!!data.clinicUser?.invitationCode) {
        clearUserInvitation({
          variables: { userId: data.clinicUser.id },
          optimisticResponse: { updateOneUser: { __typename: 'User', id: data.clinicUser.id, invitationCode: null } },
        });
      }
    },
  });

  useEffect(() => {
    if (clinicUser?.isNewPasswordRequired) {
      openModal(ModalNames.CreatePassword);
    }
  }, [clinicUser, openModal]);

  const onClinicSelected = async (e: MouseEvent, option: IOption): Promise<void> => {
    if (!JSON.parse(localStorage.getItem(PersistedStateKeys.HasUnsavedSettingsChanges) || 'false')) {
      await updateCurrentClinic(option.value);
      const [redirectTo] = history.location.pathname.match(/^\/(settings.*|[a-z]+)\/?/) || [];
      if (redirectTo) {
        history.push(redirectTo);
      }
    } else {
      setNewClinicId({ id: option.value });
    }
  };

  const impersonationBannerText = useMemo(() => {
    return `Logged in as: ${clinicUser?.displayName} (${clinicUser?.email}) at ${currentClinic?.name}`;
  }, [clinicUser, currentClinic]);

  return (
    <IntegrationsProvider>
      <StripeTerminalProvider>
        <StripeElementsProvider stripe={stripe}>
          <Flex flex="0 0 auto" direction="column" background="background.default">
            {isImpersonating && clinicUser && (
              <Alert
                status="warning"
                hideIcon
                hideCloseButton
                title={impersonationBannerText}
                titleProps={{
                  textAlign: 'center',
                }}
                buttonProps={{
                  buttonText: 'Log Out',
                  buttonPosition: 'topRight',
                  onClick: (): void => {
                    logout();
                  },
                }}
              />
            )}
            {!!suggestRefreshMessage && clinicUser && (
              <Alert
                status="warning"
                hideCloseButton
                title={suggestRefreshMessage}
                titleProps={{
                  textAlign: 'center',
                }}
                buttonProps={{
                  buttonText: 'Refresh Page',
                  iconName: 'doubleArrowCircle',
                  buttonPosition: 'topRight',
                  onClick: refreshPage,
                }}
              />
            )}
          </Flex>
          <Flex
            overflow="hidden"
            className="Layout__Container"
            flexDirection={isMobile ? 'column-reverse' : 'row'}
            h="100%"
          >
            <Sidebar onClinicSelected={onClinicSelected} />
            <Flex className="Layout__Workspace" background="background.subtle" overflow="hidden" flex="1 1 auto">
              <Flex overflow="hidden" flex="1 1 auto" flexDirection="column">
                <TopToolbar clinicId={currentClinicId} />
                {children}
              </Flex>

              {channleViewState.isOpen && <ChannelOverlay />}

              <SidePanel />

              {modal?.name === ModalNames.Appointment && (
                <AppointmentModal
                  startDate={modal.data?.startDate}
                  durationInMinutes={modal.data?.durationInMinutes}
                  defaultAppointmentDurationInMinutes={modal.data?.defaultAppointmentDurationInMinutes}
                  appointmentId={modal.data?.appointmentId}
                />
              )}

              {modal?.name === ModalNames.FormExport && modal?.data && (
                <FormExportModal form={modal?.data?.form} pets={modal?.data?.pets} petParent={modal?.data?.petParent} />
              )}
              {modal?.name === ModalNames.CreatePassword && <CreatePasswordModal />}

              {popover?.name === PopoverNames.PhoneDialer && (
                <PhoneDialerPopover
                  startTop={popover?.data?.startTop}
                  startLeft={popover?.data?.startLeft}
                  closePopover={closePopover}
                />
              )}

              {popover?.name === PopoverNames.PhoneCall && (
                <PhoneCallPopover
                  clinicPetParent={popover?.data?.clinicPetParent}
                  phoneNumber={popover?.data?.phoneNumber}
                  isCallWithRecording={popover?.data?.isCallWithRecording}
                  closePopover={closePopover}
                />
              )}

              {popover?.name === PopoverNames.VideoCall && (
                <VideoCall
                  channelId={popover?.data?.channelId}
                  startTop={popover?.data?.startTop}
                  clinicPetParentId={popover?.data?.clinicPetParentId}
                  startLeft={popover?.data?.startLeft}
                />
              )}

              {popover?.name === PopoverNames.UnsupportedBrowser && (
                <UnsupportedBrowserPopover
                  startTop={popover?.data?.startTop}
                  startLeft={popover?.data?.startLeft}
                  closePopover={closePopover}
                />
              )}

              {popover?.name === PopoverNames.MicrophoneBlocked && (
                <MicrophoneBlockedPopover
                  startTop={popover?.data?.startTop}
                  startLeft={popover?.data?.startLeft}
                  closePopover={closePopover}
                />
              )}

              <ExportOrDownloadChannelModal />
            </Flex>
          </Flex>
        </StripeElementsProvider>
      </StripeTerminalProvider>
    </IntegrationsProvider>
  );
};

export default DefaultAuthenticated;

// TODO: update layouts to be kibble
export const ContentLayout = styled.div`
  flex: 1 1 auto;
  overflow: auto;
  width: 100%;
`;
