import { useEffect, useMemo, useCallback, useState } from 'react';
import * as Sentry from '@sentry/react';
import { Reference } from '@apollo/client';
import { startOfDay, endOfDay, parseISO, addDays, isSameDay, formatISO } from 'date-fns';
import { useSnackbar, MessageTypes } from '@televet/televet-ui';
import {
  Appointment,
  ClinicPimsIntegrationType,
  useFindManyUserAppointmentGroupingQuery,
  UserAppointmentGroupingFullSelectionFragment,
  ClinicRoom,
  AppointmentType,
  ClinicEmployee,
  useCreateOneUserAppointmentGroupingMutation,
  UserAppointmentGroupingFullSelectionFragmentDoc,
  UserAppointmentGroupingCreateInput,
  useUpdateOneUserAppointmentGroupingMutation,
  UserAppointmentGroupingUpdateInput,
  useDeleteOneUserAppointmentGroupingMutation,
  useFindManyAppointmentQuery,
  AppointmentWhereInput,
  SortOrder,
  useCreateOneAppointmentMutation,
  useUpdateOneAppointmentMutation,
  AppointmentCreateInput,
  AppointmentUpdateInput,
  AppointmentStatus,
  AppointmentFullSelectionFragment,
  FindManyAppointmentDocument,
  useGetAppointmentFiltersQuery,
  useGetUserAppointmentGroupingsFiltersQuery,
  AppointmentOrderByWithRelationAndSearchRelevanceInput,
} from 'shared/types/graphql';
import {
  getPersistedAppointmentFilters,
  setPersistedAppointmentFilters,
} from 'shared/providers/ApolloProvider/reactiveVars/appointmentFilters';
import {
  getPersistedUserAppointmentGroupingsFilters,
  setPersistedUserAppointmentGroupingsFilters,
} from 'shared/providers/ApolloProvider/reactiveVars/appointmentGroupingFilters';
import { GraphQLErrorPolicies } from 'shared/enums/GraphQLErrorPolicies';
import { AppointmentFilters } from 'shared/providers/ApolloProvider/reactiveVars/appointmentFilters';
import { ExtendedAppointment } from 'pages/Appointments';
import { ScheduleTypes } from 'pages/Appointments/components/Modals/AppointmentModal';
import { DEFAULT_APPOINTMENT_DURATION_IN_MINUTES } from 'pages/Appointments';
import { useIntegrationsProvider } from 'shared/providers/IntegrationsProvider';
import useClinicUser from 'shared/hooks/useClinicUser';
import { GraphQLFetchPolicies } from 'shared/enums';
import { DEFAULT_POLL_INTERVAL } from 'shared/constants';

export interface GroupedAppointment {
  id: string;
  label: string;
  type: ScheduleTypes;
  appointments: Record<string, ExtendedAppointment[]>;
}

/**
 * Get a date string with time to use as a key in Record<string, T>
 * Remove seconds and milliseconds
 * @param startAt string
 */
const getDateKey = (startAt: string): string => {
  const date = parseISO(startAt);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date.toISOString();
};

/**
 * Filter appointments by UserAppointmentGrouping
 * @param rooms ClinicRoom[]
 * @param appointmentTypes AppointmentType[]
 * @param clinicEmployees ClinicEmployee[]
 * @param appointments ExtendedAppointment
 */
const getFilteredAppointmentsInUserAppointmentGroupings = (
  rooms: Pick<ClinicRoom, 'id'>[],
  appointmentTypes: Pick<AppointmentType, 'id'>[],
  clinicEmployees: Pick<ClinicEmployee, 'id'>[],
  appointments: ExtendedAppointment[],
): ExtendedAppointment[] => {
  return appointments.filter((appointment: ExtendedAppointment) => {
    const hasRoom = rooms.some((r) => r.id === appointment?.room?.id);
    if (hasRoom) return hasRoom;

    const hasAppointmentType = appointmentTypes.some((at) => at.id === appointment?.appointmentType?.id);
    if (hasAppointmentType) return hasAppointmentType;
    const hasClinicEmployees = clinicEmployees?.some((c) => appointment.clinicEmployees?.some((ce) => ce.id === c.id));
    return hasClinicEmployees;
  });
};

/**
 * Get appointments grouped by
 * @param userAppointmentGroupingId string
 * @param appointments ExtendedAppointment[]
 */
const getGroupedAppointments = (
  userAppointmentGroupingId: string,
  appointments: ExtendedAppointment[],
): Record<string, ExtendedAppointment[]> => {
  const result = appointments.reduce(
    (result: Record<string, ExtendedAppointment[]>, currentValue: ExtendedAppointment) => {
      const key = getDateKey(currentValue.startAt);
      (result[key] = result[key] || []).push({ ...currentValue, userAppointmentGroupingId });
      return result;
    },
    {} as Record<string, ExtendedAppointment[]>,
  );

  return result;
};

/**
 * Get GroupedAppointment[] from userAppointmentGroupings and appointments
 * @param userAppointmentGroupings UserAppointmentGrouping[]
 * @param appointments ExtendedAppointment[]
 */
const getUserAppointmentGroupings = (
  userAppointmentGroupings: UserAppointmentGroupingFullSelectionFragment[],
  appointments: ExtendedAppointment[],
  userAppointmentGroupingsFilters: Record<string, string[]>,
  clinicId: string,
): GroupedAppointment[] => {
  const userAppointmentGroupingsExtended: UserAppointmentGroupingFullSelectionFragment[] = [
    ...userAppointmentGroupings,
  ];
  // Apply the filter options to userAppointmentGroupings if available
  const filteredUserAppointmentGroupings = !userAppointmentGroupingsFilters[clinicId]
    ? userAppointmentGroupingsExtended
    : userAppointmentGroupingsExtended.filter((s) => !userAppointmentGroupingsFilters[clinicId].includes(s.id));

  const result = filteredUserAppointmentGroupings.map(
    (userAppointmentGrouping: UserAppointmentGroupingFullSelectionFragment): GroupedAppointment => {
      const { rooms, appointmentTypes, clinicEmployees } = userAppointmentGrouping;
      const filteredAppointments = getFilteredAppointmentsInUserAppointmentGroupings(
        rooms || [],
        appointmentTypes || [],
        clinicEmployees || [],
        appointments,
      );
      return {
        id: userAppointmentGrouping.id,
        label: userAppointmentGrouping.label,
        type: ScheduleTypes.UserGrouping,
        appointments: getGroupedAppointments(userAppointmentGrouping.id, filteredAppointments),
      };
    },
  );

  // Filter out the all column if in userAppointmentGroupingsFilters
  if (!userAppointmentGroupingsFilters[clinicId]?.includes('all')) {
    result.unshift({
      id: 'all',
      label: 'All',
      type: ScheduleTypes.UserGrouping,
      appointments: getGroupedAppointments('all', appointments),
    });
  }

  return result;
};

/**
 * Get appointment query variables for GET_CLINIC_APPOINTMENTS
 * Used in getAppointment and createAppointmentMutation
 * @param clinicId
 * @param appointmentFilters
 * @param hideAppointmentsWithoutPetOwner
 */
const getAppointmentVariables = (
  appointmentFilters: AppointmentFilters,
  clinicId?: string,
  clinicIntegrationType?: ClinicPimsIntegrationType | null,
  hideAppointmentsWithoutPetOwner?: boolean,
): { where: AppointmentWhereInput; orderBy: AppointmentOrderByWithRelationAndSearchRelevanceInput } => {
  // Hide inactive appointments from EZYVet
  const active = {} as { isActive?: { equals: boolean } };
  if (clinicIntegrationType === ClinicPimsIntegrationType.Ezyvet) {
    active.isActive = { equals: true };
  }
  const where: AppointmentWhereInput = {
    clinic: {
      id: { equals: clinicId },
    },
    startAt: { gte: startOfDay(appointmentFilters.currentDay), lte: endOfDay(appointmentFilters.currentDay) },
    isDeleted: { equals: false },
    ...active,
  };

  // Check setting to exclude null pet parents
  if (hideAppointmentsWithoutPetOwner) {
    where.clinicPetParents = {
      some: { id: { not: undefined } },
    };
  }
  return {
    where,
    orderBy: { startAt: SortOrder.Asc },
  };
};

interface IUseAppointmentGroupingQueryResults {
  groupedAppointments: GroupedAppointment[];
  appointments: ExtendedAppointment[];
  appointmentsLoading: boolean;
  userAppointmentGroupings: UserAppointmentGroupingFullSelectionFragment[];
  appointmentFilters: AppointmentFilters;
  userAppointmentGroupingsFilters: Record<string, string[]>;
  syncAppointments: boolean;
}

interface IUseAppointmentGroupingCommands {
  createUserAppointmentGrouping(
    data: UserAppointmentGroupingCreateInput,
  ): Promise<UserAppointmentGroupingFullSelectionFragment | undefined>;
  deleteUserAppointmentGrouping(id: string): Promise<UserAppointmentGroupingFullSelectionFragment | undefined | null>;
  updateUserAppointmentGrouping(
    id: string,
    data: UserAppointmentGroupingUpdateInput,
  ): Promise<UserAppointmentGroupingFullSelectionFragment | undefined>;
  createAppointment(data: AppointmentCreateInput): Promise<AppointmentFullSelectionFragment | undefined>;
  updateAppointment(id: string, data: AppointmentUpdateInput): Promise<AppointmentFullSelectionFragment | undefined>;
  cancelAppointment(id: string): Promise<AppointmentFullSelectionFragment | undefined>;
  updateCurrentDay: (date: Date) => void;
  updateAppointmentFilterDateRange: (startDate: Date, endDate: Date) => void;
  goToToday: () => void;
  updateUserAppointmentGroupingFilters: (clinicId: string, userAppointmentGroupingId: string) => void;
  setSyncAppointments: (sync: boolean) => void;
}

interface IUseAppointmentGroupingUtils {
  getFilteredAppointmentsInUserAppointmentGroupings: (
    rooms: Pick<ClinicRoom, 'id'>[],
    appointmentTypes: Pick<AppointmentType, 'id'>[],
    clinicEmployees: Pick<ClinicEmployee, 'id'>[],
    appointments: ExtendedAppointment[],
  ) => ExtendedAppointment[];
}
/*****************************************************************************
 * ***************************************************************************
 * Contains all commands and filters used to manipulate appointment schedule
 * ***************************************************************************
 * ***************************************************************************
 */
const useAppointmentGrouping = (): [
  IUseAppointmentGroupingQueryResults,
  IUseAppointmentGroupingCommands,
  IUseAppointmentGroupingUtils,
] => {
  const { addSnackbar } = useSnackbar();
  const { currentClinicId, currentClinic } = useClinicUser();
  const [syncAppointments, setSyncAppointments] = useState(false);
  const { primaryIntegration } = useIntegrationsProvider();
  const clinicIntegrationType = primaryIntegration?.type;
  const hideAppointmentsWithoutPetOwner = currentClinic?.clinicSetting?.hideAppointmentsWithoutPetOwner;

  const { data: appointmentFiltersData } = useGetAppointmentFiltersQuery();

  /**
   * clinicId needs to be here so that apollo knows it should re-render on login.
   * Because this is persisted, the cache doesn't understand that that reactiveVar should be
   * re-initialized
   */
  const { data: userAppointmentGroupingsFiltersData } = useGetUserAppointmentGroupingsFiltersQuery({
    variables: { clinicId: currentClinicId || '' },
    skip: !currentClinicId,
  });

  const {
    data: userAppointmentGroupingData,
    refetch: refetchAppointmentGroupingsData,
    error: userAppointmentGroupingError,
  } = useFindManyUserAppointmentGroupingQuery({
    skip: !currentClinicId,
    variables: {
      where: {
        clinic: {
          id: { equals: currentClinicId },
        },
      },
      orderBy: { createdAt: SortOrder.Asc },
    },
    errorPolicy: GraphQLErrorPolicies.All,
  });
  /**
   * Normalize userAppointmentFilters
   */
  const appointmentFiltersMemo = useMemo((): AppointmentFilters => {
    return appointmentFiltersData?.appointmentFilters || getPersistedAppointmentFilters();
  }, [appointmentFiltersData]);

  const getAppointmentFilters = useMemo(() => {
    return getAppointmentVariables(
      appointmentFiltersMemo,
      currentClinicId,
      clinicIntegrationType,
      hideAppointmentsWithoutPetOwner,
    );
  }, [appointmentFiltersMemo, currentClinicId, clinicIntegrationType, hideAppointmentsWithoutPetOwner]);

  const {
    data: appointmentsData,
    error: appointmentsError,
    loading: appointmentsLoading,
  } = useFindManyAppointmentQuery({
    variables: getAppointmentFilters,
    pollInterval: DEFAULT_POLL_INTERVAL,
    errorPolicy: GraphQLErrorPolicies.All,
    fetchPolicy: GraphQLFetchPolicies.CacheAndNetwork,
    skip: !currentClinicId || !appointmentFiltersMemo,
  });

  /**
   * Normalize userAppointmentGroupingsFilters
   */
  const userAppointmentGroupingsFiltersMemo = useMemo((): Record<string, string[]> => {
    return (
      userAppointmentGroupingsFiltersData?.userAppointmentGroupingsFilters ||
      getPersistedUserAppointmentGroupingsFilters()
    );
  }, [userAppointmentGroupingsFiltersData]);
  const [createUserAppointmentGroupingMutation] = useCreateOneUserAppointmentGroupingMutation({
    errorPolicy: GraphQLErrorPolicies.All,
    update(cache, { data }) {
      cache.modify({
        fields: {
          findManyUserAppointmentGrouping(existingUserAppointmentGroupings = []): Reference[] {
            if (!data?.createOneUserAppointmentGrouping) return existingUserAppointmentGroupings;
            const newUserAppointmentGroupingsRef = cache.writeFragment({
              data: data.createOneUserAppointmentGrouping,
              fragment: UserAppointmentGroupingFullSelectionFragmentDoc,
            });
            return [...existingUserAppointmentGroupings, newUserAppointmentGroupingsRef];
          },
        },
      });
    },
  });

  const [updateUserAppointmentGroupingMutation] = useUpdateOneUserAppointmentGroupingMutation({
    errorPolicy: GraphQLErrorPolicies.All,
  });
  const [deleteUserAppointmentGroupingMutation] = useDeleteOneUserAppointmentGroupingMutation({
    errorPolicy: GraphQLErrorPolicies.All,
    update(cache, { data }) {
      cache.modify({
        fields: {
          findManyUserAppointmentGrouping(existingUserAppointmentGroupings = []): Reference[] {
            if (!data?.deleteOneUserAppointmentGrouping) return existingUserAppointmentGroupings;
            const newUserAppointmentGroupingsRef = cache.writeFragment({
              data: data.deleteOneUserAppointmentGrouping,
              fragment: UserAppointmentGroupingFullSelectionFragmentDoc,
            });

            const userAppointmentGroupingRefs = existingUserAppointmentGroupings.filter(
              (u: Reference) => u.__ref !== newUserAppointmentGroupingsRef?.__ref,
            );

            return userAppointmentGroupingRefs;
          },
        },
      });
    },
  });

  const [updateAppointmentMutation] = useUpdateOneAppointmentMutation({
    update(cache, { data }) {
      if (!data?.updateOneAppointment) return;

      const { updateOneAppointment } = data;
      if (!updateOneAppointment) return;
      const variables = getAppointmentVariables(
        appointmentFiltersMemo,
        currentClinicId,
        clinicIntegrationType,
        hideAppointmentsWithoutPetOwner,
      );
      const updatedStartAt = parseISO(updateOneAppointment.startAt);
      function removeFromCache(): void {
        const currentDayCacheData = cache.readQuery<{ findManyAppointment: Appointment[] }>({
          query: FindManyAppointmentDocument,
          variables,
        });
        const currentDayUpdatedCache = currentDayCacheData?.findManyAppointment.filter(
          (a) => a.id !== updateOneAppointment.id,
        );
        cache.writeQuery({
          query: FindManyAppointmentDocument,
          variables,
          data: { findManyAppointment: currentDayUpdatedCache },
        });
      }
      if (data.updateOneAppointment.isDeleted) {
        removeFromCache();
        return;
      }
      /**
       * Only update the cache directly when the date of the appointment has changed.
       * Apollo does not invalidate the cache and respect the date filter of the query
       */
      if (!isSameDay(updatedStartAt, appointmentFiltersMemo.currentDay)) {
        // Remove appointment from root query for this day
        removeFromCache();

        // Write the updated value to the proper slice of the root query cache
        const updatedDayVariable = {
          where: {
            ...variables.where,
            startAt: { gte: formatISO(startOfDay(updatedStartAt)), lte: formatISO(endOfDay(updatedStartAt)) },
          },
          orderBy: variables.orderBy,
        };

        const updatedDayCacheData = cache.readQuery<{ findManyAppointment: Appointment[] }>({
          query: FindManyAppointmentDocument,
          variables: updatedDayVariable,
        });

        const updatedDayUpdatedCache = [...(updatedDayCacheData?.findManyAppointment || []), updateOneAppointment];
        cache.writeQuery({
          query: FindManyAppointmentDocument,
          variables: updatedDayVariable,
          data: { findManyAppointment: updatedDayUpdatedCache },
        });
      }
    },
  });

  /**
   * The variables on getAppointments and update must be
   * the same to properly update the cache
   */
  const [createAppointmentMutation] = useCreateOneAppointmentMutation({
    update(cache, { data }) {
      const variables = getAppointmentVariables(
        appointmentFiltersMemo,
        currentClinicId,
        clinicIntegrationType,
        hideAppointmentsWithoutPetOwner,
      );

      const cacheData = cache.readQuery<{ findManyAppointment: Appointment[] }>({
        query: FindManyAppointmentDocument,
        variables,
      });

      if (cacheData && data?.createOneAppointment) {
        const updatedCache = [...cacheData.findManyAppointment, data.createOneAppointment];
        cache.writeQuery({
          query: FindManyAppointmentDocument,
          variables,
          data: { findManyAppointment: updatedCache },
        });
      }
    },
  });

  /**
   * Update the filters with the selected clinic's settings and
   * refetch userAppointmentGroupings when clinicId changes
   */
  useEffect(() => {
    if (!currentClinicId) return;
    const filters = getPersistedAppointmentFilters();
    setPersistedAppointmentFilters({
      ...filters,
      defaultAppointmentDurationInMinutes:
        currentClinic?.clinicSetting?.appointmentTimeSlotIncrementInMinutes || DEFAULT_APPOINTMENT_DURATION_IN_MINUTES,
    });
    refetchAppointmentGroupingsData();
  }, [currentClinicId, currentClinic, refetchAppointmentGroupingsData]);

  /**
   * Handle all query errors
   */
  useEffect(() => {
    if (userAppointmentGroupingError) {
      Sentry.captureException(userAppointmentGroupingError);
      addSnackbar({
        type: MessageTypes.Error,
        message: 'Oops, an error occurred while getting your appointment filters.',
      });
      console.error(userAppointmentGroupingError);
    }

    if (appointmentsError) {
      Sentry.captureException(appointmentsError);
      addSnackbar({
        type: MessageTypes.Error,
        message: 'Oops, an error occurred while getting your appointments.',
      });
      console.error(appointmentsError);
    }
  }, [addSnackbar, userAppointmentGroupingError, appointmentsError]);

  /**
   * Group the appointments as GroupedAppointment[]
   */
  const groupedAppointments = useMemo(() => {
    const userAppointmentGroupings = userAppointmentGroupingData?.findManyUserAppointmentGrouping;
    const appointments = appointmentsData?.findManyAppointment;
    if (userAppointmentGroupings && appointments && userAppointmentGroupingsFiltersMemo && currentClinicId) {
      return getUserAppointmentGroupings(
        userAppointmentGroupings,
        appointments,
        userAppointmentGroupingsFiltersMemo,
        currentClinicId,
      );
    }
    return [];
  }, [appointmentsData, userAppointmentGroupingData, userAppointmentGroupingsFiltersMemo, currentClinicId]);

  /**
   * Update the current day
   */
  const updateCurrentDay = useCallback((date: Date) => {
    const filters = getPersistedAppointmentFilters();
    setPersistedAppointmentFilters({
      ...filters,
      currentDay: date,
    });
  }, []);

  /**
   * Update the date range filter
   */
  const updateAppointmentFilterDateRange = useCallback((startDate: Date, endDate: Date) => {
    const filters = getPersistedAppointmentFilters();
    setPersistedAppointmentFilters({
      ...filters,
      startDate,
      endDate,
      currentDay: startDate,
    });
  }, []);

  /**
   * Set filters to today
   */
  const goToToday = useCallback(() => {
    const filters = getPersistedAppointmentFilters();
    const today = startOfDay(Date.now());
    const endDate = addDays(new Date(), filters.daysAheadInterval);
    setPersistedAppointmentFilters({
      ...filters,
      currentDay: today,
      startDate: today,
      endDate,
    });
  }, []);

  /**
   * Update the appointment grouping filters for the clinic
   */
  const updateUserAppointmentGroupingFilters = useCallback((clinicId: string, userAppointmentGroupingId: string) => {
    const filters = { ...getPersistedUserAppointmentGroupingsFilters() };
    if (filters[clinicId] && filters[clinicId].includes(userAppointmentGroupingId)) {
      // remove a filter
      filters[clinicId] = filters[clinicId].filter((id) => id !== userAppointmentGroupingId);
    } else {
      // Add a filter
      filters[clinicId] =
        filters[clinicId] && filters[clinicId].length
          ? [...filters[clinicId], userAppointmentGroupingId]
          : [userAppointmentGroupingId];
    }
    setPersistedUserAppointmentGroupingsFilters(filters);
  }, []);

  /**
   * Create a user appointment grouping
   */
  const createUserAppointmentGrouping = useCallback(
    async (data: UserAppointmentGroupingCreateInput) => {
      try {
        const result = await createUserAppointmentGroupingMutation({ variables: { data } });
        return result.data?.createOneUserAppointmentGrouping;
      } catch (e) {
        Sentry.captureException(e);
        addSnackbar({
          type: MessageTypes.Error,
          message: 'Oops, an error occurred while creating your appointment filter.',
        });
        console.error(e);
      }
    },
    [addSnackbar, createUserAppointmentGroupingMutation],
  );

  /**
   * Delete a user appointment grouping
   */
  const deleteUserAppointmentGrouping = useCallback(
    async (id: string) => {
      try {
        const result = await deleteUserAppointmentGroupingMutation({
          variables: {
            where: {
              id,
            },
          },
        });
        return result.data?.deleteOneUserAppointmentGrouping;
      } catch (e) {
        Sentry.captureException(e);
        addSnackbar({
          type: MessageTypes.Error,
          message: 'Oops, an error occurred while deleting your appointment filter.',
        });
        console.error(e);
      }
    },
    [addSnackbar, deleteUserAppointmentGroupingMutation],
  );

  /**
   * Update a user appointment grouping
   */
  const updateUserAppointmentGrouping = useCallback(
    async (id: string, data: UserAppointmentGroupingUpdateInput) => {
      try {
        const result = await updateUserAppointmentGroupingMutation({
          variables: {
            data,
            where: {
              id,
            },
          },
        });
        return result?.data?.updateOneUserAppointmentGrouping;
      } catch (e) {
        Sentry.captureException(e);
        addSnackbar({
          type: MessageTypes.Error,
          message: 'Oops, an error occurred while updating your appointment filters.',
        });
        console.error(e);
      }
    },
    [addSnackbar, updateUserAppointmentGroupingMutation],
  );

  const createAppointment = useCallback(
    async (data: AppointmentCreateInput) => {
      try {
        const result = await createAppointmentMutation({
          variables: {
            data,
          },
        });
        return result?.data?.createOneAppointment;
      } catch (e) {
        Sentry.captureException(e);
        addSnackbar({
          type: MessageTypes.Error,
          message: 'Oops, an error occurred while creating your appointment.',
        });
        console.error(e);
      }
    },
    [addSnackbar, createAppointmentMutation],
  );

  const updateAppointment = useCallback(
    async (id: string, data: AppointmentUpdateInput) => {
      try {
        const result = await updateAppointmentMutation({
          variables: {
            where: {
              id,
            },
            data,
          },
        });
        return result?.data?.updateOneAppointment;
      } catch (e) {
        Sentry.captureException(e);
        addSnackbar({
          type: MessageTypes.Error,
          message: 'Oops, an error occurred while updating your appointment.',
        });
        console.error(e);
      }
    },
    [addSnackbar, updateAppointmentMutation],
  );

  const cancelAppointment = useCallback(
    async (id: string) => {
      try {
        const result = await updateAppointmentMutation({
          variables: {
            where: { id },
            data: {
              status: AppointmentStatus.Cancelled,
              isDeleted: true,
            },
          },
        });
        return result?.data?.updateOneAppointment;
      } catch (e) {
        Sentry.captureException(e);
        addSnackbar({
          type: MessageTypes.Error,
          message: 'Oops, an error occurred while cancelling your appointment.',
        });
        console.error(e);
      }
    },
    [addSnackbar, updateAppointmentMutation],
  );

  return [
    {
      groupedAppointments,
      appointments: appointmentsData?.findManyAppointment || [],
      appointmentsLoading,
      userAppointmentGroupings: userAppointmentGroupingData?.findManyUserAppointmentGrouping || [],
      appointmentFilters: appointmentFiltersMemo,
      userAppointmentGroupingsFilters: userAppointmentGroupingsFiltersMemo,
      syncAppointments,
    },
    {
      createUserAppointmentGrouping,
      deleteUserAppointmentGrouping,
      updateUserAppointmentGrouping,
      createAppointment,
      updateAppointment,
      cancelAppointment,
      updateCurrentDay,
      updateAppointmentFilterDateRange,
      goToToday,
      updateUserAppointmentGroupingFilters,
      setSyncAppointments,
    },
    {
      getFilteredAppointmentsInUserAppointmentGroupings,
    },
  ];
};

export default useAppointmentGrouping;
