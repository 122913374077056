export const formatCurrency = (amount: number, showDecimals?: boolean): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: showDecimals ? 2 : 0,
    maximumFractionDigits: showDecimals ? 2 : 0,
  });

  return formatter.format(amount);
};

export const centsToDollars = (amount: number): number => {
  return amount / 100;
};

export const dollarsToCents = (amount: number): number => {
  return Math.ceil(amount * 100);
};

export const roundTo = (amount: number, precision: number): number => {
  const factor = Math.pow(10, precision);
  return Math.round(amount * factor) / factor;
};
