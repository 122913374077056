import React, { MouseEvent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { defaultSettings } from './config/defaultSettings';
import { ExtendedAppointment } from 'pages/Appointments';
import { ISettings } from './interfaces/ISettings';
import GridSchedulerContent from './components/GridSchedulerContent';
import HourlyAppointments from './components/HourlyAppointments';
import TimeOfDayIndicators from './components/TimeOfDayIndicators';
import AppointmentsSchedule from './components/AppointmentsSchedule';

interface IDaySchedulerProps {
  currentDay: Date;
  appointments: ExtendedAppointment[];
  selectedAppointmentId: string | null;
  scheduleTimeBlockInMinutes: number;
  onAppointmentClicked?: (e: MouseEvent, appointment: ExtendedAppointment) => void;
  onDayClicked?: (e: MouseEvent, startDate: Date, durationInMinutes: number) => void;
  settings?: ISettings;
}

const DayScheduler = ({
  currentDay,
  appointments,
  selectedAppointmentId,
  scheduleTimeBlockInMinutes,
  onAppointmentClicked: onAppointmentClickedProp,
  onDayClicked: onDayClickedProp,
  settings = defaultSettings,
}: IDaySchedulerProps): JSX.Element => {
  const onAppointmentClicked = (e: MouseEvent, appointment: ExtendedAppointment): void => {
    if (onAppointmentClickedProp) onAppointmentClickedProp(e, appointment);
  };

  const onDayClicked = (e: MouseEvent, startDate: Date, durationInMinutes: number): void => {
    if (onDayClickedProp) onDayClickedProp(e, startDate, durationInMinutes);
  };

  return (
    <DaySchedulerContainer>
      <GridSchedulerContent>
        <TimeOfDayIndicators />
        <AppointmentsSchedule>
          <HourlyAppointments
            appointments={appointments}
            selectedAppointmentId={selectedAppointmentId}
            scheduleTimeBlockInMinutes={scheduleTimeBlockInMinutes}
            onAppointmentClicked={onAppointmentClicked}
            onDayClicked={onDayClicked}
            currentDay={currentDay}
            settings={settings}
          />
        </AppointmentsSchedule>
      </GridSchedulerContent>
    </DaySchedulerContainer>
  );
};

DayScheduler.propTypes = {
  currentDay: PropTypes.object.isRequired,
  appointments: PropTypes.array.isRequired,
  onAppointmentClicked: PropTypes.func,
  onDayChanged: PropTypes.func,
  onDayDoubleClicked: PropTypes.func,
  settings: PropTypes.object,
};

export default DayScheduler;

const DaySchedulerContainer = styled.div`
  display: block;
`;
