import { Box } from '@televet/kibble-ui/build/chakra';
import { Icon } from '@televet/kibble-ui/build/components/Icon';
import { Text } from '@televet/kibble-ui/build/components/Text';
import React from 'react';

interface CareGoalProgressBarMarkerProps {
  value: number;
  position: { top: string } | { bottom: string };
  isActive: boolean;
}

export const CareGoalProgressBarMarker = ({
  value,
  position,
  isActive,
}: CareGoalProgressBarMarkerProps): JSX.Element => {
  return (
    <Box className="CareGoalProgressBarMarker" position="absolute" {...position}>
      <Box position="relative" sx={{ svg: { strokeWidth: '2', stroke: 'text.onContrast' } }}>
        <Icon size="2xl" name="starSolid" color={!isActive ? 'background.lessSubtle' : 'background.warningContrast'} />
        <Text
          size="xs"
          fontWeight="bold"
          variant={!isActive ? 'subtleOnContrast' : 'onContrast'}
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
        >
          {value}
        </Text>
      </Box>
    </Box>
  );
};
