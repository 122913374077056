import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Spinner } from '@televet/kibble-ui/build/components/Spinner';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { useModal } from '@televet/televet-ui';
import { useDisclosure, HStack, VStack } from '@televet/kibble-ui/build/chakra';
import parseISO from 'date-fns/parseISO';
import {
  AppointmentFullSelectionFragment,
  GetPaginatedAppointmentsQuery,
  GetUserAppointmentGroupingsQuery,
  PimsIntegrationCapability,
} from 'shared/types/graphql';
import PimsSync, { ErrorMessageType } from 'shared/components/PimsSync';
import { useIntegrationsProvider } from 'shared/providers/IntegrationsProvider';
import useClinicUser from 'shared/hooks/useClinicUser';
import { ModalNames } from 'shared/enums/ModalNames';
import useAppointmentCalendarCurrentDay from 'pages/Appointments/hooks/useAppointmentCalendarCurrentDay';
import { DateToolbar } from './components/Header/DateToolbar';
import { AppointmentGroup } from './components/Group';
import DeleteAppointmentGroupModal from './components/Modals/DeleteAppointmentGroupModal';
import CreateAppointmentGroupModal from './components/Modals/CreateAppointmentGroupModal';
import FilterGroupsMenu from './components/Filters/FilterGroupsMenu';
import FilterByConversationStatusMenu from './components/Filters/FilterByConversationStatusMenu';
import { useGetUserAppointmentGroupings } from './hooks/useGetUserAppointmentGroupings';

export type Appointment = NonNullable<GetPaginatedAppointmentsQuery['appointments']>[0];
export type AppointmentGrouping = NonNullable<GetUserAppointmentGroupingsQuery['userAppointmentGroupings']>[0];
export interface ExtendedAppointment extends AppointmentFullSelectionFragment {
  userAppointmentGroupingId?: string;
}

export const GROUPS_PER_PAGE = 4;
export const DEFAULT_APPOINTMENT_DURATION_IN_MINUTES = 30;
export const DEFAULT_DAYS_AHEAD_INTERVAL = 21;

const Appointments = (): JSX.Element => {
  const [selectedGroupId, setSelectedGroupId] = useState('');
  const [selectedChannelStatusIds, setSelectedChannelStatusIds] = useState<string[]>([]);
  const [appointmentsAreSyncing, setAppointmentsAreSyncing] = useState(false);
  const [triggerSync, setTriggerSync] = useState(false);
  const [selectedAppointmentGroupingIds, setSelectedAppointmentGroupingIds] = useState<string[]>([]);
  const { currentClinic, currentClinicId, clinicUser } = useClinicUser();
  const { isTriggerPimsSyncSupported } = useIntegrationsProvider();
  const { currentDay, setCurrentDay } = useAppointmentCalendarCurrentDay();
  const appointmentsColumnContainer = useRef<HTMLDivElement | null>(null);
  const isInitialPageLoad = useRef(true);

  // TODO: (future improvement) Convert this to use kibble modal
  const { openModal } = useModal();

  const {
    isOpen: isDeleteAppointmentGroupModalOpen,
    onOpen: openDeleteAppointmentGroupModal,
    onClose: closeDeleteAppointmentGroupModal,
  } = useDisclosure();

  const {
    isOpen: isCreateAppointmentGroupModalOpen,
    onOpen: openCreateAppointmentGroupModal,
    onClose: closeCreateAppointmentGroupModal,
  } = useDisclosure();

  const { userAppointmentGroupings, totalGroups, setGroupPage, groupPage, isLoadingAppointmentGroups } =
    useGetUserAppointmentGroupings({
      clinicId: currentClinicId || '',
      numberOfGroups: GROUPS_PER_PAGE,
      filteredAppointmentGroupings: selectedAppointmentGroupingIds,
      userId: clinicUser?.id || '',
    });

  const defaultAppointmentDurationInMinutes = useMemo(() => {
    return (
      currentClinic?.clinicSetting?.appointmentTimeSlotIncrementInMinutes || DEFAULT_APPOINTMENT_DURATION_IN_MINUTES
    );
  }, [currentClinic]);

  const onAppointmentClicked = (appointment: Appointment): void => {
    openModal(ModalNames.Appointment, {
      startDate: parseISO(appointment.startAt),
      durationInMinutes: appointment.durationInMinutes,
      appointmentId: appointment.id,
      defaultAppointmentDurationInMinutes,
    });
  };

  const onAddAppointment = (): void => {
    openModal(ModalNames.Appointment, {
      startDate: currentDay,
      defaultAppointmentDurationInMinutes,
      durationInMinutes: defaultAppointmentDurationInMinutes,
    });
  };

  const openDeleteGroupModal = (id: string): void => {
    setSelectedGroupId(id);
    openDeleteAppointmentGroupModal();
  };

  const hasNextGroupPage = useMemo(() => {
    return totalGroups !== 0 && (totalGroups || 0) > groupPage * GROUPS_PER_PAGE;
  }, [totalGroups, groupPage]);

  const onScrollRight = useCallback(
    (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
      const { scrollLeft, scrollWidth, clientWidth } = event.currentTarget;

      if (hasNextGroupPage && !isLoadingAppointmentGroups && scrollLeft + clientWidth >= scrollWidth) {
        setGroupPage(groupPage + 1);
      }
    },
    [groupPage, hasNextGroupPage, setGroupPage, isLoadingAppointmentGroups],
  );

  // If the device screen size is large enough, 4 columns may not be enough to fill the screen
  // If that is the case, we need to load more groups on page load so the columns overflow the right edge
  // This allows the user to be able to scroll to load more
  useEffect(() => {
    if (!isLoadingAppointmentGroups && isInitialPageLoad.current) {
      isInitialPageLoad.current = false;

      const _container = appointmentsColumnContainer.current;
      if (_container) {
        const { scrollLeft, scrollWidth, clientWidth } = _container;
        if (hasNextGroupPage && scrollLeft + clientWidth >= scrollWidth) {
          setGroupPage(groupPage + 1);
        }
      }
    }
  }, [
    isLoadingAppointmentGroups,
    isInitialPageLoad,
    appointmentsColumnContainer,
    hasNextGroupPage,
    setGroupPage,
    groupPage,
  ]);

  return (
    <VStack className="Appointments__PageContainer" spacing={0} height="100%" backgroundColor="background.default">
      <DateToolbar currentDay={currentDay} setCurrentDay={setCurrentDay} numberOfDays={14} />
      <HStack className="Appointments__PageHeader" justify="space-between" width="100%" px={4} pt={4}>
        <HStack className="Appointments__PageHeader--Left">
          <FilterGroupsMenu
            clinicId={currentClinicId}
            userId={clinicUser?.id}
            setGroupPage={setGroupPage}
            setSelectedAppointmentGroupingIds={setSelectedAppointmentGroupingIds}
            selectedAppointmentGroupingIds={selectedAppointmentGroupingIds}
          />
          <FilterByConversationStatusMenu
            clinicId={currentClinicId}
            selectedChannelStatusIds={selectedChannelStatusIds}
            setSelectedChannelStatusIds={setSelectedChannelStatusIds}
          />
        </HStack>
        <HStack className="Appointments__PageHeader--Right">
          {isTriggerPimsSyncSupported && (
            <PimsSync
              style={{ margin: '0 15px 0 0', width: 'auto' }}
              triggerSearch={(): void => setAppointmentsAreSyncing(!appointmentsAreSyncing)}
              shouldSync={triggerSync}
              resetSyncState={(): void => setTriggerSync(false)}
              parentComponent="Appointments"
              syncModels={[PimsIntegrationCapability.Appointment]}
              errorType={ErrorMessageType.Toast}
              shouldShowLastSync={false}
              showSuccessMessage={true}
            />
          )}
          <Button onClick={openCreateAppointmentGroupModal} variant="secondarySubtle" size="sm" iconName="plus">
            Add New Column
          </Button>
          <Button onClick={onAddAppointment} size="sm" iconName="plus" data-testid="add-appointment-button">
            Add New Appointment
          </Button>
        </HStack>
      </HStack>
      <HStack
        className="Appointments__PageColumns"
        w="100%"
        h="100%"
        spacing={4}
        overflowX="auto"
        p={4}
        onScroll={onScrollRight}
        pos="relative"
        ref={appointmentsColumnContainer}
      >
        {userAppointmentGroupings?.map((userGroup) => (
          <AppointmentGroup
            key={userGroup.id}
            currentDay={currentDay}
            userGroup={userGroup}
            onAppointmentClicked={onAppointmentClicked}
            onDeleteUserAppointmentGroup={openDeleteGroupModal}
            channelStatusIds={selectedChannelStatusIds}
          />
        ))}
        {isLoadingAppointmentGroups && <Spinner isFullScreen label="Loading Columns..." size="md" />}
      </HStack>
      <CreateAppointmentGroupModal
        isOpen={isCreateAppointmentGroupModalOpen}
        onClose={closeCreateAppointmentGroupModal}
      />
      <DeleteAppointmentGroupModal
        isOpen={isDeleteAppointmentGroupModalOpen}
        onClose={closeDeleteAppointmentGroupModal}
        selectedGroupId={selectedGroupId}
      />
    </VStack>
  );
};

export default Appointments;
