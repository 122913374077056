import { useMemo } from 'react';
import { FeatureFlagName } from 'shared/enums';
import { WildcardValues } from 'shared/enums/WildcardOptions';
import useFeatureFlag from 'shared/hooks/useFeatureFlag';
import { WorkflowEvent } from 'shared/types/graphql';
import { useAppSelector } from 'state/hooks';

export const useGetAvailableWildCards = (): WildcardValues[] => {
  const automationType = useAppSelector((state) => state.automations.automationDraft.automationType);
  const { isFeatureEnabled } = useFeatureFlag();

  const availableWildCardOptions = useMemo(() => {
    switch (automationType) {
      case WorkflowEvent.WidgetRequestFormSubmitted:
        return [WildcardValues.ClientName, WildcardValues.ClinicName];
      default:
        return [
          WildcardValues.Time,
          WildcardValues.ClientName,
          WildcardValues.EmployeeName,
          WildcardValues.ClinicName,
          ...(automationType === WorkflowEvent.LapsedPetParent ? [] : [WildcardValues.PetName]), // Include pet name for all automations except Lapsed Pet Parent
          ...(isFeatureEnabled(FeatureFlagName.CarePlans) ? [WildcardValues.PetPortalLinksForPets] : []),
        ];
    }
  }, [automationType, isFeatureEnabled]);

  return availableWildCardOptions;
};
