import { defaultDiscoveredInvoiceDemoData } from 'pages/Settings/pages/DevToolsSettings';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import HasFeature from 'shared/components/HasFeature';
import InvoiceSelector from 'shared/components/Invoicing/InvoiceSelector';
import { PanelViews } from 'shared/components/SidePanel/shared/types/enums/PanelViews';
import { useSidePanel } from 'shared/components/SidePanel/state/providers/SidePanelProvider';
import { usePets } from 'shared/components/SidePanel/components/SearchPanel/components/Benefits/hooks/usePets';
import { FeatureFlagName } from 'shared/enums/FeatureFlagName';
import { GraphQLErrorPolicies } from 'shared/enums/GraphQLErrorPolicies';
import { GraphQLFetchPolicies } from 'shared/enums/GraphQLFetchPolicies';
import usePersistedState from 'shared/hooks/usePersistedState';
import useClinicUser from 'shared/hooks/useClinicUser';
import { useResolutionProvider } from 'shared/providers/ResolutionProvider';

import {
  InvoiceStatus as StripeInvoiceStatus,
  useStripeTerminalProvider,
} from 'shared/providers/StripeTerminalProvider';
import {
  ChannelViewClinicPetParentFragment,
  PaymentMedium as PaymentMedium,
  ClinicPetParent,
  InvoiceStatus,
  PaymentRelationship,
  StripePaymentIntentStatus,
  StripePaymentIntentStatusFragment,
  useGetBalanceForClientLazyQuery,
  useGetInvoicesForClientLazyQuery,
  InvoiceForClientFragment,
} from 'shared/types/graphql';
import { getBalanceTerminology, getMonetaryDisplayValue } from 'shared/utils';
import { Mixpanel } from 'shared/utils/mixpanel';
import styled from 'styled-components/macro';
import GenericToolbarButton from '../../Toolbar/GenericToolbarButton';
import { Box } from '@televet/kibble-ui/build/chakra';
import { Button } from '@televet/kibble-ui/build/components/Button';
import LoyaltyProgramBanner from './LoyaltyProgramBanner';
import useFeatureFlag from 'shared/hooks/useFeatureFlag';
import { CareBenefitsBanner } from 'shared/components/CareBenefitsBanner/CareBenefitsBanner';
import { PersistedStateKeys } from 'shared/enums';
import { useIntegrationsProvider } from 'shared/providers/IntegrationsProvider';
import TrupanionCheckout from 'shared/components/SidePanel/components/InvoicingPanel/components/TrupanionCheckout/TrupanionCheckout';
import { Popover } from '@televet/kibble-ui/build/components/Popover';
import { Alert, Icon } from '@televet/kibble-ui';
import usePaymentWritebackConfig from 'shared/hooks/usePaymentWritebackConfig';
import AccountBalanceSelector from 'shared/components/Invoicing/AccountBalanceSelector';

interface InvoiceAttachmentPopoverProps {
  clinicPetParents: ChannelViewClinicPetParentFragment[];
  isDrawerOpen: boolean;
  onDrawerClose: () => void;
  onDrawerToggle: () => void;
  onInvoiceAttach(
    amount: string,
    invoiceIds: string[],
    isBalanceSelected: boolean,
    discounts?: string,
    paymentRelationship?: PaymentRelationship,
  ): void;
  onRemoveAllInvoices(): void;
  selectedInvoiceIds: string[];
  invoiceButtonRef?: React.MutableRefObject<HTMLButtonElement | null>;
  setSelectedInvoiceIds: Dispatch<SetStateAction<string[]>>;
  setHasPotentialBenefits: Dispatch<SetStateAction<boolean>>;
  hasPotentialBenefits: boolean;
  setInvoiceForSidePanelId: Dispatch<SetStateAction<string>>;
}

const InvoiceAttachmentPopover = ({
  clinicPetParents,
  isDrawerOpen,
  onDrawerClose,
  onDrawerToggle,
  onInvoiceAttach,
  onRemoveAllInvoices,
  selectedInvoiceIds,
  invoiceButtonRef,
  setSelectedInvoiceIds,
}: InvoiceAttachmentPopoverProps): JSX.Element => {
  const { isFeatureEnabled } = useFeatureFlag();
  const [invoices, setInvoices] = useState<InvoiceForClientFragment[]>([]);
  const [balances, setBalances] = useState<{ [key: string]: number }>({});
  const [selectedClinicPetParent, setSelectedClinicPetParent] = useState<string>('');
  const [selectedBalances, setSelectedBalances] = useState<{ [key: string]: number }>({});
  const [isInvoiceLoading, setIsInvoiceLoading] = useState(false);
  const [isBalanceLoading, setIsBalanceLoading] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');
  const [emptyStateMessage, setEmptyStateMessage] = useState('There are no pending invoices');
  const { setIsOpen, setCurrentView: setCurrentSidePanelView } = useSidePanel();
  const { isMobile } = useResolutionProvider();
  const history = useHistory();
  const { currentClinic } = useClinicUser();
  const { primaryIntegrationName, isInvoicePreviewSupported } = useIntegrationsProvider();
  const { isWritebackManualPaymentEnabled } = usePaymentWritebackConfig();

  const [getBalance] = useGetBalanceForClientLazyQuery({
    errorPolicy: GraphQLErrorPolicies.All,
    fetchPolicy: GraphQLFetchPolicies.NetworkOnly,
  });

  const [getInvoices] = useGetInvoicesForClientLazyQuery({
    errorPolicy: GraphQLErrorPolicies.All,
    fetchPolicy: GraphQLFetchPolicies.NetworkOnly,
  });

  const [isDiscoveredInvoiceDemoModeEnabled] = usePersistedState(
    PersistedStateKeys.IsDiscoveredInvoiceDemoModeEnabled,
    false,
  );

  const [discoveredInvoiceDemoData, setDiscoveredInvoiceDemoData] = usePersistedState(
    'discoveredInvoiceDemoData',
    defaultDiscoveredInvoiceDemoData,
  );

  const pimsPetParents = useMemo(() => {
    return clinicPetParents.filter((parent) => parent.pimsId !== null);
  }, [clinicPetParents]);

  const {
    setCurrentPaymentMedium: setCurrentInvoicingPaymentMedium,
    setSuggestedPetParentOptions: setSuggestedInvoicingPetParentOptions,
    setSelectedPetParent: setSelectedInvoicingPetParent,
    setCurrentInvoiceStatus,
    terminalOptions,
    currentInvoiceStatus,
    setInvoiceIds,
    setIsBalancePreSelected,
  } = useStripeTerminalProvider();

  const petParentIds = useMemo(() => {
    return clinicPetParents.map((clinicPetParent) => {
      return clinicPetParent.id;
    });
  }, [clinicPetParents]);

  const isPendingPaymentIntents = (intents: StripePaymentIntentStatusFragment[]): boolean => {
    for (const intent of intents) {
      if (intent.status === StripePaymentIntentStatus.RequiresPaymentMethod) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    setSelectedClinicPetParent(petParentIds[0]);
  }, [petParentIds]);

  const isBalanceSelected = useMemo(() => Object.keys(selectedBalances).length > 0, [selectedBalances]);

  const selectedParentInPims = useMemo(() => {
    const selectedParent = clinicPetParents.find(({ id }) => id === selectedClinicPetParent);
    return !!selectedParent?.pimsId;
  }, [clinicPetParents, selectedClinicPetParent]);

  const totalAmountSelected = useMemo(() => {
    let total = 0;
    if (invoices) {
      for (const invoiceId of selectedInvoiceIds) {
        const invoice = invoices.find((invoice) => invoice.id === invoiceId);
        total += invoice?.total ?? 0;
      }
    }

    if (balances) {
      for (const balanceId in selectedBalances) {
        total += selectedBalances[balanceId] ?? 0;
      }
    }

    if (isBalanceSelected || !!selectedInvoiceIds.length) {
      if (total < 0) {
        setValidationMessage('Cannot collect negative payment amount');
      }

      if (total < 50 && total >= 0) {
        setValidationMessage('Cannot collect amount less than $0.50');
      }

      if (total > 50) {
        setValidationMessage('');
      }
    } else {
      setValidationMessage('');
    }

    if (total > 0) {
      return total;
    } else return 0;
  }, [isBalanceSelected, balances, invoices, selectedBalances, selectedInvoiceIds]);

  const { pets } = usePets(selectedClinicPetParent);
  const enrolledPets = useMemo(() => pets.filter((pet) => !!pet.organizationPet?.carePlanEnrollments.length), [pets]);

  useEffect(() => {
    if (isDrawerOpen) {
      onInvoiceAttach(
        getMonetaryDisplayValue(totalAmountSelected),
        isDiscoveredInvoiceDemoModeEnabled ? [] : selectedInvoiceIds,
        isBalanceSelected,
        undefined,
        !!selectedBalances[selectedClinicPetParent] || (isWritebackManualPaymentEnabled && selectedParentInPims)
          ? PaymentRelationship.AccountBalance
          : undefined,
      );
    }
  }, [
    totalAmountSelected,
    isDrawerOpen,
    onInvoiceAttach,
    selectedInvoiceIds,
    selectedBalances,
    selectedClinicPetParent,
    isDiscoveredInvoiceDemoModeEnabled,
    isWritebackManualPaymentEnabled,
    selectedParentInPims,
    isBalanceSelected,
  ]);

  const handleDrawerToggle = (): void => {
    onDrawerToggle();
  };

  const balanceTerminology = useMemo(() => {
    return getBalanceTerminology(primaryIntegrationName);
  }, [primaryIntegrationName]);

  const sendToSidePanel = (clinicPetParentId: string): void => {
    const clinicPetParent = clinicPetParents.find((clinicPetParent) => {
      return clinicPetParent.id === clinicPetParentId;
    });

    if (currentInvoiceStatus !== StripeInvoiceStatus.Pending) {
      setIsOpen(false);
      setTimeout(() => {
        setCurrentSidePanelView(PanelViews.Invoicing);
        setIsOpen(true);
        if (terminalOptions && terminalOptions?.length > 0) {
          setCurrentInvoicingPaymentMedium(PaymentMedium.StripeTerminal);
        } else {
          setCurrentInvoicingPaymentMedium(PaymentMedium.StripeVirtualTerminal);
        }
        setCurrentInvoiceStatus(StripeInvoiceStatus.Default);
        setSelectedInvoicingPetParent(clinicPetParent);
        setSuggestedInvoicingPetParentOptions(clinicPetParents);
        setInvoiceIds(selectedInvoiceIds);
      }, 0);
    }
  };

  // reset selected balance when opening drawer
  useEffect(() => {
    if (isDrawerOpen) {
      setSelectedBalances({});
      setSelectedInvoiceIds([]);
    }
  }, [isDrawerOpen, setSelectedInvoiceIds]);

  const pullBalance = async (): Promise<void> => {
    setIsBalanceLoading(true);

    if (isDiscoveredInvoiceDemoModeEnabled) {
      // Get clinicPetParent
      const clinicPetParent = clinicPetParents.find((clinicPetParent) => {
        return clinicPetParent.id === selectedClinicPetParent;
      });
      // Inject clinicPetParent into demo data
      for (const invoice of discoveredInvoiceDemoData.invoices) {
        invoice.clinicPetParent = {
          ...clinicPetParent,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          clinic: currentClinic || ({} as any),
        } as ClinicPetParent;
      }
      setDiscoveredInvoiceDemoData({ ...discoveredInvoiceDemoData, creditsApplied: 0 });
      // Set demo data as invoice data
      const luis: { [key: string]: number } = {};
      luis[selectedClinicPetParent] = discoveredInvoiceDemoData.balance.currentBalance;
      setBalances(luis);
      // Emulate api call and delay 2 seconds
      setTimeout(() => {
        setIsBalanceLoading(false);
      }, 1500);
    } else {
      const newBalances: { [key: string]: number } = {};
      for (const petParentId of petParentIds) {
        const { data } = await getBalance({
          variables: {
            where: {
              clinicPetParentId: petParentId,
            },
          },
        });

        if (data) {
          newBalances[petParentId] = data.getBalanceForClient?.currentBalance || 0;
        }
      }

      setBalances(newBalances);
      setIsBalanceLoading(false);
    }
  };

  const pullInvoices = async (): Promise<void> => {
    setInvoices([]);

    setSelectedInvoiceIds([]);

    // If we can't do anything with invoices, don't fetch them (wasteful query to Bitwerx)
    if (!isInvoicePreviewSupported) {
      return;
    }

    setIsInvoiceLoading(true);

    if (isDiscoveredInvoiceDemoModeEnabled) {
      // Get clinicPetParent
      const clinicPetParent = clinicPetParents.find((clinicPetParent) => {
        return clinicPetParent.id === selectedClinicPetParent;
      });
      // Inject clinicPetParent into demo data
      for (const invoice of discoveredInvoiceDemoData.invoices) {
        invoice.clinicPetParent = {
          ...clinicPetParent,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          clinic: currentClinic || ({} as any),
        } as ClinicPetParent;
      }
      setDiscoveredInvoiceDemoData({ ...discoveredInvoiceDemoData });
      // Set demo data as invoice data
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setInvoices(discoveredInvoiceDemoData.invoices as any);
      // Emulate api call and delay 2 seconds
      setTimeout(() => {
        setIsInvoiceLoading(false);
      }, 2500);
    } else {
      const newInvoices: InvoiceForClientFragment[] = [];

      for (const petParentId of petParentIds) {
        const { data } = await getInvoices({
          variables: {
            where: {
              clinicPetParentId: { equals: petParentId },
              status: { equals: InvoiceStatus.Open },
              total: { gt: 0 },
              stripePaymentIntents: { none: { status: { equals: StripePaymentIntentStatus.Succeeded } } },
            },
          },
        });

        if (data) {
          setEmptyStateMessage('There are no pending invoices');
          newInvoices.push(...data.getInvoicesForClient);
        } else {
          setEmptyStateMessage('Error connecting to invoice data, please wait a moment and try again');
        }
      }

      setInvoices(newInvoices);
      setIsInvoiceLoading(false);
    }
  };

  const pullInvoicesAndBalance = async (): Promise<void> => {
    pullInvoices();
    pullBalance();
  };

  const showLoyaltyProgramAlert = useMemo(() => {
    return isFeatureEnabled(FeatureFlagName.LoyaltyProgram) && selectedInvoiceIds.length > 0;
  }, [isFeatureEnabled, selectedInvoiceIds]);

  const handleBalanceSelected = useCallback(() => {
    if (!selectedBalances[selectedClinicPetParent]) {
      if (isDiscoveredInvoiceDemoModeEnabled) {
        setDiscoveredInvoiceDemoData({
          ...discoveredInvoiceDemoData,
          creditsApplied: balances[selectedClinicPetParent] * -1,
        });
      }
      setSelectedBalances((prevState): { [key: string]: number } => {
        const newState = { ...prevState };
        if (balances[selectedClinicPetParent] !== 0) {
          Mixpanel.track('Attach PIMS balance checked');
          newState[selectedClinicPetParent] = balances[selectedClinicPetParent];
        }
        return newState;
      });
    } else {
      if (isDiscoveredInvoiceDemoModeEnabled) {
        setDiscoveredInvoiceDemoData({
          ...discoveredInvoiceDemoData,
          creditsApplied: 0,
        });
      }
      setSelectedBalances((prevState): { [key: string]: number } => {
        const newState = { ...prevState };
        delete newState[selectedClinicPetParent];
        return newState;
      });
    }
  }, [
    balances,
    discoveredInvoiceDemoData,
    isDiscoveredInvoiceDemoModeEnabled,
    selectedBalances,
    selectedClinicPetParent,
    setDiscoveredInvoiceDemoData,
  ]);

  return (
    <Popover
      headerProps={{
        children: 'Payments',
        onClick: onDrawerClose,
      }}
      isLazy={true}
      offset={[-22, 132]}
      size="lg"
      variant="default"
      isOpen={isDrawerOpen}
      anchor={
        <GenericToolbarButton
          data-testid="real-attach-invoice-button"
          tooltip="Attach payment"
          ref={invoiceButtonRef}
          onMouseEnter={(): void => {
            if (petParentIds.length > 0 && !isDrawerOpen) {
              pullInvoicesAndBalance();
            }
          }}
          onClick={(): void => {
            if (!isDrawerOpen) {
              handleDrawerToggle();
            }

            onInvoiceAttach(getMonetaryDisplayValue(totalAmountSelected), selectedInvoiceIds, isBalanceSelected);
            // Pull data on click since mobile doesn't hover
            if (isMobile) {
              if (petParentIds.length > 0 && !isDrawerOpen) {
                pullInvoicesAndBalance();
              }
            }
          }}
        >
          <Icon name="invoice" />
        </GenericToolbarButton>
      }
    >
      <ClinicPetParents>
        {pimsPetParents.map((clinicPetParent) => {
          return (
            <OptionContainer
              isSelected={clinicPetParent.id === selectedClinicPetParent}
              key={clinicPetParent.id}
              onClick={(): void => {
                if (clinicPetParent.id !== selectedClinicPetParent) {
                  setSelectedInvoiceIds([]);
                  setSelectedBalances({});
                  setSelectedClinicPetParent(clinicPetParent.id);
                }
              }}
            >
              {clinicPetParent.id === selectedClinicPetParent ? <BlueCheckIcon /> : <UncheckedCircle />}{' '}
              {clinicPetParent.firstName} {clinicPetParent.lastName}
            </OptionContainer>
          );
        })}
      </ClinicPetParents>
      <DrawerSection>
        <DrawerTitle>
          <Title>Client Account</Title>
          <Button
            isLoading={isBalanceLoading}
            iconName="doubleArrowCircle"
            data-testid="convo-ar-balance-refresh-button"
            loadingText="Refreshing"
            variant="ghost"
            size="xs"
            onClick={(): void => {
              Mixpanel.track('Refresh PIMS balance data button clicked in conversation');
              if (!isBalanceLoading) {
                pullBalance();
              }
            }}
          >
            Refresh
          </Button>
        </DrawerTitle>
      </DrawerSection>
      <AccountBalanceSelector
        balanceTerminology={balanceTerminology}
        currentBalance={balances[selectedClinicPetParent]}
        isChecked={!!selectedBalances[selectedClinicPetParent]}
        onClick={handleBalanceSelected}
        showSkeleton={isBalanceLoading}
      />
      {isInvoicePreviewSupported && (
        <>
          <DrawerSection>
            <DrawerTitle>
              <Title>Client Invoices ({invoices.length})</Title>
              <Button
                isLoading={!!isInvoiceLoading}
                iconName="doubleArrowCircle"
                data-testid="convo-ar-balance-refresh-button"
                loadingText="Refreshing"
                variant="ghost"
                size="xs"
                onClick={(): void => {
                  Mixpanel.track('Refresh PIMS invoice data button clicked in conversation');
                  if (!isInvoiceLoading) {
                    pullInvoices();
                  }
                }}
              >
                Refresh
              </Button>
            </DrawerTitle>
          </DrawerSection>
          {!invoices.length && !isInvoiceLoading && <DrawerSubTitle>{emptyStateMessage}</DrawerSubTitle>}
          <Invoices isMobile={isMobile}>
            {isInvoiceLoading ? (
              <InvoiceSelector
                selectedInvoiceIds={selectedInvoiceIds}
                isPendingPaymentIntents={isPendingPaymentIntents}
                setSelectedInvoiceIds={setSelectedInvoiceIds}
                showSkeleton={true}
              />
            ) : (
              invoices
                .filter((invoice) => {
                  return invoice.clinicPetParent.id === selectedClinicPetParent;
                })
                .map((invoice) => {
                  return (
                    <InvoiceSelector
                      key={invoice.id}
                      invoice={invoice}
                      selectedInvoiceIds={selectedInvoiceIds}
                      isPendingPaymentIntents={isPendingPaymentIntents}
                      setSelectedInvoiceIds={setSelectedInvoiceIds}
                    />
                  );
                })
            )}
          </Invoices>
          {!!validationMessage && (
            <Alert
              size="sm"
              status="error"
              title={validationMessage}
              hideCloseButton
              titleProps={{ fontSize: '14px' }}
              mb={2}
              mx={2}
            />
          )}
          {showLoyaltyProgramAlert && (
            <Box mx={2}>
              <LoyaltyProgramBanner clinicPetParentId={selectedClinicPetParent} />
            </Box>
          )}
        </>
      )}
      <HasFeature name={FeatureFlagName.CarePlans}>
        {!!enrolledPets.length && (
          <Box pt={0} mx={2}>
            <CareBenefitsBanner
              clinicPetParentId={selectedClinicPetParent}
              hasMultipleEnrolledPets={enrolledPets.length > 1}
              organizationPetId={enrolledPets.length > 1 ? undefined : enrolledPets[0]?.organizationPet?.id}
            />
          </Box>
        )}
      </HasFeature>
      <DrawerFooter>
        <Button
          size="sm"
          isDisabled={!!validationMessage || totalAmountSelected < 50}
          onClick={(): void => {
            Mixpanel.track('View invoice data in terminal button clicked in conversation');
            onDrawerClose();
            onRemoveAllInvoices();
            setIsBalancePreSelected(isBalanceSelected);
            sendToSidePanel(invoices?.[0]?.clinicPetParent?.id || clinicPetParents[0].id);
            if (isMobile) {
              history.push(`/conversations`);
            }
          }}
          variant="secondary"
          flexGrow={1}
          flexBasis={0}
          mr="3px"
        >
          View in Terminal
        </Button>
        <Button
          size="sm"
          isDisabled={!!validationMessage || totalAmountSelected < 50}
          onClick={(): void => {
            if (!validationMessage && totalAmountSelected > 0) {
              Mixpanel.track('Attach clicked in invoice conversation drawer');
              onDrawerClose();
            }
          }}
          flexGrow={1}
          flexBasis={0}
          ml="3px"
        >
          Attach
        </Button>
      </DrawerFooter>
      <HasFeature name={FeatureFlagName.TrupanionDigitalCheckout}>
        <TrupanionCheckout clinicPetParentId={selectedClinicPetParent} />
      </HasFeature>
    </Popover>
  );
};

export default React.memo(InvoiceAttachmentPopover, (prevProps, newProps) => {
  return (
    prevProps.clinicPetParents[0] === newProps.clinicPetParents[0] &&
    prevProps.selectedInvoiceIds === newProps.selectedInvoiceIds &&
    prevProps.isDrawerOpen === newProps.isDrawerOpen
  );
});

const ClinicPetParents = styled.div`
  display: flex;
  margin: 0 10px 10px 10px;
`;

const OptionContainer = styled.div<{ isSelected: boolean }>`
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(190, 190, 190, 0.5);
  border: solid 0.5px
    ${({ isSelected }): string =>
      isSelected ? 'var(--chakra-colors-border-info)' : 'var(--chakra-colors-border-default)'};
  padding: 4px 8px;
  height: 30px;
  margin-right: 12px;
  width: auto;
  max-width: 220px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-top: 8px;
  cursor: pointer;
`;

const BlueCheckIcon = styled.div`
  border-radius: 7px; // Circle
  border: solid 1px #979797;
  min-width: 8px;
  min-height: 8px;
  margin-right: 8px;
  background-color: var(--chakra-colors-background-primary);
`;

const UncheckedCircle = styled.div`
  border-radius: 7px; // Circle
  border: solid 1px #979797;
  min-width: 8px;
  min-height: 8px;
  margin-right: 8px;
`;

const DrawerSection = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Title = styled.div``;

const DrawerTitle = styled.div`
  display: flex;

  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.11px;

  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
`;

const DrawerSubTitle = styled.div`
  margin: 10px 0;
  padding: 0 10px;
`;

interface IInvoicesProps {
  isMobile: boolean;
}

const Invoices = styled.div<IInvoicesProps>`
  max-height: ${({ isMobile }): string => (isMobile ? '300px' : '400px')};
  overflow-y: auto;
  padding: 0 10px 10px 10px;
`;

const DrawerFooter = styled.div`
  padding: 10px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
