import React from 'react';
import { CPopover, PopoverTrigger, PopoverContent } from '@televet/kibble-ui/build/chakra';
import ChatEntityPopoverContent from './ChatEntityPopoverContent';
import { PopoverPlacement } from 'shared/components/Dropdown';

interface IChatEntityPopoverProps {
  isOpen: boolean;
  trigger: JSX.Element;
  clinicPetParentIds: string[];
  onClose: () => void;
}

const ChatEntityPopover = ({ isOpen, trigger, clinicPetParentIds, onClose }: IChatEntityPopoverProps): JSX.Element => {
  return (
    <CPopover
      isOpen={isOpen}
      isLazy={true}
      lazyBehavior="unmount"
      placement={PopoverPlacement.BottomStart}
      onClose={onClose}
    >
      <PopoverTrigger>{trigger}</PopoverTrigger>
      <PopoverContent w="400px" boxShadow="lg" _focus={{ boxShadow: 'lg' }}>
        <ChatEntityPopoverContent clinicPetParentIds={clinicPetParentIds} onClose={onClose} />
      </PopoverContent>
    </CPopover>
  );
};

export default ChatEntityPopover;
