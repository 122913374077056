import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { env } from 'env';
import { persistStore, FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import automationsReducer from 'pages/Automations/state/automationsSlice';
import conversationsReducer from 'pages/Conversations/state/conversationsSlice';
import paymentSettingsReducer from 'pages/Settings/pages/PaymentSettings/state/paymentSettingsSlice';
import boardReducer from 'pages/Board/state/boardSlice';
import mergeProfilesReducer from '../shared/components/MergeProfiles/state/mergeProfilesSlice';
import careWizardStepsReducer from '../shared/components/SidePanel/components/SearchPanel/components/Benefits/state/careWizardStepsSlice';
import currentClinicReducer from './currentClinic/currentClinicSlice';

const rootReducer = combineReducers({
  automations: automationsReducer,
  paymentSettings: paymentSettingsReducer,
  CareWizardSteps: careWizardStepsReducer,
  mergeProfiles: mergeProfilesReducer,
  currentClinic: currentClinicReducer,
  conversations: conversationsReducer,
  board: boardReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: env.REACT_APP_DEVELOPMENT === 'true',
  middleware: (getDefaultMiddleware) =>
    // For redux-persist. See https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist for more info.
    getDefaultMiddleware({
      serializableCheck: { ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER] },
    }),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
