import { Flex } from '@televet/kibble-ui/build/chakra';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { Menu, MenuItemProps } from '@televet/kibble-ui/build/components/Menu';
import { Tag } from '@televet/kibble-ui/build/components/Tag';
import { Text } from '@televet/kibble-ui/build/components/Text';
import React, { useMemo, useState } from 'react';
import { ConditionProps } from '../types/conditionalSegments.types';
import { SegmentWrapper } from './SegmentWrapper';

type AppointmentTypeSegmentConditionValue = {
  appointmentType: string[];
};

type AdditionalConditionProps = { appointmentTypes: { value: string; label: string }[] };

type AppointmentTypeSegmentProps = ConditionProps<AppointmentTypeSegmentConditionValue> & AdditionalConditionProps;

export const AppointmentTypeSegment = ({
  onDelete,
  updateConditionValue,
  conditionValue,
  asSummary,
  displayValidation,
  appointmentTypes,
}: AppointmentTypeSegmentProps): JSX.Element => {

  const [menuItems, setMenuItems] = useState(
    (appointmentTypes || []).map((apptType) => ({
      ...apptType,
      isSelected: !!conditionValue?.appointmentType?.includes(apptType.value),
    })),
  );

  const selectedAppointmentTypes = useMemo(() => menuItems.filter((item) => item.isSelected), [menuItems]);

  const handleSelection = ({ value, isSelected }: MenuItemProps): void => {
    setMenuItems((currentItems) => {
      const appointmentTypes = [];
      const selectedItems = [];
      for (const item of currentItems) {
        const updatedItem = { ...item };
        if (item.value === value) {
          updatedItem.isSelected = !!isSelected;
        }
        if (updatedItem.isSelected) {
          appointmentTypes.push(item.value);
        }
        selectedItems.push(updatedItem);
      }
      updateConditionValue({ appointmentType: appointmentTypes });
      return selectedItems;
    });
  };

  const handleAllSelection = (selectedItems: MenuItemProps[]): void => {
    setMenuItems((currentItems) => currentItems.map((item) => ({ ...item, isSelected: selectedItems.length > 0 })));
    updateConditionValue({ appointmentType: selectedItems.map((i) => i.value || '') });
  };

  if (asSummary) {

    // We are storing the appointmentType Ids in the conditionValue, so we need to map them to their labels
    const appointmentLabels = conditionValue.appointmentType.map((apptTypeId) => {
      const menuItem = menuItems.find((item) => item.value === apptTypeId);
      return menuItem?.label || '';
    }).filter(value => value).join(', ');

    return (
      <Text fontWeight="light" color="text.subtle">
        Appointment Type: {appointmentLabels}
      </Text>
    );
  }

  return (
    <SegmentWrapper onDelete={onDelete}>
      <Flex flexDir="column" gap="2">
        <Flex align="center" gap="3" w="100%">
          <Text fontWeight="bold" mr="3">
            Appointment Type
          </Text>
          <Menu
            buttonProps={{
              size: 'sm',
              variant: 'secondarySubtle',
              leftIconName: 'plus',
              text: 'Add',
              showRightIcon: false,
            }}
            listProps={{
              isMultiSelect: true,
              isSearchable: true,
              canSelectAll: true,
              onSelectAll: handleAllSelection,
              onSelect: handleSelection,
              menuItems,
            }}
          />
          <Button
            variant="ghostNeutral"
            iconName="arrowCircle"
            size="sm"
            onClick={(): void => {
              handleAllSelection([]);
            }}
          >
            Clear All
          </Button>
        </Flex>

        {displayValidation && (
          <Text as="p" color="text.danger">
            Please add at least one appointment type.
          </Text>
        )}

        {selectedAppointmentTypes.length > 0 && (
          <Flex gap="1" wrap="wrap">
            {selectedAppointmentTypes.map((apptType) => (
              <Tag
                key={apptType.value}
                label={apptType.label}
                size="sm"
                fontWeight="light"
                closeButtonDisplay="always"
                onClose={(): void => {
                  handleSelection({ ...apptType, isSelected: false });
                }}
              />
            ))}
          </Flex>
        )}
      </Flex>
    </SegmentWrapper>
  );
};
