import React, { useState, useContext, ReactPortal, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { useDrop, useDrag, XYCoord, DragLayerMonitor } from 'react-dnd';
import PopoverContext from '../context/PopoverContext';
import { IPopoverContext } from '../interfaces/IPopoverContext';
import { IDragItem } from '../interfaces/IDragItem';
import { PopoverSizes } from '../enums/PopoverSizes';
import { ItemTypes } from '../enums/ItemTypes';
import PopoverContent from './PopoverContent';
import StyledContent from './StyledContent';
import StyledContainer from './StyledContainer';
import { CloseIcon, ResizeIcon } from 'assets/icons';
import styled from 'styled-components/macro';
import { useLocation } from 'react-router-dom';

// React Resize n' Drag: https://www.npmjs.com/package/react-rnd
import { Rnd } from 'react-rnd';
import { PopoverNames } from 'shared/enums/PopoverNames';
import { useResolutionProvider } from 'shared/providers/ResolutionProvider';
import useCustomRnd from 'shared/hooks/useCustomRnd';

interface IPopoverProps {
  children: ReactNode;
  size: PopoverSizes;
  id: string;
  startTop?: number;
  startLeft?: number;
  name?: string;
  borderWidth?: PopoverSizes;
  onClose?: () => void;
  isMobile?: boolean;
  isTouchDevice?: boolean;
  width?: number;
  height?: number;
  isResizable?: boolean;
  zIndex?: number;
  useDynamicPositioning?: boolean;
}

const Popover = ({
  children,
  name,
  size,
  id,
  startTop = window.innerHeight / 4,
  startLeft = window.innerWidth / 4,
  borderWidth,
  onClose,
  isMobile,
  isTouchDevice,
  width,
  height,
  isResizable,
  zIndex = 100000,
  useDynamicPositioning
}: IPopoverProps): JSX.Element | ReactPortal | null => {
  const [position, setPosition] = useState<IDragItem>({ id, type: ItemTypes.Window, top: startTop, left: startLeft });
  const { popoverNode, popover } = useContext<IPopoverContext>(PopoverContext);
  const { isOpen, name: popoverName } = popover;
  const { currentHeight } = useResolutionProvider();

  const rndWidth = width || 865;
  const rndHeight = height || 600;
  const dynamicRndProps = useCustomRnd({
    width: rndWidth,
    height: rndHeight,
    useDynamicPositioning
  });

  const moveBox = (left: number, top: number): void => {
    setPosition({
      ...position,
      left,
      top,
    });
  };

  const [, dropRef] = useDrop({
    accept: ItemTypes.Window,
    drop(item: IDragItem, monitor) {
      const delta = monitor.getDifferenceFromInitialOffset() as XYCoord;
      const left = Math.round(item.left + delta.x);
      const top = Math.round(item.top + delta.y);
      moveBox(left, top);
      return undefined;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { id, left: position.left, top: position.top, type: ItemTypes.Window },
    collect: (monitor: DragLayerMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const handleClose = (): void => {
    if (onClose) {
      onClose();
    }
  };

  const location = useLocation();

  // If the component is not resizable/mobile, use the standard popover; else use the resizable component
  if (!isResizable || isMobile) {
    return popoverNode && isOpen && popoverName === name
      ? createPortal(
        <StyledContainer ref={dropRef} isDragging={isDragging} isMobile={isMobile}>
          <StyledContent
            ref={drag}
            size={size}
            top={position.top}
            left={position.left}
            name={popoverName}
            isMobile={isMobile}
            isTouchDevice={isTouchDevice}
            width={width}
            height={height || currentHeight}
            route={location.pathname}
          >
            {onClose && (
              <CloseButtonDiv>
                <CloseButton onClick={handleClose}>
                  Close
                    <ClosePopoverIcon height="12" width="12" />
                </CloseButton>
              </CloseButtonDiv>
            )}
            <PopoverContent
              borderWidth={borderWidth}
              name={popoverName}
              isMobile={isMobile}
              style={{ height: popoverName === PopoverNames.VideoCall ? '-webkit-fill-available' : '' }}
            >
              {children}
            </PopoverContent>
          </StyledContent>
        </StyledContainer>,
        popoverNode,
      )
      : null;
  }

  return popoverNode && isOpen && popoverName === name ? (
    <Rnd
      style={{ zIndex, userSelect: 'none', WebkitUserSelect: 'none', WebkitTouchCallout: 'none', }}
      default={{
        x: window.innerWidth / 3,
        y: window.innerHeight / 3,
        width: rndWidth,
        height: rndHeight,
      }}
      minWidth={410}
      minHeight={594}
      bounds="window"
      enableUserSelectHack={true}
      lockAspectRatio={false}
      {...dynamicRndProps}
    >
      <ResizableContent>
        {onClose && (
          <CloseButtonDiv>
            <CloseButton onClick={handleClose}>
              Close
              <ClosePopoverIcon height="12" width="12" />
            </CloseButton>
          </CloseButtonDiv>
        )}
        <PopoverContent
          style={{ maxHeight: '100%', height: '93%', padding: '0', minHeight: 510 }}
          borderWidth={borderWidth}
          name={popoverName}
          isMobile={isMobile}
        >
          {children}
        </PopoverContent>
      </ResizableContent>
      <ResizeButton>
        <ResizeIcon />
      </ResizeButton>
    </Rnd>
  ) : null;
};

export default Popover;

const CloseButtonDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 10px 10px 10px;
`;

const CloseButton = styled.div`
  cursor: pointer;
`;

const ClosePopoverIcon = styled(CloseIcon)`
  margin-left: 8px;
  svg {
    height: 24px;
    width: 24px;
  }
`;

const ResizableContent = styled.div`
  background-color: var(--chakra-colors-background-default);
  border-radius: 10px;
  border: solid 1px #efefef;
  box-shadow: 0 2px 21px 11px rgba(0, 0, 0, 0.04);
  height: 100%;
  padding: 1.5em;
  overflow-y: scroll;
  object-fit: contain;
`;

const ResizeButton = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  bottom: 24px;
  right: 6px;

  & g {
    stroke: #575d7c;
  }
`;
