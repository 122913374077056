import React, { useCallback } from 'react';
import styled from 'styled-components/macro';

interface IPercentageInputProps {
  value: string;
  decimals?: number;
  className?: string;
  onChange: (value: string) => void;
}

const PercentageInput = ({ value, decimals = 2, className, onChange }: IPercentageInputProps): JSX.Element => {
  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      if (isNaN(Number(value))) return;
      const decimalPlaces = value.includes('.') && value.split('.').pop();
      if (decimalPlaces && decimalPlaces.length > decimals) return;
      onChange(value.trim());
    },
    [decimals, onChange],
  );

  const handleInputBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>): void => {
      const value = Number(event.target.value);
      const formattedValue = value.toFixed(2).toString();
      onChange(formattedValue);
    },
    [onChange],
  );

  return (
    <PercentageInputContainer>
      <StyledInput
        type="number"
        step=".25"
        inputMode="decimal"
        placeholder="0.00"
        value={value}
        className={className}
        onFocus={(e): void => e.target.select()}
        onBlur={handleInputBlur}
        onChange={handleInputChange}
      />
    </PercentageInputContainer>
  );
};

const PercentageInputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  position: relative;

  min-width: 80px;
  width: 120px;
  max-width: 100%;

  &::after {
    content: '%';
    position: absolute;
    width: 18px;
    right: 0;
    text-align: right;
    pointer-events: none;
  }
`;

const StyledInput = styled.input`
  border: solid 1px #d2d2d2;
  padding: 0 10px;
  margin-right: 18px;
  width: 100%;
  min-height: 30px;
  height: 100%;
  border-radius: 7px;
  border: 1px solid #d2d2d2;
  transition: border-color 0.1s ease-in-out;

  &.error {
    border: 1px solid #ff3e28;
  }

  &:hover {
    border-color: #989aa1;
    &.error {
      border: 1px solid #ff3e28;
    }
  }

  &:focus {
    border-color: #39adc3;
    &.error {
      border: 1px solid #ff3e28;
    }
  }
`;

export default PercentageInput;
