import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { updateAutomationErrors, updateErrorAlertIsVisible } from '../state/automationsSlice';
import { validateAutomation } from '../utils/validation/validateAutomation';

export const useValidateAutomationDraft = (): {
  validateAutomationDraft: () => boolean;
} => {
  const dispatch = useAppDispatch();
  const automationName = useAppSelector((state) => state.automations.automationDraft.automationName);
  const automationType = useAppSelector((state) => state.automations.automationDraft.automationType);
  const triggerType = useAppSelector((state) => state.automations.automationDraft.triggerType);
  const sendConditions = useAppSelector((state) => state.automations.automationDraft.sendConditions);
  const forms = useAppSelector((state) => state.automations.automationDraft.forms);
  const requestOptions = useAppSelector((state) => state.automations.automationDraft.requestOptions);
  const notificationMessage = useAppSelector((state) => state.automations.automationDraft.notificationMessage);
  const prompts = useAppSelector((state) => state.automations.automationDraft.prompts);

  const validateAutomationDraft = useCallback((): boolean => {
    const { errors, isValid } = validateAutomation({
      automationName,
      automationType,
      triggerType,
      sendConditions,
      forms,
      requestOptions,
      notificationMessage,
      prompts,
    });

    dispatch(updateAutomationErrors(errors));
    const automationHasErrors = !isValid;
    dispatch(updateErrorAlertIsVisible(automationHasErrors));

    return automationHasErrors;
  }, [
    automationName,
    automationType,
    triggerType,
    sendConditions,
    forms,
    requestOptions,
    notificationMessage,
    prompts,
    dispatch,
  ]);

  return {
    validateAutomationDraft,
  };
};
