import { Box } from '@televet/kibble-ui/build/chakra';
import React, { useEffect, useState } from 'react';

type ProgressBarProps = {
  recordCount: number;
  completeProgress?: boolean;
};

export const ProgressBar = ({ recordCount, completeProgress }: ProgressBarProps): JSX.Element => {
  const [progress, setProgress] = useState(0);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [isSlow, setIsSlow] = useState(true);
  const [isFast, setIsFast] = useState(false);

  const totalUploadTime = recordCount * 0.5;
  const minimumSlowTime = 1500; // Minimum slow time in milliseconds (1.5 seconds) (for smaller files)
  const fastUploadTime = totalUploadTime * 0.9;
  const fastIncrementTime = fastUploadTime / 90; // Time per 1% increment in milliseconds

  useEffect(() => {
    if (progress < 10) {
      const interval = setInterval(() => {
        setProgress((prev) => Math.min(prev + 0.5, 10));
      }, minimumSlowTime / 20); // Divide the minimum slow time into 20 increments

      setTimeout(() => {
        clearInterval(interval);
        setIsSlow(false);
        setIsFast(true);
      }, minimumSlowTime);

      setIntervalId(interval);
      return (): void => clearInterval(interval);
    }
  }, [progress, minimumSlowTime]);

  useEffect(() => {
    if (!isSlow && progress < 90 && isFast) {
      const interval = setInterval(() => {
        setProgress((prev) => Math.min(prev + 1, 90));
      }, fastIncrementTime);
      setIntervalId(interval);

      return ():void => clearInterval(interval);
    }
  }, [isSlow, isFast, progress, fastIncrementTime]);

  useEffect(() => {
    if (progress >= 90 && progress < 100) {
      const interval = setInterval(() => {
        setProgress((prev) => Math.min(prev + 0.1, 100));
      }, 1000); // 1 second per 0.1% increment
      setIntervalId(interval);

      return ():void => clearInterval(interval);
    }
  }, [progress]);

  useEffect(() => {
    if (progress === 100 && intervalId) {
      clearInterval(intervalId);
    }
  }, [progress, intervalId]);

  useEffect(() => {
    if (completeProgress) {
      if (intervalId) {
        clearInterval(intervalId);
      }
      setProgress(100);
    }
  }, [completeProgress, intervalId]);

  return (
    <Box px={4} py={5} backgroundColor="background.subtle" borderWidth="1px" borderRadius="lg">
      <Box
        height={3}
        backgroundColor="gray.50"
        borderRadius="8px"
        boxShadow="0 0 3px rgba(0, 0, 0, 0.1)"
        overflow="hidden"
        position="relative"
      >
        <Box
          width={`${progress}%`}
          height="100%"
          backgroundColor="background.primary"
          borderRadius="8px"
          transition="width 0.2s ease" />
      </Box>
    </Box>
  );
};
