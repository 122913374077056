import React, { useMemo } from 'react';
import { Box } from '@televet/kibble-ui/build/chakra';
import { SelectOptionProps } from '@televet/kibble-ui/build/components/Select';
import { WildCardInputGroup, WildcardInputTypes } from 'shared/components/WildCardInputGroup';
import { AutomationRunActionPromptType } from 'shared/types/graphql';
import { AutomationsPromptBlockFieldContainer } from 'pages/Automations/components/Layout';
import { useAppDispatch } from 'state/hooks';
import { updateAutomationPromptConfig, updateAutomationPromptErrors } from 'pages/Automations/state/automationsSlice';
import { PromptQuestionOrMessageError } from 'pages/Automations/types/AutomationValidation';
import { useGetFieldErrorText } from 'pages/Automations/hooks/useGetFieldErrorText';
import { TimeFormatValue } from 'shared/components/WildCardInputGroup/WildcardTimeFormatSelect';
import { useGetAvailableWildCards } from 'pages/Automations/hooks/useGetAvailableWildCards';
import { AutomationPromptConfig } from 'pages/Automations/types/AutomationPrompt';

interface QuestionMessageProps {
  promptType: AutomationRunActionPromptType;
  config: AutomationPromptConfig;
  promptIndex: number;
  errors?: PromptQuestionOrMessageError[];
}

export const QuestionMessage = ({ promptType, promptIndex, config, errors }: QuestionMessageProps): JSX.Element => {
  const availableWildCardOptions = useGetAvailableWildCards();
  const dispatch = useAppDispatch();
  const errorText = useGetFieldErrorText(errors);
  const { messageBodyTemplate, timeFormat } = config;

  const fieldIsInvalid = useMemo(() => {
    return Boolean(errors?.length);
  }, [errors]);

  const placeholderText = useMemo(() => {
    switch (promptType) {
      case AutomationRunActionPromptType.Buttons:
        return 'Provide context and direct the client to the buttons below...';
      case AutomationRunActionPromptType.Form:
        return 'Provide context and direct the client to the form below...';
      case AutomationRunActionPromptType.Payment:
        return 'Provide context and direct the client to the invoice below...';
      case AutomationRunActionPromptType.Statement:
        return 'Give the client information or instructions...';
      default:
        return 'Ask a question or direct the client to fill out the text field...';
    }
  }, [promptType]);

  const handleInputValueChange = (value: string): void => {
    dispatch(updateAutomationPromptConfig({ promptIndex, config: { messageBodyTemplate: value } }));

    if (fieldIsInvalid) {
      dispatch(updateAutomationPromptErrors({ promptIndex, prompt: { questionOrMessage: [] } }));
    }
  };

  const handleTimeFormatValueChange = (selectedOption: SelectOptionProps): void => {
    dispatch(
      updateAutomationPromptConfig({ promptIndex, config: { timeFormat: selectedOption.value as TimeFormatValue } }),
    );
  };

  return (
    <AutomationsPromptBlockFieldContainer label="Question or Message">
      <Box className="PromptBlock__TextArea">
        <WildCardInputGroup
          data-testid="question-or-message-input"
          availableWildCards={availableWildCardOptions}
          inputProps={{
            placeholder: placeholderText,
            errorText,
            inputType: WildcardInputTypes.TextArea,
            inputValue: messageBodyTemplate || '',
            onInputValueChange: handleInputValueChange,
            isInvalid: fieldIsInvalid,
          }}
          timeFormatProps={{
            onChange: handleTimeFormatValueChange,
            timeFormatValue: timeFormat || TimeFormatValue.DayAndDateAndTime,
          }}
        />
      </Box>
    </AutomationsPromptBlockFieldContainer>
  );
};
