import { useMemo } from 'react';
import {
  ChannelStatusAction,
  TagInput,
  TagType,
  useGetAppointmentOptionsQuery,
  useGetClinicChannelStatusesQuery,
  useGetClinicTagsQuery,
  useGetClinicUsersQuery,
} from 'shared/types/graphql';
import { GraphQLFetchPolicies } from 'shared/enums/GraphQLFetchPolicies';
import { titleCase } from 'shared/utils';
import { ChannelListFilterDraftValueOption } from '../types/ChannelListFilterDraft';
import { IFilterOption } from '../interfaces/IFilterOption';
import useClinicUser from 'shared/hooks/useClinicUser';
import { channelStatusActionLabels } from 'shared/components/ConversationStatusSelect';
import { getTagVariantByType } from '../../ChannelListItem/CardTags';

export enum QuickSelectOptionValues {
  UNASSIGNED = 'UNASSIGNED',
  ASSIGNED_TO_ME = 'ASSIGNED_TO_ME',
}

const useChannelFilterOptions = (): Record<string, IFilterOption[]> => {
  const { currentClinicId } = useClinicUser();

  const { data: channelStatusesData } = useGetClinicChannelStatusesQuery({
    fetchPolicy: GraphQLFetchPolicies.CacheFirst,
    variables: { clinicId: currentClinicId || '' },
    skip: !currentClinicId,
  });
  const { data: clinicUsersData } = useGetClinicUsersQuery({
    fetchPolicy: GraphQLFetchPolicies.CacheFirst,
    variables: { clinicId: currentClinicId || '' },
    skip: !currentClinicId,
  });
  const { data: clinicTagsData } = useGetClinicTagsQuery({
    fetchPolicy: GraphQLFetchPolicies.CacheFirst,
    variables: { clinicId: currentClinicId || '' },
    skip: !currentClinicId,
  });
  const { data: appointmentOptions } = useGetAppointmentOptionsQuery({
    fetchPolicy: GraphQLFetchPolicies.CacheFirst,
    variables: { clinicId: currentClinicId || '' },
    skip: !currentClinicId,
  });

  const channelStatusOptions = useMemo(() => {
    const active = { label: channelStatusActionLabels.Active, value: [] as IFilterOption[] };
    const closed = { label: channelStatusActionLabels.Inactive, value: [] as IFilterOption[] };
    const archived = { label: channelStatusActionLabels.InactivePermanently, value: [] as IFilterOption[] };
    channelStatusesData?.findManyChannelStatus?.forEach((status) => {
      const option = {
        value: status.id,
        label: status.name,
        color: status.color,
      };
      if (status.channelStatusAction === ChannelStatusAction.Active) {
        active.value.push(option);
      } else if (status.channelStatusAction === ChannelStatusAction.Inactive) {
        closed.value.push(option);
      } else {
        archived.value.push(option);
      }
    });
    return [active, closed, archived].filter((category) => !!category.value.length);
  }, [channelStatusesData]);
  const clinicUserOptions = useMemo(() => {
    const quickSelect = { label: 'Quick Select', value: [] as IFilterOption[] };
    const clinicMembers = { label: 'Team Members', value: [] as IFilterOption[] };

    const users = clinicUsersData?.users
      ?.map((user) => ({
        value: user?.id || '',
        label: [user?.firstName, user?.lastName].join(' ').trim(),
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    clinicMembers.value = users || [];
    quickSelect.value = [
      { value: QuickSelectOptionValues.UNASSIGNED, label: 'Unassigned' },
      { value: QuickSelectOptionValues.ASSIGNED_TO_ME, label: 'Assigned to Me' },
    ];

    return [quickSelect, clinicMembers];
  }, [clinicUsersData]);
  const clinicTagOptions = useMemo(() => {
    return (
      clinicTagsData?.findUniqueClinic?.clinicSetting?.tags?.tags
        ?.map((t: TagInput) => ({
          value: t.name,
          label: titleCase(t.name),
          color: t.tagType === TagType.Custom ? t.colorBackground : `background.${getTagVariantByType(t.tagType)}`,
        }))
        .sort((a: ChannelListFilterDraftValueOption, b: ChannelListFilterDraftValueOption) =>
          a.label.localeCompare(b.label),
        ) || []
    );
  }, [clinicTagsData]);
  const clinicEmployeeOptions = useMemo(() => {
    return (
      appointmentOptions?.clinicEmployees
        ?.map((employee) => ({
          value: employee?.id || '',
          label: [employee?.firstName, employee?.lastName].join(' ').trim(),
        }))
        .sort((a, b) => a.label.localeCompare(b.label)) || []
    );
  }, [appointmentOptions]);
  const appointmentTypeOptions = useMemo(() => {
    return (
      appointmentOptions?.appointmentTypes?.map((type) => ({
        value: type?.id || '',
        label: type?.name || '',
      })) || []
    );
  }, [appointmentOptions]);
  const clinicRoomOptions = useMemo(() => {
    return (
      appointmentOptions?.clinicRooms
        ?.map((room) => ({ value: room?.id || '', label: room?.name || '' }))
        .sort((a, b) => a.label.localeCompare(b.label)) || []
    );
  }, [appointmentOptions]);

  return {
    channelStatusOptions,
    clinicUserOptions,
    clinicTagOptions,
    clinicEmployeeOptions,
    appointmentTypeOptions,
    clinicRoomOptions,
  };
};

export default useChannelFilterOptions;
