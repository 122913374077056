import React, { useCallback, useEffect, useRef } from 'react';
import AppointmentsColumn, { NO_STATUS } from '../Columns/AppointmentsColumn';
import { Appointment } from 'pages/Board';
import { updateBoardState } from 'pages/Board/state/boardSlice';
import { useGetPaginatedChannelStatuses } from 'pages/Board/hooks/useGetPaginatedChannelStatuses';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { HStack } from '@televet/kibble-ui/build/chakra';
import ConversationsColumn from '../Columns/ConversationsColumn';
import { LoadingColumn } from '../Columns/LoadingColumn';
import { BoardFilterView, UpdateUserBoardFilterSettingsMutationFn } from 'shared/types/graphql';

interface IChannelStatusBoardProps {
  onEditAppointment: (appointment: Partial<Appointment>) => void;
  clinicId: string;
  unreadChannelIds?: string[];
  updateBoardSettings: UpdateUserBoardFilterSettingsMutationFn;
}

const ChannelStatusBoard = ({
  onEditAppointment,
  clinicId,
  unreadChannelIds,
  updateBoardSettings,
}: IChannelStatusBoardProps): JSX.Element => {
  const { channelStatusPageNumber, boardSettings } = useAppSelector((state) => state.board);
  const boardScrollContainerRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  const { visibleChannelStatuses, isLoadingChannelStatusData, hasNextStatusPage, emptyChannelStatusIds } =
    useGetPaginatedChannelStatuses({
      clinicId,
      selectedChannelStatusIds:
        clinicId && boardSettings?.selectedChannelStatusIdsMap
          ? boardSettings.selectedChannelStatusIdsMap[clinicId]
          : [],
      channelStatusPageNumber,
      currentView: boardSettings?.currentView,
      dateRange: boardSettings?.specificDateRange,
    });

  const fetchMoreColumns = useCallback(async () => {
    const _container = boardScrollContainerRef?.current;

    if (_container) {
      const { scrollLeft, clientWidth, scrollWidth } = _container;

      if (hasNextStatusPage && scrollLeft + clientWidth >= scrollWidth) {
        await dispatch(updateBoardState({ channelStatusPageNumber: channelStatusPageNumber + 1 }));
      }
    }
  }, [channelStatusPageNumber, dispatch, hasNextStatusPage]);

  // Listen for update to collapse empty columns value
  // If true, check to see if more columns need to be fetched to fill the screen
  useEffect(() => {
    if (boardSettings?.shouldHideEmptyColumns) {
      fetchMoreColumns();
    }
  }, [fetchMoreColumns, boardSettings?.shouldHideEmptyColumns]);

  // If the device screen size is large enough, 4 columns may not be enough to fill the screen
  // If that is the case, we need to load more groups so the columns overflow the right edge
  // This allows the user to be able to scroll to load more
  useEffect(() => {
    if (!isLoadingChannelStatusData && hasNextStatusPage) {
      fetchMoreColumns();
    }
  }, [isLoadingChannelStatusData, hasNextStatusPage, fetchMoreColumns]);

  return (
    <HStack
      className="Board__Columns--ScrollContainer"
      h="100%"
      w="100%"
      spacing={4}
      overflowX="auto"
      py={2}
      px={4}
      onScroll={fetchMoreColumns}
      ref={boardScrollContainerRef}
    >
      {isLoadingChannelStatusData ? (
        <LoadingColumn />
      ) : (
        <>
          {boardSettings?.currentView === BoardFilterView.Appointments && (
            <AppointmentsColumn
              columnName={NO_STATUS}
              columnColor="border.default"
              onEditAppointment={onEditAppointment}
              channelStatusId={NO_STATUS}
              unreadChannelIds={unreadChannelIds}
              fetchMoreColumns={fetchMoreColumns}
              isEmpty={false}
              isCollapsed={false}
              updateBoardSettings={updateBoardSettings}
            />
          )}
          {visibleChannelStatuses?.map((channelStatus) => {
            const isEmpty = emptyChannelStatusIds?.includes(channelStatus.id) || isLoadingChannelStatusData;
            const isCollapsed = boardSettings?.collapsedColumnsMap[clinicId]?.includes(channelStatus.id) || false;

            return boardSettings?.currentView === BoardFilterView.Appointments ? (
              <AppointmentsColumn
                key={channelStatus.id}
                columnName={channelStatus.name}
                columnColor={channelStatus.color || 'background.default'}
                onEditAppointment={onEditAppointment}
                channelStatusId={channelStatus.id}
                unreadChannelIds={unreadChannelIds}
                channelStatusAction={channelStatus.channelStatusAction}
                fetchMoreColumns={fetchMoreColumns}
                isEmpty={isEmpty}
                isCollapsed={isCollapsed}
                updateBoardSettings={updateBoardSettings}
              />
            ) : (
              <ConversationsColumn
                key={channelStatus.id}
                columnName={channelStatus.name}
                backgroundColor={channelStatus.color}
                channelStatusId={channelStatus.id}
                unreadChannelIds={unreadChannelIds}
                channelStatusAction={channelStatus.channelStatusAction}
                fetchMoreColumns={fetchMoreColumns}
                isCollapsed={isCollapsed}
                isEmpty={isEmpty}
                updateBoardSettings={updateBoardSettings}
              />
            );
          })}
        </>
      )}
    </HStack>
  );
};

export default ChannelStatusBoard;
