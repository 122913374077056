export enum PersistedStateKeys {
  AllClinicPets = 'allClinicPets',
  AllClinicPetParents = 'allClinicPetParents',
  AppointmentConversationFilter = 'appointmentConversationFilter',
  AppointmentFilters = 'appointmentFilters',
  AppointmentCalendarCurrentDay = 'appointmentCalendarCurrentDay',
  ArchivedFormsExpanded = 'archivedFormsExpanded',
  BoardFilters = 'boardFilters',
  CachedToken = 'cachedToken',
  ChannelListFilter = 'channelListFilter',
  ChannelListFilters = 'channelListFilters',
  ChannelListFilterSelections = 'channelListFilterSelections',
  ChannelListFilterPopoverState = 'channelListFilterPopoverState',
  ClinicUser = 'clinicUser2',
  ConversationDrafts = 'conversationDrafts',
  DiscoveredInvoiceDemoData = 'discoveredInvoiceDemoData',
  ForcedQaLane = 'forcedQaLane',
  HasUnsavedSettingsChanges = 'hasUnsavedSettingsChanges',
  HiddenPostcardsCardClinics = 'hiddenPostcardsCardClinics',
  InvitationAuthToken = 'invitationAuthToken',
  InvoiceId = 'invoiceId',
  IsAutomationsSenderEnabled = 'isAutomationsSenderEnabled',
  IsDeveloperModeEnabled = 'isDeveloperModeEnabled',
  IsDiscoveredInvoiceDemoModeEnabled = 'isDiscoveredInvoiceDemoModeEnabled',
  IsIntroDisabled = 'isIntroDisabled',
  IsMessageComposerExpanded = 'isMessageComposerExpanded',
  IsReaderModeEnabled = 'isReaderModeEnabled',
  IsShowInactivePetsEnabled = 'isShowInactivePetsEnabled',
  LastSelectedTeamChannelIds = 'lastSelectedTeamChannelIds',
  LastSelectedTerminalId = 'lastSelectedTerminalId',
  MassTextAlertsDraftMessage = 'massTextDraftMessage',
  NewClinic = 'newClinic',
  OpenBenefitsModal = 'openBenefitsModal',
  PanelView = 'panelView',
  RefreshUsages = 'refreshUsages',
  RemoveInvoiceAttachment = 'removeInvoiceAttachment',
  ShouldIncludeNotesInConversationExport = 'shouldIncludeNotesInConversationExport',
  ShowSubscriptionsInPaymentReport = 'showSubscriptionsInPaymentReport',
  SidePanelAddFormSelectedClient = 'sidePanelAddFormSelectedClient',
  SidePanelAddFormSelectedPatient = 'sidePanelAddFormSelectedPatient',
  SidePanelCurrentClinicPetId = 'sidePanelCurrentClinicPetId',
  SidePanelCurrentClinicPetParentId = 'sidePanelCurrentClinicPetParentId',
  SidePanelMode = 'sidePanelMode',
  SidePanelOpen = 'sidePanelOpen',
  SidePanelPetParentNameSearch = 'sidePanelPetParentNameSearch',
  SidePanelPhoneNumberSearch = 'sidePanelPhoneNumberSearch',
  SidePanelSearchPetNameSearch = 'sidePanelSearchPetNameSearch',
  SidePanelSelectedClinicPet = 'sidePanelSelectedClinicPet',
  SidePanelSelectedClinicPetParent = 'sidePanelSelectedClinicPetParent',
  SidePanelView = 'sidePanelView',
  TerminalFilterExclusions = 'terminalFilterExclusions',
  TourSeen = 'tourSeen',
  UnlayerScratchTemplate = 'unlayerScratchTemplate',
  UserAppointmentGroupingsFilter = 'userAppointmentGroupingsFilter',
  ViewBenefitSource = 'viewBenefitSource',
  WritebackConfigurationAlertDismissed = 'writebackConfigurationAlertDismissed',
}
