import { useMemo } from 'react';
import useClinicUser from 'shared/hooks/useClinicUser';
import {
  AutomationRunActionPromptType,
  useGetAutomationsListQuery,
  useGetGlobalAutomationsListQuery,
  WorkflowEvent,
  WorkflowEventTriggerType,
} from 'shared/types/graphql';
import { useAppSelector } from 'state/hooks';
import { childAutomationEventTypes } from '../state/utils/draft';
import { NextStepSelectValue } from '../types/NextStepSelectValue';
import { getStepValueDisplayProps, StepValueDisplayProps } from '../utils/getStepValueDisplayProps';
import { isConditionalChildAutomation } from '../utils/isConditionalChildAutomation';

interface UseGetNextStepDisplayPropsInput {
  promptType: AutomationRunActionPromptType;
}

export const useGetNextStepDisplayProps = ({
  promptType,
}: UseGetNextStepDisplayPropsInput): { options: StepValueDisplayProps[]; isLoading: boolean } => {
  const { currentClinicId } = useClinicUser();
  const isGlobalEditor = useAppSelector((state) => state.automations.isGlobalEditor);

  const { data: automationsListData, loading: isLoadingAutomationsList } = useGetAutomationsListQuery({
    variables: {
      clinicId: currentClinicId || '',
    },
    skip: !currentClinicId || isGlobalEditor,
  });

  const { data: globalAutomationsListData, loading: isLoadingGlobalAutomationsList } = useGetGlobalAutomationsListQuery(
    {
      skip: !isGlobalEditor,
    },
  );

  const allAutomationsList = useMemo(
    () =>
      isGlobalEditor
        ? globalAutomationsListData?.findManyWorkflowEventSetting || []
        : automationsListData?.findManyWorkflowEventSetting || [],
    [automationsListData, globalAutomationsListData, isGlobalEditor],
  );

  const isLoading = useMemo(
    () => isLoadingAutomationsList || isLoadingGlobalAutomationsList,
    [isLoadingAutomationsList, isLoadingGlobalAutomationsList],
  );

  const eventTypes = useMemo(() => {
    const types = [...childAutomationEventTypes];

    // Add for H2H automations
    if (isGlobalEditor) {
      types.push(WorkflowEvent.InvoiceLineItemCreated);
    }

    return types;
  }, [isGlobalEditor]);

  const childAutomations = useMemo(
    () =>
      allAutomationsList?.filter(
        ({ triggerType, event }) => triggerType === WorkflowEventTriggerType.Manual && eventTypes.includes(event),
      ) ?? [],
    [allAutomationsList, eventTypes],
  );

  const childAutomationOptions = useMemo(() => {
    if (!childAutomations) return [];

    const options: StepValueDisplayProps[] = childAutomations.map((automation) => {
      return {
        ...getStepValueDisplayProps(automation.id),
        label: `${automation.name}${automation.isPublished ? '' : ' (Inactive)'}`,
        isConditionalChild: isConditionalChildAutomation(automation),
      };
    });

    return options;
  }, [childAutomations]);

  const options = useMemo(() => {
    if (promptType === AutomationRunActionPromptType.Statement) {
      return [
        {
          ...getStepValueDisplayProps(NextStepSelectValue.EndAutomation),
        },
      ];
    }
    return [
      NextStepSelectValue.SendAMessage,
      NextStepSelectValue.NextPrompt,
      NextStepSelectValue.EndAutomation,
      NextStepSelectValue.NewAutomation,
    ]
      .map((value) => getStepValueDisplayProps(value))
      .concat(childAutomationOptions);
  }, [childAutomationOptions, promptType]);

  return { options, isLoading };
};
