import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import addMinutes from 'date-fns/addMinutes';
import formatISO from 'date-fns/formatISO';
import { IOption, Modal, ModalSizes, useModal } from '@televet/televet-ui';
import { useToast } from '@televet/kibble-ui/build/components/Toast';
import useClinicUser from 'shared/hooks/useClinicUser';
import startOfDay from 'date-fns/startOfDay';
import startOfHour from 'date-fns/startOfHour';
import isEqual from 'date-fns/isEqual';
import sortBy from 'lodash-es/sortBy';
import useAppointmentGrouping from 'shared/components/SidePanel/components/SchedulerPanel/hooks/useAppointmentGrouping';
import {
  SearchClinicPetInput,
  WorkflowEventSetting,
  useFindUniqueAppointmentLazyQuery,
  AppointmentTypeWorkflowsSelectionFragment,
  useGetAppointmentOptionsQuery,
  SurveyStep,
  ClinicRoomBaseSelectionFragment,
  ClinicEmployeeBaseSelectionFragment,
  useGetAppointmentPetsLazyQuery,
  GetPaginatedAppointmentsDocument,
} from 'shared/types/graphql';
import { ModalNames } from 'shared/enums/ModalNames';
import { GraphQLErrorPolicies } from 'shared/enums/GraphQLErrorPolicies';
import { GraphQLFetchPolicies } from 'shared/enums/GraphQLFetchPolicies';
import AppointmentForm, { FormValues } from './components/AppointmentForm';
import { Mixpanel } from 'shared/utils/mixpanel';
import { GET_CLINIC_WORKFLOWS_EVENT_SETTINGS } from 'shared/queries/workflows';

import DevModeDisplayJSON from 'pages/Settings/components/DevModeDisplayJSON';
import { getIsChildAutomation } from 'pages/Automations/hooks';

export enum ScheduleTypes {
  Room = 'Room',
  AppointmentType = 'AppointmentType',
  ClinicEmployee = 'ClinicEmployee',
  UserGrouping = 'UserGrouping',
}

export interface DefaultScheduleBlock {
  id: string;
  type: ScheduleTypes;
}

interface IAppointmentModal {
  startDate: Date;
  durationInMinutes: number;
  defaultAppointmentDurationInMinutes: number;
  defaultScheduleBlock?: DefaultScheduleBlock;
  appointmentId?: string;
}

const petSearchPageSize = 25;

const DEFAULT_OPTION: IOption = { text: 'None', value: { id: '', name: 'None', firstName: 'None' } };

const AppointmentModal = ({
  startDate,
  durationInMinutes: durationInMinutesProp,
  defaultAppointmentDurationInMinutes,
  appointmentId,
}: IAppointmentModal): JSX.Element | null => {
  const apolloClient = useApolloClient();
  const [mutationLoading, setMutationLoading] = useState<boolean>(false);
  const { closeModal } = useModal();
  const toast = useToast();
  const { currentClinicId } = useClinicUser();
  const [, { createAppointment, updateAppointment, cancelAppointment }] = useAppointmentGrouping();

  const [findClinicPets, { data: clinicPetsData }] = useGetAppointmentPetsLazyQuery({
    fetchPolicy: GraphQLFetchPolicies.NetworkOnly,
  });

  /**
   * The loading state of this constant tells us whether or not we are ready to render the form
   */
  const [getAppointment, { data: appointmentData, loading: appointmentDataLoading }] =
    useFindUniqueAppointmentLazyQuery({
      errorPolicy: GraphQLErrorPolicies.All,
      fetchPolicy: GraphQLFetchPolicies.NetworkOnly,
    });

  const { data: workflowEventSettings, loading: workflowOptionsLoading } = useQuery(
    GET_CLINIC_WORKFLOWS_EVENT_SETTINGS,
    {
      variables: {
        where: {
          clinic: {
            id: { equals: currentClinicId },
          },
        },
      },
      fetchPolicy: GraphQLFetchPolicies.CacheAndNetwork,
    },
  );

  const { data: appointmentOptions, loading: appointmentOptionsLoading } = useGetAppointmentOptionsQuery({
    variables: { clinicId: currentClinicId || '' },
    skip: !currentClinicId,
  });

  useEffect(() => {
    if (!appointmentId) return;
    getAppointment({
      variables: {
        where: {
          id: appointmentId,
        },
      },
    });
  }, [getAppointment, appointmentId]);

  const onFindClinicPets = useCallback(
    (petSearch: string) => {
      if (!petSearch) return;

      const whereInput: SearchClinicPetInput = {
        name: petSearch,
        petParentNames: petSearch,
      };

      findClinicPets({
        variables: {
          whereInput: whereInput,
          pageSize: petSearchPageSize,
        },
      });
    },
    [findClinicPets],
  );

  /**
   * Where all the magic happens
   */
  const onAppointmentSubmit = async (values: FormValues): Promise<void> => {
    setMutationLoading(true);

    const {
      clinicEmployeeId,
      roomId,
      appointmentTypeId,
      startAt,
      description,
      petId,
      durationInMinutes,
      workflows,
      clinicPetParents,
    } = values;

    const assignedClinicPetParents: string[] = [];
    Object.keys(clinicPetParents || {}).map((clinicPetParentId): boolean => {
      if (clinicPetParents && clinicPetParents[clinicPetParentId]) {
        assignedClinicPetParents.push(clinicPetParentId);
      }
      return true;
    });

    const disconnectedPetParents = appointmentData?.findUniqueAppointment?.clinicPetParents
      ?.filter((pp) => {
        return clinicPetParents ? !clinicPetParents[pp.id] : false;
      })
      .map((pp) => {
        return { id: pp.id };
      });

    const disconnectedClinicEmployees = appointmentData?.findUniqueAppointment?.clinicEmployees?.map((ce) => {
      return { id: ce.id };
    });

    Mixpanel.track('Workflow type submitted', { workflows });
    // Type is set below before making the api call
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: any = {
      description,
      clinic: { connect: { id: currentClinicId } },
      appointmentType: appointmentTypeId ? { connect: { id: appointmentTypeId } } : { disconnect: true },
      startAt: formatISO(startAt),
      durationInMinutes,
      workflows,
      clinicPet: { connect: { id: petId } },
      room: roomId ? { connect: { id: roomId } } : { disconnect: true },
      clinicPetParents: {
        connect: assignedClinicPetParents.map((id) => {
          return { id };
        }),
        disconnect: disconnectedPetParents,
      },
    };

    if (!!surveyParentWorkflows.length) {
      // add both survey workflows, backend handles filtering
      const surveyWorkflowObjects = surveyParentWorkflows.map((workflow: WorkflowEventSetting) => ({
        id: workflow.id,
      }));
      workflows.push(...surveyWorkflowObjects);
    }

    if (appointmentId) {
      if (clinicEmployeeId) {
        data.clinicEmployees = { set: [{ id: clinicEmployeeId }] };
      } else {
        data.clinicEmployees = { disconnect: disconnectedClinicEmployees };
      }
      data.workflows = { set: workflows };
      await updateAppointment(appointmentId, data);
      toast({ title: 'Appointment updated', status: 'success' });
    } else {
      if (!appointmentTypeId) {
        delete data.appointmentType;
      }
      if (clinicEmployeeId) {
        data.clinicEmployees = { connect: [{ id: clinicEmployeeId }] };
      }
      if (!roomId) {
        data.room = undefined;
      }
      data.workflows = { connect: workflows };
      await createAppointment(data);
      toast({ title: 'Appointment created', status: 'success' });
    }
    apolloClient.refetchQueries({ include: [GetPaginatedAppointmentsDocument] });
    setMutationLoading(false);
    closeModal();
  };

  const onDeleteAppointment = async (): Promise<void> => {
    if (!appointmentId) return;
    setMutationLoading(true);
    await cancelAppointment(appointmentId);
    setMutationLoading(false);
    apolloClient.refetchQueries({ include: [GetPaginatedAppointmentsDocument] });
    closeModal();
  };

  const clinicPetParents = useMemo(() => {
    const appointment = appointmentData?.findUniqueAppointment;

    if (appointment?.clinicPetParents) {
      const clinicPetParentMap: Record<string, boolean> = {};
      appointment.clinicPetParents.forEach((clinicPetParent) => {
        clinicPetParentMap[clinicPetParent.id] = true;
      });
      return clinicPetParentMap;
    }
    return null;
  }, [appointmentData]);

  const surveyParentWorkflows = useMemo(() => {
    return workflowEventSettings?.findManyWorkflowEventSetting.filter(
      (setting: WorkflowEventSetting) => !!setting.surveySettingId && setting.surveyStep === SurveyStep.Rating,
    );
  }, [workflowEventSettings?.findManyWorkflowEventSetting]);

  const filteredWorkflowEventSettings = useMemo(() => {
    return (
      workflowEventSettings?.findManyWorkflowEventSetting.filter((setting: WorkflowEventSetting) => {
        const { surveySettingId, event, triggerType } = setting;
        const isSurveyWorkflow = !!surveySettingId;
        const isChildWorkflow = getIsChildAutomation({
          automationType: event,
          triggerType: triggerType,
        });
        return !isSurveyWorkflow && !isChildWorkflow;
      }) || []
    );
  }, [workflowEventSettings?.findManyWorkflowEventSetting]);

  /**
   * The 4 variables below now use IOption[] structures so they can be selected from our custom dropdown,
   * rather than the standard HTML select input as we were previously using.
   */
  const workflowOptions: IOption[] = useMemo(() => {
    return sortBy(
      filteredWorkflowEventSettings.map((setting: WorkflowEventSetting) => ({
        text: setting.name,
        value: setting,
        isSelected: !!appointmentData?.findUniqueAppointment?.workflows?.find((workflow) => workflow.id === setting.id),
      })),
      (o) => o.text?.toLowerCase(),
    );
  }, [filteredWorkflowEventSettings, appointmentData?.findUniqueAppointment?.workflows]);

  const clinicRoomsOptions: IOption[] = useMemo(() => {
    if (appointmentOptions?.clinicRooms) {
      return [DEFAULT_OPTION].concat(
        sortBy(
          appointmentOptions.clinicRooms.map((room) => {
            return {
              text: room.name,
              value: room,
            };
          }),
          (o: IOption) => o.text?.toLowerCase(),
        ),
      );
    }
    return [];
  }, [appointmentOptions]);

  const clinicEmployeesOptions: IOption[] = useMemo(() => {
    if (appointmentOptions?.clinicEmployees) {
      return [DEFAULT_OPTION].concat(
        sortBy(
          appointmentOptions.clinicEmployees.map((employee) => {
            return {
              text: `${employee.firstName} ${employee.lastName}`,
              value: employee,
            };
          }),
          (o) => o.text?.toLowerCase(),
        ),
      );
    }
    return [];
  }, [appointmentOptions]);

  const appointmentTypesOptions: IOption[] = useMemo(() => {
    if (appointmentOptions?.appointmentTypes) {
      return [DEFAULT_OPTION].concat(
        appointmentOptions.appointmentTypes.map((appointmentType) => {
          return {
            text: appointmentType.name,
            value: appointmentType,
          };
        }),
      );
    }
    return [];
  }, [appointmentOptions]);

  /**
   * These two values now use Date objects, instead of strings with hours and minutes offset from midnight
   */
  const defaultStartDateTime = useMemo((): Date => {
    // start day defaults to midnight if you click on appointment button
    if (isEqual(startOfDay(startDate), startDate)) return startOfHour(new Date());

    // Default for new appointments will now use a time close to the current time
    return startDate;
  }, [startDate]);

  const defaultEndDateTime = useMemo((): Date => {
    if (appointmentId) {
      return addMinutes(defaultStartDateTime, durationInMinutesProp);
    }
    return addMinutes(defaultStartDateTime, defaultAppointmentDurationInMinutes);
  }, [durationInMinutesProp, defaultAppointmentDurationInMinutes, defaultStartDateTime, appointmentId]);

  /**
   * These props being passed down now use the full object, instead of just the ID,
   * so we can do more complex operations in the appointment form.
   */
  const defaultRoom: ClinicRoomBaseSelectionFragment | null =
    clinicRoomsOptions.length > 0 ? clinicRoomsOptions[0].value : null;
  const defaultAppointmentType: AppointmentTypeWorkflowsSelectionFragment | null =
    appointmentTypesOptions.length > 0 ? appointmentTypesOptions[0].value : null;
  const defaultClinicEmployee: ClinicEmployeeBaseSelectionFragment | null =
    clinicEmployeesOptions.length > 0 ? clinicEmployeesOptions[0].value : null;

  const appointmentRoom = useMemo(
    () => (appointmentData?.findUniqueAppointment?.room ? appointmentData?.findUniqueAppointment.room : defaultRoom),
    [appointmentData?.findUniqueAppointment?.room, defaultRoom],
  );

  const appointmentAppointmentType = useMemo(
    () =>
      appointmentData?.findUniqueAppointment?.appointmentType
        ? appointmentData?.findUniqueAppointment.appointmentType
        : defaultAppointmentType,
    [appointmentData, defaultAppointmentType],
  );

  const appointmentClinicEmployee = useMemo(
    () =>
      !!appointmentData?.findUniqueAppointment?.clinicEmployees?.length
        ? appointmentData?.findUniqueAppointment.clinicEmployees[0]
        : defaultClinicEmployee,
    [appointmentData?.findUniqueAppointment?.clinicEmployees, defaultClinicEmployee],
  );

  const appointmentDescription: string = useMemo(
    () =>
      appointmentData?.findUniqueAppointment?.description ? appointmentData?.findUniqueAppointment.description : '',
    [appointmentData],
  );

  return (
    <Modal
      name={ModalNames.Appointment}
      size={ModalSizes.md}
      overlayOpacity={0.11}
      disableBackdropClick
      includeCloseButton={false}
      style={{ zIndex: 100 }}
    >
      {/* Do not render the appointment form until the request has returned, or else the form 
      will be stuck with the default values as initial form state */}
      {!appointmentDataLoading && !appointmentOptionsLoading && !workflowOptionsLoading && (
        <AppointmentForm
          workflowOptions={workflowOptions}
          rooms={clinicRoomsOptions}
          workflowEventSettings={workflowEventSettings?.findManyWorkflowEventSetting || []}
          employees={clinicEmployeesOptions}
          appointmentTypes={appointmentTypesOptions}
          clinicEmployee={appointmentClinicEmployee}
          room={appointmentRoom}
          petId={appointmentData?.findUniqueAppointment?.clinicPet?.id || ''}
          clinicPet={appointmentData?.findUniqueAppointment?.clinicPet || null}
          appointmentType={appointmentAppointmentType}
          startAt={startDate}
          description={appointmentDescription}
          startTime={defaultStartDateTime}
          endTime={defaultEndDateTime}
          clinicPetParents={clinicPetParents}
          onFindClinicPets={onFindClinicPets}
          petSearchResults={clinicPetsData?.clinicPetSearch || []}
          onAppointmentSubmit={onAppointmentSubmit}
          appointmentId={appointmentData?.findUniqueAppointment?.id}
          onCancel={closeModal}
          onDelete={onDeleteAppointment}
          isLoading={mutationLoading}
          defaultAppointmentDurationInMinutes={defaultAppointmentDurationInMinutes}
          pimsId={appointmentData?.findUniqueAppointment?.pimsId || ''}
        />
      )}
      <DevModeDisplayJSON src={appointmentData} />
    </Modal>
  );
};

export default AppointmentModal;
