import differenceInMonths from 'date-fns/differenceInMonths';
import { useEffect, useMemo, useState } from 'react';
import { GraphQLFetchPolicies } from 'shared/enums/GraphQLFetchPolicies';
import { CareBenefitType, useGetCareEnrollmentInfoQuery } from 'shared/types/graphql';
import { useManageMembershipContext } from '../components/ManageMembership/context/ManageMembershipContext';
import { PaymentsMade } from '../components/ManageMembership/types';
import { centsToDollars, roundTo } from '../utils/currency';
import { getRenewalCycleInMonths } from '../utils/planRenewalCycle';
import { UsePetBenefitCareBenefit } from './usePetBenefits';

interface UseCancellationCalculatorInput {
  enrollmentId: string;
  benefitUsages: UsePetBenefitCareBenefit[];
}

interface ItemInfo {
  usedValue: number;
  savings?: number;
  discountedValue: number;
  quantity?: number;
  price?: number;
}

interface UseCancellationCalculatorOutput {
  hasPlanDiscount: boolean;
  accountCredit: ItemInfo;
  exams: ItemInfo;
  discount: ItemInfo;
  teletriage: ItemInfo;
  fees: ItemInfo;
  subtotal: ItemInfo;
  paymentsMade: PaymentsMade;
  balanceDue: number;
  balanceDueWithoutManualAdjustment: number;
  totalSavings: number;
  remainingMembershipBalance: PaymentsMade;
  isLoading: boolean;
}

export const useCancellationCalculator = ({
  enrollmentId,
  benefitUsages,
}: UseCancellationCalculatorInput): UseCancellationCalculatorOutput => {
  const { setBalanceDue, applyDiscounts, includeFees, includeManualAdjustment, manualAdjustment } =
    useManageMembershipContext();
  const [balanceDueWithoutManualAdjustment, setBalanceDueWithoutManualAdjustment] = useState(0);
  const [internalBalanceDue, setInternalBalanceDue] = useState(0);
  const manualAdjustmentValue = useMemo(() => Number(manualAdjustment), [manualAdjustment]);
  const { data, loading: isLoading } = useGetCareEnrollmentInfoQuery({
    variables: {
      enrollmentId,
    },
    fetchPolicy: GraphQLFetchPolicies.NetworkOnly,
  });

  const cancellationCalcInfo = data?.findUniqueCarePlanEnrollment;
  const plan = cancellationCalcInfo?.plan;
  const hasPlanDiscount = !!plan?.planDiscount;
  const planDiscount = plan?.planDiscount ? plan?.planDiscount / 100 : 0;

  const accountCredit: ItemInfo = useMemo(() => {
    const benefit = benefitUsages.find((usage) => usage.type === CareBenefitType.AccountCredit);
    const usesInCents =
      benefit && benefit.quantity !== undefined && benefit.provided !== undefined
        ? benefit.provided - benefit.quantity
        : 0;
    const usesInDollars = centsToDollars(usesInCents);
    const savings = usesInDollars * planDiscount;
    return {
      usedValue: usesInDollars,
      savings,
      discountedValue: usesInDollars - savings,
    };
  }, [benefitUsages, planDiscount]);

  const exams: ItemInfo = useMemo(() => {
    const benefits = benefitUsages.filter((usage) => usage.type === CareBenefitType.ProvidedService);
    const usesInCents = benefits?.reduce((prev, curr) => prev + curr.costCovered, 0);
    const quantity = benefits?.reduce((prev, curr) => prev + curr.used, 0);
    const usesInDollars = usesInCents / 100;
    const savings = usesInDollars * planDiscount;

    return {
      usedValue: usesInDollars,
      savings,
      discountedValue: usesInDollars - savings,
      quantity,
    };
  }, [benefitUsages, planDiscount]);

  const discount: ItemInfo = useMemo(() => {
    const benefit = benefitUsages.find((usage) => usage.type === CareBenefitType.Discount);
    const usesInCents = benefit?.costCovered || 0;
    const usesInDollars = centsToDollars(usesInCents);
    return {
      usedValue: usesInDollars,
      discountedValue: usesInDollars,
    };
  }, [benefitUsages]);

  const teletriage: ItemInfo = useMemo(() => {
    const teletriageBenefit = plan?.planBenefits.find((pb) => pb.benefit.type === CareBenefitType.Teletriage)?.benefit;
    const teletriageFee = centsToDollars(teletriageBenefit?.reportingExpectedValue || 0);

    const monthsEnrolled = differenceInMonths(new Date(), new Date(cancellationCalcInfo?.startDate)) + 1;

    const totalTeletriage = teletriageFee * monthsEnrolled;
    return {
      usedValue: totalTeletriage,
      discountedValue: totalTeletriage,
      quantity: monthsEnrolled,
      price: teletriageFee,
    };
  }, [cancellationCalcInfo?.startDate, plan?.planBenefits]);

  const paymentsMade: PaymentsMade = useMemo(() => {
    const payments = cancellationCalcInfo?.enrollmentPayments;
    const planPrice = plan?.pricePerRenewal
      ? centsToDollars(plan?.pricePerRenewal) / getRenewalCycleInMonths(plan?.renewalCycle)
      : 0;
    const totalPaid = payments?.reduce((prev, curr) => curr.amount + prev, 0) || 0;
    const totalPaidInDollars = centsToDollars(totalPaid);

    return {
      total: totalPaidInDollars,
      price: planPrice,
      quantity: totalPaidInDollars / planPrice,
    };
  }, [cancellationCalcInfo?.enrollmentPayments, plan?.pricePerRenewal, plan?.renewalCycle]);

  const subtotal: ItemInfo = useMemo(() => {
    const subtotalUsed = accountCredit.usedValue + exams.usedValue + discount.usedValue + teletriage.usedValue;
    const subtotalDiscounted =
      accountCredit.discountedValue + exams.discountedValue + discount.usedValue + teletriage.discountedValue;

    return {
      usedValue: subtotalUsed - paymentsMade.total,
      discountedValue: subtotalDiscounted - paymentsMade.total,
    };
  }, [
    accountCredit.discountedValue,
    accountCredit.usedValue,
    discount.usedValue,
    exams.discountedValue,
    exams.usedValue,
    paymentsMade.total,
    teletriage.discountedValue,
    teletriage.usedValue,
  ]);

  const fees: ItemInfo = useMemo(() => {
    // 5% for all clinics
    const fee = 5 / 100;
    const feeValue = paymentsMade.total * fee;

    return {
      usedValue: feeValue,
      savings: !includeFees ? feeValue : 0,
      discountedValue: includeFees ? feeValue : 0,
    };
  }, [includeFees, paymentsMade.total]);

  const totalSavings: number = useMemo(() => {
    let savings = 0;

    if (accountCredit.savings) {
      savings += accountCredit.savings;
    }

    if (exams.savings) {
      savings += exams.savings;
    }

    savings += discount.usedValue;

    return savings;
  }, [accountCredit.savings, discount.usedValue, exams.savings]);

  useEffect(() => {
    let amountToUse = subtotal.usedValue;

    if (applyDiscounts) {
      if (planDiscount) {
        amountToUse -= totalSavings;
      } else {
        amountToUse -= discount.usedValue;
      }
    }

    if (includeFees) {
      amountToUse += fees.usedValue;
    }

    setBalanceDueWithoutManualAdjustment(parseFloat(`${amountToUse}`));

    if (includeManualAdjustment) {
      amountToUse -= manualAdjustmentValue;
    }

    const parsedAmount = parseFloat(`${amountToUse}`);

    setBalanceDue(roundTo(parsedAmount, 2));
    setInternalBalanceDue(roundTo(parsedAmount, 2));
  }, [
    applyDiscounts,
    discount.usedValue,
    fees.usedValue,
    hasPlanDiscount,
    includeFees,
    includeManualAdjustment,
    manualAdjustment,
    manualAdjustmentValue,
    planDiscount,
    setBalanceDue,
    subtotal.discountedValue,
    subtotal.usedValue,
    totalSavings,
  ]);

  const remainingMembershipBalance: PaymentsMade = useMemo(() => {
    const planPrice = plan?.pricePerRenewal ? centsToDollars(plan?.pricePerRenewal) : 0;
    const monthlyPlanPrice = planPrice / getRenewalCycleInMonths(plan?.renewalCycle);
    const total = planPrice - paymentsMade.total;
    return {
      quantity: total / monthlyPlanPrice,
      price: monthlyPlanPrice,
      total,
    };
  }, [paymentsMade.total, plan?.pricePerRenewal, plan?.renewalCycle]);

  return {
    hasPlanDiscount,
    accountCredit,
    exams,
    discount,
    teletriage,
    fees,
    subtotal,
    paymentsMade,
    balanceDue: internalBalanceDue,
    balanceDueWithoutManualAdjustment,
    totalSavings,
    remainingMembershipBalance,
    isLoading,
  };
};
