import React, { useCallback, useState, useMemo, useRef, useEffect, ReactNode } from 'react';
import * as Sentry from '@sentry/react';
import styled from 'styled-components/macro';
import useClinicUser from 'shared/hooks/useClinicUser';
import MessageComposerKeyboardShortcutHelpModal, { availableKeyboardShortcuts } from './KeyboardShortcutHelpModal';
import {
  ChannelMessageAttachmentType,
  ChannelViewChannelFragment,
  MessageType,
  ChannelStatusAction,
  useHasDeviceThatCanRecievePushQuery,
} from 'shared/types/graphql';
import 'emoji-mart/css/emoji-mart.css';
import { BaseEmoji } from 'emoji-mart';
import Tooltip from 'shared/components/Tooltip';
import { useSnackbar, MessageTypes } from '@televet/televet-ui';
import { IAttachmentOption } from 'pages/Conversations/interfaces/IAttachmentOption';
import MessageComposerAttachment from './Attachments';
import { useSendMessage } from '../../../hooks/useSendMessage';
import { getChannelParticipants } from 'pages/Conversations/utils';
import { Mixpanel } from 'shared/utils/mixpanel';
import { colonsToUnicode } from 'shared/utils';
import { GraphQLFetchPolicies } from 'shared/enums/GraphQLFetchPolicies';
import Checkbox from 'shared/components/Checkbox';
import { useResolutionProvider } from 'shared/providers/ResolutionProvider';
import useChannelDraft from '../../../hooks/useChannelDraft';
import usePrevious from 'shared/hooks/usePrevious';
import { ElementIds } from 'shared/enums/ElementIds';
import MessageComposerToolbar from './Toolbar';
import {
  IKeyboardShortcutElementRefs,
  IAvailableKeyboardShortcuts,
} from 'pages/Conversations/interfaces/IKeyboardShortcutElementRefs';
import MentionsList from './Mentions/MentionsList';
import { IMentionedUser } from './Mentions/MentionsList';
import usePersistedState from 'shared/hooks/usePersistedState';
import { PersistedStateKeys } from 'shared/enums/PersistedStateKeys';
import LinkPetToFormModal from './Attachments/Form/LinkPetToFormModal';
import { WildcardValues } from 'shared/enums/WildcardOptions';
import OpenFormFieldModal from './Attachments/Form/OpenFormFieldModal';
import { useSidePanelSearch } from 'shared/components/SidePanel/components/SearchPanel/state/providers/SearchPanelProvider';
import { HStack, useDisclosure, Button, Icon } from '@televet/kibble-ui';
import DictationButton from './Toolbar/DictationButton';
import getIsAllPetParentsReachableBySms from 'pages/Conversations/utils/getIsAllPetParentsReachableBySms';
import getIsAllPetParentsReachableByPush from 'pages/Conversations/utils/getIsAllPetParentsReachableByPush';
import useFeatureFlag from 'shared/hooks/useFeatureFlag';
import { FeatureFlagName } from 'shared/enums';
import QuickReplyPopover from './QuickReply/QuickReplyPopover';
import { ArchivedOverlay } from './ArchivedOverlay';
import useQuickReplyUsageTracking from './QuickReply/hooks/useQuickReplyUsageTracking';

interface MessageComposerProps {
  channel?: ChannelViewChannelFragment | null;
  isChannelLoading: boolean;
  isTeamChannel: boolean;
  isDirectTeamMessage: boolean;
  sendAsChatMessage: boolean;
  setPreviewData: (fileUrl: string, attachmentType: ChannelMessageAttachmentType, fileName: string) => void;
  onSendAsChatMessageChange: (sendAsChatMessage: boolean) => void;
  setCurrentInvoiceId: (invoiceId: string) => void;
  invoiceClinicPetParentId: string;
  setAttachmentFeePercent: (percent: number) => void;
  attachments: IAttachmentOption[];
  setAttachments: (
    attachments: ((prevAttachments: IAttachmentOption[]) => IAttachmentOption[]) | IAttachmentOption[],
  ) => void;
  updateInvoiceAttachmentDiscount: (invoiceId: string, discount: number) => void;
  setAttachInvoiceClicked?: React.Dispatch<React.SetStateAction<boolean>>;
}

const MessageComposer = ({
  channel,
  isChannelLoading,
  isTeamChannel,
  isDirectTeamMessage,
  sendAsChatMessage,
  setPreviewData,
  onSendAsChatMessageChange,
  setCurrentInvoiceId,
  setAttachmentFeePercent,
  attachments,
  setAttachments,
  setAttachInvoiceClicked,
}: MessageComposerProps): JSX.Element => {
  const { isFeatureEnabled, isFeatureFlagsLoading } = useFeatureFlag();
  const { removeInvoiceAttachment, setRemoveInvoiceAttachment, invoiceId } = useSidePanelSearch();
  const [draftMessageText, setDraftMessageText] = useState('');
  const draftMessageTextRef = useRef<string>('');
  const { clinicUser, currentClinicId, currentClinic } = useClinicUser();
  const { allChannelPets, channelPetParents, channelPetParentIds } = getChannelParticipants(channel?.channelMembers);
  const linkedPets = useMemo(
    () => (channel?.pets === undefined || channel?.pets.length === 0 ? allChannelPets : channel.pets),
    [channel?.pets, allChannelPets],
  );
  const [stagedFiles, setStagedFiles] = useState<File[]>([]);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [messageType, setMessageType] = useState<MessageType>(MessageType.Message);
  const {
    isOpen: isShortcutHelpModalOpen,
    onOpen: openShortcutHelpModal,
    onClose: closeShortcutHelpModal,
  } = useDisclosure();
  const {
    isOpen: isOpenFormFieldModalOpen,
    onOpen: onOpenFormFieldModalOpen,
    onClose: onOpenFormFieldModalClose,
  } = useDisclosure();
  const {
    isOpen: isInvoicingDrawerOpen,
    onClose: onInvoicingDrawerClose,
    onToggle: onInvoicingDrawerToggle,
  } = useDisclosure();

  const inputTextareaRef = useRef<HTMLTextAreaElement | null>(null);
  const invoiceAmountInputRef = useRef<HTMLInputElement | null>(null);
  const { addSnackbar } = useSnackbar();
  const { isMobile } = useResolutionProvider();
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
  const [hasPotentialBenefits, setHasPotentialBenefits] = useState(false);
  const [invoiceForSidePanelId, setInvoiceForSidePanelId] = useState<string>('');
  const [isLinkPetToFormModalOpen, setIsLinkPetToFormModalOpen] = useState(false);
  const [selectedFormAttachment, setSelectedFormAttachment] = useState<IAttachmentOption | undefined>();
  const [shouldOpenOpenFormModal, setShouldOpenOpenFormModal] = useState(false);
  const [isDictationEnabled] = usePersistedState('isDictationEnabled', false);
  /**
   * Focus on message input when switching channel or message type
   */
  const channelId = channel?.id;
  const { addSuggestion, discardSuggestion, handleMessageSent, setSelectedSuggestion } =
    useQuickReplyUsageTracking(channelId);

  useEffect(() => {
    if (inputTextareaRef.current && channelId && !isMobile) {
      inputTextareaRef.current.focus();
    }
  }, [inputTextareaRef, channelId, messageType, isMobile]);

  const { channelDraft, setChannelDraft, clearChannelDraft } = useChannelDraft({ channelId: channelId || '' });

  const resetToPersistedDraftState = useCallback((): void => {
    const { messageType, draftMessageText, attachments } = channelDraft;
    setMessageType(messageType);
    setDraftMessageText(draftMessageText);
    setAttachments(attachments);
    setStagedFiles([]);
  }, [channelDraft, setAttachments]);

  /**
   * When channel ID changes, populate inputs with draft values from local storage
   */
  const previousChannelId = usePrevious(channelId);
  useEffect(() => {
    if (channelId && channelId !== previousChannelId) {
      resetToPersistedDraftState();
    }
  }, [channelId, previousChannelId, resetToPersistedDraftState]);

  /**
   * Handle @ mentions
   */
  const [mentionsListIsVisible, setMentionsListIsVisible] = useState<boolean>(false);
  const [mentionedUsersDraft, setMentionedUsersDraft] = useState<IMentionedUser[] | []>([]);
  const shouldShowMentionsList = (messageType === MessageType.Note || isTeamChannel) && !isDirectTeamMessage;

  const handleTeamMembersMentionList = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const fullMessageText = e.currentTarget.value;

    if (!fullMessageText.includes('@')) {
      setMentionsListIsVisible(false);
      return;
    }
  }, []);

  const handleDraftMessageTextChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const message = event.target.value;
      const unicodeMessage = colonsToUnicode(event.target.value);
      if (message !== unicodeMessage) {
        Mixpanel.track('Emoji typed manually', { clinicId: currentClinicId });
      }
      if (!isSendingMessage) {
        setDraftMessageText(unicodeMessage);
      }

      if (shouldShowMentionsList && mentionsListIsVisible) {
        handleTeamMembersMentionList(event);
      }
    },
    [currentClinicId, isSendingMessage, shouldShowMentionsList, mentionsListIsVisible, handleTeamMembersMentionList],
  );

  /**
   * Handle keyboard shortcuts
   */
  const messageButtonRef = useRef<HTMLButtonElement | null>(null);
  const invoiceButtonRef = useRef<HTMLButtonElement | null>(null);
  const formsButtonRef = useRef<HTMLButtonElement | null>(null);
  const attachmentsButtonRef = useRef<HTMLButtonElement | null>(null);
  const giphyButtonRef = useRef<HTMLDivElement | null>(null);
  const emojiButtonRef = useRef<HTMLDivElement | null>(null);

  const keyboardShortcutElementRefs: IKeyboardShortcutElementRefs = useMemo(
    () => ({
      t: messageButtonRef,
      i: invoiceButtonRef,
      f: formsButtonRef,
      a: attachmentsButtonRef,
      g: giphyButtonRef,
      e: emojiButtonRef,
      h: null,
    }),
    [],
  );

  const handleKeyboardShortcuts = useCallback(
    (value: string) => {
      const shortcutIndex = value.indexOf('//');
      const selectedShortcut = value.slice(shortcutIndex + 2, shortcutIndex + 3);

      // Remove shortcut from message text
      setDraftMessageText((value) => `${value.slice(0, shortcutIndex)}${value.slice(shortcutIndex + 3)}`);

      // Throw warning if not a valid shortcut
      if (!keyboardShortcutElementRefs.hasOwnProperty(selectedShortcut)) {
        addSnackbar({
          type: MessageTypes.Info,
          message: "Whoops! That shortcut doesn't exist. Try '//h' to view a list of all available shortcuts",
          timeout: 3000,
        });
        Mixpanel.track(`Invalid shortcut used`);
        return;
      }

      // Open shortcut help modal
      if (selectedShortcut === 'h') {
        openShortcutHelpModal();
      } else {
        // Trigger corresponding shortcut event
        const toolbarButton = keyboardShortcutElementRefs[selectedShortcut as keyof IKeyboardShortcutElementRefs];
        toolbarButton?.current?.click();
      }

      // Track in Mixpanel
      const shortcutName = availableKeyboardShortcuts.filter(
        (shortcut: IAvailableKeyboardShortcuts) => shortcut.char === selectedShortcut,
      )[0].name;
      Mixpanel.track(`Shortcut used`, { shortcutName });
    },
    [keyboardShortcutElementRefs, openShortcutHelpModal, addSnackbar],
  );

  /**
   * - Store message draft on ref to prevent unnecessary re-renders
   * - Ensure draft stored in local storage is kept in sync
   */
  useEffect(() => {
    draftMessageTextRef.current = draftMessageText;
    if (!isSendingMessage) {
      setChannelDraft({ draftMessageText });
    }
  }, [draftMessageText, draftMessageTextRef, isSendingMessage, setChannelDraft]);

  /**
   * Store changes to messageType or attachments in local storage
   */
  useEffect(() => {
    setChannelDraft({
      messageType,
      attachments,
      fillInTheBlankFormValues:
        channelDraft?.fillInTheBlankFormValues?.filter((draftValues) =>
          attachments.some(({ id }) => id === draftValues.attachmentEntityId),
        ) || [],
    });
    // avoid circular rerender when adding channelDraft as dep
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachments, messageType, setChannelDraft]);

  useEffect(() => {
    if (currentClinicId === 'ckyt3x2tqf3mq08861cl16h6n') {
      // Hardcoded for Avian and Animal Hospital
      onSendAsChatMessageChange(true);
    }
  }, [currentClinicId, onSendAsChatMessageChange]);

  const handleEmojiSelect = useCallback(
    (emoji: BaseEmoji): void => {
      if (!inputTextareaRef.current) return;

      Mixpanel.track('Emoji inserted via drawer', { clinicId: currentClinicId });
      const selectionStart = inputTextareaRef.current.selectionStart;
      const selectionEnd = inputTextareaRef.current.selectionEnd;
      setDraftMessageText((value) => `${value.slice(0, selectionStart)}${emoji.native}${value.slice(selectionEnd)}`);
      const emojiLength = emoji.native.length;
      const newStart = selectionStart + emojiLength;
      const newEnd = selectionEnd + emojiLength;

      inputTextareaRef.current.setSelectionRange(newStart, newEnd);

      setIsEmojiPickerOpen(!isEmojiPickerOpen);
      inputTextareaRef.current?.focus();
      setTimeout(() => {
        inputTextareaRef.current?.setSelectionRange(newStart, newEnd);
      }, 0);
    },
    [currentClinicId, isEmojiPickerOpen, inputTextareaRef],
  );

  const onError = useCallback(
    (error: Error): void => {
      console.error(error);
      Sentry.captureException(error);
      addSnackbar({
        type: MessageTypes.Error,
        message: error.message,
      });
    },
    [addSnackbar],
  );

  const onAttachmentChange = useCallback(
    (attachment: IAttachmentOption) => {
      setAttachments((prevAttachments) => prevAttachments.map((a) => (a.id === attachment.id ? attachment : a)));
    },
    [setAttachments],
  );

  const onAttachmentRemove = useCallback(
    (attachment: IAttachmentOption) => {
      setAttachments((prevAttachments) => {
        const attachments = [...prevAttachments];
        const index = attachments.findIndex((a) => a.id === attachment.id);
        const removal = attachments.splice(index, 1).pop();
        if (removal?.value?.filename) {
          const newFiles = stagedFiles.filter((file: File) => file?.name !== removal.value.filename);
          setStagedFiles(newFiles);
        }
        return attachments;
      });
      setIsLinkPetToFormModalOpen(false);
    },
    [setAttachments, stagedFiles],
  );

  const { sendMessage } = useSendMessage({ channel, onError });

  const validateAttachments = useCallback((): IAttachmentOption[] => {
    const invalidAttachments = attachments.filter((attachment) => {
      return attachment.validationError;
    });
    return invalidAttachments;
  }, [attachments]);

  const handleSendMessage = useCallback(async (): Promise<void> => {
    if (isChannelLoading || !sendMessage || isSendingMessage) {
      // Do nothing
    } else {
      setIsSendingMessage(true);
      try {
        const trimmedMessage = draftMessageTextRef.current.trim();
        const invalidAttachments = validateAttachments();
        const mentionedUsers: IMentionedUser[] = [];

        // Ensure mentioned users haven't been removed from message before notifying them
        mentionedUsersDraft.forEach((user: IMentionedUser) => {
          const userExists = mentionedUsers.find((mentionedUser) => mentionedUser.id === user.id);
          if (userExists) return;

          if (trimmedMessage.includes(`@${user.name}`)) {
            mentionedUsers.push(user);
          }
        });

        if (mentionedUsers.length > 0) {
          Mixpanel.track('One or more users were @mentioned in a message');
        }

        if (invalidAttachments.length) {
          for (const invalidAttachment of invalidAttachments) {
            addSnackbar({ message: invalidAttachment.validationError, type: MessageTypes.Error, timeout: 5000 });
          }
          setIsSendingMessage(false);
          return;
        }

        onInvoicingDrawerClose();

        // Replace {{fillInTheBlank}} fields with stored values
        const fillInTheBlankForms = attachments.filter((attachment) => attachment.isFillInTheBlankForm);
        if (fillInTheBlankForms.length) {
          const draftValues = channelDraft?.fillInTheBlankFormValues;

          // Ensure all fields have been given values and don't send message if not
          // Empty fields will just have empty string values in the draft state
          let firstIncompleteFormId = draftValues.find(({ inputValues }) =>
            inputValues?.some((values) => values.includes('')),
          )?.attachmentEntityId;

          // check for any FITB forms without draft data
          if (!firstIncompleteFormId) {
            firstIncompleteFormId = fillInTheBlankForms.find(
              (attachment) => !draftValues.some(({ attachmentEntityId }) => attachmentEntityId === attachment.id),
            )?.id;
          }

          if (firstIncompleteFormId) {
            setIsSendingMessage(false);
            const firstIncompleteForm = attachments.find(({ id }) => id === firstIncompleteFormId);

            if (firstIncompleteForm) {
              setSelectedFormAttachment(firstIncompleteForm);
              onOpenFormFieldModalOpen();
            }

            return;
          }

          for (let i = 0; i < fillInTheBlankForms.length; i++) {
            const { id, formTemplateContent } = fillInTheBlankForms[i];
            const modifiedFormTemplateContent = { ...formTemplateContent };
            const modifiedQuestions = [...modifiedFormTemplateContent.questions];
            const blankValues =
              draftValues
                .find((values) => values.attachmentEntityId == id)
                ?.inputValues.flat()
                .filter(Boolean) || [];

            // Replace wildcards with saved values
            for (const value of blankValues) {
              const intro = modifiedFormTemplateContent.introMessage;
              if (intro.includes(WildcardValues.FillInTheBlank)) {
                modifiedFormTemplateContent.introMessage = intro.replace(WildcardValues.FillInTheBlank, value);
              } else {
                for (let j = 0; j < modifiedQuestions.length; j++) {
                  const question = modifiedQuestions[j];
                  if (question.prompt.includes(WildcardValues.FillInTheBlank)) {
                    const newQuestion = { ...modifiedQuestions[j] };
                    newQuestion.prompt = question.prompt.replace(WildcardValues.FillInTheBlank, value);
                    modifiedQuestions[j] = newQuestion;
                    break;
                  }
                }
              }
            }

            modifiedFormTemplateContent.questions = modifiedQuestions;
            fillInTheBlankForms[i].formTemplateContent = modifiedFormTemplateContent;
            onAttachmentChange(fillInTheBlankForms[i]);
          }
        }

        if (!!stagedFiles.length || !!attachments.length || !!trimmedMessage) {
          const result = await sendMessage({
            text: trimmedMessage,
            attachments,
            messageType,
            sendAsChatMessage,
            mentionedUsers,
          });
          if (result) {
            setDraftMessageText('');
            clearChannelDraft(channelId || '', { messageType });
            setStagedFiles([]);
            setAttachments([]);

            // AI messages handler
            handleMessageSent(result, trimmedMessage);
          }
        }
      } catch (e: unknown) {
        if (e instanceof Error) {
          addSnackbar({ message: e.message, type: MessageTypes.Error });
        }
      }
      setIsSendingMessage(false);
    }
  }, [
    isChannelLoading,
    sendMessage,
    isSendingMessage,
    validateAttachments,
    mentionedUsersDraft,
    onInvoicingDrawerClose,
    attachments,
    stagedFiles.length,
    addSnackbar,
    channelDraft?.fillInTheBlankFormValues,
    onOpenFormFieldModalOpen,
    onAttachmentChange,
    messageType,
    sendAsChatMessage,
    clearChannelDraft,
    channelId,
    setAttachments,
    handleMessageSent,
  ]);

  const messageInputPlaceholder = useMemo(() => {
    if (messageType === MessageType.Note) {
      return isTeamChannel ? 'Make a note...' : `Make internal note or type '//h' for shortcuts...`;
    }
    return isTeamChannel
      ? `Type a message for ${channel?.friendlyName || 'your team'}...`
      : `Type a message or '//h' for shortcuts...`;
  }, [channel, isTeamChannel, messageType]);

  const [isMessageComposerExpanded, setIsMessageComposerExpanded] = usePersistedState(
    PersistedStateKeys.IsMessageComposerExpanded,
    false,
  );

  const handleComposerToggle = useCallback(() => {
    let mixPanelEvent = '';
    if (isMessageComposerExpanded) {
      mixPanelEvent = 'Message composer contracted';
    } else {
      mixPanelEvent = 'Message composer expanded';
    }
    Mixpanel.track(mixPanelEvent);
    setIsMessageComposerExpanded((value) => !value);
  }, [isMessageComposerExpanded, setIsMessageComposerExpanded]);

  useEffect(() => {
    if (removeInvoiceAttachment && !!attachments.length) {
      const attachment = attachments.find((attachment) => attachment.text && attachment.text === 'Invoice');
      if (attachment) {
        onAttachmentRemove(attachment);
      }
      setRemoveInvoiceAttachment(false);
    }
  }, [
    removeInvoiceAttachment,
    invoiceId,
    onAttachmentRemove,
    attachments,
    setRemoveInvoiceAttachment,
    invoiceForSidePanelId,
  ]);

  // Open OpenFormModal after LinkPetToFormModal is closed after attaching new form
  useEffect(() => {
    if ((!isLinkPetToFormModalOpen || !linkedPets) && shouldOpenOpenFormModal && selectedFormAttachment) {
      onOpenFormFieldModalOpen();
      setShouldOpenOpenFormModal(false);
    }
  }, [isLinkPetToFormModalOpen, linkedPets, onOpenFormFieldModalOpen, selectedFormAttachment, shouldOpenOpenFormModal]);

  const { data: deviceData } = useHasDeviceThatCanRecievePushQuery({
    variables: {
      clinicPetParentIds: channelPetParentIds,
    },
    skip: !channelPetParentIds,
    fetchPolicy: GraphQLFetchPolicies.CacheAndNetwork,
    nextFetchPolicy: GraphQLFetchPolicies.CacheFirst,
  });

  const isQuickReplyEnabled = useMemo(() => {
    return !isFeatureFlagsLoading && isFeatureEnabled(FeatureFlagName.AiQuickReply);
  }, [isFeatureEnabled, isFeatureFlagsLoading]);

  const isConversationInactive = useMemo(() => {
    const channelStatus = channel?.channelStatus?.channelStatusAction;

    if (isTeamChannel) {
      return !channel?.isActive;
    }

    if (!channelStatus) return;

    return [ChannelStatusAction.Inactive, ChannelStatusAction.InactivePermanently].includes(channelStatus);
  }, [channel, isTeamChannel]);

  if (isConversationInactive) {
    return (
      <ArchivedOverlay
        isTeamChannel={isTeamChannel}
        currentClinicId={currentClinicId}
        channelMembers={channel?.channelMembers}
        channelId={channel?.id}
        channelStatusAction={channel?.channelStatus?.channelStatusAction}
      />
    );
  }

  return (
    <MessageComposerContainer isMessageComposerExpanded={isMessageComposerExpanded}>
      <MessageComposerHeader>
        {!isTeamChannel && (
          <MessageTabs>
            <LeftSide>
              <MessageTab
                onClick={(): void => setMessageType(MessageType.Message)}
                className={messageType === MessageType.Message ? 'active' : ''}
                messageType={MessageType.Message}
              >
                <Icon name="message" />
                {isTeamChannel ? 'Team Message' : 'Message'}
              </MessageTab>
              <MessageTab
                onClick={(): void => setMessageType(MessageType.Note)}
                className={messageType === MessageType.Note ? 'active' : ''}
                messageType={MessageType.Note}
              >
                <Icon name="pen" />
                Note
              </MessageTab>
            </LeftSide>
            <RightSide></RightSide>
          </MessageTabs>
        )}
        {((): ReactNode => {
          const showCheckbox =
            !isTeamChannel && Boolean(currentClinic?.hasSmsConversations) && messageType === MessageType.Message;
          const isSmsEnabled = !getIsAllPetParentsReachableBySms(channelPetParents);
          const { shouldDisable: isPushEnabled, enabledPetParentsLength } =
            getIsAllPetParentsReachableByPush(channelPetParents);

          const currentUserDisplayName =
            clinicUser?.nameDisplay || [clinicUser?.firstName, clinicUser?.lastName].join(' ').trim();

          const isFeatureEnabledAndHasPushEnabled = isPushEnabled && !!deviceData?.findFirstClinicPetParentDevice;

          const disabledContent = (): string => {
            if (isFeatureEnabledAndHasPushEnabled) {
              if (enabledPetParentsLength > 1) {
                return 'Messages sent in this conversation will be sent as a chat message because one or more clients in this conversation have installed the mobile app and opted in to app notifications.';
              }

              return 'Messages sent in this conversation will be sent as a chat message because this client has installed the mobile app and opted in to app notifications.';
            }

            return 'Messages sent in this conversation will be sent as a chat message because one or more clients in this conversation have a primary phone number that does not accept SMS text messages.';
          };

          const shouldDisable = isFeatureEnabledAndHasPushEnabled || isSmsEnabled;

          const tooltipContent = shouldDisable ? (
            <div>{disabledContent()}</div>
          ) : (
            <div>
              <div>
                With &quot;Send as chat message&quot;{' '}
                {sendAsChatMessage
                  ? 'enabled, clients will receive a link to view and reply to this message from the web link:'
                  : 'disabled, clients will receive this as a direct text message:'}
              </div>
              {sendAsChatMessage ? (
                <MessagePreview>{`Hi ${[channelPetParents[0]?.firstName, channelPetParents[0]?.lastName]
                  .join(' ')
                  .trim()}!

              You have a new message in your chat from ${currentUserDisplayName} with ${
                  channel?.clinic?.name || 'your clinic'
                }:

              "${draftMessageText || '(Your message here)'}"

              Please follow this link to view this message in your conversation: https://tele.vet/xxxxxxx
              `}</MessagePreview>
              ) : (
                <MessagePreview>{`${draftMessageText || '(Your message here)'}
                - ${currentUserDisplayName}`}</MessagePreview>
              )}
            </div>
          );

          return (
            showCheckbox && (
              <Tooltip content={tooltipContent} delay={[500, 0]} hideOnClick={false}>
                <Checkbox
                  name="sendAsChatMessage"
                  value="sendAsChatMessage"
                  disabled={shouldDisable}
                  checked={shouldDisable || sendAsChatMessage}
                  label="Send as chat message"
                  data-mixpanel-name="Send as chat message button"
                  onChange={(sendAsChatMessage: boolean): void => {
                    onSendAsChatMessageChange(sendAsChatMessage);
                    inputTextareaRef?.current?.focus();
                  }}
                />
              </Tooltip>
            )
          );
        })()}
        {!isMobile && (
          <CornerOptions>
            <Button
              size="xs"
              variant="ghostNeutral"
              iconName={isMessageComposerExpanded ? 'arrowsIn' : 'arrowsOut'}
              onClick={handleComposerToggle}
              aria-label="Expand Message Composer"
            />
          </CornerOptions>
        )}
      </MessageComposerHeader>

      <InputArea className={isInputFocused ? 'active' : ''} messageType={messageType} isMobile={isMobile}>
        <MessageContainer hasAttachments={attachments.length > 0}>
          <Textarea
            spellCheck={true}
            id={ElementIds.MessageComposerTextArea}
            placeholder={messageInputPlaceholder}
            value={draftMessageText}
            ref={inputTextareaRef}
            data-testid="message-composer-textarea"
            onChange={handleDraftMessageTextChange}
            onKeyDown={(e): void => {
              const message = e.currentTarget.value;

              if (e.key === '@' && shouldShowMentionsList) {
                setMentionsListIsVisible(true);
              }
              if (
                // Send message on enter
                (e.key === 'Enter' &&
                  !e.shiftKey &&
                  !mentionsListIsVisible &&
                  !clinicUser?.userSetting?.enterAddsNewLine) ||
                // Enter adds new line and send message with shift + enter
                (e.key === 'Enter' && e.shiftKey && !mentionsListIsVisible && clinicUser?.userSetting?.enterAddsNewLine)
              ) {
                e.preventDefault();
                const shortcutRegex = /(^\/\/[a-z]{1})|( \/\/[a-z]{1} )|( \/\/[a-z]{1})$/g;

                // Support keyboard shortcuts
                if (message.match(shortcutRegex)) {
                  handleKeyboardShortcuts(message);
                  return;
                }

                handleSendMessage();
              }
            }}
            onBlur={(): void => setIsInputFocused(false)}
            onFocus={(): void => {
              setIsInputFocused(true);
            }}
          />
          {isQuickReplyEnabled && !isTeamChannel && (
            <QuickReplyPopover
              channelId={channelId}
              setDraftMessageText={setDraftMessageText}
              addSuggestion={addSuggestion}
              discardSuggestion={discardSuggestion}
              setSelectedSuggestion={setSelectedSuggestion}
            />
          )}
          {isDictationEnabled && (
            <DictationButton
              isSendingMessage={isSendingMessage}
              setMessage={setDraftMessageText}
              currentMessageText={draftMessageText}
            />
          )}
        </MessageContainer>
        {!!attachments.length && (
          <AttachmentsContainer
            onClick={(e: React.MouseEvent<Element, globalThis.MouseEvent>): void => {
              if (inputTextareaRef?.current && e.target === e.currentTarget) {
                inputTextareaRef.current.focus();
              }
            }}
          >
            <HStack>
              <Stack>
                {isOpenFormFieldModalOpen && (
                  <OpenFormFieldModal
                    isOpen={isOpenFormFieldModalOpen}
                    onClose={onOpenFormFieldModalClose}
                    attachment={selectedFormAttachment}
                    channelDraft={channelDraft}
                    setChannelDraft={setChannelDraft}
                  />
                )}
                {attachments.map((attachment: IAttachmentOption, index: number) => (
                  <MessageComposerAttachment
                    key={index}
                    attachment={attachment}
                    linkedPets={linkedPets}
                    clinic={currentClinic}
                    channelDraft={channelDraft}
                    setChannelDraft={setChannelDraft}
                    onAttachmentChange={onAttachmentChange}
                    onAttachmentRemove={onAttachmentRemove}
                    setPreviewData={setPreviewData}
                    inputTextareaRef={inputTextareaRef}
                    invoiceAmountInputRef={invoiceAmountInputRef}
                    setCurrentInvoiceId={setCurrentInvoiceId}
                    setAttachmentFeePercent={setAttachmentFeePercent}
                    setHasPotentialBenefits={setHasPotentialBenefits}
                    setIsLinkPetToFormModalOpen={setIsLinkPetToFormModalOpen}
                    setSelectedFormAttachment={setSelectedFormAttachment}
                    onOpenFormFieldModalOpen={onOpenFormFieldModalOpen}
                  />
                ))}
              </Stack>
            </HStack>
          </AttachmentsContainer>
        )}
        <MessageComposerToolbar
          keyboardShortcutElementRefs={keyboardShortcutElementRefs}
          channel={channel}
          clinicPetParents={channelPetParents}
          clinic={currentClinic}
          messageType={messageType}
          attachments={attachments}
          stagedFiles={stagedFiles}
          inputTextareaRef={inputTextareaRef}
          isChannelLoading={isChannelLoading}
          isSendingMessage={isSendingMessage}
          isTeamChannel={isTeamChannel}
          isInvoicingDrawerOpen={isInvoicingDrawerOpen}
          onInvoicingDrawerClose={onInvoicingDrawerClose}
          onInvoicingDrawerToggle={onInvoicingDrawerToggle}
          setAttachments={setAttachments}
          setStagedFiles={setStagedFiles}
          setDraftMessageText={setDraftMessageText}
          onEmojiSelect={handleEmojiSelect}
          onSendMessage={handleSendMessage}
          isEmojiPickerOpen={isEmojiPickerOpen}
          setIsEmojiPickerOpen={setIsEmojiPickerOpen}
          setHasPotentialBenefits={setHasPotentialBenefits}
          hasPotentialBenefits={hasPotentialBenefits}
          setInvoiceForSidePanelId={setInvoiceForSidePanelId}
          setAttachInvoiceClicked={setAttachInvoiceClicked}
          setIsLinkPetToFormModalOpen={setIsLinkPetToFormModalOpen}
          setSelectedFormAttachment={setSelectedFormAttachment}
          setShouldOpenOpenFormModal={setShouldOpenOpenFormModal}
        />
        {mentionsListIsVisible && (
          <MentionsList
            isListVisibile={mentionsListIsVisible}
            setIsListVisible={setMentionsListIsVisible}
            inputTextareaRef={inputTextareaRef}
            setDraftMessageText={setDraftMessageText}
            setMentionedUsers={setMentionedUsersDraft}
          />
        )}
      </InputArea>
      <MessageComposerKeyboardShortcutHelpModal isOpen={isShortcutHelpModalOpen} onClose={closeShortcutHelpModal} />
      {attachments.length > 0 && linkedPets.length ? (
        <LinkPetToFormModal
          isOpen={isLinkPetToFormModalOpen}
          onClose={(): void => {
            setIsLinkPetToFormModalOpen(false);
          }}
          attachments={attachments}
          linkedPets={linkedPets}
          onAttachmentChange={onAttachmentChange}
        />
      ) : null}
    </MessageComposerContainer>
  );
};

const MessageComposerContainer = styled.div<{ isMessageComposerExpanded: boolean }>`
  flex: 0 0 auto;
  padding: 0 12px 12px;
  border-top: 1px solid #ddd;
  position: relative;
  width: 100%;
  height: ${({ isMessageComposerExpanded }): string => (isMessageComposerExpanded ? '50%' : 'unset')};
`;

interface IInputAreaProps {
  messageType: MessageType;
  isMobile: boolean;
}
const InputArea = styled.div<IInputAreaProps>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  border: 1px solid var(--chakra-colors-border-default);
  border-radius: 8px;
  transition: border-color 0.1s ease-in-out;
  height: calc(100% - 45px);
  height: ${({ isMobile }: IInputAreaProps): string => (isMobile ? 'auto' : 'calc(100% - 45px)')};
  background-color: ${({ messageType }: IInputAreaProps): string => {
    return messageType === MessageType.Note
      ? 'var(--chakra-colors-background-note)'
      : 'var(--chakra-colors-background-default)';
  }};

  &:hover {
    border-color: var(--chakra-colors-border-default);
  }

  &.active {
    border-color: ${({ messageType }: IInputAreaProps): string => {
      return messageType === MessageType.Note
        ? 'var(--chakra-colors-border-warning)'
        : 'var(--chakra-colors-border-focus)';
    }};
  }
`;

const MessageContainer = styled.div<{ hasAttachments: boolean }>`
  border-bottom: ${({ hasAttachments }): string => {
    return hasAttachments ? '1px solid var(--chakra-colors-border-default)' : 'none';
  }};
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;
`;

const Textarea = styled.textarea`
  background-color: transparent;
  border: none;
  font-family: inherit;
  height: 5em;
  padding: 12px;
  resize: none;
  width: 100%;
  height: 100%;
`;

const AttachmentsContainer = styled.div`
  background-color: transparent;
  display: flex;
  flex-flow: row wrap;
  padding: 12px 4px 8px;
  max-height: 170px;
  overflow-y: scroll;
`;

const MessagePreview = styled.div`
  white-space: pre-line;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  margin: 10px 5px 5px;
`;

const MessageComposerHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 12px;
`;

const MessageTabs = styled.div`
  flex: 1 1 auto;
  display: flex;
  border: none;
`;

interface IMessageTab {
  messageType?: string;
}

const MessageTab = styled.div<IMessageTab>`
  border: 0;
  height: 32px;
  margin: 0 18px;
  display: flex;
  width: auto;

  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  cursor: pointer;
  align-items: center;
  border-bottom: 2px solid transparent;

  &.active {
    border-bottom-color: ${({ messageType }): string =>
      messageType === MessageType.Message ? 'var(--chakra-colors-border-info)' : 'var(--chakra-colors-border-warning)'};
  }

  svg {
    margin-right: 8px;
  }
`;

const LeftSide = styled.div`
  flex: 1 1 auto;
  display: flex;
`;

const RightSide = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Stack = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  align-items: flex-end;
`;

const CornerOptions = styled.div`
  margin-left: 8px;
`;

export default React.memo(MessageComposer);
