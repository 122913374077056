import { VStack, Text, HStack, Flex, Switch, Spacer, TimePicker } from '@televet/kibble-ui';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import React, { useCallback, useMemo, useState } from 'react';

interface PayoutSwitchProps {
  index: number;
  endsAt: string;
  updateBatchingPeriod: (endsAt: string, time: Date, isChecked: boolean) => void;
}

const PayoutSwitch: React.FC<PayoutSwitchProps> = ({ index, endsAt, updateBatchingPeriod }) => {
  const [isChecked, setIsChecked] = useState(true);

  const initialTime = useMemo(() => {
    const currentDate = new Date();
    const zonedDate = utcToZonedTime(endsAt, 'UTC');
    const zonedDateWithTime = zonedDate.setHours(currentDate.getHours(), currentDate.getMinutes());
    return new Date(zonedDateWithTime);
  }, [endsAt]);

  const [time, setTime] = useState(initialTime);

  const formattedDate = useMemo(() => {
    // date strings from backend are in UTC, to avoid mismatching dates, convert to UTC before formatting
    const zonedDate = utcToZonedTime(endsAt, 'UTC');
    return format(zonedDate, 'EEEE, MMMM do');
  }, [endsAt]);

  const handleTimeChange = useCallback(
    (newTime: Date) => {
      const adjustedNewTime = initialTime.setHours(newTime.getHours(), newTime.getMinutes(), 0, 0);
      const adjustedNewDate = new Date(adjustedNewTime);
      setTime(adjustedNewDate);
      updateBatchingPeriod(endsAt, adjustedNewDate, isChecked);
    },
    [endsAt, initialTime, isChecked, updateBatchingPeriod],
  );

  const handleSwitchChange = useCallback(() => {
    const newCheckedState = !isChecked;
    setIsChecked(newCheckedState);
    updateBatchingPeriod(endsAt, time, newCheckedState);
  }, [isChecked, endsAt, time, updateBatchingPeriod]);

  return (
    <VStack key={index} alignContent="baseline">
      <Text as="p" mt={4} alignSelf="baseline" fontWeight="bold">
        {formattedDate}
      </Text>
      <HStack>
        <Flex width="50%">
          <TimePicker
            initialTime={time}
            inputProps={{
              isDisabled: !isChecked,
            }}
            minuteInterval={1}
            onTimeChange={handleTimeChange}
          />
        </Flex>
        <HStack>
          <Text minWidth="fit-content">Pay Out Day</Text>
          <Switch isChecked={isChecked} onChange={handleSwitchChange} />
          <Text>{isChecked ? 'ON' : 'OFF'}</Text>
          <Spacer />
        </HStack>
      </HStack>
    </VStack>
  );
};

export default PayoutSwitch;
