import { CarePlanCycleType } from 'shared/types/graphql';

export const getRenewalCycleInMonths = (renewalCycle?: CarePlanCycleType): number => {
  switch (renewalCycle) {
    case CarePlanCycleType.Monthly:
      return 1;
    case CarePlanCycleType.Semianually:
      return 6;
    default:
      return 12;
  }
};
