import { TimeFormatValue } from 'shared/components/WildCardInputGroup/WildcardTimeFormatSelect';
import { AutomationRunActionPromptType, SpeciesType } from 'shared/types/graphql';

export enum ActionEffectType {
  None = 'None',
  ConfirmAppointment = 'Confirm Appointment',
  AddTagsToChannel = 'Tag Conversation',
}

export interface ConditionalNextStepConfig {
  fallbackNextActionNumber?: number | null;
  fallbackNextWorkflowEventSettingId?: string | null;
}

interface ActionEffect {
  actionEffectType: ActionEffectType;
  tagIds?: string[];
}

export interface AutomationButtonConfig {
  body: string;
  channelStatusChangeId?: string | null;
  workflowEventTriggerDelayInMilliseconds?: number | null;
  workflowEventTriggerActionNumber?: number | null;
  nextWorkflowEventTriggerSettingId?: string | null;
  nextWorkflowEventSettingBySpecies?: Record<SpeciesType, string>;
  conditionalNextStepConfig?: ConditionalNextStepConfig;
  entityAction: ActionEffectType;
  actionEffects?: ActionEffect[];

  // TODO: These should all be deprecated
  doNothing?: boolean;
  // channelStatusChange?: string;
  // workflowActionEventTrigger?: string;
  // workflowEventSettingId?: string | null;
  // nextWorkflowEventTriggerEvent?: string;
  // clicked: { clinicUserId?: string; petParentId?: string; clickedAt: string } | null;
}

export interface AutomationPromptConfig {
  messageBodyTemplate?: string;
  callToActionBody?: string;
  buttons?: AutomationButtonConfig[];
  formTemplateId?: string | null;
  invoiceAmount?: string;
  paymentChannelStatusChange?: string;
  nextWorkflowEventTriggerSettingId?: string | null;
  timeFormat?: TimeFormatValue;
  conditionalNextStepConfig?: ConditionalNextStepConfig;
  actionEffects?: ActionEffect[];

  // TODO: These should all be deprecated
  // nextWorkflowEventTriggerEvent
  // options
  // workflowEventTriggerSettingId
  // isStatement
  // attachmentType
  // callToActionTimeFormat
  // channelStatusChange
}

export interface AutomationPrompt {
  id: string;
  channelStatusChangeId: string | null;
  order: number;
  promptType: AutomationRunActionPromptType;
  config: AutomationPromptConfig;
  triggerDelayInMilliseconds: number | null; // workflowEventTriggerDelayInMilliseconds
  triggerActionNumber: number | null; // workflowEventTriggerActionNumber

  // TODO: These should all be deprecated
  // triggerWorkflowId
  // isRemoved?
  // actionType
  // channelStatusChange
  // workflowEventTrigger
}
