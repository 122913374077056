import { TypePolicy } from '@apollo/client';

export const ClinicPet: TypePolicy = {
  fields: {
    computedIsDeceased: {
      read(_, { readField }) {
        const flowIsDeceased = readField('isDeceased');
        const pimsIsDeceased = readField('computed_rawPimsIsDeceased');

        return !!(flowIsDeceased || pimsIsDeceased);
      },
    },
    computedIsActive: {
      read(_, { readField }) {
        const flowIsActive = readField('isActive');
        const pimsIsActive = readField('computed_rawPimsIsActive');
        const isDeceased = readField('computedIsDeceased');

        if (isDeceased || !pimsIsActive) {
          return false;
        }

        return flowIsActive;
      },
    },
  },
};
