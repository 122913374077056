import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { VStack, Text, Flex, useDisclosure, Box } from '@televet/kibble-ui';
import xor from 'lodash-es/xor';
import partial from 'lodash-es/partial';
import { RouteBasePaths } from 'routes';
import { AutomationsListItem, AutomationsListHeader } from 'pages/Automations/components';
import {
  useGetAutomationsListQuery,
  useGetGlobalAutomationsListQuery,
  WorkflowEventTriggerType,
} from 'shared/types/graphql';
import useClinicUser from 'shared/hooks/useClinicUser';
import { AutomationsLoadingState } from '../Layout/AutomationsLoadingState';
import { AutomationToDelete, DeleteAutomationModal } from '../Modals/DeleteAutomationModal';
import { useAuth } from 'shared/providers/AuthProvider';
import { useAppSelector } from 'state/hooks';

export const AutomationsList = (): JSX.Element => {
  const { currentClinicId } = useClinicUser();
  const { isImpersonating } = useAuth();
  const [searchInputValue, setSearchInputValue] = useState<string>('');
  const [isSelectingAutomations, setIsSelectingAutomations] = useState(false);
  const [selectedAutomationIds, setSelectedAutomationIds] = useState<Set<string>>(new Set());
  const [automationListErrors, setAutomationListErrors] = useState<Set<string>>(new Set());
  const deleteAutomationModalState = useDisclosure();
  const isGlobalEditor = useAppSelector((state) => state.automations.isGlobalEditor);

  const { data: automationsListData, loading: isLoadingAutomationsList } = useGetAutomationsListQuery({
    variables: {
      clinicId: currentClinicId || '',
    },
    skip: !currentClinicId || isGlobalEditor,
  });

  const { data: globalAutomationsListData, loading: isLoadingGlobalAutomationsList } = useGetGlobalAutomationsListQuery(
    {
      skip: !isGlobalEditor,
    },
  );

  const allAutomationsList = useMemo(
    () =>
      isGlobalEditor
        ? globalAutomationsListData?.findManyWorkflowEventSetting || []
        : automationsListData?.findManyWorkflowEventSetting || [],
    [automationsListData, globalAutomationsListData, isGlobalEditor],
  );

  const isLoadingList = useMemo(
    () => isLoadingAutomationsList || isLoadingGlobalAutomationsList,
    [isLoadingAutomationsList, isLoadingGlobalAutomationsList],
  );

  const filteredAutomationsList = useMemo(() => {
    const visibleAutomations = allAutomationsList.filter(({ conditionSets, messagingCampaignId, triggerType }) => {
      const isCampaignAutomation =
        conditionSets.some((conditionSet) => conditionSet.conditions.length) && !!messagingCampaignId;
      return (
        !isCampaignAutomation ||
        (isCampaignAutomation && triggerType !== WorkflowEventTriggerType.Manual && isImpersonating)
      );
    });

    if (searchInputValue === '') {
      return visibleAutomations;
    }

    return visibleAutomations.filter((item) =>
      item.name.toLocaleLowerCase().includes(searchInputValue.toLocaleLowerCase()),
    );
  }, [searchInputValue, allAutomationsList, isImpersonating]);

  const [automationToDelete, setAutomationToDelete] = useState<AutomationToDelete | null>(null);
  const handleDeleteAutomation = useCallback(
    ({ id, name }: AutomationToDelete): void => {
      setAutomationToDelete({ id, name });
      deleteAutomationModalState.onOpen();
    },
    [deleteAutomationModalState],
  );

  const history = useHistory();

  const onToggleSelectionMode = useCallback(() => {
    history.push(RouteBasePaths.Automations);
    setIsSelectingAutomations((prev) => !prev);
    setSelectedAutomationIds(new Set());
    setAutomationListErrors(new Set());
  }, [history]);

  const onToggleAutomationSelection = useCallback(
    (id: string): void => {
      if (!isSelectingAutomations) {
        onToggleSelectionMode();
      }
      setSelectedAutomationIds((prev) => new Set(xor(Array.from(prev), [id])));
    },
    [isSelectingAutomations, onToggleSelectionMode],
  );

  const onSelectAutomations = useCallback((ids: string[]): void => {
    setSelectedAutomationIds(new Set(ids));
  }, []);

  return (
    <>
      <Box
        className="Automations__List"
        h="100%"
        minW="364px"
        flexBasis="364px"
        bg="background.subtle"
        display="flex"
        flexDirection="column"
        alignItems="stretch"
        height="100%"
        zIndex="banner"
        borderRight="1px"
        borderColor="border.default"
        pos="relative"
      >
        <AutomationsListHeader
          filteredAutomationsList={filteredAutomationsList}
          searchInputValue={searchInputValue}
          isSelectingAutomations={isSelectingAutomations}
          selectedAutomationIds={selectedAutomationIds}
          setSearchInputValue={setSearchInputValue}
          onSelectAutomations={onSelectAutomations}
          onToggleSelectionMode={onToggleSelectionMode}
          onSetAutomationListErrors={setAutomationListErrors}
        />
        <VStack flex="1 1 auto" p={3} spacing={4} overflow="auto">
          {isLoadingList && <AutomationsLoadingState />}
          {filteredAutomationsList.length ? (
            filteredAutomationsList?.map((item) => (
              <AutomationsListItem
                key={item.id}
                id={item.id}
                name={item.name}
                event={item.event}
                triggerType={item.triggerType}
                isPublished={item.isPublished}
                hasError={automationListErrors.has(item.id)}
                sendingHours={item.sendingHours}
                isSelected={selectedAutomationIds.has(item.id)}
                isSelectingAutomations={isSelectingAutomations}
                onDeleteAutomation={handleDeleteAutomation}
                onToggleSelectionMode={onToggleSelectionMode}
                onToggleSelection={partial(onToggleAutomationSelection, item.id)}
              />
            ))
          ) : (
            <Flex h="100%" alignItems="center" py={2} px={3}>
              <Text>No automations found</Text>
            </Flex>
          )}
        </VStack>
      </Box>
      <DeleteAutomationModal
        automation={automationToDelete}
        isOpen={deleteAutomationModalState.isOpen}
        onClose={deleteAutomationModalState.onClose}
      />
    </>
  );
};
