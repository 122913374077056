import { Box, Menu, Tooltip, Text, Icon, HStack, Button, useToast } from '@televet/kibble-ui';
import { ExportType, updateExportData } from 'pages/Conversations/state/conversationsSlice';
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useIntegrationsProvider } from 'shared/providers/IntegrationsProvider';
import { useAppDispatch } from 'state/hooks';
import useCheckChannelExportability, {
  CheckChannelExportabilityResponse,
} from 'pages/Conversations/hooks/useCheckChannelExportability';
import {
  ChannelViewClinicPetParentFragment,
  ChannelPinFragmentDoc,
  useCreateChannelPinMutation,
  useDeleteChannelPinByUserAndChannelMutation,
} from 'shared/types/graphql';
import { Reference } from '@apollo/client';
import * as Sentry from '@sentry/react';
import useGA from 'shared/hooks/useGA';
import { GA4Events } from 'shared/enums/GA4Events';

interface CardButtonsProps {
  channelId: string;
  channelPetParents: ChannelViewClinicPetParentFragment[];
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>;
  setIsHovering: Dispatch<SetStateAction<boolean>>;
  isChannelPinned: boolean;
  userId: string;
}

const CardButtons = ({
  channelId,
  channelPetParents,
  setIsMenuOpen,
  setIsHovering,
  isChannelPinned,
  userId,
}: CardButtonsProps): JSX.Element => {
  const { checkChannelExportability } = useCheckChannelExportability();
  const dispatch = useAppDispatch();
  const { primaryIntegrationName } = useIntegrationsProvider();
  const [channelExportability, setChannelExportability] = useState<CheckChannelExportabilityResponse>();
  const toast = useToast();
  const { gaTrack } = useGA();

  const fetchMenuData = useCallback(async () => {
    const exportability = await checkChannelExportability(channelId);
    setChannelExportability(exportability);
  }, [checkChannelExportability, channelId]);

  const openExportModal = useCallback(
    (exportType: ExportType) => {
      dispatch(
        updateExportData({
          isExportModalOpen: true,
          selectedExportType: exportType,
          channelPetParents,
          channelId,
        }),
      );
    },
    [dispatch, channelPetParents, channelId],
  );

  const [createChannelPin] = useCreateChannelPinMutation({
    refetchQueries: ['getChannelListChannels'],
    update: (cache, result) => {
      const newPin = result.data?.createOneChannelPin;
      if (newPin?.id) {
        const newPinRef = cache.writeFragment({
          data: newPin,
          fragment: ChannelPinFragmentDoc,
        });

        cache.modify({
          id: cache.identify({ __typename: 'Channel', id: channelId }),
          fields: {
            channelPins(existingChannelPinsRefs: ReadonlyArray<Reference> = [], { readField }) {
              const pinExists = existingChannelPinsRefs.some((ref) => readField('id', ref) === newPin.id);

              if (pinExists) {
                return existingChannelPinsRefs; // Return the existing refs if the pin is already there to avoid duplicates
              }

              // Return the updated array of refs with the new pin ref added
              return [...existingChannelPinsRefs, newPinRef];
            },
          },
        });
      }
    },
  });

  const [deleteChannelPin] = useDeleteChannelPinByUserAndChannelMutation({
    refetchQueries: ['getChannelListChannels'],
    update: (cache, result): void => {
      if (result.data?.deleteManyChannelPin?.count) {
        cache.modify({
          id: cache.identify({ __typename: 'Channel', id: channelId }),
          fields: {
            channelPins(existingChannelPinsRefs: ReadonlyArray<Reference> = [], { readField }) {
              return existingChannelPinsRefs.filter((channelPinRef) => readField('userId', channelPinRef) !== userId);
            },
          },
        });
      }
    },
  });

  const togglePinnedConversation = useCallback(async () => {
    try {
      if (isChannelPinned) {
        await deleteChannelPin({
          variables: { userId, channelId },
        });
        toast({ status: 'info', title: 'Conversation unpinned', hideIcon: true });
      } else {
        await createChannelPin({
          variables: { userId: userId, channelId },
        });
        toast({ status: 'info', title: 'Conversation pinned', hideIcon: true });
      }
      setIsHovering(false);
      gaTrack(GA4Events.CONVERSATION_PIN_TOGGLE, { pinned: !isChannelPinned });
    } catch (e) {
      Sentry.captureException(e);
    }
  }, [isChannelPinned, setIsHovering, gaTrack, deleteChannelPin, userId, channelId, toast, createChannelPin]);

  return (
    <HStack className="ChannelListItem__Menu" spacing={1}>
      <Button
        data-testid="conversation-pin-toggle-icon-button"
        variant={isChannelPinned ? 'secondarySubtleDestructive' : 'secondarySubtle'}
        onClick={togglePinnedConversation}
        iconName={isChannelPinned ? 'pinCancel' : 'pin'}
        size="sm"
      />
      <Menu
        buttonProps={{
          variant: 'secondarySubtle',
          size: 'sm',
          leftIconName: 'dotsVertical',
          showRightIcon: false,
        }}
        isLazy
        onOpen={fetchMenuData}
        closeOnSelect={false}
        containerProps={{
          onClose: (): void => {
            setIsMenuOpen(false);
          },
        }}
        listProps={{
          shouldUsePortal: true,
          menuItems: [
            {
              'data-testid': 'conversation-pin-toggle-dropdown-button',
              label: `${isChannelPinned ? 'Unpin' : 'Pin'} Conversation`,
              value: 'PinConversation',
              variant: isChannelPinned ? 'danger' : 'subtle',
              leftElement: (
                <Icon
                  size="md"
                  variant={isChannelPinned ? 'danger' : 'subtle'}
                  name={isChannelPinned ? 'pinCancel' : 'pin'}
                />
              ),
              onSelect: togglePinnedConversation,
            },
            {
              label: (
                <Tooltip
                  isDisabled={!channelExportability}
                  label={channelExportability?.message || `Client and/or patient are not in ${primaryIntegrationName}`}
                >
                  <Box position="relative">
                    <Text size="sm">Export to {primaryIntegrationName}</Text>
                  </Box>
                </Tooltip>
              ),
              isDisabled: !!channelExportability,
              value: 'ExportToPims',
              leftElement: <Icon size="md" variant="subtle" name="upload" />,
              onSelect: (): void => {
                openExportModal('ExportToPims');
              },
            },
            {
              label: 'Download PDF',
              value: 'DownloadPdf',
              leftElement: <Icon size="md" variant="subtle" name="form" />,
              onSelect: (): void => {
                openExportModal('DownloadPdf');
              },
            },
          ],
        }}
      />
    </HStack>
  );
};

export default React.memo(CardButtons);
