import React, { useEffect, useMemo, useState } from 'react';
import { Flex, VStack } from '@televet/kibble-ui/build/chakra';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { PaymentRelationship, SidePanelParentPetDataFragment } from 'shared/types/graphql';
import FollowupStepsForm from '../../FollowupStepsForm';
import { useAppSelector } from 'state/hooks';
import { StripeTerminalClinicPetParent } from 'shared/providers/StripeTerminalProvider/types/StripeTerminalClinicPetParent';
import useFeatureFlag from 'shared/hooks/useFeatureFlag';
import { FeatureFlagName } from 'shared/enums';
import { ModalHeader } from '@televet/kibble-ui/build/components/Modal';
import usePaymentWritebackConfig from 'shared/hooks/usePaymentWritebackConfig';
import { useStripeTerminalProvider } from 'shared/providers/StripeTerminalProvider';

interface IFinalStepsScreenProps {
  clinicPetParent?: StripeTerminalClinicPetParent;
  emptyInvoiceMessage?: string;
  pet: SidePanelParentPetDataFragment | undefined;
  onClose: () => void;
  refreshInvoice: () => void;
  refreshBalance: () => void;
}

const FinalStepsScreen = ({
  clinicPetParent,
  pet,
  onClose,
  refreshInvoice,
  refreshBalance,
}: IFinalStepsScreenProps): JSX.Element => {
  const { isFeatureEnabled } = useFeatureFlag();
  const isWizardPaymentsFFEnabled = isFeatureEnabled(FeatureFlagName.WizardPaymentIntegrations);
  const careWizardDraft = useAppSelector((state) => state.CareWizardSteps.careWizardDraft);
  const { paymentDue, selectedInvoiceId, isBalanceSelected } = careWizardDraft;
  const { isClinicWritebackEnabled } = usePaymentWritebackConfig();
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState<boolean>(false);

  const isPimsPetParent = useMemo(() => {
    return !!clinicPetParent?.pimsId;
  }, [clinicPetParent]);

  const showPaymentDisplay = useMemo(() => {
    return (
      !!isWizardPaymentsFFEnabled &&
      !!(selectedInvoiceId || isBalanceSelected) &&
      !!isClinicWritebackEnabled &&
      !!isPimsPetParent
    );
  }, [isWizardPaymentsFFEnabled, selectedInvoiceId, isBalanceSelected, isClinicWritebackEnabled, isPimsPetParent]);

  const { setPaymentRelationship, paymentRelationship } = useStripeTerminalProvider();

  useEffect(() => {
    if (!!isBalanceSelected) {
      setPaymentRelationship(PaymentRelationship.AccountBalance);
    } else if (selectedInvoiceId) {
      setPaymentRelationship(null);
    } else {
      setPaymentRelationship(PaymentRelationship.CustomAmount);
    }
  }, [isBalanceSelected, paymentRelationship, selectedInvoiceId, setPaymentRelationship]);

  return (
    <>
      <Flex direction="column" gap={1} mb={4}>
        {!showPaymentDisplay ? (
          <>
            <Text fontWeight="bold">Final Steps</Text>
            <Text fontWeight="bold">Collect Payment</Text>
            <Text size="xs">Follow the steps below to ensure all accounting is complete</Text>
          </>
        ) : (
          <>
            <ModalHeader>{paymentDue > 0 && !isPaymentSuccessful ? 'Collect Payment' : `You're all set!`}</ModalHeader>
            {paymentDue > 0 && !isPaymentSuccessful && (
              <Flex bg="background.primaryAlpha.hover" justifyContent="center">
                <VStack p={4}>
                  <Text size="xs">Balance Due</Text>
                  <Text fontWeight="bold" size="xl">{`$${paymentDue.toFixed(2)}`}</Text>
                </VStack>
              </Flex>
            )}
          </>
        )}
      </Flex>
      <FollowupStepsForm
        clinicPetParent={clinicPetParent}
        pet={pet}
        forceFilledForm={false}
        afterSubmit={onClose}
        showPaymentDisplay={showPaymentDisplay}
        setIsPaymentSuccessful={setIsPaymentSuccessful}
        isPaymentSuccessful={isPaymentSuccessful}
        refreshInvoice={refreshInvoice}
        refreshBalance={refreshBalance}
      />
    </>
  );
};

export default FinalStepsScreen;
