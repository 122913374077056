import { Box, Flex } from '@televet/kibble-ui/build/chakra';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { Icon } from '@televet/kibble-ui/build/components/Icon';
import { Spinner } from '@televet/kibble-ui/build/components/Spinner';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { Tooltip } from '@televet/kibble-ui/build/components/Tooltip';
import React from 'react';
import { EmailCampaign } from 'shared/types/graphql';

type CustomListSummaryProps = {
  campaign: EmailCampaign | undefined;
  onConfirm: () => void;
  isDownloading: boolean;
  onDownload: () => Promise<void>;
};

export const CustomListSummary = ({
  campaign,
  onDownload,
  onConfirm,
  isDownloading,
}: CustomListSummaryProps): JSX.Element => {
  return (
    <Box pl={4} pr={2} py={2} backgroundColor="background.subtle" borderWidth="1px" borderRadius="lg">
      <Flex justifyContent="space-between" mb={2}>
        <Text fontWeight="bold">{campaign?.customList?.fileName}</Text>
        <Button iconName="trashcan" variant="ghostNeutral" aria-label="Delete List" size="md" onClick={onConfirm} />
      </Flex>
      <Flex justifyContent="space-between" mb={2} alignItems="center">
        <Box>
          <Flex mb={2} alignItems="center" gap={1}>
            <Icon size="sm" name="checkmarkCircle" variant="success" />
            <Text fontSize="sm">{campaign?.customList?.validRecipients} Valid Recipients</Text>
          </Flex>
          <Flex alignItems="center" gap={1}>
            <Icon size="sm" name="warningSign" variant="warning" />
            <Text fontSize="sm">{campaign?.customList?.invalidRecipients} Recipients Rejected</Text>
            <Tooltip label="Recipients are rejected when the email address is invalid or it does not belong to an existing client.">
              <Flex>
                <Icon name="questionMarkCircleSolid" size="sm" />
              </Flex>
            </Tooltip>
          </Flex>
        </Box>
        {isDownloading ? (
          <Box p={3} alignSelf="flex-end">
            <Spinner size="sm" />
          </Box>
        ) : (
          <Button
            alignSelf="flex-end"
            iconName="download"
            variant="ghostNeutral"
            aria-label="Download List"
            onClick={onDownload}
          >
            Download List
          </Button>
        )}
      </Flex>
    </Box>
  );
};
