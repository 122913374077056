import {
  RatingDot,
  useDisclosure,
  ModalCloseButton,
  ModalOverlay,
  ModalContent,
  Button,
  CModal,
  CModalHeader,
  CModalFooter,
  CModalBody,
  Icon,
} from '@televet/kibble-ui';
import format from 'date-fns/format';
import cloneDeep from 'lodash-es/cloneDeep';
import React, { useMemo, useState } from 'react';
import Tooltip from 'shared/components/Tooltip';
import useClinicUser from 'shared/hooks/useClinicUser';
import {
  AutomationRunActionFragment,
  AutomationRunStatus,
  useUpdateAutomationRunActionMutation,
  WorkflowEvent,
} from 'shared/types/graphql';
import { Mixpanel } from 'shared/utils/mixpanel';
import styled from 'styled-components/macro';

interface IAutomationRunButtonsPromptProps {
  automationRunAction: AutomationRunActionFragment | undefined;
  actionIndex: number;
  lastAutomatedActionIndex: number;
  isSurveyAutomation?: boolean;
}

interface IButton {
  body: string;
  clicked?: {
    clinicUserId?: string;
    petParentId?: string;
    clickedAt: string;
  };
}

const AutomationRunButtonsPrompt = ({
  automationRunAction,
  actionIndex,
  lastAutomatedActionIndex,
  isSurveyAutomation,
}: IAutomationRunButtonsPromptProps): JSX.Element => {
  const { clinicUser } = useClinicUser();
  const userId = clinicUser?.id || '';

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [updateAutomationRunAction] = useUpdateAutomationRunActionMutation();

  const [buttonClicked, setButtonClicked] = useState<IButton | undefined>(undefined);

  const isHillsRegistrationWorkflow = useMemo(
    () => automationRunAction?.automationAction?.workflowEventSetting?.event === WorkflowEvent.InvoiceLineItemCreated,
    [automationRunAction?.automationAction?.workflowEventSetting?.event],
  );

  const areButtonsClickable = useMemo(() => {
    return (
      !isSurveyAutomation &&
      actionIndex === lastAutomatedActionIndex &&
      automationRunAction?.automationRun.status !== AutomationRunStatus.Completed &&
      !isHillsRegistrationWorkflow
    );
  }, [
    isSurveyAutomation,
    actionIndex,
    lastAutomatedActionIndex,
    automationRunAction?.automationRun.status,
    isHillsRegistrationWorkflow,
  ]);

  const handleButtonSubmit = (): void => {
    Mixpanel.track('Clinic submitted automation run button for pet parent');
    const newButtons = cloneDeep(automationRunAction?.buttons);
    for (const button of newButtons) {
      if (buttonClicked?.body === button.body) {
        button.clicked = {
          clickedAt: new Date().toISOString(),
          clinicUserId: userId,
        };
      }
    }
    updateAutomationRunAction({
      variables: {
        where: { id: automationRunAction?.id },
        data: {
          buttons: newButtons,
        },
      },
    });
    onClose();
  };

  const sentTime = format(new Date(automationRunAction?.createdAt), 'h:mmaaa');
  return (
    <PromptWrapper>
      <ChildAutomationStart>
        {actionIndex > 0 && (
          <AutomationSent>
            Automation Sent <SentTime>{sentTime}</SentTime>
          </AutomationSent>
        )}

        {automationRunAction?.order === 1 && actionIndex !== 0 && (
          <Tooltip content={automationRunAction?.automationAction?.workflowEventSetting?.name}>
            <Icon name="robot" size="sm" />
          </Tooltip>
        )}
      </ChildAutomationStart>

      <Body>{automationRunAction?.body}</Body>

      <Answer isSurveyAutomation={!!isSurveyAutomation}>
        <CallToAction>{automationRunAction?.automationAction?.config.callToActionBody}</CallToAction>
        <>
          {automationRunAction?.buttons.map((button: IButton, index: number) => {
            const isButtonClicked = !!button.clicked?.clickedAt;

            // don't allow clinic user to select rating value
            return isSurveyAutomation ? (
              <RatingDot
                isDisabled={true}
                key={index}
                number={parseInt(button.body)}
                selected={isButtonClicked}
                isInteractive={true}
              />
            ) : (
              <Button
                size="md"
                key={index}
                isDisabled={!areButtonsClickable}
                variant={isButtonClicked ? 'primary' : 'secondary'}
                mb={2}
                isFullWidth
                h="max-content"
                whiteSpace="break-spaces"
                onClick={(e): void => {
                  if (areButtonsClickable) {
                    if (!isButtonClicked) {
                      e.stopPropagation();
                      setButtonClicked(button);
                      onOpen();
                    }
                  }
                }}
              >
                {button.body}
              </Button>
            );
          })}
        </>
      </Answer>

      <CModal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <CModalHeader>Submit Prompt</CModalHeader>
          <ModalCloseButton _focus={{ border: 'none' }} />
          <CModalBody>
            Submitting the prompt will continue the automation without input from the client.
            <br />
            <br />
            <span style={{ fontWeight: 700 }}>Are you sure you want to submit this prompt?</span>
          </CModalBody>

          <CModalFooter>
            <ModalButtons>
              <Button onClick={onClose}>Cancel</Button>
              <Button onClick={handleButtonSubmit}>Submit Prompt</Button>
            </ModalButtons>
          </CModalFooter>
        </ModalContent>
      </CModal>
    </PromptWrapper>
  );
};

export default AutomationRunButtonsPrompt;

interface IPromptAnswerProps {
  isSurveyAutomation: boolean;
}
const Answer = styled.div<IPromptAnswerProps>`
  background-color: var(--chakra-colors-background-primaryAlpha-hover);
  border-left: 4px solid var(--chakra-colors-border-info);
  padding: 12px;
  margin-top: 12px;
  ${({ isSurveyAutomation }: IPromptAnswerProps): string => {
    return isSurveyAutomation
      ? `
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    `
      : '';
  }}
`;

const ModalButtons = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

const ChildAutomationStart = styled.div`
  display: flex;
  margin-top: 14px;
  align-items: center;
`;

const AutomationSent = styled.div`
  font-weight: 600;
  margin-right: 8px;
  font-size: 14px;
`;
const SentTime = styled.span`
  font-size: 12px;
  color: #1d70ed;
  font-weight: 400;
`;

const Body = styled.div`
  margin-top: 4px;
  white-space: pre-wrap;
`;

const CallToAction = styled.div`
  margin-bottom: 12px;
  font-weight: 600;
`;

const PromptWrapper = styled.div`
  padding: 20px 24px;
`;
