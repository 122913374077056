import * as Sentry from '@sentry/react';
import { Box, Divider, HStack } from '@televet/kibble-ui/build/chakra';
import { Alert } from '@televet/kibble-ui/build/components/Alert';
import { Text } from '@televet/kibble-ui/build/components/Text';
import {
  BalanceForClientFragment,
  InvoiceForClientFragment,
  StripePaymentIntentStatusFragment,
  useGetBalanceForClientLazyQuery,
  useGetInvoicesForClientLazyQuery,
} from 'shared/types/graphql';
import { defaultDiscoveredInvoiceDemoData } from 'pages/Settings/pages/DevToolsSettings';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import HasFeature from 'shared/components/HasFeature';
import { FeatureFlagName } from 'shared/enums/FeatureFlagName';
import { GraphQLErrorPolicies } from 'shared/enums/GraphQLErrorPolicies';
import { GraphQLFetchPolicies } from 'shared/enums/GraphQLFetchPolicies';
import usePersistedState from 'shared/hooks/usePersistedState';
import { StripeTerminalClinicPetParent } from 'shared/providers/StripeTerminalProvider/types/StripeTerminalClinicPetParent';
import { BitwerxClientBalance, Invoice, InvoiceStatus, StripePaymentIntentStatus } from 'shared/types/graphql';
import { getBalanceTerminology } from 'shared/utils';
import { integrationSystemDisplayName, useIntegrationsProvider } from 'shared/providers/IntegrationsProvider';
import { PersistedStateKeys } from 'shared/enums';
import InvoiceDisplay from './InvoiceDisplay';
import RefreshLink from './RefreshLink';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { updateIsBalanceSelected } from '../../state/careWizardStepsSlice';
import { MINIMUM_PAYMENT_AMOUNT } from './utils/paymentEnumsAndVariables';
import BalanceDisplay from './PaymentHelperComponents/BalanceDisplay';

type BalanceProps = {
  propBalanceData?: BalanceForClientFragment;
};

type InvoiceProps = {
  propInvoiceData?: InvoiceForClientFragment[];
  emptyInvoiceMessage?: string;
};

interface IInvoiceAndARDisplayProps extends BalanceProps, InvoiceProps {
  isPaymentPage?: boolean;
  clinicPetParent?: StripeTerminalClinicPetParent;
  updateInvoiceCost?: (invoiceTotal: number) => void;
  setSelectedInvoiceId: (invoiceId: string | null) => void;
  emptyInvoiceMessage?: string;
  paymentDue?: number;
}

const InvoiceAndARDisplay = ({
  isPaymentPage,
  clinicPetParent,
  updateInvoiceCost,
  setSelectedInvoiceId,
  propInvoiceData,
  propBalanceData,
  emptyInvoiceMessage,
}: IInvoiceAndARDisplayProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [isDiscoveredInvoiceDemoModeEnabled] = usePersistedState(
    PersistedStateKeys.IsDiscoveredInvoiceDemoModeEnabled,
    false,
  );
  const [discoveredInvoiceDemoData] = usePersistedState(
    PersistedStateKeys.DiscoveredInvoiceDemoData,
    defaultDiscoveredInvoiceDemoData,
  );
  const [emptyInvoiceStateMessage, setEmptyInvoiceStateMessage] = useState(
    emptyInvoiceMessage ? emptyInvoiceMessage : 'There are no pending invoices',
  );

  const [noEligibleBalanceMessage, setNoEligibleBalanceMessage] = useState(
    'There is no eligible balance for this client',
  );
  const [isSimulatedBalanceQueryLoading, setIsSimulatedBalanceQueryLoading] = useState(false);
  const [isSimulatedInvoiceQueryLoading, setIsSimulatedInvoiceQueryLoading] = useState(false);
  const { primaryIntegrationName, isInvoiceDiscoverySupported, isInvoicePreviewSupported } = useIntegrationsProvider();
  const [isInvoiceDataForceRefreshed, setIsInvoiceDataForceRefreshed] = useState(false);
  const [isBalanceDataForceRefreshed, setIsBalanceDataForceRefreshed] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');

  const careWizardDraft = useAppSelector((state) => state.CareWizardSteps.careWizardDraft);

  const { paymentDue, selectedInvoiceId, isBalanceSelected, totalInvoiceSavings } = careWizardDraft;
  const setIsBalanceSelected = useCallback(
    (isBalanceSelected: boolean) => {
      dispatch(updateIsBalanceSelected(isBalanceSelected));
    },
    [dispatch],
  );

  const isPimsClinicPetParent = useMemo(() => {
    return clinicPetParent?.pimsId !== null;
  }, [clinicPetParent?.pimsId]);

  const [getInvoices, { data, loading: isInvoiceQueryLoading }] = useGetInvoicesForClientLazyQuery({
    fetchPolicy: GraphQLFetchPolicies.NetworkOnly,
    errorPolicy: GraphQLErrorPolicies.All,
    onError: (error) => {
      console.error(error);
      Sentry.captureException(error);
      setEmptyInvoiceStateMessage('Error connecting to invoice data, please wait a moment and try again');
    },
    onCompleted: () => {
      setEmptyInvoiceStateMessage('There are no pending invoices');
    },
  });

  const [getBalance, { data: balanceData, loading: isBalanceQueryLoading }] = useGetBalanceForClientLazyQuery({
    fetchPolicy: GraphQLFetchPolicies.NetworkOnly,
    errorPolicy: GraphQLErrorPolicies.All,
    onError: (error) => {
      console.error(error);
      Sentry.captureException(error);
      setNoEligibleBalanceMessage('Error connecting to balance data, please wait a moment and try again');
    },
    onCompleted: () => {
      setNoEligibleBalanceMessage('There is no eligible balance for this client');
    },
  });

  const balanceTerminology = useMemo(() => {
    return getBalanceTerminology(primaryIntegrationName);
  }, [primaryIntegrationName]);

  const petParentId = clinicPetParent?.id;

  const isPendingPaymentIntents = (intents: StripePaymentIntentStatusFragment[]): boolean => {
    for (const intent of intents) {
      if (intent?.status === StripePaymentIntentStatus.RequiresPaymentMethod) {
        return true;
      }
    }
    return false;
  };

  const simulateInvoiceQueryLoading = (): void => {
    setIsSimulatedInvoiceQueryLoading(true);
    setTimeout(() => {
      setIsSimulatedInvoiceQueryLoading(false);
    }, 2500);
  };

  const fetchInvoices = useCallback(() => {
    const shouldFetchInvoices =
      !!petParentId && !!isPimsClinicPetParent && !!isInvoiceDiscoverySupported && !!isInvoicePreviewSupported;

    if (!shouldFetchInvoices) {
      return;
    }

    if (isDiscoveredInvoiceDemoModeEnabled) {
      simulateInvoiceQueryLoading();
      return;
    }

    if (propInvoiceData && !isInvoiceDataForceRefreshed) {
      // if data is preloaded and passed as a prop (and user has not pressed refresh), use that data
      setEmptyInvoiceStateMessage(emptyInvoiceMessage || 'There are no pending invoices');
      return;
    }

    getInvoices({
      variables: {
        where: {
          clinicPetParentId: { equals: petParentId },
          status: { equals: InvoiceStatus.Open },
          total: { gt: 0 },
          stripePaymentIntents: { none: { status: { equals: StripePaymentIntentStatus.Succeeded } } },
        },
      },
    });
    setIsInvoiceDataForceRefreshed(false);
  }, [
    emptyInvoiceMessage,
    getInvoices,
    isDiscoveredInvoiceDemoModeEnabled,
    isInvoiceDataForceRefreshed,
    isInvoiceDiscoverySupported,
    isInvoicePreviewSupported,
    isPimsClinicPetParent,
    petParentId,
    propInvoiceData,
  ]);

  const fetchBalance = useCallback(() => {
    if (isDiscoveredInvoiceDemoModeEnabled) {
      setIsSimulatedBalanceQueryLoading(true);
      setTimeout(() => {
        setIsSimulatedBalanceQueryLoading(false);
      }, 1500);
    }
    if (!!propBalanceData && !isBalanceDataForceRefreshed) {
      // if data is preloaded and passed as a prop (and user has not pressed refresh), use that data
      return;
    }
    if (petParentId && isPimsClinicPetParent) {
      getBalance({
        variables: {
          where: {
            clinicPetParentId: petParentId,
          },
        },
      });
    }
    setIsBalanceDataForceRefreshed(false);
  }, [
    getBalance,
    isBalanceDataForceRefreshed,
    isDiscoveredInvoiceDemoModeEnabled,
    isPimsClinicPetParent,
    petParentId,
    propBalanceData,
  ]);

  useEffect(() => {
    if (petParentId && isPimsClinicPetParent) {
      fetchInvoices();
    }
  }, [fetchInvoices, isPimsClinicPetParent, petParentId]);

  useEffect(() => {
    if (petParentId && isPimsClinicPetParent) {
      fetchBalance();
    }
  }, [fetchBalance, isPimsClinicPetParent, petParentId]);

  const invoices: InvoiceForClientFragment[] = useMemo(() => {
    if (isDiscoveredInvoiceDemoModeEnabled) {
      return discoveredInvoiceDemoData.invoices as unknown as Invoice[];
    }
    if (!!propInvoiceData) {
      return propInvoiceData;
    }

    return data?.getInvoicesForClient || [];
  }, [
    data?.getInvoicesForClient,
    discoveredInvoiceDemoData.invoices,
    isDiscoveredInvoiceDemoModeEnabled,
    propInvoiceData,
  ]);

  const balance: BitwerxClientBalance = useMemo(() => {
    if (isDiscoveredInvoiceDemoModeEnabled) {
      return discoveredInvoiceDemoData.balance;
    }
    if (!!propBalanceData) {
      return propBalanceData;
    }
    return balanceData?.getBalanceForClient || {};
  }, [
    balanceData?.getBalanceForClient,
    discoveredInvoiceDemoData.balance,
    isDiscoveredInvoiceDemoModeEnabled,
    propBalanceData,
  ]);

  const isBalanceLoading = useMemo(() => {
    if (isDiscoveredInvoiceDemoModeEnabled) {
      return isSimulatedBalanceQueryLoading;
    }
    return isBalanceQueryLoading;
  }, [isBalanceQueryLoading, isDiscoveredInvoiceDemoModeEnabled, isSimulatedBalanceQueryLoading]);

  const isInvoiceLoading = useMemo(() => {
    if (isDiscoveredInvoiceDemoModeEnabled) {
      return isSimulatedInvoiceQueryLoading;
    }
    return isInvoiceQueryLoading;
  }, [isDiscoveredInvoiceDemoModeEnabled, isInvoiceQueryLoading, isSimulatedInvoiceQueryLoading]);

  const errorMessage = useMemo(() => {
    const invoicesText = primaryIntegrationName === integrationSystemDisplayName.AVIMARK ? '' : 'invoices or';
    const balanceText = balanceTerminology.toLocaleLowerCase();
    const systemName = primaryIntegrationName ?? 'your PIMS';

    return `Unable to pull ${invoicesText} ${balanceText} for clients not in ${systemName}`;
  }, [primaryIntegrationName, balanceTerminology]);

  const refreshInvoiceData = useCallback(() => {
    setSelectedInvoiceId(null);
    setIsInvoiceDataForceRefreshed(true);
    fetchInvoices();
  }, [fetchInvoices, setSelectedInvoiceId]);

  const refreshBalanceData = useCallback(() => {
    setIsBalanceDataForceRefreshed(true);
    fetchBalance();
  }, [fetchBalance]);

  const ARBalance = useMemo(() => {
    // only show negative AR balances on payment page
    if (isPaymentPage) {
      return balance.currentBalance ? balance?.currentBalance + totalInvoiceSavings * 100 : 0;
    } else {
      return balance?.currentBalance && balance?.currentBalance > 0 ? balance?.currentBalance : 0;
    }
  }, [balance.currentBalance, isPaymentPage, totalInvoiceSavings]);

  useEffect(() => {
    if (paymentDue < MINIMUM_PAYMENT_AMOUNT && !!isPaymentPage) {
      setValidationMessage('Total payment collected must be at least $0.50.');
    }
    paymentDue >= MINIMUM_PAYMENT_AMOUNT && !!isPaymentPage && setValidationMessage('');
  }, [isPaymentPage, paymentDue]);

  const handleSelectBalance = useCallback(() => {
    const normalizedARBalance = (ARBalance || 0) / 100;

    // Early return if it is the payment page and there is no balance to select
    if (isPaymentPage && !paymentDue) return;

    if (isPaymentPage) {
      const totalAfterApplyingBalance = paymentDue + normalizedARBalance;

      if (totalAfterApplyingBalance < MINIMUM_PAYMENT_AMOUNT) {
        setValidationMessage('Total payment collected must be at least $0.50.');
        setIsBalanceSelected(false);
        return;
      }

      const newPaymentDue = isBalanceSelected ? paymentDue - normalizedARBalance : paymentDue + normalizedARBalance;

      setIsBalanceSelected(!isBalanceSelected);
      updateInvoiceCost && updateInvoiceCost(newPaymentDue);
      return;
    }

    const newPaymentDue = isBalanceSelected ? 0 : normalizedARBalance;

    setIsBalanceSelected(!isBalanceSelected);
    updateInvoiceCost && updateInvoiceCost(newPaymentDue);
  }, [ARBalance, isBalanceSelected, isPaymentPage, paymentDue, setIsBalanceSelected, updateInvoiceCost]);

  const showBalance = useMemo(() => {
    // if there is no balance.currentBalance, don't show balance
    if (!balance?.currentBalance) return false;
    // if it is not the payment page and the balance is negative, don't show balance
    if (!isPaymentPage && balance?.currentBalance < 0) return false;
    // if it is the payment page show the balance unless the balance is positive
    if (isPaymentPage && balance?.currentBalance < 0) return true;
    // if isBalance is selected when the payment page loads then that means that we are applying benefits to a client balance (vs invoice)
    if (isPaymentPage && isBalanceSelected) return false;
    return true;
  }, [balance?.currentBalance, isBalanceSelected, isPaymentPage]);

  if (clinicPetParent?.id) {
    return isPimsClinicPetParent ? (
      <Box>
        <HStack spacing={4} justifyContent="space-between">
          {isPaymentPage && !isBalanceSelected && (
            <Box>
              <Text size="sm">Client Account Balance</Text>
            </Box>
          )}
        </HStack>
        {showBalance || isBalanceLoading ? (
          <>
            <RefreshLink onClick={refreshBalanceData} isLoading={isBalanceLoading} />
            <BalanceDisplay
              balanceTerminology={balanceTerminology}
              ARBalance={ARBalance}
              isBalanceSelected={isBalanceSelected}
              handleSelectBalance={handleSelectBalance}
              validationMessage={validationMessage}
              showSkeleton={isBalanceLoading}
            />
          </>
        ) : (
          <>
            {!isBalanceSelected && (
              <>
                <RefreshLink onClick={refreshBalanceData} isLoading={isBalanceLoading} />
                <Box pb={4}>
                  <Text>{noEligibleBalanceMessage}</Text>
                </Box>
              </>
            )}
          </>
        )}

        {isInvoiceDiscoverySupported && isInvoicePreviewSupported && !isPaymentPage && (
          <HasFeature name={FeatureFlagName.InvoiceDiscovery}>
            <RefreshLink onClick={refreshInvoiceData} isLoading={isInvoiceLoading} />
            <>
              {!invoices?.length && !isDiscoveredInvoiceDemoModeEnabled && !isInvoiceLoading && (
                <Box pb={4}>
                  <Text>{emptyInvoiceStateMessage}</Text>
                </Box>
              )}
              {isInvoiceLoading ? (
                <InvoiceDisplay
                  isPendingPaymentIntents={isPendingPaymentIntents}
                  setSelectedInvoiceId={setSelectedInvoiceId}
                  selectedInvoiceId={selectedInvoiceId}
                  updateInvoiceCost={updateInvoiceCost}
                  setIsBalanceSelected={setIsBalanceSelected}
                  setValidationMessage={setValidationMessage}
                  showSkeleton={true}
                />
              ) : (
                invoices.map((invoice) => {
                  return (
                    <InvoiceDisplay
                      invoice={invoice}
                      isPendingPaymentIntents={isPendingPaymentIntents}
                      setSelectedInvoiceId={setSelectedInvoiceId}
                      selectedInvoiceId={selectedInvoiceId}
                      updateInvoiceCost={updateInvoiceCost}
                      setIsBalanceSelected={setIsBalanceSelected}
                      setValidationMessage={setValidationMessage}
                      key={invoice.id}
                      showSkeleton={isInvoiceLoading}
                    />
                  );
                })
              )}
              {!!validationMessage && (
                <Alert title={validationMessage} status="error" hideCloseButton mt={4} mb={3} mr={2} />
              )}
            </>
          </HasFeature>
        )}
      </Box>
    ) : (
      <Box>
        <Divider height="md" borderBottomWidth="1px" borderBottomColor="border.default" />
        <Box my={2} px={2}>
          {errorMessage}
        </Box>
      </Box>
    );
  }
  return <></>;
};

export default InvoiceAndARDisplay;
