import React, { useMemo, useCallback } from 'react';
import { Flex, Text, Heading, Box, Switch, Select, SelectOptionProps, Grid, GridItem } from '@televet/kibble-ui';
import { useSurveySettingsContext } from 'pages/Survey/context/SurveySettingsContext';
import { SurveySettingsActions } from 'pages/Survey/context/SurveySettingsReducer';
import { Mixpanel } from 'shared/utils/mixpanel';

enum ConversionTypes {
  toMinutes = 'toMinutes',
  toDays = 'toDays',
}

const AppointmentTypesTable = (): JSX.Element => {
  const {
    appointmentTypeSettings,
    generalSettings: { isAutomatedTriggerForAllAppointmentTypes },
    dispatch,
  } = useSurveySettingsContext();

  const convertDelayOptions = useCallback(({ delayValue, conversionType }): number => {
    switch (conversionType) {
      case ConversionTypes.toMinutes:
        return Math.floor(delayValue * 1440);
      case ConversionTypes.toDays:
        return Math.floor(delayValue / 1440);
      default:
        return 0;
    }
  }, []);

  const delayAmountOptions = useMemo((): SelectOptionProps[] => {
    const options: SelectOptionProps[] = [];
    let sendDelay = 1;
    while (sendDelay <= 7) {
      options.push({
        value: `${convertDelayOptions({ delayValue: sendDelay, conversionType: ConversionTypes.toMinutes })}`,
        label: `${sendDelay} day${sendDelay > 1 ? 's' : ''}`,
      });

      sendDelay++;
    }
    return options || [];
  }, [convertDelayOptions]);

  const mixpanelEventNames = useMemo((): Record<'automatedTriggerInMinutes' | 'isActive', string> => {
    return {
      automatedTriggerInMinutes: 'Survey appointment type delay in minutes changed',
      isActive: 'Survey appointment type active status changed',
    };
  }, []);

  const handleChange = useCallback(
    (
      field: 'automatedTriggerInMinutes' | 'isActive',
      appointmentTypesSettings,
      changedAppointmentTypeId: string,
    ): void => {
      dispatch({
        type: SurveySettingsActions.UPDATE_APPOINTMENT_TYPE_SETTINGS,
        payload: appointmentTypesSettings,
      });
      dispatch({
        type: SurveySettingsActions.ADD_CHANGED_SURVEY_APPOINTMENT_TYPE_SETTING_ID,
        payload: changedAppointmentTypeId,
      });
      Mixpanel.track(mixpanelEventNames[field], { appointmentTypes: appointmentTypesSettings });
    },
    [dispatch, mixpanelEventNames],
  );

  const handleDelayAmountChange = useCallback(
    (selectedItem: SelectOptionProps, appointmentTypeId: string) => {
      const _appointmentTypes = [...appointmentTypeSettings];

      const alteredType = _appointmentTypes.find((type) => type.appointmentTypeId === appointmentTypeId);

      if (alteredType && selectedItem.value) {
        alteredType.automatedTriggerInMinutes = parseInt(selectedItem.value);
      }

      handleChange('automatedTriggerInMinutes', _appointmentTypes, appointmentTypeId);
    },
    [appointmentTypeSettings, handleChange],
  );

  const handleIsEnabledToggleChange = useCallback(
    (isChecked, appointmentTypeId) => {
      const _appointmentTypes = [...appointmentTypeSettings];

      const alteredType = _appointmentTypes.find((type) => type.appointmentTypeId === appointmentTypeId);

      if (alteredType) {
        alteredType.isActive = isChecked;
      }

      handleChange('isActive', _appointmentTypes, appointmentTypeId);
    },
    [appointmentTypeSettings, handleChange],
  );

  return (
    <Box className="settings__container" mt="24px">
      <Grid gap={4} templateRows="repeat(5, 1fr)" templateColumns="repeat(11, 1fr)">
        <GridItem colSpan={5}>
          <Heading fontWeight="bold" className="settings__header" size="sm">
            Appointment Type
          </Heading>
        </GridItem>
        <GridItem colSpan={5}>
          <Heading fontWeight="bold" className="settings__header" size="sm">
            Send Delay
          </Heading>
        </GridItem>
        <GridItem textAlign="right">
          <Heading fontWeight="bold" className="settings__header" size="sm">
            Enable
          </Heading>
        </GridItem>
        {appointmentTypeSettings?.map((type, index) => {
          return (
            <React.Fragment key={type.id || index}>
              <GridItem colSpan={5}>{type.appointmentTypeName}</GridItem>
              <GridItem colSpan={5}>
                <Select
                  value={type.automatedTriggerInMinutes.toString()}
                  listProps={{
                    onSelect: (selectedItem: SelectOptionProps): void =>
                      handleDelayAmountChange(selectedItem, type.appointmentTypeId),
                  }}
                  width="200px"
                  size="sm"
                  options={delayAmountOptions}
                />
              </GridItem>
              <GridItem textAlign={'right'}>
                <Flex className="settings__fieldRow-input" flexDir="row" justify="flex-end" align="baseline">
                  <Switch
                    isDisabled={isAutomatedTriggerForAllAppointmentTypes}
                    isChecked={isAutomatedTriggerForAllAppointmentTypes ? true : type.isActive}
                    pr="10px"
                    onChange={(): void => handleIsEnabledToggleChange(!type.isActive, type.appointmentTypeId)}
                  />
                  <Text
                    color={isAutomatedTriggerForAllAppointmentTypes ? 'subtle' : 'default'}
                    minWidth="30px"
                    size="xs"
                  >
                    {type.isActive || isAutomatedTriggerForAllAppointmentTypes ? 'ON' : 'OFF'}
                  </Text>
                </Flex>
              </GridItem>
            </React.Fragment>
          );
        })}
      </Grid>
    </Box>
  );
};

export default AppointmentTypesTable;
