import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Text,
  Button,
  Flex,
  Checkbox,
  ButtonProps,
  CCheckboxGroup,
  useToast,
  Icon,
  HStack,
  Spinner,
  VStack,
  Divider,
} from '@televet/kibble-ui';
import { Controller, useForm } from 'react-hook-form';
import {
  PaymentMedium,
  PaymentRelationship,
  SidePanelParentPetDataFragment,
  useCompleteCareBenefitUsageFollowupMutation,
  useCompleteCareBenefitUsageFollowupStepMutation,
} from 'shared/types/graphql';
import LoadingSpinner from 'shared/components/LoadingSpinner';
import { UseCareBenefitFollowupStep, useCareBenefitFollowup } from '../hooks/useCareBenefitFollowup';
import useClinicUser from 'shared/hooks/useClinicUser';
import useGA from 'shared/hooks/useGA';
import { GA4Events } from 'shared/enums/GA4Events';
import { StripeTerminalClinicPetParent } from 'shared/providers/StripeTerminalProvider/types/StripeTerminalClinicPetParent';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { updatePaymentDue, updateSelectedInvoiceId } from '../state/careWizardStepsSlice';
import { useStripeTerminalProvider } from 'shared/providers/StripeTerminalProvider';
import Payment from './CareWizardModal/Payment';
import { parseWriteBackMessage } from './CareWizardModal/utils/paymentHelperFunctions';
import { useSidePanel } from 'shared/components/SidePanel/state/providers/SidePanelProvider';
import { PanelViews } from 'shared/components/SidePanel/shared/types/enums/PanelViews';
import ConfirmPaymentModal from './CareWizardModal/PaymentHelperComponents/ConfirmPaymentModal';
import usePaymentWritebackConfig from 'shared/hooks/usePaymentWritebackConfig';

interface IFollowupStepsFormProps {
  pet: SidePanelParentPetDataFragment | undefined;
  refreshInvoice: () => void;
  refreshBalance: () => void;
  forceFilledForm: boolean;
  showCompletedSteps?: boolean;
  buttonProps?: ButtonProps;
  isPaymentSuccessful?: boolean;
  afterSubmit?: () => void;
  clinicPetParent?: StripeTerminalClinicPetParent;
  showPaymentDisplay?: boolean;
  showFallBackDisplay?: boolean;
  invoiceId?: string;
  setIsPaymentSuccessful?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IFormValues {
  completedSteps: string[];
}

const FollowupStepsForm = ({
  pet,
  refreshInvoice,
  refreshBalance,
  buttonProps,
  forceFilledForm,
  afterSubmit,
  showCompletedSteps = true,
  clinicPetParent,
  showPaymentDisplay,
  showFallBackDisplay,
  invoiceId,
  setIsPaymentSuccessful,
  isPaymentSuccessful,
}: IFollowupStepsFormProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { gaTrack } = useGA();
  const toast = useToast();
  const { setIsOpen, setCurrentView: setCurrentSidePanelView } = useSidePanel();
  const { isClinicWritebackEnabled } = usePaymentWritebackConfig();
  const [showSteps, setShowSteps] = useState<boolean>(false);
  const [confirmPaymentModalOpen, setConfirmPaymentModalOpen] = useState(false);
  const { handleSubmit, control, watch } = useForm<IFormValues>({
    mode: 'onChange',
  });

  const { followup, loading } = useCareBenefitFollowup(pet?.organizationPet?.id);

  const watchSteps = watch('completedSteps', []);

  const { clinicUser } = useClinicUser();

  const [completeStep] = useCompleteCareBenefitUsageFollowupStepMutation();
  const [completeFollowup] = useCompleteCareBenefitUsageFollowupMutation();
  const careWizardDraft = useAppSelector((state) => state.CareWizardSteps.careWizardDraft);

  const { paymentDue, selectedInvoiceId, isBalanceSelected } = careWizardDraft;

  const isPimsClinicPetParent = !!clinicPetParent?.pimsId;

  const nonPimsOrWriteBackNotEnabled = useMemo(() => {
    return !isPimsClinicPetParent || !isClinicWritebackEnabled;
  }, [isPimsClinicPetParent, isClinicWritebackEnabled]);

  const filteredSteps = useMemo(() => {
    const filteredByOrderSteps = [...(followup?.followupSteps || [])].sort((a, b) => a.displayOrder - b.displayOrder);
    return showCompletedSteps ? filteredByOrderSteps : filteredByOrderSteps?.filter((step) => !step.completed);
  }, [showCompletedSteps, followup?.followupSteps]);

  useEffect(() => {
    // prevent enter key from submitting the form
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return (): void => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const refreshBalanceAndInvoice = useCallback(() => {
    if (isPimsClinicPetParent) {
      if (!!invoiceId) {
        refreshInvoice();
      } else if (isBalanceSelected) {
        refreshBalance();
      }
    }
  }, [invoiceId, isBalanceSelected, isPimsClinicPetParent, refreshBalance, refreshInvoice]);

  const onSubmit = useCallback(
    async (data: IFormValues) => {
      try {
        if (data.completedSteps?.length) {
          data.completedSteps.forEach(async (step) => {
            await completeStep({
              variables: {
                stepId: step,
                completedAt: new Date(),
                completedByUserId: clinicUser?.id || '',
              },
            });
          });
        }

        if (filteredSteps?.length === data.completedSteps.length) {
          // if all steps are completed, complete the follow-up
          await completeFollowup({
            variables: {
              followupId: followup?.id || '',
              completedAt: new Date(),
            },
          });
        }

        gaTrack(GA4Events.MEMBERSHIP_WIZARD_COMPLETE);

        if (afterSubmit && (paymentDue >= 0 || nonPimsOrWriteBackNotEnabled)) {
          refreshBalanceAndInvoice();
          afterSubmit();
        }
      } catch (error) {
        console.error('Encountered an error updating follow-up and/or steps', error);
        toast({ status: 'error', title: 'Encountered an error updating follow-up and/or steps' });
      }
    },
    [
      afterSubmit,
      clinicUser?.id,
      completeFollowup,
      completeStep,
      filteredSteps?.length,
      followup?.id,
      gaTrack,
      nonPimsOrWriteBackNotEnabled,
      paymentDue,
      refreshBalanceAndInvoice,
      toast,
    ],
  );

  const handleExit = useCallback(
    async (completed: boolean) => {
      if (completed) {
        await completeFollowup({
          variables: {
            followupId: followup?.id || '',
            completedAt: new Date(),
          },
        });
      }
      gaTrack(GA4Events.MEMBERSHIP_WIZARD_COMPLETE);

      if (afterSubmit) {
        refreshBalanceAndInvoice();
        afterSubmit();
      }
    },
    [afterSubmit, completeFollowup, followup?.id, gaTrack, refreshBalanceAndInvoice],
  );

  const sendToSidePanel = async (): Promise<void> => {
    setTimeout(() => {
      setCurrentSidePanelView(PanelViews.Invoicing);
      setIsOpen(true);
      if (terminalOptions && terminalOptions.length > 0) {
        setCurrentInvoicingPaymentMedium(PaymentMedium.StripeTerminal);
      } else {
        setCurrentInvoicingPaymentMedium(PaymentMedium.StripeVirtualTerminal);
      }
      setSelectedInvoicingPetParent(clinicPetParent);
      setInvoiceIds(invoiceId ? [invoiceId] : []);
    }, 0);
  };

  const completeWriteBackFollowUpStep = useCallback(
    async (stepId: string) => {
      try {
        await completeStep({
          variables: {
            stepId,
            completedAt: new Date(),
            completedByUserId: clinicUser?.id || '',
          },
          onCompleted: async () => {
            // when this is complete we need the filteredSteps to be updated so that the completed variable can accurately reflect the status of the step, then we need to complete follow up if true

            let completed = false;
            filteredSteps?.forEach((step) => {
              step.completed ? (completed = true) : (completed = false);
              // }
            });
            // all completed steps then complete followup
            if (completed || paymentDue === 0) {
              handleExit(true);
            }
          },
        });
      } catch (error) {
        console.error('Encountered an error updating follow-up and/or steps', error);
        toast({ status: 'error', title: 'Encountered an error updating follow-up and/or steps' });
      }
    },
    [clinicUser?.id, completeStep, filteredSteps, handleExit, paymentDue, toast],
  );

  const setSelectedInvoiceId = useCallback(
    (selectedInvoiceId: string | null) => {
      dispatch(updateSelectedInvoiceId(selectedInvoiceId));
    },
    [dispatch],
  );

  const updateInvoiceTotalCost = useCallback(
    (invoiceTotalCost: number) => {
      // if invoicetotalcost is less than 0 we don't want to update the dispatch
      if (invoiceTotalCost < 0) {
        return;
      }
      dispatch(updatePaymentDue(invoiceTotalCost));
    },
    [dispatch],
  );

  const {
    terminalOptions,
    currentInvoiceStatus,
    setCurrentInvoiceStatus,
    setInvoiceIds,
    setIsBalancePreSelected,
    setCurrentPaymentMedium: setCurrentInvoicingPaymentMedium,
    setSuggestedPetParentOptions: setSuggestedInvoicingPetParentOptions,
    setSelectedPetParent: setSelectedInvoicingPetParent,
    invoiceIds,
    isBalancePreSelected,
    paymentRelationship,
  } = useStripeTerminalProvider();

  useEffect(() => {
    setTimeout(() => {
      setSelectedInvoicingPetParent(clinicPetParent || undefined);
      setInvoiceIds(selectedInvoiceId ? [selectedInvoiceId] : []);
      setIsBalancePreSelected(isBalanceSelected);
    }, 0);
  }, [
    clinicPetParent,
    currentInvoiceStatus,
    isBalanceSelected,
    pet,
    selectedInvoiceId,
    setCurrentInvoiceStatus,
    setCurrentInvoicingPaymentMedium,
    setInvoiceIds,
    setIsBalancePreSelected,
    setSelectedInvoicingPetParent,
    setSuggestedInvoicingPetParentOptions,
    terminalOptions,
  ]);

  const isInvoiceOrBalanceSelected = useMemo(() => {
    return (!!invoiceIds && invoiceIds?.length > 0) || !!isBalancePreSelected;
  }, [invoiceIds, isBalancePreSelected]);

  const unCompletedFollowUpSteps = useMemo(() => {
    return filteredSteps?.filter((step) => step?.completed === false);
  }, [filteredSteps]);

  const completeUncompletedFollowUpSteps = useCallback(
    async (id: string) => {
      try {
        completeStep({
          variables: {
            stepId: id || '',
            completedAt: new Date(),
            completedByUserId: clinicUser?.id || '',
          },
        });
        setShowSteps(true);
      } catch (error) {
        console.error('Encountered an error updating follow-up and/or steps', error);
        toast({ status: 'error', title: 'Encountered an error updating follow-up and/or steps' });
      }
    },
    [clinicUser?.id, completeStep, toast],
  );

  useEffect(() => {
    if (
      showPaymentDisplay &&
      paymentDue === 0 &&
      !!unCompletedFollowUpSteps &&
      unCompletedFollowUpSteps?.length === 1
    ) {
      // if the invoice is fully discounted so no payment is due complete the payment follow up step
      // TO DO: sometimes this becomes 0 when it shouldnt
      if (unCompletedFollowUpSteps[0].id) {
        completeUncompletedFollowUpSteps(unCompletedFollowUpSteps[0].id);
      }
    }
  }, [
    completeStep,
    completeUncompletedFollowUpSteps,
    filteredSteps,
    paymentDue,
    showPaymentDisplay,
    toast,
    unCompletedFollowUpSteps,
    updateInvoiceTotalCost,
  ]);

  const showStep = useCallback((step, index) => {
    // show the step if its index is not two (payment step), or if its index is two but it is completed
    return index !== 2 || (index === 2 && step.completed);
  }, []);

  const openConfirmPaymentModal = useCallback(() => {
    setConfirmPaymentModalOpen(true);
  }, [setConfirmPaymentModalOpen]);

  useEffect(() => {
    // if paymentDue is 0 and all steps are completed setIsPaymentSuccessful to true (even if there was no payment but the benefits zeroed out the balance)
    if (paymentDue === 0 && filteredSteps?.every((step) => step.completed === true)) {
      if (setIsPaymentSuccessful) {
        setIsPaymentSuccessful(true);
      }
    }
  }, [filteredSteps, paymentDue, setIsPaymentSuccessful]);

  const isInvoiceWriteBackRelated = useMemo(() => {
    return !!filteredSteps?.find((step) => !!step?.careAccountCreditIntents?.find((intent) => !!intent?.invoiceId));
  }, [filteredSteps]);

  const isBalanceWriteBackRelated = useMemo(() => {
    return paymentRelationship === PaymentRelationship.AccountBalance;
  }, [paymentRelationship]);

  const isInvoiceOrBalanceWriteBackRelatedFollowUp = useMemo(() => {
    // find a step with careAccountCreditIntents that has an invoiceId on it or one with careAccountCreditPimsWritebackLogEntries.writeBackLogId
    return isInvoiceWriteBackRelated || isBalanceWriteBackRelated;
  }, [isBalanceWriteBackRelated, isInvoiceWriteBackRelated]);

  const showPaymentLinkButton = useMemo(() => {
    const careAccountIntentInvoiceId = filteredSteps
      ?.find((step) => !!step?.careAccountCreditIntents?.find((intent) => !!intent?.invoiceId))
      ?.careAccountCreditIntents?.find((intent) => !!intent?.invoiceId)?.invoiceId;

    if (!!isInvoiceWriteBackRelated) {
      // if there is an invoiceId in the careAccountCreditIntents then show the payment link button if there is also an invoiceId returned for the client
      // And the invoiceIds match (indicating that the returned invoiceId is the same one that is related to the careAccountCreditIntent vs a newly opened invoice)
      // if there is no invoiceId that invoice has likely already been paid and going to the invoicing panel will show nothing left to pay
      return !!invoiceId && careAccountIntentInvoiceId === invoiceId;
    } else if (isBalanceWriteBackRelated) {
      // if it is balance related we cannot be sure if the balance has been paid or not simply by testing if there is a balance for the client, so always true
      return true;
    }
    return false;
  }, [filteredSteps, invoiceId, isBalanceWriteBackRelated, isInvoiceWriteBackRelated]);

  const renderPaymentSection = (): JSX.Element => (
    <Payment
      clinicPetParent={clinicPetParent}
      setSelectedInvoiceId={setSelectedInvoiceId}
      updateInvoiceCost={updateInvoiceTotalCost}
      unCompletedFollowUpSteps={unCompletedFollowUpSteps}
      completeUncompletedFollowUpSteps={completeUncompletedFollowUpSteps}
      setIsPaymentSuccessful={setIsPaymentSuccessful}
    />
  );

  const renderStepsSection = (step: UseCareBenefitFollowupStep, index: number): JSX.Element => {
    if (showPaymentDisplay) {
      return (
        showStep(step, index) && (
          <HStack key={`${step.id}-paymentDisplay`} p="3">
            {step.completed ? <Icon name="checkmark" size="md" /> : <Spinner size="xs" />}
            <Text size="sm">{index === 2 ? 'Collected remaining balance due' : step.description}</Text>
          </HStack>
        )
      );
    } else if (
      showFallBackDisplay &&
      (!!isInvoiceOrBalanceSelected || !!isInvoiceOrBalanceWriteBackRelatedFollowUp) &&
      isPimsClinicPetParent
    ) {
      return (
        <React.Fragment key={step.id}>
          {index === 1 && !step.completed && (
            <VStack key={`${step.id}-fallBackDisplay`}>
              <Text size="xs" fontWeight="semibold" alignSelf="baseline">
                Writeback Failed
              </Text>
              <Text size="xs">{parseWriteBackMessage(step.description, index)}</Text>
              <Button
                value={step.id}
                size="xs"
                variant="secondary"
                width="100%"
                onClick={(): void => {
                  completeWriteBackFollowUpStep(step.id);
                }}
              >
                {parseWriteBackMessage(step.description, -1)}
              </Button>
            </VStack>
          )}
          {index === 2 && !isPaymentSuccessful && (
            <VStack key={`${step.id}-fallBackDisplay`}>
              <Text size="xs" fontWeight="bold" alignSelf="baseline">
                Payment Due
              </Text>
              <Text size="xs">There is still an outstanding balance due for this pet.</Text>
              {!!showPaymentLinkButton && (
                <>
                  <Button
                    value={step.id}
                    size="xs"
                    variant="primary"
                    width="100%"
                    isDisabled={!filteredSteps?.some((step) => step.completed === false)}
                    onClick={(): void => {
                      sendToSidePanel();
                    }}
                  >
                    {parseWriteBackMessage(step.description, 2)}
                  </Button>
                  <Text size="xs">Or</Text>
                </>
              )}
              <Button
                value={step.id}
                size="xs"
                type="submit"
                variant="secondary"
                width="100%"
                onClick={openConfirmPaymentModal}
              >
                I have collected the payment
              </Button>
              <ConfirmPaymentModal
                isOpen={confirmPaymentModalOpen}
                onClose={(): void => setConfirmPaymentModalOpen(false)}
                onConfirm={(): void => {
                  completeWriteBackFollowUpStep(step.id);
                }}
                modalText={parseWriteBackMessage(step.description, -2)}
              />
            </VStack>
          )}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment key={step.id}>
          {index !== 0 && <Divider my={2} />}
          <Checkbox value={step.id} isDisabled={step.completed}>
            <Text size="sm">{step.description}</Text>
          </Checkbox>
        </React.Fragment>
      );
    }
  };
  const renderButtons = (): JSX.Element | null => {
    if (
      nonPimsOrWriteBackNotEnabled ||
      (!isInvoiceOrBalanceSelected &&
        !isInvoiceOrBalanceWriteBackRelatedFollowUp &&
        (!showPaymentDisplay || showPaymentDisplay === undefined))
    ) {
      return (
        <Flex pb={4} mt={4} justify="center">
          <Button
            data-testid="benefits-im-done-button"
            type="submit"
            {...buttonProps}
            disabled={forceFilledForm ? watchSteps.length !== filteredSteps?.length : false}
          >
            I&apos;m Done!
          </Button>
        </Flex>
      );
    } else if (showPaymentDisplay && !!isPaymentSuccessful && isClinicWritebackEnabled) {
      return (
        <Flex pb={4} justify="center">
          <Button onClick={(): Promise<void> => handleExit(true)} variant="secondary">
            Exit
          </Button>
        </Flex>
      );
    }
    return null;
  };

  return loading && !showSteps ? (
    <LoadingSpinner message="Loading Final Steps..." />
  ) : (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      {isPimsClinicPetParent &&
        !!showPaymentDisplay &&
        paymentDue > 0 &&
        !isPaymentSuccessful &&
        renderPaymentSection()}
      <Flex direction="column" gap={1} justify="stretch" pb={showPaymentDisplay ? '20' : '0'}>
        <Controller
          as={CCheckboxGroup}
          name="completedSteps"
          control={control}
          defaultValue={
            showCompletedSteps ? followup?.followupSteps.filter((step) => step.completed).map((step) => step.id) : []
          }
        >
          {filteredSteps?.map((step, index) => renderStepsSection(step, index))}
        </Controller>
      </Flex>
      {renderButtons()}
    </form>
  );
};

export default FollowupStepsForm;
