import React, { MouseEvent, useCallback, useMemo, useRef, useState } from 'react';
import * as Sentry from '@sentry/react';
import { Box, Flex, useMediaQuery } from '@televet/kibble-ui/build/chakra';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { Menu, MenuItemProps } from '@televet/kibble-ui/build/components/Menu';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { Tooltip } from '@televet/kibble-ui/build/components/Tooltip';
import { Icon } from '@televet/kibble-ui/build/components/Icon';
import useCheckChannelExportability, {
  CheckChannelExportabilityResponse,
} from 'pages/Conversations/hooks/useCheckChannelExportability';
import { closeChannelOverlay, ExportType, updateExportData } from 'pages/Conversations/state/conversationsSlice';
import { getChannelParticipants } from 'pages/Conversations/utils';
import { useHistory } from 'react-router-dom';
import {
  AvatarGroupSelect,
  IAvatarGroupSelectOption,
  IAvatarOption,
  useAvatarGroupSelectOptions,
} from 'shared/components/Avatars/AvatarGroupSelect';
import ChannelStatusSelect from 'shared/components/ChannelStatusSelect/ChannelStatusSelect';
import { IOption, PopoverPlacement } from 'shared/components/Dropdown';
import { useSidePanelSearch } from 'shared/components/SidePanel/components/SearchPanel/state/providers/SearchPanelProvider';
import { DeviceSizes } from 'shared/enums/DeviceSizes';
import { GraphQLFetchPolicies } from 'shared/enums/GraphQLFetchPolicies';
import { Placement } from 'shared/enums/Placement';
import useAddChannelMember from 'shared/hooks/useAddChannelMember';
import useRemoveChannelMember from 'shared/hooks/useRemoveChannelMember';
import { useIntegrationsProvider } from 'shared/providers/IntegrationsProvider';
import {
  ChannelStatusAction,
  ChannelViewChannelFragment,
  ChannelViewChannelMemberFragment,
  ChannelViewClientDropdownClinicPetParentFragment,
  ClinicPetParent,
  useGetChannelViewClientDropdownPetParentsLazyQuery,
  useUpdateChannelAssigneesMutation,
} from 'shared/types/graphql';
import { Mixpanel } from 'shared/utils/mixpanel';
import { useAppDispatch } from 'state/hooks';
import styled from 'styled-components/macro';
import ChatEntityPopover from './ChatEntityPopover/components/ChatEntityPopover';
import { RouteBasePaths } from 'routes';
import { useToast } from '@televet/kibble-ui/build/components/Toast';
import { useIsOverflow } from 'shared/hooks/useIsOverflow';

interface IProps {
  channel?: ChannelViewChannelFragment;
  onRefetchChannel: () => void;
  isOverlay?: boolean;
}

export enum ExportConversationOptions {
  ExportToPDF = 'ExportPDF',
  ExportToDB = 'ExportDB',
  IncludeNotes = 'IncludeNotes',
}

const ChannelViewToolbar = ({ channel, onRefetchChannel, isOverlay }: IProps): JSX.Element => {
  const scrollContainerRef = useRef(null);
  const toast = useToast();
  const history = useHistory();
  const [addChannelMember] = useAddChannelMember();
  const [removeChannelMember] = useRemoveChannelMember();
  const [channelExportability, setChannelExportability] = useState<CheckChannelExportabilityResponse>();
  const [isChatEntityPopoverOpen, setIsChatEntityPopoverOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { checkChannelExportability } = useCheckChannelExportability();
  const { primaryIntegrationName } = useIntegrationsProvider();
  const [isSmallerThanMobileMaxWidth] = useMediaQuery('(' + DeviceSizes.MobileMaxWidth + ')');
  const isOverflow = useIsOverflow({ ref: scrollContainerRef, overflowDirection: 'x' });

  const { channelPetParents, channelPetParentIds, allChannelPets, petParentChannelMembers } = getChannelParticipants(
    channel?.channelMembers,
  );

  const { viewAddClientOrPatient, viewClinicPetParent } = useSidePanelSearch();

  const getPetParentOptions = useCallback(
    (
      parents: ChannelViewClientDropdownClinicPetParentFragment[],
      addSelection?: (parent: ChannelViewClientDropdownClinicPetParentFragment) => boolean,
    ): IAvatarGroupSelectOption[] => {
      if (!parents || !parents?.length) return [];

      if (addSelection) {
        return parents.map((parent) => {
          return {
            text: [parent.firstName, parent.lastName].join(' ').trim(),
            value: { ...parent, clinicPetParentId: parent.id },
            isSelected: addSelection(parent),
          };
        });
      }
      return parents.map((parent) => {
        return { text: [parent.firstName, parent.lastName].join(' ').trim(), value: parent };
      });
    },
    [],
  );

  /**
   * Pet parent channel members that are active
   */
  const petParentAvatars: IAvatarGroupSelectOption[] = useMemo(() => {
    return channelPetParents.map((petParent) => {
      const { id, firstName, lastName } = petParent;
      return {
        text: [firstName, lastName].join(' ').trim(),
        value: { petParent, id, firstName, lastName },
        isSelected: true,
      };
    });
  }, [channelPetParents]);

  const [getAvailablePetParentList, { data: availablePetParentData, loading: isLoadingPetParentOptions }] =
    useGetChannelViewClientDropdownPetParentsLazyQuery({
      fetchPolicy: GraphQLFetchPolicies.CacheAndNetwork,
      notifyOnNetworkStatusChange: true,
    });

  const petParentOptions = useMemo(() => {
    const checkPetParentAvailable = (parent: ChannelViewClientDropdownClinicPetParentFragment): boolean => {
      if (channel && parent) {
        const member = parent.channelMembers?.find((member) => member.channel?.id === channel.id);
        return !member?.removedAt && petParentAvatars.some((a) => a.value?.id === parent.id);
      }
      return false;
    };

    const petParentOptions = getPetParentOptions(
      availablePetParentData?.findManyClinicPetParent || [],
      checkPetParentAvailable,
    );

    const petParentsInChannelWithoutPetAssignment = petParentAvatars.filter((f) => {
      return petParentOptions.every((ppo) => ppo.value.id !== f.value.id);
    });

    return petParentOptions.concat(petParentsInChannelWithoutPetAssignment);
  }, [channel, petParentAvatars, availablePetParentData, getPetParentOptions]);

  const handleLoadClientDropdownOptions = useCallback(async () => {
    const conversationPetIds = allChannelPets.map((pet) => pet.id);
    if (conversationPetIds) {
      getAvailablePetParentList({ variables: { petIds: conversationPetIds } });
    }
  }, [allChannelPets, getAvailablePetParentList]);

  const [updateChannelAssignees, { loading: isUpdateChannelLoading }] = useUpdateChannelAssigneesMutation();

  const assignees = useMemo(() => channel?.assignees || [], [channel]);

  const assigneeOptions = useAvatarGroupSelectOptions({
    selectedUserIds: assignees.map(({ id }) => id),
  });

  const handleAssigneeToggle = useCallback(
    (e: MouseEvent, option: IOption) => {
      if (!channel || isUpdateChannelLoading) return;
      let assigneeUserIds = [];
      if (!option.isSelected) {
        assigneeUserIds = assignees.concat(option.value).map(({ id }) => ({ id }));
        Mixpanel.track('Staff added to conversation');
      } else {
        assigneeUserIds = assignees.filter((a) => a.id !== option.value.id).map(({ id }) => ({ id }));
      }

      updateChannelAssignees({
        variables: {
          where: {
            id: channel.id,
          },
          data: { twilioChannelSid: channel.twilioChannelSid, assignees: { set: assigneeUserIds } },
        },
      });
    },
    [channel, assignees, isUpdateChannelLoading, updateChannelAssignees],
  );

  // Disable pet parent from being selected if they are in another active convo and have not been removed from that convo
  const setDisabled = useCallback(
    (parent: ClinicPetParent) => {
      return !!parent.channelMembers?.filter(
        (member) =>
          member?.channel?.channelStatus?.channelStatusAction === ChannelStatusAction.Active &&
          !member?.removedAt &&
          !petParentChannelMembers.some(
            (a: ChannelViewChannelMemberFragment) => a?.clinicPetParent?.id === parent.id && !a?.removedAt,
          ),
      )?.length;
    },
    [petParentChannelMembers],
  );

  const handlePetParentToggle = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (_: any, option: IOption<IAvatarOption>) => {
      const optionValue = option.value;

      if (!channel) {
        toast({
          status: 'error',
          description: "An error occurred modifying this conversation's data. Please try again.",
          duration: 10000,
        });
        throw new Error('Conversation channel ID not found for this conversation.');
      }

      const channelMemberId = channel?.channelMembers.find(
        ({ clinicPetParent }) => clinicPetParent?.id === optionValue.id,
      )?.id;

      if (!option.isSelected) {
        try {
          await addChannelMember({ variables: { channelId: channel.id, clinicPetParentId: optionValue.id } });
          toast({
            description: `${option.text || 'User'} was successfully added to the conversation`,
          });
          Mixpanel.track('Pet parent added to conversation');
        } catch (e) {
          Sentry.captureException(e);
          toast({
            status: 'error',
            description: 'An error occurred in adding this client',
            duration: 10000,
          });
          console.error(e);
        }
      } else {
        if (petParentAvatars?.length > 1) {
          try {
            if (!channelMemberId) {
              toast({
                status: 'error',
                description: "An error occurred in finding this client's ID.",
                duration: 10000,
              });

              throw new Error('Member ID not found for this conversation member.');
            }
            await removeChannelMember({ variables: { channelMemberId } });
            toast({
              description: `${option.text || 'User'} was successfully removed from the conversation`,
            });
            Mixpanel.track('Pet parent removed from conversation');
          } catch (e) {
            Sentry.captureException(e);
            toast({
              status: 'error',
              description: 'An error occurred in removing this client',
              duration: 10000,
            });
            console.error(e);
          }
        } else {
          toast({
            status: 'info',
            description: 'You cannot remove the last client in a conversation',
            duration: 10000,
          });
        }
      }
      onRefetchChannel();
    },
    [channel, onRefetchChannel, toast, addChannelMember, petParentAvatars?.length, removeChannelMember],
  );

  const selectPetParent = async (e: MouseEvent, option?: IOption): Promise<void> => {
    e.stopPropagation();
    const clinicPetParentId = option?.value.id;
    if (!clinicPetParentId) return;
    viewClinicPetParent({ clinicPetParentId });
  };

  const onAddClientClicked = (): void => {
    viewAddClientOrPatient({ conversationId: channel?.id });
    Mixpanel.track('Conversations Add New Client Clicked');
  };

  const renderClientDropdownActions = (): JSX.Element | null => {
    if (channel?.channelStatus?.channelStatusAction !== ChannelStatusAction.Active) {
      return null;
    }

    return (
      <AddClientButton onClick={onAddClientClicked}>
        <AddClientButtonContent>
          <AddClientButtonIconContainer>
            <Icon name="plus" size="xs" />
          </AddClientButtonIconContainer>
          <AddClientButtonText>Add New Contact</AddClientButtonText>
        </AddClientButtonContent>
      </AddClientButton>
    );
  };

  const openExportModal = useCallback(
    (exportType: ExportType) => {
      dispatch(
        updateExportData({
          isExportModalOpen: true,
          selectedExportType: exportType,
          channelPetParents,
          channelId: channel?.id,
        }),
      );
    },
    [dispatch, channelPetParents, channel?.id],
  );

  const fetchMenuData = useCallback(async () => {
    const exportability = await checkChannelExportability(channel?.id || '');
    setChannelExportability(exportability);
  }, [checkChannelExportability, channel?.id]);

  const menuItems: MenuItemProps[] = useMemo(() => {
    return [
      {
        label: (
          <Tooltip
            isDisabled={!channelExportability}
            label={channelExportability?.message || `Client and/or patient are not in ${primaryIntegrationName}`}
          >
            <Box position="relative" data-testid="dropdown-option-2">
              <Text size="sm">Export to {primaryIntegrationName}</Text>
            </Box>
          </Tooltip>
        ),
        isDisabled: !!channelExportability,
        value: 'ExportToPims',
        leftElement: <Icon name="download" variant="subtle" />,
        onSelect: (): void => {
          openExportModal('ExportToPims');
        },
      },
      {
        label: 'Download PDF',
        value: 'DownloadPdf',
        leftElement: <Icon name="form" variant="subtle" />,
        onSelect: (): void => {
          openExportModal('DownloadPdf');
        },
      },
    ];
  }, [channelExportability, openExportModal, primaryIntegrationName]);

  const onCloseButtonClick = (): void => {
    if (isOverlay) {
      dispatch(closeChannelOverlay());
    } else {
      history.push(RouteBasePaths.Conversations);
    }
  };

  const useCompactView = useMemo(
    () => isSmallerThanMobileMaxWidth || isOverlay,
    [isOverlay, isSmallerThanMobileMaxWidth],
  );

  return (
    <Flex
      h="min-content"
      className="ChannelView__ToolbarContainer"
      borderBottomWidth="1px"
      data-testid="channel-view-toolbar-container"
    >
      <Flex
        w="100%"
        align="center"
        justify="space-between"
        overflowX="auto"
        ref={scrollContainerRef}
        py={2}
        pl={2}
        {...(useCompactView && { gap: 4, pl: 4 })}
      >
        <Flex gap={2}>
          {/* Chat Entity Popover */}
          <ChatEntityPopover
            trigger={
              <Button
                iconName="chainLink"
                size="md"
                variant="ghostNeutral"
                onClick={(): void => setIsChatEntityPopoverOpen((value) => !value)}
              />
            }
            isOpen={isChatEntityPopoverOpen}
            clinicPetParentIds={channelPetParentIds}
            onClose={(): void => setIsChatEntityPopoverOpen(false)}
          />

          {/* Channel Status */}
          {!!channel?.channelStatus && (
            <Flex gap={2} align="center">
              {!useCompactView && <Text fontWeight="semibold">Status:</Text>}
              <ChannelStatusSelect
                currentStatus={{
                  name: channel?.channelStatus?.name,
                  color: channel?.channelStatus?.color,
                  id: channel?.channelStatus?.id,
                }}
                channelId={channel.id}
                placement={Placement.BottomEnd}
              />
            </Flex>
          )}
        </Flex>

        {/* Clients */}
        <Flex gap={2} align="center">
          {!useCompactView && <Text fontWeight="semibold">Client{petParentAvatars?.length > 1 ? 's' : ''}:</Text>}

          <AvatarGroupSelect
            options={petParentOptions}
            displayedAvatars={petParentAvatars}
            popoverPlacement={PopoverPlacement.BottomEnd}
            addButtonTooltip="Invite client to conversation"
            onToggle={handlePetParentToggle}
            setDisabled={setDisabled}
            disabledMessage="This client is already a member of an active conversation"
            showRemoveButton={petParentAvatars?.length > 1}
            isLoadingOptions={isLoadingPetParentOptions}
            onAvatarClick={selectPetParent}
            renderDropdownActions={renderClientDropdownActions}
            onLoadOptions={handleLoadClientDropdownOptions}
          />
        </Flex>

        {/* Right Elements */}
        <Flex gap={2}>
          {/* Assignees */}
          <Flex gap={2} align="center">
            {!useCompactView && <Text fontWeight="semibold">Assignees:</Text>}
            <AvatarGroupSelect
              options={assigneeOptions}
              popoverPlacement={PopoverPlacement.BottomEnd}
              addButtonTooltip="Assign team member"
              onToggle={handleAssigneeToggle}
              showRemoveButton={true}
            />
          </Flex>

          {/* Actions */}
          <Box data-testid="convo-export-dropdown-button" {...(isOverflow && { pr: 4 })}>
            <Menu
              onOpen={fetchMenuData}
              buttonProps={{
                variant: 'ghostNeutral',
                size: 'md',
                leftIconName: 'dotsVertical',
                showRightIcon: false,
              }}
              isLazy
              containerProps={{
                placement: 'left-start',
              }}
              listProps={{
                menuItems: menuItems,
              }}
            />
          </Box>
        </Flex>
      </Flex>
      <Flex
        align="center"
        pr={2}
        pl={1}
        pos="sticky"
        right="0"
        bgColor="background.default"
        {...(isOverflow && {
          boxShadow: '0px 0px 15px -3px rgba(0, 0, 0, 0.10), 0px 0px 6px -2px rgba(0, 0, 0, 0.05)',
          borderLeftWidth: '1px',
          pr: 1,
        })}
      >
        <Button data-testid="convo-close-button" iconName="close" variant="ghostNeutral" onClick={onCloseButtonClick} />
      </Flex>
    </Flex>
  );
};

const AddClientButton = styled.button`
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  flex: 0 0 auto;
  height: 38px;
  padding: 0 8px 0 8px;
  position: relative;
  &: hover;
`;

const AddClientButtonContent = styled.span`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 7px;
`;

const AddClientButtonText = styled.span`
  font-size: 13px;
  font-weight: 600;
  margin-left: 7px;
`;

const AddClientButtonIconContainer = styled.span`
  align-items: center;
  background-color: ${(): string => 'background.default'};
  border-radius: 14px;
  display: flex;
  height: 14px;
  justify-content: center;
  width: 14px;
  z-index: 3;
`;

export default ChannelViewToolbar;
