import { IManageMembershipRequestInfo } from './types';
import { IZendeskTicketPriority, IZendeskTicketType, ZendeskTicket } from 'shared/types/zendesk';
import { getPossesiveName } from 'shared/utils';
import {
  CancelReasonType,
  CarePlanInfoFragment,
  ManageMembershipRequestType,
  PaymentSchedule,
} from 'shared/types/graphql';
import { centsToDollars } from '../../utils/currency';
import { getRenewalCycleInMonths } from '../../utils/planRenewalCycle';

export interface IValidationResponse {
  valid: boolean;
  message: string;
}

const isProduction = process.env.REACT_APP_PRODUCTION === 'true';

export const zendeskMessage = (
  manageMembershipRequestInfo: IManageMembershipRequestInfo,
  isPublic: boolean,
): string => {
  const {
    requestType,
    inputFieldText,
    clinic,
    clinicUser,
    client,
    patient,
    stripeCustomer,
    currentPlan,
    pointOfContact,
    cancelReason,
    trueUpInfo,
    transferPet,
    newPlan,
    paymentSchedule,
  } = manageMembershipRequestInfo;
  const poc = pointOfContact ? pointOfContact : clinicUser;
  const showTransferInfo = requestType === ManageMembershipRequestType.Transfer && transferPet;
  const trueUpDollarString = trueUpInfo ? `$${trueUpInfo.trueUpAmount.toFixed(2)}` : '';
  const upgradeString =
    requestType === ManageMembershipRequestType.Upgrade
      ? `Upgrade from ${currentPlan.planName} to ${newPlan?.planName}.`
      : '';
  const trueUpString = trueUpInfo
    ? `${requestType === ManageMembershipRequestType.Upgrade ? 'Upgrade Fee' : 'True Up'} Amount: ${trueUpDollarString}`
    : '';
  const last4String =
    requestType === ManageMembershipRequestType.Upgrade || requestType === ManageMembershipRequestType.Cancel
      ? `to be charged ${paymentSchedule === PaymentSchedule.Full ? 'in full' : ''} to ${getPossesiveName(
          client.clientName,
        )} card ending in ${trueUpInfo.last4}`
      : '';
  const cancelPrivateTrueUpString =
    requestType === ManageMembershipRequestType.Cancel
      ? `True Up Amount: ${trueUpDollarString} ${last4String}. ${
          paymentSchedule === PaymentSchedule.OverTime
            ? 'Pay Over Time: Current monthly payment amount will continue being charged to the card on file until balance is paid off.'
            : ''
        }`
      : '';
  const cancelPublicTrueUpString =
    requestType === ManageMembershipRequestType.Cancel
      ? `True Up Amount: ${trueUpDollarString} ${last4String}. ${
          paymentSchedule === PaymentSchedule.OverTime
            ? '. Current monthly payment amount will continue being charged to the card on file until balance is paid off.'
            : ''
        } Payment method can be changed by the pet parent in the billing portal.`
      : '';
  const shouldShowTrueUp = trueUpInfo.trueUpAmount > 0;
  const dataIds = `Request Type: ${requestType}
  ${cancelReason ? `Cancel Reason: ${cancelReason}` : ''}
  ${shouldShowTrueUp ? `${cancelPrivateTrueUpString}` : ''}
  ${!!inputFieldText.length ? `Description: ${inputFieldText}` : ''}
  Clinic: ${clinic.clinicName}, Clinic Id: ${clinic.clinicId}
  Submitted by: ${clinicUser.name}, Email: ${clinicUser.email}, Id: ${clinicUser.id}
  Point of Contact: ${poc.name}, Email: ${poc.email}, Id: ${poc.id}
  Client: ${client.clientName}, Client Id: ${client.clientId}
  Patient: ${patient.patientName}, Patient Id: ${patient.patientId}
  Stripe Customer Id: ${stripeCustomer.stripeCustomerId}
  Current Care Plan: ${currentPlan.planName}, Id: ${currentPlan.planId}, Enrollment Status: ${
    currentPlan.enrollmentStatus
  }, Enrollment Id: ${currentPlan.enrollmentId}, Subscription Status: ${currentPlan.subscriptionStatus},
  ${
    requestType === ManageMembershipRequestType.Upgrade
      ? `Requested Upgrade Plan: ${newPlan?.planName}, Id: ${newPlan?.planId}`
      : ''
  }
  Care Stripe Subscription Id: ${currentPlan.careStripeSubscriptionId}
  ${
    showTransferInfo
      ? `Transfer Pet Id: ${transferPet?.transferPetId}, Transfer Pet Name: ${transferPet?.transferPetName}`
      : ''
  }
  Note: If ticket was escalated to the Care team or to an L3, please run the EnrollmentSupportEvent script once the work is done to mark the event as completed.
  `;

  const message = isPublic
    ? `Thank you for your request. We will get back to you as soon as possible.
  Request Type: ${requestType}
  ${upgradeString}
  ${
    cancelReason
      ? `Cancel Reason: ${cancelReason}
  ${shouldShowTrueUp ? cancelPublicTrueUpString : ''}`
      : ''
  }
${
  showTransferInfo
    ? `${trueUpString}
          Transfer Plan from ${patient.patientName} to ${transferPet?.transferPetName}
        ${last4String}`
    : ''
} 
  Description: ${inputFieldText}
  Requested by: ${clinicUser.name}, Email: ${clinicUser.email}, Id: ${clinicUser.id}
  Point of Contact: ${poc.name}, Email: ${poc.email}, Id: ${poc.id}
  `
    : requestType === ManageMembershipRequestType.Cancel
    ? `Contact clinic user ${poc.name} at ${poc.email}.

  ${dataIds}`
    : `${
        showTransferInfo
          ? `Transfer Plan from ${patient.patientName} to ${transferPet?.transferPetName}. Please create a clickup ticket with the care-adjustment tag in the Care team's current board. Copy and paste all the clinic-provided details from this Zendesk ticket. And include a link to this Zendesk ticket, so that Care developers can comment directly on the ZD ticket when this task is complete.`
          : ''
      }${upgradeString} ${
        requestType === ManageMembershipRequestType.Upgrade
          ? `Please make sure the upgrade fee amount of ${trueUpDollarString} is correct.`
          : ''
      }
  ${dataIds}`;

  return message;
};

export const validateNumber = (value: number): IValidationResponse => {
  if (value < 0) {
    return {
      valid: false,
      message: `You cannot input a negative value.`,
    };
  }

  // if the value has more than two decimals, return false
  if (value.toString().split('.')[1] && value.toString().split('.')[1].length > 2) {
    return {
      valid: false,
      message: `You cannot input more than two decimal places.`,
    };
  }
  //if value is not a number, return false
  if (isNaN(value)) {
    return {
      valid: false,
      message: `Please enter a valid number.`,
    };
  }
  // if value is an exponential number, return false
  if (value.toString().includes('e')) {
    return {
      valid: false,
      message: `Please enter a valid number.`,
    };
  }
  return {
    valid: true,
    message: ``,
  };
};

const transformManageMembershipRequestType = (requestType: ManageMembershipRequestType): string => {
  if (requestType === ManageMembershipRequestType.RemoveBenefit) {
    return 'Remove Benefit';
  }

  return requestType;
};

export const subjectLine = (manageMembershipRequestInfo: IManageMembershipRequestInfo): string => {
  //   Request Type, cancelation reason, clinic name, client name, patient name
  const testingText = !isProduction ? 'TESTING PLEASE DISREGARD ' : '';
  return `${testingText}${transformManageMembershipRequestType(manageMembershipRequestInfo.requestType)} - ${
    manageMembershipRequestInfo.cancelReason ? manageMembershipRequestInfo.cancelReason + '-' : ''
  } ${manageMembershipRequestInfo.clinic.clinicName} - ${manageMembershipRequestInfo.client.clientName} - ${
    manageMembershipRequestInfo.patient.patientName
  }`;
};

export const getPriority = (
  requestType: ManageMembershipRequestType,
  cancelReason?: CancelReasonType,
): IZendeskTicketPriority => {
  if (
    (requestType === ManageMembershipRequestType.Cancel && cancelReason === CancelReasonType.DeceasedPet) ||
    requestType === ManageMembershipRequestType.Upgrade
  ) {
    return IZendeskTicketPriority.Urgent;
  }
  return IZendeskTicketPriority.Normal;
};

const getSupportTypeCustomField = (requestType: ManageMembershipRequestType): { id: number; value: string } => {
  const id = 14093209679501;
  switch (requestType) {
    case ManageMembershipRequestType.Cancel:
      return { id, value: 'care_membership_cancellation' };
    case ManageMembershipRequestType.Upgrade:
      return { id, value: 'care_membership_upgrade' };
    case ManageMembershipRequestType.RemoveBenefit:
      return { id, value: 'care_membership_remove_benefit_consumption' };
    case ManageMembershipRequestType.Transfer:
      return { id, value: 'care_membership_transfer_membership' };
    case ManageMembershipRequestType.OptOut:
      return { id, value: 'care_membership_renewal_opt-out' };
    default:
      return { id, value: 'care_membership_other' };
  }
};

export const parseManageMembershipRequestInfoForZendesk = (
  manageMembershipRequestInfo: IManageMembershipRequestInfo,
  isPublic: boolean,
): ZendeskTicket => {
  const followers = isProduction
    ? [{ user_email: 'rilo@otto.vet' }]
    : [{ user_email: 'veronica@otto.vet' }, { user_email: 'kimberlie@otto.vet' }, { user_email: 'rilo@otto.vet' }];
  const subject = subjectLine(manageMembershipRequestInfo);
  const body = zendeskMessage(manageMembershipRequestInfo, isPublic);
  const ticket = {
    subject: subject,
    comment: {
      body: body,
      public: isPublic,
    },
    assignee_email: '',
    followers: followers,
    custom_fields: [getSupportTypeCustomField(manageMembershipRequestInfo.requestType)],
    type: IZendeskTicketType.Task,
    priority: getPriority(manageMembershipRequestInfo.requestType, manageMembershipRequestInfo?.cancelReason),
    tags: [],
    group_id: 360009683291,
    requester: {
      name: manageMembershipRequestInfo.pointOfContact.name,
      email: manageMembershipRequestInfo.pointOfContact.email,
    },
    email_ccs: [
      {
        user_email: manageMembershipRequestInfo.pointOfContact.email,
        user_name: manageMembershipRequestInfo.pointOfContact.name,
      },
      {
        user_email: manageMembershipRequestInfo.clinicUser.email,
        user_name: manageMembershipRequestInfo.clinicUser.name,
      },
    ],
    // group id 360009683291 for "name": "Support", other groups can be found at https://ottovet.zendesk.com/api/v2/groups.json
  };

  return ticket;
};

export const DESCRIPTION_MAX_CHAR_COUNT = 2000;

export const getMonthlyAmountDue = ({
  plan,
  balanceDue,
}: {
  plan?: CarePlanInfoFragment;
  balanceDue?: number;
}): { monthlyAmountDue: number; remainder: number; numberOfPayments: number; total: number } => {
  const planPrice = plan?.pricePerRenewal
    ? centsToDollars(plan?.pricePerRenewal) / getRenewalCycleInMonths(plan?.renewalCycle)
    : 1;
  const total = balanceDue || 0;
  const numberOfPayments = Math.floor(total / planPrice);
  const monthlyAmountDue = planPrice;

  return {
    monthlyAmountDue,
    remainder: total - monthlyAmountDue * numberOfPayments,
    numberOfPayments,
    total,
  };
};
