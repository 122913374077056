import { CheckedCheckbox, ExternalLinkIcon, CloseIcon } from 'assets/icons';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as Sentry from '@sentry/react';
import styled from 'styled-components/macro';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import TimePicker from 'shared/components/TimePicker';
import DataTable, { createTheme } from 'react-data-table-component';
import { useLazyQuery, useMutation } from '@apollo/client';
import { FINANCIAL_TRANSACTIONS, GET_CLINIC_FINANCIAL_REPORT_SETTINGS } from 'shared/queries';
import format from 'date-fns/format';
import {
  StripeDisputeStatus,
  StripePaymentIntent,
  StripePaymentIntentStatus,
  StripePayoutStatus as StripePayoutStatus,
  FinancialTransaction,
  PaymentPimsWritebackLogEntry,
  PimsWritebackStatus,
  FinancialTransactionType,
  StripeInvoiceStatus,
  FeeCollection,
  useFindManyStripePayoutLazyQuery,
} from 'shared/types/graphql';
import { useReactToPrint } from 'react-to-print';
import useClinicUser from 'shared/hooks/useClinicUser';
import { GraphQLFetchPolicies } from 'shared/enums/GraphQLFetchPolicies';
import { UPDATE_CLINIC_FINANCIAL_REPORT_SETTINGS } from 'shared/mutations';
import sortBy from 'lodash-es/sortBy';
import reverse from 'lodash-es/reverse';
import cloneDeep from 'lodash-es/cloneDeep';
import { MessageTypes, Modal, ModalSizes, useModal, useSnackbar } from '@televet/televet-ui';
import RefundModal from 'shared/components/RefundModal';
import { ModalNames } from 'shared/enums/ModalNames';
import { replaceParam, RouteDefinitions } from 'routes';
import { useSidePanelSearch } from 'shared/components/SidePanel/components/SearchPanel/state/providers/SearchPanelProvider';
import Tooltip from 'shared/components/Tooltip';
import useFeatureFlag from 'shared/hooks/useFeatureFlag';
import { FeatureFlagName } from 'shared/enums/FeatureFlagName';
import { getBalanceTerminology, getInvoiceIdentifierCopy } from 'shared/utils';
import { Mixpanel } from 'shared/utils/mixpanel';
import { formatMoney } from './utils/formatMoney';
import usePersistedState from 'shared/hooks/usePersistedState';
import DevModeDisplayJSON from 'pages/Settings/components/DevModeDisplayJSON';
import PaymentMoreButton from './components/PaymentMoreButton';
import { PersistedStateKeys } from 'shared/enums/PersistedStateKeys';
import {
  Checkbox,
  useOutsideClick,
  CModal,
  ModalOverlay,
  ModalContent,
  CModalHeader,
  CModalBody,
  ModalCloseButton,
  Button,
  Icon,
  Flex,
  Box,
  Heading,
} from '@televet/kibble-ui';
import LoadingSpinner from 'shared/components/LoadingSpinner';
import useGA from 'shared/hooks/useGA';
import StatusTag from './components/StatusTag';
import { useIntegrationsProvider } from 'shared/providers/IntegrationsProvider';
import WritebackStatus from './components/WritebackStatus';
import { CSVTableRow, IColumnSelection } from './types';
import {
  stripePaymentMethodLabels,
  writebackStatusDisplayValue,
  careClinics,
  AuxiliaryPimsWritebackStatus,
} from './constants';
import { getWritebackStatus, getPaymentMethodLabel } from './utils';
import {
  PaymentReportFilterTabs,
  TransactionTypesEnum,
  PaymentReportTotalTypes,
  PaymentReportColumnNames,
  PaymentReportColumnSelectors,
} from './enums';
import WritebackAlerts from './components/WritebackAlerts';
import usePaymentWritebackConfig from 'shared/hooks/usePaymentWritebackConfig';
import { GA4Events } from 'shared/enums/GA4Events';
import TooltipText from './components/TooltipText';
import TerminalFilterMenu from './components/TerminalFilterMenu';
import EODBanner from './components/EODBanner';
import { SemanticBackgroundColorToken } from '@televet/kibble-ui/build/theme/tokens';

const PaymentReport = (): JSX.Element => {
  const { currentClinicId, currentClinic, hasAdminRoleAtCurrentClinic, clinicUser } = useClinicUser();
  const { gaTrack } = useGA();
  const organizationId = currentClinic?.organizationId;
  const { addSnackbar } = useSnackbar();

  const { primaryIntegration } = useIntegrationsProvider();
  const {
    isComplete: isPaymentConfigComplete,
    isValid: isPaymentConfigValid,
    isLoading: isPaymentConfigLoading,
  } = usePaymentWritebackConfig();

  const isPaymentConfigInvalid = useMemo(() => {
    return (!isPaymentConfigComplete || !isPaymentConfigValid) && !isPaymentConfigLoading;
  }, [isPaymentConfigLoading, isPaymentConfigComplete, isPaymentConfigValid]);

  const { openModal, closeModal } = useModal();

  const { isFeatureEnabled, isFeatureFlagsLoading } = useFeatureFlag();

  const initialStartDate = new Date();

  const { viewClinicPetParent } = useSidePanelSearch();

  initialStartDate.setHours(0, 0, 0, 0);

  const initialEndDate = new Date();
  initialEndDate.setHours(23, 59, 59, 999);

  const [showSubscriptions, setShowSubscriptions] = usePersistedState(
    PersistedStateKeys.ShowSubscriptionsInPaymentReport,
    false,
  );

  const [excludedTerminals] = usePersistedState<string[]>(PersistedStateKeys.TerminalFilterExclusions, []);

  const clinicUserId = useMemo(() => {
    return clinicUser?.id;
  }, [clinicUser]);

  const showEOD = useMemo(() => {
    return isFeatureEnabled(FeatureFlagName.EODPayoutControls);
  }, [isFeatureEnabled]);

  const getWritebackStatusColor = useCallback(
    (
      writebackLogEntries: PaymentPimsWritebackLogEntry[],
      isIntegratedPayment: boolean,
    ): SemanticBackgroundColorToken => {
      const writebackStatus = getWritebackStatus(writebackLogEntries, isPaymentConfigInvalid, isIntegratedPayment);
      switch (writebackStatus) {
        case PimsWritebackStatus.Pending:
        case PimsWritebackStatus.PendingRetryAutomatic:
        case PimsWritebackStatus.PendingRetryManual:
          return 'background.warning';
        case PimsWritebackStatus.Success:
          return 'background.success';
        case PimsWritebackStatus.Failure:
        case AuxiliaryPimsWritebackStatus.Invalid:
          return 'background.dangerSubtle';
        default:
          return 'background.info';
      }
    },
    [isPaymentConfigInvalid],
  );

  const pimsName = primaryIntegration?.displayName || 'your PIMS';

  const getWritebackStatusDescription = useCallback(
    (writebackLogEntries: PaymentPimsWritebackLogEntry[], isIntegratedPayment: boolean): string => {
      const writebackStatus = getWritebackStatus(writebackLogEntries, isPaymentConfigInvalid, isIntegratedPayment);
      switch (writebackStatus) {
        case PimsWritebackStatus.Pending:
          return 'A writeback is in progress.';
        case PimsWritebackStatus.Failure:
          return `This writeback needs to be manually posted in ${pimsName}.`;
        case PimsWritebackStatus.PendingRetryAutomatic:
        case PimsWritebackStatus.PendingRetryManual:
          return 'You may need to refresh this page to see if the retry was successful.';
        case AuxiliaryPimsWritebackStatus.Disabled:
          return 'A writeback was not attempted for this payment.';
        case AuxiliaryPimsWritebackStatus.Invalid:
          return hasAdminRoleAtCurrentClinic
            ? 'Please fix an invalid configuration in the Payments Settings page to retry this writeback.'
            : 'Please contact an admin to fix an invalid configuration in the Payments Settings before retrying this writeback.';
        default:
          return '';
      }
    },
    [isPaymentConfigInvalid, pimsName, hasAdminRoleAtCurrentClinic],
  );

  const initialTransactionsColumnsSelection = [
    {
      name: PaymentReportColumnNames.ClientName,
      selector: PaymentReportColumnSelectors.ClientName,
      sortable: true,
      omit: false,
      width: '155px',
    },
    {
      name: PaymentReportColumnNames.Conversation,
      selector: PaymentReportColumnSelectors.Conversation,
      sortable: false,
      omit: false,
    },
    {
      name: PaymentReportColumnNames.TransactionType,
      selector: PaymentReportColumnSelectors.TransactionType,
      sortable: true,
      omit: false,
      width: '165px',
    },
    {
      name: PaymentReportColumnNames.PaymentStatus,
      selector: PaymentReportColumnSelectors.PaymentStatus,
      sortable: true,
      omit: false,
      width: '175px',
    },
    {
      name: PaymentReportColumnNames.Date,
      selector: PaymentReportColumnSelectors.Date,
      sortable: true,
      omit: false,
      width: '155px',
    },
    {
      name: PaymentReportColumnNames.Disbursed,
      selector: PaymentReportColumnSelectors.Disbursed,
      sortable: true,
      omit: false,
    },
    {
      name: PaymentReportColumnNames.Amount,
      selector: PaymentReportColumnSelectors.Amount,
      sortable: true,
      omit: false,
      right: true,
    },
    {
      name: PaymentReportColumnNames.ClientFee,
      selector: PaymentReportColumnSelectors.ClientFee,
      sortable: true,
      omit: false,
      width: '115px',
      right: true,
    },
    {
      name: PaymentReportColumnNames.TotalInvoiced,
      selector: PaymentReportColumnSelectors.TotalInvoiced,
      sortable: true,
      omit: false,
      right: true,
      width: '165px',
    },
    {
      name: PaymentReportColumnNames.TransactionFees,
      selector: PaymentReportColumnSelectors.TransactionFees,
      sortable: true,
      omit: false,
      width: '165px',
      right: true,
    },
    {
      name: PaymentReportColumnNames.NetProceeds,
      selector: PaymentReportColumnSelectors.NetProceeds,
      sortable: true,
      omit: false,
      width: '145px',
      right: true,
    },
    {
      name: PaymentReportColumnNames.SentBy,
      selector: PaymentReportColumnSelectors.SentBy,
      sortable: true,
      omit: false,
      width: '145px',
    },
    {
      name: PaymentReportColumnNames.PaymentMethod,
      selector: PaymentReportColumnSelectors.PaymentMethod,
      sortable: true,
      omit: false,
      width: '165px',
    },
    {
      name: PaymentReportColumnNames.Terminal,
      selector: PaymentReportColumnSelectors.Terminal,
      sortable: true,
      omit: false,
      width: '165px',
    },
    {
      name: PaymentReportColumnNames.CardType,
      selector: PaymentReportColumnSelectors.CardType,
      sortable: true,
      omit: false,
      width: '115px',
    },
    {
      name: PaymentReportColumnNames.Last4,
      selector: PaymentReportColumnSelectors.Last4,
      sortable: true,
      omit: false,
    },
    {
      name: PaymentReportColumnNames.Actions,
      selector: PaymentReportColumnSelectors.Actions,
      sortable: false,
      omit: false,
    },
  ];

  // TODO: [Rebranding] This will be updated in a future phase
  createTheme('otto', {
    striped: {
      default: '#f1f5f6',
    },
  });

  const { isClientIdSupported, primaryIntegrationName, isInvoiceDiscoverySupported } = useIntegrationsProvider();

  // Apollo
  const [getClinicSettings, { data: clinicSettingsData, error: clinicSettingsError, loading: clinicSettingsLoading }] =
    useLazyQuery(GET_CLINIC_FINANCIAL_REPORT_SETTINGS, {
      fetchPolicy: GraphQLFetchPolicies.NetworkOnly,
    });

  const [displayData, setDisplayData] = useState<FinancialTransaction[]>([]);

  const [getTransactions, { data: transactionsData, error: TransactionsError, loading: isLoadingTransactions }] =
    useLazyQuery<{
      findManyFinancialTransaction: FinancialTransaction[];
      findManyFinancialTransactionCount: number;
    }>(FINANCIAL_TRANSACTIONS, {
      fetchPolicy: GraphQLFetchPolicies.NetworkOnly,
      nextFetchPolicy: GraphQLFetchPolicies.CacheFirst,
      onCompleted: (data) => {
        setDisplayData(data?.findManyFinancialTransaction ?? []);
        if (!initialTransactionsLoaded) {
          setInitialTransactionsLoaded(true);
        }
      },
    });

  // updated report data when cache changes
  useEffect(() => {
    if (transactionsData?.findManyFinancialTransaction) {
      setDisplayData(transactionsData?.findManyFinancialTransaction || []);
    }
  }, [transactionsData?.findManyFinancialTransaction]);

  const showFailedWritebackAlert = useMemo(() => {
    return displayData.some(
      (transaction) =>
        getWritebackStatus(transaction.stripePaymentIntent?.writebackLogEntries || []) === PimsWritebackStatus.Failure,
    );
  }, [displayData]);

  const allTransactionsLoaded = useMemo(() => {
    if (TransactionsError || !transactionsData?.findManyFinancialTransactionCount) return true;

    return displayData.length === transactionsData?.findManyFinancialTransactionCount;
  }, [displayData.length, transactionsData?.findManyFinancialTransactionCount, TransactionsError]);

  const [getPayouts, { data: payoutsData, error: PayoutsError, loading: PayoutsLoading }] =
    useFindManyStripePayoutLazyQuery({
      fetchPolicy: GraphQLFetchPolicies.NetworkOnly,
    });

  const [updateFinancialReportSetting] = useMutation(UPDATE_CLINIC_FINANCIAL_REPORT_SETTINGS);

  // State

  const [linkedTransactions, setLinkedTransactions] = useState<{ payoutDate: string }>();

  const [refundReason, setRefundReason] = useState('');

  const [selectionRange, setSelectionRange] = useState({
    startDate: initialStartDate,
    endDate: initialEndDate,
    key: 'selection',
  });
  const [isPrinting, setIsPrinting] = useState(false);
  const [initialTransactionsLoaded, setInitialTransactionsLoaded] = useState(false);
  const [selectedFilterTab, setSelectedFilterTab] = useState(PaymentReportFilterTabs.All);
  const [isCalendarOnDisplay, setIsCalendarOnDisplay] = useState(false);
  const [isColumnFilterOnDisplay, setIsColumnFilterOnDisplay] = useState(false);
  const [refundPaymentIntent, setRefundPaymentIntent] = useState<StripePaymentIntent>();
  const [isSmallScreen] = useState(window.outerWidth <= 1575);
  const [nameFiltering, setNameFiltering] = useState('');
  const [cardFiltering, setCardFiltering] = useState('');
  const [excludedTerminalNames, setExcludedTerminalNames] = useState<string[]>(excludedTerminals);
  const [transactionsColumnsSelection, setTransactionsColumnsSelection] = useState<IColumnSelection[]>(
    initialTransactionsColumnsSelection,
  );

  const [disbursementsColumnsSelection, setDisbursementsColumnsSelection] = useState<IColumnSelection[]>([
    {
      name: PaymentReportColumnNames.Created,
      selector: PaymentReportColumnSelectors.Created,
      sortable: true,
      omit: false,
    },
    {
      name: PaymentReportColumnNames.ArrivalDate,
      selector: PaymentReportColumnSelectors.ArrivalDate,
      sortable: true,
      omit: false,
    },
    {
      name: PaymentReportColumnNames.Amount,
      selector: PaymentReportColumnSelectors.Amount,
      sortable: true,
      omit: false,
      width: '155px',
      right: true,
    },
    {
      name: PaymentReportColumnNames.PaymentStatus,
      selector: PaymentReportColumnSelectors.PaymentStatus,
      sortable: true,
      omit: false,
      width: '140px',
    },
    {
      name: PaymentReportColumnNames.LinkedTransactions,
      selector: PaymentReportColumnSelectors.LinkedTransactions,
      sortable: true,
      omit: false,
    },
  ]);

  useEffect(() => {
    if (showEOD) {
      setDisbursementsColumnsSelection((columns) => {
        if (!columns.some((column) => column.selector === PaymentReportColumnSelectors.EndOfDay)) {
          columns.splice(1, 0, {
            name: PaymentReportColumnNames.EndOfDay,
            selector: PaymentReportColumnSelectors.EndOfDay,
            sortable: true,
            omit: false,
          });
        }
        return columns;
      });
    }
  }, [showEOD]);

  // Set up available columns for special clinics
  useEffect(() => {
    if (currentClinicId) {
      setTransactionsColumnsSelection(initialTransactionsColumnsSelection);
    }
  }, [currentClinicId]); //eslint-disable-line

  useEffect(() => {
    if (isClientIdSupported && currentClinicId) {
      setTransactionsColumnsSelection((columns) => {
        columns.splice(1, 0, {
          name: 'CLIENT ID',
          selector: PaymentReportColumnSelectors.ClientId,
          sortable: true,
          omit: false,
        });
        return columns;
      });
    }
  }, [currentClinicId, isClientIdSupported]);

  useEffect(() => {
    if (isFeatureEnabled(FeatureFlagName.InvoiceDiscovery) && isInvoiceDiscoverySupported && !isFeatureFlagsLoading) {
      setTransactionsColumnsSelection((columns) => {
        if (
          !columns.some((column) => column.selector === PaymentReportColumnSelectors.WritebackStatus) &&
          isFeatureEnabled(FeatureFlagName.BitwerxPaymentWritebacks)
        ) {
          columns.splice(1, 0, {
            name: PaymentReportColumnNames.WritebackStatus,
            selector: PaymentReportColumnSelectors.WritebackStatus,
            width: '165px',
            sortable: true,
            omit: false,
          });
        }
        if (!columns.some((column) => column.selector === PaymentReportColumnSelectors.Invoices)) {
          columns.splice(1, 0, {
            name: PaymentReportColumnNames.Invoices,
            selector: PaymentReportColumnSelectors.Invoices,
            width: '145px',
            sortable: false,
            omit: false,
          });
        }
        return columns;
      });
    }
  }, [isFeatureEnabled, isInvoiceDiscoverySupported, isFeatureFlagsLoading]);

  // Set up Developer mode!
  const [isDeveloperModeEnabled] = usePersistedState(PersistedStateKeys.IsDeveloperModeEnabled, false);
  const [developerModeObject, setDeveloperModeObject] = useState<Record<string, unknown> | null>(null);

  // Helper Functions

  const balanceTerminologyShort = useMemo(() => {
    return getBalanceTerminology(primaryIntegrationName, true);
  }, [primaryIntegrationName]);

  const convertArrayOfObjectsToCSV = useCallback(
    (array: CSVTableRow[]): string => {
      let result: string;

      const columnDelimiter = ',';
      const lineDelimiter = '\n';
      const keys = Object.keys(array[0] || []);
      const columnNames = Object.keys(array[0] || []).map((key) => {
        if (selectedFilterTab === PaymentReportFilterTabs.Disbursements) {
          for (const column of disbursementsColumnsSelection) {
            if (key === column.selector) {
              return column.name;
            }
          }
        } else {
          for (const column of transactionsColumnsSelection) {
            if (key === column.selector) {
              return column.name;
            }
          }
        }
        return key;
      });

      result = '';
      result += columnNames.join(columnDelimiter);
      result += lineDelimiter;

      array.forEach((item: CSVTableRow) => {
        let ctr = 0;
        keys.forEach((key) => {
          if (ctr > 0) result += columnDelimiter;

          result += item[key as keyof CSVTableRow];

          ctr++;
        });
        result += lineDelimiter;
      });

      return result;
    },
    [disbursementsColumnsSelection, selectedFilterTab, transactionsColumnsSelection],
  );

  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  const downloadCSV = useCallback(
    (array: CSVTableRow[]) => {
      const link = document.createElement('a');

      let csv = convertArrayOfObjectsToCSV(array);
      if (csv == null) return;

      const filename = `Otto_Financial_Report_${format(new Date(), "MM-dd-yy'_'_h_mm_aaaaa'm'")}.csv`;

      if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv;charset=utf-8,${csv}`;
      }

      link.setAttribute('href', encodeURI(csv));
      link.setAttribute('download', filename);
      link.click();
    },
    [convertArrayOfObjectsToCSV],
  );

  // Effects

  useEffect(() => {
    if (clinicSettingsError) {
      Sentry.captureException(clinicSettingsError);
      addSnackbar({
        type: MessageTypes.Error,
        message: 'Oops, an error occurred while getting your column settings.',
      });
    }
    if (TransactionsError) {
      Sentry.captureException(TransactionsError);
      addSnackbar({
        type: MessageTypes.Error,
        message: 'Oops, an error occurred while getting your transactions.',
      });
    }
    if (PayoutsError) {
      Sentry.captureException(PayoutsError);
      addSnackbar({
        type: MessageTypes.Error,
        message: 'Oops, an error occurred while getting your disbursements.',
      });
    }
  }, [clinicSettingsError, TransactionsError, PayoutsError, addSnackbar]);

  const refetch = useCallback(
    (skip = 0, take = 100): void => {
      if (currentClinicId && !isLoadingTransactions) {
        getTransactions({
          variables: {
            where: {
              clinicId: { equals: currentClinicId },
              timestamp: { gte: selectionRange.startDate, lte: selectionRange.endDate },
            },
            skip,
            take,
          },
        });
      }
    },
    [currentClinicId, isLoadingTransactions, getTransactions, selectionRange.endDate, selectionRange.startDate],
  );

  useEffect(() => {
    if (currentClinicId) {
      getClinicSettings({
        variables: {
          id: currentClinicId,
        },
      });
      getTransactions({
        variables: {
          where: {
            clinicId: { equals: currentClinicId },
            timestamp: { gte: selectionRange.startDate, lte: selectionRange.endDate },
          },
          skip: 0,
          take: 100,
        },
      });
      getPayouts({
        variables: {
          where: {
            financialTransactions: { some: { clinicId: { equals: currentClinicId } } },
            initiatedAt: { gte: selectionRange.startDate, lte: selectionRange.endDate },
          },
        },
      });
    }
  }, [
    currentClinicId,
    getClinicSettings,
    getPayouts,
    getTransactions,
    selectionRange.endDate,
    selectionRange.startDate,
  ]);

  // load transactions in chunks to avoid overloading server
  useEffect(() => {
    const transactionsLoaded = transactionsData?.findManyFinancialTransaction.length;
    const totalTransactions = transactionsData?.findManyFinancialTransactionCount;

    // don't refetch if we already have all the data
    if (totalTransactions === displayData.length) return;

    if (transactionsLoaded && totalTransactions && transactionsLoaded < totalTransactions) {
      refetch(transactionsLoaded, 100);
    }
  }, [
    refetch,
    transactionsData?.findManyFinancialTransaction.length,
    transactionsData?.findManyFinancialTransactionCount,
    selectionRange.endDate,
    displayData.length,
  ]);

  // Use clinic columns in clinicSettings
  useEffect(() => {
    if (!isPrinting) {
      const financialReportingConfig = clinicSettingsData?.findUniqueClinic?.clinicSetting?.financialReportingConfig;

      if (financialReportingConfig) {
        setTransactionsColumnsSelection((prev) => {
          const newState = [...prev];
          for (const column of newState) {
            column.omit = financialReportingConfig.transactionColumns?.[column.selector]?.omit || false;
          }
          return newState;
        });
        setDisbursementsColumnsSelection((prev) => {
          const newState = [...prev];
          for (const column of newState) {
            column.omit = financialReportingConfig.disbursementColumns?.[column.selector]?.omit || false;
          }
          return newState;
        });
      }
    }
  }, [clinicSettingsData, isPrinting]);

  //remove 3rd party range selector
  useEffect(() => {
    document.getElementsByClassName('rdrInputRange')[1]?.remove();
  }, []);

  const filterDropRef = useRef<HTMLDivElement>(null);
  const calendarDropRef = useRef<HTMLDivElement>(null);
  const printableRef = useRef<HTMLDivElement>(null);

  useOutsideClick({
    ref: calendarDropRef,
    handler: () => setIsCalendarOnDisplay(false),
  });

  useOutsideClick({
    ref: filterDropRef,
    handler: () => setIsColumnFilterOnDisplay(false),
  });

  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
    onAfterPrint: () => {
      setIsPrinting(false);
    },
    copyStyles: true,
    documentTitle: `Otto_Financial_Report_${format(new Date(), "MM-dd-yy'_'_h_mm_aaaaa'm'")}`,
    pageStyle: () => {
      return `
        @page {
          size: ${2 * 297}mm ${2 * 210}mm
        }

        @media all {
          .pagebreak {
            display: none;
          }
       }

        @media print {
          .pagebreak {
            page-break-before: always;
          }
        }`;
    },
  });

  const doesItPassTabFiltering = useCallback(
    (
      transactionType: TransactionTypesEnum,
      status:
        | StripePaymentIntentStatus
        | 'Refund'
        | 'Adjustment'
        | StripeDisputeStatus
        | 'Disbursed'
        | StripeInvoiceStatus,
    ): boolean => {
      switch (selectedFilterTab) {
        case PaymentReportFilterTabs.Complete:
          if (transactionType === TransactionTypesEnum.Refund) {
            return true;
          }
          if (transactionType === TransactionTypesEnum.Intent && status === StripePaymentIntentStatus.Succeeded) {
            return true;
          }
          if (transactionType === TransactionTypesEnum.Dispute && status === StripeDisputeStatus.Lost) {
            return true;
          }
          if (transactionType === TransactionTypesEnum.Invoice && status === StripeInvoiceStatus.Paid) {
            return true;
          }
          if (transactionType === TransactionTypesEnum.Adjustment) {
            return true;
          }
          break;
        case PaymentReportFilterTabs.Incomplete:
          if (transactionType === TransactionTypesEnum.Intent && status !== StripePaymentIntentStatus.Succeeded) {
            return true;
          }
          if (transactionType === TransactionTypesEnum.Dispute && status !== StripeDisputeStatus.Lost) {
            return true;
          }
          break;
        case PaymentReportFilterTabs.All:
          // we only want to show paid subscriptions, so filter out all others
          if (transactionType === TransactionTypesEnum.Invoice && status !== StripeInvoiceStatus.Paid) {
            return false;
          }
          return true;
        case PaymentReportFilterTabs.Disbursements:
          if (transactionType === TransactionTypesEnum.Payout) {
            return true;
          }
          break;
      }
      return false;
    },
    [selectedFilterTab],
  );

  const getNetProceeds = (
    status: StripePaymentIntentStatus | StripeDisputeStatus | 'Refund',
    amount: number,
  ): string => {
    switch (status) {
      case StripePaymentIntentStatus.Succeeded:
        return formatMoney(amount);

      case StripeDisputeStatus.Lost:
        return formatMoney(amount * -1);

      case 'Refund':
        return formatMoney(amount * -1);

      default:
        return formatMoney(0);
    }
  };

  const getStatus = useCallback(
    (
      status: StripePaymentIntentStatus | StripeDisputeStatus | StripePayoutStatus | StripeInvoiceStatus | 'Refund',
      component = false, // false for CSV download, true for displayed report
      reason?: string,
    ): JSX.Element | string => {
      switch (status) {
        case StripePaymentIntentStatus.Canceled:
          return component ? <StatusTag label="Canceled" bg="background.dangerSubtle" /> : 'Canceled';
        case StripePaymentIntentStatus.Created:
          return component ? <StatusTag label="Invoiced" bg="background.warning" /> : 'Invoiced';
        case StripePaymentIntentStatus.Processing:
          return component ? <StatusTag label="Processing" bg="background.warning" /> : 'Processing';
        case StripePaymentIntentStatus.RequiresAction:
          return component ? <StatusTag label="Action Required" bg="background.dangerSubtle" /> : 'Action Required';
        case StripePaymentIntentStatus.RequiresCapture:
          return component ? <StatusTag label="Pre-Authorized" bg="background.warning" /> : 'Pre-Authorized';
        case StripePaymentIntentStatus.RequiresConfirmation:
          return component ? (
            <StatusTag label="Confirmation Required" bg="background.dangerSubtle" />
          ) : (
            'Confirmation Required'
          );
        case StripePaymentIntentStatus.RequiresPaymentMethod:
          return component ? <StatusTag label="Invoiced" bg="background.warning" /> : 'Invoiced';
        case StripePaymentIntentStatus.Succeeded:
          return component ? <StatusTag label="Complete" bg="background.success" /> : 'Complete';

        case StripeDisputeStatus.ChargeRefunded:
          return component ? <StatusTag label="Refunded" bg="background.dangerSubtle" /> : 'Refunded';
        case StripeDisputeStatus.Lost:
          return component ? <StatusTag label="Lost" bg="background.dangerSubtle" /> : 'Lost';
        case StripeDisputeStatus.NeedsResponse:
          return component ? <StatusTag label="Response Required" bg="background.dangerSubtle" /> : 'Response Required';
        case StripeDisputeStatus.UnderReview:
          return component ? <StatusTag label="Under Review" bg="background.warning" /> : 'Under Review';
        case StripeDisputeStatus.WarningClosed:
          return component ? <StatusTag label="Review" bg="background.warning" /> : 'Review';
        case StripeDisputeStatus.WarningUnderReview:
          return component ? <StatusTag label="Review" bg="background.warning" /> : 'Review';
        case StripeDisputeStatus.Won:
          return component ? <StatusTag label="Won" bg="background.success" /> : 'Won';

        case StripePayoutStatus.Canceled:
          return component ? <StatusTag label="Canceled" bg="background.dangerSubtle" /> : 'Canceled';
        case StripePayoutStatus.Failed:
          return component ? <StatusTag label="Failed" bg="background.dangerSubtle" /> : 'Failed';
        case StripePayoutStatus.InTransit:
          return component ? <StatusTag label="Pending" bg="background.warning" /> : 'Pending';
        case StripePayoutStatus.Paid:
          return component ? <StatusTag label="Disbursed" bg="background.success" /> : 'Disbursed';
        case StripePayoutStatus.Pending:
          return component ? <StatusTag label="Pending" bg="background.warning" /> : 'Pending';

        case StripeInvoiceStatus.Draft:
          return component ? <StatusTag label="Draft" bg="background.warning" /> : 'Draft';
        case StripeInvoiceStatus.Open:
          return component ? <StatusTag label="Open" bg="background.warning" /> : 'Open';
        case StripeInvoiceStatus.Paid:
          return component ? <StatusTag label="Paid" bg="background.success" /> : 'Paid';
        case StripeInvoiceStatus.Uncollectible:
          return component ? <StatusTag label="Uncollectible" bg="background.dangerSubtle" /> : 'Uncollectible';
        case StripeInvoiceStatus.Void:
          return component ? <StatusTag label="Void" bg="background.dangerSubtle" /> : 'Void';

        case 'Refund':
          return component ? (
            <StatusTag
              label="Refund"
              bg="background.dangerSubtle"
              onClick={(): void => {
                if (reason) {
                  setRefundReason(reason.replaceAll('_', ' '));
                  openModal(ModalNames.RefundReason);
                }
              }}
            />
          ) : (
            'Refund'
          );

        default:
          return component ? <>--</> : '';
      }
    },
    [openModal],
  );

  const onPetParentClicked = useCallback(
    async (e: React.MouseEvent<HTMLDivElement>, id: string): Promise<void> => {
      e.stopPropagation();
      viewClinicPetParent({ clinicPetParentId: id });
    },
    [viewClinicPetParent],
  );
  // eslint-disable-next-line
  const NaturalSortByKey = (key: string): any => {
    // eslint-disable-next-line
    return (o: any): any => {
      const v = parseInt(o[key], 10);
      return isNaN(v) ? o[key] : v;
    };
  };
  const tableData = useMemo(() => {
    const newTableData = [];
    const emptyCell = '--';
    const hasPostPaidFeeCollection =
      clinicSettingsData?.findUniqueClinic?.clinicSetting?.paymentFeeConfig?.feeCollection === FeeCollection.PostPaid;

    if (displayData && selectedFilterTab !== PaymentReportFilterTabs.Disbursements) {
      for (const data of displayData) {
        const intent = data?.stripePaymentIntent;
        const payout = data?.stripePayout;
        const refund = data?.stripeRefund;
        const dispute = data?.stripeDispute;
        const invoice = data?.stripeInvoice;
        const adjustment = data?.adjustment;
        const isSubscription = data?.type === FinancialTransactionType.Subscription;

        if (intent && doesItPassTabFiltering(TransactionTypesEnum.Intent, intent.status)) {
          const invoices = sortBy(intent.invoices, NaturalSortByKey('identifier'));

          const paymentMethodLabel = getPaymentMethodLabel(intent);

          const isIntegratedPayment = !!intent.paymentRelationship || !!invoices.length;

          const isPaymentSuccessful = intent.status === StripePaymentIntentStatus.Succeeded;

          const writebackStatus = getWritebackStatus(
            intent.writebackLogEntries,
            isPaymentConfigInvalid,
            isIntegratedPayment,
          );

          const writebackStatusDisplay = writebackStatusDisplayValue[writebackStatus];

          let invoiceNumbers = '';
          if (intent.paymentRelationship) {
            invoiceNumbers = balanceTerminologyShort;
          }
          invoices.forEach((invoice) => {
            const identifier = getInvoiceIdentifierCopy(invoice.identifier);
            invoiceNumbers = invoiceNumbers ? `${invoiceNumbers}, ${identifier}` : identifier;
          });

          const clinicPetParent = intent.clinicPetParent;
          newTableData.push({
            developerMode: (
              <DeveloperLink
                onClick={(): void => {
                  setDeveloperModeObject(data);
                }}
              >
                {data.id}
              </DeveloperLink>
            ),
            actions:
              intent.status !== StripePaymentIntentStatus.Canceled ? (
                <PaymentMoreButton
                  intent={intent}
                  invoiceId={intent.invoices?.[0]?.id}
                  clinicPetParentId={clinicPetParent?.id}
                  petParentEmail={clinicPetParent?.email}
                  setRefundPaymentIntent={setRefundPaymentIntent}
                />
              ) : (
                emptyCell
              ),
            clinicPetParentNameFiltering: `${clinicPetParent?.firstName || ''} ${
              clinicPetParent?.lastName || ''
            }`.trim(),
            transactionType: <TooltipText text="Payment" />,
            clinicPetParentName: clinicPetParent?.id ? (
              <Tooltip content={`${clinicPetParent?.firstName || ''} ${clinicPetParent?.lastName || ''}`.trim()}>
                <StyledLink
                  className="PaymentReport_PetParentLink"
                  onClick={(e): void => {
                    // pet parent click
                    if (clinicPetParent?.id) {
                      onPetParentClicked(e, clinicPetParent.id);
                    }
                  }}
                >
                  {`${clinicPetParent?.firstName || ''} ${clinicPetParent?.lastName || ''}`.trim()}
                </StyledLink>
              </Tooltip>
            ) : (
              emptyCell
            ),
            clinicPetParentPimsId: clinicPetParent?.pimsId || emptyCell,
            conversation: data.channel?.id ? (
              <StyledLink
                onClick={(): void => {
                  if (data?.channel?.id) {
                    Object.assign(document.createElement('a'), {
                      target: '_blank',
                      href: replaceParam(RouteDefinitions.Conversations, ':channelId', data.channel.id),
                    }).click();
                  }
                }}
              >
                View <StyledExternalLinkIcon />
              </StyledLink>
            ) : (
              emptyCell
            ),
            paymentStatus: getStatus(intent?.status, true) || emptyCell,
            date: data?.timestamp ? format(new Date(data?.timestamp), "MM/dd/yy '·' h:mm aaaaa'm'") : emptyCell,
            disbursed: payout?.arrivalDate
              ? format(new Date(payout?.arrivalDate?.replace(/Z$/, '')), 'MM/dd/yy')
              : emptyCell,
            amount: formatMoney(intent?.amount),
            totalInvoiced: formatMoney(intent?.amount + (intent?.clientServiceFee || 0)),
            netProceeds: getNetProceeds(
              intent?.status,
              hasPostPaidFeeCollection
                ? intent?.amount + (intent?.clientServiceFee || 0)
                : intent?.amount - intent?.serviceFeeAmount || 0,
            ),
            sentBy: intent?.sentBy?.firstName ? (
              <Tooltip
                content={intent?.sentBy?.nameDisplay || `${intent?.sentBy?.firstName} ${intent?.sentBy?.lastName}`}
              >
                <div>{intent?.sentBy?.nameDisplay || `${intent?.sentBy?.firstName} ${intent?.sentBy?.lastName}`}</div>
              </Tooltip>
            ) : (
              emptyCell
            ),
            cardBrand: intent.stripePaymentMethod?.cardBrand || emptyCell,
            last4: intent.stripePaymentMethod?.last4 || emptyCell,
            transactionFees:
              formatMoney(
                -1 * (hasPostPaidFeeCollection ? 0 : intent.serviceFeeAmount + (intent.clientServiceFee || 0) || 0),
              ) || emptyCell,
            clientTransactionFee: formatMoney(intent.clientServiceFee || 0),
            paymentMethod: <TooltipText tooltipText={intent.stripeTerminal?.name} text={paymentMethodLabel} />,
            terminal: intent.stripeTerminal?.name || emptyCell,
            invoices: invoiceNumbers,
            writebackStatus: isPaymentSuccessful ? (
              <WritebackStatus
                statusDisplay={writebackStatusDisplay}
                paymentIntentId={intent.id}
                transactionId={data.id}
                tagColor={getWritebackStatusColor(intent.writebackLogEntries, isIntegratedPayment)}
                popoverDescription={getWritebackStatusDescription(intent.writebackLogEntries, isIntegratedPayment)}
              />
            ) : (
              emptyCell
            ),
          });
        }
        if (refund && doesItPassTabFiltering(TransactionTypesEnum.Refund, 'Refund')) {
          const clinicPetParent = refund?.stripePaymentIntent?.clinicPetParent;
          const intent = refund.stripePaymentIntent;

          const paymentMethodLabel = getPaymentMethodLabel(intent);

          newTableData.push({
            developerMode: (
              <DeveloperLink
                onClick={(): void => {
                  setDeveloperModeObject(data);
                }}
              >
                {data.id}
              </DeveloperLink>
            ),
            actions: emptyCell,
            refund: emptyCell,
            clinicPetParentNameFiltering: `${clinicPetParent?.firstName || ''} ${
              clinicPetParent?.lastName || ''
            }`.trim(),
            transactionType: (
              <TooltipText
                text={refund.amount < (refund?.stripePaymentIntent?.amount || 0) ? 'Partial Refund' : 'Refund'}
              />
            ),
            clinicPetParentName: clinicPetParent?.id ? (
              <Tooltip content={`${clinicPetParent?.firstName || ''} ${clinicPetParent?.lastName || ''}`.trim()}>
                <StyledLink
                  onClick={(e): void => {
                    // pet parent click
                    if (clinicPetParent?.id) {
                      onPetParentClicked(e, clinicPetParent.id);
                    }
                  }}
                >
                  {`${clinicPetParent?.firstName || ''} ${clinicPetParent?.lastName || ''}`.trim()}
                </StyledLink>
              </Tooltip>
            ) : (
              emptyCell
            ),
            clinicPetParentPimsId: clinicPetParent?.pimsId || emptyCell,
            conversation: data.channel?.id ? (
              <StyledLink
                onClick={(): void => {
                  if (data?.channel?.id) {
                    Object.assign(document.createElement('a'), {
                      target: '_blank',
                      href: replaceParam(RouteDefinitions.Conversations, ':channelId', data.channel.id),
                    }).click();
                  }
                }}
              >
                View <StyledExternalLinkIcon />
              </StyledLink>
            ) : (
              emptyCell
            ),
            paymentStatus:
              getStatus('Refund', true, `${refund.reason}${refund.reasonOther ? ', ' + refund.reasonOther : ''}`) ||
              emptyCell,
            totalInvoiced: emptyCell,
            date: data?.timestamp ? format(new Date(data?.timestamp), "MM/dd/yy '·' h:mm aaaaa'm'") : emptyCell,
            transactionFees: formatMoney((refund.clientFeeRefunded || 0) + (refund.clinicFeeRefunded || 0)),
            clientTransactionFee: formatMoney((refund.clientFeeRefunded || 0) * -1),
            disbursed: payout?.arrivalDate
              ? format(new Date(payout?.arrivalDate?.replace(/Z$/, '')), 'MM/dd/yy')
              : emptyCell,
            amount: formatMoney(refund?.amount * -1),
            netProceeds: getNetProceeds('Refund', refund?.amount - (refund?.clinicFeeRefunded || 0)),
            sentBy: refund.issuedBy?.firstName ? (
              <Tooltip
                content={refund.issuedBy?.nameDisplay || `${refund.issuedBy?.firstName} ${refund.issuedBy?.lastName}`}
              >
                <div>
                  {refund.issuedBy?.nameDisplay || `${refund.issuedBy?.firstName} ${refund.issuedBy?.lastName}`}
                </div>
              </Tooltip>
            ) : (
              emptyCell
            ),
            paymentMethod: (
              <TooltipText tooltipText={refund?.stripePaymentIntent?.stripeTerminal?.name} text={paymentMethodLabel} />
            ),
            terminal: refund?.stripePaymentIntent?.stripeTerminal?.name || emptyCell,
            cardBrand: refund.stripePaymentIntent?.stripePaymentMethod?.cardBrand || emptyCell,
            last4: refund.stripePaymentIntent?.stripePaymentMethod?.last4 || emptyCell,
          });
        }
        if (dispute && doesItPassTabFiltering(TransactionTypesEnum.Dispute, dispute.status)) {
          const clinicPetParent = dispute?.stripePaymentIntent?.clinicPetParent;
          const intent = dispute?.stripePaymentIntent;

          const paymentMethodLabel = getPaymentMethodLabel(intent);
          const amount = dispute.status === StripeDisputeStatus.Lost ? dispute.amount * -1 : 0;
          const disputeFees = ((dispute.disputeFee || 0) - (dispute.processingFeesRefunded || 0)) * -1;

          newTableData.push({
            developerMode: (
              <DeveloperLink
                onClick={(): void => {
                  setDeveloperModeObject(data);
                }}
              >
                {data.id}
              </DeveloperLink>
            ),
            actions: emptyCell,
            clinicPetParentNameFiltering: `${clinicPetParent?.firstName || ''} ${
              clinicPetParent?.lastName || ''
            }`.trim(),
            transactionType: <TooltipText text="Dispute" />,
            clinicPetParentName: clinicPetParent?.id ? (
              <Tooltip content={`${clinicPetParent?.firstName || ''} ${clinicPetParent?.lastName || ''}`.trim()}>
                <StyledLink
                  onClick={(e): void => {
                    // pet parent click
                    if (clinicPetParent?.id) {
                      onPetParentClicked(e, clinicPetParent.id);
                    }
                  }}
                >
                  {`${clinicPetParent?.firstName || ''} ${clinicPetParent?.lastName || ''}`.trim()}
                </StyledLink>
              </Tooltip>
            ) : (
              emptyCell
            ),
            clinicPetParentPimsId: clinicPetParent?.pimsId || emptyCell,
            conversation: data.channel?.id ? (
              <StyledLink
                onClick={(): void => {
                  if (data?.channel?.id) {
                    Object.assign(document.createElement('a'), {
                      target: '_blank',
                      href: replaceParam(RouteDefinitions.Conversations, ':channelId', data.channel.id),
                    }).click();
                  }
                }}
              >
                View <StyledExternalLinkIcon />
              </StyledLink>
            ) : (
              emptyCell
            ),
            paymentStatus: getStatus(dispute.status, true),
            date: data?.timestamp ? format(new Date(data?.timestamp), "MM/dd/yy '·' h:mm aaaaa'm'") : emptyCell,
            disbursed: payout?.arrivalDate
              ? format(new Date(payout?.arrivalDate?.replace(/Z$/, '')), 'MM/dd/yy')
              : emptyCell,
            amount: formatMoney(amount),
            transactionFees: formatMoney(dispute.status === StripeDisputeStatus.Lost ? disputeFees : 0),
            netProceeds: formatMoney(amount + (dispute.status === StripeDisputeStatus.Lost ? disputeFees : 0)),
            paymentMethod: (
              <TooltipText tooltipText={refund?.stripePaymentIntent?.stripeTerminal?.name} text={paymentMethodLabel} />
            ),
            terminal: refund?.stripePaymentIntent?.stripeTerminal?.name || emptyCell,
          });
        }
        if (
          invoice &&
          isSubscription &&
          showSubscriptions &&
          doesItPassTabFiltering(TransactionTypesEnum.Invoice, invoice.status)
        ) {
          // no stripePaymentIntent associated, so can't use paymentMedium
          let paymentMethodLabel = 'Pending';
          if (invoice.stripePaymentMethod) {
            paymentMethodLabel = stripePaymentMethodLabels[invoice.stripePaymentMethod.type];
          }
          const transactionFee = -1 * invoice.serviceFeeAmount;
          const organizationPetParent = invoice.stripeCustomer?.organizationPetParents?.[0];
          const clinicPetParent = organizationPetParent?.clinicPetParents?.find(
            (clinicPetParent) => clinicPetParent.clinicId === currentClinicId,
          );
          newTableData.push({
            developerMode: (
              <DeveloperLink
                onClick={(): void => {
                  setDeveloperModeObject(data);
                }}
              >
                {data.id}
              </DeveloperLink>
            ),
            actions: emptyCell,
            clinicPetParentNameFiltering: `${clinicPetParent?.firstName || ''} ${
              clinicPetParent?.lastName || ''
            }`.trim(),
            transactionType: <TooltipText text="Subscription" />,
            clinicPetParentName: clinicPetParent?.id ? (
              <Tooltip content={`${clinicPetParent?.firstName || ''} ${clinicPetParent?.lastName || ''}`.trim()}>
                <StyledLink
                  onClick={(e): void => {
                    // pet parent click
                    if (clinicPetParent?.id) {
                      onPetParentClicked(e, clinicPetParent.id);
                    }
                  }}
                >
                  {`${clinicPetParent?.firstName || ''} ${clinicPetParent?.lastName || ''}`.trim()}
                </StyledLink>
              </Tooltip>
            ) : (
              emptyCell
            ),
            clinicPetParentPimsId: clinicPetParent?.pimsId || emptyCell,
            conversation: data.channel?.id ? (
              <StyledLink
                onClick={(): void => {
                  if (data?.channel?.id) {
                    Object.assign(document.createElement('a'), {
                      target: '_blank',
                      href: replaceParam(RouteDefinitions.Conversations, ':channelId', data.channel.id),
                    }).click();
                  }
                }}
              >
                View <StyledExternalLinkIcon />
              </StyledLink>
            ) : (
              emptyCell
            ),
            paymentStatus: getStatus(invoice.status, true) || emptyCell,
            date: data?.timestamp ? format(new Date(data?.timestamp), "MM/dd/yy '·' h:mm aaaaa'm'") : emptyCell,
            disbursed: payout?.arrivalDate
              ? format(new Date(payout?.arrivalDate?.replace(/Z$/, '')), 'MM/dd/yy')
              : emptyCell,
            amount: formatMoney(invoice.amount),
            totalInvoiced: emptyCell,
            clientTransactionFee: emptyCell,
            transactionFees: formatMoney(transactionFee) || emptyCell,
            netProceeds: formatMoney(invoice.amount + transactionFee),
            cardBrand: invoice.stripePaymentMethod?.cardBrand || emptyCell,
            last4: invoice.stripePaymentMethod?.last4 || emptyCell,
            paymentMethod:
              paymentMethodLabel !== 'Pending' ? (
                <TooltipText
                  tooltipText={invoice.financialTransaction?.stripePaymentIntent?.stripeTerminal?.name}
                  text={paymentMethodLabel}
                />
              ) : (
                emptyCell
              ),
            terminal: invoice.financialTransaction?.stripePaymentIntent?.stripeTerminal?.name || emptyCell,
            sentBy: emptyCell,
          });
        }
        if (adjustment && doesItPassTabFiltering(TransactionTypesEnum.Adjustment, 'Adjustment')) {
          const amount = adjustment.amount;

          newTableData.push({
            developerMode: (
              <DeveloperLink
                onClick={(): void => {
                  setDeveloperModeObject(data);
                }}
              >
                {data.id}
              </DeveloperLink>
            ),
            actions: emptyCell,
            clinicPetParentNameFiltering: emptyCell,
            transactionType: <TooltipText text="Adjustment" tooltipText={adjustment.description} />,
            clinicPetParentName: emptyCell,
            conversation: emptyCell,
            paymentStatus: emptyCell,
            date: data?.timestamp ? format(new Date(data?.timestamp), "MM/dd/yy '·' h:mm aaaaa'm'") : emptyCell,
            disbursed: payout?.arrivalDate
              ? format(new Date(payout?.arrivalDate?.replace(/Z$/, '')), 'MM/dd/yy')
              : emptyCell,
            amount: formatMoney(amount),
            transactionFees: formatMoney(0),
            netProceeds: formatMoney(amount),
            sentBy: emptyCell,
            paymentMethod: emptyCell,
            terminal: emptyCell,
          });
        }
      }
    }
    if (payoutsData && selectedFilterTab === PaymentReportFilterTabs.Disbursements) {
      for (const data of payoutsData?.findManyStripePayout || []) {
        newTableData.push({
          createdAt: format(new Date(data.initiatedAt), "MM/dd/yy '·' h:mm aaaaa'm'") || emptyCell,
          endOfDay: data?.payoutBatchingPeriod?.endsAt
            ? format(new Date(data?.payoutBatchingPeriod?.endsAt), "MM/dd/yy '·' h:mm aaaaa'm'")
            : emptyCell,
          arrivalDate: format(new Date(data.arrivalDate.replace(/Z$/, '')), 'MM/dd/yy') || emptyCell,
          amount: formatMoney(data.amount),
          netProceeds: formatMoney(data.amount),
          paymentStatus: getStatus(data.status, true) || emptyCell,
          linkedTransactions: (
            <StyledLink
              onClick={(): void => {
                if (isFeatureEnabled(FeatureFlagName.CarePlans)) {
                  setShowSubscriptions(true);
                }
                setSelectedFilterTab(PaymentReportFilterTabs.All);
                setLinkedTransactions({ payoutDate: format(new Date(data.arrivalDate), 'MM/dd/yy') });
                getTransactions({
                  variables: {
                    where: {
                      id: { in: data.financialTransactions?.map((transaction) => transaction.id) },
                    },
                  },
                });
              }}
            >
              View
            </StyledLink>
          ),
        });
      }
    }

    let filteredNewTableData = newTableData;

    // Add filter by name functionality
    if (nameFiltering) {
      filteredNewTableData = newTableData.filter((row) => {
        if (row?.clinicPetParentNameFiltering) {
          return row.clinicPetParentNameFiltering.toLowerCase().includes(nameFiltering.toLowerCase());
        }
        return false;
      });
    }

    // Add filter by card functionality
    if (cardFiltering) {
      filteredNewTableData = filteredNewTableData.filter((row) => {
        if (row?.cardBrand) {
          return row.cardBrand.toLowerCase().includes(cardFiltering.toLowerCase());
        }
        return false;
      });
    }

    // Add filter by terminal name functionality
    if (excludedTerminalNames?.length) {
      filteredNewTableData = filteredNewTableData.filter((row) => {
        if (!row.terminal || row.terminal === emptyCell) return false;

        return !excludedTerminalNames.includes(row.terminal);
      });
    }

    return filteredNewTableData;
  }, [
    balanceTerminologyShort,
    cardFiltering,
    currentClinicId,
    doesItPassTabFiltering,
    getStatus,
    getTransactions,
    nameFiltering,
    onPetParentClicked,
    payoutsData,
    selectedFilterTab,
    showSubscriptions,
    setShowSubscriptions,
    displayData,
    isFeatureEnabled,
    getWritebackStatusColor,
    getWritebackStatusDescription,
    isPaymentConfigInvalid,
    excludedTerminalNames,
    clinicSettingsData,
  ]);

  const CSVTableData = useMemo(() => {
    const CSVNewTableData: CSVTableRow[] = [];

    const transactionColumnsToRemove = transactionsColumnsSelection
      .filter((col) => col.omit)
      .map((col) => col.selector) as (keyof CSVTableRow)[];

    const disbursementColumnsToRemove = disbursementsColumnsSelection
      .filter((col) => col.omit)
      .map((col) => col.selector) as (keyof CSVTableRow)[];

    if (transactionsData && selectedFilterTab !== PaymentReportFilterTabs.Disbursements) {
      for (const data of transactionsData.findManyFinancialTransaction) {
        const intent = data?.stripePaymentIntent;
        const payout = data?.stripePayout;
        const refund = data?.stripeRefund;
        const dispute = data?.stripeDispute;
        const invoice = data?.stripeInvoice;
        const adjustment = data?.adjustment;
        const isSubscription = data?.type === FinancialTransactionType.Subscription;

        if (intent && doesItPassTabFiltering(TransactionTypesEnum.Intent, intent.status)) {
          const invoices = sortBy(intent.invoices, NaturalSortByKey('identifier'));
          let invoiceText = '';
          if (!!intent.paymentRelationship) {
            invoiceText = balanceTerminologyShort;
          }
          if (invoices.length) {
            invoices.forEach((invoice) => {
              invoiceText += `${invoiceText.length ? '; ' : ''}${invoice.identifier}`;
            });
          }

          const isIntegratedPayment = !!intent.paymentRelationship || !!invoices.length;

          const isPaymentSuccessful = intent.status === StripePaymentIntentStatus.Succeeded;

          const writebackStatus = getWritebackStatus(
            intent.writebackLogEntries,
            isPaymentConfigInvalid,
            isIntegratedPayment,
          );

          const writebackStatusDisplay = writebackStatusDisplayValue[writebackStatus];

          let paymentMethodLabel = getPaymentMethodLabel(intent);

          if (paymentMethodLabel === 'Terminal') {
            paymentMethodLabel = `Terminal (${intent?.stripeTerminal?.name || ''})`;
          }

          const clinicPetParent = intent?.clinicPetParent;
          const tableRow: CSVTableRow = {
            clinicPetParentName: `${clinicPetParent?.firstName || ''} ${clinicPetParent?.lastName || ''}`.trim() || ' ',
            clinicPetParentPimsId: clinicPetParent?.pimsId || ' ',
            invoices: invoiceText,
            writebackStatus: isPaymentSuccessful ? writebackStatusDisplay : '',
            transactionType: 'Payment',
            paymentStatus: (getStatus(intent?.status) as string) || ' ',
            date: data?.timestamp ? format(new Date(data?.timestamp), "MM/dd/yy '·' h:mm aaaaa'm'") : ' ',
            disbursed: payout?.arrivalDate ? format(new Date(payout?.arrivalDate?.replace(/Z$/, '')), 'MM/dd/yy') : ' ',
            amount: intent?.amount / 100,
            clientTransactionFee: intent?.clientServiceFee ? intent.clientServiceFee / 100 : 0,
            totalInvoiced: (intent?.amount + (intent?.clientServiceFee || 0)) / 100,
            transactionFees: (-1 * (intent.serviceFeeAmount + (intent.clientServiceFee || 0) || 0)) / 100,
            netProceeds: parseFloat(
              getNetProceeds(intent?.status, intent?.amount - intent?.serviceFeeAmount || 0).replace(/[$,]/g, '') ||
                '0',
            ),
            sentBy: intent?.sentBy?.firstName
              ? intent?.sentBy?.nameDisplay || `${intent?.sentBy?.firstName} ${intent?.sentBy?.lastName}`
              : '',
            paymentMethod: paymentMethodLabel,
            terminal: intent?.stripeTerminal?.name || '',
            cardBrand: intent.stripePaymentMethod?.cardBrand || ' ',
            last4: intent.stripePaymentMethod?.last4 || ' ',
          };

          if (!isFeatureEnabled(FeatureFlagName.InvoiceDiscovery)) {
            delete tableRow.invoices;
          }

          if (!isFeatureEnabled(FeatureFlagName.BitwerxPaymentWritebacks)) {
            delete tableRow.writebackStatus;
          }

          transactionColumnsToRemove.forEach((columnName) => {
            delete tableRow[columnName];
          });

          CSVNewTableData.push(tableRow);
        }
        if (refund && doesItPassTabFiltering(TransactionTypesEnum.Refund, 'Refund')) {
          const intent = refund?.stripePaymentIntent;
          const clinicPetParent = intent?.clinicPetParent;

          const paymentMethodLabel = getPaymentMethodLabel(intent);

          const tableRow: CSVTableRow = {
            clinicPetParentName: `${clinicPetParent?.firstName || ''} ${clinicPetParent?.lastName || ''}`.trim() || ' ',
            clinicPetParentPimsId: clinicPetParent?.pimsId || ' ',
            invoices: ' ',
            writebackStatus: ' ',
            transactionType: refund.amount < (refund.stripePaymentIntent?.amount || 0) ? 'Partial Refund' : 'Refund',
            paymentStatus: (getStatus('Refund') as string) || ' ',
            date: data?.timestamp ? format(new Date(data?.timestamp), "MM/dd/yy '·' h:mm aaaaa'm'") : ' ',
            disbursed: payout?.arrivalDate ? format(new Date(payout?.arrivalDate?.replace(/Z$/, '')), 'MM/dd/yy') : ' ',
            amount: (refund?.amount * -1) / 100,
            clientTransactionFee: ((refund.clientFeeRefunded || 0) * -1) / 100,
            totalInvoiced: ' ',
            transactionFees: ((refund.clientFeeRefunded || 0) + (refund.clinicFeeRefunded || 0)) / 100,
            netProceeds: parseFloat(
              getNetProceeds('Refund', refund?.amount - (refund?.clinicFeeRefunded || 0)).replace(/[$,]/g, '') || '0',
            ),
            sentBy: refund.issuedBy?.firstName
              ? refund.issuedBy?.nameDisplay || `${refund.issuedBy?.firstName} ${refund.issuedBy?.lastName}`
              : '',
            paymentMethod: paymentMethodLabel,
            cardBrand: refund.stripePaymentIntent?.stripePaymentMethod?.cardBrand || ' ',
            last4: refund.stripePaymentIntent?.stripePaymentMethod?.last4 || ' ',
          };

          if (!isFeatureEnabled(FeatureFlagName.InvoiceDiscovery)) {
            delete tableRow.invoices;
          }

          if (!isFeatureEnabled(FeatureFlagName.BitwerxPaymentWritebacks)) {
            delete tableRow.writebackStatus;
          }

          transactionColumnsToRemove.forEach((columnName) => {
            delete tableRow[columnName];
          });

          CSVNewTableData.push(tableRow);
        }
        if (dispute && doesItPassTabFiltering(TransactionTypesEnum.Dispute, dispute.status)) {
          const intent = dispute?.stripePaymentIntent;
          const clinicPetParent = intent?.clinicPetParent;

          const paymentMethodLabel = getPaymentMethodLabel(intent);
          const amount = dispute.status === StripeDisputeStatus.Lost ? (dispute.amount * -1) / 100 : 0;
          const disputeFees = (((dispute.disputeFee || 0) - (dispute.processingFeesRefunded || 0)) * -1) / 100;

          const tableRow: CSVTableRow = {
            transactionType: 'Dispute',
            clinicPetParentName: `${clinicPetParent?.firstName || ''} ${clinicPetParent?.lastName || ''}`.trim() || ' ',
            clinicPetParentPimsId: clinicPetParent?.pimsId || ' ',
            invoices: ' ',
            writebackStatus: ' ',
            paymentStatus: getStatus(dispute.status) as string,
            date: data?.timestamp ? format(new Date(data?.timestamp), "MM/dd/yy '·' h:mm aaaaa'm'") : ' ',
            disbursed: payout?.arrivalDate ? format(new Date(payout?.arrivalDate?.replace(/Z$/, '')), 'MM/dd/yy') : ' ',
            amount: amount,
            transactionFees: dispute.status === StripeDisputeStatus.Lost ? disputeFees : 0,
            netProceeds: amount + (dispute.status === StripeDisputeStatus.Lost ? disputeFees : 0),
            paymentMethod: paymentMethodLabel,
          };

          if (!isFeatureEnabled(FeatureFlagName.InvoiceDiscovery)) {
            delete tableRow.invoices;
          }

          if (!isFeatureEnabled(FeatureFlagName.BitwerxPaymentWritebacks)) {
            delete tableRow.writebackStatus;
          }

          transactionColumnsToRemove.forEach((columnName) => {
            delete tableRow[columnName];
          });

          CSVNewTableData.push(tableRow);
        }
        if (
          invoice &&
          showSubscriptions &&
          isSubscription &&
          doesItPassTabFiltering(TransactionTypesEnum.Invoice, invoice.status)
        ) {
          let paymentMethodLabel = ' ';
          if (invoice?.stripePaymentMethod) {
            paymentMethodLabel = stripePaymentMethodLabels[invoice.stripePaymentMethod.type];
          }

          const organizationPetParent = invoice.stripeCustomer?.organizationPetParents?.[0];
          const clinicPetParent = organizationPetParent?.clinicPetParents?.find(
            (clinicPetParent) => clinicPetParent.clinicId === currentClinicId,
          );
          const transactionFee = -1 * invoice.serviceFeeAmount;
          const tableRow: CSVTableRow = {
            clinicPetParentName: `${clinicPetParent?.firstName || ''} ${clinicPetParent?.lastName || ''}`.trim() || ' ',
            clinicPetParentPimsId: clinicPetParent?.pimsId || ' ',
            invoices: ' ',
            writebackStatus: ' ',
            transactionType: 'Subscription',
            paymentStatus: (getStatus(invoice?.status) as string) || ' ',
            date: data?.timestamp ? format(new Date(data?.timestamp), "MM/dd/yy '·' h:mm aaaaa'm'") : ' ',
            disbursed: payout?.arrivalDate ? format(new Date(payout?.arrivalDate?.replace(/Z$/, '')), 'MM/dd/yy') : ' ',
            amount: invoice.amount / 100,
            clientTransactionFee: ' ',
            totalInvoiced: ' ',
            transactionFees: transactionFee / 100,
            netProceeds: parseFloat(`${((invoice.amount || 0) + transactionFee) / 100}`.replace(/[$,]/g, '') || '0'),
            sentBy: ' ',
            cardBrand: invoice.stripePaymentMethod?.cardBrand || ' ',
            last4: invoice.stripePaymentMethod?.last4 || ' ',
            paymentMethod: paymentMethodLabel,
          };

          if (!isFeatureEnabled(FeatureFlagName.InvoiceDiscovery)) {
            delete tableRow.invoices;
          }

          if (!isFeatureEnabled(FeatureFlagName.BitwerxPaymentWritebacks)) {
            delete tableRow.writebackStatus;
          }

          transactionColumnsToRemove.forEach((columnName) => {
            delete tableRow[columnName];
          });

          CSVNewTableData.push(tableRow);
        }
        if (adjustment && doesItPassTabFiltering(TransactionTypesEnum.Adjustment, 'Adjustment')) {
          const { amount, description } = adjustment;

          const tableRow: CSVTableRow = {
            clinicPetParentName: ' ',
            clinicPetParentPimsId: ' ',
            invoices: ' ',
            writebackStatus: ' ',
            transactionType: `Adjustment (${description})`,
            paymentStatus: ' ',
            date: data?.timestamp ? format(new Date(data?.timestamp), "MM/dd/yy '·' h:mm aaaaa'm'") : ' ',
            disbursed: payout?.arrivalDate ? format(new Date(payout?.arrivalDate?.replace(/Z$/, '')), 'MM/dd/yy') : ' ',
            amount: amount / 100,
            clientTransactionFee: ' ',
            totalInvoiced: ' ',
            transactionFees: ' ',
            netProceeds: amount / 100,
            sentBy: ' ',
            cardBrand: ' ',
            last4: ' ',
            paymentMethod: ' ',
            terminal: ' ',
          };

          if (!isFeatureEnabled(FeatureFlagName.InvoiceDiscovery)) {
            delete tableRow.invoices;
          }

          if (!isFeatureEnabled(FeatureFlagName.BitwerxPaymentWritebacks)) {
            delete tableRow.writebackStatus;
          }

          transactionColumnsToRemove.forEach((columnName) => {
            delete tableRow[columnName];
          });

          CSVNewTableData.push(tableRow);
        }
      }
    }
    if (payoutsData && selectedFilterTab === PaymentReportFilterTabs.Disbursements) {
      for (const data of payoutsData?.findManyStripePayout || []) {
        const tableRow: CSVTableRow = {
          createdAt: format(new Date(data.initiatedAt), "MM/dd/yy '·' h:mm aaaaa'm'") || ' ',
          endOfDay: data?.payoutBatchingPeriod?.endsAt.toString() || ' ',
          arrivalDate: format(new Date(data.arrivalDate.replace(/Z$/, '')), 'MM/dd/yy') || ' ',
          amount: data.amount / 100,
          paymentStatus: (getStatus(data.status) as string) || ' ',
        };

        disbursementColumnsToRemove.forEach((columnName) => {
          delete tableRow[columnName];
        });

        CSVNewTableData.push(tableRow);
      }
    }

    let filteredNewTableData = CSVNewTableData;

    if (nameFiltering) {
      filteredNewTableData = CSVNewTableData.filter((row) => {
        if (row?.clinicPetParentName) {
          return row.clinicPetParentName.toLowerCase().includes(nameFiltering.toLowerCase());
        }
        return false;
      });
    }

    if (cardFiltering) {
      filteredNewTableData = filteredNewTableData.filter((row) => {
        if (row?.cardBrand) {
          return row.cardBrand.toLowerCase().includes(cardFiltering.toLowerCase());
        }
        return false;
      });
    }

    if (excludedTerminalNames?.length) {
      filteredNewTableData = filteredNewTableData.filter((row) => {
        if (!row.terminal || row.terminal === ' ') return false;

        return !excludedTerminalNames.includes(row.terminal);
      });
    }

    return filteredNewTableData;
  }, [
    cardFiltering,
    currentClinicId,
    doesItPassTabFiltering,
    getStatus,
    nameFiltering,
    payoutsData,
    selectedFilterTab,
    transactionsData,
    balanceTerminologyShort,
    isFeatureEnabled,
    transactionsColumnsSelection,
    disbursementsColumnsSelection,
    showSubscriptions,
    excludedTerminalNames,
    isPaymentConfigInvalid,
  ]);

  const tableTotals = useMemo(() => {
    let totalAmount = 0;
    let totalClientFees = 0;
    let totalInvoiced = 0;
    let totalTransactionFees = 0;
    let totalNetProceeds = 0;
    if (tableData) {
      for (const data of tableData) {
        const clientTransactionFee = data.clientTransactionFee === '--' ? '0' : data.clientTransactionFee;
        const clinicTotalInvoiced = data.totalInvoiced === '--' ? '0' : data.totalInvoiced;
        const transactionFees = data.transactionFees === '--' ? '0' : data.transactionFees;

        totalAmount += parseFloat(data.amount?.toString().replace(/[$,]/g, '') || '0') * 100;
        totalClientFees += parseFloat(clientTransactionFee?.replace(/[$,]/g, '') || '0') * 100;
        totalInvoiced += parseFloat(clinicTotalInvoiced?.replace(/[$,]/g, '') || '0') * 100;
        totalTransactionFees += parseFloat(transactionFees?.replace(/[$,]/g, '') || '0') * 100;
        totalNetProceeds += parseFloat(data?.netProceeds?.replace(/[$,]/g, '') || '0') * 100;
      }
    }
    return { totalAmount, totalClientFees, totalInvoiced, totalTransactionFees, totalNetProceeds };
  }, [tableData]);

  const handleDisbursementsColumnSelectionAll = (select: boolean): void => {
    setDisbursementsColumnsSelection((prevState) => {
      const newState = [...prevState];
      const financialReportingConfig = clinicSettingsData?.findUniqueClinic?.clinicSetting?.financialReportingConfig;
      let newFinancialReportingConfig: {
        transactionColumns: { [key: string]: { omit?: boolean } };
        disbursementColumns: { [key: string]: { omit?: boolean } };
      } = cloneDeep(financialReportingConfig);
      if (!newFinancialReportingConfig) {
        newFinancialReportingConfig = { transactionColumns: {}, disbursementColumns: {} };
      }
      for (const column of newState) {
        column.omit = !select;
      }
      for (const column of newState) {
        if (!newFinancialReportingConfig?.disbursementColumns) {
          newFinancialReportingConfig.disbursementColumns = {};
        }
        if (!newFinancialReportingConfig?.disbursementColumns[column.selector]) {
          newFinancialReportingConfig.disbursementColumns[column.selector] = {};
        }
        newFinancialReportingConfig.disbursementColumns[column.selector].omit = column.omit;
      }
      updateFinancialReportSetting({
        variables: {
          where: { id: clinicSettingsData?.findUniqueClinic?.clinicSetting?.id },
          data: {
            financialReportingConfig: {
              transactionColumns: newFinancialReportingConfig.transactionColumns,
              disbursementColumns: newFinancialReportingConfig.disbursementColumns,
            },
          },
        },
      });
      return newState;
    });
  };

  const handleDisbursementsColumnSelectionToggle = (columnSelector: string): void => {
    setDisbursementsColumnsSelection((prevState) => {
      const newState = [...prevState];
      const financialReportingConfig = clinicSettingsData?.findUniqueClinic?.clinicSetting?.financialReportingConfig;
      let newFinancialReportingConfig: {
        transactionColumns: { [key: string]: { omit?: boolean } };
        disbursementColumns: { [key: string]: { omit?: boolean } };
      } = cloneDeep(financialReportingConfig);
      if (!newFinancialReportingConfig) {
        newFinancialReportingConfig = { transactionColumns: {}, disbursementColumns: {} };
      }
      for (const column of newState) {
        if (column.selector === columnSelector) {
          column.omit = !column.omit;
        }
      }
      for (const column of newState) {
        if (!newFinancialReportingConfig?.disbursementColumns) {
          newFinancialReportingConfig.disbursementColumns = {};
        }
        if (!newFinancialReportingConfig?.disbursementColumns[column.selector]) {
          newFinancialReportingConfig.disbursementColumns[column.selector] = {};
        }
        newFinancialReportingConfig.disbursementColumns[column.selector].omit = column.omit;
      }

      updateFinancialReportSetting({
        variables: {
          where: { id: clinicSettingsData?.findUniqueClinic?.clinicSetting?.id },
          data: {
            financialReportingConfig: {
              transactionColumns: newFinancialReportingConfig.transactionColumns,
              disbursementColumns: newFinancialReportingConfig.disbursementColumns,
            },
          },
        },
      });
      return newState;
    });
  };

  const handleTransactionsColumnSelectionAll = (select: boolean): void => {
    setTransactionsColumnsSelection((prevState) => {
      const newState = [...prevState];
      const financialReportingConfig = clinicSettingsData?.findUniqueClinic?.clinicSetting?.financialReportingConfig;
      let newFinancialReportingConfig: {
        transactionColumns: { [key: string]: { omit?: boolean } };
        disbursementColumns: { [key: string]: { omit?: boolean } };
      } = cloneDeep(financialReportingConfig);
      if (!newFinancialReportingConfig) {
        newFinancialReportingConfig = { transactionColumns: {}, disbursementColumns: {} };
      }
      for (const column of newState) {
        column.omit = !select;
      }
      for (const column of newState) {
        if (!newFinancialReportingConfig?.transactionColumns) {
          newFinancialReportingConfig.transactionColumns = {};
        }
        if (!newFinancialReportingConfig?.transactionColumns[column.selector]) {
          newFinancialReportingConfig.transactionColumns[column.selector] = {};
        }
        newFinancialReportingConfig.transactionColumns[column.selector].omit = column.omit;
      }
      updateFinancialReportSetting({
        variables: {
          where: { id: clinicSettingsData?.findUniqueClinic?.clinicSetting?.id },
          data: {
            financialReportingConfig: {
              transactionColumns: newFinancialReportingConfig?.transactionColumns,
              disbursementColumns: newFinancialReportingConfig?.disbursementColumns,
            },
          },
        },
      });
      return newState;
    });
  };

  const handleTransactionsColumnSelectionToggle = (columnSelector: string): void => {
    setTransactionsColumnsSelection((prevState) => {
      const newState = [...prevState];
      const financialReportingConfig = clinicSettingsData?.findUniqueClinic?.clinicSetting?.financialReportingConfig;
      let newFinancialReportingConfig: {
        transactionColumns: { [key: string]: { omit?: boolean } };
        disbursementColumns: { [key: string]: { omit?: boolean } };
      } = cloneDeep(financialReportingConfig);
      if (!newFinancialReportingConfig) {
        newFinancialReportingConfig = { transactionColumns: {}, disbursementColumns: {} };
      }
      for (const column of newState) {
        if (column.selector === columnSelector) {
          column.omit = !column.omit;
        }
      }
      for (const column of newState) {
        if (!newFinancialReportingConfig?.transactionColumns) {
          newFinancialReportingConfig.transactionColumns = {};
        }
        if (!newFinancialReportingConfig?.transactionColumns[column.selector]) {
          newFinancialReportingConfig.transactionColumns[column.selector] = {};
        }
        newFinancialReportingConfig.transactionColumns[column.selector].omit = column.omit;
      }
      updateFinancialReportSetting({
        variables: {
          where: { id: clinicSettingsData?.findUniqueClinic?.clinicSetting?.id },
          data: {
            financialReportingConfig: {
              transactionColumns: newFinancialReportingConfig?.transactionColumns,
              disbursementColumns: newFinancialReportingConfig?.disbursementColumns,
            },
          },
        },
      });
      return newState;
    });
  };

  const shouldTotalDisplay = useCallback(
    (totalType: PaymentReportTotalTypes): boolean => {
      const disbursementColumn = disbursementsColumnsSelection.filter((column) => column.selector === totalType)[0];
      const transactionColumn = transactionsColumnsSelection.filter((column) => column.selector === totalType)[0];

      if (selectedFilterTab === PaymentReportFilterTabs.Disbursements) {
        if (disbursementColumn?.selector === PaymentReportTotalTypes.Amount) {
          return !disbursementColumn.omit;
        }
        return false;
      } else {
        switch (totalType) {
          case PaymentReportTotalTypes.Amount:
            if (transactionColumn?.selector === PaymentReportTotalTypes.Amount) {
              return !transactionColumn.omit;
            }
            break;
          case PaymentReportTotalTypes.ClientFee:
            if (transactionColumn?.selector === PaymentReportTotalTypes.ClientFee) {
              return !transactionColumn.omit;
            }
            break;
          case PaymentReportTotalTypes.TransactionFees:
            if (transactionColumn?.selector === PaymentReportTotalTypes.TransactionFees) {
              return !transactionColumn.omit;
            }
            break;
          case PaymentReportTotalTypes.NetProceeds:
            if (transactionColumn?.selector === PaymentReportTotalTypes.NetProceeds) {
              return !transactionColumn.omit;
            }
            break;
          case PaymentReportTotalTypes.TotalInvoiced:
            if (transactionColumn?.selector === PaymentReportTotalTypes.TotalInvoiced) {
              return !transactionColumn.omit;
            }
            break;
        }
      }
      return false;
    },
    [disbursementsColumnsSelection, selectedFilterTab, transactionsColumnsSelection],
  );

  const isAnyFilteredTotalSelected = (): boolean => {
    return (
      shouldTotalDisplay(PaymentReportTotalTypes.Amount) ||
      shouldTotalDisplay(PaymentReportTotalTypes.ClientFee) ||
      shouldTotalDisplay(PaymentReportTotalTypes.TransactionFees) ||
      shouldTotalDisplay(PaymentReportTotalTypes.NetProceeds) ||
      shouldTotalDisplay(PaymentReportTotalTypes.TotalInvoiced)
    );
  };

  const totalsCategories = [
    {
      type: PaymentReportTotalTypes.Amount,
      label: 'AMOUNT',
      value: tableTotals.totalAmount,
    },
    {
      type: PaymentReportTotalTypes.ClientFee,
      label: 'CLIENT FEES',
      value: tableTotals.totalClientFees,
    },
    {
      type: PaymentReportTotalTypes.TotalInvoiced,
      label: 'TOTAL INVOICED',
      value: tableTotals.totalInvoiced,
    },
    {
      type: PaymentReportTotalTypes.TransactionFees,
      label: 'TRANSACTION FEES',
      value: tableTotals.totalTransactionFees,
    },
    {
      type: PaymentReportTotalTypes.NetProceeds,
      label: 'NET PROCEEDS',
      value: tableTotals.totalNetProceeds,
    },
  ];

  const shouldShowIncludeMembershipCheckbox = useMemo(
    () => careClinics.includes(organizationId || ''),
    [organizationId],
  );

  const onPrintClick = useCallback(() => {
    setTransactionsColumnsSelection((state) => {
      const newState = [...state];
      for (const column of newState) {
        const selector = column.selector;
        if (selector === PaymentReportColumnSelectors.Conversation) {
          column.omit = true;
        }
      }

      return newState;
    });
    setDisbursementsColumnsSelection((state) => {
      const newState = [...state];
      for (const column of newState) {
        const selector = column.selector;
        if (selector === PaymentReportColumnSelectors.LinkedTransactions) {
          column.omit = true;
        }
      }
      return newState;
    });
    setIsPrinting(true);
    setIsColumnFilterOnDisplay(false);
    setIsCalendarOnDisplay(false);
    setTimeout(() => {
      if (handlePrint && tableData.length) {
        handlePrint();
      } else {
        addSnackbar({
          type: MessageTypes.Error,
          message: 'There is no data to print, please adjust your filters',
        });
      }
    }, 0);
  }, [addSnackbar, handlePrint, tableData.length]);

  const onDownloadClick = useCallback(() => {
    if (CSVTableData.length) {
      setIsColumnFilterOnDisplay(false);
      setIsCalendarOnDisplay(false);
      downloadCSV(CSVTableData);
    } else {
      addSnackbar({
        type: MessageTypes.Error,
        message: 'There is no data to download, please adjust your filters',
      });
    }
  }, [CSVTableData, addSnackbar, downloadCSV]);

  return (
    <Wrapper>
      <Modal
        name={ModalNames.RefundReason}
        size={ModalSizes.sm}
        overlayOpacity={0.11}
        includeCloseButton={false}
        style={{ zIndex: 100 }}
      >
        <RefundModalContent>
          <ModalHeader>
            <Title>Reason for refund</Title>
            <CloseButton data-testid="close-modal-button" onClick={closeModal}>
              <CloseIcon />
            </CloseButton>
          </ModalHeader>

          {refundReason}
        </RefundModalContent>
      </Modal>
      <WritebackAlerts showFailedWritebackAlert={showFailedWritebackAlert} />
      <Flex justify="space-between" align="center" mb={4}>
        <Heading size="sm">Transaction Reporting</Heading>
        <Flex align="center" gap={4}>
          {selectedFilterTab !== PaymentReportFilterTabs.Disbursements &&
            (isFeatureEnabled(FeatureFlagName.CarePlans) || shouldShowIncludeMembershipCheckbox) && (
              <Checkbox
                isChecked={showSubscriptions}
                defaultChecked={showSubscriptions}
                onChange={(): void => {
                  gaTrack(
                    showSubscriptions
                      ? GA4Events.MEMBERSHIP_PAYMENTS_REPORT_EXCLUDE
                      : GA4Events.MEMBERSHIP_PAYMENTS_REPORT_INCLUDE,
                  );
                  setShowSubscriptions((shown) => !shown);
                }}
              >
                Include Memberships
              </Checkbox>
            )}
          {selectedFilterTab !== PaymentReportFilterTabs.Disbursements && (
            <TerminalFilterMenu setExcludedTerminalNames={setExcludedTerminalNames} />
          )}
          <Box>
            <Button
              iconName="calendarDate"
              variant="secondary"
              size="sm"
              rightIcon={<Icon size="sm" name="chevronDown" />}
              onClick={(): void => {
                setIsColumnFilterOnDisplay(false);
                setIsCalendarOnDisplay((current) => !current);
              }}
            >
              Date
            </Button>
            <FilterDrop
              ref={calendarDropRef}
              onClick={(e): void => {
                e.stopPropagation();
              }}
              display={`${isCalendarOnDisplay}`}
            >
              <TimesSelector>
                <TimeItem>
                  Start at
                  <TimePicker
                    defaultDate={selectionRange.startDate}
                    onMenuClosed={(date): void => {
                      const newSelectionRange = { ...selectionRange };
                      newSelectionRange.startDate = date;
                      setSelectionRange(newSelectionRange);
                    }}
                    withTimeIcon={false}
                    minuteIncrement={5 || undefined}
                    showLastMinute
                  />
                  on
                </TimeItem>
                <TimeItem>
                  End at
                  <TimePicker
                    defaultDate={selectionRange.endDate}
                    onMenuClosed={(date): void => {
                      const newSelectionRange = { ...selectionRange };
                      newSelectionRange.endDate = date;
                      setSelectionRange(newSelectionRange);
                    }}
                    withTimeIcon={false}
                    minuteIncrement={5 || undefined}
                    showLastMinute
                  />
                  on
                </TimeItem>
              </TimesSelector>
              <DateRangePicker
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={isSmallScreen ? 1 : 2}
                rangeColors={['#39adc2']}
                fixedHeight={true}
                direction="horizontal"
                ranges={[selectionRange]}
                // eslint-disable-next-line
                onChange={(e: any): void => {
                  setLinkedTransactions(undefined);
                  e.selection.startDate = new Date(e.selection.startDate.setHours(0, 0, 0, 0));
                  e.selection.endDate = new Date(e.selection.endDate.setHours(23, 59, 59, 999));
                  setSelectionRange(e.selection);
                }}
              />
            </FilterDrop>
          </Box>
          <Box>
            <Button
              iconName="eyeOpen"
              variant="secondary"
              size="sm"
              rightIcon={<Icon size="sm" name="chevronDown" />}
              onClick={(): void => {
                setIsCalendarOnDisplay(false);
                setIsColumnFilterOnDisplay((current) => !current);
              }}
            >
              Show
            </Button>
            <FilterDrop
              ref={filterDropRef}
              display={`${isColumnFilterOnDisplay}`}
              onClick={(e): void => {
                e.stopPropagation();
              }}
            >
              {selectedFilterTab === PaymentReportFilterTabs.Disbursements
                ? disbursementsColumnsSelection.map((column) => {
                    return (
                      <ColumnSelectorRow
                        key={column.selector}
                        onClick={(): void => handleDisbursementsColumnSelectionToggle(column.selector)}
                      >
                        {column.omit ? <CustomEmptyCheckbox /> : <CustomCheckedCheckbox />}
                        <div>{column.name}</div>
                      </ColumnSelectorRow>
                    );
                  })
                : transactionsColumnsSelection.map((column) => {
                    return (
                      <ColumnSelectorRow
                        key={column.selector}
                        onClick={(): void => handleTransactionsColumnSelectionToggle(column.selector)}
                      >
                        {column.omit ? <CustomEmptyCheckbox /> : <CustomCheckedCheckbox />}
                        <div>{column.name}</div>
                      </ColumnSelectorRow>
                    );
                  })}
              <SelectMultiple
                onClick={(): void => {
                  if (selectedFilterTab === PaymentReportFilterTabs.Disbursements) {
                    handleDisbursementsColumnSelectionAll(true);
                  } else {
                    handleTransactionsColumnSelectionAll(true);
                  }
                }}
              >
                Select All
              </SelectMultiple>
              <SelectMultiple
                onClick={(): void => {
                  if (selectedFilterTab === PaymentReportFilterTabs.Disbursements) {
                    handleDisbursementsColumnSelectionAll(false);
                  } else {
                    handleTransactionsColumnSelectionAll(false);
                  }
                }}
              >
                Deselect All
              </SelectMultiple>
            </FilterDrop>
          </Box>
          <Box>
            <Button
              iconName="printer"
              variant="secondary"
              size="sm"
              disabled={!allTransactionsLoaded}
              onClick={onPrintClick}
            >
              Print
            </Button>
          </Box>
          <Box>
            <Button
              iconName="download"
              variant="secondary"
              size="sm"
              disabled={!allTransactionsLoaded}
              onClick={onDownloadClick}
            >
              Download CSV
            </Button>
          </Box>
        </Flex>
      </Flex>
      {showEOD && (
        <Flex mb="4">
          <EODBanner pimsName={pimsName} currentClinicId={currentClinicId} clinicUserId={clinicUserId} />
        </Flex>
      )}

      <FilterBar>
        <Filtering>
          <FilterTabSelection>
            <TabSelection
              isSelected={selectedFilterTab === PaymentReportFilterTabs.Complete}
              onClick={(): void => {
                setSelectedFilterTab(PaymentReportFilterTabs.Complete);
              }}
            >
              {PaymentReportFilterTabs.Complete}
            </TabSelection>
            <TabSelection
              isSelected={selectedFilterTab === PaymentReportFilterTabs.Incomplete}
              onClick={(): void => {
                setSelectedFilterTab(PaymentReportFilterTabs.Incomplete);
              }}
            >
              {PaymentReportFilterTabs.Incomplete}
            </TabSelection>
            <TabSelection
              isSelected={selectedFilterTab === PaymentReportFilterTabs.All}
              onClick={(): void => {
                setSelectedFilterTab(PaymentReportFilterTabs.All);
              }}
            >
              {PaymentReportFilterTabs.All}
            </TabSelection>
            <TabSpacer>|</TabSpacer>
            <TabSelection
              isSelected={selectedFilterTab === PaymentReportFilterTabs.Disbursements}
              onClick={(): void => {
                setSelectedFilterTab(PaymentReportFilterTabs.Disbursements);
              }}
            >
              {PaymentReportFilterTabs.Disbursements}
            </TabSelection>
          </FilterTabSelection>
          {linkedTransactions?.payoutDate ? (
            <LinkedTransactions>Transactions from disbursement on {linkedTransactions?.payoutDate}</LinkedTransactions>
          ) : (
            <FilteringDates>
              {format(new Date(selectionRange.startDate), "MM-dd-yy h:mm aaaaa'm' ")}-
              {format(new Date(selectionRange.endDate), " MM-dd-yy h:mm aaaaa'm'")}
            </FilteringDates>
          )}
        </Filtering>
      </FilterBar>
      <OtherFilters>
        {selectedFilterTab !== PaymentReportFilterTabs.Disbursements && (
          <>
            <TagName>Filter Results by Client Name:</TagName>
            <FilterInput
              onFocus={(): void => {
                Mixpanel.track('Filter by transactions by name');
              }}
              type="text"
              value={nameFiltering}
              onChange={(e): void => {
                setNameFiltering(e.target.value);
              }}
            />
            <TagName>Card Type:</TagName>
            <FilterInput
              onFocus={(): void => {
                Mixpanel.track('Filter by transactions by card type');
              }}
              type="text"
              value={cardFiltering}
              onChange={(e): void => {
                setCardFiltering(e.target.value);
              }}
            />
          </>
        )}
      </OtherFilters>
      <div ref={printableRef}>
        <TableHeader>
          {isAnyFilteredTotalSelected() && (
            <FilteredTotals>
              Filtered Totals
              <StyledTable>
                <StyledTableBody>
                  <StyledTableRow>
                    {totalsCategories.map((category) => {
                      return shouldTotalDisplay(category.type) ? (
                        <React.Fragment key={`totals_${category.type}`}>
                          <StyledTableHeader>{category.label}</StyledTableHeader>
                          <StyledTableData>
                            {allTransactionsLoaded ? formatMoney(category.value) : <LoadingSpinner />}
                          </StyledTableData>
                        </React.Fragment>
                      ) : null;
                    })}
                  </StyledTableRow>
                </StyledTableBody>
              </StyledTable>
            </FilteredTotals>
          )}
        </TableHeader>
        <StyledDataTable
          noHeader
          striped
          columns={
            selectedFilterTab === PaymentReportFilterTabs.Disbursements
              ? disbursementsColumnsSelection
              : isDeveloperModeEnabled
              ? [
                  {
                    name: PaymentReportColumnNames.DevMode,
                    selector: PaymentReportColumnSelectors.DevMode,
                    sortable: false,
                    omit: false,
                    width: '260px',
                  },
                  ...transactionsColumnsSelection,
                ]
              : transactionsColumnsSelection
          }
          data={tableData}
          progressPending={
            (clinicSettingsLoading || !initialTransactionsLoaded || PayoutsLoading) && !TransactionsError
          }
          pagination={!isPrinting}
          paginationRowsPerPageOptions={[10, 15, 20, 30, tableData.length]}
          theme="otto"
          // eslint-disable-next-line
          sortFunction={(rows, field, sortDirection): any => {
            // eslint-disable-next-line
            const getCellValue = (field: string, value: any): string | number => {
              switch (field) {
                case 'clinicPetParentName':
                  return value.props?.content;
                case 'clinicPetParentPimsId':
                  if (typeof parseInt(value) === 'number') {
                    return parseInt(value);
                  } else {
                    return value;
                  }
                case 'paymentStatus':
                  return value.props?.label;
                case 'writebackStatus':
                  return value?.props?.children?.props?.statusDisplay;
                case 'amount':
                  return parseFloat(value.replace(/[$,]/g, ''));
                case 'clientTransactionFee':
                  return parseFloat(value.replace(/[$,]/g, ''));
                case 'totalInvoiced':
                  return parseFloat(value.replace(/[$,]/g, ''));
                case 'transactionFees':
                  return parseFloat(value.replace(/[$,]/g, ''));
                case 'netProceeds':
                  return parseFloat(value.replace(/[$,]/g, ''));
                case 'transactionType':
                case 'paymentMethod':
                  return value.props.children;
                case 'sentBy':
                  return value.props?.content;
                case 'invoices':
                  const v = parseInt(value?.props?.children?.[0]?.props?.children, 10);

                  return isNaN(v) ? value?.props?.children?.[0]?.props?.children : v;
                default:
                  break;
              }
              return value || '';
            };
            const sortedArray = sortBy(tableData, (element: { [key: string]: string | JSX.Element }) => {
              return getCellValue(field, element[field]);
            });
            return sortDirection === 'asc' ? sortedArray : reverse(sortedArray);
          }}
        />
      </div>
      {refundPaymentIntent && (
        <RefundModal
          elementId={refundPaymentIntent.id}
          paymentIntent={refundPaymentIntent}
          refetchStripePaymentIntent={refetch}
        />
      )}
      {isDeveloperModeEnabled && (
        <CModal size={'2xl'} isOpen={!!developerModeObject} onClose={(): void => setDeveloperModeObject(null)}>
          <ModalOverlay />
          <ModalContent>
            <CModalHeader>Financial Transaction {developerModeObject?.id}</CModalHeader>
            <ModalCloseButton />
            <CModalBody>
              <ScrollArea>
                <DevModeDisplayJSON src={developerModeObject || {}} />
              </ScrollArea>
            </CModalBody>
          </ModalContent>
        </CModal>
      )}
    </Wrapper>
  );
};

export default PaymentReport;

const Wrapper = styled.div`
  padding-top: 20px;
`;

// const SectionHeader = styled.div`
//   position: relative;
//   display: flex;
//
//   font-size: 16px;
//   font-weight: 600;
//   justify-content: space-between;
//   align-items: center;
//   margin-bottom: 19px;
// `;

const Title = styled.h2`
  display: flex;

  font-size: 16px;
  font-weight: 600;
  align-items: center;
`;

const Filtering = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--chakra-colors-background-default);
  border-bottom: 2px #e8e9e9 solid;
  width: 100%;
  background-color: var(--chakra-colors-background-default);
`;

const FilterTabSelection = styled.div`
  display: flex;
`;

interface ITabSelectionProps {
  isSelected: boolean;
}
const TabSelection = styled.div<ITabSelectionProps>`
  padding-bottom: 8px;
  margin: 17px 17px 0 0px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #73747d;
  border-bottom: ${({ isSelected }): string => (isSelected ? 'solid 3px #39adc3' : 'none')};
  cursor: pointer;
`;

const TabSpacer = styled.div`
  padding-bottom: 8px;
  margin: 17px 17px 0 0;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #73747d;
`;

interface IFilterDropProps {
  display: string;
}

const FilterDrop = styled.div<IFilterDropProps>`
  display: ${({ display }): string => (display === 'true' ? 'inline-block' : 'none')};
  position: absolute;
  top: 45px;
  right: ${window.outerWidth <= 1575 ? '0px' : '240px'};
  padding: 20px;
  border-radius: 7px;
  border: solid 1px #d2d2d2;
  z-index: 900;
  background-color: var(--chakra-colors-background-default);
`;

const FilteringDates = styled.div``;

const LinkedTransactions = styled.div``;

const TableHeader = styled.div`
  display: flex;
  padding: 10px 0px;
  justify-content: flex-end;
`;

const FilteredTotals = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledTable = styled.table`
  margin-left: 12px;
`;

const StyledTableBody = styled.tbody``;

const StyledTableHeader = styled.th`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`;
const StyledTableRow = styled.tr``;
const StyledTableData = styled.td`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  background-color: var(--chakra-colors-background-default);
  padding: 0 10px;
`;

const ColumnSelectorRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  cursor: pointer;
  user-select: none;
`;

const CustomCheckedCheckbox = styled(CheckedCheckbox)`
  width: 14px;
  height: 14px;
  margin-right: 10px;
`;

const CustomEmptyCheckbox = styled.div`
  margin-right: 10px;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  border: solid 1.5px #575d7c;
  background-color: var(--chakra-colors-background-default);
`;

const TimesSelector = styled.div`
  padding-left: 225px;
  padding-top: 10px;
  display: flex;
  width: ${window.outerWidth <= 1575 ? '570px' : '889px'};
  padding-bottom: 10px;
  background-color: var(--chakra-colors-background-default);

  justify-content: flex-end;
`;

const TimeItem = styled.div`
  display: flex;
  width: 498px;
  padding: 11px;
`;

const StyledExternalLinkIcon = styled(ExternalLinkIcon)`
  margin-left: 7px;
`;
const FilterBar = styled.div`
  background-color: var(--chakra-colors-background-default);
  padding: 0 17px 20px 17px;
`;

const StyledLink = styled.div`
  color: #39adc3;
  cursor: pointer;
  text-decoration: underline;
`;

const SelectMultiple = styled.div`
  margin-top: 10px;
  cursor: pointer;
`;

const OtherFilters = styled.div`
  margin-top: 10px;
`;

const TagName = styled.span``;

const FilterInput = styled.input`
  margin: 0 10px;
  padding: 4px;
  border-radius: 7px;
  border: solid 1px #d2d2d2;
  background-color: var(--chakra-colors-background-default);
`;

const RefundModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
`;

const ModalHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const CloseButton = styled.button`
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #eee;
  }

  svg {
    height: 12px;
    width: 12px;
  }
`;

const DeveloperLink = styled.div`
  cursor: pointer;
  color: #39adc3;
`;

const ScrollArea = styled.div`
  height: 75vh;
  overflow-y: auto;
`;

const StyledDataTable = styled(DataTable)`
  min-height: 300px;

  [data-popper-arrow] {
    overflow: visible !important;
  }
`;
