import { Spinner } from '@televet/kibble-ui/build/components/Spinner';
import { Text } from '@televet/kibble-ui/build/components/Text';
import React from 'react';

type RecipientCountProps = {
  loading?: boolean;
  recipientCount?: number | null;
}

const RecipientCount = ({
  recipientCount = 0,
  loading,
}: RecipientCountProps): JSX.Element => {
  const text = recipientCount === 1 ? '1 Recipient' : `${recipientCount} Recipients`;

  return <>{loading ? <Spinner size="xs" /> : <Text>({text})</Text>}</>;
};

export default RecipientCount;
