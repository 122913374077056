import React, { useState, useEffect, useMemo, useCallback, ChangeEvent } from 'react';
import styled from 'styled-components/macro';
import * as Sentry from '@sentry/react';
import { AutoComplete, MenuItem, TextArea, IOption, Checkbox } from '@televet/televet-ui';
import {
  ClinicPet as ClinicPetT,
  ClinicPimsIntegrationType,
  ClinicPetNestedSelectionFragment,
  AppointmentTypeBaseSelectionFragment,
  ClinicRoomBaseSelectionFragment,
  ClinicEmployeeBaseSelectionFragment,
  ClinicPimsIntegrationFragment,
  VetDataIntegrationSystem,
  WorkflowEventSetting,
  AppointmentPetFragment,
} from 'shared/types/graphql';
import useDebounce from 'shared/hooks/useDebounce';
import PetAvatar from 'shared/components/Avatars/PetAvatar';
import { addMinutes, differenceInMinutes, startOfDay } from 'date-fns';
import TimePicker from 'shared/components/TimePicker';
import useClinicUser from 'shared/hooks/useClinicUser';
import { PopoverPlacement } from 'shared/components/Popover';
import Dropdown from 'shared/components/Dropdown';
import { ChevronIcon, CloseIcon, CircleCancelIcon } from 'assets/icons';
import { addHours } from 'date-fns/esm';
import useResponsiveObserver, { observerCallback } from 'shared/hooks/useResponsiveObserver';
import mergeWith from 'lodash-es/mergeWith';
import sortBy from 'lodash-es/sortBy';
import { Box, DatePicker, Tooltip, Button, Wrap, WrapItem, Skeleton, DateRange } from '@televet/kibble-ui';
import { useIntegrationsProvider, integrationSystemDisplayName } from 'shared/providers/IntegrationsProvider';
import { useTriggerMessageEventAndSubscribe } from 'shared/hooks/useTriggerMessageEventAndSubscribe';
import { SqsQueueNames } from '@televet/shared-types/SqsQueueNames';
import { ConditionEngineRoutingKey, FindWorkflowsForContextJobInput } from '@televet/shared-types/conditionEngineJobs';
import useFeatureFlag from 'shared/hooks/useFeatureFlag';
import { FeatureFlagName } from 'shared/enums';

/**
 * These are the values passed through to the submit appointment function, but it's not exactly the same as the formState.
 * The formState uses options to accommodate the Dropdown component, but the data is transformed before it is submitted.
 */
export type FormValues = {
  clinicEmployeeId: string;
  roomId: string;
  petId: string;
  petParentIds: string[];
  appointmentTypeId: string;
  startAt: Date; // ISO Date
  durationInMinutes: number;
  description: string;
  workflows: { id: string }[];
  clinicPetParents: Record<string, boolean> | null;
  petSearch: string;
};

interface IAppointmentFormProps {
  workflowOptions: IOption[];
  rooms: IOption[];
  employees: IOption[];
  appointmentTypes: IOption[];
  workflowEventSettings: WorkflowEventSetting[];
  clinicEmployee?: ClinicEmployeeBaseSelectionFragment | null;
  room: ClinicRoomBaseSelectionFragment | null;
  petId: string;
  clinicPet: ClinicPetNestedSelectionFragment | null;
  clinicPetParents: Record<string, boolean> | null;
  startAt: Date;
  description: string;
  petSearchResults: AppointmentPetFragment[];
  appointmentType: AppointmentTypeBaseSelectionFragment | null;
  isLoading: boolean;
  startTime: Date;
  endTime: Date;
  appointmentId?: string;
  pimsId?: string;
  defaultAppointmentDurationInMinutes: number;
  pimsIntegrations?: ClinicPimsIntegrationFragment[] | null;
  onFindClinicPets: (petSearch: string) => void;
  onAppointmentSubmit: (values: FormValues) => void;
  onCancel: () => void;
  onDelete: () => void;
}

/**
 * These are the values we are tracking and updating across our Dropdown components,
 * and the data is transformed throughout user interaction with the form.
 * Note: startAt is used in the form to get the specific day, and startTime and endTime
 * are used to get the times during the day. Upon form submission, startAt becomes the time
 * and day of the appointment, and durationInMinutes is the calculated to gather the endTime for referencing.
 *
 */

interface FormState {
  startAt: Date;
  endTime: Date;
  startTime: Date;
  room: ClinicRoomBaseSelectionFragment | null;
  petSearch: string;
  description: string;
  clinicEmployee?: ClinicEmployeeBaseSelectionFragment | null;
  appointmentType: AppointmentTypeBaseSelectionFragment | null;
  workflowOptions: IOption[] | null;
  clinicPetParents: Record<string, boolean> | null;
}

/**
 * This state will probably get merged into the above FormState interface at some point, but for now it is independent.
 */
interface SelectedPetState {
  clinicPet: ClinicPetNestedSelectionFragment | null;
  error: string | null;
}

const AppointmentForm = ({
  rooms,
  employees,
  appointmentTypes,
  clinicEmployee,
  workflowOptions,
  room,
  petId,
  clinicPet,
  appointmentType,
  startAt,
  description,
  startTime,
  endTime,
  petSearchResults,
  isLoading,
  defaultAppointmentDurationInMinutes,
  appointmentId,
  pimsId,
  onFindClinicPets,
  onAppointmentSubmit,
  onCancel,
  onDelete,
  clinicPetParents,
}: IAppointmentFormProps): JSX.Element => {
  const { primaryIntegration, primaryIntegrationName } = useIntegrationsProvider();
  const { isFeatureEnabled } = useFeatureFlag();
  const primaryIntegrationType = primaryIntegration?.type || null;

  const shouldShowPimsWarning = useMemo(() => {
    const vetdataSystemName = primaryIntegration?.vetdata?.system || '';

    if (primaryIntegration) {
      // No error --> Datapoint (Cornerstone), Vetdata (Evet), Neo
      if (
        primaryIntegrationType === ClinicPimsIntegrationType.Neo ||
        primaryIntegrationType === ClinicPimsIntegrationType.Datapoint ||
        (primaryIntegrationType === ClinicPimsIntegrationType.Vetdata &&
          vetdataSystemName === VetDataIntegrationSystem.Evetpractice)
      ) {
        return !isFeatureEnabled(FeatureFlagName.FlowAppointmentCreationPimsWriteback);
      }

      // Error --> VetData/Bitwerx (Impromed, Avimark, Cornerstone), Instinct, Ezyvet and any other integration type
      return true;
    }

    // No integrations
    return false;
  }, [primaryIntegration, primaryIntegrationType, isFeatureEnabled]);

  const isNeoAppointmentWithPims = useMemo(
    () => primaryIntegrationName === integrationSystemDisplayName.NEO && !!pimsId,
    [pimsId, primaryIntegrationName],
  );

  const [petSearch, setPetSearch] = useState('');
  /**
   * selectedPetState manages the pet chosen for this appointment. The value is set in either the useEffect listening for the
   * clinicPet prop, or upon the user selecting a pet from the search list.
   */
  const [selectedPetState, setSelectedPetState] = useState<SelectedPetState>({ clinicPet: null, error: null });

  /**
   * This error is set in the form submit function and shows red text above the submit button.
   */
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { currentClinic } = useClinicUser();
  /**
   * The time slot increments shown in the TimePicker component (e.g. 5, 10, 15 minute intervals for possible appointment timing).
   */
  const clinicMinuteIncrement = useMemo(
    () => currentClinic?.clinicSetting?.appointmentTimeSlotIncrementInMinutes,
    [currentClinic],
  );
  /**
   * The form state that holds almost all of this component's data
   */
  const [formState, setFormState] = useState<FormState>({
    room,
    startAt,
    endTime,
    startTime,
    description,
    petSearch: '',
    clinicEmployee,
    appointmentType,
    workflowOptions,
    clinicPetParents,
  });

  /**
   * The rows wrap to the next line at a screen width of about 545px, and we need to account for this when showing the
   * list of selected workflow options. They are meant to be underneath both the workflow type and assignee fields
   * on desktop, but they need to be directly underneath workflow type on mobile, or it doesn't make sense.
   * `isMobile` is our key to know which location to show it in
   */
  const FORM_WIDTH_BREAKPOINT = 545;

  const [isMobile, setIsMobile] = useState(window.outerWidth <= FORM_WIDTH_BREAKPOINT);

  useResponsiveObserver({
    element: document.getElementById('root'),
    callback: (entries: readonly ResizeObserverEntry[]) =>
      observerCallback(entries, setIsMobile, FORM_WIDTH_BREAKPOINT),
  });

  const [debouncedSearchTerm] = useDebounce(petSearch, 350);

  useEffect(() => {
    onFindClinicPets(debouncedSearchTerm);
  }, [debouncedSearchTerm, onFindClinicPets]);

  useEffect(() => {
    setSelectedPetState({
      error: null,
      clinicPet,
    });
  }, [clinicPet, setSelectedPetState]);

  /*
   * Compare to ensure endTime is always after startTime, and move the end time if
   * the user tries to set it to occur before the start time.
   */
  useEffect(() => {
    const startAtCopy = new Date(formState.startTime.getTime());
    let endAtCopy = new Date(formState.endTime.getTime());
    if (startAtCopy >= endAtCopy) {
      endAtCopy = addMinutes(startAtCopy, defaultAppointmentDurationInMinutes);
      setFormState((prevState) => ({ ...prevState, endTime: endAtCopy }));
    }
  }, [formState.startTime, formState.endTime, startAt, defaultAppointmentDurationInMinutes]);

  const getItemValue = (clinicPet: ClinicPetT): string | number => {
    if (!clinicPet) return '';
    return clinicPet.id;
  };

  const onRemovePet = (): void => {
    setSelectedPetState({ clinicPet: null, error: null });
  };

  const onSelectPet = (_: string, clinicPet: ClinicPetNestedSelectionFragment): void => {
    onRemovePet();
    const clinicPetParentMap: Record<string, boolean> = {};
    clinicPet.petParents?.forEach((clinicPetParent) => {
      clinicPetParentMap[clinicPetParent.id] = true;
    });

    updateMatchingWorkflows({ clinicPetId: clinicPet.id });

    setFormState((prevState) => ({ ...prevState, petSearch: '', clinicPetParents: clinicPetParentMap }));

    // Do not remove. Fixes a strange internal race condition
    // for ref in react-hook-form Radio component
    setTimeout(() => {
      setSelectedPetState({ clinicPet, error: null });
    }, 0);
  };

  const renderItem = (clinicPet: ClinicPetT, isSelected: boolean): JSX.Element => {
    return (
      <MenuItem isSelected={isSelected}>
        <PetSearchMenuContainer>
          <PetAvatar
            species={clinicPet.species}
            name={clinicPet.name}
            isDeceased={clinicPet.computedIsDeceased}
            isActive={clinicPet.computedIsActive}
            photoUrl={clinicPet.photoUrl}
          />
          <PetSearchContent>
            <PetName>{clinicPet.name}</PetName>
            <PetInfo>{`${clinicPet.breed || 'Breed Not Available'}, ${
              clinicPet.gender || 'Sex Not Available'
            }`}</PetInfo>
            {clinicPet?.petParents &&
              clinicPet?.petParents.map((petParent, index) => {
                return <OwnerInfo key={index}>{`${petParent.firstName} ${petParent.lastName}`}</OwnerInfo>;
              })}
          </PetSearchContent>
        </PetSearchMenuContainer>
      </MenuItem>
    );
  };

  const onStartTimeChanged = useCallback((_, date: Date): void => {
    setFormState((prevState) => {
      return {
        ...prevState,
        startTime: date,
      };
    });
  }, []);

  const onEndTimeChanged = useCallback((_, date: Date): void => {
    setFormState((prevState) => {
      return {
        ...prevState,
        endTime: date,
      };
    });
  }, []);

  /**
   * When selecting/unselecting workflows, we are changing the isSelected property on the IOption object.
   * Upon form submission, we pass through only the selected options and send the array of workflow IDs to the createAppointment mutation.
   */
  const onSelectWorkflow = useCallback((_, workflowOption: IOption) => {
    setFormState((prevState) => {
      return {
        ...prevState,
        workflowOptions:
          prevState?.workflowOptions?.map((option) => {
            if (option.value.id === workflowOption.value.id) return { ...option, isSelected: !option.isSelected };
            return option;
          }) || [],
      };
    });
  }, []);

  const { triggerMessageEvent, payload: jobChainPayload } =
    useTriggerMessageEventAndSubscribe<Pick<WorkflowEventSetting, 'id'>[]>();

  const [showAutomationsLoadingState, setShowAutomationsLoadingState] = useState(false);

  useEffect(() => {
    if (jobChainPayload?.status === 'completed') {
      // TODO: Fix BE-sourced JSON structure to be a top-level Array rather than a nested, int-keyed Object
      const matchingWorkflowIds = Object.values(jobChainPayload.payload?.[0] || {}).map(({ id }) => id);
      setFormState((prevState) => ({
        ...prevState,
        workflowOptions: (prevState.workflowOptions || []).map((option) => ({
          ...option,
          isSelected: matchingWorkflowIds.includes(option.value.id),
        })),
      }));
    }
    if (jobChainPayload?.status === 'completed' || jobChainPayload?.status === 'errored') {
      setShowAutomationsLoadingState(false);
    }
  }, [jobChainPayload]);

  /**
   * Match workflows to this appointment by calling the condition engine with appointment context
   */
  const updateMatchingWorkflows = useCallback(
    async (
      updates: Partial<Pick<FormState, 'appointmentType' | 'clinicEmployee' | 'room'> & { clinicPetId?: string }>,
    ) => {
      if (!currentClinic?.id) {
        return;
      }
      setShowAutomationsLoadingState(true);

      const existingContext: FindWorkflowsForContextJobInput = {
        appointmentTypeId: formState.appointmentType?.id,
        clinicEmployeeIds: formState.clinicEmployee?.id ? [formState.clinicEmployee.id] : [],
        clinicRoomId: formState.room?.id,
        clinicPetId: selectedPetState?.clinicPet?.id,
      };
      const updatedContext: FindWorkflowsForContextJobInput = {
        appointmentTypeId: updates.appointmentType?.id,
        clinicEmployeeIds: updates.clinicEmployee
          ? [...(updates.clinicEmployee.id ? [updates.clinicEmployee.id] : [])]
          : undefined,
        clinicRoomId: updates.room?.id,
        clinicPetId: updates.clinicPetId,
      };

      const appointmentContext = mergeWith(existingContext, updatedContext, (objValue, srcValue) => {
        if (Array.isArray(objValue) && Array.isArray(srcValue)) {
          return srcValue;
        }
      });

      try {
        await triggerMessageEvent({
          variables: {
            queueName: SqsQueueNames.ConditionEngineJobs,
            event: ConditionEngineRoutingKey.FindWorkflowsForContext,
            useSubscription: true,
            data: {
              clinicId: currentClinic.id,
              ...appointmentContext,
            },
          },
        });
      } catch (error) {
        console.log('Error triggering message event: ', error);
        Sentry.captureException(error);
      }
    },
    [currentClinic?.id, formState, selectedPetState, triggerMessageEvent],
  );

  const onSelectAppointmentType = useCallback(
    (_, appointmentTypeOption: IOption) => {
      setFormState((prevState) => {
        const updates = {
          appointmentType: appointmentTypeOption.value,
        };
        updateMatchingWorkflows(updates);
        return { ...prevState, ...updates };
      });
    },
    [updateMatchingWorkflows],
  );

  const onSelectRoom = useCallback(
    (_, roomOption: IOption) => {
      setFormState((prevState) => {
        const updates = {
          room: roomOption.value,
        };
        updateMatchingWorkflows(updates);
        return { ...prevState, ...updates };
      });
    },
    [updateMatchingWorkflows],
  );

  const onSelectProvider = useCallback(
    (_, assigneeOption: IOption) => {
      setFormState((prevState) => {
        const updates = {
          clinicEmployee: assigneeOption.value,
        };
        updateMatchingWorkflows(updates);
        return { ...prevState, ...updates };
      });
    },
    [updateMatchingWorkflows],
  );

  const handleAppointmentSubmit = useCallback(() => {
    setErrorMessage('');
    // Ensure a pet has been selected and set its ID
    if (!selectedPetState.clinicPet) {
      setSelectedPetState({ clinicPet: null, error: 'Please select a pet' });
      setErrorMessage('Please select a pet');
      return;
    }

    const values: FormValues = {
      clinicEmployeeId: formState.clinicEmployee?.id || '',
      roomId: formState.room?.id || '',
      petId: selectedPetState.clinicPet.id,
      appointmentTypeId: formState.appointmentType?.id || '',
      startAt: addMinutes(
        // Calculating the correct day and time from the combination of startAt (correct day) and startTime (correct hours & minutes)
        addHours(startOfDay(formState.startAt), formState.startTime.getHours()),
        formState.startTime.getMinutes(),
      ),
      petParentIds: [], // Will set this below in a conditional
      durationInMinutes: differenceInMinutes(formState.endTime, formState.startTime),
      description: formState.description,
      // Map selected workflow options to fit the { id: 'string' } shape needed for the createAppointment mutation
      workflows:
        formState.workflowOptions
          ?.filter((workflowOption) => workflowOption.isSelected)
          .map((workflowOption) => ({ id: workflowOption.value.id })) || [],
      clinicPetParents: formState.clinicPetParents,
      petSearch,
    };

    /**
     * Make sure at least one pet parent is selected as a contact to create this appointment.
     */
    if (!!values.clinicPetParents && !!Object.values(values.clinicPetParents).filter((val) => !!val)?.length) {
      values.petParentIds =
        Object.keys(values.clinicPetParents).filter((parentId) =>
          !!values.clinicPetParents ? values.clinicPetParents[parentId] : false,
        ) || [];
    } else {
      setErrorMessage('Please add at least one pet parent to contact for this appointment');
      return;
    }

    if (values.durationInMinutes <= 0) {
      setErrorMessage('Please enter a valid end time for this appointment.');
      return;
    }

    // Ensure a room resource has been selected for any PIMS outside of Ezyvet, Vetdata, and Datapoint
    if (
      primaryIntegrationType !== ClinicPimsIntegrationType.Ezyvet &&
      primaryIntegrationType !== ClinicPimsIntegrationType.Vetdata &&
      primaryIntegrationType !== ClinicPimsIntegrationType.Datapoint
    ) {
      if (!values.roomId) {
        setErrorMessage('Please add a room resource to this appointment.');
        return;
      }
    }

    // Add checks for EzVet required fields
    if (primaryIntegrationName === integrationSystemDisplayName.EZYVET && !values.clinicEmployeeId && !values.roomId) {
      setErrorMessage('Please select Provider or Room to continue.');
      return;
    }

    // Ensure an employee has been selected for any PIMS outside of Ezyvet
    if (
      !values.clinicEmployeeId &&
      primaryIntegrationName !== integrationSystemDisplayName.EZYVET &&
      primaryIntegrationName !== integrationSystemDisplayName.AVIMARK
    ) {
      setErrorMessage('Please add a provider to this appointment.');
      return;
    }

    onAppointmentSubmit(values);
  }, [
    selectedPetState.clinicPet,
    formState.clinicEmployee?.id,
    formState.room?.id,
    formState.appointmentType?.id,
    formState.startAt,
    formState.startTime,
    formState.endTime,
    formState.description,
    formState.workflowOptions,
    formState.clinicPetParents,
    petSearch,
    primaryIntegrationName,
    primaryIntegrationType,
    onAppointmentSubmit,
  ]);

  const handleNotesChange = (e: ChangeEvent<HTMLInputElement>): void => {
    e.persist();
    setFormState((prevState) => ({ ...prevState, description: e.target?.value }));
  };

  const renderWorkflowOptions = useCallback(() => {
    return (
      <Wrap pt={2}>
        {showAutomationsLoadingState ? (
          <>
            <WrapItem>
              <Skeleton h="23px" w="184px" />
            </WrapItem>
            <WrapItem>
              <Skeleton h="23px" w="160px" />
            </WrapItem>
          </>
        ) : (
          formState?.workflowOptions?.map((workflowOption) => {
            if (!workflowOption.isSelected) return;
            if (!workflowOption.value.isPublished) return;
            return (
              <TriggeringType key={workflowOption.value.id} data-testid="workflow-button">
                <StyledCircleCancelIcon
                  onClick={(e: React.MouseEvent): void => {
                    onSelectWorkflow(e, workflowOption);
                  }}
                />
                {workflowOption.text}
              </TriggeringType>
            );
          })
        )}
      </Wrap>
    );
  }, [showAutomationsLoadingState, formState?.workflowOptions, onSelectWorkflow]);

  return (
    <AppointmentFormContainer>
      <AppointmentFormHeader>
        <AppointmentFormTitle>{petId ? 'Update Appointment' : 'New Appointment'} </AppointmentFormTitle>
        <StyledCloseIcon onClick={(): void => onCancel()} />
      </AppointmentFormHeader>
      <form>
        <Row style={{ marginTop: 8 }}>
          <Column style={{ marginTop: 24 }}>
            Appointment Type:
            <Dropdown
              options={appointmentTypes}
              placement={PopoverPlacement.BottomEnd}
              onSelect={onSelectAppointmentType}
              closeOnClick
            >
              <FieldDiv data-mixpanel-name="Appointment type" data-testid="appointment-type-select">
                <FieldText>{formState.appointmentType?.name}</FieldText>
                <ChevronDownIcon />
              </FieldDiv>
            </Dropdown>
          </Column>
          <Column style={{ marginTop: 24 }}>
            Room:
            <Dropdown options={rooms} placement={PopoverPlacement.BottomEnd} onSelect={onSelectRoom} closeOnClick>
              <FieldDiv data-mixpanel-name="Appointment room" data-testid="appointment-room-select">
                <FieldText>{formState.room?.name}</FieldText>
                <ChevronDownIcon />
              </FieldDiv>
            </Dropdown>
          </Column>
        </Row>
        <Row style={{ marginBottom: 8 }}>
          <ColumnDiv>
            <Row style={{ alignItems: 'center', margin: 0 }}>
              Automations:
              <Dropdown
                options={
                  formState?.workflowOptions?.filter((workflowOptions) => workflowOptions.value.isPublished) || []
                }
                placement={PopoverPlacement.BottomEnd}
                onSelect={onSelectWorkflow}
                closeOnClick={false}
                isMultiSelect
                showSelectedFirst={false}
              >
                <AddItemButton>+ Add</AddItemButton>
              </Dropdown>
            </Row>
            {isMobile && renderWorkflowOptions()}
          </ColumnDiv>
          <ColumnDiv>
            <Row style={{ alignItems: 'center', margin: 0 }}>
              Provider:
              <Dropdown
                options={employees}
                placement={PopoverPlacement.BottomEnd}
                onSelect={onSelectProvider}
                closeOnClick
              >
                <FieldDiv data-mixpanel-name="Appointment assigned to" data-testid="assigned-to">
                  <FieldText>
                    {[formState.clinicEmployee?.firstName, formState.clinicEmployee?.lastName].join(' ')}
                  </FieldText>
                  <ChevronDownIcon />
                </FieldDiv>
              </Dropdown>
            </Row>
          </ColumnDiv>
        </Row>
        {!isMobile && renderWorkflowOptions()}
        <Row style={{ justifyContent: 'space-between', alignItems: 'baseline', margin: '16px 0 24px' }}>
          <div>Search for a client or patient:</div>
          <StyledAutoComplete
            name="petSearch"
            label=""
            items={sortBy(petSearchResults, (result) => result.name)}
            onSelect={onSelectPet}
            getItemValue={getItemValue}
            renderItem={renderItem}
            error={selectedPetState?.error} // This will be removed in a future iteration, as it is left over from React Hook Form
            onChange={(e: ChangeEvent<HTMLInputElement>): void => {
              setPetSearch(e.target.value.trimStart() || '');
            }}
            value={petSearch}
          />
        </Row>
        {selectedPetState?.clinicPet && (
          <PetResultContainer>
            <PetAvatar
              species={selectedPetState?.clinicPet?.species}
              name={selectedPetState?.clinicPet?.name}
              isDeceased={selectedPetState?.clinicPet?.computedIsDeceased}
              isActive={selectedPetState?.clinicPet?.computedIsActive}
              photoUrl={selectedPetState?.clinicPet?.photoUrl}
            />
            <PetSearchContent>
              <PetName>{selectedPetState?.clinicPet.name}</PetName>
              <PetInfo>{`${selectedPetState?.clinicPet.breed || 'Breed Not Available'}, ${
                selectedPetState?.clinicPet?.gender || 'Sex Not Available'
              }`}</PetInfo>
              {selectedPetState?.clinicPet?.petParents &&
                selectedPetState?.clinicPet?.petParents.map((petParent, index) => {
                  return <OwnerInfo key={index}>{`${petParent.firstName} ${petParent.lastName}`}</OwnerInfo>;
                })}
            </PetSearchContent>
            <Button iconName="close" variant="ghost" onClick={onRemovePet} />
          </PetResultContainer>
        )}
        {selectedPetState?.clinicPet?.petParents && (
          <ContactInformationContainer>
            <ContactInformation>Who would you like us to contact about this appointment?</ContactInformation>
            {selectedPetState?.clinicPet?.petParents?.map((petParent) => {
              return (
                <Checkbox
                  key={petParent.id}
                  name={`clinicPetParents.${petParent.id}`}
                  value={petParent.id}
                  checked={formState.clinicPetParents ? formState.clinicPetParents[petParent.id] : null}
                  defaultChecked={!formState.clinicPetParents}
                  label={`${petParent.firstName} ${petParent.lastName}`}
                  onClick={(): void =>
                    setFormState((prevState) => ({
                      ...prevState,
                      clinicPetParents: {
                        ...prevState.clinicPetParents,
                        [petParent.id]: prevState.clinicPetParents ? !prevState.clinicPetParents[petParent.id] : false,
                      },
                    }))
                  }
                />
              );
            })}
          </ContactInformationContainer>
        )}
        <Row>
          <DateDiv>
            <Label>Date:</Label>
            <Tooltip
              label={isNeoAppointmentWithPims ? 'All Neo appointment reschedules should be done in Neo' : ''}
              titleCaseLabel={false}
            >
              <Box>
                <DatePicker
                  startDate={formState.startAt}
                  dateFormat="MM/dd/yyyy"
                  onDateChange={({ startDate }: DateRange): void =>
                    setFormState((prevState) => ({ ...prevState, startAt: startDate || new Date() }))
                  }
                  inputProps={{
                    isDisabled: isNeoAppointmentWithPims,
                  }}
                  data-mixpanel-name="Appointment date"
                  data-testid="appointment-date-select"
                  minYear={new Date().getFullYear()}
                />
              </Box>
            </Tooltip>
          </DateDiv>
          <TimeDiv>
            <Label>Time:</Label>
            <Column>
              <Tooltip
                label={isNeoAppointmentWithPims ? 'All Neo appointment reschedules should be done in Neo' : ''}
                titleCaseLabel={false}
              >
                <Box>
                  <TimePicker
                    defaultDate={formState.startTime}
                    onDateChanged={onStartTimeChanged}
                    withTimeIcon={true}
                    minuteIncrement={clinicMinuteIncrement || undefined}
                    data-mixpanel-name="Appointment start time"
                    testId="appointment-start-time"
                    showNoOptions={isNeoAppointmentWithPims}
                    message="All Neo appointment reschedules should be done in Neo"
                  />
                </Box>
              </Tooltip>
              <ToSpan>to</ToSpan>
              <Tooltip
                label={isNeoAppointmentWithPims ? 'All Neo appointment reschedules should be done in Neo' : ''}
                titleCaseLabel={false}
              >
                <Box>
                  <TimePicker
                    defaultDate={formState.endTime}
                    onDateChanged={onEndTimeChanged}
                    withTimeIcon={true}
                    minuteIncrement={clinicMinuteIncrement || undefined}
                    data-mixpanel-name="Appointment end time"
                    testId="appointment-end-time"
                    showNoOptions={isNeoAppointmentWithPims}
                    message="All Neo appointment reschedules should be done in Neo"
                  />
                </Box>
              </Tooltip>
            </Column>
          </TimeDiv>
        </Row>
        <Label>Notes:</Label>
        <StyledTextArea name="description" label="" onChange={handleNotesChange} value={formState.description} />
        {/* This will be a string representing whichever error message most recently occurred in the last form submission attempt. */}
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        {shouldShowPimsWarning && (
          <PimsWarning>Changes to this appointment will not be reflected in {primaryIntegrationName}.</PimsWarning>
        )}
      </form>
      {/* The buttons were moved outside of the form element so it didn't trigger a page 
      refresh on submission before the mutation could be completed. */}
      <ActionsContainer>
        {appointmentId ? (
          <Button
            isDisabled={isLoading}
            onClick={onDelete}
            data-mixpanel-name="Delete appointment"
            data-testid="appointment-delete-button"
            variant="primaryDestructive"
          >
            Delete Appointment
          </Button>
        ) : (
          <Button
            isDisabled={isLoading}
            onClick={onCancel}
            data-mixpanel-name="Cancel appointment"
            data-testid="appointment-cancel-button"
            variant="tertiary"
          >
            Cancel
          </Button>
        )}
        <Button
          isLoading={isLoading}
          data-mixpanel-name={`${petId ? 'Update' : 'Create'} appointment"`}
          data-testid="appointment-submit-button"
          onClick={handleAppointmentSubmit}
        >
          {petId ? 'Update Appointment' : 'Create Appointment'}
        </Button>
      </ActionsContainer>
    </AppointmentFormContainer>
  );
};

export default AppointmentForm;

const AppointmentFormContainer = styled.div`
  min-height: 95vh;
  padding: 2em;
`;

const AppointmentFormHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AppointmentFormTitle = styled.h2`
  color: #000b8e;
  font-size: 18px;
  font-weight: 600;
`;

const PetSearchMenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const PetResultContainer = styled(PetSearchMenuContainer)`
  border: 2px solid #e6f4f6;
  border-radius: 8px;
  margin: 1em 0 1em 0;
  padding: 1em;
`;

const PetSearchContent = styled.div`
  align-content: right;
  flex-basis: 80%;
`;

const PetName = styled.h3``;

const PetInfo = styled.p``;

const OwnerInfo = styled.p``;

const ContactInformation = styled.p`
  padding: 0em 0 0.5em 0;
`;

const ContactInformationContainer = styled.div`
  padding: 0.5em 0 0.5em 0;
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const ErrorMessage = styled.p`
  color: #ff3e28;
  margin: 10px 0 10px 0;
`;

const TimeDiv = styled.div``;

const DateDiv = styled.div``;

const ToSpan = styled.div`
  margin: 0 20px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 24px;
  flex-wrap: wrap;
`;

const Column = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const ColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Label = styled.div`
  margin-bottom: 12px;
`;

const FieldDiv = styled.div`
  width: 140px;
  height: 32px;
  margin-left: 8px;
  border-radius: 7px;
  border: solid 1px #d2d2d2;
  padding: 12px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AddItemButton = styled.div`
  cursor: pointer;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  border-radius: 7px;
  border: dashed 1px #d2d2d2;
  margin-left: 8px;
`;

const StyledCloseIcon = styled(CloseIcon)`
  height: 12px;
  width: 12px;
  cursor: pointer;
`;

const FieldText = styled.div`
  text-overflow: ellipsis;
  width: 120px;
  overflow: hidden;
  white-space: nowrap;
`;

const ChevronDownIcon = styled(ChevronIcon)`
  transform: rotate(-90deg);
`;

const TriggeringType = styled.div`
  margin: 8px 10px 0px 0px;
  padding: 3px 12px 5px 8px;
  border-radius: 7px;
  background-color: #eeeff1;

  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #060a3a;
  display: flex;
  align-items: center;
`;

const StyledCircleCancelIcon = styled(CircleCancelIcon)`
  cursor: pointer;
  margin-right: 5px;
`;

const StyledAutoComplete = styled(AutoComplete)`
  min-width: 270px;
  margin-left: 8px;
  padding: 0px 16px !important;
`;

const StyledTextArea = styled(TextArea)`
  border-radius: 7px;
`;

const PimsWarning = styled.div`
  color: #ff3e28;
  margin-bottom: 10px;
  text-align: center;
`;
