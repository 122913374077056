import React, { useCallback, useState } from 'react';
import { Box, Card, HStack } from '@televet/kibble-ui';
import { ChannelListChannelFragment, ChannelViewChannelMemberFragment } from 'shared/types/graphql';
import { Placement } from 'shared/enums/Placement';
import ChannelStatusSelect from 'shared/components/ChannelStatusSelect/ChannelStatusSelect';
import { useSidePanel } from 'shared/components/SidePanel/state/providers/SidePanelProvider';
import { useSidePanelSearch } from 'shared/components/SidePanel/components/SearchPanel/state/providers/SearchPanelProvider';
import { Mixpanel } from 'shared/utils/mixpanel';
import { PanelViews } from 'shared/components/SidePanel/shared/types/enums/PanelViews';
import DevModeDisplayString from 'pages/Settings/components/DevModeDisplayString';
import { getChannelParticipants } from 'pages/Conversations/utils';
import CardButtons from './CardButtons';
import CardHeader from './CardHeader';
import CardMessage from './CardMessage';
import CardTags from './CardTags';
import useClinicUser from 'shared/hooks/useClinicUser';

interface IProps {
  channel: ChannelListChannelFragment;
  isCurrentChannel: boolean;
  isLoadingNextPage: boolean;
  hasUnreadMessages: boolean;
  onChannelSelect(channelId: string): void;
  isChannelPinned?: boolean;
}

const ChannelListItem = ({
  channel,
  isCurrentChannel,
  isLoadingNextPage,
  hasUnreadMessages,
  onChannelSelect,
  isChannelPinned = false,
}: IProps): JSX.Element => {
  const { viewClinicPetParent } = useSidePanelSearch();
  const { currentView } = useSidePanel();
  const { clinicUserId } = useClinicUser();
  const { channelPetParents, channelPetParentIds } = getChannelParticipants(
    channel.channelMembers as ChannelViewChannelMemberFragment[],
  );
  const [isHovering, setIsHovering] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleChannelSelect = useCallback(() => {
    const [clinicPetParentId] = channelPetParentIds;
    onChannelSelect(channel.id);

    if (clinicPetParentId && currentView !== PanelViews.Invoicing) {
      viewClinicPetParent({ clinicPetParentId, forceOpen: false });
    }
  }, [channel.id, channelPetParentIds, currentView, onChannelSelect, viewClinicPetParent]);

  return (
    <Card
      className="ChannelListItem__Card"
      id="channel-list-item"
      data-testid="channel-list-item"
      isInteractive
      isActive={isCurrentChannel}
      size="sm"
      onClick={handleChannelSelect}
      bg={
        hasUnreadMessages || isCurrentChannel || isHovering ? 'background.default' : 'background.alphaContrast.pressed'
      }
      sx={{ overflowAnchor: isLoadingNextPage ? 'auto' : 'none' }}
      onMouseEnter={(): void => setIsHovering(true)}
      onMouseLeave={(): void => setIsHovering(false)}
      pos="relative"
    >
      {(isHovering || isMenuOpen) && (
        <Box
          className="CardButtons__Container"
          pos="absolute"
          top={2}
          zIndex="banner"
          right={2}
          bg="background.default"
          borderRadius="md"
          onClick={(e): void => e.stopPropagation()}
        >
          <CardButtons
            userId={clinicUserId || ''}
            channelId={channel.id}
            channelPetParents={channelPetParents}
            setIsMenuOpen={setIsMenuOpen}
            isChannelPinned={isChannelPinned}
            setIsHovering={setIsHovering}
          />
        </Box>
      )}
      <CardHeader
        channelPets={channel.pets}
        channelPetParents={channelPetParents}
        channelUpdatedAtDate={channel.updatedAt}
        lastMessageCreatedAtDate={channel.lastMessage?.createdAt}
        hasUnreadMessages={hasUnreadMessages}
        isHoveringCard={isHovering}
        isChannelPinned={isChannelPinned}
      />

      <HStack className="ChannelListItem__ConversationDetails" mt={3} justify="space-between" align="center">
        {channel.lastMessage && <CardMessage lastChannelMessage={channel.lastMessage} />}
        {channel.channelStatus && (
          <Box
            className="ChannelListItem__ChannelStatus"
            onClick={(): void => Mixpanel.track('Conversation Status Clicked - Conversation Card')}
            marginLeft="auto"
          >
            <ChannelStatusSelect
              currentStatus={channel.channelStatus}
              channelId={channel.id}
              placement={Placement.BottomEnd}
              showRightIcon={false}
            />
          </Box>
        )}
      </HStack>
      {!!channel.tags.length && <CardTags tags={channel.tags} channelId={channel.id} />}
      <DevModeDisplayString src={`src : ${channel.creationSource?.creationSource}`} />
    </Card>
  );
};

export default React.memo(ChannelListItem);
