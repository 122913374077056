import styled from 'styled-components/macro';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import { MessageTypes, useSnackbar } from '@televet/televet-ui';
import { ChevronIcon, CloseIcon, SearchIcon } from 'assets/icons';
import { captureException } from '@sentry/react';
import Dropdown, { IOption, PopoverPlacement } from 'shared/components/Dropdown';
import {
  InvoicePetParentFragment,
  ClinicPetWhereInput,
  ClinicUserClinicFragment,
  ExistingClinicPetParentMatchFragment,
  LoginLinkOutput,
  PaymentMedium,
  SearchClinicPetParentInput,
  StripeCreateAccountOutput,
  StripePaymentIntentStatus,
  StripePaymentMethodType,
  StripePaymentOutputFragment,
  useCancelStripePaymentIntentMutation,
  useClearTerminalDisplayMutation,
  useCreateStripePaymentIntentMutation,
  useGetInvoicePetParentsLazyQuery,
  useGetPaymentIntentStatusQuery,
  useGetStripePaymentIntentQuery,
  useProcessTerminalPaymentMutation,
  useSubscribeToStripePaymentIntentChangedSubscription,
  PaymentRelationship,
  InvoiceForClientFragment,
  CardFunding,
} from 'shared/types/graphql';
import useClinicUser from 'shared/hooks/useClinicUser';
import { GraphQLFetchPolicies } from 'shared/enums/GraphQLFetchPolicies';
import InvoiceStatusMessage from './components/InvoiceStatusMessage';
import ClientSearchResult from 'shared/components/SidePanel/shared/components/ClientSearchResult';
import ImportantNoticeModal from 'shared/components/ImportantNoticeModal';
import AddClientPatientForm, { ExistingClient } from '../../shared/components/AddClientPatientForm';
import TruncatedPetList from 'shared/components/TruncatedPetList';
import useDebounce from 'shared/hooks/useDebounce';
import { ConnectionStatus, InvoiceStatus, useStripeTerminalProvider } from 'shared/providers/StripeTerminalProvider';
import { useSidePanelSearch } from 'shared/components/SidePanel/components/SearchPanel/state/providers/SearchPanelProvider';
import { useSidePanel } from '../../state/providers/SidePanelProvider';
import { email } from 'shared/utils/validation';
import { Mixpanel, useMixpanelTracking } from 'shared/utils/mixpanel';
import { PersistedStateKeys } from 'shared/enums/PersistedStateKeys';
import usePersistedState from 'shared/hooks/usePersistedState';
import { StripeCardElementChangeEvent, StripeElement } from '@stripe/stripe-js';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { DeviceSizes } from 'shared/enums/DeviceSizes';
import { MenuTitle } from 'pages/MobileMenu';
import { MobileMenuViews, useMobileMenuProvider } from 'pages/MobileMenu/MobileMenuProvider';
import Carousel from './components/Carousel';
import SuggestedOption from '../../shared/components/SuggestedOption';
import { useResolutionProvider } from 'shared/providers/ResolutionProvider';
import DiscoveredInvoices from './components/DiscoveredInvoices';
import HasFeature from 'shared/components/HasFeature';
import { FeatureFlagName } from 'shared/enums/FeatureFlagName';
import { usePopover } from 'shared/providers/PopoverWindowProvider';
import { IPopover } from 'shared/providers/PopoverWindowProvider/interfaces/IPopover';
import { PopoverNames } from 'shared/enums/PopoverNames';
import SavedPaymentMethods from './components/SavedPaymentMethods';
import useFeatureFlag from 'shared/hooks/useFeatureFlag';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  Text,
  VStack,
  Link,
  useDisclosure,
  Icon,
  Spinner,
  Tooltip,
  Divider,
  HStack,
} from '@televet/kibble-ui';
import { BenefitAlertSource } from 'pages/Conversations/components/ChannelView/MessageComposer/BenefitAlert';
import sortBy from 'lodash-es/sortBy';
import { CareBenefitsBanner } from 'shared/components/CareBenefitsBanner/CareBenefitsBanner';
import { usePets } from 'shared/components/SidePanel/components/SearchPanel/components/Benefits/hooks/usePets';
import { STRIPE_CONNECT_CONNECTED_ACCOUNT } from 'pages/Settings/graphql/mutations';
import { STRIPE_CREATE_LOGIN_LINK, STRIPE_RETRIEVE_CONNECTED_ACCOUNT } from 'pages/Settings/graphql/queries';
import { RouteBasePaths, SettingsRoutes } from 'routes';
import usePaymentWritebackConfig from 'shared/hooks/usePaymentWritebackConfig';
import TrupanionCheckout from './components/TrupanionCheckout/TrupanionCheckout';
import useGetBenefitLineItemDiscount from 'pages/Conversations/hooks/useGetBenefitLineItemDiscounts';
import { Link as ReactRouterLink } from 'react-router-dom';
import { useBitwerxData, useIntegrationsProvider } from 'shared/providers/IntegrationsProvider';
import { getInvoiceAmountDue } from './utils/InvoicingPanelFunctions';
import { InfoIcon } from 'shared/components/InfoIcon';
import ErrorInstructionComponent from '../SearchPanel/components/Benefits/components/CareWizardModal/PaymentHelperComponents/ErrorInstruction';
import { STRIPE_ERROR_READABLE } from './constants/StripeErrorReadable';
import { CreditCardFields } from '../SearchPanel/components/Benefits/components/CareWizardModal/utils/paymentEnumsAndVariables';
import FieldTitle from './components/FieldTitle';
import { getBalanceTerminology, getInvoiceIdentifierCopy, getMonetaryDisplayValue } from 'shared/utils';
import { UFClinicsToExclude } from 'pages/Forms/utils';
import { cardFundingTypeConverter } from './utils/cardFundingTypeConverter';
import FundingTypeTag from './components/FundingTypeTag';

const maxInputAmount = 999999.99;

// Our set of fields for credit card input
const defaultCardValidationState = {
  cardNumber: false,
  cardExpiry: false,
  cardCvc: false,
  card: false, // If we decide to just go with the single input field (preferred)
};

/**
 *
 * This file is a bit of a mess for several reasons... but we're going to come back to it after refactoring how the side panel works in general
 */

interface IInvoicingPanelProps {
  isInMobileMenu?: boolean;
}

const InvoicingPanel = ({ isInMobileMenu }: IInvoicingPanelProps): JSX.Element => {
  const { addSnackbar } = useSnackbar();
  const { currentClinicId, currentClinic: currentProviderClinic, hasAdminRoleAtCurrentClinic } = useClinicUser();
  const ref = useRef<HTMLDivElement | null>(null);
  const { setIsOpen, isOpen: isSidePanelOpen, setIsInvoicingPanelDirty } = useSidePanel();
  const [isSearching, setIsSearching] = useState(false);
  const [isEditingFee, setIsEditingFee] = useState(false);
  const [isClientFeeCheckboxChecked, setIsClientFeeCheckboxChecked] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [clientSearchValue, setClientSearchValue] = useState('');
  const [isAddingPetParent, setIsAddingPetParent] = useState(false);
  const [stripePaymentIntentId, setStripePaymentIntentId] = useState<string>();
  const [confirmPath, setConfirmPath] = useState<string>();
  const [elementToClick, setElementToClick] = useState<Element>();
  const [manualAmount, setManualAmount] = useState('');
  const [selectedInvoices, setSelectedInvoices] = useState<InvoiceForClientFragment[]>([]);
  const [selectedBalanceAmount, setSelectedBalanceAmount] = useState<number>(0);
  const [selectedSavedPaymentMethodStripeId, setSelectedSavedPaymentMethodStripeId] = useState<string | null>(null);
  const { viewClinicPetParent, viewClinicPet, viewAddClientOrPatient, setViewBenefitSource } = useSidePanelSearch();
  const [currentCarouselStep, setCurrentCarouselStep] = useState<number>(1);
  const { popover } = usePopover();
  const [writebackConfigurationAlertDismissed, setWritebackConfigurationAlertDismissed] = usePersistedState(
    PersistedStateKeys.WritebackConfigurationAlertDismissed,
    false,
  );
  const [isDiscoveredInvoiceDemoModeEnabled] = usePersistedState(
    PersistedStateKeys.IsDiscoveredInvoiceDemoModeEnabled,
    false,
  );
  const { setRemoveInvoiceAttachment } = useSidePanelSearch();
  const [isSyncing, setSyncingState] = useState(false);
  const [isCardSetForSaving, setIsCardSetForSaving] = useState(false);
  const [fundingType, setFundingType] = useState<CardFunding | undefined>(undefined);
  const { isMobile, isTouchDevice } = useResolutionProvider();
  const { onClose: onModalClose, onOpen: onModalOpen, isOpen: isModalOpen } = useDisclosure();
  const {
    primaryIntegrationName,
    isTriggerPimsSyncSupported,
    isInvoicePreviewSupported,
    isInvoiceDiscoverySupported,
    isClientIdSupported,
  } = useIntegrationsProvider();
  const { needsBitwerxForPaymentWritebacks } = useBitwerxData();
  const { isWritebackManualPaymentEnabled } = usePaymentWritebackConfig();

  const { track, events } = useMixpanelTracking('Invoicing');

  const { isFeatureEnabled } = useFeatureFlag();

  // Credit card payment validation message
  const [validationMessage, setValidationMessage] = useState('');
  const [showClientFeeAlert, setShowClientFeeAlert] = useState(false);

  // Retrieve Stripe input elements
  const elements = useElements();

  // Keep track of credit card validation state
  const [isValid, setIsValid] = useState(defaultCardValidationState);

  // Track whether or not the invoice is in progress, so we can make sure the user doesn't try to cancel it before it reaches the terminal
  const [isTransactionLoading, setIsTransactionLoading] = useState(false);

  const [receiptEmail, setReceiptEmail] = useState<string>();

  const stripe = useStripe();

  const {
    isComplete: writebackConfigComplete,
    isValid: writebackConfigValid,
    isClinicWritebackEnabled,
    isLoading: writebackConfigLoading,
  } = usePaymentWritebackConfig();

  // Retrieve Stripe information and functionality for use in transactions
  const {
    terminal,
    getErrorMessage,
    terminalOptions,
    isInitialized,
    disconnectReader,
    createTerminal,
    resetTerminalList,
    currentInvoiceStatus,
    setCurrentInvoiceStatus,
    selectedPetParent,
    suggestedPetParentOptions,
    setSuggestedPetParentOptions,
    setSelectedPetParent,
    currentPaymentMedium,
    paymentMediumOptions,
    setCurrentPaymentMedium,
    amountToInvoice,
    setAmountToInvoice,
    setInvoiceIds,
    invoiceIds,
    paymentRelationship,
    setPaymentRelationship,
    setIsBalancePreSelected,
  } = useStripeTerminalProvider();

  const isPimsPetParent = useMemo(() => !!selectedPetParent?.pimsId, [selectedPetParent?.pimsId]);

  const showWritebackEnabledStatus = useMemo(
    () => parseFloat(manualAmount) > 0 && isPimsPetParent && isClinicWritebackEnabled,
    [manualAmount, isPimsPetParent, isClinicWritebackEnabled],
  );

  const balanceLabel = useMemo(() => getBalanceTerminology(primaryIntegrationName), [primaryIntegrationName]);

  const discoveredAmount = useMemo(() => {
    const invoiceAmount = selectedInvoices.map(({ total }) => total).reduce((prev, curr) => prev + curr, 0);
    return invoiceAmount + selectedBalanceAmount;
  }, [selectedBalanceAmount, selectedInvoices]);

  // reset manual amount if writebackManualPayment gets disabled
  useEffect(() => {
    if (!isWritebackManualPaymentEnabled && discoveredAmount && parseFloat(manualAmount) > 0) {
      setManualAmount('');
    }
  }, [discoveredAmount, manualAmount, isWritebackManualPaymentEnabled]);

  const clinicPetParentId = useMemo(() => selectedPetParent?.id || '', [selectedPetParent]);
  const { pets } = usePets(clinicPetParentId);
  const enrolledPets = useMemo(() => pets.filter((pet) => !!pet.organizationPet?.carePlanEnrollments.length), [pets]);
  //TODO: add enrolledPets as variable to usePets hook
  // show alert if writeback config becomes invalid (i.e. selected employee/payment type gets deleted in PIMS)
  useEffect(() => {
    if (!writebackConfigValid) {
      setWritebackConfigurationAlertDismissed(false);
    }
  }, [setWritebackConfigurationAlertDismissed, writebackConfigValid]);

  const showWritebackConfigurationAlert = useMemo(() => {
    // 1. is not on mobile since settings page is not exposed on mobile
    // 2. alert has not been dismissed
    // 3. needs bitwerx OR has bitwerx but writeback config is incomplete OR writeback config is invalid
    // 4. clinic PIMS can support writebacks
    // 5. writeback config is not still loading
    return (
      !isMobile &&
      !writebackConfigurationAlertDismissed &&
      (needsBitwerxForPaymentWritebacks || !writebackConfigComplete || !writebackConfigValid) &&
      isClinicWritebackEnabled &&
      !writebackConfigLoading
    );
  }, [
    writebackConfigComplete,
    writebackConfigValid,
    writebackConfigurationAlertDismissed,
    isMobile,
    needsBitwerxForPaymentWritebacks,
    isClinicWritebackEnabled,
    writebackConfigLoading,
  ]);

  const writebackConfigAlertDescription = useMemo(() => {
    return hasAdminRoleAtCurrentClinic
      ? `Configure how payments collected through Flow write back to ${primaryIntegrationName}.`
      : 'Please contact an administrator to fix invalid payments configuration for writebacks to succeed.';
  }, [hasAdminRoleAtCurrentClinic, primaryIntegrationName]);

  // Subscribe to payment intent changed events.
  useSubscribeToStripePaymentIntentChangedSubscription({
    fetchPolicy: GraphQLFetchPolicies.NoCache,
    variables: { where: { node: { id: stripePaymentIntentId } } },
    skip: !stripePaymentIntentId || currentPaymentMedium !== PaymentMedium.StripeTerminal,
    onData: ({ data }) => {
      const { status, lastPaymentError } = data.data?.stripePaymentIntentChanged?.node ?? {};

      if (status === StripePaymentIntentStatus.Succeeded || status === StripePaymentIntentStatus.RequiresCapture) {
        Mixpanel.track('Terminal invoice successfully sent', {
          integrationType: 'ServerSideTerminal',
        });
        setReceiptEmail('');
        setCurrentInvoiceStatus(InvoiceStatus.Received);
      } else if (lastPaymentError) {
        Mixpanel.track('Terminal invoice payment failed', {
          integrationType: 'ServerSideTerminal',
          error: STRIPE_ERROR_READABLE[lastPaymentError],
        });
        setCurrentInvoiceStatus(InvoiceStatus.Error);
        setErrorMessage(STRIPE_ERROR_READABLE[lastPaymentError]);
      }
    },
  });

  const { setCurrentMobileMenuView } = useMobileMenuProvider();

  // The selected terminal for a terminal transaction.
  const [terminalOption, setTerminalOption] = useState<IOption | undefined>(
    terminalOptions?.filter(({ value }) => value?.status === 'online')?.length ? terminalOptions?.[0] : undefined,
  );

  const [lastSelectedTerminalId, setLastSelectedTerminalId] = usePersistedState<string>(
    PersistedStateKeys.LastSelectedTerminalId,
    '',
  );

  // Check for the last terminal clicked and if it's online,
  // if not and there is only one terminal location, and it is online select it.
  useEffect(() => {
    if (lastSelectedTerminalId) {
      const lastSelectedTerminal = terminalOptions?.find(
        (terminalOption) => terminalOption?.value.id === lastSelectedTerminalId,
      );
      if (lastSelectedTerminal?.value?.status === 'online') setTerminalOption(lastSelectedTerminal);
    } else if (terminalOptions?.length) {
      const firstTerminal = terminalOptions?.find((terminalOption) => terminalOption?.value?.status === 'online');
      if (firstTerminal) {
        setTerminalOption(firstTerminal);
      }
    }
  }, [lastSelectedTerminalId, terminalOptions]);

  const clearCardElements = useCallback(() => {
    if (!elements) {
      return;
    }

    elements.getElement(CreditCardFields.Card)?.clear();
  }, [elements]);

  // Clear all fields in the form.
  const resetPanelState = useCallback(
    (isCanceling?: boolean, isError?: boolean) => {
      if (!isError) {
        if (!isCanceling) {
          setCurrentInvoiceStatus(InvoiceStatus.Default);
        } else if (stripePaymentIntentId && !confirmPath) {
          // only show canceled message if intent was actually created and user is not navigating away from invoicing panel
          setCurrentInvoiceStatus(InvoiceStatus.Canceled);
        }
        setSuggestedPetParentOptions([]);
        setSelectedPetParent(undefined);
        setAmountToInvoice('');
        setManualAmount('');
        setReceiptEmail('');
        setInvoiceIds([]);
        setPaymentRelationship(null);

        // Credit-card-related
        setIsValid(defaultCardValidationState);
        clearCardElements();
      }

      setIsInvoicingPanelDirty(false);

      setClientSearchValue('');

      setStripePaymentIntentId('');
      setIsAddingPetParent(false);
      setIsTransactionLoading(false);
      setIsSearching(false);
      setIsEditingFee(false);
      setConfirmPath('');

      // Credit-card-related
      setValidationMessage('');
      setErrorMessage('');
    },
    [
      setAmountToInvoice,
      setCurrentInvoiceStatus,
      setInvoiceIds,
      setPaymentRelationship,
      setSelectedPetParent,
      setSuggestedPetParentOptions,
      clearCardElements,
      stripePaymentIntentId,
      confirmPath,
      setIsInvoicingPanelDirty,
    ],
  );

  // reset if user navigates away from invoicing panel without dismissing success or cancelation message
  useEffect(() => {
    return (): void => {
      if (
        [InvoiceStatus.CardSuccessful, InvoiceStatus.Received, InvoiceStatus.Canceled].includes(currentInvoiceStatus)
      ) {
        resetPanelState();
      }
    };
  }, [currentInvoiceStatus, resetPanelState]);

  // The clinic that the transaction is for
  const currentClinic: ClinicUserClinicFragment | null | undefined = useMemo(() => {
    // only reset if clinic actually changes
    if (currentClinicId !== currentProviderClinic?.id) {
      if (isSidePanelOpen) {
        resetPanelState();
      }
      if (!isSidePanelOpen) {
        setCurrentPaymentMedium(PaymentMedium.StripeVirtualTerminal);
      }
      resetTerminalList();
    }
    return currentProviderClinic;
  }, [
    isSidePanelOpen,
    currentClinicId,
    currentProviderClinic,
    resetPanelState,
    setCurrentPaymentMedium,
    resetTerminalList,
  ]);

  /**
   * The terminals/backends aren't quite ready to use the service fee percent breakdown
   */

  const hasCustomizableFees = useMemo(
    () => !!currentClinic?.clinicSetting?.hasCustomizableFees,
    [currentClinic?.clinicSetting?.hasCustomizableFees],
  );
  const [clientServiceFeePercent, setClientServiceFeePercent] = useState<string | undefined>(undefined);
  

  useEffect(() => {
    if (
      !hasCustomizableFees ||
      isFeatureEnabled(FeatureFlagName.TerminalAppOnlySurchargeUpdates) ||
      hasCustomizableFees && isFeatureEnabled(FeatureFlagName.SurchargeUpdates) && currentPaymentMedium === PaymentMedium.StripeTerminal
    ) {
      setClientServiceFeePercent('0');
      return;
    }
    setClientServiceFeePercent(
      currentPaymentMedium === PaymentMedium.StripeTerminal
        ? currentClinic?.clinicSetting?.paymentFeeConfig?.terminalClientServiceFeePercent
          ? (currentClinic?.clinicSetting?.paymentFeeConfig?.terminalClientServiceFeePercent * 100).toFixed(2)
          : undefined
        : currentClinic?.clinicSetting?.paymentFeeConfig?.onlineClientServiceFeePercent
        ? (currentClinic?.clinicSetting?.paymentFeeConfig?.onlineClientServiceFeePercent * 100).toFixed(2)
        : undefined,
    );
  }, [
    hasCustomizableFees,
    currentClinic?.clinicSetting?.paymentFeeConfig?.onlineClientServiceFeePercent,
    currentClinic?.clinicSetting?.paymentFeeConfig?.terminalClientServiceFeePercent,
    currentPaymentMedium,
    isFeatureEnabled,
  ]);

  const clientServiceFeeMax = 100;

  const [debouncedClientSearchValue] = useDebounce(clientSearchValue, 350);

  // Check if user is working in the invoice panel
  const isInDraftProcess = useMemo(
    () =>
      (isSearching || clientSearchValue || parseFloat(amountToInvoice) || selectedPetParent || receiptEmail) &&
      currentInvoiceStatus === InvoiceStatus.Default,
    [amountToInvoice, clientSearchValue, currentInvoiceStatus, isSearching, receiptEmail, selectedPetParent],
  );

  useEffect(() => {
    const pendingStatuses = [InvoiceStatus.CardProcessing, InvoiceStatus.Pending, InvoiceStatus.Sending];

    if (isInDraftProcess || pendingStatuses.includes(currentInvoiceStatus)) {
      setIsInvoicingPanelDirty(true);
    } else {
      setIsInvoicingPanelDirty(false);
    }
  }, [currentInvoiceStatus, isInDraftProcess, setIsInvoicingPanelDirty]);

  /**
   * Check to see if user tries to click on another side panel icon while the invoice is pending
   */
  useEffect(() => {
    const handleClick = (e: MouseEvent): void => {
      // Track clicks that would close the side panel so we can alert the user not to leave the panel if the transaction is pending

      // Skip if it's not in draft progress or pending payment
      if (!isInDraftProcess && currentInvoiceStatus !== InvoiceStatus.Pending) return;

      const pathList = e.composedPath();

      if (
        pathList.some((element) => {
          const elementClass = (element as Element).className;
          if (!elementClass) return elementClass;
          switch ((element as Element).id) {
            case 'sidepanel-search-button':
            case 'sidepanel-scheduler-button':
            case 'dropdown-option-invoice':
            case 'invoice-client-name':
            case 'right-panel-patient-search-icon':
            case 'mobile-navbar-more-menu-icon':
            case 'channel-list-item':
              setConfirmPath('default');
              setElementToClick(element as Element);
              break;
            case 'sidepanel-toggle-button':
            case 'sidepanel-invoice-button':
              setConfirmPath('toggle-sidepanel');
              setElementToClick(element as Element);
              break;
            case 'mobile-invoice-back-button':
              setConfirmPath('mobile-invoice-back-button');
              break;
            default:
              break;
          }

          // show alert if clicking on another side panel button
          // of if navigating while on mobile side panel
          // with invoice panel dirtied
          const sidePanelButtonIds = [
            'sidepanel-toggle-button',
            'sidepanel-scheduler-button',
            'sidepanel-search-button',
            'sidepanel-invoice-button',
            'sidepanel-mass-text-alert-button',
          ];

          const navigationButtonIds = [
            'appointment-nav-button',
            'conversations-nav-button',
            'team-nav-button',
            'right-panel-patient-search-icon',
            'mobile-navbar-more-menu-icon',
          ];

          const profileRedirectClassNames = [
            'Avatar__AvatarIconContainer',
            'PetAvatar_Label',
            'PaymentReport_PetParentLink',
          ];

          const elementId = (element as Element).id || '';
          const className = (element as Element).className || '';

          // handle profile redirect clicks
          if (className.includes && profileRedirectClassNames.some((name) => className.includes(name))) {
            setConfirmPath('default');
            setElementToClick(element as Element);
            return true;
          }

          return sidePanelButtonIds.includes(elementId) || (isMobile && navigationButtonIds.includes(elementId));
        }) &&
        !isModalOpen
      ) {
        e.preventDefault();
        onModalOpen();
      }
    };
    document.addEventListener('mousedown', handleClick);

    return (): void => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [
    ref,
    isMobile,
    isModalOpen,
    onModalOpen,
    setIsOpen,
    isSearching,
    receiptEmail,
    terminalOption,
    amountToInvoice,
    selectedPetParent,
    clientSearchValue,
    currentInvoiceStatus,
    isInDraftProcess,
  ]);
  //Determine if clinic has PimsSync enabled
  const [triggerSync, setTriggerSyncState] = useState(false);

  const shouldShowPimsSync = isTriggerPimsSyncSupported;
  const clientSearchResultProps = {
    messageText: shouldShowPimsSync ? '' : 'Unfortunately, we were unable to find this client in our system.',
    callToActionText: `${shouldShowPimsSync ? 'a' : 'A'}dd a new client`,
  };
  const syncEnabledProps = shouldShowPimsSync
    ? {
        triggerSearch: (): void => setSyncingState(!isSyncing),
        secondaryCallToAction: (
          <Text size="sm">
            Try
            <Text
              variant="interactive"
              textDecoration="underline"
              cursor="pointer"
              onClick={(): void => {
                setTriggerSyncState(true);
              }}
            >
              refreshing
            </Text>
            to get new clients from {primaryIntegrationName} or{' '}
          </Text>
        ),
        shouldSync: triggerSync,
        resetSyncState: (): void => setTriggerSyncState(false),
      }
    : {};

  useGetBenefitLineItemDiscount({
    invoiceIds: invoiceIds,
    clinicPetParentId: selectedPetParent?.id || '',
    shouldCheckForBenefits: isInvoicePreviewSupported && isFeatureEnabled(FeatureFlagName.CarePlans),
  });

  // combine manual amount and discovered (invoice/balance) amount
  useEffect(() => {
    const totalAmount = discoveredAmount / 100 + (parseFloat(manualAmount) || 0);
    setAmountToInvoice(totalAmount.toFixed(2));
  }, [discoveredAmount, manualAmount, setAmountToInvoice]);

  const clientServiceFee = useMemo(
    () =>
      amountToInvoice && clientServiceFeePercent
        ? parseFloat(((parseFloat(clientServiceFeePercent) * parseFloat(amountToInvoice)) / 100).toFixed(2))
        : 0,
    [amountToInvoice, clientServiceFeePercent],
  );

  const cashDiscountAmount = useMemo(() => {
    if (
      hasCustomizableFees &&
      fundingType !== CardFunding.Credit &&
      isFeatureEnabled(FeatureFlagName.SurchargeUpdates) &&
      currentPaymentMedium !== PaymentMedium.StripeTerminal
    ) {
      return clientServiceFee;
    }
    return 0;
  }, [fundingType, clientServiceFee, hasCustomizableFees, isFeatureEnabled, currentPaymentMedium]);

  /**
   * The amount the client must pay, not including the clinic's fees
   * uses the imported getInvoiceAmountDue function defined in utils
   */
  const amountDue = useMemo(() => {
    return getInvoiceAmountDue({ amountToInvoice, clientServiceFee });
  }, [amountToInvoice, clientServiceFee]);

  /**
   * Component for future dynamic tooltip
   */
  const clientServiceFeeMessage =
    'The Client Service Fee is an additional charge added to the amount you want to collect from a client.';

  const selectTerminalMessage =
    'Please make sure your terminal is turned on and connected to the correct WiFi network. It may take up to 5 minutes for changes in the connection status of your terminal to appear';

  // Show specific message for hovering over submit button
  const submitButtonTooltipMessage: string = useMemo(() => {
    if (currentPaymentMedium) {
      if (currentPaymentMedium === PaymentMedium.StripeTerminal) {
        if (amountDue && terminalOption && selectedPetParent) return 'Send invoice to terminal';
        else return 'Please enter an amount above $0.50 and select a terminal and client for this invoice.'; // Can separate these out in the future
      } else if (currentPaymentMedium === PaymentMedium.StripeVirtualTerminal) {
        if (isValid && amountDue && selectedPetParent) return 'Send credit/debit card invoice';
        else
          return 'Please enter an amount above $0.50, fill out the payment details, and select a client for this invoice.'; // Can separate these out in the future
      } else return '';
    } else {
      return 'Please select a payment method type.';
    }
  }, [currentPaymentMedium, amountDue, terminalOption, selectedPetParent, isValid]);

  const client = useApolloClient();

  const [createStripeAccount, { loading: creatingStripeAccount }] = useMutation<{
    stripeCreateConnectedAccount: StripeCreateAccountOutput;
  }>(STRIPE_CONNECT_CONNECTED_ACCOUNT);

  const [createStripePaymentIntent, { loading: isPaymentIntentLoading }] = useCreateStripePaymentIntentMutation();

  // Cancel the created Stripe payment intent in the event of user cancellation or an error
  const [cancelStripePaymentIntent, { loading: cancelingIntent }] = useCancelStripePaymentIntentMutation({
    onError: (error) => {
      console.error('Error canceling payment intent: ', error);
      addSnackbar({
        message:
          'An error occurred while canceling this invoice. Please check the transactions table to view the current status.',
        type: MessageTypes.Error,
        timeout: 5000,
      });
    },
  });

  const [processTerminalPayment] = useProcessTerminalPaymentMutation({
    onError: (error) => {
      console.error('Network error sending payment to terminal', error);
      addSnackbar({
        message: 'An error occurred when sending this payment to the terminal.',
        type: MessageTypes.Error,
        timeout: 5000,
      });
    },
  });

  const [clearTerminalDisplay, { loading: clearingTerminalDisplay }] = useClearTerminalDisplayMutation({
    onError: (error) => {
      console.error('Network error clearing terminal display', error);
      addSnackbar({
        message: 'An error occurred when clearing the terminal display.',
        type: MessageTypes.Error,
        timeout: 5000,
      });
    },
  });

  const [getClinicPetParents, { data: clinicPetParentsData, loading: clinicPetParentsLoading }] =
    useGetInvoicePetParentsLazyQuery({
      fetchPolicy: GraphQLFetchPolicies.NetworkOnly,
    });

  const isCancelingPayment = useMemo(
    () => cancelingIntent || clearingTerminalDisplay,
    [cancelingIntent, clearingTerminalDisplay],
  );

  const selectTerminalOption = (_: React.MouseEvent<Element, MouseEvent>, option: IOption): void => {
    setLastSelectedTerminalId(option?.value?.id);
    setTerminalOption(option);
  };

  // Select a payment method type and update the client fee accordingly
  const selectPaymentTypeOption = useCallback(
    (_: React.MouseEvent<Element, MouseEvent>, option: IOption): void => {
      setCurrentPaymentMedium(option.value);
      setClientServiceFeePercent(
        option.value === PaymentMedium.StripeTerminal
          ? ((currentClinic?.clinicSetting?.paymentFeeConfig?.terminalClientServiceFeePercent || 0) * 100).toFixed(2)
          : ((currentClinic?.clinicSetting?.paymentFeeConfig?.onlineClientServiceFeePercent || 0) * 100).toFixed(2),
      );
      if (option.value === PaymentMedium.StripeTerminal && terminalOptions?.length === 0) {
        setCurrentInvoiceStatus(InvoiceStatus.NoTerminalsFound);
      } else setCurrentInvoiceStatus(InvoiceStatus.Default);
    },
    [setCurrentPaymentMedium, currentClinic, setCurrentInvoiceStatus, terminalOptions],
  );

  // Handle changing and validating the invoice amount being entered
  const handleChangeInvoiceAmount = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      // If the value is falsy or empty, don't update anything
      if (!e.target.value.trim()) {
        setManualAmount('');
        return;
      }

      if (isNaN(Number(e.target.value))) return; // No special characters

      const [, decimal] = e.target.value.split('.');
      if (decimal && decimal.length > 2) return; // Only allow 2 decimal places
      const stringVal = e.target.value.replace(',', '');
      const numericVal = parseFloat(stringVal);

      let amount;
      if (numericVal > maxInputAmount) {
        amount = maxInputAmount.toFixed(2);
      } else if (numericVal <= 0 || isNaN(numericVal)) {
        amount = '';
      } else {
        amount = stringVal;
      }

      setManualAmount(amount);

      if (isWritebackManualPaymentEnabled && isPimsPetParent) {
        setPaymentRelationship(PaymentRelationship.AccountBalance);
      }
    },
    [setPaymentRelationship, isWritebackManualPaymentEnabled, isPimsPetParent],
  );

  const handleToggleIsEditing = useCallback((): void => {
    setIsEditingFee((isEditingFee) => !isEditingFee);
  }, [setIsEditingFee]);

  const handleClientFeeCheckboxChange = useCallback(
    (e) => {
      const isChecked = e.target.checked;
      setIsClientFeeCheckboxChecked(isChecked);

      const terminalFeePercent = currentClinic?.clinicSetting?.paymentFeeConfig?.terminalClientServiceFeePercent;
      const onlineFeePercent = currentClinic?.clinicSetting?.paymentFeeConfig?.onlineClientServiceFeePercent;

      const formattedTerminalFeePercent = terminalFeePercent ? (terminalFeePercent * 100).toFixed(2) : undefined;

      const formattedOnlineFeePercent = onlineFeePercent ? (onlineFeePercent * 100).toFixed(2) : undefined;

      // Set the fee percent to 0 if the checkbox is not checked, otherwise set it to the default value
      if (isChecked) {
        const feePercent =
          currentPaymentMedium === PaymentMedium.StripeTerminal
            ? formattedTerminalFeePercent
            : formattedOnlineFeePercent;
        setClientServiceFeePercent(feePercent);
      } else {
        setClientServiceFeePercent('0');
      }
    },
    [currentClinic?.clinicSetting?.paymentFeeConfig, currentPaymentMedium],
  );

  // Handle changing and validating the client service fee percent being entered
  const handleChangeClientServiceFeePercent = useCallback((e: ChangeEvent<HTMLInputElement>): void => {
    if (!e.target.value) {
      setClientServiceFeePercent(undefined);
      return;
    }

    if (isNaN(Number(e.target.value))) return; // No special characters

    const [, decimal] = e.target.value.split('.');

    if (decimal && decimal.length > 2) return; // Only allow 2 decimal places

    const stringVal = e.target.value.replace(',', '');
    const numericVal = parseFloat(stringVal);

    if (numericVal < 0) {
      setClientServiceFeePercent(undefined);
      return;
    } else if (clientServiceFeeMax && numericVal > clientServiceFeeMax) {
      setClientServiceFeePercent(clientServiceFeeMax.toFixed(2));
      return;
    } else {
      setClientServiceFeePercent(stringVal);
    }
  }, []);

  const clinicTransactionFee = useMemo(() => {
    let clinicTransactionFee = 0;
    if (currentPaymentMedium === PaymentMedium.StripeVirtualTerminal) {
      clinicTransactionFee = currentClinic?.clinicSetting?.paymentFeeConfig?.onlineProcessingFeePercent || 0;
    } else {
      clinicTransactionFee = currentClinic?.clinicSetting?.paymentFeeConfig?.terminalProcessingFeePercent || 0;
    }

    return clinicTransactionFee;
  }, [currentClinic?.clinicSetting?.paymentFeeConfig, currentPaymentMedium]);

  // show alert if clientServiceFeePercent exceeds clinic transaction fee percent
  useEffect(() => {
    if (!clientServiceFeePercent) {
      setShowClientFeeAlert(false);
      return;
    }

    const clientFeeDecimal = Math.round(parseFloat(clientServiceFeePercent) * 100) / 10000; // limit to 2 dec places bc JS
    if (clientServiceFeePercent !== undefined && clientFeeDecimal > clinicTransactionFee) {
      setShowClientFeeAlert(true);
    } else {
      setShowClientFeeAlert(false);
    }
  }, [clientServiceFeePercent, clinicTransactionFee]);

  const clientFeePercentDisplay = useMemo(
    () => (clientServiceFeePercent ? parseFloat(clientServiceFeePercent).toFixed(2) : '0.00'),
    [clientServiceFeePercent],
  );

  const clientFeeAlertTitle = useMemo(() => {
    const transactionFeeDisplay = (clinicTransactionFee * 100).toFixed(2);
    return `Client fee exceeds transaction fee (${transactionFeeDisplay}%)`;
  }, [clinicTransactionFee]);

  const clearTerminalDisplayWithFallback = useCallback(async () => {
    let success = false;
    try {
      if (!terminalOption?.value.id) return;

      const response = await clearTerminalDisplay({
        variables: {
          stripeTerminalId: terminalOption.value.id,
        },
      });

      success = response.data?.clearTerminalDisplay?.success || false;

      if (!success) {
        console.error(response.data?.clearTerminalDisplay?.error);
      }
    } catch (e) {
      console.error('Failed to clear terminal display via server', e);
    }

    if (!terminal || success) {
      return;
    }

    if (terminal.getConnectionStatus() === ConnectionStatus.CONNECTED) {
      await disconnectReader();
    }

    try {
      terminal.cancelCollectPaymentMethod();
    } catch (e) {
      console.error('Failed to clear terminal display via local SDK', e);
    }
  }, [terminal, terminalOption, disconnectReader, clearTerminalDisplay]);

  // Cancel the created Stripe payment intent in the event of
  // user cancellation or an error and redirect if necessary.
  const cancelInvoice = useCallback(
    async (isError?: boolean) => {
      if (!isError && stripePaymentIntentId) {
        setCurrentInvoiceStatus(InvoiceStatus.Canceled);
        Mixpanel.track('Terminal invoice cancelled');
      }

      try {
        if (stripePaymentIntentId && currentPaymentMedium === PaymentMedium.StripeTerminal) {
          await cancelStripePaymentIntent({
            variables: {
              where: {
                id: stripePaymentIntentId,
              },
            },
          });
        }

        if (currentPaymentMedium === PaymentMedium.StripeTerminal) {
          await clearTerminalDisplayWithFallback();
        }

        if (confirmPath) {
          switch (confirmPath) {
            case 'default':
              elementToClick && (elementToClick as HTMLButtonElement).click();
              break;
            case 'toggle-sidepanel':
              setIsOpen(false);
              break;
            case 'mobile-invoice-back-button':
              setCurrentMobileMenuView(MobileMenuViews.Root);
              break;
            default:
              break;
          }
        }
        onModalClose();
        setIsTransactionLoading(false);
      } catch (e) {
        console.error(e);
        captureException(e);
      } finally {
        if (confirmPath) {
          resetPanelState(true, isError);
          createTerminal();
        }
      }
    },
    [
      setIsOpen,
      onModalClose,
      confirmPath,
      createTerminal,
      elementToClick,
      resetPanelState,
      currentPaymentMedium,
      stripePaymentIntentId,
      setCurrentInvoiceStatus,
      cancelStripePaymentIntent,
      setCurrentMobileMenuView,
      clearTerminalDisplayWithFallback,
    ],
  );

  const handleCreditCardInvoice = useCallback(
    async (paymentIntentData: StripePaymentOutputFragment) => {
      try {
        if (!(stripe && elements && selectedPetParent)) {
          throw new Error('Stripe not properly set. Please refresh the page and try again.');
        }

        const cardElement: StripeElement | null = elements.getElement(CreditCardFields.Card);

        if (!cardElement && !selectedSavedPaymentMethodStripeId)
          throw new Error('Stripe not properly set. Please refresh the page and try again.');

        const { paymentIntent: updatedIntent, error } = await stripe.confirmCardPayment(
          paymentIntentData.paymentIntentClientSecret || '',
          cardElement
            ? {
                payment_method: {
                  card: cardElement,
                },
                setup_future_usage:
                  (currentPaymentMedium === PaymentMedium.StripeVirtualTerminal && isCardSetForSaving) ||
                  (currentPaymentMedium === PaymentMedium.StripeVirtualTerminal && selectedSavedPaymentMethodStripeId)
                    ? 'off_session'
                    : undefined,
              }
            : { payment_method: selectedSavedPaymentMethodStripeId || '' },
        );
        if (updatedIntent?.status === 'succeeded') {
          setCurrentInvoiceStatus(InvoiceStatus.CardSuccessful);
        } else {
          // if a network error occurs while processing, the Stripe request may be retried even if it succeeded
          // don't show error in this case since it succeeded the first time
          if (
            error?.payment_intent?.status === 'succeeded' &&
            error.payment_intent.id === paymentIntentData.payment.id
          ) {
            console.error('Stripe confirmCardPayment retried after success. Error supressed', error);
            return;
          }
          setCurrentInvoiceStatus(InvoiceStatus.Error);
          const newErrorMessage = getErrorMessage(
            error || new Error('Stripe was not able to complete updating the intent'),
          );
          setErrorMessage(newErrorMessage || errorMessage);
          throw error;
        }
      } catch (e) {
        if (e instanceof Error) {
          setValidationMessage(e?.message || 'Whoops! Something went wrong while processing this payment.');
          console.error(e);
          await cancelInvoice(true);
          const newErrorMessage = getErrorMessage(e);
          setErrorMessage(newErrorMessage || errorMessage);
          captureException(e);
          setCurrentInvoiceStatus(InvoiceStatus.Error);
        }
      }
    },
    [
      stripe,
      elements,
      selectedPetParent,
      selectedSavedPaymentMethodStripeId,
      currentPaymentMedium,
      isCardSetForSaving,
      setCurrentInvoiceStatus,
      getErrorMessage,
      errorMessage,
      cancelInvoice,
    ],
  );

  useGetStripePaymentIntentQuery({
    fetchPolicy: GraphQLFetchPolicies.NetworkOnly,
    onCompleted: (data) => {
      if (data?.stripePaymentOutput) {
        // don't try again if it has already succeeded
        if (data.stripePaymentIntent?.status !== StripePaymentIntentStatus.Succeeded) {
          handleTransaction(data?.stripePaymentOutput);
        }
        setIsTransactionLoading(false);
      }
    },
    skip: !stripePaymentIntentId,
    onError: (error) => {
      console.error('Network error querying payment intent', error);
      addSnackbar({
        message: 'An error occurred in retrieving your stripe',
        type: MessageTypes.Error,
        timeout: 5000,
      });
    },
    variables: {
      where: {
        id: stripePaymentIntentId,
      },
    },
  });

  const { stopPolling } = useGetPaymentIntentStatusQuery({
    variables: {
      where: {
        id: stripePaymentIntentId,
      },
    },
    fetchPolicy: GraphQLFetchPolicies.NetworkOnly,
    skip: !stripePaymentIntentId,
    onCompleted: (data) => {
      // check for payment updates if web socket crashes subscription while waiting
      if (data.findUniqueStripePaymentIntent?.status && currentPaymentMedium === PaymentMedium.StripeTerminal) {
        if (
          [StripePaymentIntentStatus.Succeeded, StripePaymentIntentStatus.RequiresCapture].includes(
            data.findUniqueStripePaymentIntent.status,
          )
        ) {
          setReceiptEmail('');
          setCurrentInvoiceStatus(InvoiceStatus.Received);
          stopPolling();
        } else if (!!data.findUniqueStripePaymentIntent.lastPaymentError) {
          setCurrentInvoiceStatus(InvoiceStatus.Error);
          setErrorMessage(STRIPE_ERROR_READABLE[data.findUniqueStripePaymentIntent.lastPaymentError]);
          stopPolling();
        }
      } else {
        stopPolling();
      }
    },
    pollInterval: 5000,
    notifyOnNetworkStatusChange: true,
  });

  // Handle credit card invoice transaction if applicable or update invoice status
  // if it happened to not be handled in another part of the code (unlikely).
  const handleTransaction: (stripePaymentIntentData: StripePaymentOutputFragment) => Promise<void> = useCallback(
    async (stripePaymentIntentData: StripePaymentOutputFragment) => {
      if (!stripePaymentIntentData) {
        console.error('No Stripe payment intent data was found.');
        return;
      }

      if (currentPaymentMedium === PaymentMedium.StripeVirtualTerminal) {
        handleCreditCardInvoice(stripePaymentIntentData);
        return;
      }

      if (stripePaymentIntentData.payment?.status === 'canceled') {
        setReceiptEmail('');
        setCurrentInvoiceStatus(InvoiceStatus.Canceled);
        return;
      }
    },
    [currentPaymentMedium, handleCreditCardInvoice, setCurrentInvoiceStatus],
  );

  // Create payment intent for a credit card payment.
  const handleSubmitDigitalInvoice = useCallback(async () => {
    try {
      if (!(selectedPetParent && (isValid.card || selectedSavedPaymentMethodStripeId))) return;
      setIsTransactionLoading(true);
      setCurrentInvoiceStatus(InvoiceStatus.CardProcessing);

      const amount = Math.round((amountDue - clientServiceFee) * 100);

      const feePercent = clientServiceFeePercent !== undefined ? parseFloat(clientServiceFeePercent) / 100 : undefined;

      const stripePaymentIntent = await createStripePaymentIntent({
        variables: {
          data: {
            amount,
            paymentMethodType: StripePaymentMethodType.CardNotPresent,
            clientServiceFeePercent:
              fundingType !== CardFunding.Credit && isFeatureEnabled(FeatureFlagName.SurchargeUpdates) ? 0 : feePercent,
          },
          clinicPetParentId: selectedPetParent.id,
          paymentMedium: PaymentMedium.StripeVirtualTerminal,
          invoiceIds: isDiscoveredInvoiceDemoModeEnabled ? null : invoiceIds || null,
          paymentRelationship,
          emailOverride: receiptEmail,
          cardType: fundingType,
        },
      });

      if (!stripePaymentIntent?.data?.createStripePaymentIntent2) {
        setErrorMessage('Failed to create Stripe payment intent. Please try again.');
        setCurrentInvoiceStatus(InvoiceStatus.Error);
        throw new Error('Failed to create Stripe payment intent');
      }

      setStripePaymentIntentId(stripePaymentIntent?.data.createStripePaymentIntent2?.id);
    } catch (e) {
      if (e instanceof Error) {
        const newErrorMessage = getErrorMessage(e);
        setErrorMessage(newErrorMessage || errorMessage);

        setCurrentInvoiceStatus(InvoiceStatus.Error);
        Mixpanel.track('Digital invoice error', { error: e.message });
        captureException(e);
      }
    } finally {
      setIsTransactionLoading(false);
      setRemoveInvoiceAttachment(true);
      setViewBenefitSource(BenefitAlertSource.MessageComposer);
      //remove invoice attachment since it is now paid and reset the Benefit source to MessageComposer since the invoicing panel transaction is done
    }
  }, [
    selectedPetParent,
    isValid.card,
    selectedSavedPaymentMethodStripeId,
    setCurrentInvoiceStatus,
    amountDue,
    clientServiceFee,
    receiptEmail,
    clientServiceFeePercent,
    createStripePaymentIntent,
    isDiscoveredInvoiceDemoModeEnabled,
    invoiceIds,
    paymentRelationship,
    getErrorMessage,
    errorMessage,
    setRemoveInvoiceAttachment,
    setViewBenefitSource,
    fundingType,
    isFeatureEnabled,
  ]);

  const handleSubmitTerminalInvoice = useCallback(async () => {
    Mixpanel.track('Send terminal invoice button clicked', {
      integrationType: 'ServerSideTerminal',
    });

    if (!amountDue) {
      addSnackbar({
        message: 'Please enter an amount above $0.50 and select a terminal and client for this invoice.',
        type: MessageTypes.Info,
        timeout: 5000,
      });
      return;
    }

    if (!terminalOption) {
      addSnackbar({
        message: 'Please select a terminal for this invoice.',
        type: MessageTypes.Info,
        timeout: 5000,
      });
      return;
    }

    if (!selectedPetParent) {
      addSnackbar({
        message: 'Please select or create a client for this invoice.',
        type: MessageTypes.Info,
        timeout: 5000,
      });
      return;
    }

    if (terminalOption?.value?.status !== 'online') {
      addSnackbar({
        message: 'Please select an online terminal.',
        type: MessageTypes.Info,
        timeout: 5000,
      });
      return;
    }

    if (!terminalOption?.value.id) {
      addSnackbar({
        message: 'Selected terminal is unavailable, please refresh your page and try again.',
        type: MessageTypes.Info,
        timeout: 5000,
      });
      return;
    }

    const amount = Math.round((amountDue - clientServiceFee) * 100);

    try {
      setCurrentInvoiceStatus(InvoiceStatus.Sending);
      setIsTransactionLoading(true);

      const feePercent = clientServiceFeePercent !== undefined ? parseFloat(clientServiceFeePercent) / 100 : undefined;
      const stripeTerminalId = terminalOption?.value.id;

      const response = await processTerminalPayment({
        variables: {
          stripeTerminalId,
          paymentIntent: {
            id: stripePaymentIntentId,
            create: {
              amount,
              paymentMethodType: StripePaymentMethodType.CardPresent,
              clientServiceFeePercent: feePercent,
            },
            clinicPetParentId: selectedPetParent.id,
            paymentMedium: PaymentMedium.StripeTerminal,
            invoiceIds: isDiscoveredInvoiceDemoModeEnabled ? null : invoiceIds || null,
            paymentRelationship,
            emailOverride: receiptEmail,
          },
        },
      });

      if (!response.data?.processTerminalPayment?.stripePaymentIntentId) {
        setCurrentInvoiceStatus(InvoiceStatus.Error);
        setErrorMessage('Failed to send payment to this terminal, please try again in a moment.');
        console.error('Terminal Invoice Failed to Create Payment Intent', response.data?.processTerminalPayment?.error);
        return;
      }

      setStripePaymentIntentId(response.data?.processTerminalPayment?.stripePaymentIntentId);

      if (!response.data?.processTerminalPayment?.success) {
        console.error('Terminal Invoice Failed - Allowing Retry', response.data?.processTerminalPayment?.error);
        setCurrentInvoiceStatus(InvoiceStatus.TerminalProcessRetry);
        return;
      }

      setCurrentInvoiceStatus(InvoiceStatus.Pending);
    } catch (e) {
      setErrorMessage(
        getErrorMessage(
          e,
          'An error occurred sending the payment to your terminal. Otto staff has been notified and are working to address this as soon as possible.',
        ),
      );
      Mixpanel.track('Terminal invoice error', { error: e.message });
      setCurrentInvoiceStatus(InvoiceStatus.Error);
      captureException(e);
    } finally {
      setIsTransactionLoading(false);
      setRemoveInvoiceAttachment(true);
      setViewBenefitSource(BenefitAlertSource.MessageComposer);
      //remove invoice attachment since it is now paid and reset the Benefit source to MessageComposer since the invoicing panel transaction is done
    }
  }, [
    amountDue,
    terminalOption,
    selectedPetParent,
    clientServiceFee,
    addSnackbar,
    setCurrentInvoiceStatus,
    receiptEmail,
    clientServiceFeePercent,
    isDiscoveredInvoiceDemoModeEnabled,
    invoiceIds,
    paymentRelationship,
    setErrorMessage,
    getErrorMessage,
    processTerminalPayment,
    stripePaymentIntentId,
    setRemoveInvoiceAttachment,
    setViewBenefitSource,
  ]);

  // Handle changing the search value of the client for this transaction
  const handleClientSearchValueChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setClientSearchValue(e.target.value);
  }, []);

  // Handle results returned from pet parent query
  const petParentSearchResults: InvoicePetParentFragment[] = useMemo(() => {
    if (clinicPetParentsData) return clinicPetParentsData.clinicPetParentSearch;
    return [];
  }, [clinicPetParentsData]);

  // Handle searching for the pet parent - ES/SWAP?
  useEffect(() => {
    if (debouncedClientSearchValue === '') return;

    const whereInput: SearchClinicPetParentInput = {
      or: [
        { fullName: debouncedClientSearchValue },
        { email: debouncedClientSearchValue },
        { phoneNumber: debouncedClientSearchValue },
        { petNames: debouncedClientSearchValue },
        ...(isClientIdSupported
          ? [{ pimsId: debouncedClientSearchValue }, { petPimsIds: debouncedClientSearchValue }]
          : []),
      ],
    };
    const petWhere: ClinicPetWhereInput = {
      isDeleted: { equals: false },
      isActive: { equals: true },
      isDeceased: { not: { equals: true } },
    };
    setIsSearching(true);
    getClinicPetParents({
      variables: {
        whereInput,
        petWhere,
        pageSize: 10,
      },
    });

    setIsSearching(false);
  }, [debouncedClientSearchValue, getClinicPetParents, currentClinic, isSyncing, isClientIdSupported]);

  // Dismiss the pop-up modal and continue transaction, after clicking something that would cancel the ongoing transaction
  const continueTransaction = useCallback(() => {
    onModalClose();
    setConfirmPath('');
  }, [onModalClose]);

  // Ability to navigate to pet from the client-addition form component
  const goToPet = useCallback(
    async (id: string | undefined) => {
      if (!id) return;
      viewClinicPet({ clinicPetId: id });
    },
    [viewClinicPet],
  );

  // Ability to navigate to client from the client-addition form component
  const goToPetParent = useCallback(
    async (id: string | undefined) => {
      if (!id) return;
      viewClinicPetParent({ clinicPetParentId: id });
    },
    [viewClinicPetParent],
  );

  // The receipt email that will receive the information on the transaction
  const handleChangeReceiptEmail = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setReceiptEmail(e.target.value);
  }, []);

  // Handle changing the saved credit card info for a digital payment
  const handleChangeCreditCard = useCallback(
    async (e: StripeCardElementChangeEvent) => {
      setValidationMessage('');
      const { complete, error } = e;

      if (complete && !error) {
        setIsValid((prevState) => ({ ...prevState, [e.elementType]: true }));

        if (!isFeatureEnabled(FeatureFlagName.SurchargeUpdates) || !hasCustomizableFees) {
          // if FF not enabled or client fees not enabled no need to create payment method
          return;
        }
        if (!elements || !stripe) {
          // Stripe.js has not loaded
          setValidationMessage('Stripe.js has not loaded properly. Please refresh the page and try again.');
          return;
        }

        const cardElement = elements.getElement(CardElement);

        if (!cardElement) {
          // if card element not available
          setValidationMessage('Card element not available. Please refresh the page and try again.');
          return;
        }

        const result = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
        });

        if (result.error) {
          setValidationMessage(result.error.message || '');
        } else {
          setFundingType(cardFundingTypeConverter(result.paymentMethod.card?.funding));
        }
        return;
      }

      if (error) setValidationMessage(error?.message || 'Make sure you have provided valid credit card details.');
      setIsValid((prevState) => ({ ...prevState, [e.elementType]: false }));
      setFundingType(undefined);
    },
    [elements, setValidationMessage, setIsValid, setFundingType, stripe, isFeatureEnabled, hasCustomizableFees],
  );

  const handleSetCurrentCarouselStep = useCallback((step: number) => {
    setCurrentCarouselStep(step);
  }, []);

  const handleSetupStripe = useCallback(async (): Promise<void> => {
    Mixpanel.track('Payment setup button (side panel) clicked');

    const { data: stripeAccountData } = await client.query<{
      stripeRetrieveConnectedAccount: StripeCreateAccountOutput;
    }>({
      query: STRIPE_RETRIEVE_CONNECTED_ACCOUNT,
    });

    const connectedStripeAccount = stripeAccountData?.stripeRetrieveConnectedAccount?.account;

    if (!connectedStripeAccount) {
      try {
        const stripeAccount = await createStripeAccount({
          variables: {
            data: {
              redirectUrl: window.location.href,
            },
          },
        });

        const onboardingUrl = stripeAccount.data?.stripeCreateConnectedAccount?.onboarding?.url;
        if (onboardingUrl) {
          window.location.href = onboardingUrl;
        }
      } catch (e) {
        console.error(e);
      }
    } else if (!connectedStripeAccount.chargesEnabled || !connectedStripeAccount.payoutsEnabled) {
      const { data: stripeLoginLinkData } = await client.query<{
        stripeCreateLoginLink: LoginLinkOutput;
      }>({
        query: STRIPE_CREATE_LOGIN_LINK,
      });

      const loginLink = stripeLoginLinkData.stripeCreateLoginLink?.url;
      if (loginLink) {
        window.open(loginLink);
      }
    }
  }, [client, createStripeAccount]);

  const isSubmitButtonDisabled = useMemo(() => {
    if (!currentPaymentMedium) return true;
    else if (currentPaymentMedium === PaymentMedium.StripeTerminal) {
      return (
        !(
          amountToInvoice &&
          parseFloat(amountToInvoice) >= 0.5 &&
          terminalOption &&
          selectedPetParent &&
          receiptEmail?.match(email)
        ) || currentInvoiceStatus !== InvoiceStatus.Default
      );
    } else if (currentPaymentMedium === PaymentMedium.StripeVirtualTerminal) {
      return (
        !(
          amountToInvoice &&
          parseFloat(amountToInvoice) >= 0.5 &&
          (isValid.card || selectedSavedPaymentMethodStripeId) &&
          selectedPetParent &&
          receiptEmail?.match(email)
        ) || currentInvoiceStatus !== InvoiceStatus.Default
      );
    }
    return true;
  }, [
    currentPaymentMedium,
    amountToInvoice,
    terminalOption,
    selectedPetParent,
    receiptEmail,
    currentInvoiceStatus,
    isValid.card,
    selectedSavedPaymentMethodStripeId,
  ]);

  // don't allow manual amount if invoice/balance selected and writebackManualPayment disabled since we can't do partial writebacks
  const manualAmountInputDisabled = useMemo(() => {
    return discoveredAmount > 0 && !isWritebackManualPaymentEnabled;
  }, [discoveredAmount, isWritebackManualPaymentEnabled]);

  const manualAmountInputDisplayValue = useMemo(() => {
    if (manualAmount) {
      if (manualAmount === '0.00') {
        return '';
      }
      if (parseFloat(manualAmount) >= maxInputAmount) {
        return maxInputAmount.toFixed(2);
      } else {
        return manualAmount;
      }
    } else {
      return '';
    }
  }, [manualAmount]);

  const manualAmountDisplayValue = useMemo(
    () => getMonetaryDisplayValue(parseFloat(manualAmountInputDisplayValue || '0') * 100, true),
    [manualAmountInputDisplayValue],
  );

  const hasManualAmount = useMemo(() => parseFloat(manualAmount) > 0, [manualAmount]);

  // Select client to be used for receipt email
  const handleSelectClient = useCallback(
    (parent: InvoicePetParentFragment) => {
      setSelectedPetParent(parent);
      setReceiptEmail(parent.email || undefined);
      setClientSearchValue('');
      setSuggestedPetParentOptions([]);

      if (isWritebackManualPaymentEnabled && !!parent.pimsId && hasManualAmount) {
        setPaymentRelationship(PaymentRelationship.AccountBalance);
      }
    },
    [
      hasManualAmount,
      isWritebackManualPaymentEnabled,
      setPaymentRelationship,
      setSelectedPetParent,
      setSuggestedPetParentOptions,
    ],
  );

  // Set selected pet parent when a new parent is selected from conversation
  useEffect(() => {
    if (selectedPetParent) setReceiptEmail(selectedPetParent.email || '');
    setSelectedSavedPaymentMethodStripeId(null);
  }, [selectedPetParent]);

  const searchInfoText = useMemo(() => {
    return `You can search by client name, ${
      isClientIdSupported ? 'ID, ' : ''
    }email, or phone number. You can also search by patient name${isClientIdSupported ? ' or ID' : ''}.`;
  }, [isClientIdSupported]);

  const paymentSendMethodOptions = useMemo(() => {
    // remove StripeOnline from the list and exclude StripeVirtualTerminal if clinic is one of UFClinicsToExclude
    const isUFClinic = UFClinicsToExclude.includes(currentClinic?.id || '');
    return paymentMediumOptions.filter(
      (option) =>
        option.value !== PaymentMedium.StripeOnline &&
        (!isUFClinic || option.value !== PaymentMedium.StripeVirtualTerminal),
    );
  }, [paymentMediumOptions, currentClinic?.id]);

  const isInputDisabled = useMemo(() => {
    return currentInvoiceStatus !== InvoiceStatus.Default || !isClientFeeCheckboxChecked;
  }, [currentInvoiceStatus, isClientFeeCheckboxChecked]);

  const showCashDiscount = useMemo(() => {
    return (
      fundingType !== CardFunding.Credit &&
      fundingType !== undefined &&
      isFeatureEnabled(FeatureFlagName.SurchargeUpdates) &&
      hasCustomizableFees &&
      currentPaymentMedium !== PaymentMedium.StripeTerminal
    );
  }, [fundingType, currentPaymentMedium, isFeatureEnabled, hasCustomizableFees]);

  const showClientFee = useMemo(() => {
    return (
      hasCustomizableFees && isFeatureEnabled(FeatureFlagName.SurchargeUpdates) && currentPaymentMedium !== PaymentMedium.StripeTerminal ||
      hasCustomizableFees && !isFeatureEnabled(FeatureFlagName.TerminalAppOnlySurchargeUpdates) && !isFeatureEnabled(FeatureFlagName.SurchargeUpdates) 
    );
  }, [hasCustomizableFees, isFeatureEnabled, currentPaymentMedium]);

  /**
   * If Stripe has not been connected, show the user what they need to do
   */
  if (!(currentClinic?.submerchantStripeId && currentClinic?.submerchantStripeChargesEnabled)) {
    return (
      <Carousel
        totalNumberOfCarouselSteps={1}
        pageContentJSX={
          <div style={{ fontSize: 14 }}>
            <Text as="p" size="lg" fontWeight="bold" pb={2}>
              Complete Payment Solutions for your clinic.
            </Text>
            <div>
              Give your clients in-clinic and contactless payment options, with no additional cost or effort for your
              team.
            </div>
            <ListItems>
              <ListItem>Wi-Fi Terminals: Accept multiple forms of payment from any room in your clinic.</ListItem>
              <ListItem>Text &amp; Chat to Pay: Accept payment without ever leaving the conversation.</ListItem>
              <ListItem>Virtual Pay: Accept payment from wherever your client is.</ListItem>
            </ListItems>
            <div style={{ paddingBottom: 18 }}>
              When you&apos;re ready, click the button below to complete your payment setup. Once you&apos;ve completed
              your profile in Stripe, return to Flow and refresh the page to gain full access to Otto&apos;s payment
              features.
            </div>
          </div>
        }
        CTACallback={async (): Promise<void> => {
          await handleSetupStripe();
        }}
        CTALoading={creatingStripeAccount}
        currentCarouselStep={currentCarouselStep}
        setCurrentCarouselStep={handleSetCurrentCarouselStep}
        sectionLabel="Stripe Payments"
        CTAButtonText="Set up Payments Now"
        secondaryCTACallback={(): void => {
          if (window.zE) {
            window.zE('messenger', 'open');
          }
          Mixpanel.track('Ask for support for Stripe payment setup (side panel) clicked');
        }}
      />
    );
  }

  // Show loading state if StripeTerminalProvider says we haven't finished initializing the online terminals
  if (!isInitialized) {
    return (
      <VStack p={isInMobileMenu ? '0' : '120px 20px'} spacing={0}>
        <Text size="xl" fontWeight="bold">
          Fetching Payment Data
        </Text>
        <Spinner />
      </VStack>
    );
  }

  return (
    <>
      {isAddingPetParent ? ( // If the user didn't find their client in the search and is choosing to add a new one
        <AddClientPatientForm
          goToPetParent={goToPetParent}
          goToPet={goToPet}
          onBackButtonClick={(): void => {
            setIsAddingPetParent(false);
          }}
          resultMessageText="It looks like we already have this client in your system. You do not need to add this client again."
          onSelectExistingPetParent={(clinicPetParent: ExistingClinicPetParentMatchFragment): void => {
            setSelectedPetParent(clinicPetParent);
            setReceiptEmail(clinicPetParent?.email || '');
            setIsAddingPetParent(false);
          }}
        />
      ) : (
        <Container ref={ref}>
          {isInMobileMenu ? (
            <MenuTitle>
              <Button
                iconName="chevronLeft"
                variant="ghostNeutral"
                onClick={(): void => setCurrentMobileMenuView(MobileMenuViews.Root)}
              />
              <Heading size="lg">{MobileMenuViews.SendInvoice}</Heading>
            </MenuTitle>
          ) : (
            <Header>
              <Text size="2xl" fontWeight="bold">
                Payments
              </Text>
            </Header>
          )}
          {showWritebackConfigurationAlert && (
            <Box p={1}>
              <Alert
                status="warning"
                title="Configuration Incomplete"
                titleProps={{ children: null, size: 'xs' }}
                description={writebackConfigAlertDescription}
                descriptionProps={{
                  size: 'sm',
                  children: null,
                }}
                onClose={(): void => setWritebackConfigurationAlertDismissed(true)}
              >
                {hasAdminRoleAtCurrentClinic && (
                  <Link
                    iconProps={{ name: 'externalLink' }}
                    as={ReactRouterLink}
                    to={`${RouteBasePaths.Settings}${SettingsRoutes.ClinicPayment}`}
                    onClick={(): void => {
                      track(events.PaymentIntegrationInitiatedSidePanel.name);
                      setIsOpen(false);
                    }}
                    size="sm"
                  >
                    Go to Payment Settings
                  </Link>
                )}
              </Alert>
            </Box>
          )}
          <InnerContainer status={currentInvoiceStatus} isMobile={isMobile}>
            {suggestedPetParentOptions && suggestedPetParentOptions.length > 0 ? (
              <HorizontalPadding>
                <Flex flexWrap="wrap" mt={2}>
                  {suggestedPetParentOptions?.map((parent) => (
                    <SuggestedOption
                      key={parent.id}
                      onSelect={(): void => setSelectedPetParent(parent)}
                      textContent={[parent.firstName, parent.lastName].join(' ')}
                      isSelected={selectedPetParent?.id === parent.id}
                      petParentPimsId={parent.pimsId || ''}
                      isClientIdSupported={isClientIdSupported}
                    />
                  ))}
                  <SuggestedOption
                    onSelect={(): void => {
                      setInvoiceIds([]);
                      setAmountToInvoice('');
                      setManualAmount('');
                      setSelectedPetParent(undefined);
                      setPaymentRelationship(null);
                      setIsBalancePreSelected(false);
                    }}
                    textContent="Choose a different client"
                    isSelected={!selectedPetParent}
                    noRadial={true}
                  />
                </Flex>
              </HorizontalPadding>
            ) : (
              <></>
            )}
            {/* We only want to show the search field if a pet parent is not currently selected */}
            {!selectedPetParent && (
              <>
                <FieldTitle fieldTitleText="Search Client or Patient" popoverText={searchInfoText} />
                <SearchField placeholder="Search...">
                  <SearchIcon />
                  <FieldInput
                    disabled={currentInvoiceStatus !== InvoiceStatus.Default}
                    value={clientSearchValue}
                    onChange={handleClientSearchValueChange}
                    data-testid="terminal-invoice-client-search-input"
                    isMobile={isMobile}
                    width="100%"
                  />
                  <StyledCloseIcon
                    $isEmpty={Boolean(debouncedClientSearchValue)}
                    onClick={(): void => {
                      setClientSearchValue('');
                      setReceiptEmail('');
                    }}
                    data-testid="invoice-clear-client-search-input"
                  />
                </SearchField>
              </>
            )}
            {!selectedPetParent && isSearching && <Spinner />}

            {selectedPetParent && !isSearching && !(suggestedPetParentOptions && suggestedPetParentOptions.length) && (
              <HorizontalPadding>
                <Flex flexWrap="wrap" mt={2}>
                  <SuggestedOption
                    onSelect={(): void => setSelectedPetParent(selectedPetParent)}
                    textContent={[selectedPetParent.firstName, selectedPetParent.lastName].join(' ')}
                    isSelected={true}
                    petParentPimsId={selectedPetParent.pimsId || ''}
                    isClientIdSupported={isClientIdSupported}
                  />
                  <SuggestedOption
                    onSelect={(): void => {
                      setSelectedPetParent(undefined);
                      setInvoiceIds([]);
                      setAmountToInvoice('');
                      setManualAmount('');
                      setPaymentRelationship(null);
                      setIsBalancePreSelected(false);
                    }}
                    textContent="Choose a different client"
                    isSelected={isSearching}
                    noRadial={true}
                  />
                  {!selectedPetParent.email && (
                    <ErrorInstructionComponent
                      errorText={`WARNING: This client does not have an email associated. Please add a designated email below for
                    the Receipt Email.`}
                    />
                  )}
                </Flex>
              </HorizontalPadding>
            )}
            {clinicPetParentsLoading && (
              <Center style={{ marginBottom: 16 }}>
                <Spinner />
              </Center>
            )}
            {!petParentSearchResults.length &&
              !selectedPetParent &&
              debouncedClientSearchValue &&
              !clinicPetParentsLoading && (
                <HorizontalPadding>
                  <VerticalPadding>
                    <ClientSearchResult
                      icon={<Icon name="team" />}
                      {...clientSearchResultProps}
                      messageText="Unfortunately, we were unable to find this client in our system."
                      backgroundColor="background.default"
                      callToActionFunction={(): void => {
                        setIsAddingPetParent(true);
                        viewAddClientOrPatient({ isFromInvoice: true });
                      }}
                      shouldShowPimsSync={shouldShowPimsSync}
                      {...syncEnabledProps}
                    />
                  </VerticalPadding>
                </HorizontalPadding>
              )}
            {!!petParentSearchResults.length && clientSearchValue && (
              <HorizontalPadding>
                <VerticalPadding>
                  {sortBy(
                    petParentSearchResults,
                    (petParent) => `${petParent?.firstName.toLowerCase()} ${petParent?.lastName.toLowerCase()}`,
                  ).map((parent: InvoicePetParentFragment, index) => {
                    const { id, firstName, lastName, pets } = parent;
                    return (
                      <ExistingClient
                        onClick={(): void => handleSelectClient(parent)}
                        key={id}
                        data-testid={'invoice-parent-search-result-' + index}
                      >
                        <span>{[firstName, lastName].join(' ').trim()}</span>
                        {!!pets?.length && <TruncatedPetList clinicPets={pets} />}
                      </ExistingClient>
                    );
                  })}
                </VerticalPadding>
              </HorizontalPadding>
            )}

            {selectedPetParent && (
              <>
                <FieldTitle fieldTitleText="Receipt Email" />
                <SearchField style={{ marginBottom: selectedPetParent ? 20 : 0 }}>
                  <FieldInput
                    onChange={handleChangeReceiptEmail}
                    value={receiptEmail || selectedPetParent?.email || ''}
                    disabled={currentInvoiceStatus !== InvoiceStatus.Default || !selectedPetParent}
                    isMobile={isMobile}
                    width="100%"
                  />
                </SearchField>
                {receiptEmail && !email.test(receiptEmail) && (
                  <HorizontalPadding>
                    <ErrorInstructionComponent errorText="Please enter a valid email address" />
                  </HorizontalPadding>
                )}
                {!selectedPetParent && (
                  <HorizontalPadding>
                    <VerticalPadding>
                      <ErrorInstructionComponent errorText="Please select an existing client before editing the receipt email" />
                    </VerticalPadding>
                  </HorizontalPadding>
                )}
                <HasFeature name={FeatureFlagName.TrupanionCheckout}>
                  <TrupanionCheckout clinicPetParentId={clinicPetParentId} />
                </HasFeature>
              </>
            )}
            <HR />
            <InvoiceDropdownContainer>
              <FieldTitle
                fieldTitleText="Send Method"
                popoverText="Choose to send an invoice via terminal reader or credit/debit card"
              />
              <Dropdown
                options={paymentSendMethodOptions}
                onSelect={selectPaymentTypeOption}
                placement={PopoverPlacement.BottomStart}
                width={295}
              >
                <SearchField tabIndex={1} data-testid="invoice-payment-method-dropdown">
                  <DropdownRow
                    isMobile={isMobile}
                    tabIndex={1}
                    disabled={
                      currentInvoiceStatus !== InvoiceStatus.Default &&
                      currentInvoiceStatus !== InvoiceStatus.NoTerminalsFound
                    }
                  >
                    <div>
                      <SelectedDropdownOption
                        isTextPresent={!!paymentMediumOptions.find((medium) => medium.value === currentPaymentMedium)}
                        data-testid="invoice-selected-payment-type"
                      >
                        {paymentMediumOptions.find((medium) => medium.value === currentPaymentMedium)?.text ||
                          'Select a payment method...'}
                      </SelectedDropdownOption>
                    </div>
                    <div>
                      <ChevronDownIcon />
                    </div>
                  </DropdownRow>
                </SearchField>
              </Dropdown>
            </InvoiceDropdownContainer>
            {currentPaymentMedium === PaymentMedium.StripeVirtualTerminal && (
              <>
                <FieldTitle fieldTitleText="Card Details" />
                <SavedPaymentMethods
                  clinicPetParentId={clinicPetParentId}
                  selectedSavedPaymentMethodStripeId={selectedSavedPaymentMethodStripeId}
                  setSelectedSavedPaymentMethodStripeId={setSelectedSavedPaymentMethodStripeId}
                  setFundingType={
                    isFeatureEnabled(FeatureFlagName.SurchargeUpdates) && hasCustomizableFees
                      ? setFundingType
                      : undefined
                  }
                  fundingType={fundingType}
                />
                {!selectedSavedPaymentMethodStripeId && (
                  <Box>
                    <Row>
                      <SearchField style={{ margin: 0, width: '100%' }}>
                        <StyledCardElement
                          data-testid="credit-card-invoice-card"
                          onChange={(e: StripeCardElementChangeEvent): Promise<void> => handleChangeCreditCard(e)}
                        />
                      </SearchField>
                    </Row>

                    {isValid.card && (
                      <>
                        <FundingTypeTag fundingType={fundingType} isCardSelected={isValid.card} />
                        <SavingPaymentMethodOption>
                          <Checkbox
                            isChecked={isCardSetForSaving}
                            onChange={(): void => {
                              setIsCardSetForSaving((current) => !current);
                            }}
                          >
                            Save this card for future use
                          </Checkbox>
                        </SavingPaymentMethodOption>
                      </>
                    )}
                  </Box>
                )}
                <HorizontalPadding>
                  <ErrorInstructionComponent errorText={validationMessage} />
                </HorizontalPadding>
              </>
            )}
            {/* Handle display when the user has terminals */}
            {currentPaymentMedium === PaymentMedium.StripeTerminal && (
              <>
                {terminalOptions?.length ? (
                  <InvoiceDropdownContainer>
                    <Flex>
                      <FieldTitle fieldTitleText="Select Terminal" popoverText={selectTerminalMessage} />
                      {currentInvoiceStatus === InvoiceStatus.Default && (
                        <Link
                          mt={3}
                          size="sm"
                          onClick={(): void => {
                            setCurrentInvoiceStatus(InvoiceStatus.Default);
                            setTerminalOption(undefined);
                            resetTerminalList();
                          }}
                          iconProps={{
                            name: 'doubleArrowCircle',
                          }}
                        >
                          Sync Terminals
                        </Link>
                      )}
                    </Flex>
                    {currentInvoiceStatus !== InvoiceStatus.Default ? (
                      <SearchField tabIndex={1} data-testid="invoice-dropdown">
                        <DropdownRow isMobile={isMobile} disabled={true}>
                          <div>
                            <SelectedDropdownOption
                              isTextPresent={!!terminalOption?.text}
                              data-testid="invoice-selected-terminal"
                            >
                              {terminalOption?.text}
                            </SelectedDropdownOption>
                          </div>
                          <div>
                            <ChevronDownIcon />
                          </div>
                        </DropdownRow>
                      </SearchField>
                    ) : (
                      <Dropdown
                        options={terminalOptions}
                        onSelect={selectTerminalOption}
                        placement={PopoverPlacement.BottomStart}
                        width={295}
                        disabledMessage="This terminal is currently offline"
                        showActivityStatus={true}
                      >
                        <SearchField tabIndex={1} data-testid="terminal-invoice-dropdown">
                          <DropdownRow
                            isMobile={isMobile}
                            tabIndex={1}
                            disabled={currentInvoiceStatus !== InvoiceStatus.Default}
                          >
                            <div>
                              <SelectedDropdownOption
                                isTextPresent={!!terminalOption?.text}
                                data-testid="invoice-selected-terminal"
                              >
                                {terminalOption?.text || 'Select an online terminal...'}
                              </SelectedDropdownOption>
                            </div>
                            <div>
                              <ChevronDownIcon />
                            </div>
                          </DropdownRow>
                        </SearchField>
                      </Dropdown>
                    )}
                  </InvoiceDropdownContainer>
                ) : (
                  <></>
                )}
              </>
            )}

            {(currentPaymentMedium !== PaymentMedium.StripeTerminal || terminalOptions?.length !== 0) && (
              <>
                {isFeatureEnabled(FeatureFlagName.InvoiceDiscovery) && isInvoiceDiscoverySupported && (
                  <DiscoveredInvoices
                    data-testid="discovered-invoices"
                    clinicPetParent={selectedPetParent}
                    preSelectedInvoiceIds={invoiceIds || []}
                    setSelectedInvoices={setSelectedInvoices}
                    setSelectedBalanceAmount={setSelectedBalanceAmount}
                  />
                )}
                <HasFeature name={FeatureFlagName.CarePlans}>
                  {!!enrolledPets.length && (
                    <Box px={3} pt={3} pb={5}>
                      <CareBenefitsBanner
                        clinicPetParentId={clinicPetParentId}
                        hasMultipleEnrolledPets={enrolledPets.length > 1}
                        organizationPetId={enrolledPets.length > 1 ? undefined : enrolledPets[0]?.organizationPet?.id}
                      />
                    </Box>
                  )}
                </HasFeature>
                <HR />
                <FieldTitle
                  fieldTitleText={`${isInvoiceDiscoverySupported ? 'Manual ' : ''}Amount`}
                  isRequired={false}
                />
                <SearchField readOnly={manualAmountInputDisabled}>
                  $
                  <FieldInput
                    width="90%"
                    isMobile={isMobile}
                    placeholder="0.00"
                    type="text"
                    onChange={handleChangeInvoiceAmount}
                    readOnly={manualAmountInputDisabled}
                    value={manualAmountInputDisplayValue}
                    disabled={currentInvoiceStatus !== InvoiceStatus.Default}
                    data-testid="invoice-amount-input"
                    onBlur={(e): void => {
                      if (e.currentTarget.value.trim()) {
                        setManualAmount(parseFloat(e.currentTarget.value).toFixed(2));
                      }
                    }}
                  />
                </SearchField>
                {showWritebackEnabledStatus && (
                  <Flex justifyContent="center" alignItems="center" mb={3}>
                    {isWritebackManualPaymentEnabled && <Icon name="transfer" size="sm" />}
                    <Text size="sm" ml={2}>{`Writeback to account ${
                      isWritebackManualPaymentEnabled ? 'enabled' : 'disabled'
                    }`}</Text>
                  </Flex>
                )}
                {showClientFee && (
                  <>
                    <HR />
                    <Flex mx={5} my={2} mt={2} justifyContent="space-between" alignItems="center">
                      {isFeatureEnabled(FeatureFlagName.SurchargeUpdates) ? (
                        <Checkbox
                          isChecked={isClientFeeCheckboxChecked}
                          onChange={handleClientFeeCheckboxChange}
                          labelPosition="start"
                          m={0}
                          p={0}
                        >
                          <HStack>
                            <Text size="sm">{`Client Service Fee: (${clientFeePercentDisplay}%)`}</Text>
                            <InfoIcon text={clientServiceFeeMessage} />
                          </HStack>
                        </Checkbox>
                      ) : (
                        <Flex>
                          <Text size="sm">{`Client Service Fee: (${clientFeePercentDisplay}%)`}</Text>
                          <InfoIcon text={clientServiceFeeMessage} />
                        </Flex>
                      )}
                      <Button
                        variant="ghostNeutral"
                        size="sm"
                        iconName="pen"
                        iconPosition="left"
                        onClick={handleToggleIsEditing}
                        data-testid="edit-fee-button"
                        _hover={{ bg: 'background.transparent', color: 'text.interactive' }}
                      >
                        Edit
                      </Button>
                    </Flex>
                    {isEditingFee && (
                      <SearchField>
                        <FieldInput
                          width="60px"
                          onChange={handleChangeClientServiceFeePercent}
                          value={
                            clientServiceFeePercent && clientServiceFeeMax
                              ? parseFloat(clientServiceFeePercent) > clientServiceFeeMax
                                ? ''
                                : clientServiceFeePercent
                              : ''
                          }
                          onBlur={(): void =>
                            setClientServiceFeePercent((amount: string | undefined) =>
                              amount ? parseFloat(amount).toFixed(2) : amount,
                            )
                          }
                          disabled={isInputDisabled}
                          placeholder={clientServiceFee ? `${clientServiceFeePercent}` : `0.00`}
                          isMobile={isMobile}
                        />
                        %
                      </SearchField>
                    )}
                    {showClientFeeAlert && !isFeatureEnabled(FeatureFlagName.SurchargeUpdates) && (
                      <Alert
                        mx={5}
                        mb={2}
                        status="warning"
                        title={clientFeeAlertTitle}
                        onClose={(): void => setShowClientFeeAlert(false)}
                      />
                    )}
                  </>
                )}
                <HR />
                <Flex direction="column" pt={4} mx={5} gap={1}>
                  {isInvoiceDiscoverySupported && (
                    <Flex direction="column" pb={2} gap={2}>
                      {!!selectedBalanceAmount && (
                        <Flex justifyContent="space-between">
                          <Text size="sm">{balanceLabel}</Text>
                          <Text size="sm">{getMonetaryDisplayValue(selectedBalanceAmount, true)}</Text>
                        </Flex>
                      )}
                      {selectedInvoices.map((invoice) => (
                        <Flex key={`invoice-amount-${invoice.id}`} justifyContent="space-between">
                          <Text size="sm">{`Invoice ${getInvoiceIdentifierCopy(
                            invoice.identifier,
                            invoice.date,
                          )}`}</Text>
                          <Text size="sm">{getMonetaryDisplayValue(invoice.total, true)}</Text>
                        </Flex>
                      ))}
                      {hasManualAmount && (
                        <Flex justifyContent="space-between">
                          <Text size="sm">Manual Amount</Text>
                          <Text size="sm">{manualAmountDisplayValue}</Text>
                        </Flex>
                      )}
                    </Flex>
                  )}
                  {showClientFee && !!clientServiceFeePercent && (
                    <>
                      <Divider />
                      <Flex pt={2} justifyContent="space-between">
                        <Text size="sm">Subtotal</Text>
                        <Text size="sm">${amountToInvoice || 0}</Text>
                      </Flex>
                      <Flex justifyContent="space-between">
                        <Text size="sm">Client Service Fee</Text>
                        <Text size="sm">${clientServiceFee.toFixed(2) || '--'}</Text>
                      </Flex>

                      {showCashDiscount && (
                        <Flex justifyContent="space-between">
                          <Text size="sm" color="text.success">
                            Cash Discount:
                          </Text>
                          <Text size="sm" color="text.success">
                            -(${cashDiscountAmount.toFixed(2)})
                          </Text>
                        </Flex>
                      )}
                    </>
                  )}
                </Flex>
                <Flex mx={5} my={3} justifyContent="space-between">
                  <Text fontWeight="bold">Amount Due</Text>
                  <Text fontWeight="bold">{`$${(amountDue - cashDiscountAmount).toFixed(2)}`}</Text>
                </Flex>
                {isFeatureEnabled(FeatureFlagName.SurchargeUpdates) &&
                  hasCustomizableFees &&
                  clientServiceFee > 0 &&
                  currentPaymentMedium === PaymentMedium.StripeVirtualTerminal && (
                    <>
                      {fundingType === CardFunding.Credit || fundingType === undefined ? (
                        <Alert
                          mx={5}
                          iconProps={{ name: 'infoCircle' }}
                          status="info"
                          title={`Save $${clientServiceFee.toFixed(2)} by paying with Debit`}
                          titleProps={{ size: 'xs' }}
                          hideCloseButton={true}
                        />
                      ) : (
                        <Alert
                          mx={5}
                          status="success"
                          iconProps={{ name: 'checkmark' }}
                          title="Cash discount applied!"
                          titleProps={{ size: 'xs', color: 'text.success' }}
                          hideCloseButton={true}
                        />
                      )}
                    </>
                  )}
              </>
            )}
          </InnerContainer>

          {/* ***Fixed position*** bottom button container to execute the contents of the form */}
          <ActionDiv isMobile={isMobile} isTouchDevice={isTouchDevice} popover={popover}>
            {currentInvoiceStatus !== InvoiceStatus.Default && (
              <InvoiceStatusMessage
                clientFirstName={selectedPetParent?.firstName || 'this client'}
                clientLastName={selectedPetParent?.lastName || ''}
                status={currentInvoiceStatus}
                invoiceTotal={amountDue.toFixed(2)}
                paymentMethodType={
                  currentPaymentMedium === PaymentMedium.StripeTerminal
                    ? StripePaymentMethodType.CardPresent
                    : StripePaymentMethodType.CardNotPresent
                }
                terminalName={terminalOption?.text || 'Unknown terminal'}
                dismissCallback={(): void => {
                  if (currentInvoiceStatus === InvoiceStatus.NoTerminalsFound) {
                    if (window.zE) {
                      window.zE('messenger', 'open');
                    }
                    return;
                  }

                  // Since we have a payment intent that's saved for manual retries,
                  // we'll cancel it and clear the terminal display to be cleaned up.
                  if (currentInvoiceStatus === InvoiceStatus.TerminalProcessRetry) {
                    cancelInvoice(true);
                  }

                  resetPanelState(
                    currentInvoiceStatus === InvoiceStatus.Canceled, // isCanceling
                    currentInvoiceStatus === InvoiceStatus.Error ||
                      currentInvoiceStatus === InvoiceStatus.TerminalProcessRetry, // isError
                  );

                  setCurrentInvoiceStatus(InvoiceStatus.Default);
                  if (currentInvoiceStatus === InvoiceStatus.Received) setReceiptEmail('');
                }}
                cancelCallback={cancelInvoice}
                retryCallback={handleSubmitTerminalInvoice}
                data-testid="invoice-status"
                errorMessage={errorMessage}
                isTransactionLoading={isTransactionLoading}
              />
            )}
            {currentInvoiceStatus !== InvoiceStatus.NoTerminalsFound &&
              currentInvoiceStatus !== InvoiceStatus.Pending &&
              currentInvoiceStatus !== InvoiceStatus.Sending &&
              currentInvoiceStatus !== InvoiceStatus.TerminalProcessRetry &&
              currentInvoiceStatus !== InvoiceStatus.CardProcessing && (
                <Tooltip label={submitButtonTooltipMessage}>
                  <Flex alignItems="center" justifyContent="center">
                    <Button
                      isLoading={isPaymentIntentLoading || isTransactionLoading}
                      disabled={isSubmitButtonDisabled}
                      onClick={(): void => {
                        if (currentPaymentMedium && !isSubmitButtonDisabled) {
                          if (currentPaymentMedium === PaymentMedium.StripeTerminal) handleSubmitTerminalInvoice();
                          if (currentPaymentMedium === PaymentMedium.StripeVirtualTerminal)
                            handleSubmitDigitalInvoice();
                        }
                      }}
                      tabIndex={0}
                      data-testid="invoice-submit-button"
                      w="100%"
                      mx={5}
                      mt={3}
                    >
                      Make Payment
                    </Button>
                  </Flex>
                </Tooltip>
              )}
          </ActionDiv>
        </Container>
      )}
      <ImportantNoticeModal
        onClose={onModalClose}
        isOpen={isModalOpen}
        loading={isCancelingPayment}
        confirmCallback={(): void => {
          cancelInvoice();
          setSelectedPetParent(undefined);
          setReceiptEmail('');
          setClientSearchValue('');
          setCurrentInvoiceStatus(InvoiceStatus.Default);
          setViewBenefitSource(BenefitAlertSource.MessageComposer);

          if (isMobile) {
            setIsOpen(false);
          }
        }}
        cancelCallback={continueTransaction}
        data-testid="invoice-progress-warning"
      />
    </>
  );
};

export default InvoicingPanel;

export const HorizontalPadding = styled.div<ISearchFieldProps>`
  margin: 0 20px;
`;

export const VerticalPadding = styled.div<{ padding?: number }>`
  padding: ${({ padding }): string => (padding ? padding + 'px 0' : '6px 0')};
`;

const Container = styled.div`
  height: 100%;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const Header = styled.div`
  padding: 20px;
  border-bottom: solid 1px #d2d2d2;

  @media (${DeviceSizes.TabletMaxWidth}) {
    width: 100%;
  }
`;

interface ISearchFieldProps {
  readOnly?: boolean | null;
}

const SearchField = styled(HorizontalPadding)`
  margin: 12px 20px 12px;
  padding: 8px;
  border-radius: 7px;
  border: solid 1px #d2d2d2;
  display: flex;
  align-items: center;
  background-color: ${({ readOnly }): string => (readOnly ? 'hsl(180,8%,90%)' : 'auto')};
  &:focus {
    border-color: hsl(190, 55%, 49%);
  }
`;

const FieldInput = styled.input<{ width?: string; isMobile: boolean }>`
  padding: 3px 0 0 8px;
  border: none;
  width: ${({ width }): string => (width ? width : '250px')};
  cursor: ${({ readOnly }): string => (readOnly ? 'not-allowed' : 'auto')};
  font-size: 16px;
  font-weight: 500;

  background-color: transparent;
`;

const DropdownRow = styled.div<{ disabled: boolean; isMobile: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${({ isMobile }): string => (isMobile ? '100%' : '275px')};
  cursor: ${({ disabled }): string => (disabled ? 'not-allowed' : 'pointer')};
`;

const Row = styled(HorizontalPadding)`
  display: flex;
  margin: 12px 20px;
  justify-content: space-between;
  align-items: baseline;
`;

const HR = styled.div`
  height: 1px;
  border-bottom: solid 1px #d2d2d2;
`;

const StyledCloseIcon = styled(CloseIcon)<{ $isEmpty: boolean }>`
  width: 12px;
  height: 12px;
  padding: 2px;
  cursor: pointer;
  border-radius: 50%;
  background-color: rgb(5, 15, 95);
  display: ${({ $isEmpty }): string => ($isEmpty ? 'inherit' : 'none')};

  & * {
    fill: #fff;
  }

  svg {
    margin-right: 8px;
  }

  &:hover,
  &:hover svg path {
    transform: scale(1.1);
  }
`;

const ChevronDownIcon = styled(ChevronIcon)`
  transform: rotate(-90deg);
  margin-right: 4px;
`;

const SelectedDropdownOption = styled.div<{ isTextPresent: boolean }>`
  opacity: ${({ isTextPresent }): number => (isTextPresent ? 1 : 0.7)};
`;

const InvoiceDropdownContainer = styled.div``;

const InnerContainer = styled.div<{ status: InvoiceStatus; isMobile: boolean }>`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  opacity: ${({ status }): number =>
    status === InvoiceStatus.Default || status === InvoiceStatus.NoTerminalsFound ? 1.0 : 0.5};
  max-height: 100%;
  padding-bottom: 200px;
  max-width: ${({ isMobile }): string => (isMobile ? 'none' : '335px')};
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const ActionDiv = styled.div<{ isMobile: boolean; isTouchDevice: boolean; popover: IPopover }>`
  position: ${({ isMobile }): string => (isMobile ? 'fixed' : 'absolute')};
  bottom: ${({ isMobile, isTouchDevice, popover }): string => {
    if (isMobile) {
      if (popover.isOpen && popover.name === PopoverNames.PhoneCall) {
        return '114';
      }
      return '50';
    } else if (isTouchDevice && popover.isOpen && popover.name === PopoverNames.PhoneCall) {
      return '50';
    }
    return '0';
  }}px;
  width: ${({ isMobile }): string => (isMobile ? '100%' : '350px')};
  padding: 16px 20px 30px;
  box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.1);
  background: #ffffff;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px 0 20px;
`;

const StyledCardElement = styled(CardElement)`
  border: none;
  width: 100%;
`;

const ListItems = styled.ul`
  font-size: 14px;
  font-weight: 600;
  margin: 12px 0;
  list-style-type: circle;
  list-style-position: inside;
`;

const ListItem = styled.li`
  margin: 8px;
`;

const SavingPaymentMethodOption = styled.div`
  margin: 20px 0 10px 20px;
  display: flex;
`;
