import React from 'react';
import { Modal, ModalBody, ModalCloseButton, useDisclosure } from '@televet/kibble-ui';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import {
  BalanceForClientFragment,
  CareBenefitType,
  InvoiceForClientFragment,
  SidePanelParentPetDataFragment,
} from 'shared/types/graphql';
import { resetCareWizardDraft } from '../../state/careWizardStepsSlice';
import ConfirmCloseModal from './ConfirmCloseModal';
import InvoiceTotalScreen from './Screens/InvoiceTotal';
import IncludedExamsScreen from './Screens/IncludedExams';
import SummaryScreen from './Screens/Summary';
import CarePassAndDiscountsScreen from './Screens/CarePassAndDiscounts';
import FinalStepsScreen from './Screens/FinalSteps';
import { StripeTerminalClinicPetParent } from 'shared/providers/StripeTerminalProvider/types/StripeTerminalClinicPetParent';
import { FeatureFlagName } from 'shared/enums';
import useFeatureFlag from 'shared/hooks/useFeatureFlag';
import usePaymentWritebackConfig from 'shared/hooks/usePaymentWritebackConfig';
import PetInfoHeader from './PaymentHelperComponents/PetInfoHeader';
import { usePetBenefits } from '../../hooks/usePetBenefits';

interface ICareWizardModalProps {
  isOpen: boolean;
  onClose: () => void;
  pet: SidePanelParentPetDataFragment | undefined;
  careStripeSubscriptionId: string;
  refreshInvoice: () => void;
  refreshBalance: () => void;
  clinicPetParent?: StripeTerminalClinicPetParent;
  propInvoiceData?: InvoiceForClientFragment[] | undefined;
  propBalanceData?: BalanceForClientFragment | undefined;
  emptyInvoiceMessage?: string;
}

const CareWizardModal = ({
  isOpen,
  onClose,
  pet,
  careStripeSubscriptionId,
  refreshInvoice,
  refreshBalance,
  clinicPetParent,
  propInvoiceData,
  propBalanceData,
  emptyInvoiceMessage,
}: ICareWizardModalProps): JSX.Element => {
  const currentStep = useAppSelector((state) => state.CareWizardSteps.careWizardDraft.currentStep);
  const originalInvoiceCost = useAppSelector((state) => state.CareWizardSteps.careWizardDraft.originalInvoiceCost);
  const totalInvoiceSavings = useAppSelector((state) => state.CareWizardSteps.careWizardDraft.totalInvoiceSavings);
  const careWizardDraft = useAppSelector((state) => state.CareWizardSteps.careWizardDraft);
  const { selectedInvoiceId, isBalanceSelected } = careWizardDraft;
  const invoiceOrBalanceSelected = !!selectedInvoiceId || !!isBalanceSelected;
  const paymentDue = useAppSelector((state) => state.CareWizardSteps.careWizardDraft.paymentDue);
  const { isFeatureEnabled } = useFeatureFlag();
  const isWizardPaymentsFFEnabled = isFeatureEnabled(FeatureFlagName.WizardPaymentIntegrations);
  const { isClinicWritebackEnabled } = usePaymentWritebackConfig();
  const isLastStepAndFFEnabled = isWizardPaymentsFFEnabled && currentStep === 5;
  // show the header unless the wizard FF is enabled AND it is the last step except if writebacks are also not enabled
  const showHeader = !isClinicWritebackEnabled ? true : !isLastStepAndFFEnabled || !invoiceOrBalanceSelected;

  const dispatch = useAppDispatch();
  const { isOpen: isConfirmModalOpen, onOpen: onConfirmModalOpen, onClose: onConfirmModalClose } = useDisclosure();

  const handleClose = (confirm: boolean): void => {
    if (confirm && currentStep !== 5) {
      onConfirmModalOpen();
      return;
    }
    dispatch(resetCareWizardDraft());
    onClose();
  };

  const { benefits } = usePetBenefits(pet?.organizationPet?.id || '');

  const accountCredit = benefits.find((benefit) => benefit.type === CareBenefitType.AccountCredit);
  const percentDiscount = benefits.find((benefit) => benefit.type === CareBenefitType.Discount);


  const renderScreen = (step: number): JSX.Element | undefined => {
    switch (step) {
      case 1:
        return (
          <InvoiceTotalScreen
            currentStep={currentStep}
            petName={pet?.name || ''}
            clinicPetParent={clinicPetParent}
            propInvoiceData={propInvoiceData}
            propBalanceData={propBalanceData}
            emptyInvoiceMessage={emptyInvoiceMessage}
          />
        );
      case 2:
        return (
          <IncludedExamsScreen
            accountCredit={accountCredit}
            percentDiscount={percentDiscount}
            currentStep={currentStep}
            pet={pet}
          />
        );
      case 3:
        return <CarePassAndDiscountsScreen pet={pet} />;
      case 4:
        return (
          <SummaryScreen
            currentStep={currentStep}
            pet={pet}
            careStripeSubscriptionId={careStripeSubscriptionId}
            clinicPetParentId={clinicPetParent?.id}
            accountCredit={accountCredit}
            percentDiscount={percentDiscount}
          />
        );
      case 5:
        return (
          <FinalStepsScreen
            clinicPetParent={clinicPetParent}
            emptyInvoiceMessage={emptyInvoiceMessage}
            pet={pet}
            onClose={(): void => handleClose(false)}
            refreshInvoice={refreshInvoice}
            refreshBalance={refreshBalance}
          />
        );
      default:
        break;
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={(): void => handleClose(true)}>
      {showHeader && (
        <PetInfoHeader
          title="Calculate Benefits"
          pet={pet}
          originalInvoiceCost={originalInvoiceCost}
          totalInvoiceSavings={totalInvoiceSavings}
          paymentDue={paymentDue}
          currentStep={currentStep}
          isWizardPaymentsFFEnabled={isWizardPaymentsFFEnabled}
          isLastStepAndFFEnabled={isLastStepAndFFEnabled}
          accountCredit={accountCredit}
          percentDiscount={percentDiscount}
        />
      )}
      <ModalCloseButton data-testid="wizard-modal-close-button" />
      <ModalBody>{renderScreen(currentStep)}</ModalBody>
      <ConfirmCloseModal
        isOpen={isConfirmModalOpen}
        onClose={onConfirmModalClose}
        handleClose={handleClose}
        currentStep={currentStep}
      />
    </Modal>
  );
};

export default CareWizardModal;
