export enum ModalNames {
  Appointment = 'appointment',
  EditAppointmentType = 'editAppointmentType',
  CustomSnooze = 'customSnooze',
  DeleteAppointmentGrouping = 'deleteAppointmentGrouping',
  ChangePassword = 'changePassword',
  CreatePassword = 'createPassword',
  ChangeEmail = 'changeEmail',
  CropImage = 'cropImage',
  EditUser = 'editUser',
  DeleteUser = 'deleteUser',
  ChatMediaPreview = 'chatMediaPreview',
  RequestTypes = 'requestTypes',
  CancelRefundInvoice = 'cancelRefundInvoice',
  EditServiceFee = 'editServiceFee',
  MarkAllAsReadConfirmation = 'markAllAsReadConfirmation',
  VerifyPhoneNumber = 'verifyPhoneNumber',
  ClickOnMessageAutomationButton = 'clickOnMessageAutomationButton',
  Refund = 'partialRefund',
  ConfigurePhoneNumbers = 'configurePhoneNumbers',
  FormExport = 'formExport',
  AddTeamChannel = 'addTeamChannel',
  EditTeamChannel = 'editTeamChannel',
  InvoicePreview = 'invoicePreview',
  ManageBenefitUsage = 'manageBenefitUsage',
  RefundReason = 'refundReason',
  CustomizeExportedConversationName = 'customizeExportedConversationName',
  KeyboardShortcutHelpModal = 'keyboardShorcutHelpModal',
  CreateDirectMessage = 'createDirectMessage',
  DeleteEmergencyContact = 'deleteEmergencyContact',
  EditEmergencyContact = 'editEmergencyContact',
  SendMassTextAlertConfirmation = 'sendMassTextAlertConfirmation',
  SelectMassTextAlertCustomDateRange = 'selectMassTextAlertCustomDateRange',
}
