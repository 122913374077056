import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { Button, Flex, Box, Text, ButtonGroup } from '@televet/kibble-ui';
import * as Sentry from '@sentry/react';
import { useCustomToast, STATUSES } from 'shared/hooks/useCustomToast';
import { Mixpanel } from 'shared/utils/mixpanel';
import { SurveySettingsActions } from '../context/SurveySettingsReducer';
import {
  useGetSurveyWorkflowEventSettingsQuery,
  SurveyStep,
  useUpdateSurveyGeneralSettingsMutation,
  useUpsertSurveyAppointmentTypeSettingsMutation,
  useSaveWorkflowEventSettingsMutation,
  useGetSurveyGeneralSettingsQuery,
  useGetSurveyAppointmentTypeSettingsQuery,
  useGetDefaultSurveyStatusesQuery,
  ChannelStatusAction,
  useInitSurveySettingMutation,
  WorkflowEventAction,
  WorkflowEventSetting,
  SurveyType,
  useCreateSurveyChannelStatusMutation,
} from 'shared/types/graphql';
import useClinicUser from 'shared/hooks/useClinicUser';
import { ISurveyComposeSettings, useSurveySettingsContext } from '../context/SurveySettingsContext';
import { url, www } from 'shared/utils/validation';
import { DefaultNegativeStatusNames, DefaultPositiveStatusNames } from './SurveyContainer';
import { GraphQLFetchPolicies } from 'shared/enums/GraphQLFetchPolicies';
import { GET_CLINIC_WORKFLOWS_EVENT_SETTINGS } from 'shared/queries/workflows';
import { useLazyQuery } from '@apollo/client/react';
import isSameSecond from 'date-fns/isSameSecond';

interface ISurveyFooterProps {
  unsavedChanges: boolean;
}

const SurveyFooter = ({ unsavedChanges }: ISurveyFooterProps): JSX.Element => {
  const {
    surveyEnabled,
    surveyType,
    changedSettings,
    isSaving,
    dispatch,
    composeSettings,
    surveySettingId,
    generalSettings,
    appointmentTypeSettings,
    generalSettings: { isAutomatedTriggerForAllAppointmentTypes },
  } = useSurveySettingsContext();
  const { toastMessagePop } = useCustomToast();
  const { currentClinicId, currentClinic } = useClinicUser();
  const clinicName = currentClinic?.name || '';
  const clinicSettingId = currentClinic?.clinicSetting?.id || '';

  const { data: surveyAppointmentTypeSettingsData, loading: isLoadingAppointmentTypeSettings } =
    useGetSurveyAppointmentTypeSettingsQuery({
      variables: { clinicId: currentClinicId || '' },
      skip: !currentClinicId,
      fetchPolicy: GraphQLFetchPolicies.CacheAndNetwork,
      onCompleted: (data) => {
        const hasUpdatedApptType = data.findManyAppointmentType?.some(({ surveyAppointmentTypeSettings }) => {
          return surveyAppointmentTypeSettings.some((type) => {
            return !isSameSecond(new Date(type.createdAt), new Date(type.updatedAt));
          });
        });

        // if no survey appt type settings have been changed since created, look to migrate old csat
        if (!hasUpdatedApptType) {
          setCheckForOldWorkflow(true);
        }
        setInitialAppointmentTypeSettings();
      },
    });

  // Taken from old onboarding code
  const oldCsatWorkflowName = 'Customer Satisfaction Survey';
  const oldDetractorWorkflowName = 'Detractor - Start a Conversation';
  const [getOldCsatWorkflows, { data: oldCsatWorkflowData, loading: isLoadingOldCsatWorkflow }] = useLazyQuery(
    GET_CLINIC_WORKFLOWS_EVENT_SETTINGS,
  );

  const [checkForOldWorkflow, setCheckForOldWorkflow] = useState(false);
  const [initComplete, setInitComplete] = useState(false);
  const [initialWorkflowSettingsSet, setInitialWorkflowSettingsSet] = useState(false);
  const [initialGeneralSettingsSet, setInitialGeneralSettingsSet] = useState(false);
  const [initialApptTypeSettingsSet, setInitialApptTypeSettingsSet] = useState(false);

  const setupComplete = useMemo(
    () => initialWorkflowSettingsSet && initialGeneralSettingsSet && initialApptTypeSettingsSet,
    [initialApptTypeSettingsSet, initialGeneralSettingsSet, initialWorkflowSettingsSet],
  );

  const { data: surveyWorkflowsData, refetch: refetchSurveyWorkflows } = useGetSurveyWorkflowEventSettingsQuery({
    variables: { clinicId: currentClinicId || '' },
    skip: !currentClinicId,
    fetchPolicy: GraphQLFetchPolicies.CacheAndNetwork,
    onCompleted: async () => {
      // create workflows if they don't already exist
      if (!surveyWorkflowsData?.findManyWorkflowEventSetting?.length) {
        setCheckForOldWorkflow(true);
        if (currentClinicId) {
          await initSurveySetting();
        }
        await refetchSurveyWorkflows();
      }
      await setInitialWorkflowSettings();
    },
  });

  const { data: surveyGeneralSettingsData, loading: isLoadingGeneralSettings } = useGetSurveyGeneralSettingsQuery({
    variables: { settingId: surveySettingId },
    skip: !surveySettingId,
    fetchPolicy: GraphQLFetchPolicies.CacheAndNetwork,
    onCompleted: () => setInitialGeneralSettings(),
  });

  const [updateWorkflowEventSetting] = useSaveWorkflowEventSettingsMutation();
  const [updateGeneralSettings] = useUpdateSurveyGeneralSettingsMutation();
  const [updateAppointmentTypeSettings] = useUpsertSurveyAppointmentTypeSettingsMutation();
  const [createChannelStatus] = useCreateSurveyChannelStatusMutation();

  const ratingWorkflow = useMemo(
    () =>
      surveyWorkflowsData?.findManyWorkflowEventSetting?.find(
        (workflow) => workflow.surveyStep === SurveyStep.Rating && workflow.surveyType === surveyType,
      ),
    [surveyType, surveyWorkflowsData?.findManyWorkflowEventSetting],
  );

  const positiveWorkflow = useMemo(
    () =>
      surveyWorkflowsData?.findManyWorkflowEventSetting?.find(
        (workflow) =>
          (workflow.surveyStep === SurveyStep.PassivePromoterFeedback ||
            workflow.surveyStep === SurveyStep.SatisfiedFeedback) &&
          workflow.surveyType === surveyType,
      ),
    [surveyType, surveyWorkflowsData?.findManyWorkflowEventSetting],
  );

  const negativeWorkflow = useMemo(
    () =>
      surveyWorkflowsData?.findManyWorkflowEventSetting?.find(
        (workflow) =>
          (workflow.surveyStep === SurveyStep.DetractorFeedback ||
            workflow.surveyStep === SurveyStep.DissatisfiedFeedback) &&
          workflow.surveyType === surveyType,
      ),
    [surveyType, surveyWorkflowsData?.findManyWorkflowEventSetting],
  );

  const negativeStatusNames =
    surveyType === SurveyType.Csat
      ? [DefaultNegativeStatusNames[surveyType], 'Dissatisfied']
      : [DefaultNegativeStatusNames[surveyType]];

  const positiveStatusNames =
    surveyType === SurveyType.Csat
      ? [DefaultPositiveStatusNames[surveyType], 'Satisfied']
      : [DefaultPositiveStatusNames[surveyType]];

  const sentStatusNames = ['Survey Sent'];

  const { data: defaultSurveyStatuses } = useGetDefaultSurveyStatusesQuery({
    variables: {
      clinicSettingId,
      positiveStatusNames,
      negativeStatusNames,
      sentStatusNames,
    },
    skip: !clinicSettingId || !negativeWorkflow?.id,
    fetchPolicy: GraphQLFetchPolicies.NetworkOnly,
    onCompleted: async () => {
      await createDefaultChannelStatuses();
      setInitComplete(true);
    },
  });

  const defaultNegativeStatus = useMemo(() => defaultSurveyStatuses?.negativeStatus, [defaultSurveyStatuses]);
  const defaultPositiveStatus = useMemo(() => defaultSurveyStatuses?.positiveStatus, [defaultSurveyStatuses]);
  const defaultSentStatus = useMemo(() => defaultSurveyStatuses?.sentStatus, [defaultSurveyStatuses]);

  const [initSurveySetting] = useInitSurveySettingMutation({
    variables: { clinicId: currentClinicId || '' },
  });

  const surveyIsActive = useMemo(
    (): boolean =>
      generalSettings.isAutomatedTriggerForAllAppointmentTypes ||
      appointmentTypeSettings.some(({ isActive }) => isActive),
    [appointmentTypeSettings, generalSettings.isAutomatedTriggerForAllAppointmentTypes],
  );

  const saveWorkflowEventSettingsChanges = useCallback(async () => {
    if (!ratingWorkflow?.id || !positiveWorkflow?.id || !negativeWorkflow?.id) return;

    // update rating workflow
    // there's only one action
    const ratingAction = ratingWorkflow.actions[0];
    if (ratingAction && (changedSettings.ratingWorkflowChanged || ratingWorkflow.isPublished !== surveyIsActive)) {
      await updateWorkflowEventSetting({
        variables: {
          where: {
            id: ratingWorkflow.id,
          },
          data: {
            name: composeSettings.surveyName,
            notificationBody: composeSettings.introMessage,
            isPublished: surveyIsActive,
            sendingHours: composeSettings.sendHours,
            actions: {
              update: [
                {
                  where: {
                    id: ratingAction.id,
                  },
                  data: {
                    config: {
                      ...ratingAction.config,
                      messageBodyTemplate: composeSettings.ratingQuestion,
                    },
                    channelStatusChangeId: composeSettings.sentStatusId || null,
                  },
                },
              ],
            },
          },
        },
      });
    }

    // update positive workflow
    // update first action (feedback step)
    const positiveAction = positiveWorkflow.actions.find((action) => action.order === 1);
    if (
      positiveAction &&
      (changedSettings.positiveWorkflowChanged || positiveWorkflow.isPublished !== surveyIsActive)
    ) {
      await updateWorkflowEventSetting({
        variables: {
          where: {
            id: positiveWorkflow.id,
          },
          data: {
            name: composeSettings.surveyName,
            notificationBody: composeSettings.introMessage,
            isPublished: surveyIsActive,
            actions: {
              update: [
                {
                  where: {
                    id: positiveAction.id,
                  },
                  data: {
                    config: {
                      ...positiveAction.config,
                      messageBodyTemplate: composeSettings.positiveQuestion,
                    },
                    channelStatusChangeId: composeSettings.positiveStatusId || null,
                  },
                },
              ],
            },
          },
        },
      });
    }

    // update negative workflow
    // update first action (feedback step)
    const negativeAction = negativeWorkflow.actions.find((action) => action.order === 1);
    if (
      negativeAction &&
      (changedSettings.negativeWorkflowChanged || negativeWorkflow.isPublished !== surveyIsActive)
    ) {
      await updateWorkflowEventSetting({
        variables: {
          where: {
            id: negativeWorkflow.id,
          },
          data: {
            name: composeSettings.surveyName,
            notificationBody: composeSettings.introMessage,
            isPublished: surveyIsActive,
            actions: {
              update: [
                {
                  where: {
                    id: negativeAction.id,
                  },
                  data: {
                    config: {
                      ...negativeAction.config,
                      messageBodyTemplate: composeSettings.negativeQuestion,
                    },
                    channelStatusChangeId: composeSettings.negativeStatusId || null,
                  },
                },
              ],
            },
          },
        },
      });
    }
  }, [
    ratingWorkflow,
    positiveWorkflow,
    negativeWorkflow,
    composeSettings,
    updateWorkflowEventSetting,
    changedSettings,
    surveyIsActive,
  ]);

  const saveGeneralSettingsChanges = useCallback(async () => {
    if (changedSettings.surveySettingChanged && surveySettingId) {
      updateGeneralSettings({
        variables: {
          where: {
            id: surveySettingId,
          },
          data: generalSettings,
        },
      });
    }
  }, [generalSettings, surveySettingId, updateGeneralSettings, changedSettings.surveySettingChanged]);

  const saveAppointmentTypeSettingChanges = useCallback(async () => {
    if (appointmentTypeSettings.length && surveySettingId) {
      appointmentTypeSettings.forEach((appointmentType) => {
        if (changedSettings.surveyAppointmentTypeSettingIds.includes(appointmentType.appointmentTypeId)) {
          updateAppointmentTypeSettings({
            variables: {
              where: {
                id: appointmentType.id || '',
              },
              create: {
                appointmentType: {
                  connect: {
                    id: appointmentType.appointmentTypeId,
                  },
                },
                surveySetting: {
                  connect: {
                    id: surveySettingId,
                  },
                },
                isActive: isAutomatedTriggerForAllAppointmentTypes ? true : appointmentType.isActive,
                isDeleted: false,
                automatedTriggerInMinutes: appointmentType.automatedTriggerInMinutes,
              },
              update: {
                isActive: isAutomatedTriggerForAllAppointmentTypes ? true : appointmentType.isActive,
                automatedTriggerInMinutes: appointmentType.automatedTriggerInMinutes,
              },
            },
          });
        }
      });
    }
  }, [
    appointmentTypeSettings,
    updateAppointmentTypeSettings,
    surveySettingId,
    isAutomatedTriggerForAllAppointmentTypes,
    changedSettings.surveyAppointmentTypeSettingIds,
  ]);

  // look for existing active csat surveys to migrate
  // we will use their details as default for the new survey
  // once the new survey workflows are saved, the old ones will be deactivated
  useEffect(() => {
    if (!currentClinicId || !checkForOldWorkflow || !setupComplete) return;

    const sharedQueryVars = {
      clinicId: {
        equals: currentClinicId,
      },
      surveySettingId: {
        equals: null,
      },
      isPublished: {
        equals: true,
      },
    };

    getOldCsatWorkflows({
      variables: {
        where: {
          OR: [
            {
              ...sharedQueryVars,
              name: {
                equals: oldCsatWorkflowName,
              },
            },
            {
              ...sharedQueryVars,
              name: {
                equals: oldDetractorWorkflowName,
              },
            },
          ],
        },
      },
    });
  }, [checkForOldWorkflow, currentClinicId, getOldCsatWorkflows, setupComplete]);

  const oldCsatWorkflow = useMemo(() => {
    return oldCsatWorkflowData?.findManyWorkflowEventSetting?.find(
      (workflow: WorkflowEventSetting) => workflow.name === oldCsatWorkflowName,
    );
  }, [oldCsatWorkflowData?.findManyWorkflowEventSetting]);

  const oldDetractorWorkflow = useMemo(() => {
    return oldCsatWorkflowData?.findManyWorkflowEventSetting?.find(
      (workflow: WorkflowEventSetting) => workflow.name === oldDetractorWorkflowName,
    );
  }, [oldCsatWorkflowData?.findManyWorkflowEventSetting]);

  const oldCsatApptTypes = useMemo(
    () => oldCsatWorkflow?.appointmentTypes.map((apptType: WorkflowEventSetting) => apptType.id),
    [oldCsatWorkflow?.appointmentTypes],
  );

  const oldReviewLink = useMemo(() => {
    const oldCsatFeedbackPrompt =
      oldCsatWorkflow?.actions?.find((action: WorkflowEventAction) => action.order === 2)?.config.messageBodyTemplate ||
      '';

    let link = oldCsatFeedbackPrompt?.match(url)?.[0];

    // look for www link and add https to it
    if (!link) {
      link = oldCsatFeedbackPrompt?.match(www)?.[0];
      link = link ? `https://${link}` : link;
    }

    // remove trailing period if found
    if (!!link?.length && link[link.length - 1] === '.') {
      link = link.substring(0, link.length - 1);
    }

    return link;
  }, [oldCsatWorkflow?.actions]);

  // use old CSAT automation properties as defaults when first setting up
  // wait for init to complete if first time loading
  useEffect(() => {
    if (!oldCsatWorkflow || !initComplete) return;

    const { name, automatedTriggerInMinutes } = oldCsatWorkflow;

    const oldButtonAction = oldCsatWorkflow.actions?.find((action: WorkflowEventAction) => action.order === 1);
    const oldCsatMessage = oldButtonAction?.config?.messageBodyTemplate;
    const oldCsatCTA = oldButtonAction?.config?.callToActionBody;

    // the buttons are untyped JSON
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const oldSatisfiedStatus = oldButtonAction?.config?.buttons?.find((button: any) =>
      button.body?.includes('5'),
    )?.channelStatusChangeId;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const oldDissatisfiedStatus = oldButtonAction?.config?.buttons?.find((button: any) =>
      button.body?.includes('1'),
    )?.channelStatusChangeId;
    const oldSentStatus = oldButtonAction?.channelStatusChangeId;

    // compose settings
    const settings: Partial<ISurveyComposeSettings> = {};
    if (name) {
      settings.surveyName = name;
    }
    if (oldCsatMessage) {
      settings.introMessage = oldCsatMessage;
    }
    if (oldCsatCTA) {
      settings.ratingQuestion = oldCsatCTA;
    }
    if (oldSatisfiedStatus) {
      settings.positiveStatusId = oldSatisfiedStatus;
    }
    if (oldDissatisfiedStatus) {
      settings.negativeStatusId = oldDissatisfiedStatus;
    }
    if (oldSentStatus) {
      settings.sentStatusId = oldSentStatus;
    }
    dispatch({ type: SurveySettingsActions.UPDATE_COMPOSE_SETTINGS, payload: { ...composeSettings, ...settings } });
    dispatch({ type: SurveySettingsActions.SET_RATING_WORKFLOW_CHANGED, payload: true });
    dispatch({ type: SurveySettingsActions.SET_POSITIVE_WORKFLOW_CHANGED, payload: true });
    dispatch({ type: SurveySettingsActions.SET_NEGATIVE_WORKFLOW_CHANGED, payload: true });

    // general settings
    if (!!oldReviewLink) {
      dispatch({
        type: SurveySettingsActions.UPDATE_GENERAL_SETTINGS,
        payload: {
          showLinkToLeaveReviewNeutral: true,
          showLinkToLeaveReviewSatisfied: true,
          linkToLeaveReview: oldReviewLink,
        },
      });
      dispatch({ type: SurveySettingsActions.SET_SURVEY_SETTING_CHANGED, payload: true });
    }

    // appt type settings
    if (oldCsatApptTypes) {
      if (surveyAppointmentTypeSettingsData?.findManyAppointmentType) {
        const data = surveyAppointmentTypeSettingsData.findManyAppointmentType;

        dispatch({
          type: SurveySettingsActions.UPDATE_APPOINTMENT_TYPE_SETTINGS,
          payload: data.map((item) => {
            const enabledInOldCsat = oldCsatApptTypes?.some((id: string) => id === item.id);
            const itemSettings = item.surveyAppointmentTypeSettings[0];

            // even if survey isn't enabled for this appt type and default delay isn't used, we want to dirty the settings
            if (automatedTriggerInMinutes !== 1440) {
              dispatch({
                type: SurveySettingsActions.ADD_CHANGED_SURVEY_APPOINTMENT_TYPE_SETTING_ID,
                payload: itemSettings.id,
              });
            }

            return {
              id: itemSettings?.id || null,
              isActive: !!enabledInOldCsat,
              automatedTriggerInMinutes: automatedTriggerInMinutes || 1440,
              appointmentTypeName: item.displayName || item.name,
              appointmentTypeId: item.id,
            };
          }),
        });
      }

      oldCsatApptTypes.forEach((apptTypeId: string) => {
        const surveyApptTypeId =
          appointmentTypeSettings.find((apptType) => apptType.appointmentTypeId === apptTypeId)?.id || '';

        // skip if already added above
        if (changedSettings.surveyAppointmentTypeSettingIds.includes(surveyApptTypeId)) return;

        dispatch({
          type: SurveySettingsActions.ADD_CHANGED_SURVEY_APPOINTMENT_TYPE_SETTING_ID,
          payload: surveyApptTypeId,
        });
      });
    }
    // only want the effect to trigger when old workflow data loads or init completes to avoid circular deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldCsatWorkflow, initComplete]);

  const disableOldCsatWorkflows = useCallback(async () => {
    if (oldCsatWorkflow?.id) {
      await updateWorkflowEventSetting({
        variables: {
          where: {
            id: oldCsatWorkflow.id,
          },
          data: {
            isPublished: false,
          },
        },
      });
    }

    if (oldDetractorWorkflow?.id) {
      await updateWorkflowEventSetting({
        variables: {
          where: {
            id: oldDetractorWorkflow.id,
          },
          data: {
            isPublished: false,
          },
        },
      });
    }
  }, [oldCsatWorkflow?.id, oldDetractorWorkflow?.id, updateWorkflowEventSetting]);

  const clearChangeContext = useCallback(() => {
    dispatch({ type: SurveySettingsActions.CLEAR_ALL_CHANGES });
  }, [dispatch]);

  const saveAllSettings = useCallback(async () => {
    try {
      dispatch({ type: SurveySettingsActions.SET_SURVEY_ENABLED, payload: surveyIsActive });

      await saveWorkflowEventSettingsChanges();
      await saveGeneralSettingsChanges();
      await saveAppointmentTypeSettingChanges();

      if (oldCsatWorkflow || oldDetractorWorkflow) {
        await disableOldCsatWorkflows();
      }

      clearChangeContext();
      toastMessagePop('Changes saved!', STATUSES.success);
      Mixpanel.track('Survey settings updated', { ...composeSettings, ...generalSettings });
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      toastMessagePop('An error occurred when saving your changes. Please try again.', STATUSES.error);
    }
  }, [
    dispatch,
    surveyIsActive,
    saveWorkflowEventSettingsChanges,
    saveGeneralSettingsChanges,
    saveAppointmentTypeSettingChanges,
    oldCsatWorkflow,
    oldDetractorWorkflow,
    clearChangeContext,
    toastMessagePop,
    composeSettings,
    generalSettings,
    disableOldCsatWorkflows,
  ]);

  const createDefaultChannelStatuses = useCallback(async () => {
    if (!clinicSettingId) return;

    let negativeStatusId = defaultNegativeStatus?.id,
      positiveStatusId = defaultPositiveStatus?.id,
      sentStatusId = defaultSentStatus?.id;

    if (!negativeStatusId) {
      const newNegativeWorkflow = surveyWorkflowsData?.findManyWorkflowEventSetting?.find(
        (workflow) =>
          (workflow.surveyStep === SurveyStep.DetractorFeedback ||
            workflow.surveyStep === SurveyStep.DissatisfiedFeedback) &&
          workflow.surveyType === surveyType,
      );

      const negativeAction = newNegativeWorkflow?.actions?.find((action) => action.order === 1);
      const negativeActionId = !composeSettings.negativeStatusId ? negativeAction?.id : undefined;

      if (negativeActionId && !defaultNegativeStatus?.id) {
        const negativeStatusData = await createChannelStatus({
          variables: {
            data: {
              channelStatusAction: ChannelStatusAction.Active,
              name: DefaultNegativeStatusNames[surveyType],
              clinicSetting: {
                connect: {
                  id: clinicSettingId,
                },
              },
              color: 'text.danger',
              order: 301,
              workflowEventAction: {
                connect: [
                  {
                    id: negativeActionId,
                  },
                ],
              },
            },
          },
        });
        negativeStatusId = negativeStatusData.data?.createOneChannelStatus.id;
      }
    }

    if (!positiveStatusId && !defaultPositiveStatus?.id) {
      const positiveStatusData = await createChannelStatus({
        variables: {
          data: {
            channelStatusAction: ChannelStatusAction.Active,
            name: DefaultPositiveStatusNames[surveyType],
            clinicSetting: {
              connect: {
                id: clinicSettingId,
              },
            },
            color: 'background.primary',
            order: 302,
          },
        },
      });
      positiveStatusId = positiveStatusData.data?.createOneChannelStatus.id;
    }

    if (!sentStatusId && !defaultSentStatus?.id) {
      const sentStatusData = await createChannelStatus({
        variables: {
          data: {
            channelStatusAction: ChannelStatusAction.Active,
            name: 'Survey Sent',
            clinicSetting: {
              connect: {
                id: clinicSettingId,
              },
            },
            color: 'background.moreSubtleContrast',
            order: 303,
          },
        },
      });
      sentStatusId = sentStatusData.data?.createOneChannelStatus.id;
    }

    return {
      negativeStatusId,
      positiveStatusId,
      sentStatusId,
    };
  }, [
    clinicSettingId,
    createChannelStatus,
    defaultNegativeStatus,
    defaultPositiveStatus,
    defaultSentStatus,
    surveyType,
    surveyWorkflowsData?.findManyWorkflowEventSetting,
    composeSettings.negativeStatusId,
  ]);

  const setInitialWorkflowSettings = useCallback(async () => {
    const settings: Partial<ISurveyComposeSettings> = {};

    if (ratingWorkflow) {
      const { name, notificationBody, actions, sendingHours } = ratingWorkflow;

      if (name) {
        settings.surveyName = name;
      }
      if (sendingHours) {
        settings.sendHours = sendingHours;
      }
      if (notificationBody) {
        settings.introMessage = notificationBody;
      } else {
        settings.introMessage = `Thank you for choosing ${
          clinicName || 'us'
        } as your healthcare provider for your pet {{petName}}. Would you mind taking a minute to fill out a quick survey for us about your visit?`;
      }

      const action = actions?.[0];
      if (action?.config?.messageBodyTemplate) {
        settings.ratingQuestion = action.config.messageBodyTemplate;
      }
      settings.sentStatusId = action?.channelStatusChange?.id || '';
    }

    if (positiveWorkflow) {
      const { actions } = positiveWorkflow;
      const action = actions.find((action) => action.order === 1);
      if (action?.config.messageBodyTemplate) {
        settings.positiveQuestion = action.config.messageBodyTemplate;
      }
      settings.positiveStatusId = action?.channelStatusChange?.id || '';
    }

    if (negativeWorkflow) {
      const { actions } = negativeWorkflow;
      const action = actions.find((action) => action.order === 1);
      if (action?.config.messageBodyTemplate) {
        settings.negativeQuestion = action.config.messageBodyTemplate;
      }

      settings.negativeStatusId = action?.channelStatusChange?.id || defaultNegativeStatus?.id;
    }

    dispatch({ type: SurveySettingsActions.UPDATE_COMPOSE_SETTINGS, payload: { ...composeSettings, ...settings } });

    setInitialWorkflowSettingsSet(true);
  }, [
    ratingWorkflow,
    positiveWorkflow,
    negativeWorkflow,
    dispatch,
    composeSettings,
    clinicName,
    defaultNegativeStatus,
    setInitialWorkflowSettingsSet,
  ]);

  const setInitialGeneralSettings = useCallback(() => {
    if (surveyGeneralSettingsData?.findUniqueSurveySetting) {
      const data = surveyGeneralSettingsData.findUniqueSurveySetting;

      dispatch({
        type: SurveySettingsActions.UPDATE_GENERAL_SETTINGS,
        payload: {
          isAutomatedTriggerForAllAppointmentTypes: data.isAutomatedTriggerForAllAppointmentTypes,
          frequencyInDays: data.frequencyInDays,
          showLinkToLeaveReviewNeutral: data.showLinkToLeaveReviewNeutral,
          showLinkToLeaveReviewSatisfied: data.showLinkToLeaveReviewSatisfied,
          linkToLeaveReview: data.linkToLeaveReview,
        },
      });

      if (data.isAutomatedTriggerForAllAppointmentTypes) {
        dispatch({ type: SurveySettingsActions.SET_SURVEY_ENABLED, payload: true });
      }

      setInitialGeneralSettingsSet(true);
    }
  }, [surveyGeneralSettingsData?.findUniqueSurveySetting, dispatch, setInitialGeneralSettingsSet]);

  const setInitialAppointmentTypeSettings = useCallback(() => {
    if (surveyAppointmentTypeSettingsData?.findManyAppointmentType) {
      const data = surveyAppointmentTypeSettingsData.findManyAppointmentType;
      dispatch({
        type: SurveySettingsActions.UPDATE_APPOINTMENT_TYPE_SETTINGS,
        payload: data.map((item) => {
          const itemSettings = item.surveyAppointmentTypeSettings[0];
          return {
            id: itemSettings?.id || null,
            isActive: itemSettings?.isActive || false,
            automatedTriggerInMinutes: itemSettings?.automatedTriggerInMinutes || 1440,
            appointmentTypeName: item.displayName || item.name,
            appointmentTypeId: item.id,
          };
        }),
      });

      if (
        data.some(
          (apptType) =>
            apptType.surveyAppointmentTypeSettings?.length && apptType.surveyAppointmentTypeSettings[0].isActive,
        )
      ) {
        dispatch({ type: SurveySettingsActions.SET_SURVEY_ENABLED, payload: true });
      }

      setInitialApptTypeSettingsSet(true);
    }
  }, [surveyAppointmentTypeSettingsData?.findManyAppointmentType, dispatch, setInitialApptTypeSettingsSet]);

  const settingsDirtied = useMemo(
    () =>
      changedSettings.surveySettingChanged ||
      changedSettings.ratingWorkflowChanged ||
      changedSettings.positiveWorkflowChanged ||
      changedSettings.negativeWorkflowChanged ||
      changedSettings.surveyAppointmentTypeSettingIds.length,
    [changedSettings],
  );

  const settingsError = useMemo(
    () =>
      !composeSettings.negativeQuestion ||
      !composeSettings.positiveQuestion ||
      !composeSettings.ratingQuestion ||
      !composeSettings.introMessage ||
      (!!generalSettings.linkToLeaveReview && !url.test(generalSettings.linkToLeaveReview)),
    [composeSettings, generalSettings.linkToLeaveReview],
  );

  const cancelChanges = useCallback(() => {
    setInitialWorkflowSettings();
    setInitialGeneralSettings();
    setInitialAppointmentTypeSettings();
    clearChangeContext();
  }, [setInitialWorkflowSettings, setInitialGeneralSettings, setInitialAppointmentTypeSettings, clearChangeContext]);

  // Set loading state
  useEffect(() => {
    dispatch({
      type: SurveySettingsActions.SET_IS_LOADING,
      payload: isLoadingAppointmentTypeSettings || isLoadingGeneralSettings || isLoadingOldCsatWorkflow,
    });
  }, [isLoadingAppointmentTypeSettings, isLoadingGeneralSettings, isLoadingOldCsatWorkflow, dispatch]);

  // Set survey setting id
  useEffect(() => {
    if (surveyWorkflowsData?.findManyWorkflowEventSetting?.length) {
      dispatch({
        type: SurveySettingsActions.UPDATE_SETTINGS_ID,
        payload: surveyWorkflowsData?.findManyWorkflowEventSetting[0].surveySettingId,
      });
    }
  }, [surveyWorkflowsData, dispatch]);

  return (
    <Flex
      bg="background.default"
      borderTopWidth="1px"
      borderTopColor="border.default"
      flexDir="row"
      justifyContent="space-between"
      alignItems="center"
      p={3}
    >
      <Flex alignItems="center">
        <Box
          h="8px"
          w="8px"
          borderRadius="4px"
          bg={surveyEnabled ? 'background.primary' : 'background.mostSubtleContrast'}
          mr={2}
        />
        <Text variant="subtle" size="sm">
          {surveyType} survey is {!surveyEnabled && 'in'}active
        </Text>
      </Flex>
      <ButtonGroup>
        {unsavedChanges && <Text alignSelf="center">Unsaved changes</Text>}
        <Button size="md" variant="tertiary" w="92px" disabled={!settingsDirtied} onClick={cancelChanges}>
          Cancel
        </Button>
        <Button
          size="md"
          variant="primary"
          w="92px"
          disabled={!settingsDirtied || settingsError}
          onClick={saveAllSettings}
          isLoading={isSaving}
        >
          Save
        </Button>
      </ButtonGroup>
    </Flex>
  );
};

export default SurveyFooter;
