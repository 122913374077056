import { Button } from '@televet/kibble-ui';
import { Box } from '@televet/kibble-ui/build/chakra';
import { Tooltip } from '@televet/kibble-ui/build/components/Tooltip';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

type DictationButtonProps = {
  isSendingMessage: boolean;
  setMessage: (string: string) => void;
  currentMessageText: string;
};

const DictationButton = ({ isSendingMessage, setMessage, currentMessageText }: DictationButtonProps): JSX.Element => {
  const [isDictating, setIsDictating] = useState(false);

  const isSpeechRecognitionSupported = !!window.SpeechRecognition || !!window.webkitSpeechRecognition;

  const { channelId } = useParams<{ channelId: string }>();

  const handleDictationMessageTextChange = useCallback(
    (message: string) => {
      // Replace punctuation words with actual punctuation
      const newMessage = message
        .replaceAll(' period', '\b.')
        .replaceAll(' colon', '\b:')
        .replaceAll(' comma', ',')
        .replaceAll(' question mark', '?')
        .replaceAll(' exclamation point', '!')
        .replaceAll(' dash', '-')
        .replaceAll(' underscore', '_')
        .replaceAll(' slash', '/');

      if (!isSendingMessage) {
        setMessage(newMessage);
      }
    },
    [isSendingMessage, setMessage],
  );

  // Handle when the message is send and still listening for dictation
  useEffect(() => {
    if (isSendingMessage) {
      stopDictation();
    }
  }, [isSendingMessage]);

  // Handle when the component is unmounted
  useEffect(() => {
    return (): void => {
      stopDictation();
    };
  }, []);

  // Handle Navigating away from the conversation
  useEffect(() => {
    stopDictation();
  }, [channelId]);

  const speechRecognitionRef = useRef<null | SpeechRecognition>(null);

  const stopDictation = (): void => {
    setIsDictating(false);
    speechRecognitionRef.current?.stop();
    speechRecognitionRef.current?.abort();
    speechRecognitionRef.current = null;
  };

  const handleSpeechRecognition = (): void => {
    const speechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    if (!speechRecognition) {
      return;
    }

    if (!isDictating) {
      speechRecognitionRef.current = new speechRecognition();
      speechRecognitionRef.current.continuous = true;
      speechRecognitionRef.current.interimResults = true;
      speechRecognitionRef.current.lang = 'en-US';

      speechRecognitionRef.current.onstart = (): void => {
        setIsDictating(true);
      };

      speechRecognitionRef.current.onresult = (event): void => {
        let messageString = currentMessageText + ' ';

        const results = event.results;
        for (let index = 0; index < results.length; index++) {
          const result = results[index];
          messageString += result[0].transcript;
        }
        handleDictationMessageTextChange(messageString);
      };
      speechRecognitionRef.current.start();
    } else {
      stopDictation();
    }
  };
  return (
    <Box className="MessageComposer__DictationButton">
      <Tooltip isDisabled={isSpeechRecognitionSupported} label="Speech Recognition is not supported in this browser">
        <Box pos="relative">
          <Button
            mt={1}
            mr={1}
            onClick={handleSpeechRecognition}
            variant={isDictating ? 'primary' : 'ghostNeutral'}
            iconName="microphone"
            isDisabled={!isSpeechRecognitionSupported}
          />
        </Box>
      </Tooltip>
    </Box>
  );
};

export default DictationButton;
