import React from 'react';
import { Box, Circle } from '@televet/kibble-ui/build/chakra';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { Tooltip } from '@televet/kibble-ui/build/components/Tooltip';

interface IFilterIconButtonProps {
  badgeCount?: number;
  showBadge?: boolean;
  tooltip: string;
  buttonProps: Record<string, unknown>;
  onFilterToggle: () => void;
}

const FilterIconButton = React.forwardRef<HTMLButtonElement, IFilterIconButtonProps>(
  (
    { showBadge, tooltip, badgeCount, buttonProps, onFilterToggle, ...rest }: IFilterIconButtonProps,
    ref,
  ): JSX.Element => {
    return (
      <Tooltip label={tooltip} placement="bottom" openDelay={500}>
        <Box position="relative" {...rest}>
          {showBadge || (badgeCount && badgeCount > 0) ? (
            <Circle bg="background.primary" size={3} pos="absolute" top={1} right={1}>
              {badgeCount || ''}
            </Circle>
          ) : null}
          <Button
            {...buttonProps}
            iconName="filter"
            onClick={onFilterToggle}
            size="md"
            variant="ghostNeutral"
            ref={ref}
          />
        </Box>
      </Tooltip>
    );
  },
);

FilterIconButton.displayName = 'FilterIconButton';

export default FilterIconButton;
