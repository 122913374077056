import { TypePolicies } from '@apollo/client';
import { Reference } from '@apollo/client/utilities';
import { getPersistedAppointmentFilters } from 'shared/providers/ApolloProvider/reactiveVars/appointmentFilters';
import { getPersistedUserAppointmentGroupingsFilters } from 'shared/providers/ApolloProvider/reactiveVars/appointmentGroupingFilters';
import { AppointmentFilters } from '../reactiveVars/appointmentFilters';
import { ChannelType } from 'shared/types/graphql';
import findManyChannelMessage from '../fieldPolicies/findManyChannelMessage';
import { ClinicPimsIntegration } from './ClinicPimsIntegration';
import { User } from './User';
import { Channel } from './Channel';
import { ClinicPet } from './ClinicPet';
import uniqBy from 'lodash-es/uniqBy';

type ChannelSearchField = { channels: Reference[]; total: number };
const typePolicies: TypePolicies = {
  ClinicPimsIntegration,
  User,
  ClinicPet,
  Channel,
  Query: {
    fields: {
      appointmentFilters: {
        read(): AppointmentFilters {
          return getPersistedAppointmentFilters();
        },
      },
      userAppointmentGroupingsFilters: {
        read(): Record<string, string[]> {
          return getPersistedUserAppointmentGroupingsFilters();
        },
      },
      channelSearch: {
        keyArgs: ['where', 'orderBy'],
        merge(
          existing: ChannelSearchField = { channels: [], total: 0 },
          incoming: ChannelSearchField,
          { args, variables },
        ): ChannelSearchField {
          let channels: Reference[] = [];

          if (variables && variables.skip === 0) {
            channels = incoming.channels.slice(0, variables.take) || [];
          } else {
            channels = existing.channels ? existing.channels.slice(0) : [];
            for (let i = 0; i < incoming.channels.length; ++i) {
              channels[(args?.skip || 0) + i] = incoming.channels[i];
            }
          }

          return {
            ...incoming,
            channels,
          };
        },
      },
      findManyChannel: {
        keyArgs: ['where'],
        merge(existing = [], incoming, { args }): Reference[] {
          // don't merge TeamChannel results
          if (args?.where?.channelType?.equals === ChannelType.Team) return incoming.slice(0, args.take) || [];

          // if re-rendering side panel, don't merge with old cache
          if (args?.skip === 0) return incoming.slice(0, args.take) || [];

          const channels = existing ? existing.slice(0) : [];
          for (let i = 0; i < incoming.length; ++i) {
            channels[(args?.skip || 0) + i] = incoming[i];
          }

          return channels;
        },
      },
      findManyAutomationRun: {
        keyArgs: ['where'],
        merge(existing = [], incoming, { args }): Reference[] {
          const automationRuns = existing ? existing.slice(0) : [];
          for (let i = 0; i < incoming.length; ++i) {
            automationRuns[(args?.skip || 0) + i] = incoming[i];
          }

          return automationRuns;
        },
      },
      findManyChannelMessage,
      findManyMassTextAlert: {
        keyArgs: ['where'],
        merge(existing = [], incoming, { args }): Reference[] {
          if (args?.skip === 0) return incoming.slice(0, args.take) || [];

          const alerts = existing ? existing.slice(0) : [];
          for (let i = 0; i < incoming.length; ++i) {
            alerts[(args?.skip || 0) + i] = incoming[i];
          }

          return alerts;
        },
      },
      findUniqueClinicPetParent: {
        read(_, { args, toReference }): Reference | undefined {
          return toReference({
            __typename: 'ClinicPetParent',
            id: args?.where.id,
          });
        },
      },
      findManyCareBenefitUsage: {
        keyArgs: ['usageClinicPetId', 'petParentId'],
      },
      findManyClinicPet: {
        keyArgs: ['where'],
      },
      findManyLoyaltyPointDelta: {
        keyArgs: ['where'],
      },
      findManyLoyaltyReward: {
        keyArgs: ['where', 'orderBy'],
      },
      findManyFinancialTransaction: {
        keyArgs: ['where'],
        merge(existing = [], incoming, { args }): Reference[] {
          if (args?.skip === 0) return incoming.slice(0, args.take) || [];

          return [...(existing || []), ...incoming];
        },
      },
      findManyAppointment: {
        keyArgs: ['where'],
        merge(existing = [], incoming, { args }): Reference[] {
          if (args?.skip === 0 || args?.skip === undefined) return incoming;
          return uniqBy([...existing, ...incoming], '__ref');
        },
      },
      findManyUserAppointmentGrouping: {
        keyArgs: ['where'],
        merge(existing = [], incoming, { args }): Reference[] {
          if (args?.skip === 0 || args?.skip === undefined) return incoming;
          return uniqBy([...existing, ...incoming], '__ref');
        },
      },
    },
  },
};

export default typePolicies;
