import { Box, Flex } from '@televet/kibble-ui/build/chakra';
import React, { useMemo } from 'react';
import { CareGoalProgressBarMarker } from './CareGoalProgressBarMarker';

interface CareGoalProgressBarProps {
  baseGoal: number;
  stretchGoal: number;
  currentValue: number;
}

export const CareGoalProgressBar = ({ baseGoal, stretchGoal, currentValue }: CareGoalProgressBarProps): JSX.Element => {
  const currentValuePosition = useMemo(() => (currentValue * 100) / stretchGoal, [currentValue, stretchGoal]);
  const baseGoalPosition = useMemo(() => (baseGoal * 100) / stretchGoal, [baseGoal, stretchGoal]);
  const shouldShowCurrentValueMarker = currentValue !== baseGoal && currentValue < stretchGoal;

  return (
    <Flex className="CareGoalProgressBar" justifyContent="center" my={2} position="relative">
      <Box
        className="CareGoalProgressBar__Container"
        bgColor="background.lessSubtle"
        w="14px"
        h="200px"
        borderRadius="full"
      />
      <Box
        className="CareGoalProgressBar__ContainerFill"
        bgColor="background.primary"
        w="14px"
        h={`${currentValuePosition}%`}
        maxH="100%"
        position="absolute"
        bottom={0}
        borderRadius="full"
      />
      {shouldShowCurrentValueMarker && (
        <Flex
          className="CareGoalProgressBar__CurrentValueMarker"
          alignItems="center"
          justifyContent="center"
          bgColor="background.primary"
          border="3px solid"
          borderColor="border.strongContrast"
          borderRadius="full"
          color="text.onContrast"
          fontWeight="bold"
          fontSize="sm"
          position="absolute"
          top={`calc(100% - ${currentValuePosition}% - 14px)`}
          w={7}
          h={7}
          zIndex={2}
        >
          {currentValue}
        </Flex>
      )}
      <CareGoalProgressBarMarker
        value={baseGoal}
        position={baseGoal <= 5 ? { bottom: '-14px' } : { top: `calc(100% - ${baseGoalPosition}% - 19px)` }}
        isActive={currentValue >= baseGoal}
      />
      <CareGoalProgressBarMarker
        value={currentValue > stretchGoal ? currentValue : stretchGoal}
        position={{ top: '-19px' }}
        isActive={currentValue >= stretchGoal}
      />
    </Flex>
  );
};
