import React, { useCallback, useEffect, MouseEvent, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PopoverPlacement } from 'shared/components/Dropdown';
import { RouteDefinitions, replaceParam, RouteBasePaths } from 'routes';
import useClinicUser from 'shared/hooks/useClinicUser';
import NavButton from './NavButton';
import UserProfilePopover from './UserProfilePopover';
import PanelButton from 'shared/components/SidePanel/shared/components/PanelButton';
import { PanelViews } from 'shared/components/SidePanel/shared/types/enums/PanelViews';
import { useSidePanel } from 'shared/components/SidePanel/state/providers/SidePanelProvider';
import { useAuth } from 'shared/providers/AuthProvider';
import useUnreadMessageCount from 'shared/hooks/useUnreadMessageCount';
import { useUpdateLeftNavPanelToggleMutation } from 'shared/types/graphql';
import { FeatureFlagName } from 'shared/enums/FeatureFlagName';
import HasFeature from 'shared/components/HasFeature';
import { MobileMenuViews, useMobileMenuProvider } from 'pages/MobileMenu/MobileMenuProvider';
import { useResolutionProvider } from 'shared/providers/ResolutionProvider';
import { Icon, VStack, Box, HStack, Button, Text, Center, Flex } from '@televet/kibble-ui';
import ChangeClinicPopover from './ChangeClinicPopover';
import { IOption } from 'shared/components/MutiSelectSearchDropdown';
import LoadingSpinner from 'shared/components/LoadingSpinner';
import sortBy from 'lodash-es/sortBy';
import useFeatureFlag from 'shared/hooks/useFeatureFlag';
import { useIntegrationsProvider } from 'shared/providers/IntegrationsProvider';
import { ClinicAvatar } from 'shared/components/Avatars';
import { useHideClinicFormsByClinicId } from 'pages/Forms/useHideClinicFormsByClinicId';
import { GA4Events } from 'shared/enums/GA4Events';

interface ISidebarProps {
  onClinicSelected: (e: MouseEvent, option: IOption) => Promise<void>;
}

const Sidebar = ({ onClinicSelected }: ISidebarProps): JSX.Element | null => {
  const location = useLocation();
  const { setIsOpen, currentView, setCurrentView } = useSidePanel();
  const { isMobile, currentHeight } = useResolutionProvider();
  const { setCurrentMobileMenuView } = useMobileMenuProvider();
  const formsHidden = useHideClinicFormsByClinicId();
  const { isImpersonating } = useAuth();
  const { clinicUser, currentClinic, isOttoTeamMember, hasAdminRoleAtCurrentClinic } = useClinicUser();
  const hasClinicLoaded = !!currentClinic;
  const [isLeftPanelOpen, setIsLeftPanelOpen] = useState(false);
  const { isFeatureEnabled } = useFeatureFlag();
  const {
    syncStatus: { hasSyncOutage },
  } = useIntegrationsProvider();

  const clinicName = currentClinic?.name;
  const [updateLeftNavPanelToggleMutation] = useUpdateLeftNavPanelToggleMutation();

  const isAutomationsPageVisibilityEnabled = useMemo(() => {
    return isFeatureEnabled(FeatureFlagName.AutomationsPageVisibility);
  }, [isFeatureEnabled]);

  const handleSidePanelToggle = useCallback(
    (panelView: PanelViews) => {
      setCurrentView(panelView);
      if (panelView === PanelViews.Menu) setCurrentMobileMenuView(MobileMenuViews.Root);
      setIsOpen(true);
    },
    [setIsOpen, setCurrentView, setCurrentMobileMenuView],
  );

  const {
    data: { total: unreadMessagesCount, clientTotal, teamTotal },
  } = useUnreadMessageCount();

  useEffect(() => {
    const unread = unreadMessagesCount ? ` (${unreadMessagesCount})` : '';
    document.title = `${document.title.replace(/ \(\d+\)$/, '')}${unread}`;
    if (clinicUser) {
      setIsLeftPanelOpen(!!clinicUser?.userSetting?.leftNavPanel);
    }
  }, [clinicUser, clinicUser?.userSetting?.leftNavPanel, unreadMessagesCount]);

  const shouldHideNavbar =
    (location.pathname.includes('conversations') &&
      location.pathname.length > replaceParam(RouteDefinitions.Conversations, ':channelId', '').length) ||
    location.pathname.includes('video-call');

  const clinicOptions = useMemo((): IOption[] => {
    if (!clinicUser?.clinics) return [];
    const options = clinicUser.clinics.map((clinic) => {
      return {
        text: clinic.name,
        value: clinic.id,
        logo: clinic.logo || '',
      };
    });

    const sortedClinicOptions = sortBy(options, [(options): string => options.text.toLowerCase()], ['asc']);

    return sortedClinicOptions;
    // NOTE: we need this to be deep-compared, which is simplest when using JSON.stringify
  }, [JSON.stringify(clinicUser?.clinics), clinicUser]); // eslint-disable-line react-hooks/exhaustive-deps

  const onToggleLeftNavPanel = useCallback(async () => {
    if (!clinicUser?.userSetting?.id) return;
    try {
      await updateLeftNavPanelToggleMutation({
        variables: {
          where: { id: clinicUser?.userSetting?.id },
          data: {
            leftNavPanel: !isLeftPanelOpen,
          },
        },
      });

      setIsLeftPanelOpen((currentValue) => !currentValue);
    } catch (error) {
      console.error(error);
    }
  }, [clinicUser, isLeftPanelOpen, updateLeftNavPanelToggleMutation]);

  if (isMobile && shouldHideNavbar) return null;

  return (
    <Box
      {...(isMobile && { w: '100%' })}
      transition="width 0.4s ease-out"
      overflow="hidden"
      borderRightWidth="1px"
      borderRightColor="border.default"
      className="Sidebar__Container"
      flex="0 0 auto"
    >
      {hasClinicLoaded || !!clinicUser ? (
        <Box className="Sidebar__NavItems">
          {!isMobile && (
            <Box h={14} opacity={!hasClinicLoaded ? '.5' : '1'}>
              <ChangeClinicPopover
                clinicOptions={clinicOptions}
                onClinicSelected={onClinicSelected}
                placement={PopoverPlacement.Bottom}
                yOffset={1}
                anchor={
                  <HStack
                    className="Sidebar__ChangeClinicButton"
                    p={3}
                    _hover={
                      clinicOptions.length === 1
                        ? { cursor: 'default' }
                        : {
                            cursor: 'pointer',
                            background: 'background.primaryAlpha.hover',
                            borderRadius: '4px',
                          }
                    }
                    {...(!isLeftPanelOpen && {
                      sx: {
                        '.TruncatedText__Wrapper': { margin: 0, width: '0px', transition: 'width 0.4s ease-out' },
                      },
                    })}
                  >
                    {currentClinic && (
                      <>
                        <ClinicAvatar size="sm" showTooltip={!isLeftPanelOpen} variant="accent" isClinic />
                        <Text
                          noOfLines={2}
                          fontWeight="bold"
                          size="sm"
                          lineHeight="120%"
                          isTruncated={true}
                          w={!isLeftPanelOpen ? '0px' : clinicOptions.length === 1 ? '135px' : '113px'}
                          opacity={isLeftPanelOpen ? 1 : 0}
                          transition="opacity 0.4s ease-out"
                        >
                          {clinicName || 'No clinic name'}
                        </Text>
                        {isLeftPanelOpen && clinicOptions.length > 1 && <Icon name="chevronDown" size="xs" />}
                      </>
                    )}
                  </HStack>
                }
              />
            </Box>
          )}
          <Box
            h={isMobile ? '64px' : currentHeight - 56 - (isImpersonating && clinicUser ? 32 : 0)}
            overflowY={isMobile ? 'hidden' : 'auto'}
            overflowX="hidden"
          >
            <VStack h={isMobile ? '64px' : `100%`} align="flex-start" spacing={0}>
              <Box
                className="Sidebar__TopButtons"
                display={isMobile ? 'flex' : 'block'}
                flex="1 1 auto"
                width={isMobile ? '100%' : isLeftPanelOpen ? '200px' : '56px'}
                borderTop="1px"
                borderTopColor={isMobile ? 'border.default' : 'transparent'}
              >
                <NavButton
                  leftIconName="calendarInfo"
                  to={RouteBasePaths.Appointments}
                  title={PanelViews.Appointments}
                  data-mixpanel-name="Left sidebar appointments icon"
                  data-testid="appointment-nav-button"
                  isOpen={isLeftPanelOpen}
                  id="appointment-nav-button"
                />
                {!isMobile && (
                  <NavButton
                    leftIconName="kanbanBoard"
                    to={RouteBasePaths.Board}
                    title={PanelViews.Board}
                    data-mixpanel-name="Left sidebar board icon"
                    data-testid="board-nav-button"
                    isOpen={isLeftPanelOpen}
                    id="board-nav-button"
                    ga4Event={GA4Events.BOARD_VIEW}
                  />
                )}
                <NavButton
                  leftIconName="messages"
                  to={RouteBasePaths.Conversations}
                  title={PanelViews.Conversations}
                  data-mixpanel-name={!!clientTotal ? 'Chat notification' : 'Left sidebar conversations icon'}
                  data-testid="conversations-nav-button"
                  badgeCount={clientTotal}
                  isOpen={isLeftPanelOpen}
                  id="conversations-nav-button"
                />
                <NavButton
                  leftIconName="team"
                  to={RouteBasePaths.Team}
                  title={PanelViews.TeamChat}
                  data-mixpanel-name={!!teamTotal ? 'Team chat notification' : 'Left sidebar team icon'}
                  data-testid="team-nav-button"
                  badgeCount={teamTotal}
                  isOpen={isLeftPanelOpen}
                  id="team-nav-button"
                />
                {isMobile && (
                  <>
                    <PanelButton
                      isCurrentView={currentView === PanelViews.Search}
                      iconName="magnifyingGlass"
                      onClick={(): void => handleSidePanelToggle(PanelViews.Search)}
                      title={PanelViews.Search}
                      data-mixpanel-name="Right panel patient search icon"
                      data-testid="right-panel-patient-search-icon"
                      id="right-panel-patient-search-icon"
                    />

                    <PanelButton
                      isCurrentView={currentView === PanelViews.Menu}
                      iconName="dotsHorizontal"
                      onClick={(): void => handleSidePanelToggle(PanelViews.Menu)}
                      title="More"
                      data-mixpanel-name="Mobile navbar more menu icon"
                      data-testid="mobile-navbar-more-menu-icon"
                      id="mobile-navbar-more-menu-icon"
                    />
                  </>
                )}
              </Box>

              {!isMobile && (
                <Box
                  w={isLeftPanelOpen ? '200px' : '56px'}
                  transition="width 0.4s ease-out"
                  className="Sidebar__BottomButtons"
                >
                  {hasAdminRoleAtCurrentClinic && (
                    <>
                      <HasFeature name={FeatureFlagName.LoyaltyProgram}>
                        <NavButton
                          leftIconName="award"
                          to={RouteBasePaths.LoyaltyProgram}
                          title={PanelViews.LoyaltyProgram}
                          data-mixpanel-name="Loyalty Program"
                          data-testid="loyalty-program-nav-button"
                          isOpen={isLeftPanelOpen}
                          id="loyalty-program-nav-button"
                        />
                      </HasFeature>
                      <HasFeature name={FeatureFlagName.ActiveCampaigns}>
                        <NavButton
                          leftIconName="emailSend"
                          to={RouteBasePaths.EmailCampaigns}
                          title={PanelViews.EmailCampaigns}
                          data-mixpanel-name="Email Campaigns"
                          data-testid="email-nav-button"
                          isOpen={isLeftPanelOpen}
                          id="email-nav-button"
                        />
                      </HasFeature>
                      {hasAdminRoleAtCurrentClinic && !isFeatureEnabled(FeatureFlagName.ActiveCampaigns) && (
                        <NavButton
                          leftIconName="emailSend"
                          to={RouteBasePaths.OttoEmailCampaigns}
                          title={PanelViews.EmailCampaigns}
                          data-mixpanel-name="Otto Email Campaigns"
                          data-testid="otto-email-campaigns-nav-button"
                          isOpen={isLeftPanelOpen}
                          id="otto-email-campaigns-nav-button"
                        />
                      )}
                      <NavButton
                        leftIconName="smileyFace"
                        to={RouteBasePaths.Survey}
                        title={PanelViews.Survey}
                        data-mixpanel-name="Left sidebar survey icon"
                        data-testid="survey-nav-button"
                        isOpen={isLeftPanelOpen}
                        id="survey-nav-button"
                      />
                    </>
                  )}
                  <NavButton
                    leftIconName="report"
                    to={RouteBasePaths.Reporting}
                    isOpen={isLeftPanelOpen}
                    title={PanelViews.Reporting}
                    data-mixpanel-name="Left sidebar reporting icon"
                    data-testid="reporting-nav-button"
                    id="reporting-nav-button"
                  />
                  {(isAutomationsPageVisibilityEnabled || isImpersonating || isOttoTeamMember) &&
                    hasAdminRoleAtCurrentClinic && (
                      <NavButton
                        leftIconName="robot"
                        to={RouteBasePaths.Automations}
                        isOpen={isLeftPanelOpen}
                        title={PanelViews.Automations}
                        data-mixpanel-name="Left sidebar automations icon"
                        data-testid="automations-nav-button"
                        id="automations-nav-button"
                      />
                    )}
                  <NavButton
                    leftIconName="messageDetail"
                    to={RouteBasePaths.MessageTemplates}
                    isOpen={isLeftPanelOpen}
                    title={PanelViews.MessageTemplates}
                    data-mixpanel-name="Left sidebar message templates icon"
                    data-testid="message-templates-nav-button"
                    id="message-templates-nav-button"
                  />
                  {!formsHidden && (
                    <NavButton
                      leftIconName="form"
                      to={RouteBasePaths.Forms}
                      isOpen={isLeftPanelOpen}
                      title={PanelViews.Forms}
                      data-mixpanel-name="Left sidebar forms icon"
                      data-testid="forms-nav-button"
                      id="forms-nav-button"
                    />
                  )}
                  <NavButton
                    leftIconName="gear"
                    {...(hasSyncOutage && { rightIconName: 'alertCircle' })}
                    to={RouteBasePaths.Settings}
                    isOpen={isLeftPanelOpen}
                    title={PanelViews.Settings}
                    data-mixpanel-name="Left sidebar settings icon"
                    data-testid="settings-nav-button"
                    id="settings-nav-button"
                  />
                  <UserProfilePopover
                    user={clinicUser}
                    clinicOptions={clinicOptions}
                    clinicLogo={currentClinic?.logo}
                    clinicName={clinicName || ''}
                    onClinicSelected={onClinicSelected}
                    anchor={
                      <Flex
                        data-mixpanel-name="User profile icon"
                        data-testid="user-profile-icon"
                        width="100%"
                        cursor="pointer"
                        p={isLeftPanelOpen ? '12px 20px' : '12px'}
                        h={isLeftPanelOpen ? '44px' : '56px'}
                        _hover={{
                          bg: 'background.primaryAlpha.hover',
                        }}
                      >
                        {clinicUser ? (
                          <HStack w="100%" justify="flex-start" textAlign="left">
                            <ClinicAvatar clinicMember={clinicUser} size="sm" />
                            <Text
                              size="sm"
                              overflow="hidden"
                              whiteSpace="nowrap"
                              textOverflow="ellipsis"
                              fontWeight="bold"
                              maxW="100px"
                              flexGrow={1}
                            >
                              {clinicUser.displayName
                                ? clinicUser.displayName
                                : `${clinicUser.firstName} ${clinicUser.lastName}`}
                            </Text>
                            <Box h={4}>
                              <Icon name="chevronRight" size="sm" />
                            </Box>
                          </HStack>
                        ) : null}
                      </Flex>
                    }
                  />
                  <Center h={14} p={2} w="100%" justifyContent="flex-end" pr={isLeftPanelOpen ? 3 : 2} mb={2}>
                    <Button
                      iconName={isLeftPanelOpen ? 'moveLeft' : 'moveRight'}
                      variant="ghostNeutral"
                      onClick={(): Promise<void> => onToggleLeftNavPanel()}
                      data-mixpanel-name="Right panel collapse icon"
                      data-testid="right-panel-collapse-icon-button"
                      id="sidepanel-toggle-button"
                    />
                  </Center>
                </Box>
              )}
            </VStack>
          </Box>
        </Box>
      ) : (
        <Box className="Sidebar__LoadingSpinner" marginTop="18px" px={3}>
          <LoadingSpinner />
        </Box>
      )}
    </Box>
  );
};

export default Sidebar;
