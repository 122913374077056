import CampaignHTMLEditor from './components/CampaignHTMLEditor';
import CampaignList from './components/CampaignList';
import CampaignSummary from './components/CampaignSummary';
import NewOttoEmailCampaignModal from './components/NewOttoEmailCampaignModal';
import TemplateList from './components/TemplateList';
import { CampaignAction } from './enums';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { Heading } from '@televet/kibble-ui/build/components/Heading';
import { Spinner } from '@televet/kibble-ui/build/components/Spinner';
import { Flex } from '@televet/kibble-ui/build/chakra';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useClinicUser from 'shared/hooks/useClinicUser';
import {
  EmailCampaignStatus,
  TemplateAvailability,
  useFindManyAppointmentTypeQuery,
  useFindManyEmailCampaignQuery,
  useFindManyEmailCampaignTemplateQuery,
} from 'shared/types/graphql';
import useGA from 'shared/hooks/useGA';
import { GA4Events } from 'shared/enums/GA4Events';
import differenceInDays from 'date-fns/differenceInDays';
import useFeatureFlag from 'shared/hooks/useFeatureFlag';
import { FeatureFlagName, GraphQLFetchPolicies } from 'shared/enums';
import CannotSendAlert from './components/CannotSendAlert';
import useEmailCampaigns from './hooks/useEmailCampaigns';

const DAYS_RECENT_THRESHOLD = 30;

const OttoEmailCampaigns = (): JSX.Element => {
  const { campaignId, campaignAction } = useParams<{ campaignId: string; campaignAction: string }>();
  const { isFeatureEnabled } = useFeatureFlag();

  const [isNewCampaignModalOpen, setIsNewCampaignModalOpen] = React.useState(false);

  const { currentClinicId: clinicId } = useClinicUser();
  const { canSendEmailCampaigns } = useEmailCampaigns();

  const { gaTrack } = useGA();

  const {
    data: campaigns,
    loading,
    refetch: refetchEmailCampaigns,
  } = useFindManyEmailCampaignQuery({
    variables: {
      where: {
        clinicId: { equals: clinicId },
        deletedAt: null,
      },
    },
  });

  const { data: emailTemplates } = useFindManyEmailCampaignTemplateQuery({
    variables: {
      where: {
        OR: [
          { clinicId: { equals: clinicId || '' } },
          {
            templateAvailability: {
              in: [
                TemplateAvailability.Global,
                ...(isFeatureEnabled(FeatureFlagName.CarePlans) ? [TemplateAvailability.Care] : []),
              ],
            },
          },
        ],
      },
    },
  });

  const { data: appointmentTypesData, loading: loadingAppointmentTypes } = useFindManyAppointmentTypeQuery({
    variables: {
      clinicId: clinicId || '',
    },
    fetchPolicy: GraphQLFetchPolicies.CacheAndNetwork,
  });

  const appointmentTypes = useMemo(() => {
    return (
      appointmentTypesData?.findManyAppointmentType?.map((appointmentType) => ({
        value: appointmentType.id,
        label: appointmentType.name,
      })) || []
    );
  }, [appointmentTypesData?.findManyAppointmentType]);

  const additionalSegmentProps = useMemo(() => {
    return {
      appointmentTypes,
    };
  }, [appointmentTypes]);

  const recentlySentOrSendingCampaign = useMemo(() => {
    // Filter out campaigns with a status of 'Sent'
    // Sort them by 'updatedAt' or 'createdAt' in descending order
    const sentCampaigns = (campaigns?.findManyEmailCampaign || []).filter((campaign) =>
      [EmailCampaignStatus.Sent, EmailCampaignStatus.Sending].includes(campaign.status),
    );
    sentCampaigns.sort(
      (a, b) => new Date(b.updatedAt || b.createdAt).valueOf() - new Date(a.updatedAt || a.createdAt).valueOf(),
    );

    const campaign = sentCampaigns[0];

    if (campaign) {
      const today = new Date();
      const lastDate = new Date(campaign.updatedAt || campaign.createdAt);
      const elapsedDays = differenceInDays(today, lastDate);

      if (elapsedDays < DAYS_RECENT_THRESHOLD) {
        return {
          elapsedDays,
          campaign,
        };
      }
    }

    return undefined;
  }, [campaigns?.findManyEmailCampaign]);

  const campaign = useMemo(
    () => campaigns?.findManyEmailCampaign?.find((campaign) => campaign.id === campaignId),
    [campaignId, campaigns?.findManyEmailCampaign],
  );

  if (loading) {
    return (
      <Flex justifyContent="center" alignItems="center" height="calc(100vh - 75px)">
        <Spinner size="xl" />
      </Flex>
    );
  }

  if (campaignId && campaignAction === CampaignAction.editCampaign) {
    return (
      <CampaignSummary
        recentlySentOrSendingCampaign={recentlySentOrSendingCampaign}
        campaigns={campaigns}
        campaignId={campaignId}
        refetchEmailCampaigns={refetchEmailCampaigns}
        additionalSegmentProps={additionalSegmentProps}
        loadingAdditionalSegmentProps={loadingAppointmentTypes}
      />
    );
  }

  if (campaignId && campaignAction === CampaignAction.chooseTemplate) {
    return (
      <TemplateList
        emailTemplates={emailTemplates}
        campaignId={campaignId}
        isTemplateAssigned={!!campaign?.unlayerJson}
      />
    );
  }

  if (campaignId && campaignAction === CampaignAction.editCampaignHTML) {
    return <CampaignHTMLEditor campaigns={campaigns} campaignId={campaignId} emailTemplates={emailTemplates} />;
  }
  return (
    <Flex justifyContent="center" bgColor="background.default" height="100%">
      <Flex
        maxWidth="1024px"
        width="100%"
        direction="column"
        className="OttoEmailCampaigns__Container"
        overflow="hidden"
        height="100%"
        padding="40px 20px"
        bgColor="background.default"
      >
        {/* Modals */}
        <NewOttoEmailCampaignModal
          isOpen={isNewCampaignModalOpen}
          setIsOpen={setIsNewCampaignModalOpen}
          refetchCampaigns={refetchEmailCampaigns}
          isLoadingCampaigns={loading}
        />
        {/* Header */}
        <Flex justifyContent="space-between" mb="4">
          <div>
            <Heading size="lg" marginBottom={2}>
              All Campaigns
            </Heading>
            <Text as="p" variant="subtle">
              Schedule and send mass emails to your clients with this easy-to-use tool.{' '}
              <Text
                variant="interactive"
                textDecoration="underline"
                onClick={(): void => {
                  gaTrack(GA4Events.EMAIL_CAMPAIGN_BEST_PRACTICES_CLICK);
                }}
              >
                <a
                  href="https://otto.vet/wp-content/uploads/2024/07/Email-Dos-and-Donts.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn more about best practices here.
                </a>
              </Text>
            </Text>
          </div>
          <Button flexShrink={0} onClick={(): void => setIsNewCampaignModalOpen(true)}>
            Create New
          </Button>
        </Flex>

        {!canSendEmailCampaigns && <CannotSendAlert />}

        {!campaigns?.findManyEmailCampaign?.length ? (
          <Flex
            padding="6"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            bgColor="background.subtle"
            borderRadius="xl"
          >
            <Text mb="4" color="text.subtle">
              Once you create an email campaign, it will show up here.
            </Text>
            <Button onClick={(): void => setIsNewCampaignModalOpen(true)}>Create New Campaign</Button>
          </Flex>
        ) : (
            <CampaignList campaigns={campaigns} refetchEmailCampaigns={refetchEmailCampaigns} />
          )}
      </Flex>
    </Flex>
  );
};

export default OttoEmailCampaigns;
