import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Text, Button, Flex, Divider, Icon, useToast, Center } from '@televet/kibble-ui';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { updateCareWizardDraft, updateCurrentStep } from '../../../state/careWizardStepsSlice';
import { formatPluralization } from 'shared/utils';
import { formatMoney } from 'pages/Reporting/utils/formatMoney';
import useClinicUser from 'shared/hooks/useClinicUser';
import * as Sentry from '@sentry/react';
import { CareBenefitType, SidePanelParentPetDataFragment } from 'shared/types/graphql';
import { UsePetBenefitCareBenefit, usePetBenefits } from '../../../hooks/usePetBenefits';
import {
  TriggerMessageEventPayload,
  useTriggerMessageEventAndSubscribe,
} from 'shared/hooks/useTriggerMessageEventAndSubscribe';
import { SqsQueueNames } from '@televet/shared-types/SqsQueueNames';
import usePersistedState from 'shared/hooks/usePersistedState';
import { FeatureFlagName, PersistedStateKeys } from 'shared/enums';
import {
  TriggerMessageEventPayloadDetails,
  TriggerMessageEventPayloadDetailsStatus,
} from '../utils/paymentEnumsAndVariables';
import useFeatureFlag from 'shared/hooks/useFeatureFlag';
import usePaymentWritebackConfig from 'shared/hooks/usePaymentWritebackConfig';
import { CoreRoutingKey } from '@televet/shared-types/coreJobs/generated/types';
import LoadingSpinner from 'shared/components/LoadingSpinner';

interface IBenefitConsumed {
  benefitId: string;
  usageAmount: number;
  discountAmountCents: number;
}
interface IPetCareBenefitConsumption {
  recordingUserId?: string;
  invoiceId: string | null;
  clinicPetId: string;
  clinicId: string;
  careStripeSubscriptionId: string;
  invoiceEstimateTotalCents: number;
  benefitsConsumed: IBenefitConsumed[];
  clinicPetParentId?: string;
}

interface ExamMap {
  [key: string]: {
    benefitId: string;
    usageAmount: number;
    discountAmountCents: number;
  };
}

interface ISummaryScreenProps {
  currentStep: number;
  pet: SidePanelParentPetDataFragment | undefined;
  careStripeSubscriptionId: string;
  clinicPetParentId?: string;
  accountCredit: UsePetBenefitCareBenefit | undefined;
  percentDiscount: UsePetBenefitCareBenefit | undefined;
}

interface IBenefitRowProps {
  title: string;
  value: number;
}

const BenefitRow = ({ title, value }: IBenefitRowProps): JSX.Element => {
  return (
    <Flex alignItems="center" justifyContent="space-between" mb={1}>
      <Flex alignItems="center">
        <Icon name="checkmark" size="sm" />
        <Text ml={1} size="sm">
          {title}
        </Text>
      </Flex>
      <Text size="sm">{formatMoney(value * 100)}</Text>
    </Flex>
  );
};

const dollarsToCents = (amount: number): number => {
  return amount * 100;
};

const SummaryScreen = ({
  currentStep,
  pet,
  careStripeSubscriptionId,
  clinicPetParentId,
  accountCredit,
  percentDiscount,
}: ISummaryScreenProps): JSX.Element => {
  const toast = useToast();
  const { currentClinicId, clinicUser } = useClinicUser();
  const dispatch = useAppDispatch();
  const { benefits, loading: loadingBenefits } = usePetBenefits(pet?.organizationPet?.id || '');
  const careWizardDraft = useAppSelector((state) => state.CareWizardSteps.careWizardDraft);
  const [isUpdatingBenefitUsages, setIsUpdatingBenefitUsages] = useState(false);
  const [isDiscoveredInvoiceDemoModeEnabled] = usePersistedState(
    PersistedStateKeys.IsDiscoveredInvoiceDemoModeEnabled,
    false,
  );

  const shouldShowExams = benefits.some((benefit) => benefit.type === CareBenefitType.ProvidedService);
  const shouldShowCarePass = benefits.some((benefit) => benefit.type === CareBenefitType.AccountCredit);
  const shouldShowPercentDiscount = benefits.some((benefit) => benefit.type === CareBenefitType.Discount);

  const { isFeatureEnabled } = useFeatureFlag();
  const isWizardPaymentsFFEnabled = isFeatureEnabled(FeatureFlagName.WizardPaymentIntegrations);
  const { isClinicWritebackEnabled } = usePaymentWritebackConfig();
  const includedExams = careWizardDraft.includedExams;
  const isSubmitted = useRef(false);

  const { triggerMessageEvent, payload } = useTriggerMessageEventAndSubscribe<TriggerMessageEventPayloadDetails>();

  const {
    carePassValueToConsume,
    additionalDiscountValueToConsume,
    examAmountToConsume,
    examValueToConsume,
    totalInvoiceSavings,
    paymentDue,
    selectedInvoiceId,
    isBalanceSelected,
  } = careWizardDraft;

  const previousStep = !!accountCredit || !!percentDiscount ? currentStep - 1 : currentStep - 2;

  const handleBack = useCallback(() => {
    if (additionalDiscountValueToConsume > 0) {
      dispatch(
        updateCareWizardDraft({
          totalInvoiceSavings: totalInvoiceSavings - additionalDiscountValueToConsume,
          paymentDue: paymentDue + additionalDiscountValueToConsume,
          additionalDiscountValueToConsume: 0,
        }),
      );
    }
    if (carePassValueToConsume > 0 && additionalDiscountValueToConsume === 0) {
      dispatch(
        updateCareWizardDraft({
          totalInvoiceSavings: totalInvoiceSavings - carePassValueToConsume,
          paymentDue: paymentDue + carePassValueToConsume,
          carePassValueToConsume: 0,
          showPercentDiscountForm: false,
        }),
      );
    }
    dispatch(updateCurrentStep(previousStep));
  }, [
    additionalDiscountValueToConsume,
    carePassValueToConsume,
    dispatch,
    paymentDue,
    previousStep,
    totalInvoiceSavings,
  ]);

  const examBenefits: UsePetBenefitCareBenefit[] = useMemo(
    // array of all benefits that are of type ProvidedService
    () => benefits.filter((benefit) => benefit.type === CareBenefitType.ProvidedService),
    [benefits],
  );

  const calculateBenefits = useCallback(() => {
    if (!pet) {
      return;
    }
    const benefitsToConsume: IBenefitConsumed[] = [];
    if (examAmountToConsume > 0) {
      const examsMap: ExamMap = {};
      //TODO: Because this is a temporary fix I wanted to preserve the original logic for the case where there is only one type of exam, in the future the mapping technique should be used for all cases

      const firstExamBenefit = examBenefits[0];
      const moreThanOneExam = !!includedExams.find((exam) => exam.benefitId !== firstExamBenefit.id);
      if (!moreThanOneExam) {
        benefitsToConsume.push({
          benefitId: firstExamBenefit.id,
          usageAmount: examAmountToConsume,
          discountAmountCents: dollarsToCents(examValueToConsume),
        });
      } else {
        includedExams.forEach((exam) => {
          const { benefitId, value } = exam;
          const discountAmountCents = dollarsToCents(parseFloat(value));

          if (examsMap[benefitId]) {
            examsMap[benefitId].usageAmount += 1;
            examsMap[benefitId].discountAmountCents += discountAmountCents;
          } else {
            examsMap[benefitId] = {
              benefitId: benefitId,
              usageAmount: 1,
              discountAmountCents: discountAmountCents,
            };
          }
        });

        for (const key in examsMap) {
          benefitsToConsume.push(examsMap[key]);
        }
      }
    }

    if (carePassValueToConsume > 0) {
      const carePassBenefit = benefits.find((benefit) => benefit.type === CareBenefitType.AccountCredit);
      if (carePassBenefit?.id) {
        benefitsToConsume.push({
          benefitId: carePassBenefit.id,
          usageAmount: dollarsToCents(carePassValueToConsume),
          discountAmountCents: dollarsToCents(carePassValueToConsume),
        });
      }
    }
    if (additionalDiscountValueToConsume > 0) {
      if (percentDiscount?.id) {
        benefitsToConsume.push({
          benefitId: percentDiscount.id,
          usageAmount: dollarsToCents(additionalDiscountValueToConsume),
          discountAmountCents: dollarsToCents(additionalDiscountValueToConsume),
        });
      }
    }

    return benefitsToConsume;
  }, [
    additionalDiscountValueToConsume,
    benefits,
    examBenefits,
    carePassValueToConsume,
    examAmountToConsume,
    examValueToConsume,
    percentDiscount?.id,
    pet,
    includedExams,
  ]);

  const prevPayloadRef = useRef<TriggerMessageEventPayload<TriggerMessageEventPayloadDetails> | null | undefined>(
    undefined,
  );

  useEffect(() => {
    prevPayloadRef.current = payload;
  }, [payload]);

  const shouldTriggerWriteBack = useMemo(() => {
    return (isBalanceSelected || selectedInvoiceId) && !!isClinicWritebackEnabled && !!isWizardPaymentsFFEnabled;
  }, [isBalanceSelected, isClinicWritebackEnabled, isWizardPaymentsFFEnabled, selectedInvoiceId]);

  useEffect(() => {
    const typedPayload = prevPayloadRef.current as TriggerMessageEventPayloadDetails | null;
    if (typedPayload) {
      const { status, payload } = typedPayload;
      const careAccountCreditIntent = payload ? payload[0]?.careAccountCreditIntent : null;
      const id = careAccountCreditIntent && careAccountCreditIntent.id ? careAccountCreditIntent.id : null;
      const triggeredStatus = careAccountCreditIntent?.writeback?.triggered;

      if (id !== null && id !== undefined) {
        dispatch(updateCareWizardDraft({ careAccountCreditIntentId: id }));
      }

      const writeBackNotTriggered = triggeredStatus === false && shouldTriggerWriteBack;
      if (status === TriggerMessageEventPayloadDetailsStatus.Errored || writeBackNotTriggered) {
        const reason = careAccountCreditIntent?.writeback?.reason;
        toast({
          title: 'Writeback failed',
          description: reason || 'Something went wrong. Please try again.',
          status: 'error',
          duration: 9000,
        });
      }

      if (status !== TriggerMessageEventPayloadDetailsStatus.Pending) {
        setIsUpdatingBenefitUsages(false);
        dispatch(updateCurrentStep(currentStep + 1));
      }
    }
  }, [currentStep, dispatch, shouldTriggerWriteBack, toast]);

  const handleNext = useCallback(async () => {
    const benefitsToConsume = calculateBenefits();

    if (!isSubmitted.current && !!benefitsToConsume?.length && pet && currentClinicId) {
      setIsUpdatingBenefitUsages(true);
      const data: IPetCareBenefitConsumption = {
        recordingUserId: clinicUser?.id,
        invoiceId: isDiscoveredInvoiceDemoModeEnabled ? null : selectedInvoiceId,
        clinicPetParentId: clinicPetParentId || '',
        clinicPetId: pet.id,
        clinicId: currentClinicId,
        careStripeSubscriptionId: careStripeSubscriptionId,
        invoiceEstimateTotalCents: dollarsToCents(careWizardDraft.originalInvoiceCost),
        benefitsConsumed: benefitsToConsume,
      };

      try {
        await triggerMessageEvent({
          variables: {
            useSubscription: true,
            event: 'CONSUME_CARE_BENEFIT' as CoreRoutingKey,
            queueName: SqsQueueNames.CoreQueue,
            data,
          },
        });

        isSubmitted.current = true;
      } catch (e) {
        console.error(e);
        Sentry.captureException(e);
        toast({
          status: 'error',
          title: 'Whoops! An error occurred when applying the selected benefits.',
        });
        setIsUpdatingBenefitUsages(false);
        isSubmitted.current = false;
      }
    }
  }, [
    calculateBenefits,
    careStripeSubscriptionId,
    careWizardDraft.originalInvoiceCost,
    clinicPetParentId,
    clinicUser?.id,
    currentClinicId,
    isDiscoveredInvoiceDemoModeEnabled,
    pet,
    selectedInvoiceId,
    toast,
    triggerMessageEvent,
  ]);

  const totalSavings = useMemo(
    () => formatMoney(careWizardDraft.totalInvoiceSavings * 100),
    [careWizardDraft.totalInvoiceSavings],
  );

  const selectedExamsAmount = useMemo(
    () => (careWizardDraft.examAmountToConsume > 1 ? `(${careWizardDraft.examAmountToConsume})` : ''),
    [careWizardDraft.examAmountToConsume],
  );

  const examPluralization = useMemo(
    () => formatPluralization({ word: 'exam', singularCriteria: careWizardDraft.examAmountToConsume }),
    [careWizardDraft.examAmountToConsume],
  );

  return (
    <Box>
      <Text as="p" fontWeight="bold" mb={1}>
        Summary
      </Text>
      <Text as="p" size="xs" variant="subtle" mb={6}>
        {'Please review the benefits you have selected before proceeding - '}
        <Text fontWeight="bold" size="xs" fontStyle="italic">
          applying benefits will consume them and cannot be undone.
        </Text>
      </Text>
      {loadingBenefits ? (
        <Center>
          <LoadingSpinner message="Loading Benefits..." />
        </Center>
      ) : (
        <>
          <Box>
            <Flex alignItems="center" justifyContent="space-between">
              <Text size="sm">Benefit</Text>
              <Text size="sm">Value</Text>
            </Flex>
            <Divider my={1} />
            {shouldShowExams && (
              <BenefitRow
                title={`${selectedExamsAmount} Included ${examPluralization}`}
                value={careWizardDraft.examValueToConsume}
              />
            )}
            {shouldShowCarePass && (
              <BenefitRow title="Instant Account Credit" value={careWizardDraft.carePassValueToConsume} />
            )}
            {shouldShowPercentDiscount && (
              <BenefitRow
                title={`Additional ${percentDiscount?.discountPercentage || 0}% Discount`}
                value={careWizardDraft.additionalDiscountValueToConsume}
              />
            )}
          </Box>
          <Divider mb={1} />
          <Text as="p" size="sm" textAlign="end">
            Savings
            <Text data-testid="wizard-summary-savings-amount" size="sm" fontWeight="bold" ml={1}>
              {totalSavings}
            </Text>
          </Text>
        </>
      )}
      <Flex pb={4} mt="64px" justify="space-between">
        <Button variant="tertiary" onClick={handleBack} isDisabled={isUpdatingBenefitUsages}>
          Back
        </Button>
        <Button
          data-testid="wizard-apply-benefits-button"
          onClick={handleNext}
          isDisabled={careWizardDraft.totalInvoiceSavings <= 0 || isUpdatingBenefitUsages || loadingBenefits}
          isLoading={isUpdatingBenefitUsages}
        >
          Apply {totalSavings} of Benefits
        </Button>
      </Flex>
    </Box>
  );
};

export default SummaryScreen;
