import { TimeFormatValue } from 'shared/components/WildCardInputGroup/WildcardTimeFormatSelect';
import {
  AutomatedTriggerType,
  WorkflowSendingHours,
  WorkflowEvent,
  WorkflowVisitType,
  WorkflowEventTriggerType,
  AutomationEditorConditionSetFragment,
} from 'shared/types/graphql';
import { AutomationPrompt } from './AutomationPrompt';
import { SendConditions } from './AutomationSendConditions';

export enum TimeIncrement {
  Minutes = 'Minutes',
  Hours = 'Hours',
  Days = 'Days',
  Weeks = 'Weeks',
  Months = 'Months',
}

export type SendTiming = {
  triggerType?: null | AutomatedTriggerType; // automatedTriggerType
  timeValue?: null | number; // automatedTriggerInMinutes
  timeIncrement: TimeIncrement;
  timeOfDay?: string | null; // automatedTriggerTimeOfDay
};

export enum ExtendedWorkflowEvent {
  FromAnotherAutomation = 'From_Another_Automation',
}

export type AutomationType = WorkflowEvent | ExtendedWorkflowEvent.FromAnotherAutomation;

export interface AutomationDraft {
  automationId: null | string;
  automationName: null | string; // name
  isPublished: boolean;
  automationType: AutomationType | null; // event
  sendTiming: SendTiming;
  sendConditions: SendConditions;
  conditionSets: AutomationEditorConditionSetFragment[];
  forms: null | string[]; // triggeringFormTemplates
  requestOptions: null | string[]; // widgetRequestTypes
  sendFrequency: WorkflowVisitType; // visitType
  sendHours: WorkflowSendingHours; // sendingHours
  triggerType: WorkflowEventTriggerType;
  notificationMessage: null | string; // notificationBody
  timeFormat: null | TimeFormatValue;
  prompts: AutomationPrompt[]; // actions
  // workflowType? Not in use will be deprecated default to curbside
  // groupByWindowInHours? Not in use?
}
