import React, { useCallback, ChangeEvent, useState, useEffect } from 'react';
import {
  Modal,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooterButtons,
  MenuItemProps,
  TextInput,
  VStack,
  HStack,
  Select,
} from '@televet/kibble-ui';
import { NextStepSelect } from '../NextStepSelect';
import { StatusChangeSelect } from '../StatusChangeSelect';
import { AutomationButtonConfig, ActionEffectType } from 'pages/Automations/types/AutomationPrompt';
import { ButtonErrorDetail, errorDetails } from 'pages/Automations/types/AutomationValidation';
import partial from 'lodash-es/partial';
import { useAppDispatch } from 'state/hooks';
import {
  updateAutomationPromptButton,
  updateAutomationPromptButtonErrors,
} from 'pages/Automations/state/automationsSlice';
import { AutomationRunActionPromptType } from 'shared/types/graphql';

const buttonActionOptions = [
  { label: 'None', value: ActionEffectType.None },
  { label: 'Confirm Appointment', value: ActionEffectType.ConfirmAppointment },
];

interface EditButtonModalProps {
  button: AutomationButtonConfig;
  buttonIndex: number;
  buttonErrors: ButtonErrorDetail | undefined;
  promptType: AutomationRunActionPromptType;
  promptIndex: number;
  isOpen: boolean;
  onClose: (revertChanges: boolean) => void;
}

const EditButtonModal = ({
  button,
  buttonIndex,
  buttonErrors,
  promptType,
  promptIndex,
  isOpen,
  onClose,
}: EditButtonModalProps): JSX.Element | null => {
  const dispatch = useAppDispatch();

  const [buttonDraft, setButtonDraft] = useState<AutomationButtonConfig>(button);

  useEffect(() => {
    if (isOpen) {
      setButtonDraft(button);
    }
  }, [button, isOpen]);

  const buttonTextErrors = buttonErrors?.buttonText;

  const handleButtonTextChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      dispatch(updateAutomationPromptButton({ promptIndex, buttonIndex, button: { body: event?.target.value || '' } }));

      if (buttonErrors?.buttonText) {
        dispatch(
          updateAutomationPromptButtonErrors({
            promptIndex,
            buttonIndex,
            button: { buttonText: [] },
          }),
        );
      }
    },
    [dispatch, promptIndex, buttonIndex, buttonErrors?.buttonText],
  );

  const handleButtonActionChange = useCallback(
    (selectedItem: MenuItemProps) => {
      dispatch(
        updateAutomationPromptButton({
          promptIndex,
          buttonIndex,
          button: { entityAction: selectedItem.value as ActionEffectType },
        }),
      );
    },
    [dispatch, promptIndex, buttonIndex],
  );

  const buttonTextInputRef = useCallback((el: HTMLInputElement | null): void => {
    if (el) {
      el.focus();
    }
  }, []);

  if (!buttonDraft) {
    return null;
  }

  return (
    <Modal size="md" isOpen={isOpen} onClose={partial(onClose, false)}>
      <ModalHeader>Edit Button</ModalHeader>
      <ModalCloseButton size="sm" />
      <ModalBody pb={2}>
        <VStack spacing={4} align="stretch">
          <TextInput
            ref={buttonTextInputRef}
            label="Button Text"
            size="md"
            isInvalid={Boolean(buttonTextErrors?.length)}
            errorText={buttonTextErrors?.map((errorName) => errorDetails[errorName].errorMessage).join(' ')}
            value={buttonDraft.body}
            onChange={handleButtonTextChange}
            placeholder="Button text"
          />
          <HStack className="PromptBlock__Buttons" alignItems="flex-start">
            <Select
              label="Button Action"
              size="md"
              maxW="172px"
              value={buttonDraft.entityAction}
              options={buttonActionOptions}
              listProps={{ onSelect: handleButtonActionChange }}
            />
            <StatusChangeSelect
              size="md"
              promptType={promptType}
              promptIndex={promptIndex}
              buttonIndex={buttonIndex}
              channelStatusChangeId={button.channelStatusChangeId}
            />
          </HStack>
          <NextStepSelect promptIndex={promptIndex} buttonIndex={buttonIndex} />
        </VStack>
      </ModalBody>
      <ModalFooterButtons onSubmit={partial(onClose, false)} onCancel={partial(onClose, true)} submitText="Done" />
    </Modal>
  );
};

export default EditButtonModal;
