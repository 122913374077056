import { CampaignAction } from '../enums';
import { ReactComponent as EmailPreview } from '../images/emailPreview.svg';
import TemplatePreview from './TemplatePreview';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { Heading } from '@televet/kibble-ui/build/components/Heading';
import { Card } from '@televet/kibble-ui/build/components/Card';
import { Box, Flex } from '@televet/kibble-ui/build/chakra';
import { render } from 'mustache';
import React, { useMemo } from 'react';
import { Design } from 'react-email-editor';
import { useHistory } from 'react-router-dom';
import { RouteBasePaths } from 'routes';
import useClinicUser from 'shared/hooks/useClinicUser';
import {
  ClinicUserClinicFragment,
  FindManyEmailCampaignTemplateQuery,
  useUpdateOneEmailCampaignMutation,
  EmailCampaignTemplate,
} from 'shared/types/graphql';
import { formatPhoneNumber } from 'shared/utils';
import useGA from 'shared/hooks/useGA';
import { GA4Events } from 'shared/enums/GA4Events';

type TemplateListProps = {
  campaignId: string;
  isTemplateAssigned: boolean;
  emailTemplates: FindManyEmailCampaignTemplateQuery | undefined;
};

type Template = Pick<EmailCampaignTemplate, 'id' | 'name' | 'htmlContent' | 'unlayerJson'>;

/**
 * Static array of wildcards that need to be rendered as raw values
 */
const wildcardsToFix = ['petPortalLinksForPets', 'petPortalLinksForClients', 'clinicName'];

/**
 * Replace certain wildcards using standard double mustache syntax with triple
 * mustache syntax to render raw values instead of escaping.
 * Currently set up to replace:
 * * petPortalLinksForPets
 * * petPortalLinksForClients
 * * clinicName
 */
const fixWildcardsInHTML = (template: string): string => {
  wildcardsToFix.forEach((wildcard) => template.replaceAll(`{{${wildcard}}}`, `{{{${wildcard}}}}`));

  return template;
};

/**
 * Replace special characters in clinic name that break JSON parser
 * Currently set up to replace:
 * * \
 * * "
 */
const fixSpecialCharsInClinicName = (clinicName: string): string =>
  clinicName.replaceAll(/\\/gm, '\\\\').replaceAll(/"/gm, '\\"');

/**
 * Use Mustache render function to replace wildcards in HTML template string
 */
const replaceWildCardsInHTML = ({ html, clinic }: { html: string; clinic: ClinicUserClinicFragment | null }): string =>
  render(fixWildcardsInHTML(html), {
    clinicLogo: clinic?.logo || '',
    clinicName: clinic?.name || '',
  });

/**
 * Replace wildcards in JSON by stringifying, using regex to make replacements,
 * and then parsing back to JSON
 */
export const replaceWildCardsInJSON = ({
  json,
  clinic,
}: {
  json: Design;
  clinic: ClinicUserClinicFragment | null;
}): Design =>
  JSON.parse(
    JSON.stringify(json)
      .replaceAll(/{{clinicLogo}}/gm, clinic?.logo || '')
      .replaceAll(/{{clinicName}}/gm, fixSpecialCharsInClinicName(clinic?.name || ''))
      .replaceAll(/{{clinicPhoneNumber}}/gm, formatPhoneNumber(clinic?.phone) || '(555) 555-5555'),
  );

const TemplateList = ({ campaignId, isTemplateAssigned, emailTemplates }: TemplateListProps): JSX.Element => {
  const { currentClinic: clinic } = useClinicUser();
  const history = useHistory();
  const { gaTrack } = useGA();

  const [updateOneEmailCampaign] = useUpdateOneEmailCampaignMutation();

  const templates = useMemo(
    () => emailTemplates?.findManyEmailCampaignTemplate,
    [emailTemplates?.findManyEmailCampaignTemplate],
  );

  const handleSelectTemplate = async (template: Template): Promise<void> => {
    gaTrack(GA4Events.EMAIL_CAMPAIGN_TEMPLATE_SELECTED, {
      campaignId,
      templateName: template.name,
      templateId: template.id,
    });
    const wildcardsReplacedJson = replaceWildCardsInJSON({ json: template.unlayerJson, clinic });

    if (!isTemplateAssigned) {
      await updateOneEmailCampaign({
        variables: {
          data: {
            htmlContent: template.htmlContent,
            unlayerJson: wildcardsReplacedJson,
          },
          where: {
            id: campaignId,
          },
        },
      });
    }

    let newURL = `${RouteBasePaths.OttoEmailCampaigns}/${campaignId}/${CampaignAction.editCampaignHTML}`;
    if (isTemplateAssigned) {
      newURL += `/?templateId=${template.id}`;
    }
    history.push(newURL);
  };

  return (
    <Flex
      direction="column"
      className="OttoEmailCampaignsTemplateList__Container"
      overflowX="hidden"
      overflowY="auto"
      height="100%"
      padding="40px 70px"
      bgColor="background.default"
    >
      <Box mb="4">
        <Button
          size="sm"
          iconName="chevronLeft"
          variant="ghost"
          marginLeft="-3"
          onClick={(): void => {
            history.goBack();
          }}
        >
          Back
        </Button>
        <Heading marginTop="2" marginBottom="2" size="lg">
          Choose a Template
        </Heading>
        <Text size="md" color="text.subtle">
          Start with a template, or create a custom design from scratch.
        </Text>
      </Box>
      <Flex justifyContent="flex-start" flexWrap="wrap">
        {templates?.map((template) => {
          const wildCardsReplacedHTML = replaceWildCardsInHTML({ html: template.htmlContent, clinic });

          return (
            <Card
              isInteractive
              key={template.id}
              size="sm"
              cursor="pointer"
              marginRight="4"
              marginBottom="4"
              data-testid="email-campaign-template-card"
              onClick={(): Promise<void> => handleSelectTemplate(template)}
            >
              {!template.htmlContent || template.name.toLowerCase().includes('blank') ? (
                <EmailPreview width="284px" height="340px" />
              ) : (
                <TemplatePreview html={wildCardsReplacedHTML} />
              )}

              <Heading size="md" mt="4">
                {template.name}
              </Heading>
            </Card>
          );
        })}
      </Flex>
    </Flex>
  );
};

export default TemplateList;
