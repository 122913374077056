import { makeVar } from '@apollo/client';
import { addDays } from 'date-fns';
import { DEFAULT_APPOINTMENT_DURATION_IN_MINUTES, DEFAULT_DAYS_AHEAD_INTERVAL } from 'pages/Appointments';
import { PersistedStateKeys } from 'shared/enums/PersistedStateKeys';
import { jsonDateParser } from 'shared/utils';

export interface AppointmentFilters {
  currentDay: Date;
  startDate: Date;
  endDate: Date;
  daysAheadInterval: number;
  defaultAppointmentDurationInMinutes: number;
}

/**
 * Get the default persisted appointmentFilters
 */
const getAppointmentDefaultFilters = (): AppointmentFilters => {
  const result = localStorage.getItem(PersistedStateKeys.AppointmentFilters);
  return result
    ? JSON.parse(result, jsonDateParser)
    : {
        currentDay: new Date(),
        startDate: new Date(),
        endDate: addDays(new Date(), DEFAULT_DAYS_AHEAD_INTERVAL),
        daysAheadInterval: DEFAULT_DAYS_AHEAD_INTERVAL,
        defaultAppointmentDurationInMinutes: DEFAULT_APPOINTMENT_DURATION_IN_MINUTES,
      };
};

const appointmentFilters = makeVar<AppointmentFilters>(getAppointmentDefaultFilters());

/**
 * Get the persisted appointmentFilters
 */
export const getPersistedAppointmentFilters = (): AppointmentFilters => {
  return appointmentFilters();
};

/**
 * Set the appointmentFilters in local storage as well as in the reactiveVar
 * @param data AppointmentFilters
 */
export const setPersistedAppointmentFilters = (data: AppointmentFilters): void => {
  localStorage.setItem(PersistedStateKeys.AppointmentFilters, JSON.stringify(data));
  appointmentFilters(data);
};
