import { MenuItemProps, Select, SelectOptionProps, Text, TextInput, Wrap, WrapItem } from '@televet/kibble-ui';
import { PatientAgeUnit } from 'pages/Automations/types/SendConditionPatientAge';
import React, { useMemo } from 'react';
import { formatPluralization } from 'shared/utils';

type PetAgeFormProps = {
  operator: string;
  handleAgeOperatorChange: (menuItem: MenuItemProps) => void;
  operandValue:
  | number
  | {
    upper: number | null;
    lower: number | null;
  }
  | null;
  displayUnit?: string;
  handleAgeValueChange: (value: string) => void;
  handleAgeDisplayUnitChange: (menuItem: MenuItemProps) => void;
  patientAgeOperatorOptions: SelectOptionProps[];
};

export const PetAgeForm = ({
  operator,
  handleAgeOperatorChange,
  operandValue,
  displayUnit,
  handleAgeValueChange,
  handleAgeDisplayUnitChange,
  patientAgeOperatorOptions
}: PetAgeFormProps): JSX.Element => {

  const formattedAgeUnits = useMemo(() => {
    return [
      {
        label: formatPluralization({
          word: 'Month',
          singularCriteria: typeof operandValue === 'number' ? operandValue : 0,
        }),
        value: PatientAgeUnit.Months,
      },
      {
        label: formatPluralization({
          word: 'Year',
          singularCriteria: typeof operandValue === 'number' ? operandValue : 0,
        }),
        value: PatientAgeUnit.Years,
      },
    ]
  }, [operandValue])

  return (
    <Wrap align="center">
      <WrapItem>
        <Select
          minW="112px"
          options={patientAgeOperatorOptions}
          value={operator}
          listProps={{
            onSelect: handleAgeOperatorChange,
          }}
        />
      </WrapItem>
      <WrapItem>
        <Text>than</Text>
      </WrapItem>
      <WrapItem flex="0 1 auto">
        <TextInput
          w="52px"
          value={typeof operandValue === 'number' ? operandValue.toString() : ''}
          onChange={(event): void => handleAgeValueChange(event.target.value)}
        />
      </WrapItem>
      <WrapItem flex="0 0 auto">
        <Select
          minW="106px"
          options={formattedAgeUnits}
          value={displayUnit}
          listProps={{ onSelect: handleAgeDisplayUnitChange }}
        />
      </WrapItem>
    </Wrap>
  );
};
