import React, { useMemo } from 'react';
import { AutomationButtonConfig } from 'pages/Automations/types/AutomationPrompt';
import { ButtonErrorDetail } from 'pages/Automations/types/AutomationValidation';
import { Box, Button, HStack, Text, VStack, Tag, Square, DragHandle, Icon, Center } from '@televet/kibble-ui';
import partial from 'lodash-es/partial';
import omit from 'lodash-es/omit';
import flatten from 'lodash-es/flatten';
import { useGetFieldErrorText } from 'pages/Automations/hooks/useGetFieldErrorText';
import { AutomationRunActionPromptType, FullChannelStatusFragment } from 'shared/types/graphql';
import { useGetNextStepDisplayProps } from 'pages/Automations/hooks/useGetNextStepDisplayProps';
import { getStepValueFromConfig } from 'pages/Automations/utils/getStepValueFromConfig';
import { NextStepSelectValue } from 'pages/Automations/types/NextStepSelectValue';

interface ButtonListItemProps {
  button: AutomationButtonConfig;
  buttonIndex: number;
  buttonCount: number;
  buttonErrorDetail: ButtonErrorDetail | undefined;
  channelStatus: FullChannelStatusFragment | undefined;
  promptType: AutomationRunActionPromptType;
  onEditButton: () => void;
  onDeleteButton: () => void;
}

const ButtonListItem = ({
  button,
  buttonIndex,
  buttonCount,
  buttonErrorDetail,
  channelStatus,
  promptType,
  onEditButton,
  onDeleteButton,
}: ButtonListItemProps): JSX.Element => {
  const buttonErrorTypes = flatten(Object.values(omit(buttonErrorDetail, 'buttonIndex')));
  const errorText = useGetFieldErrorText(buttonErrorTypes);

  const nextStepValue: NextStepSelectValue | string = useMemo(
    () =>
      getStepValueFromConfig({
        workflowId: button.nextWorkflowEventTriggerSettingId,
        actionNumber: button.workflowEventTriggerActionNumber,
        isStatementPrompt: false,
        sendButtonMessage: button.doNothing,
      }),
    [button],
  );
  const followupStepValue: NextStepSelectValue | string = useMemo(
    () =>
      getStepValueFromConfig({
        workflowId: button.conditionalNextStepConfig?.fallbackNextWorkflowEventSettingId,
        actionNumber: button.conditionalNextStepConfig?.fallbackNextActionNumber,
        isStatementPrompt: false,
        sendButtonMessage: button.doNothing,
      }),
    [button],
  );
  const { options } = useGetNextStepDisplayProps({ promptType });
  const nextStepDisplayProps = useMemo(
    () => options.find((option) => option.value === nextStepValue),
    [options, nextStepValue],
  );
  const followupStepDisplayProps = useMemo(
    () => options.find((option) => option.value === followupStepValue),
    [options, followupStepValue],
  );

  return (
    <HStack
      borderRadius="md"
      border="1px"
      borderColor={buttonErrorTypes.length ? 'border.danger' : 'border.default'}
      bg="background.default"
      p={2}
      mb={2}
      spacing={2}
      className="PromptBlock__Button"
    >
      {buttonCount > 1 && <DragHandle size="md" />}
      <VStack flex="1 1 auto" align="flex-start" overflow="hidden">
        <Text>{button.body}</Text>
        <HStack w="100%">
          <Tag
            label={channelStatus?.name || 'No Change'}
            size="md"
            flex="0 0 auto"
            background="background.subtle"
            fontWeight="regular"
            leftElement={
              <Square
                bgColor={channelStatus?.color || 'transparent'}
                border="1px"
                borderColor={`${channelStatus?.color ?? 'border.default'}`}
                size="1em"
                borderRadius={4}
                mr={1}
              />
            }
          />

          <HStack overflow="hidden" spacing={1}>
            <Tag
              label={nextStepDisplayProps?.label || '[Deleted Automation]'}
              leftElement={
                nextStepDisplayProps?.iconName && (
                  <Center flex=" 0 0 auto">
                    <Icon size="sm" name={nextStepDisplayProps.iconName} />
                  </Center>
                )
              }
              size="md"
              color={nextStepDisplayProps?.variant === 'danger' ? 'text.danger' : 'text.default'}
              fontWeight="regular"
              background="background.subtle"
            />
            {nextStepDisplayProps?.isConditionalChild && (
              <>
                <Center flex="0 0 auto">
                  <Icon name="arrowRight" size="xs" />
                </Center>
                <Tag
                  label={followupStepDisplayProps?.label || '[Deleted Automation]'}
                  leftElement={
                    followupStepDisplayProps?.iconName && (
                      <Center flex=" 0 0 auto">
                        <Icon size="sm" name={followupStepDisplayProps.iconName} />
                      </Center>
                    )
                  }
                  size="md"
                  color={followupStepDisplayProps?.variant === 'danger' ? 'text.danger' : 'text.default'}
                  fontWeight="regular"
                  background="background.subtle"
                />
              </>
            )}
          </HStack>
        </HStack>
        {errorText && (
          <Box>
            <Text size="sm" variant="danger">
              {errorText}
            </Text>
          </Box>
        )}
      </VStack>
      <HStack>
        <Button iconName="pen" variant="ghostNeutral" size="md" onClick={partial(onEditButton, buttonIndex)} />
        <Button iconName="trashcan" variant="ghostNeutral" size="md" onClick={partial(onDeleteButton, buttonIndex)} />
      </HStack>
    </HStack>
  );
};

export default ButtonListItem;
