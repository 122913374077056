import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as Sentry from '@sentry/react';
import { Box, Center, Flex, VStack } from '@televet/kibble-ui/build/chakra';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { Spinner } from '@televet/kibble-ui/build/components/Spinner';
import { useToast } from '@televet/kibble-ui/build/components/Toast';
import wallpaperUrl from 'assets/images/wallpaper.jpeg';
import cloneDeep from 'lodash-es/cloneDeep';
import findLastIndex from 'lodash-es/findLastIndex';
import uniq from 'lodash-es/uniq';
import { ReactComponent as EmptyStateIllustration } from 'pages/Conversations/assets/channel-view-empty-state.svg';
import getIsAllPetParentsReachableBySms from 'pages/Conversations/utils/getIsAllPetParentsReachableBySms';
import DevModeDisplayJSON from 'pages/Settings/components/DevModeDisplayJSON';
import TeamChannelToolbar from 'pages/Team/TeamChannelToolbar/TeamChannelToolbar';
import { useInView } from 'react-intersection-observer';
import { useHistory } from 'react-router-dom';
import { RouteDefinitions } from 'routes';
import { useSidePanelSearch } from 'shared/components/SidePanel/components/SearchPanel/state/providers/SearchPanelProvider';
import { FeatureFlagName } from 'shared/enums/FeatureFlagName';
import { GraphQLFetchPolicies } from 'shared/enums/GraphQLFetchPolicies';
import useAddChannelMember from 'shared/hooks/useAddChannelMember';
import useClinicUser from 'shared/hooks/useClinicUser';
import useFeatureFlag from 'shared/hooks/useFeatureFlag';
import useUpdateCurrentClinic from 'shared/hooks/useUpdateCurrentClinic';
import { cache } from 'shared/providers/ApolloProvider/cache';
import { integrationSystemDisplayName, useIntegrationsProvider } from 'shared/providers/IntegrationsProvider';
import { useUnreadMessageCountProvider } from 'shared/providers/UnreadMessageCountProvider';
import {
  ChannelMessageAttachmentType,
  ChannelType,
  ChannelViewChannelMessageFragment,
  ChannelVisibility,
  ClinicEntityPhoneNumber,
  ClinicEntityPhoneNumberSmsStatus,
  ClinicPetParentUpdateInput,
  Maybe,
  MessageSource,
  MutationType,
  PermissionType,
  SortOrder,
  useGetChannelViewChannelMessagesQuery,
  useGetClinicChannelViewChannelQuery,
  useSubscribeToChannelViewChannelMembersSubscription,
  useSubscribeToChannelViewChannelMessagesSubscription,
  useSubscribeToChannelViewChannelSubscription,
  useUpdateOneClinicPetParentMutation,
} from 'shared/types/graphql';
import { checkIfTollFree, getBalanceTerminology, validatePhoneNumber } from 'shared/utils';
import { email as emailRegex } from 'shared/utils/validation';
import { useAppSelector } from 'state/hooks';
import useTrackChannelView from '../../hooks/useTrackChannelView';
import { IAttachmentOption } from '../../interfaces/IAttachmentOption';
import ChatMediaPreviewModal from '../Modals/ChatMediaPreview/ChatMediaPreviewModal';
import InvoicePreviewModal from '../Modals/InvoicePreview/InvoicePreviewModal';
import AfterHoursNoticeBanner from './Header/AfterHoursNoticeBanner';
import ChannelViewPatients from './Header/ChannelViewPatients';
import ChannelViewToolbar from './Header/ChannelViewToolbar';
import { ChannelWarningBox } from './Header/ChannelWarningBox';
import MessageComposer from './MessageComposer';
import MessageTimeline from './Timeline';


export interface INotificationErrorTextProps {
  headers: string[];
  title: string;
  reason: string[];
  parentId?: string;
}

enum ErrorReasonText {
  INVALID_FORMAT = 'This client has invalid formatted phone number',
  NUMBER_MISSING = `This client's phone number is missing`,
  CARRIER_FILTERES = `This client's phone number is unable to receive text messages`,
  LANDLINE_NUMBER = `This client's phone number is a landline or is otherwise unreachable by text message`,
  INVALID_NUMBER = `This client's phone number is invalid`,
  OPTED_OUT = `This client has opted out of receiving text messages`,
  EMAIL_OPTED_OUT = `This client has opted out of receiving email notifications`,
  UNKNOWN = `This client's phone number is unable to receive text messages`,
  NOT_IN_SERVICE = `This client's phone number is not in service`,
  EMAIL_MISSING = `This client's email address is missing`,
  INVALID_EMAIL = 'This client has an invalid email address',
  TOLL_FREE_NUMBER = `Otto Flow does not support sending SMS messages to toll-free numbers`,
}

interface IProps {
  channelId?: string;
  refetchChannels?: () => void;
  isOverlay?: boolean;
}

const ChannelView = ({ channelId, refetchChannels, isOverlay }: IProps): JSX.Element => {
  const history = useHistory();
  const { clinicUser, currentClinicId, currentClinic, userHasPermission } = useClinicUser();
  const [updateClinicPetParent] = useUpdateOneClinicPetParentMutation();
  const { currentClinicPetParentId } = useSidePanelSearch();
  const toast = useToast();

  const messageTimelineScrollContainerRef = useRef<HTMLDivElement | null>(null);
  const [sendAsChatMessage, setSendAsChatMessage] = useState(false);
  const handleSendAsChatMessageChange = (sendAsChatMessage: boolean): void => {
    setSendAsChatMessage(sendAsChatMessage);
  };
  const { updateCurrentClinic, loading: isUpdatingCurrentClinic } = useUpdateCurrentClinic();
  const [addChannelMember] = useAddChannelMember();
  const { isFeatureEnabled } = useFeatureFlag();
  const { updateLastConsumedMessageIndex, getCurrentChannelUnreadMessagesData } = useUnreadMessageCountProvider();

  const channelUnreadMessagesData = useMemo(() => {
    if (!channelId) return;

    return getCurrentChannelUnreadMessagesData(channelId);
  }, [channelId, getCurrentChannelUnreadMessagesData]);

  const {
    data: channelData,
    loading: isChannelLoading,
    refetch: refetchChannelViewChannel,
  } = useGetClinicChannelViewChannelQuery({
    variables: {
      channelId: channelId || '',
      clinicId: currentClinicId || '',
      userId: clinicUser?.id || '',
    },
    skip: !channelId || !currentClinicId || !clinicUser,
    fetchPolicy: GraphQLFetchPolicies.CacheAndNetwork,
    onCompleted: (data) => {
      if (!data?.findUniqueChannel) {
        toast({ status: 'error', title: 'Conversation not found' });
      }
    },
  });

  useSubscribeToChannelViewChannelSubscription({
    variables: { channelId: channelId || '', clinicId: currentClinicId || '', userId: clinicUser?.id || '' },
    skip: !channelId || !currentClinicId || !clinicUser,
  });

  /**
   * Track each new conversation view in Mixpanel
   */
  useTrackChannelView({
    channelId,
    channelData,
    unreadMessageCount: channelUnreadMessagesData?.unreadMessageCount || 0,
  });

  const channelMembers = useMemo(() => channelData?.findUniqueChannel?.channelMembers || [], [channelData]);
  const currentUserChannelMember = useMemo(
    () => channelMembers.find(({ user }) => user && user?.id === clinicUser?.id),
    [channelMembers, clinicUser?.id],
  );
  const clinicPetParents = useMemo(
    () => channelMembers.filter(({ removedAt }) => !removedAt).flatMap(({ clinicPetParent }) => clinicPetParent || []),
    [channelMembers],
  );

  const [previewData, setPreviewData] = useState({
    previewFileUrl: '',
    previewAttachmentType: ChannelMessageAttachmentType.File,
    previewFileName: '',
  });

  const [messageComposerAttachments, setMessageComposerAttachments] = useState<IAttachmentOption[]>([]);
  const [currentInvoiceId, setCurrentInvoiceId] = useState<string>('');
  const [invoiceClinicPetParentId, setInvoiceClinicPetParentId] = useState<string>('');
  const [attachmentFeePercent, setAttachmentFeePercent] = useState<number>();

  const attachmentInvoiceIds = useMemo(() => {
    return uniq(messageComposerAttachments.map((attachment) => attachment.invoiceIds || []).flat());
  }, [messageComposerAttachments]);

  const invoiceAttachmentTotal =
    messageComposerAttachments?.find((attachment) => !!attachment.invoiceAmount)?.invoiceAmount || '0';

  const hasCustomizableFees = useMemo(
    () => !!currentClinic?.clinicSetting?.hasCustomizableFees,
    [currentClinic?.clinicSetting?.hasCustomizableFees],
  );

  const updateInvoiceAttachmentDiscount = useCallback(
    (invoiceId: string, discount: number) => {
      setMessageComposerAttachments((prevAttachments) => {
        const newPrevAttachments = [...prevAttachments];
        const invoiceAttachmentIndex = newPrevAttachments.findIndex((attachment) =>
          attachment.invoiceIds?.includes(invoiceId),
        );

        // add new discount to previous discount and subtract from previous total
        if (invoiceAttachmentIndex > -1) {
          const { invoiceTotalDiscounts, invoiceAmount } = newPrevAttachments[invoiceAttachmentIndex];
          newPrevAttachments[invoiceAttachmentIndex].invoiceTotalDiscounts = `${
            parseInt(invoiceTotalDiscounts || '0') + discount
          }`;
          newPrevAttachments[invoiceAttachmentIndex].invoiceAmount = (
            parseFloat(invoiceAmount || '0') -
            discount / 100
          ).toFixed(2);
        }

        return newPrevAttachments;
      });
    },
    [setMessageComposerAttachments],
  );

  const {
    data: channelMessagesData,
    loading: isChannelMessagesLoading,
    called: isChannelMessagesCalled,
    fetchMore: fetchMoreChannelMessages,
    updateQuery: updateChannelMessagesQuery,
    refetch: refetchChannelMessages,
    variables: channelMessagesVariables,
  } = useGetChannelViewChannelMessagesQuery({
    skip: !channelId,
    variables: {
      where: {
        channelId: { equals: channelId || '' },
      },
      orderBy: { createdAt: SortOrder.Asc },
      take: -20,
    },
    fetchPolicy: GraphQLFetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
  });

  useSubscribeToChannelViewChannelMessagesSubscription({
    skip: !channelId || (!isChannelMessagesCalled && isChannelMessagesLoading),
    variables: { channelId: channelId || '' },
    onData: ({ data }) => {
      if (!data.data?.channelMessageChanged) {
        return;
      }
      const { node: newChannelMessage, mutation: mutationType } = data.data?.channelMessageChanged;
      if (!newChannelMessage) {
        return;
      }
      if (mutationType === MutationType.Create) {
        updateChannelMessagesQuery((previousQueryResult) => {
          const queryResult = cloneDeep(previousQueryResult);
          queryResult.findManyChannelMessage?.push(newChannelMessage);
          return queryResult;
        });
      }
      if (mutationType === MutationType.Delete) {
        cache.evict({ fieldName: 'findManyChannelMessage', args: channelMessagesVariables });
        refetchChannelMessages();
      }
    },
  });

  const hasPreviousPage = useMemo(
    () =>
      typeof channelMessagesData?.findManyChannelMessageCount === 'number' &&
      channelMessagesData.findManyChannelMessageCount > (channelMessagesData?.findManyChannelMessage?.length || 0),
    [channelMessagesData],
  );

  const channelLastMessage = useMemo(() => {
    if (!channelMessagesData?.findManyChannelMessage?.length) return null;
    return (
      channelMessagesData.findManyChannelMessage[
        findLastIndex(channelMessagesData.findManyChannelMessage, ({ author }) => !!author)
      ] || null
    );
  }, [channelMessagesData]);

  const isTeamChannel = Boolean(
    channelData?.findUniqueChannel && channelData.findUniqueChannel.channelType === ChannelType.Team,
  );

  const isDirectTeamMessage = Boolean(
    channelData?.findUniqueChannel &&
      channelData.findUniqueChannel.channelVisibility === ChannelVisibility.DirectMessage,
  );

  const isAllPetParentsReachableBySms = useMemo(() => {
    return getIsAllPetParentsReachableBySms(clinicPetParents);
  }, [clinicPetParents]);

  /**
   * Sync "Send as chat message" checkbox with the platform
   * from which the last message was sent (web vs. SMS)
   */
  useEffect(() => {
    if (!channelLastMessage) {
      return;
    }
    if (!isAllPetParentsReachableBySms || isTeamChannel) {
      setSendAsChatMessage(true);
      return;
    } else if (channelLastMessage.author?.clinicPetParent?.id) {
      setSendAsChatMessage(
        channelLastMessage.source === MessageSource.Web ||
          !channelLastMessage.author?.clinicPetParent?.petParentSetting?.smsNotifications,
      );
    } else {
      setSendAsChatMessage(false);
    }
  }, [channelLastMessage, isTeamChannel, isAllPetParentsReachableBySms, setSendAsChatMessage, channelId]);

  useSubscribeToChannelViewChannelMembersSubscription({
    variables: { channelId: channelId || '' },
    skip: !channelId,
  });

  const previousChannelIdRef = useRef<string | null>(null);

  const canUserViewChannel = useMemo(() => {
    if (!channelData?.findUniqueChannel || !currentClinicId) return false;
    return userHasPermission(PermissionType.ReadChannel, channelData?.findUniqueChannel?.clinic?.id);
  }, [channelData, currentClinicId, userHasPermission]);

  const lastMessage = useRef<{
    messageId: string;
    messageIndex: number;
    channelId: string;
    ref: HTMLDivElement | null;
  }>({
    messageId: '',
    messageIndex: -1,
    channelId: '',
    ref: null,
  });

  const addNewChannelMember = useCallback(
    async ({ channelId, userId }) => {
      const channelMember = await addChannelMember({ variables: { channelId: channelId, userId } });

      if (channelMember?.data?.addChannelMember?.id) {
        updateLastConsumedMessageIndex(lastMessage.current.messageIndex, channelMember.data.addChannelMember.id);
      }
    },
    [addChannelMember, updateLastConsumedMessageIndex, lastMessage],
  );

  /*
    Ensure user is a channel member when entering the channel
  */
  useEffect(() => {
    const channel = channelData?.findUniqueChannel;
    const userId = clinicUser?.id;
    const previousChannelId = previousChannelIdRef?.current;

    if (channel && userId && previousChannelId !== channel.id) {
      previousChannelIdRef.current = channel.id;

      if (!canUserViewChannel) {
        history.push(RouteDefinitions.Conversations.replace(':channelId?', ''));
        return;
      }

      if (channel.clinic?.id && currentClinicId) {
        if (channel.clinic.id !== currentClinicId) {
          updateCurrentClinic(channel.clinic.id);
        } else {
          const author = channel.channelMembers?.find((m) => m.user?.id === userId);
          if (!author) {
            addNewChannelMember({ channelId: channel?.id, userId });
          }
        }
      }
    }
  }, [
    channelData,
    isChannelLoading,
    clinicUser,
    currentClinicId,
    canUserViewChannel,
    history,
    updateCurrentClinic,
    addNewChannelMember,
  ]);

  const scrollHeight = useRef(0);
  const shouldCaptureScroll = useRef(false);
  const newMessageIndicatorRef = useRef<HTMLDivElement>(null);

  const scrollToDesiredMessage = (): void => {
    // Immediately ensure the conversation is scrolled to the bottom
    if (lastMessage?.current) {
      lastMessage?.current?.ref?.scrollIntoView();
    }

    // Wait to ensure invoices and automation messages load in fully before completing scroll
    setTimeout(() => {
      if (newMessageIndicatorRef.current) {
        newMessageIndicatorRef?.current?.scrollIntoView({ behavior: 'smooth' });
      } else if (lastMessage?.current) {
        lastMessage?.current?.ref?.scrollIntoView();
      }
    }, 1500);
  };

  /**
   * Reset refs to defaults when channelId changes
   */
  useEffect(() => {
    lastMessage.current = {
      messageId: '',
      messageIndex: -1,
      channelId: '',
      ref: null,
    };
    scrollHeight.current = 0;
    shouldCaptureScroll.current = false;

    scrollToDesiredMessage();
  }, [channelId, lastMessage, scrollHeight, shouldCaptureScroll]);

  const earliestChannelMessageId = useMemo(
    () =>
      channelMessagesData?.findManyChannelMessage?.reduce(
        (prev, curr) => (!prev || curr.index < prev.index ? curr : prev),
        undefined as undefined | ChannelViewChannelMessageFragment,
      )?.id,
    [channelMessagesData],
  );

  const loadPreviousMessages = async (): Promise<void> => {
    if (hasPreviousPage && !isChannelMessagesLoading && earliestChannelMessageId) {
      // Safari on Ipad throws exception as _this.currentObservable undefined
      try {
        shouldCaptureScroll.current = true;
        await fetchMoreChannelMessages({
          variables: {
            cursor: { id: earliestChannelMessageId },
            skip: 1, // Skip the cursor
          },
        });
      } catch (error) {
        Sentry.captureException(error);
        console.error('unable to fetch previous messages', error);
      }
    }
  };

  const { inView: isScrolledToTop, ref: previousMessagesRef } = useInView({
    root: messageTimelineScrollContainerRef.current,
    rootMargin: '250px 0px 0px 0px',
  });

  useEffect(() => {
    if (isScrolledToTop) {
      loadPreviousMessages();
    }
  }, [isScrolledToTop]); // eslint-disable-line

  const handleFirstMessageMount = async (): Promise<void> => {
    const scrollContainer = messageTimelineScrollContainerRef?.current;
    if (!scrollContainer) return;
    scrollContainer.scrollTop = scrollContainer.scrollHeight - scrollHeight.current;
  };

  const handleLastMessageMount = async (
    message: ChannelViewChannelMessageFragment,
    element: HTMLDivElement,
  ): Promise<void> => {
    const { messageId } = lastMessage.current;
    if (messageId !== message.id && message.channel?.id) {
      lastMessage.current = {
        messageId: message.id,
        messageIndex: message.index,
        channelId: message.channel.id,
        ref: element,
      };

      if (!clinicUser || !message.channel?.id) return;
      if (currentUserChannelMember && message.index >= 0) {
        scrollToDesiredMessage();

        // Give container time to scroll to unread messages before updating count
        setTimeout(() => {
          updateLastConsumedMessageIndex(message.index, currentUserChannelMember?.id);
        }, 2000);
      }
    }
  };

  const setFilePreview = (
    previewFileUrl: string,
    previewAttachmentType: ChannelMessageAttachmentType,
    fileName: string,
  ): void => {
    if (!previewFileUrl) throw new Error("Could not find the file's location");
    setPreviewData({
      previewFileUrl: previewFileUrl || '',
      previewAttachmentType,
      previewFileName: fileName,
    });
    // openModal(ModalNames.ChatMediaPreview);
  };

  useEffect(() => {
    const onPageVisibilityChange = (): void => {
      if (document.visibilityState === 'visible' && clinicUser?.id && currentUserChannelMember) {
        const { messageIndex } = lastMessage.current;
        updateLastConsumedMessageIndex(messageIndex, currentUserChannelMember?.id);
      }
    };
    document.addEventListener('visibilitychange', onPageVisibilityChange);
    return (): void => {
      document.removeEventListener('visibilitychange', onPageVisibilityChange);
    };
  }, []); // eslint-disable-line

  const [phoneWarnings, setPhoneWarnings] = useState([] as INotificationErrorTextProps[]);
  const [emailWarnings, setEmailWarnings] = useState([] as INotificationErrorTextProps[]);
  const [showPhoneWarning, setShowPhoneWarning] = useState(phoneWarnings.length > 0);
  const [showEmailWarning, setShowEmailWarning] = useState(emailWarnings.length > 0);

  const [invoiceWarnings, setInvoiceWarnings] = useState([] as INotificationErrorTextProps[]);
  const [attachInvoiceClicked, setAttachInvoiceClicked] = useState(false);

  const updateIsMobileStatus = useCallback(
    async (phoneNumber, id) => {
      const data: ClinicPetParentUpdateInput = {};
      data.phoneNumbers = {
        update: [
          {
            where: { id: phoneNumber.id },
            data: {
              isMobile: false,
            },
          },
        ],
      };
      await updateClinicPetParent({
        variables: {
          where: { id: id },
          data,
        },
      });
    },
    [updateClinicPetParent],
  );

  const phoneErrorCheck = (
    status: Maybe<ClinicEntityPhoneNumberSmsStatus> | undefined,
    phoneNumber:
      | ({ __typename?: 'ClinicEntityPhoneNumber' | undefined } & Pick<
          ClinicEntityPhoneNumber,
          'number' | 'id' | 'updatedAt' | 'isDeleted' | 'isPrimary' | 'isMobile' | 'smsStatus'
        >)
      | undefined,
    petParentHasSmsNotifications: boolean | undefined,
  ): ErrorReasonText[] | void => {
    const errArr: ErrorReasonText[] = [];
    if (petParentHasSmsNotifications === false) {
      errArr.push(ErrorReasonText.OPTED_OUT);
    }

    if (phoneNumber?.number) {
      if (status === ClinicEntityPhoneNumberSmsStatus.RejectedBecauseLandline) {
        errArr.push(ErrorReasonText.LANDLINE_NUMBER);
      } else if (checkIfTollFree(phoneNumber.number)) {
        errArr.push(ErrorReasonText.TOLL_FREE_NUMBER);
      } else if (!validatePhoneNumber(phoneNumber.number)) {
        errArr.push(ErrorReasonText.INVALID_FORMAT);
      }
    } else {
      errArr.push(ErrorReasonText.NUMBER_MISSING);
    }

    if (errArr.length > 0) {
      return errArr;
    } else {
      const {
        RejectedFilteredByCarrier: FilteredByCarrier,
        RejectedUnknown: Unknown,
        RejectedNotInService: NotInService,
        RejectedBecauseLandline: Landline,
        RejectedBecauseInvalidPhoneNumber: Invalid,
        RejectedOptedOut: OptedOut,
      } = ClinicEntityPhoneNumberSmsStatus;
      if (status === FilteredByCarrier) {
        return [ErrorReasonText.CARRIER_FILTERES];
      } else if (status === Landline) {
        return [ErrorReasonText.LANDLINE_NUMBER];
      } else if (status === Invalid) {
        return [ErrorReasonText.INVALID_NUMBER];
      } else if (status === OptedOut) {
        return [ErrorReasonText.OPTED_OUT];
      } else if (status === NotInService) {
        return [ErrorReasonText.NOT_IN_SERVICE];
      } else if (status === Unknown) {
        return [ErrorReasonText.UNKNOWN];
      }
    }
  };

  const emailErrorCheck = (
    petParentEmail: Maybe<string> | undefined,
    isEmailNotificationOptedOutByPetParent: boolean | undefined,
  ): ErrorReasonText[] | void => {
    const errArr: ErrorReasonText[] = [];
    if (!petParentEmail) {
      errArr.push(ErrorReasonText.EMAIL_MISSING);
    }
    if (petParentEmail && !emailRegex.test(petParentEmail)) {
      errArr.push(ErrorReasonText.INVALID_EMAIL);
    }
    if (isEmailNotificationOptedOutByPetParent) {
      errArr.push(ErrorReasonText.EMAIL_OPTED_OUT);
    }

    return errArr.length > 0 ? errArr : undefined;
  };

  const { primaryIntegrationName, isInvoiceDiscoverySupported } = useIntegrationsProvider();

  const invoiceErrorCheck = useCallback((): string[] => {
    const balanceTerminology = getBalanceTerminology(primaryIntegrationName).toLocaleLowerCase();

    return [
      `Unable to pull ${
        primaryIntegrationName === integrationSystemDisplayName.AVIMARK ? '' : 'invoices or'
      } ${balanceTerminology} for clients not in ${primaryIntegrationName ?? 'your PIMS'}`,
    ];
  }, [primaryIntegrationName]);

  useEffect(() => {
    if (!clinicPetParents) {
      setPhoneWarnings([]);
      setInvoiceWarnings([]);
      return;
    }

    const phoneErrors: INotificationErrorTextProps[] = [];
    const emailErrors: INotificationErrorTextProps[] = [];
    const invoiceErrors: INotificationErrorTextProps[] = [];

    clinicPetParents.forEach((cpp) => {
      const clinicPetParentName = `${cpp?.firstName} ${cpp?.lastName}`;
      const clinicPetParentId = cpp?.id;
      const phoneNumber = cpp?.phoneNumbers?.find((pn) => pn.isPrimary && !pn.isDeleted);
      const status = phoneNumber?.smsStatus;
      const petParentEmail = cpp?.email;
      const petParentHasSmsNotifications = cpp?.petParentSetting?.smsNotifications;
      const isEmailNotificationOptedOutByPetParent = cpp?.petParentSetting?.emailOptedOutByPetParent;
      const phoneErr = phoneErrorCheck(status, phoneNumber, petParentHasSmsNotifications);
      const emailErr = emailErrorCheck(petParentEmail, isEmailNotificationOptedOutByPetParent);
      const invoiceErr = cpp?.pimsId === null ? invoiceErrorCheck() : null;

      if (phoneErr !== undefined && phoneErr.includes(ErrorReasonText.LANDLINE_NUMBER) && phoneNumber?.isMobile) {
        updateIsMobileStatus(phoneNumber, clinicPetParentId);
      }

      if (!!phoneErr) {
        phoneErrors.push({
          headers: ['Unable to send text notifications', 'Text notification not sent'],
          title: clinicPetParentName,
          reason: phoneErr,
          parentId: clinicPetParentId,
        });
      }

      if (!!emailErr) {
        emailErrors.push({
          headers: ['Unable to send email notifications', 'Email notification not sent'],
          title: clinicPetParentName,
          reason: emailErr,
          parentId: clinicPetParentId,
        });
      }

      if (!!invoiceErr) {
        invoiceErrors.push({
          headers: ['Unable to load Invoice data'],
          title: clinicPetParentName,
          reason: invoiceErr,
          parentId: clinicPetParentId,
        });
      }
    });

    setPhoneWarnings(phoneErrors);
    setEmailWarnings(emailErrors);
    setInvoiceWarnings(invoiceErrors);
  }, [clinicPetParents, invoiceErrorCheck, updateIsMobileStatus]);

  useEffect(() => {
    setShowPhoneWarning(true);
    setShowEmailWarning(true);
    setAttachInvoiceClicked(false);
  }, [channelId]);

  const messages = useMemo((): ChannelViewChannelMessageFragment[] => {
    /**
     * When lazy loading a previous page of messages, we store the current container height
     * just before the new messages are rendered. This allows us to set the scroll position
     * to the same place in the message history to eliminate a shift when new messages are
     * rendered. This is done in the useMemo in lieu of a useEffect hook, which would
     * require us to store messages separately in local state.
     */
    if (shouldCaptureScroll.current) {
      const scrollContainer = messageTimelineScrollContainerRef.current;
      if (scrollContainer) {
        scrollHeight.current = scrollContainer.scrollHeight;
        shouldCaptureScroll.current = false;
      }
    }
    return (channelMessagesData?.findManyChannelMessage || []).map((message: ChannelViewChannelMessageFragment) => {
      return {
        ...message,
        attributes: typeof message.attributes === 'string' ? JSON.parse(message.attributes) : message.attributes,
      };
    });
  }, [channelMessagesData, shouldCaptureScroll]);

  const wallpaperMode = useAppSelector((state) => state.currentClinic.wallpaperMode);

  if (channelId && isChannelLoading) {
    return (
      <Center w="100%" h="100%">
        <Spinner label="Loading conversation..." labelProps={{ size: 'md' }} showLabel={true} size="xl" />
      </Center>
    );
  }

  return (
    <Flex
      className="ChannelView__Container"
      flexDir="column"
      align="stretch"
      justify="flex-start"
      flex="1 1 auto"
      h="100%"
      background="background.default"
      sx={{
        '@media (max-width: 850px)': {
          width: '100%',
        },
      }}
    >
      {channelId && channelData?.findUniqueChannel ? (
        isTeamChannel && refetchChannels ? (
          <TeamChannelToolbar channel={channelData?.findUniqueChannel} refetchChannels={refetchChannels} />
        ) : (
          <>
            <ChannelViewToolbar
              channel={channelData?.findUniqueChannel}
              onRefetchChannel={refetchChannelViewChannel}
              isOverlay={isOverlay}
            />
            <ChannelViewPatients
              linkedChannelPets={channelData?.findUniqueChannel?.pets}
              channelId={channelData?.findUniqueChannel?.id}
              channelMembers={channelData?.findUniqueChannel?.channelMembers}
              refetchChannel={refetchChannelViewChannel}
            />
            <DevModeDisplayJSON src={channelData?.findUniqueChannel || {}} />
            {showPhoneWarning && phoneWarnings.length > 0 && (
              <ChannelWarningBox
                warnings={phoneWarnings}
                close={(): void => {
                  setShowPhoneWarning(false);
                }}
              />
            )}
            {showEmailWarning && emailWarnings.length > 0 && (
              <ChannelWarningBox
                warnings={emailWarnings}
                close={(): void => {
                  setShowEmailWarning(false);
                }}
              />
            )}
            {attachInvoiceClicked &&
              invoiceWarnings.length > 0 &&
              isFeatureEnabled(FeatureFlagName.InvoiceDiscovery) &&
              isInvoiceDiscoverySupported && (
                <ChannelWarningBox
                  warnings={invoiceWarnings}
                  close={(): void => {
                    setAttachInvoiceClicked(false);
                  }}
                />
              )}
            <AfterHoursNoticeBanner />
          </>
        )
      ) : null}

      <InvoicePreviewModal
        attachmentInvoiceIds={attachmentInvoiceIds}
        defaultServiceFeePercentage={
          attachmentFeePercent !== undefined
            ? attachmentFeePercent
            : ((hasCustomizableFees && !isFeatureEnabled(FeatureFlagName.TerminalAppOnlySurchargeUpdates)) && currentClinic?.clinicSetting?.paymentFeeConfig?.onlineClientServiceFeePercent) ||
              0
        }
        currentInvoiceId={currentInvoiceId}
        clinicPetParentId={currentClinicPetParentId || invoiceClinicPetParentId}
        attachmentFeePercent={attachmentFeePercent}
        invoiceAttachmentTotal={invoiceAttachmentTotal}
        updateInvoiceAttachmentDiscount={updateInvoiceAttachmentDiscount}
      />

      {previewData.previewFileUrl && (
        <ChatMediaPreviewModal
          setPreviewData={setPreviewData}
          fileURL={previewData.previewFileUrl}
          attachmentType={previewData.previewAttachmentType}
          fileName={previewData.previewFileName}
        />
      )}
      {(!isChannelLoading && !channelData?.findUniqueChannel) || !channelId ? (
        <VStack
          h="100%"
          w="100%"
          justify="center"
          align="center"
          className="LoadingSpace"
          {...(wallpaperMode
            ? {
                backgroundImage: `url(${wallpaperUrl})`,
                backgroundRepeat: 'repeat',
              }
            : {})}
        >
          {wallpaperMode ? (
            <Box
              bg="white"
              borderRadius="xl"
              p={4}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="flex-start"
              border="1px solid"
              borderColor="border.default"
            >
              <EmptyStateIllustration />
              <Text variant="subtle">No conversation selected.</Text>
              <Text variant="subtle">Pick a conversation to view from the left.</Text>
            </Box>
          ) : (
            <>
              <EmptyStateIllustration />
              <Text variant="subtle">No conversation selected.</Text>
              <Text variant="subtle">Pick a conversation to view from the left.</Text>
            </>
          )}
        </VStack>
      ) : (
        <>
          {canUserViewChannel && !isUpdatingCurrentClinic && (
            <>
              <MessageTimeline
                messages={messages}
                channelId={channelId}
                isTeamChannel={isTeamChannel}
                hasPreviousPage={hasPreviousPage}
                channelData={channelData?.findUniqueChannel}
                messageTimelineScrollContainerRef={messageTimelineScrollContainerRef}
                previousMessagesRef={previousMessagesRef}
                currentUserId={clinicUser?.id}
                onLoadPreviousMessages={loadPreviousMessages}
                onFirstMessageMount={handleFirstMessageMount}
                onLastMessageMount={handleLastMessageMount}
                setPreviewData={setFilePreview}
                setCurrentInvoiceId={setCurrentInvoiceId}
                setInvoiceClinicPetParentId={setInvoiceClinicPetParentId}
                channelMemberId={currentUserChannelMember?.id}
                lastConsumedMessageIndex={channelUnreadMessagesData?.lastConsumedMessageIndex}
                newMessageIndicatorRef={newMessageIndicatorRef}
                phoneWarnings={phoneWarnings}
                emailWarnings={emailWarnings}
                clinicPetParents={clinicPetParents}
              />
              <MessageComposer
                isChannelLoading={isChannelLoading}
                isTeamChannel={isTeamChannel}
                isDirectTeamMessage={isDirectTeamMessage}
                channel={channelData?.findUniqueChannel}
                sendAsChatMessage={sendAsChatMessage}
                setPreviewData={setFilePreview}
                onSendAsChatMessageChange={handleSendAsChatMessageChange}
                setCurrentInvoiceId={setCurrentInvoiceId}
                invoiceClinicPetParentId={currentClinicPetParentId || ''}
                setAttachmentFeePercent={setAttachmentFeePercent}
                attachments={messageComposerAttachments}
                setAttachments={setMessageComposerAttachments}
                updateInvoiceAttachmentDiscount={updateInvoiceAttachmentDiscount}
                setAttachInvoiceClicked={setAttachInvoiceClicked}
              />
            </>
          )}
        </>
      )}
    </Flex>
  );
};

export default ChannelView;
