import React, { forwardRef, useMemo, useState } from 'react';
import { Flex, Box } from '@televet/kibble-ui/build/chakra';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { Menu, MenuItemProps } from '@televet/kibble-ui/build/components/Menu';
import { Icon } from '@televet/kibble-ui/build/components/Icon';
import { Tag } from '@televet/kibble-ui/build/components/Tag';
import { useAppointmentSettings } from '../../../hooks/useAppointmentSettings';
import TruncatedList from 'shared/components/TruncatedList';
import { useProviderAppointmentTypeRoomMap } from '../../../hooks/useProviderAppointmentTypeRoomMap';
import { ProviderFragment } from 'shared/types/graphql';

// eslint-disable-next-line
const ProviderSubMenu = forwardRef<HTMLDivElement, 'div'>((props, _ref) => (
  <Flex gap={3} {...props} m={0} p={0}>
    <Icon name="person" size="md" />
    <Flex justify="space-between" flex={1} align="center">
      <Text size="sm">Change Provider</Text>
      <Icon name="chevronRight" size="sm" />
    </Flex>
  </Flex>
));
ProviderSubMenu.displayName = 'ProviderSubMenu';

interface ProviderProps {
  provider: ProviderFragment;
  remove: (id: string) => void;
  selectedRoomIds: string[];
  updateRoomsForProvider: (id: string, roomIds: string[]) => void;
  selectedProviders: ProviderFragment[];
  changeProvider: (from: string, to: string) => void;
  showValidation: boolean;
}

const Provider = ({
  provider,
  remove,
  selectedRoomIds,
  updateRoomsForProvider,
  selectedProviders,
  changeProvider,
  showValidation,
}: ProviderProps): JSX.Element => {
  const [isRoomsListExpanded, setIsRoomsListExpanded] = useState(false);
  const { rooms, providers } = useAppointmentSettings();
  const { isSupported: isRoomMappingSupported, isRequired: isRoomMappingRequired } =
    useProviderAppointmentTypeRoomMap();

  const onRoomSelect = (_selectedItem: MenuItemProps, selectedItems?: (MenuItemProps | undefined)[]): void => {
    if (selectedItems?.length) {
      const newRoomIds = selectedItems.map((item) => item?.value || '');
      updateRoomsForProvider(provider.id, newRoomIds);
    } else {
      updateRoomsForProvider(provider.id, []);
    }
  };

  const removeRoom = (id: string): void => {
    const newRoomIds = selectedRoomIds.filter((_id) => _id !== id);
    updateRoomsForProvider(provider.id, newRoomIds);
  };

  const onRoomSelectAll = (selectedItems: MenuItemProps[]): void => {
    const newRoomIds = selectedItems.map((item) => item?.value || '');
    updateRoomsForProvider(provider.id, newRoomIds);
  };

  const roomMenuItems = useMemo(() => {
    return isRoomMappingSupported
      ? rooms?.map((room) => {
          return {
            label: room.name,
            value: room.id,
            isSelected: selectedRoomIds?.includes(room.id),
          };
        })
      : [];
  }, [isRoomMappingSupported, rooms, selectedRoomIds]);

  const selectedRooms = useMemo(
    () => (isRoomMappingSupported ? rooms?.filter((room) => selectedRoomIds?.includes(room.id)) : []),
    [rooms, selectedRoomIds, isRoomMappingSupported],
  );

  const providerMenuItems = useMemo(() => {
    return providers.map((_provider) => {
      return {
        label: `${_provider.firstName} ${_provider.lastName}`,
        value: _provider.id,
        isDisabled: selectedProviders.some((p) => p.id === _provider.id),
        onSelect: (selectedItem: MenuItemProps): void => changeProvider(provider.id, selectedItem.value || ''),
      };
    });
  }, [changeProvider, provider.id, providers, selectedProviders]);

  const subMenuItems = useMemo(() => {
    const items: MenuItemProps[] = [
      {
        label: (
          <Menu
            isLazy={true}
            buttonProps={{
              as: ProviderSubMenu,
            }}
            listProps={{
              isSearchable: true,
              menuItems: providerMenuItems,
              position: 'fixed',
              zIndex: 2,
              top: 25, // move to below item
              // TODO: add this functionality into kibble with Portal
            }}
          />
        ),
        closeOnSelect: false,
      },
      {
        label: 'Remove Provider',
        leftElement: <Icon name="trashcan" size="md" />,
        onSelect: (): void => remove(provider.id),
        value: 'removeProvider',
        variant: 'danger',
      },
    ];

    if (isRoomMappingSupported) {
      items.splice(1, 0, {
        label: 'Clear All Rooms',
        leftElement: <Icon name="arrowCircle" size="md" />,
        onSelect: () => updateRoomsForProvider(provider.id, []),
        value: 'clearRooms',
      });
    }
    return items;
  }, [provider.id, providerMenuItems, remove, isRoomMappingSupported, updateRoomsForProvider]);

  const name = useMemo(() => `${provider.firstName} ${provider.lastName}`, [provider.firstName, provider.lastName]);
  const toggleRoomListExpanded = (): void => setIsRoomsListExpanded((current) => !current);
  const isValid = useMemo(() => {
    if (isRoomMappingRequired && showValidation) {
      return selectedRooms.length;
    }
    return true;
  }, [isRoomMappingRequired, selectedRooms.length, showValidation]);

  return (
    <Flex
      bgColor="background.subtle"
      borderRadius="lg"
      border="1px"
      borderColor={isRoomMappingRequired ? (isValid ? 'border.default' : 'border.danger') : 'border.default'}
      flexDir="column"
    >
      <Flex justify="space-between" align="center" p={2} pl={3}>
        <Flex gap={2}>
          <Icon name="person" />
          <Text fontWeight="semibold">{name}</Text>
          {
            provider.pimsId &&
            <Text size="sm">{`ID: ${provider.pimsId}`}</Text>
          }
        </Flex>
        <Flex gap={1}>
          {!!isRoomMappingSupported && (
            <Menu
              isLazy={true}
              buttonProps={{
                variant: 'ghost',
                leftIconName: 'plus',
                text: 'Add Rooms',
                showRightIcon: false,
                size: 'sm',
              }}
              listProps={{
                isSearchable: true,
                isMultiSelect: true,
                canSelectAll: true,
                menuItems: roomMenuItems,
                onSelect: onRoomSelect,
                onSelectAll: onRoomSelectAll,
              }}
            />
          )}

          <Menu
            isLazy={true}
            buttonProps={{
              variant: 'ghostNeutral',
              size: 'sm',
              leftIconName: 'dotsVertical',
              showRightIcon: false,
            }}
            listProps={{
              menuItems: subMenuItems,
            }}
          />
        </Flex>
      </Flex>
      {!!isRoomMappingSupported && (
        <>
          {selectedRooms.length ? (
            <Flex align="flex-start" px={3} pb={4} gap={2}>
              <Icon name="locationMarker" />
              <Flex maxW="100%">
                <TruncatedList
                  isExpanded={isRoomsListExpanded}
                  renderTruncator={({ hiddenItemsCount }): React.ReactNode => (
                    <Button size="xs" variant="ghostNeutral" onClick={toggleRoomListExpanded}>
                      +{hiddenItemsCount} More
                    </Button>
                  )}
                >
                  {selectedRooms.map((room) => (
                    <Tag
                      key={room?.id}
                      closeButtonDisplay="hover"
                      onClose={(): void => removeRoom(room?.id)}
                      size="md"
                      fontWeight="regular"
                      bgColor="background.lessSubtle"
                      label={room?.name || ''}
                    />
                  ))}
                </TruncatedList>
              </Flex>
            </Flex>
          ) : (
            <>
              {!isValid && (
                <Box px={3} pb={4}>
                  <Text variant="danger">Please add a room for this provider.</Text>
                </Box>
              )}
            </>
          )}
        </>
      )}
    </Flex>
  );
};

export default React.memo(Provider);
