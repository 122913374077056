import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import { useModal } from '@televet/televet-ui';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import partial from 'lodash-es/partial';
import {
  ChatEntityAppointmentFragment,
  ChatEntityFormSubmissionFragment,
  useGetAppointmentFiltersQuery,
  useGetChatEntitiesQuery,
} from 'shared/types/graphql';
import { CalendarIcon, FormsOutlineIcon } from 'assets/icons';
import { IChatEntity } from '../types/IChatEntity';
import { ChatEntityType } from '../enums/ChatEntityType';
import LoadingIndicator from 'shared/components/LoadingIndicator';
import { GraphQLFetchPolicies } from 'shared/enums/GraphQLFetchPolicies';
import { ModalNames } from 'shared/enums/ModalNames';
import { Mixpanel } from 'shared/utils/mixpanel';
import useNavigateToFormSubmission from 'shared/hooks/useNavigateToFormSubmission';
import { getPersistedAppointmentFilters } from 'shared/providers/ApolloProvider/reactiveVars/appointmentFilters';
import { Text, Heading, HStack, Flex, Box, CPopoverHeader, CPopoverBody, PopoverArrow } from '@televet/kibble-ui';

interface IChatEntityPopoverContentProps {
  clinicPetParentIds: string[];
  onClose: () => void;
}

const ChatEntityPopoverContent = ({ clinicPetParentIds, onClose }: IChatEntityPopoverContentProps): JSX.Element => {
  const { data: chatEntitiesData, previousData: previousChatEntitiesData } = useGetChatEntitiesQuery({
    fetchPolicy: GraphQLFetchPolicies.CacheAndNetwork,
    variables: {
      appointmentWhereInput: { clinicPetParents: { some: { id: { in: clinicPetParentIds } } } },
      formSubmissionsWhereInput: { clinicPetParent: { id: { in: clinicPetParentIds } } },
    },
  });

  const chatEntities = useMemo(() => {
    if (!chatEntitiesData) return [];
    const chatEntities: IChatEntity[] = [];
    chatEntitiesData.appointments?.forEach((appointment: ChatEntityAppointmentFragment) => {
      chatEntities.push({
        id: appointment.id,
        type: ChatEntityType.Appointment,
        title: appointment.description,
        createdAt: appointment.createdAt,
      });
    });

    chatEntitiesData.formSubmissions?.forEach((formSubmission: ChatEntityFormSubmissionFragment) => {
      chatEntities.push({
        id: formSubmission.id,
        type: ChatEntityType.Form,
        title: formSubmission.formTemplate?.title || 'Unknown',
        createdAt: formSubmission.submittedAt,
      });
    });
    return chatEntities.sort((a, b) => {
      return parseISO(b.createdAt).getTime() - parseISO(a.createdAt).getTime();
    });
  }, [chatEntitiesData]);

  const { openModal } = useModal();

  const { navigateToFormSubmission } = useNavigateToFormSubmission();

  // TODO: Move these queries into the appointment modal
  const { data: appointmentFiltersData } = useGetAppointmentFiltersQuery();

  const defaultAppointmentDurationInMinutes = useMemo(() => {
    const appointmentFilters = appointmentFiltersData?.appointmentFilters || getPersistedAppointmentFilters();
    return appointmentFilters.defaultAppointmentDurationInMinutes;
  }, [appointmentFiltersData]);

  const handleChatEntitySelect = (id: string, type: ChatEntityType): void => {
    Mixpanel.track(`Conversations Conversation Activity ${type} Clicked`);

    if (type === ChatEntityType.Appointment) {
      const appointment = chatEntitiesData?.appointments?.find((a: ChatEntityAppointmentFragment) => {
        return a.id === id;
      });
      if (appointment) {
        openModal(ModalNames.Appointment, {
          startDate: parseISO(appointment.startAt),
          durationInMinutes: appointment.durationInMinutes,
          defaultAppointmentDurationInMinutes,
          appointmentId: id,
        });
      }
    } else {
      const formSubmission = chatEntitiesData?.formSubmissions?.find(
        (f: ChatEntityFormSubmissionFragment) => f.id === id,
      );
      if (formSubmission?.clinicPetParentId) {
        navigateToFormSubmission({
          formSubmissionId: formSubmission.id,
          clinicPetParentId: formSubmission.clinicPetParentId,
        });
      }
    }

    onClose();
  };

  return (
    <>
      <PopoverArrow />
      <CPopoverHeader>
        <Heading size="sm" variant="info" fontWeight="bold">
          Related to this conversation
        </Heading>
      </CPopoverHeader>
      <CPopoverBody
        w="400px"
        maxH="500px"
        p="0"
        display="flex"
        flexDirection="column"
        boxShadow="none"
        border="none"
        outline="none"
      >
        {!chatEntitiesData && !previousChatEntitiesData ? (
          <Box p="30px">
            <LoadingIndicator />
          </Box>
        ) : (
          <Flex direction="column" overflow="auto">
            {chatEntities.map((chatEntity) => {
              return (
                <HStack
                  key={chatEntity.id}
                  justify="space-between"
                  align="baseline"
                  cursor="pointer"
                  p="6"
                  _hover={{ bg: '#e6f4f6' }}
                  onClick={partial(handleChatEntitySelect, chatEntity.id, chatEntity.type)}
                >
                  <MenuItemTitle>
                    {chatEntity.type === ChatEntityType.Form ? <FormsOutlineIcon /> : <CalendarIcon />}
                    <Text variant="info" isTruncated={true}>
                      {chatEntity.type === ChatEntityType.Form ? `${chatEntity.title} Form` : 'New Appointment'}
                    </Text>
                  </MenuItemTitle>
                  <MenuTimestampContainer>
                    {chatEntity.createdAt ? (
                      <>
                        <Date>{format(parseISO(chatEntity.createdAt), 'LLL d')} at&nbsp;</Date>
                        <MenuItemTimestamp>{format(parseISO(chatEntity.createdAt), 'h:mmaa')}</MenuItemTimestamp>
                      </>
                    ) : null}
                  </MenuTimestampContainer>
                </HStack>
              );
            })}
          </Flex>
        )}
      </CPopoverBody>
    </>
  );
};

const MenuItemTitle = styled.div`
  flex: 1 1 auto;
  color: #39adc3;
  display: flex;
  align-items: baseline;
  font-size: 12px;
  font-weight: 600;
  overflow: hidden;

  svg {
    flex: 0 0 auto;
    fill: #575d7c;
    height: 15px;
    margin-right: 10px;
    max-width: 30px;
    position: relative;
    top: 2px;
  }
`;

const MenuTimestampContainer = styled.div`
  flex: 0 0 auto;
  font-size: 12px;
`;

const Date = styled.span`
  color: #72abff;
`;

const MenuItemTimestamp = styled.span`
  color: #72abff;
  text-transform: lowercase;
`;

export default ChatEntityPopoverContent;
