import { ClinicEntityCreationSource, ClinicEntityPhoneNumber, ClinicPetParent, User } from 'shared/types/graphql';
import { RGB } from 'shared/interfaces/RGB';
import { BaseEmoji, emojiIndex, EmojiSkin } from 'emoji-mart';
import { IAvatarGroupSelectOption } from 'shared/components/Avatars/AvatarGroupSelect';
import { formatDate } from 'pages/Conversations/utils/formatDate';
import { IntegrationSystemDisplayNameType, integrationSystemDisplayName } from 'shared/providers/IntegrationsProvider';
import { phone as phoneRegex } from './validation';
export { scrollIntoView } from './scrollIntoView';
import { parseISO } from 'date-fns';
export * from './mixpanel';
export * from './devMode';

export const jsonDateParser = (key: string, value: unknown): unknown => {
  const isoDate = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;

  if (typeof value === 'string') {
    const a = isoDate.exec(value);
    if (a) return new Date(value);
  }

  return value;
};

export const titleCase = (str: string): string => {
  const string = str.split(' ');
  for (let i = 0; i < string.length; i++) {
    string[i] = string[i].charAt(0).toUpperCase() + string[i].slice(1);
  }
  return string.join(' ');
};

export const capitalize = (word: string): string => {
  return word.charAt(0).toUpperCase() + word.substring(1);
};

export const truncateString = (text: string, limit: number): string => {
  if (text.length <= limit) {
    return text;
  } else {
    return `${text.slice(0, limit)}...`;
  }
};

export const getUserFullName = ({
  namePrefix,
  firstName,
  lastName,
  nameSuffix,
}: Pick<User, 'namePrefix' | 'firstName' | 'lastName' | 'nameSuffix'>): string =>
  [`${namePrefix ? `${namePrefix}.` : ''}`, firstName, `${lastName}${nameSuffix ? ', ' : ''}`, nameSuffix]
    .join(' ')
    .trim();

export const removePhoneCountryCode = (phoneNumber: string): string => {
  if (phoneNumber.startsWith('+')) {
    phoneNumber = phoneNumber.slice(1);
  }
  return phoneNumber[0] === '1' && phoneNumber.length === 11 ? phoneNumber.substring(1, 11) : phoneNumber;
};

export const formatPhoneNumber = (phoneNumber: string | null | undefined): string => {
  if (!phoneNumber) return '';

  phoneNumber = phoneNumber.replace(/[^0-9*#.]/g, '');
  phoneNumber = removePhoneCountryCode(phoneNumber);

  const firstGroup = phoneNumber.substring(0, 3);
  const secondGroup = phoneNumber.substring(3, 6);
  const thirdGroup = phoneNumber.substring(6, 10);
  if (!(firstGroup || secondGroup || thirdGroup)) {
    return '___-___-___';
  }
  if (firstGroup && !secondGroup && !thirdGroup) {
    return `${firstGroup}`;
  }
  if (firstGroup && secondGroup && !thirdGroup) {
    return `${firstGroup}-${secondGroup}`;
  }
  return `${firstGroup}-${secondGroup}-${thirdGroup}`;
};

export const isPhoneNumber = (phoneNumber: string): boolean => {
  const regex = new RegExp(phoneRegex);
  return regex.test(phoneNumber);
};

export const cleanPhoneNumber = (phoneNumber: string): string => {
  phoneNumber = phoneNumber.replace(/[^0-9.]/g, '');
  return phoneNumber;
};

/** This can be used to convert a non-E.164 formatted phone number to E.164 by providing the number and country code. If the country code is already included in the phoneNumber argument, it will be stripped off, so it must also be provided in the second argument. */
export const getPhoneNumberE164 = (phoneNumber: string, countryCode = '1'): string => {
  const cleanedPhoneNumber = cleanPhoneNumber(formatPhoneNumber(phoneNumber));
  const cleanedCountryCode = countryCode.replace(/[^0-9]/g, '');
  return `+${cleanedCountryCode}${cleanedPhoneNumber}`;
};

const rgbToYIQ = ({ r, g, b }: RGB): number => {
  return (r * 299 + g * 587 + b * 114) / 1000;
};

const hexToRgb = (hex: string): RGB | undefined => {
  if (!hex || hex === '') {
    return undefined;
  }

  const result: RegExpExecArray | null = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : undefined;
};

/**
 * Get the correct color contract via a hex value and threshold
 * @param colorHex
 * @param threshold
 */
export const colorContrast = (colorHex: string | undefined, threshold = 142): string => {
  if (colorHex === undefined) {
    return '#333';
  }

  const rgb: RGB | undefined = hexToRgb(colorHex);

  if (rgb === undefined) {
    return '#333';
  }

  return rgbToYIQ(rgb) >= threshold ? '#333' : '#fff';
};

/**
 * Darken or lighten a color hex value.
 * @param colorHex
 * @param amount Provide positive integer to lighten, negative integer to darken
 */
export const colorLightenDarken = (colorHex: string, amount: number): string => {
  let usePound = false;
  if (colorHex[0] == '#') {
    colorHex = colorHex.slice(1);
    usePound = true;
  }

  const num = parseInt(colorHex, 16);

  let r = (num >> 16) + amount;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00ff) + amount;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000ff) + amount;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
};

/**
 * Add commas to number
 * @param number '
 */
export const addCommasToNumber = (number: number | string): string => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/**
 *
 * @param value Number of cents
 * @param showDollarSign Format output with leading dollar sign
 * @returns a string with the currency formatted
 */
export const getMonetaryDisplayValue = (value?: number | null, showDollarSign?: boolean): string => {
  if (!showDollarSign) {
    if (value === 0) return '0.00';
    if (!value) return '-';
    return addCommasToNumber((+value / 100).toFixed(2));
  }
  if (value === 0) return '$0.00';
  if (!value) return '$-';
  if (value > 0) {
    return `$${addCommasToNumber((+value / 100).toFixed(2))}`;
  }
  return `-$${addCommasToNumber((Math.abs(value) / 100).toFixed(2))}`;
};

interface Emojis {
  id?: string;
  name?: string;
  colons?: string;
  /** Reverse mapping to keyof emoticons */
  emoticons?: string[];
  unified?: string;
  skin?: EmojiSkin | null;
  native?: string | undefined;

  // id is overridden by short_names[0]

  // colons is overridden by :id:

  /** Must contain at least one name. The first name is used as the unique id. */
  short_names?: string[];

  keywords?: string[];
  imageUrl?: string;
}

const getEmoji = (colons: string): string => {
  let word = '';
  if (colons.replace) {
    word = colons.replace(/:/g, '');
  }
  const emojis: Emojis[] = emojiIndex?.search(word) || [];
  let found = colons;
  for (const item of emojis) {
    if (item.colons === colons) {
      found = item.native || '';
    }
  }

  return found;
};

export const colonsToUnicode = (text: string): string => {
  let newText = text;
  /**
   * Look for an emoji symbol shortcut surrounded by spaces
   * Examples: " :) " or " :'( "
   */
  const emoticonsRegex = new RegExp(/^(:|;)\S{1,2}\s+|\s+(:|;)\S{1,2}\s+/g);
  /**
   * Look for an emoji text shortcut surrounced by spaces, e.g. " :smile: " or " :cry: "
   * Examples: " :smile: " or " :cry: "
   */
  //
  const colonsRegex = new RegExp(/^:\w+:\s+|\s+:\w+:\s+/g);

  newText = newText.replace(emoticonsRegex, (value) => {
    let newValue = value;
    const emoticon = value.trim();
    const results = emojiIndex.search(emoticon);
    if (results?.length) {
      const result = results[0] as BaseEmoji;
      newValue = newValue.replace(emoticon, result.native);
    }
    return newValue;
  });

  newText = newText.replace(colonsRegex, (value) => {
    let newValue = value;
    const colons = value.trim();
    newValue = newValue.replace(colons, getEmoji(colons));
    return newValue;
  });

  return newText;
};

/**
 * Transform an array of clinic pet parents into an array of options for use in a dropdown
 * @param parents
 */
export const getPetParentMemberOptions = (
  parents: Pick<ClinicPetParent, 'id' | 'firstName' | 'lastName'>[],
): IAvatarGroupSelectOption[] => {
  if (!parents) return [];

  return parents.map((parent) => {
    return { text: [parent.firstName, parent.lastName].join(' ').trim(), value: parent };
  });
};

/**
 * Convert a valid phone number string into the format we use in our database
 * @param phoneNumber a string containing a phone number
 * @returns
 */
export const validatePhoneNumber = (phoneNumber: string): string => {
  const cleaned = phoneNumber.replace(/\D/g, ''); // Remove any character that is not a decimal (0-9)
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/); // Match the format, and get the international code if there is one
  if (match) {
    return [match[2], '-', match[3], '-', match[4]].join('');
  }
  return '';
};

/**
 * Check if the phone number is a toll free number
 * @param phoneNumber a string containing a phone number
 * @returns
 */
export const checkIfTollFree = (phoneNumber: string): boolean => {
  const TOLL_FREE_NUMBERS_PREFIX = ['800', '833', '844', '855', '866', '877', '888'];
  const cleanPhoneNumber = phoneNumber.slice(-10);
  return TOLL_FREE_NUMBERS_PREFIX.some((prefix) => cleanPhoneNumber.startsWith(prefix));
};

/**
 * Filter a typed array of null values. Typescript workaround
 * @param input
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isNotNullOrUndefined = <T extends Record<string, any>>(input: null | undefined | T): input is T => {
  return input != null;
};

/**
 * Get the extension of a file
 * @param name
 * @returns string
 */
export const getFileExtension = (name: string): string | null => {
  const expArray = /(?:\.([^.]+))?$/.exec(name);
  return expArray?.length ? expArray[1] : null;
};

/**
 * Finds the first non-deleted phone marked as primary, if none, defaults to the first non-deleted phone, if none, returns null
 * @param phoneNumbers
 * @returns
 */
export const selectPrimaryPhoneNumberBestGuess = (
  phoneNumbers: Pick<ClinicEntityPhoneNumber, 'updatedAt' | 'number' | 'isPrimary' | 'isDeleted'>[],
): string | null => {
  const available = phoneNumbers
    .filter((pn) => !pn.isDeleted)
    .sort((a, b) => {
      if (a.updatedAt && b.updatedAt) {
        return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
      } else {
        return 0;
      }
    });
  const primary = available.find((pn) => pn.isPrimary);
  if (primary) return primary.number;

  const first = available[0];
  if (first) return first.number;

  return null;
};

/**
 * Get pet parent's display name based on name, phone number, creation source, etc.
 * @param clinicPetParent
 * @returns string
 */
export const getClinicPetParentDisplayName = ({
  firstName,
  lastName,
  creationSource,
  phoneNumbers,
}: {
  firstName?: string | null;
  lastName?: string | null;
  creationSource?: ClinicEntityCreationSource | null;
  phoneNumbers?: Pick<ClinicEntityPhoneNumber, 'updatedAt' | 'number' | 'isPrimary' | 'isDeleted'>[] | null;
}): string => {
  // TODO: Conditionally fetch phone numbers if pet parent doesn't have a name — this will
  // prevent us having to fetch phone numbers for every pet parent when they're rarely used
  // in this view
  if (creationSource && creationSource === ClinicEntityCreationSource.InboundSms && !(firstName || lastName)) {
    const listPhoneNumber = selectPrimaryPhoneNumberBestGuess(phoneNumbers || []);
    return formatPhoneNumber(listPhoneNumber);
  }
  return [titleCase(firstName || ''), titleCase(lastName || '')].join(' ').trim();
};

export const getUserTimezoneAbbreviation = (): string => {
  const today = new Date();
  const short = today.toLocaleDateString(undefined);
  const full = today.toLocaleDateString(undefined, { timeZoneName: 'short' });

  // Trying to remove date from the string in a locale-agnostic way
  const shortIndex = full.indexOf(short);
  if (shortIndex >= 0) {
    const trimmed = full.substring(0, shortIndex) + full.substring(shortIndex + short.length);

    // by this time `trimmed` should be the timezone's name with some punctuation -
    // trim it from both sides
    return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '');
  } else {
    // in some magic case when short representation of date is not present in the long one, just return the long one as a fallback, since it should contain the timezone's name
    return full;
  }
};

/**
 * Convert array of strings to conjunction (a, b, and c) or disjunction (a, b, or c)
 * @param strings
 * @param conjunction
 * @returns
 */
export const toSentenceList = (strings: string[], conjunction = true): string => {
  const joiner = conjunction ? ' and ' : ' or ';
  if (strings.length < 2) return strings.join();
  if (strings.length === 2) return strings.join(joiner);

  return strings
    .slice(0, strings.length - 1)
    .join(', ')
    .concat(`, ${joiner} ${strings[strings.length - 1]}`);
};

export const getInvoiceIdentifierCopy = (invoiceIdentifier: string | undefined | null, invoiceDate?: Date): string => {
  if (!invoiceIdentifier) {
    return '';
  }
  if (invoiceIdentifier === '0') {
    if (invoiceDate) {
      return formatDate(invoiceDate);
    }
    return 'Draft';
  }
  return `#${invoiceIdentifier}`;
};

//eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const removeKeys = (obj: any, key: string): any => {
  for (const prop in obj) {
    if (prop === key) delete obj[prop];
    else if (typeof obj[prop] === 'object') removeKeys(obj[prop], key);
  }
  return obj;
};
export const getBalanceTerminology = (
  integrationName: IntegrationSystemDisplayNameType | null,
  shortName = false,
): string => {
  switch (integrationName) {
    case integrationSystemDisplayName.CORNERSTONE:
    case integrationSystemDisplayName.IMPROMED:
      return shortName ? 'A/R' : 'Accounts Receivable';
    default:
      return 'Balance';
  }
};

export const convertObjectArrayToCSV = (objArray: string): string => {
  const array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  let str = '';

  // eslint-disable-next-line
  // @ts-ignore
  array.forEach((item) => {
    let line = '';
    for (const prop in item) {
      if (line != '') line += ',';
      let value = item[prop];

      if (value) {
        value = item[prop].replace(/\r?\n|\r/g, ' ');
      }

      line += value;
    }

    str += line + '\r\n';
  });

  return str;
};

export const formatPluralization = ({
  word,
  needsArticle = false,
  singularCriteria,
}: {
  word: string;
  needsArticle?: boolean;
  singularCriteria?: number | string;
}): string => {
  return singularCriteria === 1 ? (needsArticle ? `an ${word}` : word) : `${word}s`;
};

export const removeTimezoneFromIsoDateString = (isoDateString: string): Date => {
  return parseISO(isoDateString.slice(0, -1));
};

/**
 * Get the possessive form of a name
 * @param name - the name to make possessive
 * @returns the possessive form of the name (e.g. "John" -> "John's") or ("Chris" -> "Chris'")
 */
export const getPossesiveName = (name: string): string => {
  return name.endsWith('s') ? `${name}'` : `${name}'s`;
}
