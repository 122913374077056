import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomFindUniqueUserBoardFilterResponse } from 'shared/types/graphql';
import { StateSliceName } from 'state/StateSliceName';

interface BoardState {
  boardSettings?: CustomFindUniqueUserBoardFilterResponse;
  channelStatusPageNumber: number;
  currentClinicId?: string | null;
  clinicUserId?: string | null;
}

const initialState: BoardState = {
  channelStatusPageNumber: 1,
  currentClinicId: null,
  clinicUserId: null,
  boardSettings: {
    currentView: null,
    collapsedColumnsMap: {},
    selectedChannelStatusIdsMap: {},
    shouldHideEmptyColumns: false,
    dateRangeOption: null,
    specificDateRange: null,
  },
};

const boardSlice = createSlice({
  name: StateSliceName.Board,
  initialState,
  reducers: {
    resetBoardState: () => {
      return initialState;
    },
    updateBoardState: (state, action: PayloadAction<Partial<BoardState>>) => {
      Object.assign(state, action.payload);
    },
  },
});

export const { resetBoardState, updateBoardState } = boardSlice.actions;

export default boardSlice.reducer;
