import { Box, Collapse, Divider, Flex, useDisclosure } from '@televet/kibble-ui/build/chakra';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { Icon } from '@televet/kibble-ui/build/components/Icon';
import { ModalBody, ModalFooter } from '@televet/kibble-ui/build/components/Modal';
import { RadioGroup } from '@televet/kibble-ui/build/components/RadioGroup';
import { Select, SelectOptionProps } from '@televet/kibble-ui/build/components/Select';
import { Tag } from '@televet/kibble-ui/build/components/Tag';
import { Text } from '@televet/kibble-ui/build/components/Text';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  CancelReasonType,
  CareSubscriptionTrueUpType,
  ClinicPetParent,
  ManageMembershipRequestType,
  PaymentSchedule,
  useCreateCarePaymentMethodSetupMutation,
  useGetCareEnrollmentInfoQuery,
  useGetCareSavedPaymentMethodsQuery,
} from 'shared/types/graphql';
import { formatCurrency } from '../../../utils/currency';
import { getCardBrandIcon, getCardErrorMessage, getIsExpired } from '../../../utils/paymentMethod';
import { ManageMembershipStep, useManageMembershipContext } from '../context/ManageMembershipContext';
import { Spinner } from '@televet/kibble-ui/build/components/Spinner';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardElement, StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { DatePicker } from '@televet/kibble-ui/build/components/DatePicker';
import add from 'date-fns/add';
import { DESCRIPTION_MAX_CHAR_COUNT, getMonthlyAmountDue } from '../utils';
import { useToast } from '@televet/kibble-ui/build/components/Toast';
import { email as emailRegex } from 'shared/utils/validation';
import isPast from 'date-fns/isPast';
import { formatPluralization } from 'shared/utils';
import { Tooltip } from '@televet/kibble-ui/build/components/Tooltip';
import { Alert } from '@televet/kibble-ui/build/components/Alert';
import { useIntegrationsProvider } from 'shared/providers/IntegrationsProvider';
import { Textarea } from '@televet/kibble-ui/build/components/Textarea';
import { Card } from '@televet/kibble-ui/build/components/Card';
import { descriptionFieldLabel, descriptionFieldPlaceholder } from '../records/descriptionField.records';

interface PaymentStepProps {
  stripeCustomerId?: string | null;
  subscriptionPaymentMethodId?: string;
  nextBillingDate?: string;
  enrollmentId?: string;
  clinicPetParent?: Pick<
    ClinicPetParent,
    'id' | 'firstName' | 'lastName' | 'pimsId' | 'email' | 'organizationPetParentId'
  >;
  clinicPetParentEmail?: string | null;
  clinicPetParentId?: string;
  setValue: (name: string, value: string | number) => void;
}

const addCard = 'add-card';

export const PaymentStep = ({
  stripeCustomerId,
  subscriptionPaymentMethodId,
  nextBillingDate,
  enrollmentId,
  clinicPetParent,
  setValue,
}: PaymentStepProps): JSX.Element => {
  const toast = useToast();
  const { isOpen, onToggle } = useDisclosure();
  const stripe = useStripe();
  const elements = useElements();
  const cardInputRef = useRef<HTMLDivElement>(null);
  const [isValid, setIsValid] = useState(false);
  const [showCardField, setShowCardField] = useState<boolean>(false);
  const [isValidatingCard, setIsValidatingCard] = useState<boolean>(false);
  const [inputFieldTextError, setInputFieldTextError] = useState('');
  const { handleNext, handleBack, balanceDue, paymentData, setPaymentData, form, setForm, submitText, setSubmitText } =
    useManageMembershipContext();
  const isPayOverTime = paymentData.paymentSchedule === PaymentSchedule.OverTime;
  const { primaryIntegrationName } = useIntegrationsProvider();

  const { data, loading: isLoadingPaymentMethods } = useGetCareSavedPaymentMethodsQuery({
    variables: {
      where: {
        stripeCustomerId: { equals: stripeCustomerId },
      },
    },
    skip: !stripeCustomerId,
  });

  const { data: enrollmentData, loading: isLoadingEnrollmentData } = useGetCareEnrollmentInfoQuery({
    variables: {
      enrollmentId,
    },
    skip: !enrollmentId,
  });

  const validateText = (text: string): boolean => {
    if (text.length < 1) {
      setInputFieldTextError('Please add a description before submitting');
      return false;
    } else {
      setInputFieldTextError('');
      return true;
    }
  };

  const paymentTypeOptions = useMemo(
    () => [
      { value: CareSubscriptionTrueUpType.Internal, children: <Text>Credit or Debit</Text> },
      {
        value: CareSubscriptionTrueUpType.ClinicAlternativePayment,
        children: (
          <Flex alignItems="center">
            <Text as="p" mr={1}>
              Pay in Clinic
            </Text>
            <Tooltip label="Select when using other in-clinic payment methods or sending to collections.">
              <Flex>
                <Icon name="questionMarkCircleSolid" variant="subtle" size="md" />
              </Flex>
            </Tooltip>
          </Flex>
        ),
      },
    ],
    [],
  );

  const paymentMethodsOptions: SelectOptionProps[] = useMemo(() => {
    const paymentMethods = data?.findManyStripePaymentMethod || [];
    let options = [];

    options = paymentMethods
      .filter(
        (paymentMethod) =>
          !(paymentMethod.isExpired || getIsExpired(paymentMethod.expirationMonth, paymentMethod.expirationYear)),
      )
      .map((paymentMethod) => {
        const { isDefault, last4, expirationMonth, expirationYear, stripeId } = paymentMethod;

        return {
          label: (
            <Flex alignItems="center" justifyContent="space-between" w="100%">
              <Flex alignItems="center">
                {getCardBrandIcon(`${paymentMethod?.cardBrand}`)}
                <Text as="p" mx={2} variant="default">{`**** ${last4 || ''}`}</Text>
                {isDefault && <Tag label="Default" size="sm" />}
              </Flex>
              <Text variant="default">
                {expirationMonth}/{expirationYear}
              </Text>
            </Flex>
          ),
          value: stripeId,
          onSelect: (selected) => {
            setPaymentData({ ...paymentData, paymentMethodId: selected.value });
            if (showCardField) {
              setShowCardField(false);
            }
          },
        } as SelectOptionProps;
      });

    if (!!options.length) {
      options.push({
        label: (
          <Flex alignItems="center">
            <Icon name="plus" size="lg" />
            <Text as="p" mx={2}>
              Add Card
            </Text>
          </Flex>
        ),
        value: addCard,
        onSelect: (selected) => {
          setPaymentData({ ...paymentData, paymentMethodId: selected.value });
          setShowCardField(true);
        },
      });
    }

    return options;
  }, [data?.findManyStripePaymentMethod, paymentData, setPaymentData, showCardField]);

  const amountDue = useMemo(
    () => getMonthlyAmountDue({ plan: enrollmentData?.findUniqueCarePlanEnrollment?.plan, balanceDue }),
    [balanceDue, enrollmentData?.findUniqueCarePlanEnrollment?.plan],
  );

  const paymentScheduleOptions = useMemo(
    () => [
      { value: PaymentSchedule.Full, children: <Text>Pay in Full</Text> },
      {
        value: PaymentSchedule.OverTime,
        children: <Text>Pay Over Time</Text>,
      },
    ],
    [],
  );

  const handleCardElementFocus = (): void => {
    if (cardInputRef.current) {
      cardInputRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleCardElementReady = (element: StripeCardElement): void => {
    element.focus();
  };

  const handleCardElementChange = useCallback((event: StripeCardElementChangeEvent) => {
    const { complete, error } = event;
    setIsValid(complete && !error);
  }, []);

  useEffect(() => {
    if (!paymentData?.paymentMethodId && subscriptionPaymentMethodId && paymentMethodsOptions.length) {
      // Preselect the subscription payment method
      let paymentMethodId = subscriptionPaymentMethodId;
      const paymentMethod = data?.findManyStripePaymentMethod?.find(
        (paymentMethod) => paymentMethod.stripeId === subscriptionPaymentMethodId,
      );

      // If payment method is expired or doesn't exist anymore, preselect the first option
      if (
        (paymentMethod &&
          (paymentMethod.isExpired || getIsExpired(paymentMethod.expirationMonth, paymentMethod.expirationYear))) ||
        !paymentMethod
      ) {
        paymentMethodId = paymentMethodsOptions[0].value || '';
      }

      setPaymentData({ ...paymentData, paymentMethodId });
    }
  }, [
    data?.findManyStripePaymentMethod,
    paymentData,
    paymentMethodsOptions,
    paymentMethodsOptions.length,
    setPaymentData,
    subscriptionPaymentMethodId,
  ]);

  useEffect(() => {
    if (!isValid && paymentData?.paymentMethodId && paymentData.paymentMethodId !== addCard) {
      setIsValid(true);
    }
  }, [isValid, paymentData?.paymentMethodId]);

  const [createSetupIntentSecret] = useCreateCarePaymentMethodSetupMutation();

  const updateCancelReason = (): void => {
    if (
      form.cancelReason === CancelReasonType.ClinicInitiatedUncollectible &&
      paymentData.paymentType === CareSubscriptionTrueUpType.Internal
    ) {
      setValue('cancelReason', CancelReasonType.ClinicInitiatedOther);
      setForm({ ...form, cancelReason: CancelReasonType.ClinicInitiatedOther });
    }
  };

  const handleSubmit = async (): Promise<void> => {
    setIsValidatingCard(true);

    if (paymentData?.paymentMethodId === addCard) {
      const cardElement = elements?.getElement(CardElement);

      if (!stripe || !cardElement) {
        toast({
          title: 'Whoops!',
          description: "Couldn't connect to Stripe, please try again.",
          status: 'error',
          position: 'top',
        });
        setIsValidatingCard(false);
        return;
      }

      try {
        const res = await createSetupIntentSecret({
          variables: {
            data: {
              clinicPetParentId: clinicPetParent?.id,
            },
          },
        });
        const setupIntentSecret = res.data?.createCarePaymentMethodSetup.setupIntentSecret;

        if (setupIntentSecret) {
          const { error, setupIntent } = await stripe.confirmCardSetup(setupIntentSecret, {
            payment_method: {
              card: cardElement,
              billing_details: {
                email:
                  clinicPetParent?.email && emailRegex.test(clinicPetParent.email)
                    ? clinicPetParent.email.match(emailRegex)?.[0]
                    : undefined,
              },
            },
          });

          if (error) {
            if (error?.type === 'card_error') {
              // More on Stripe errors: (https://stripe.com/docs/api/errors?lang=node)
              toast({
                title: 'Whoops!',
                description: `There was a problem with that payment method: ${getCardErrorMessage(
                  error.decline_code || error.code,
                )}`,
                status: 'error',
                position: 'top',
              });
            } else {
              throw error;
            }
          } else {
            setPaymentData({ ...paymentData, paymentMethodId: setupIntent?.payment_method });
            updateCancelReason();
            handleNext(ManageMembershipStep.CancelMembership);
          }
        }
      } catch (error) {
        toast({
          title: 'Whoops!',
          description: "Couldn't save payment method, try again.",
          status: 'error',
          position: 'top',
        });
      } finally {
        setIsValidatingCard(false);
      }
    } else {
      updateCancelReason();
      handleNext(ManageMembershipStep.CancelMembership);
    }
  };

  const minBillingDate = useMemo(() => new Date(), []);
  const maxBillingDate = useMemo(() => add(new Date(), { days: 30 }), []);

  useEffect(() => {
    // Sets the subscription billing date as default
    if (!paymentData?.billingDate && nextBillingDate) {
      const billingDate = new Date(nextBillingDate);
      const defaultDate = isPast(billingDate) ? new Date() : billingDate;
      setPaymentData({ ...paymentData, billingDate: defaultDate });
    }
  }, [nextBillingDate, paymentData, setPaymentData]);

  const shouldDisableSubmitButton =
    form.cancelReason === CancelReasonType.ClinicInitiatedUncollectible &&
    paymentData.paymentType === CareSubscriptionTrueUpType.Internal &&
    (submitText.length === 0 || submitText.length > DESCRIPTION_MAX_CHAR_COUNT);

  const updateSubmitText = useCallback(
    (submitText: string): void => {
      setSubmitText(submitText);
    },
    [setSubmitText],
  );

  return (
    <>
      <ModalBody py={5}>
        {isLoadingEnrollmentData ? (
          <Flex alignItems="center" justifyContent="center">
            <Spinner />
          </Flex>
        ) : (
          <>
            <Box>
              <RadioGroup
                direction="row"
                spacing={4}
                radios={paymentTypeOptions}
                label="Payment Type"
                labelStyle={{ fontWeight: 'semibold' }}
                formControlStyle={{ mb: 4 }}
                onChange={(val): void => {
                  setPaymentData({ ...paymentData, paymentType: val as CareSubscriptionTrueUpType });
                }}
                value={paymentData.paymentType}
              />
              {paymentData.paymentType === CareSubscriptionTrueUpType.Internal && (
                <>
                  {form.cancelReason === CancelReasonType.ClinicInitiatedUncollectible && (
                    <>
                      <Alert
                        title={`Selecting this option will change cancellation reason to "Clinic Initiated - Other".`}
                        description={`Choose "Pay in Clinic" to keep "Clinic Initiated - Uncollectible" as the cancellation reason.`}
                        status="info"
                        hideCloseButton
                        mb={4}
                      />
                      <Textarea
                        size="md"
                        label={descriptionFieldLabel[ManageMembershipRequestType.Cancel]}
                        labelStyle={{ fontWeight: 'semibold' }}
                        helperText={`${submitText.length}/${DESCRIPTION_MAX_CHAR_COUNT}`}
                        isInvalid={!!inputFieldTextError || submitText.length > DESCRIPTION_MAX_CHAR_COUNT}
                        isRequired
                        errorText={
                          inputFieldTextError ||
                          `Please limit your description to ${DESCRIPTION_MAX_CHAR_COUNT} characters.`
                        }
                        placeholder={descriptionFieldPlaceholder[ManageMembershipRequestType.Cancel]}
                        onBlur={(e): void => {
                          validateText(e.target.value);
                        }}
                        onChange={(e): void => {
                          updateSubmitText(e.target.value);
                          validateText(e.target.value);
                        }}
                        formControlStyle={{ mb: 4 }}
                        rows={3}
                      />
                    </>
                  )}
                  <Select
                    options={paymentMethodsOptions}
                    defaultValue={subscriptionPaymentMethodId}
                    label="Payment Method"
                    labelStyle={{ fontWeight: 'semibold' }}
                    formControlStyle={{ mb: 4 }}
                    listProps={{ isLoading: isLoadingPaymentMethods }}
                    value={paymentData?.paymentMethodId || ''}
                  />
                  {!!stripe && !!elements && showCardField && (
                    <Card data-testid="stripe-payment-box" px={5} py={3} mb={4} borderRadius="base" boxShadow="lg">
                      <Box ref={cardInputRef}>
                        <CardElement
                          onReady={handleCardElementReady}
                          onChange={handleCardElementChange}
                          onFocus={handleCardElementFocus}
                          options={{
                            style: {
                              base: {
                                color: 'text.default',
                                '::placeholder': {
                                  color: 'text.default',
                                },
                                backgroundColor: 'transparent',
                                iconColor: 'text.default',
                              },
                            },
                          }}
                        />
                      </Box>
                    </Card>
                  )}
                  {balanceDue && balanceDue > amountDue.monthlyAmountDue && (
                    <RadioGroup
                      direction="row"
                      spacing={4}
                      radios={paymentScheduleOptions}
                      label="Payment Schedule"
                      labelStyle={{ fontWeight: 'semibold' }}
                      formControlStyle={{ mb: 4 }}
                      onChange={(val): void => {
                        setPaymentData({ ...paymentData, paymentSchedule: val as PaymentSchedule });
                        if (isOpen) {
                          onToggle();
                        }
                      }}
                      value={paymentData.paymentSchedule}
                    />
                  )}
                </>
              )}
              {paymentData.paymentSchedule === PaymentSchedule.OverTime && (
                <Box mb={4}>
                  <Text as="p" fontWeight="semibold" mb={2}>
                    Billing Start Date
                  </Text>
                  <DatePicker
                    startDate={paymentData.billingDate || new Date()}
                    minDate={minBillingDate}
                    maxDate={maxBillingDate}
                    onDateChange={({ startDate }): void => setPaymentData({ ...paymentData, billingDate: startDate })}
                  />
                </Box>
              )}
            </Box>
            <Divider my={2} />
            <Box>
              <Flex alignItems="center" justifyContent="space-between">
                <Text fontWeight="semibold" size="lg">
                  {isPayOverTime ? 'Monthly' : ''} Amount Due:
                </Text>
                <Flex alignItems="center">
                  {isPayOverTime && (
                    <Button
                      size="xs"
                      iconName={isOpen ? 'chevronUp' : 'chevronDown'}
                      iconPosition="right"
                      variant="ghostNeutral"
                      onClick={onToggle}
                      mr={1}
                    >
                      Show {isOpen ? 'Less' : 'More'}
                    </Button>
                  )}
                  <Text fontWeight="semibold" size="lg">
                    {formatCurrency(isPayOverTime ? amountDue.monthlyAmountDue : amountDue.total, true)}
                  </Text>
                </Flex>
              </Flex>
              {isPayOverTime && (
                <Collapse in={isOpen}>
                  <Box px={1} mt={1}>
                    <Flex alignItems="center" justifyContent="space-between">
                      <Text variant="subtle" size="sm">
                        {amountDue.numberOfPayments}{' '}
                        {formatPluralization({ word: 'payment', singularCriteria: amountDue.numberOfPayments })} of{' '}
                        {formatCurrency(amountDue.monthlyAmountDue)}
                      </Text>
                      <Text variant="subtle" size="sm">
                        {formatCurrency(amountDue.monthlyAmountDue * amountDue.numberOfPayments, true)}
                      </Text>
                    </Flex>
                    {amountDue.remainder > 0 && (
                      <Flex alignItems="center" justifyContent="space-between">
                        <Text variant="subtle" size="sm">
                          1 payment of {formatCurrency(amountDue.remainder)}
                        </Text>
                        <Text variant="subtle" size="sm">
                          {formatCurrency(amountDue.remainder, true)}
                        </Text>
                      </Flex>
                    )}
                    <Divider my={1} />
                    <Flex alignItems="center" justifyContent="space-between">
                      <Text fontWeight="semibold" size="sm">
                        Total Amount Due
                      </Text>
                      <Text fontWeight="semibold" size="sm">
                        {formatCurrency(amountDue.total, true)}
                      </Text>
                    </Flex>
                  </Box>
                </Collapse>
              )}
            </Box>
            {paymentData.paymentType === CareSubscriptionTrueUpType.ClinicAlternativePayment && (
              <Alert
                status="info"
                title="No payment will be collected by Otto when processing this membership cancellation."
                description={`Before proceeding, please add the Amount Due (${formatCurrency(
                  amountDue.total,
                  true,
                )}) as an account balance for ${
                  clinicPetParent?.firstName && clinicPetParent.lastName
                    ? `${clinicPetParent.firstName} ${clinicPetParent.lastName}`
                    : 'this client'
                } in ${primaryIntegrationName}.`}
                mt={4}
                hideCloseButton
              />
            )}
          </>
        )}
      </ModalBody>
      <ModalFooter justifyContent="space-between">
        <Button variant="tertiary" onClick={handleBack} isDisabled={isValidatingCard}>
          Back
        </Button>
        <Flex>
          <Button
            variant="ghost"
            iconName="questionMarkCircle"
            mr={3}
            isDisabled={isValidatingCard}
            onClick={(): void => handleNext(ManageMembershipStep.GetHelp)}
          >
            Get Help
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit}
            isLoading={isValidatingCard}
            isDisabled={!isValid || shouldDisableSubmitButton}
          >
            Next
          </Button>
        </Flex>
      </ModalFooter>
    </>
  );
};
