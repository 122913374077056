import { Box, Flex, HStack, VStack } from '@televet/kibble-ui/build/chakra';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { Heading } from '@televet/kibble-ui/build/components/Heading';
import { Tooltip } from '@televet/kibble-ui/build/components/Tooltip';
import { NotificationCounter } from '@televet/kibble-ui/build/components/NotificationCounter';
import { Icon } from '@televet/kibble-ui/build/components/Icon';
import format from 'date-fns/format';
import isToday from 'date-fns/isToday';
import React, { useMemo } from 'react';
import useFeatureFlag from 'shared/hooks/useFeatureFlag';
import { FeatureFlagName } from 'shared/enums';
import { AvatarGroup, PetParentAvatar } from 'shared/components/Avatars';
import { ChannelListChannelPetFragment, ChannelViewClinicPetParentFragment } from 'shared/types/graphql';
import { getClinicPetParentDisplayName } from 'shared/utils';

interface CardHeaderProps {
  channelPets: ChannelListChannelPetFragment[];
  channelPetParents: ChannelViewClinicPetParentFragment[];
  channelUpdatedAtDate: string;
  lastMessageCreatedAtDate: string;
  hasUnreadMessages: boolean;
  isHoveringCard: boolean;
  isChannelPinned: boolean;
}

const CardHeader = ({
  channelPets,
  channelPetParents,
  lastMessageCreatedAtDate,
  channelUpdatedAtDate,
  hasUnreadMessages,
  isHoveringCard,
  isChannelPinned,
}: CardHeaderProps): JSX.Element => {
  const { isFeatureEnabled } = useFeatureFlag();

  const petParentDisplayNames = useMemo(() => {
    return channelPetParents
      .map(({ creationSource, firstName, lastName, phoneNumbers }) =>
        getClinicPetParentDisplayName({
          firstName,
          lastName,
          creationSource,
          phoneNumbers,
        }),
      )
      .join(', ');
  }, [channelPetParents]);

  const sortByLastMessageEnabled = useMemo(
    () => isFeatureEnabled(FeatureFlagName.SortConversationByLastMessage) && lastMessageCreatedAtDate,
    [lastMessageCreatedAtDate, isFeatureEnabled],
  );

  return (
    <HStack className="ChannelListItem__Header" justify="space-between" align="flex-start">
      <HStack className="ChannelListItem__Header--Left" cursor="pointer">
        <AvatarGroup petParentNames={petParentDisplayNames} firstPetParentId={channelPetParents[0]?.id} max={1}>
          {channelPetParents.map((petParent, index) => (
            <PetParentAvatar
              className="ChanneListItem__Header-Avatar"
              key={petParent.id}
              clinicPetParent={petParent}
              isGrouped
              index={index}
            />
          ))}
        </AvatarGroup>
        <VStack className="ChannelListItem__HeaderNames" justify="center" align="left" spacing="0">
          <Heading className="ChannelListItem__HeaderNames--PetParent" size="sm" isTruncated>
            {petParentDisplayNames}
          </Heading>
          {channelPets.length && (
            <Text className="ChannelListItem__HeaderNames--Pet" as="p" size="sm" variant="subtle" isTruncated>
              {channelPets.map(({ name }) => name).join(', ')}
            </Text>
          )}
        </VStack>
      </HStack>
      <HStack
        className="ChannelListItem__Header--Right"
        visibility={isHoveringCard ? 'hidden' : 'visible'}
        align="center"
        spacing={1}
      >
        <TimeStamp date={new Date(sortByLastMessageEnabled ? lastMessageCreatedAtDate : channelUpdatedAtDate)} />
        {hasUnreadMessages && (
          <Tooltip label="This conversation has unread messages">
            <Box
              as="span"
              className="ChannelListItem__HeaderNotificationCounter"
              data-testid="channel-list-item-unread-indicator"
            >
              <NotificationCounter size="xs" />
            </Box>
          </Tooltip>
        )}
        {isChannelPinned && (
          <Flex data-testid="conversation-pinned-icon">
            <Icon size="md" variant="info" name="pin" />
          </Flex>
        )}
      </HStack>
    </HStack>
  );
};

interface TimeStampProps {
  date: Date;
}

const TimeStamp = ({ date }: TimeStampProps): JSX.Element => {
  const stamp = format(date, isToday(date) ? 'h:mma' : 'M/d/yy');

  return (
    <Text className="ChannelListItem__HeaderTimestamp" variant="subtle" size="xs" textTransform="lowercase">
      <Tooltip titleCaseLabel={false} label={`Last updated ${format(date || '', "cccc',' PP 'at' p")}`}>
        {stamp}
      </Tooltip>
    </Text>
  );
};

export default CardHeader;
