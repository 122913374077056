import React, { useCallback, useMemo, useState } from 'react';
import {
  Flex,
  Heading,
  Box,
  Text,
  Switch,
  Link,
  Divider,
  Button,
  Icon,
  useDisclosure,
  Table,
  Alert,
  useToast,
} from '@televet/kibble-ui';
import { Link as ReactRouterLink } from 'react-router-dom';
import { RouteBasePaths, SettingsRoutes } from 'routes';
import {
  ExclusionRuleSelectionFragment,
  useGetClinicTimezoneNameQuery,
  useGetDirectBookingAppointmentTypesQuery,
  useGetDirectBookingExclusionsQuery,
  useGetDirectBookingProvidersQuery,
} from 'shared/types/graphql';
import ExclusionsModal from './ExclusionsModal';
import ExclusionTableRow from './ExclusionTableRow';
import useClinicUser from 'shared/hooks/useClinicUser';
import { GraphQLFetchPolicies } from 'shared/enums';
import { useIntegrationsProvider } from 'shared/providers/IntegrationsProvider';
import { UpdateDirectBookingSettingsReturnType } from './UpdateDirectBookingSettingsReturnType';
interface ExclusionaryTimeSlotsProps {
  clinicId?: string;
  directBookingSettingsId?: string;
  exclusionsEnabled: boolean;
  refetchSettings: () => void;
  updateDirectBookingSettings: UpdateDirectBookingSettingsReturnType;
}

const ExclusionaryTimeSlotSection = ({
  clinicId,
  directBookingSettingsId,
  exclusionsEnabled,
  refetchSettings,
  updateDirectBookingSettings,
}: ExclusionaryTimeSlotsProps): JSX.Element => {
  const toast = useToast();
  const [selectedExclusion, setSelectedExclusion] = useState<ExclusionRuleSelectionFragment | undefined>();
  const exclusionsModalDisclosure = useDisclosure();
  const { hasAdminRoleAtCurrentClinic } = useClinicUser();
  const { primaryIntegrationName } = useIntegrationsProvider();

  const { data: timezoneNameData } = useGetClinicTimezoneNameQuery({
    variables: {
      clinicId: clinicId || '',
    },
    skip: !clinicId,
    fetchPolicy: GraphQLFetchPolicies.CacheAndNetwork,
  });

  const clinicTimezoneName = useMemo(
    () => timezoneNameData?.findUniqueClinic?.timezoneName || '',
    [timezoneNameData?.findUniqueClinic?.timezoneName],
  );

  const { data: exclusionsData } = useGetDirectBookingExclusionsQuery({
    variables: {
      clinicId: clinicId || '',
    },
    skip: !clinicId,
  });

  const { data: appointmentTypesData } = useGetDirectBookingAppointmentTypesQuery({
    variables: { clinicId: clinicId || '' },
    skip: !clinicId,
  });

  const { data: providersData } = useGetDirectBookingProvidersQuery({
    variables: { clinicId: clinicId || '' },
    skip: !clinicId,
  });

  const exclusionRules = useMemo(() => exclusionsData?.findManyDirectBookingExclusionRule || [], [exclusionsData]);

  const handleExclusionsToggled = useCallback(async () => {
    if (!hasAdminRoleAtCurrentClinic) return;

    try {
      await updateDirectBookingSettings({
        variables: {
          where: { id: directBookingSettingsId || '' },
          update: { exclusionRulesEnabled: !exclusionsEnabled },
          create: {
            exclusionRulesEnabled: !exclusionsEnabled,
            clinic: {
              connect: { id: clinicId },
            },
          },
        },
      });

      refetchSettings();
    } catch (e) {
      console.error('Error enabling exclusions', e);
      toast({ title: 'Error enabling Exclusions. Please refresh the page and try again.', status: 'error' });
    }
  }, [
    hasAdminRoleAtCurrentClinic,
    updateDirectBookingSettings,
    refetchSettings,
    directBookingSettingsId,
    exclusionsEnabled,
    clinicId,
    toast,
  ]);

  const handleAddNewExclusion = useCallback(() => {
    setSelectedExclusion(undefined);
    exclusionsModalDisclosure.onOpen();
  }, [exclusionsModalDisclosure]);

  const handleEditExclusion = useCallback(
    (exclusionRule: ExclusionRuleSelectionFragment) => {
      setSelectedExclusion(exclusionRule);
      exclusionsModalDisclosure.onOpen();
    },
    [exclusionsModalDisclosure],
  );

  return (
    <Flex mt={10} direction="column" w="100%">
      <Heading size="md">Exclusionary Time Slots</Heading>
      <Box mt={7} mb={5}>
        <Text size="sm" variant="subtle">
          Control time windows for Direct Booking.
        </Text>
      </Box>
      <Box maxW="550px" mb={5}>
        <Switch
          size="sm"
          isFieldInline
          isChecked={exclusionsEnabled}
          label="Exclusionary Time Slots"
          labelStyle={{ fontSize: 'sm', fontWeight: 'semibold' }}
          onChange={handleExclusionsToggled}
          isDisabled={!hasAdminRoleAtCurrentClinic}
        />
        <Text as="p" size="sm" variant="subtle">
          {`When switched on, you can exclude specific time windows from your `}
          {
            <Link
              size="sm"
              fontWeight="bold"
              as={ReactRouterLink}
              to={`${RouteBasePaths.Settings}${SettingsRoutes.ClinicHours}`}
            >
              Clinic Hours
            </Link>
          }
          {` so they aren't shown as available slots in the Direct Booking options displayed to clients, 
            even if those time slots are available in your${
              primaryIntegrationName ? ` ${primaryIntegrationName}` : ''
            } appointment calendar. 
            These exclusions can be configured for specific Appointment Types and Providers.`}
        </Text>
      </Box>
      <Divider w="100%" />
      {!exclusionsEnabled && hasAdminRoleAtCurrentClinic && (
        <Alert
          title="Exclusionary Time Slots are disabled"
          status="warning"
          hideCloseButton
          buttonProps={{ buttonText: 'Enable Exclusionary Time Slots', onClick: handleExclusionsToggled }}
          my={4}
        />
      )}
      <Table mt={5} maxW="600px">
        {exclusionRules.map((rule, index) => (
          <ExclusionTableRow
            key={rule.id}
            rowIndex={index}
            exclusionRule={rule}
            handleEditExclusion={handleEditExclusion}
            providerCount={providersData?.findManyClinicEmployee?.length || -1}
            appointmentTypeCount={appointmentTypesData?.findManyAppointmentType?.length || -1}
            isClinicAdmin={hasAdminRoleAtCurrentClinic}
            clinicTimezoneName={clinicTimezoneName}
          />
        ))}
      </Table>
      {hasAdminRoleAtCurrentClinic && (
        <Box py={6}>
          <Button size="sm" variant="ghost" leftIcon={<Icon size="sm" name="plus" />} onClick={handleAddNewExclusion}>
            Add New Exclusion
          </Button>
        </Box>
      )}
      {exclusionsModalDisclosure.isOpen && (
        <ExclusionsModal
          isOpen={exclusionsModalDisclosure.isOpen}
          onClose={exclusionsModalDisclosure.onClose}
          selectedExclusion={selectedExclusion}
          clinicId={clinicId || ''}
          clinicTimezone={clinicTimezoneName}
        />
      )}
    </Flex>
  );
};

export default ExclusionaryTimeSlotSection;
