import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { TextInput } from '@televet/kibble-ui/build/components/TextInput';
import { Textarea } from '@televet/kibble-ui/build/components/Textarea';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { Icon } from '@televet/kibble-ui/build/components/Icon';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { Heading } from '@televet/kibble-ui/build/components/Heading';
import { Tooltip } from '@televet/kibble-ui/build/components/Tooltip';
import { Alert } from '@televet/kibble-ui/build/components/Alert';
import { Link } from '@televet/kibble-ui/build/components/Link';
import { Menu, MenuItemProps } from '@televet/kibble-ui/build/components/Menu';
import { useToast } from '@televet/kibble-ui/build/components/Toast';
import { Divider, Flex, Box, useDisclosure, Stack } from '@televet/kibble-ui/build/chakra';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalFooter,
} from '@televet/kibble-ui/build/components/Modal';
import { useForm } from 'react-hook-form';
import InputLabel from 'shared/components/InputLabel';
import Provider from './Provider';
import {
  AppointmentTypeFragment,
  ClinicEmployee,
  DirectBookingAppointmentTypeClientType,
  ProviderFragment,
  useUpdateAppointmentTypeProviderRoomMapsMutation,
  useUpdateOneAppointmentTypeMutation,
} from 'shared/types/graphql';
import differenceBy from 'lodash-es/differenceBy';
import isEqual from 'lodash-es/isEqual';
import * as Sentry from '@sentry/react';
import { useAppointmentSettings } from '../../../hooks/useAppointmentSettings';
import { useProviderAppointmentTypeRoomMap } from '../../../hooks/useProviderAppointmentTypeRoomMap';
import UnsavedChangesModal from './UnsavedChangesModal';
import { integrationSystemDisplayName, useIntegrationsProvider } from 'shared/providers/IntegrationsProvider';
import HasFeature from 'shared/components/HasFeature';
import { FeatureFlagName } from 'shared/enums/FeatureFlagName';
import { RadioGroup } from '@televet/kibble-ui/build/components/RadioGroup';

// eslint-disable-next-line
const CopySubMenu = forwardRef<HTMLDivElement, 'div'>((props, ref) => (
  <Flex gap={3} {...props} m={0} p={0}>
    <Icon name="twoSquares" size="md" />
    <Flex justify="space-between" flex={1} align="center">
      <Text size="sm">Copy Other Appointment Type</Text>
      <Icon name="chevronRight" size="sm" />
    </Flex>
  </Flex>
));
CopySubMenu.displayName = 'CopySubMenu';

interface EditAppointmentTypeModalProps {
  isOpen: boolean;
  onClose: () => void;
  appointmentType?: AppointmentTypeFragment;
}

interface IAppointmentTypeFormValues {
  name: string;
  displayName: string;
  defaultDurationInMinutes: string;
  defaultSoonestAvailableBookingInHours: string;
  description: string;
  delayedStartTime: string;
}

function titleCase(str: string): string {
  return str.replace(/([A-Z])/g, ' $1').trim();
}

const clientTypeOptions = [
  DirectBookingAppointmentTypeClientType.ExistingClients,
  DirectBookingAppointmentTypeClientType.NewClients,
  DirectBookingAppointmentTypeClientType.Both,
].map((value) => ({
  value,
  children: <Text>{titleCase(value)}</Text>,
}));

const EditAppointmentTypeModal = ({ isOpen, onClose, appointmentType }: EditAppointmentTypeModalProps): JSX.Element => {
  const { handleSubmit, register, reset, formState } = useForm<IAppointmentTypeFormValues>({
    reValidateMode: 'onSubmit',
  });
  const [initialProviders, setInitialProviders] = useState<ProviderFragment[]>([]);
  const [selectedProviders, setSelectedProviders] = useState<ProviderFragment[]>([]);
  const [providerRoomMaps, setProviderRoomMaps] = useState<Record<string, string[]>>({});
  const [clientType, setClientType] = useState<DirectBookingAppointmentTypeClientType>(
    DirectBookingAppointmentTypeClientType.Both,
  );
  const [isSaving, setIsSaving] = useState(false);
  const [attemptedSave, setAttemptedSave] = useState(false);

  const {
    isOpen: isUnsavedChangesOpen,
    onOpen: onUnsavedChangesOpen,
    onClose: onUnsavedChangesClose,
  } = useDisclosure();

  const toast = useToast();
  const { apptTypes, providers, rooms } = useAppointmentSettings();
  const {
    providerApptTypeRoomMaps,
    isSupported: isRoomMappingSupported,
    isRequired: isRoomMappingRequired,
  } = useProviderAppointmentTypeRoomMap();

  const [updateAppointmentType] = useUpdateOneAppointmentTypeMutation({
    refetchQueries: ['getAppointmentSettings'],
  });

  const [updateAppointmentTypeProviderRoomMaps] = useUpdateAppointmentTypeProviderRoomMapsMutation({
    refetchQueries: ['getProviderAppointmentTypeRoomMap'],
  });
  const { primaryIntegrationName } = useIntegrationsProvider();

  const isPimless = !primaryIntegrationName;
  const isAvimark = primaryIntegrationName === integrationSystemDisplayName.AVIMARK;
  const isCornerstone = primaryIntegrationName === integrationSystemDisplayName.CORNERSTONE;

  const handleClose = (): void => {
    setAttemptedSave(false);
    onClose();
  };

  const checkChanges = (): void => {
    const providersChanged = !isEqual(initialProviders, selectedProviders);
    const roomMapsChanged = !isEqual(initialProviderRoomMaps, providerRoomMaps);
    if (formState.dirty || providersChanged || roomMapsChanged) {
      onUnsavedChangesOpen();
    } else {
      handleClose();
    }
  };

  const initialProviderRoomMaps = useMemo(() => {
    const record: Record<string, string[]> = {};
    const apptTypeMaps = providerApptTypeRoomMaps.filter((map) => map.appoinmentTypeId === appointmentType?.id);
    apptTypeMaps.forEach((map) => {
      if (record[map.clinicEmployeeId]) {
        record[map.clinicEmployeeId].push(map.roomId);
      } else {
        record[map.clinicEmployeeId] = [map.roomId];
      }
    });
    return record;
  }, [appointmentType?.id, providerApptTypeRoomMaps]);

  useEffect(() => {
    if (appointmentType) {
      // initialize form
      reset({
        displayName: appointmentType.displayName || '',
        description: appointmentType.description || '',
        defaultDurationInMinutes: appointmentType?.defaultDurationInMinutes.toString(),
        defaultSoonestAvailableBookingInHours: appointmentType?.defaultSoonestAvailableBookingInHours?.toString(),
        delayedStartTime: appointmentType?.delayedStartTime?.toString(),
      });
      setClientType(
        appointmentType.directBookingAppointmentTypeSetting?.clientType || DirectBookingAppointmentTypeClientType.Both,
      );
      const providers = appointmentType.clinicEmployeeAppointmentTypeSettings.map((mapping) => mapping.clinicEmployee);
      setInitialProviders(providers);
      setSelectedProviders(providers);
      setProviderRoomMaps(initialProviderRoomMaps);
    }
  }, [appointmentType, initialProviderRoomMaps, reset]);

  const onSubmit = async (data: IAppointmentTypeFormValues): Promise<void> => {
    setIsSaving(true);
    setAttemptedSave(true);
    const providersToAdd = differenceBy(selectedProviders, initialProviders, 'id');
    const providersToRemove = differenceBy(initialProviders, selectedProviders, 'id');

    if (data.delayedStartTime) {
      let delayedStartTimeError = false;
      const delayedStartTime = Number(data.delayedStartTime);

      if (delayedStartTime !== 0) {
        if (delayedStartTime < 5) {
          delayedStartTimeError = true;
        } else if (
          isCornerstone &&
          ((delayedStartTime % 15 !== 0 && delayedStartTime % 10 !== 0) || delayedStartTime > 50)
        ) {
          delayedStartTimeError = true;
        } else if ((isPimless || isAvimark) && (delayedStartTime % 5 !== 0 || delayedStartTime > 55)) {
          delayedStartTimeError = true;
        }

        if (delayedStartTimeError) {
          const description = isCornerstone
            ? 'The duration must be a multiple of 10 or 15 minutes and no longer than 50 minutes.'
            : 'The duration must be a multiple of 5 minutes and no longer than 55 minutes.';

          toast({
            title: 'Invalid delayed start time',
            description,
            status: 'error',
          });
          setIsSaving(false);
          return;
        }
      }
    }

    if (isRoomMappingRequired) {
      const mapsAreInvalid = selectedProviders.some((provider) => !providerRoomMaps[provider.id]?.length);
      if (mapsAreInvalid) {
        setIsSaving(false);
        return;
      }
    }

    try {
      await updateAppointmentType({
        variables: {
          where: {
            id: appointmentType?.id,
          },
          data: {
            displayName: data.displayName?.trim() || '',
            defaultDurationInMinutes: parseInt(data.defaultDurationInMinutes, 10) || undefined,
            defaultSoonestAvailableBookingInHours: parseInt(data.defaultSoonestAvailableBookingInHours, 10),
            description: data.description || '',
            delayedStartTime:
              data.delayedStartTime === '0' || !!data.delayedStartTime ? parseInt(data.delayedStartTime, 10) : null,
            directBookingAppointmentTypeSetting: {
              upsert: {
                create: {
                  clientType,
                },
                update: {
                  clientType,
                },
              },
            },
            clinicEmployeeAppointmentTypeSettings: {
              create: providersToAdd.map((provider) => ({
                clinicEmployee: { connect: { id: provider.id } },
              })),
              deleteMany: providersToRemove.map((provider) => ({
                clinicEmployeeId: { equals: provider.id },
                appointmentTypeId: { equals: appointmentType?.id },
              })),
            },
          },
        },
      });

      if (isRoomMappingSupported) {
        await updateAppointmentTypeProviderRoomMaps({
          variables: {
            data: {
              appointmentTypeId: appointmentType?.id || '',
              providerRoomMaps: JSON.stringify(providerRoomMaps),
            },
          },
        });
      }

      toast({
        title: 'Appointment type successfully updated.',
      });
      handleClose();
    } catch (e) {
      Sentry.captureException(e);
      toast({
        title: 'Failed to update appointment type.',
        description: 'Please try again. If the problem persists, please contact support.',
        status: 'error',
      });
    }
    setIsSaving(false);
  };

  const copyMenuItems = useMemo(() => {
    if (!apptTypes?.length) return [];

    const filtered = apptTypes.filter(
      (type) => type.clinicEmployeeAppointmentTypeSettings.length && type.id !== appointmentType?.id,
    );

    return filtered?.map((type) => {
      return {
        label: type.displayName || type.name,
        value: type.id,
        onSelect: (): void => {
          const newProviders = type.clinicEmployeeAppointmentTypeSettings.map((setting) => setting.clinicEmployee);
          setSelectedProviders(newProviders);

          if (isRoomMappingSupported) {
            const record: Record<string, string[]> = {};
            const apptTypeMaps = providerApptTypeRoomMaps.filter((map) => map.appoinmentTypeId === type.id);
            apptTypeMaps.forEach((map) => {
              if (record[map.clinicEmployeeId]) {
                record[map.clinicEmployeeId].push(map.roomId);
              } else {
                record[map.clinicEmployeeId] = [map.roomId];
              }
            });

            setProviderRoomMaps(record);
          }
        },
      };
    });
  }, [appointmentType?.id, apptTypes, isRoomMappingSupported, providerApptTypeRoomMaps]);

  const providerMenuItems = useMemo(() => {
    return providers.map((provider) => {
      return {
        label: `${provider.firstName} ${provider.lastName}${provider.pimsId ? ` - ID: ${provider.pimsId}` : ''}`,
        value: provider.id,
        isSelected: selectedProviders.some((p) => p.id === provider.id),
      };
    });
  }, [providers, selectedProviders]);

  const onProviderSelect = (_selectedItem: MenuItemProps, selectedItems?: (MenuItemProps | undefined)[]): void => {
    if (selectedItems?.length) {
      const selectedProviderIds = selectedItems.map((item) => item?.value || '');
      const newProviders = providers.filter((provider) => selectedProviderIds.includes(provider.id));
      setSelectedProviders(newProviders);
    } else {
      setSelectedProviders([]);
    }
  };

  const onProviderSelectAll = (selectedItems: MenuItemProps[]): void => {
    let newProviders: React.SetStateAction<Pick<ClinicEmployee, 'id' | 'firstName' | 'lastName'>[]> = [];
    if (selectedItems.length) {
      const selectedProviderIds = selectedItems.map((item) => item?.value || '');
      newProviders = providers.filter((provider) => selectedProviderIds.includes(provider.id));
    }
    setSelectedProviders(newProviders);
  };

  const removeSingleProvider = (id: string): void => {
    updateRoomsForProvider(id, []);
    setSelectedProviders((providers) => providers.filter((e) => e.id !== id));
  };

  const updateRoomsForProvider = (providerId: string, roomIds: string[]): void => {
    const newMaps = Object.assign({}, providerRoomMaps);
    newMaps[providerId] = roomIds;
    setProviderRoomMaps(newMaps);
  };

  const changeProvider = (fromId: string, toId: string): void => {
    const fromIndex = selectedProviders.findIndex((provider) => provider.id === fromId);
    const to = providers.find((provider) => provider.id === toId);
    if (fromIndex !== -1 && !!to) {
      selectedProviders.splice(fromIndex, 1, to);
      setSelectedProviders(selectedProviders);
    }

    const newMaps = Object.assign({}, providerRoomMaps);
    newMaps[toId] = newMaps[fromId];
    delete newMaps[fromId];
    setProviderRoomMaps(newMaps);
  };

  const openZDWidget = useCallback(() => {
    if (window.zE) {
      window.zE('messenger', 'open');
    }
  }, []);

  return (
    <>
      <form id="appointment-type-form" onSubmit={handleSubmit(onSubmit)}>
        <Modal size="xl" isOpen={isOpen} onClose={checkChanges} scrollBehavior="outside" isCentered={false}>
          <ModalHeader size="lg">Edit Appointment Type</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex flexDir="column" gap={6}>
              <Flex flexDir="column" gap={2}>
                <Text fontWeight="semibold">Name</Text>
                <Text>{appointmentType?.name}</Text>
              </Flex>
              <TextInput
                name="displayName"
                ref={register()}
                placeholder="Display Name"
                label={
                  <InputLabel
                    label="Display Name"
                    description="This is the name that will be displayed to clients in the Pet Portal."
                  />
                }
              />

              <HasFeature name={FeatureFlagName.DirectBooking}>
                <Textarea
                  name="description"
                  ref={register()}
                  placeholder="Appointment description..."
                  label={
                    <InputLabel
                      label="Description"
                      description="This is the appointment description that will be shown to clients when making a Direct Booking request."
                    />
                  }
                  minH="80px"
                />
                <Flex gap="6">
                  <TextInput
                    name="defaultDurationInMinutes"
                    ref={register()}
                    placeholder="Duration in minutes"
                    type="number"
                    min={5}
                    step={5}
                    label={
                      <InputLabel
                        label="Duration (min)"
                        description="This is the appointment duration that will be used for Direct Booking requests. Duration must be a multiple of 5 minutes."
                      />
                    }
                  />
                  <TextInput
                    name="defaultSoonestAvailableBookingInHours"
                    ref={register()}
                    placeholder="Soonest time in hours"
                    type="number"
                    min={1}
                    step={1}
                    label={
                      <InputLabel
                        label="Booking Delay (hr)"
                        description="This is the soonest that a client will be able to book an appointment through a Direct Booking request. Specified in hourly increments."
                      />
                    }
                  />
                </Flex>
                <HasFeature name={FeatureFlagName.DirectBookingOffHourStartTime}>
                  <Flex pr={6}>
                    {(isPimless || isAvimark) && (
                      <TextInput
                        width="50%"
                        name="delayedStartTime"
                        ref={register()}
                        placeholder="Delay start time (5-55 min)"
                        type="number"
                        max={55}
                        min={5}
                        step={5}
                        label={
                          <InputLabel
                            label="Delayed start time (min)"
                            description="Set a delayed start time for appointment slots. For example, a 7:00 AM - 7:30 AM slot with a 5-minute delay becomes 7:05 AM - 7:35 AM. The duration must be a multiple of 5 minutes and no longer than 55 minutes."
                          />
                        }
                      />
                    )}
                    {isCornerstone && (
                      <TextInput
                        width="50%"
                        name="delayedStartTime"
                        ref={register()}
                        placeholder="Delay start time (10-50 min)"
                        type="number"
                        max={55}
                        min={10}
                        step={5}
                        label={
                          <InputLabel
                            label="Delayed start time (min)"
                            description="Set a delayed start time for appointment slots. For example, a 7:00 AM - 7:30 AM slot with a 15-minute delay becomes 7:15 AM - 7:45 AM. The duration must be a multiple of 10 or 15 minutes and no longer than 55 minutes."
                          />
                        }
                      />
                    )}
                  </Flex>
                </HasFeature>
                <HasFeature name={FeatureFlagName.DirectBookingCreateClientAndPatient}>
                  <Flex gap="6">
                    <Stack>
                      <RadioGroup
                        name="allowDirectBooking"
                        radios={clientTypeOptions}
                        direction="column"
                        spacing={4}
                        value={clientType}
                        onChange={(v: string): void => setClientType(v as DirectBookingAppointmentTypeClientType)}
                        label={
                          <InputLabel
                            label="Allow Direct Booking"
                            description="You can select whether this appointment type is only available to existing clients, new clients, or both within Direct Booking."
                          />
                        }
                      />
                    </Stack>
                  </Flex>
                </HasFeature>
                <Divider my={6} />
                <Flex gap={3} flexDir="column">
                  <Flex justify="space-between" align="center">
                    <Heading size="md">Providers</Heading>
                    <Tooltip
                      label={!copyMenuItems.length ? 'There are no appointment types with providers to copy' : ''}
                    >
                      <Box>
                        <Menu
                          isLazy={true}
                          buttonProps={{
                            variant: 'ghost',
                            leftIconName: 'plus',
                            text: 'Add Provider',
                            showRightIcon: false,
                            alignSelf: 'flex-start',
                          }}
                          listProps={{
                            isSearchable: true,
                            isMultiSelect: true,
                            canSelectAll: true,
                            menuItems: providerMenuItems,
                            onSelect: onProviderSelect,
                            onSelectAll: onProviderSelectAll,
                          }}
                        />
                        <Menu
                          isLazy={true}
                          buttonProps={{
                            variant: 'ghostNeutral',
                            size: 'md',
                            leftIconName: 'dotsVertical',
                            showRightIcon: false,
                          }}
                          listProps={{
                            menuItems: [
                              {
                                label: (
                                  <Menu
                                    isLazy={true}
                                    buttonProps={{
                                      disabled: !copyMenuItems.length,
                                      as: CopySubMenu,
                                    }}
                                    listProps={{
                                      isSearchable: true,
                                      menuItems: copyMenuItems,
                                      position: 'fixed',
                                      zIndex: 2,
                                      top: 25, // move to below item
                                      // TODO: add this functionality into kibble with Portal
                                    }}
                                  />
                                ),
                                closeOnSelect: false,
                              },
                            ],
                          }}
                        />
                      </Box>
                    </Tooltip>
                  </Flex>
                  {selectedProviders.length >= 1 && isRoomMappingRequired && !rooms.length && (
                    <Alert status="error" hideCloseButton={true}>
                      <Text fontWeight="semibold" as="p">
                        No rooms available
                      </Text>
                      {`Rooms are required to enable direct booking for this appointment type, but we're unable to find any valid rooms in ${primaryIntegrationName}. Please `}
                      <Link onClick={openZDWidget}>contact support.</Link>
                    </Alert>
                  )}

                  <Flex flexDir="column" gap={2}>
                    {selectedProviders.map((provider) => (
                      <Provider
                        key={provider.id}
                        provider={provider}
                        remove={removeSingleProvider}
                        selectedRoomIds={providerRoomMaps[provider.id]}
                        updateRoomsForProvider={updateRoomsForProvider}
                        selectedProviders={selectedProviders}
                        changeProvider={changeProvider}
                        showValidation={attemptedSave}
                      />
                    ))}
                  </Flex>
                </Flex>
              </HasFeature>
            </Flex>
          </ModalBody>
          <ModalFooter justifyContent="space-between">
            <Button variant="tertiary" onClick={checkChanges} disabled={isSaving}>
              Cancel
            </Button>
            <Button form="appointment-type-form" type="submit" disabled={isSaving} isLoading={isSaving}>
              Save
            </Button>
          </ModalFooter>
        </Modal>
      </form>
      <UnsavedChangesModal isOpen={isUnsavedChangesOpen} onClose={onUnsavedChangesClose} onConfirm={handleClose} />
    </>
  );
};

export default React.memo(EditAppointmentTypeModal);
