import React, { useCallback, useEffect, useState, useMemo, KeyboardEvent, useRef } from 'react';
import debounce from 'lodash-es/debounce';
import partial from 'lodash-es/partial';
import isEqual from 'lodash-es/isEqual';
import omit from 'lodash-es/omit';
import mapValues from 'lodash-es/mapValues';
import { useModal } from '@televet/televet-ui';
import {
  Flex,
  Button,
  HStack,
  Menu,
  VStack,
  MenuItemProps,
  Box,
  Text,
  Switch,
  Heading,
  TextInput,
} from '@televet/kibble-ui';
import { ModalNames } from 'shared/enums/ModalNames';
import useClinicUser from 'shared/hooks/useClinicUser';
import MarkAllAsReadConfirmationModal from './MarkAllAsReadConfirmationModal';
import { Mixpanel } from 'shared/utils/mixpanel';
import usePrevious from 'shared/hooks/usePrevious';
import { ChannelSearchInputKeys, ChannelSearchInputUpdates } from '../../../interfaces/ChannelSearchInput';
import { defaultChannelListFilter, IChannelListFilter } from '../../../interfaces/IChannelListFilter';
import ChannelListFilterPopover from '../ChannelListFilter/components/ChannelListFilterPopover';
import FilterIconButton from 'shared/components/FilterIconButton/FilterIconButton';
import { IChannelListFilterSelection } from '../../../interfaces/IChannelListFilterSelection';

export enum ChannelListFilterType {
  All = 'All',
  Unread = 'Unread',
}

const channelListViewOptions: MenuItemProps[] = [
  {
    label: 'All conversations',
    value: ChannelListFilterType.All,
  },
  {
    label: 'Unread conversations',
    value: ChannelListFilterType.Unread,
  },
];

interface IChannelListToolbarProps {
  isLoading: boolean;
  isSearching: boolean;
  currentChannelFilterSelection: IChannelListFilterSelection;
  searchResultsCount?: number;
  showSearchResultsCount?: boolean;
  isFilterApplied: boolean;
  onSearchToggle: (isSearching: boolean) => void;
  onChannelSearchInputChange: (updates: ChannelSearchInputUpdates) => void;
  onClearFilter: (source?: string, ignoreKeys?: (keyof IChannelListFilter)[]) => void;
  onSelectFilter: (filterSelection: IChannelListFilterSelection) => void;
  onChannelListViewSelect: (filterType: ChannelListFilterType) => void;
  onShowArchivedToggle: () => void;
}

enum ChannelListMoreOptions {
  MarkAllAsRead = 'MarkAllAsRead',
}

const ChannelListToolbar = ({
  isLoading,
  isSearching,
  currentChannelFilterSelection,
  searchResultsCount,
  showSearchResultsCount = false,
  isFilterApplied,
  onSearchToggle,
  onChannelSearchInputChange,
  onClearFilter,
  onSelectFilter,
  onChannelListViewSelect,
  onShowArchivedToggle,
}: IChannelListToolbarProps): JSX.Element => {
  const { clinicUser, currentClinicId } = useClinicUser();
  const { openModal } = useModal();
  const userId = clinicUser?.id;
  const [searchTextPetParentNames, setSearchTextPetParentNames] = useState('');
  const [searchTextPetNames, setSearchTextPetNames] = useState('');
  const [searchTextMessageBodies, setSearchTextMessageBodies] = useState('');
  const [isTouched, setIsTouched] = useState(false);
  const searchTextClientNameInputRef = useRef<HTMLInputElement | null>(null);
  const searchTextClientNameInputRefCallback = useCallback((element: HTMLInputElement | null) => {
    searchTextClientNameInputRef.current = element;
    element?.focus();
  }, []);
  const [isFilterPopoverOpen, setIsFilterPopoverOpen] = useState(false);
  const [filterPopoverKey, setFilterPopoverKey] = useState(Date.now());

  const isNonArchivedFilterApplied = useMemo(() => {
    return !isEqual(
      omit(currentChannelFilterSelection.filters, 'showArchived'),
      omit(defaultChannelListFilter, 'showArchived'),
    );
  }, [currentChannelFilterSelection.filters]);

  useEffect(() => {
    if (isFilterPopoverOpen) {
      Mixpanel.track('Conversation list filter popover viewed');
    }
  }, [isFilterPopoverOpen]);

  const currentFilter = currentChannelFilterSelection.filters;
  const isHidingArchived = useMemo(() => !currentFilter.showArchived, [currentFilter]);
  const conversationSetText = useMemo(() => {
    let conversationSet = 'all';
    if (isNonArchivedFilterApplied) {
      conversationSet = 'filtered';
    } else if (isHidingArchived) {
      conversationSet = 'active';
    }
    return conversationSet;
  }, [isNonArchivedFilterApplied, isHidingArchived]);

  // eslint-disable-next-line
  const searchChannels = useCallback<
    ((updates: ChannelSearchInputUpdates) => void) & { flush: () => void | undefined }
  >(
    debounce(
      (updates: ChannelSearchInputUpdates): void => {
        onChannelSearchInputChange(mapValues(updates, (value) => value?.trim()));
      },
      340,
      { leading: false, trailing: true },
    ),
    [currentClinicId],
  );

  const markAllAsRead = useCallback(async (): Promise<void> => {
    openModal(ModalNames.MarkAllAsReadConfirmation, { userId, clinicId: currentClinicId });
  }, [userId, currentClinicId, openModal]);

  const handleRemoveFilters = useCallback(
    (source?: string, ignoreKeys?: (keyof IChannelListFilter)[]) => {
      onClearFilter(source, ignoreKeys);
      setFilterPopoverKey(Date.now());
    },
    [onClearFilter],
  );

  const handleSearchInput = useCallback(
    (updates: ChannelSearchInputUpdates): void => {
      if (isSearching && isTouched) searchChannels(updates);
    },
    [isSearching, isTouched, searchChannels],
  );

  const handleChannelSearchInputChange = useCallback(
    (key: ChannelSearchInputKeys, event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      if (key === ChannelSearchInputKeys.PetParentNames) setSearchTextPetParentNames(value);
      if (key === ChannelSearchInputKeys.PetNames) setSearchTextPetNames(value);
      if (key === ChannelSearchInputKeys.MessageBodies) setSearchTextMessageBodies(value);
    },
    [],
  );

  const handleSearchInputKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (!isTouched) setIsTouched(true);
      if (event.key === 'Escape' || event.key === 'Esc') {
        onSearchToggle(false);
      } else if (event.key === 'Enter' && !isLoading) {
        searchChannels({
          [ChannelSearchInputKeys.PetParentNames]: searchTextPetParentNames,
          [ChannelSearchInputKeys.PetNames]: searchTextPetNames,
          [ChannelSearchInputKeys.MessageBodies]: searchTextMessageBodies,
        });
        searchChannels.flush();
      }
    },
    [
      isLoading,
      isTouched,
      searchTextPetParentNames,
      searchTextPetNames,
      searchTextMessageBodies,
      searchChannels,
      onSearchToggle,
    ],
  );

  const handleChannelListViewSelect = useCallback(
    (option: MenuItemProps): void => {
      if (option.value === ChannelListFilterType.Unread) {
        Mixpanel.track('Unread Conversations Filter Clicked');
      }

      Mixpanel.track('All Conversations Filter Clicked');
      onChannelListViewSelect(option?.value as ChannelListFilterType);
    },
    [onChannelListViewSelect],
  );

  const handleMoreOptionsSelect = useCallback(
    (option: MenuItemProps): void => {
      if (option.value === ChannelListMoreOptions.MarkAllAsRead) {
        Mixpanel.track('Mark all as read Clicked');
        markAllAsRead();
      }
    },
    [markAllAsRead],
  );

  const handleSelectFilter = useCallback(
    (channelFilterSelection: IChannelListFilterSelection): void => {
      onSelectFilter(channelFilterSelection);
    },
    [onSelectFilter],
  );

  const handleShowArchivedToggle = useCallback(() => {
    Mixpanel.track('Conversations Show Archived toggled', {
      value: !currentChannelFilterSelection.filters.showArchived,
      source: 'conversations list search results',
    });
    onShowArchivedToggle();
  }, [currentChannelFilterSelection, onShowArchivedToggle]);

  // Reset channel list filter popover when clinic changes
  const previousClinicId = usePrevious(currentClinicId);
  useEffect(() => {
    if (currentClinicId && previousClinicId && currentClinicId !== previousClinicId) {
      setFilterPopoverKey(Date.now());
    }
  }, [currentClinicId, previousClinicId, setFilterPopoverKey]);

  useEffect(() => {
    if (!isSearching) {
      setSearchTextPetParentNames('');
      setSearchTextPetNames('');
      setSearchTextMessageBodies('');
      setIsTouched(false);
    }
  }, [isSearching]);

  useEffect(() => {
    handleSearchInput({
      [ChannelSearchInputKeys.PetParentNames]: searchTextPetParentNames,
      [ChannelSearchInputKeys.PetNames]: searchTextPetNames,
      [ChannelSearchInputKeys.MessageBodies]: searchTextMessageBodies,
    });
  }, [searchTextPetParentNames, searchTextPetNames, searchTextMessageBodies, handleSearchInput]);

  const filterShortName = useMemo(() => (currentFilter.unread ? 'Unread' : 'All'), [currentFilter]);
  const filterButtonTooltipContent = useMemo(() => {
    if (!isFilterPopoverOpen) {
      if (currentChannelFilterSelection.name) {
        return currentChannelFilterSelection.name;
      } else {
        return 'Filters';
      }
    }
  }, [currentChannelFilterSelection, isFilterPopoverOpen]);

  return (
    <VStack spacing="0" bg="background.default" className="ChannelListToolbar__Container">
      <HStack
        borderBottom="1px"
        borderBottomColor="border.default"
        className="ChannelListToolbar__Header"
        justifyContent="space-between"
        width="100%"
        height="60px"
        p="0px 8px"
      >
        <HStack className="ChannelListToolbar__Header-LeftButtons">
          <Button
            iconName="magnifyingGlass"
            size="md"
            variant="ghostNeutral"
            onClick={(): void => onSearchToggle(!isSearching)}
            data-testid="conversations-list-search-toggle-button"
          />
          <ChannelListFilterPopover
            key={filterPopoverKey}
            trigger={
              <FilterIconButton
                data-testid="conversations-list-filter-toggle-button-container"
                buttonProps={{ 'data-testid': 'conversations-list-filter-toggle-button' }}
                showBadge={!!currentChannelFilterSelection.id || isFilterApplied}
                tooltip={filterButtonTooltipContent || ''}
                onFilterToggle={(): void => setIsFilterPopoverOpen((value) => !value)}
              />
            }
            isOpen={isFilterPopoverOpen}
            currentChannelFilterSelection={currentChannelFilterSelection}
            onSelectFilter={handleSelectFilter}
            onClearFilter={handleRemoveFilters}
            onClose={(): void => setIsFilterPopoverOpen(false)}
            onShowArchivedToggle={onShowArchivedToggle}
          />
        </HStack>
        <HStack className="ChannelListToolbar__Header-RightButtons">
          <Menu
            buttonProps={{
              text: filterShortName,
              leftIconName: 'eyeOpen',
              variant: 'ghostNeutral',
              size: 'sm',
            }}
            placement="bottom-end"
            listProps={{ onSelect: handleChannelListViewSelect, menuItems: channelListViewOptions }}
          />
          <Menu
            buttonProps={{
              leftIconName: 'dotsVertical',
              showRightIcon: false,
              variant: 'ghostNeutral',
              size: 'sm',
            }}
            placement="bottom-end"
            listProps={{
              onSelect: handleMoreOptionsSelect,
              menuItems: [{ label: 'Mark all as read', value: ChannelListMoreOptions.MarkAllAsRead }],
            }}
          />
        </HStack>
      </HStack>
      {isSearching && (
        <Flex
          className="ChannelListToolbar__ChannelSearchContainer"
          direction="column"
          w="100%"
          borderBottom="1px"
          borderBottomColor="border.default"
          p="3"
        >
          <Flex className="ChannelListToolbar__ChannelSearchHeader" align="center" justify="space-between" pb="1">
            <Heading size="sm">Search {conversationSetText} conversations</Heading>
            <Button size="sm" variant="ghostNeutral" iconName="close" onClick={(): void => onSearchToggle(false)} />
          </Flex>
          <VStack spacing="3" position="relative" w="100%" align="stretch">
            <TextInput
              label=""
              name="searchTextClientName"
              ref={searchTextClientNameInputRefCallback}
              type="text"
              placeholder="Search by client name"
              value={searchTextPetParentNames}
              onChange={partial(handleChannelSearchInputChange, ChannelSearchInputKeys.PetParentNames)}
              onKeyDown={handleSearchInputKeyDown}
              onFocus={(e): void => e.target.select()}
              data-mixpanel-name="Conversation search client name input"
              data-testid="conversation-search-client-name-input"
            />
            <TextInput
              label=""
              name="searchTextPatientName"
              type="text"
              placeholder="Search by patient name"
              value={searchTextPetNames}
              onChange={partial(handleChannelSearchInputChange, ChannelSearchInputKeys.PetNames)}
              onKeyDown={handleSearchInputKeyDown}
              onFocus={(e): void => e.target.select()}
              data-mixpanel-name="Conversation search patient name input"
              data-testid="conversation-search-patient-name-input"
            />
            <TextInput
              label=""
              name="searchTextMessageBody"
              type="text"
              placeholder="Search by message"
              value={searchTextMessageBodies}
              onChange={partial(handleChannelSearchInputChange, ChannelSearchInputKeys.MessageBodies)}
              onKeyDown={handleSearchInputKeyDown}
              onFocus={(e): void => e.target.select()}
              data-mixpanel-name="Conversation search message name input"
              data-testid="conversation-search-message-input"
            />
          </VStack>
          <Flex>
            <HStack as="label" cursor="pointer" pt="3">
              <Box>
                <Switch isFieldInline isChecked={currentFilter.showArchived} onChange={handleShowArchivedToggle} />
              </Box>
              <Text size="sm">Show Archived</Text>
            </HStack>
          </Flex>
          {showSearchResultsCount && (
            <Flex align="baseline" justify="space-between" fontSize="sm" pt="3">
              <span>
                {searchResultsCount === 0 ? 'No' : searchResultsCount} result{searchResultsCount === 1 ? '' : 's'} from{' '}
                {conversationSetText} conversations
              </span>
              {isNonArchivedFilterApplied && (
                <Button
                  variant="ghost"
                  onClick={(): void => handleRemoveFilters('search-panel-results-count')}
                  size="sm"
                >
                  Clear filters
                </Button>
              )}
            </Flex>
          )}
        </Flex>
      )}
      <MarkAllAsReadConfirmationModal />
    </VStack>
  );
};

export default ChannelListToolbar;
