import React, { useMemo, useCallback } from 'react';
import { TagEntity, TagType, useRemoveChannelTagMutation } from 'shared/types/graphql';
import { Box, Tag, TagVariant, Tooltip, Wrap, WrapItem } from '@televet/kibble-ui';
import { upperFirst } from 'lodash-es';

export interface ChannelTagProps {
  name: string;
  tagType: TagType;
  colorBackground: string;
  colorText: string;
  tagEntities?: TagEntity[];
}

interface CardTagsProps {
  tags: ChannelTagProps[];
  channelId: string;
}

export const getTagVariantByType = (tagType: TagType | null | undefined): TagVariant => {
  switch (tagType) {
    case TagType.PaymentRequested:
    case TagType.FormRequested:
      return 'warning';
    case TagType.TrupanionCandidate:
      return 'info';
    case TagType.PaymentReceived:
    case TagType.FormSubmitted:
      return 'success';
    default:
      return 'default';
  }
};

const CardTags = ({ tags, channelId }: CardTagsProps): JSX.Element => {
  const [removeChannelTag] = useRemoveChannelTagMutation();

  const handleRemoveChannelTag = useCallback(
    (tagName: string, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      const newTags = tags.filter(({ name }) => name.toLowerCase() !== tagName.toLowerCase());
      removeChannelTag({
        variables: { channelId: channelId, tagName },
        optimisticResponse: {
          removeChannelTag: {
            id: channelId,
            tags: newTags,
          },
        },
      });
    },
    [channelId, tags, removeChannelTag],
  );

  const sortedTags = useMemo(() => [...tags].sort((a, b) => a.name.localeCompare(b.name)), [tags]);

  return (
    <Box className="ChannelListItem__Tags" mt={3}>
      <Wrap spacing="6px">
        {sortedTags.map((tag) => {
          let tagElement = (
            <Tag
              size="sm"
              label={upperFirst(tag.name)}
              closeButtonDisplay="hover"
              {...(tag.tagType === TagType.Custom && tag.colorBackground && tag.colorText
                ? { bg: tag.colorBackground, color: tag.colorText }
                : { variant: getTagVariantByType(tag.tagType) })}
              onClose={(e): void => handleRemoveChannelTag(tag.name, e)}
            />
          );

          if (Array.isArray(tag.tagEntities)) {
            const labels = tag.tagEntities.map((t) => t.label);
            const tooltipContent =
              labels.length > 3 ? `${labels.slice(0, 3).join(', ')}, + ${labels.length - 3} more` : labels.join(', ');

            tagElement = (
              <Tooltip label={tooltipContent} placement="top">
                <Box>{tagElement}</Box>
              </Tooltip>
            );
          }

          return <WrapItem key={tag.name}>{tagElement}</WrapItem>;
        })}
      </Wrap>
    </Box>
  );
};

export default CardTags;
