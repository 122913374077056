import { Flex } from '@televet/kibble-ui/build/chakra';
import { Alert } from '@televet/kibble-ui/build/components/Alert';
import { MenuItemProps } from '@televet/kibble-ui/build/components/Menu/components/MenuItem';
import { ModalBody, ModalFooter } from '@televet/kibble-ui/build/components/Modal';
import { Select } from '@televet/kibble-ui/build/components/Select';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { Textarea } from '@televet/kibble-ui/build/components/Textarea';
import React, { FC, useCallback, useMemo } from 'react';
import { Control, Controller, OnSubmit } from 'react-hook-form';
import useClinicUser from 'shared/hooks/useClinicUser';
import {
  CancelReasonType,
  CareEnrollmentFragment,
  CareSubscriptionFragment,
  CareSubscriptionTrueUpType,
  ClinicCarePlanFragment,
  ManageMembershipRequestType,
  SidePanelParentPetDataFragment,
  useGetClinicUsersQuery,
} from 'shared/types/graphql';
import { ManageMembershipStep, useManageMembershipContext } from '../context/ManageMembershipContext';
import { IFormValues, RefundOptions } from '../types';
import { DESCRIPTION_MAX_CHAR_COUNT } from '../utils';
import UpgradeInformation from './UpgradeInformation';
import formatDate from 'date-fns/format';
import { isEnrollmentConsideredActive } from '../../../utils/careEnrollments';
import differenceInDays from 'date-fns/differenceInDays';
import { RadioGroup } from '@televet/kibble-ui/build/components/RadioGroup';
import { centsToDollars } from '../../../utils/currency';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { ReadableCancelReason } from '../../ActivityLog/records/activityLogCard.records';
import {
  descriptionFieldLabel,
  descriptionFieldPlaceholder,
  RequestTypesWithoutDescriptionField,
} from '../records/descriptionField.records';

type TextLabelProps = {
  text: string;
};

const TextLabel: FC<TextLabelProps> = ({ text }) => (
  <Flex pt={4} pb={2}>
    <Text size="md" fontWeight="bold">
      {text}
    </Text>
  </Flex>
);

interface FormProps {
  carePlanId?: string;
  careEnrollment?: CareEnrollmentFragment;
  plans?: ClinicCarePlanFragment[];
  pets?: SidePanelParentPetDataFragment[];
  subscription?: CareSubscriptionFragment;
  updateSubmitText: (text: string) => void;
  inputFieldTextError: string;
  setInputFieldTextError: (error: string) => void;
  selectedEmail: string;
  watchRequestType: ManageMembershipRequestType;
  watchCancelReason?: CancelReasonType;
  control: Control<IFormValues>;
  setValue: (name: string, value: string | number) => void;
  isValid: boolean;
  submitForm: () => void;
  handleSubmit: (callback: OnSubmit<IFormValues>) => (e?: React.BaseSyntheticEvent) => Promise<void>;
}

const SupportForm = ({
  carePlanId,
  careEnrollment,
  plans,
  pets,
  subscription,
  updateSubmitText,
  inputFieldTextError,
  setInputFieldTextError,
  selectedEmail,
  watchRequestType,
  watchCancelReason,
  control,
  setValue,
  isValid,
  submitForm,
  handleSubmit,
}: FormProps): JSX.Element => {
  const {
    submitText,
    isSubmitting,
    setIsAbleToUpgrade,
    isAbleToUpgrade,
    hasBenefitUsages,
    handleNext,
    setForm,
    paymentData,
    setPaymentData,
  } = useManageMembershipContext();
  const { currentClinicId } = useClinicUser();

  const { data: clinicUserData } = useGetClinicUsersQuery({
    variables: {
      clinicId: currentClinicId || '',
    },
  });

  const isElegibleForRefund = useMemo(
    () =>
      careEnrollment?.startDate
        ? !hasBenefitUsages && differenceInDays(new Date(), new Date(careEnrollment.startDate)) <= 30
        : false,
    [careEnrollment?.startDate, hasBenefitUsages],
  );

  const paymentsMade = useMemo(() => {
    const amount = careEnrollment?.enrollmentPayments.reduce((acc, curr) => acc + curr.amount, 0) || 0;
    return centsToDollars(amount);
  }, [careEnrollment?.enrollmentPayments]);

  const userOptions: MenuItemProps[] = useMemo(
    () =>
      clinicUserData?.users?.map((user) => ({
        value: user.id,
        label: user.displayName,
      })) || [],
    [clinicUserData?.users],
  );

  // Filter out current plan from the list of plans
  const filteredPlans = useMemo(() => {
    return plans?.filter((plan: ClinicCarePlanFragment) => plan.id !== carePlanId);
  }, [carePlanId, plans]);

  const upgradePlanOptions: MenuItemProps[] = useMemo(() => {
    return (
      (filteredPlans?.map((plan: ClinicCarePlanFragment) => ({
        value: plan.id,
        label: plan.flowAlias || plan.title,
      })) as MenuItemProps[]) || []
    );
  }, [filteredPlans]);

  const transferPetOptions: MenuItemProps[] = useMemo(
    () =>
      // Filter out any pets with active enrollments or inactive/deceased pets
      pets
        ?.filter((pet) => {
          const enrollments = pet.organizationPet?.carePlanEnrollments;
          const enrollment = enrollments?.find((enrollment) => isEnrollmentConsideredActive(enrollment));
          return !enrollment && pet.isActive && !pet.isDeceased;
        })
        .map((pet) => ({
          value: pet.id,
          label: pet.name,
        })) || [],
    [pets],
  );

  // Determine if 'Transfer' and 'Upgrade' should be options based on transferPetOptions and plans lengths respectively
  const shouldIncludeTransferOption = transferPetOptions.length > 0;
  const shouldIncludeUpgradeOption = upgradePlanOptions.length > 1;

  const requestTypeOptions: MenuItemProps[] = [
    { value: ManageMembershipRequestType.Cancel, label: 'Cancel Membership' },
    { value: ManageMembershipRequestType.OptOut, label: 'Renewal Opt-Out' },
    ...(shouldIncludeTransferOption
      ? [{ value: ManageMembershipRequestType.Transfer, label: 'Transfer Membership' }]
      : []),
    ...(shouldIncludeUpgradeOption
      ? [{ value: ManageMembershipRequestType.Upgrade, label: 'Upgrade Membership' }]
      : []),
    { value: ManageMembershipRequestType.RemoveBenefit, label: 'Remove Benefit Consumption' },
    { value: ManageMembershipRequestType.Other, label: 'Other Request' },
  ];

  const onEmailSelect = useCallback(
    (selectedItem: MenuItemProps): void => {
      setValue('pointOfContactId', selectedItem.value || '');
    },
    [setValue],
  );

  const highestTierPlan = useMemo(
    () =>
      plans?.reduce((prev, current) => {
        return prev && prev.pricePerRenewal > current.pricePerRenewal ? prev : current;
      }),
    [plans],
  );

  const onRequestTypeSelect = useCallback(
    (selectedItem: MenuItemProps): void => {
      const selectedRequestType = selectedItem.value as ManageMembershipRequestType;
      if (Object.values(ManageMembershipRequestType).includes(selectedRequestType)) {
        if (selectedRequestType === ManageMembershipRequestType.Upgrade) {
          // Check if pet can be upgraded
          setIsAbleToUpgrade(!!highestTierPlan ? highestTierPlan.id !== carePlanId : true);
        }

        setValue('requestType', selectedRequestType);
      }
    },
    [carePlanId, highestTierPlan, setIsAbleToUpgrade, setValue],
  );

  const cancelReasonOptions: MenuItemProps[] = useMemo(
    () => [
      { value: CancelReasonType.DeceasedPet, label: ReadableCancelReason[CancelReasonType.DeceasedPet] },
      { value: CancelReasonType.ChangedMind, label: ReadableCancelReason[CancelReasonType.ChangedMind] },
      { value: CancelReasonType.Moving, label: ReadableCancelReason[CancelReasonType.Moving] },
      { value: CancelReasonType.FinancialHardship, label: ReadableCancelReason[CancelReasonType.FinancialHardship] },
      { value: CancelReasonType.PetRehomed, label: ReadableCancelReason[CancelReasonType.PetRehomed] },
      { value: CancelReasonType.Other, label: ReadableCancelReason[CancelReasonType.Other] },
      ...(watchRequestType === ManageMembershipRequestType.Cancel
        ? [
            {
              value: CancelReasonType.ClinicInitiatedUncollectible,
              label: ReadableCancelReason[CancelReasonType.ClinicInitiatedUncollectible],
            },
            {
              value: CancelReasonType.ClinicInitiatedOther,
              label: ReadableCancelReason[CancelReasonType.ClinicInitiatedOther],
            },
          ]
        : []),
    ],
    [watchRequestType],
  );

  const onCancelReasonSelect = useCallback(
    (selectedItem: MenuItemProps): void => {
      const cancelReason = selectedItem.value as CancelReasonType;
      if (Object.values(CancelReasonType).includes(cancelReason)) {
        setValue('cancelReason', selectedItem.value as CancelReasonType);
        setPaymentData({
          ...paymentData,
          paymentType:
            cancelReason === CancelReasonType.ClinicInitiatedUncollectible
              ? CareSubscriptionTrueUpType.ClinicAlternativePayment
              : CareSubscriptionTrueUpType.Internal,
        });
      }
    },
    [paymentData, setPaymentData, setValue],
  );

  const onTransferPetSelect = useCallback(
    (selectedItem: MenuItemProps): void => {
      setValue('transferPetId', selectedItem.value || '');
    },
    [setValue],
  );

  const subscriptionPaymentLast4 = subscription?.stripePaymentMethod?.last4 || '';

  const isAutomatedPath = [ManageMembershipRequestType.Upgrade, ManageMembershipRequestType.Transfer].includes(
    watchRequestType,
  );

  const isCancelRequest = watchRequestType === ManageMembershipRequestType.Cancel;
  const isOptOutRequest = watchRequestType === ManageMembershipRequestType.OptOut;

  const isOtherCancelReason =
    isCancelRequest &&
    (watchCancelReason === CancelReasonType.Other || watchCancelReason === CancelReasonType.ClinicInitiatedOther);

  const isAlreadyOptedOut = isOptOutRequest && !careEnrollment?.autoRenews;

  const isNotAutomatedPath = [ManageMembershipRequestType.RemoveBenefit, ManageMembershipRequestType.Other].includes(
    watchRequestType,
  );

  const shouldShowDescription =
    isOtherCancelReason || isNotAutomatedPath || (isOptOutRequest && careEnrollment?.autoRenews);

  const isNonZendeskRequest = isCancelRequest || isOptOutRequest || isAutomatedPath;

  const getCTAText = (): string => {
    switch (watchRequestType) {
      case ManageMembershipRequestType.Cancel:
        return 'Next';
      case ManageMembershipRequestType.Upgrade:
        return 'Upgrade Membership';
      case ManageMembershipRequestType.Transfer:
        return 'Transfer Membership';
      default:
        return 'Submit Request';
    }
  };

  const handleGetHelp = async (data: IFormValues): Promise<void> => {
    setForm(data);
    handleNext(ManageMembershipStep.GetHelp);
  };

  return (
    <>
      <ModalBody>
        <TextLabel text="Who should we contact with questions and updates regarding this request?" />
        <Controller
          name="pointOfContactId"
          control={control}
          rules={{ required: true }}
          as={Select}
          options={userOptions}
          listProps={{
            isSearchable: true,
            onSelect: (selectedItem: MenuItemProps): void => onEmailSelect(selectedItem),
            // onSelect functions is required for the Select components to work even though the variables are being watched with react-hook-form because the Select only takes a onSelect handler vs a onChange handler which react-hook-form expects
          }}
          isRequired
        />
        <TextLabel text="Request type" />
        <Flex>
          <Controller
            name="requestType"
            control={control}
            rules={{ required: true }}
            as={Select}
            options={requestTypeOptions}
            listProps={{
              onSelect: (selectedItem: MenuItemProps): void => onRequestTypeSelect(selectedItem),
            }}
            defaultValue={ManageMembershipRequestType.RemoveBenefit}
            isRequired
          />
        </Flex>

        {(watchRequestType === ManageMembershipRequestType.Cancel ||
          (watchRequestType === ManageMembershipRequestType.OptOut && careEnrollment?.autoRenews)) && (
          <>
            <TextLabel
              text={
                watchRequestType === ManageMembershipRequestType.OptOut
                  ? 'Why is this membership not being renewed?'
                  : 'Why is this membership being cancelled?'
              }
            />
            <Controller
              name="cancelReason"
              control={control}
              as={Select}
              options={cancelReasonOptions}
              listProps={{
                onSelect: (selectedItem: MenuItemProps): void => onCancelReasonSelect(selectedItem),
              }}
              defaultValue={cancelReasonOptions[0].value}
            />
          </>
        )}
        {watchRequestType === ManageMembershipRequestType.Upgrade && (
          <UpgradeInformation
            enrollmentId={careEnrollment?.id || ''}
            upgradePlanOptions={upgradePlanOptions}
            plans={plans}
            subscriptionPaymentLast4={subscriptionPaymentLast4}
            control={control}
            setValue={setValue}
          />
        )}
        {watchRequestType === ManageMembershipRequestType.Transfer && (
          <>
            <TextLabel text="Transfer to which pet?" />
            <Controller
              name="transferPetId"
              control={control}
              as={Select}
              options={transferPetOptions}
              listProps={{
                isSearchable: true,
                onSelect: (selectedItem: MenuItemProps): void => onTransferPetSelect(selectedItem),
              }}
              defaultValue={transferPetOptions[0].value}
            />
          </>
        )}
        {shouldShowDescription && (
          <Textarea
            size="md"
            label={<TextLabel text={descriptionFieldLabel[watchRequestType as RequestTypesWithoutDescriptionField]} />}
            helperText={`${submitText.length}/${DESCRIPTION_MAX_CHAR_COUNT}`}
            isInvalid={!!inputFieldTextError || submitText.length > DESCRIPTION_MAX_CHAR_COUNT}
            errorText={
              inputFieldTextError || `Please limit your description to ${DESCRIPTION_MAX_CHAR_COUNT} characters.`
            }
            placeholder={descriptionFieldPlaceholder[watchRequestType as RequestTypesWithoutDescriptionField]}
            onBlur={(): void => {
              if (submitText.length < 1) {
                setInputFieldTextError('Please add a description before submitting');
              }
            }}
            onChange={(e): void => {
              updateSubmitText(e.target.value);
              if (e.target.value.length > 0) {
                setInputFieldTextError(''); // Clear custom error
              }
            }}
            value={submitText}
            rows={3}
          />
        )}
        {watchRequestType === ManageMembershipRequestType.Cancel && isElegibleForRefund && (
          <>
            <Text as="p" pt={4} pb={2} size="md">
              No benefits have been used from this membership and no balance is owed.{' '}
              <Text fontWeight="semibold">Client is eligible for a refund of ${paymentsMade} for payments made:</Text>
            </Text>
            <Controller
              name="allowRefund"
              control={control}
              as={RadioGroup}
              direction="column"
              radios={[
                { children: 'Process refund', value: RefundOptions.Refund },
                { children: 'No refund', value: RefundOptions.NoRefund },
              ]}
              defaultValue={RefundOptions.Refund}
            />
          </>
        )}
        {isAlreadyOptedOut && (
          <Alert
            title={`This membership is not renewing ${
              careEnrollment?.expirationDate
                ? `and will end on ${formatDate(new Date(careEnrollment.expirationDate), 'MM/dd/yy')}`
                : ''
            }.`}
            status="info"
            hideCloseButton
            mt={4}
          />
        )}
        {!(
          (watchRequestType === ManageMembershipRequestType.Upgrade && !isAbleToUpgrade) ||
          isAlreadyOptedOut ||
          watchRequestType === ManageMembershipRequestType.Cancel
        ) && (
          <>
            <Flex pt="4">
              {isNonZendeskRequest ? (
                <Text>This request will be processed automatically, no further action is needed.</Text>
              ) : (
                <Text>
                  Requests will be reviewed by a Care support representative who will be in contact with{' '}
                  {selectedEmail ? (
                    <Text variant="info" fontWeight="semibold">
                      {selectedEmail}
                    </Text>
                  ) : (
                    'you'
                  )}{' '}
                  as soon as possible.
                </Text>
              )}
            </Flex>
          </>
        )}
      </ModalBody>
      <ModalFooter justifyContent="flex-end">
        {isNonZendeskRequest && (
          <Button
            variant="ghost"
            iconName="questionMarkCircle"
            mr={3}
            isDisabled={isSubmitting}
            onClick={handleSubmit(handleGetHelp)}
          >
            Get Help
          </Button>
        )}
        <Button
          onClick={submitForm}
          isLoading={isSubmitting}
          isDisabled={
            !isValid ||
            submitText.length > DESCRIPTION_MAX_CHAR_COUNT ||
            (watchRequestType === ManageMembershipRequestType.Upgrade && !isAbleToUpgrade) ||
            isAlreadyOptedOut
          }
        >
          {getCTAText()}
        </Button>
      </ModalFooter>
    </>
  );
};

export default SupportForm;
