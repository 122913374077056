import { useModal } from '@televet/televet-ui';
import parseISO from 'date-fns/parseISO';
import { DEFAULT_APPOINTMENT_DURATION_IN_MINUTES } from 'pages/Appointments';
import React, { useRef } from 'react';
import { ModalNames } from 'shared/enums/ModalNames';
import useClinicUser from 'shared/hooks/useClinicUser';
import {
  BoardFilterDateRange,
  BoardFilterView,
  GetPaginatedAppointmentsQuery,
  GetUserBoardFilterSettingsDocument,
  useGetUserBoardFilterSettingsQuery,
  useUpdateUserBoardFilterSettingsMutation,
} from 'shared/types/graphql';
import { VStack } from '@televet/kibble-ui/build/chakra';
import BoardHeader from './components/Header/Container';
import useUnreadMessageCount from 'shared/hooks/useUnreadMessageCount';
import ChannelStatusBoard from './components/Boards/ChannelStatusBoard';
import AutomationStatusBoard from 'pages/Board/components/Boards/AutomationStatusBoard';
import { Spinner } from '@televet/kibble-ui/build/components/Spinner';
import { useAppDispatch } from 'state/hooks';
import { updateBoardState } from './state/boardSlice';
import { useToast } from '@televet/kibble-ui';
import { dateRangeMap, mapDateToPredefinedOption } from './utils/dateRangeUtils';

export type Appointment = NonNullable<GetPaginatedAppointmentsQuery['appointments']>[0];

const Board = (): JSX.Element => {
  const { currentClinicId, currentClinic, clinicUserId } = useClinicUser();
  const boardContainerRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const toast = useToast();

  const [updateBoardSettings] = useUpdateUserBoardFilterSettingsMutation({
    refetchQueries: [GetUserBoardFilterSettingsDocument],
    onError: (e) => {
      console.error(e);
      toast({
        title: 'We are having trouble saving your settings.',
        description: e,
      });
    },
  });

  const { data: userBoardSettings, loading: isLoadingSettings } = useGetUserBoardFilterSettingsQuery({
    variables: {
      where: {
        userId: clinicUserId || '',
      },
    },
    onCompleted: (data) => {
      if (data) {
        const boardSettings = data?.customFindUniqueUserBoardFilter;
        const mappedOption = mapDateToPredefinedOption({
          gte: boardSettings?.specificDateRange?.gte,
          lte: boardSettings?.specificDateRange?.lte,
        });

        // No option selected, No date range selected = default to today
        let _dateRangeOption: BoardFilterDateRange | null = BoardFilterDateRange.Today;
        let _specificDateRange = dateRangeMap.today;

        // No option selected, Yes date range selected = default to date range selected
        if (!boardSettings?.dateRangeOption && boardSettings?.specificDateRange) {
          _dateRangeOption = mappedOption;
          _specificDateRange = boardSettings?.specificDateRange;
        }

        // Yes option selected, Yes date range selected = default to date range selected
        if (boardSettings?.dateRangeOption && boardSettings?.specificDateRange) {
          _dateRangeOption = mappedOption;
          _specificDateRange = boardSettings?.specificDateRange;
        }

        // Yes option selected, No date range selected = default to option
        if (boardSettings?.dateRangeOption && !boardSettings?.specificDateRange) {
          _dateRangeOption = boardSettings.dateRangeOption;
          _specificDateRange = dateRangeMap[boardSettings.dateRangeOption];
        }

        dispatch(
          updateBoardState({
            boardSettings: {
              collapsedColumnsMap: boardSettings?.collapsedColumnsMap || {},
              selectedChannelStatusIdsMap: boardSettings?.selectedChannelStatusIdsMap || {},
              shouldHideEmptyColumns: boardSettings?.shouldHideEmptyColumns || false,
              currentView: boardSettings?.currentView || BoardFilterView.Conversations,
              dateRangeOption: _dateRangeOption,
              specificDateRange: _specificDateRange,
            },
            currentClinicId,
            clinicUserId,
          }),
        );
      }
    },
    onError: (error) => {
      toast({
        title: 'Error',
        description: error.message,
      });
    },
    skip: !clinicUserId,
  });

  const {
    data: { unreadChannelArray: unreadChannelIds },
  } = useUnreadMessageCount();

  const { openModal } = useModal();

  const onEditAppointment = (appointment?: Partial<Appointment>): void => {
    openModal(ModalNames.Appointment, {
      startDate: parseISO(appointment?.startAt),
      durationInMinutes: appointment?.durationInMinutes,
      appointmentId: appointment?.id,
      defaultAppointmentDurationInMinutes:
        currentClinic?.clinicSetting?.appointmentTimeSlotIncrementInMinutes || DEFAULT_APPOINTMENT_DURATION_IN_MINUTES,
    });
  };

  return (
    <VStack
      className="Board"
      ref={boardContainerRef}
      spacing={0}
      align="flex-start"
      justify="flex-start"
      overflow="hidden"
      h="100%"
    >
      <BoardHeader updateBoardSettings={updateBoardSettings} />
      {isLoadingSettings || !currentClinicId || !userBoardSettings ? (
        <Spinner />
      ) : userBoardSettings?.customFindUniqueUserBoardFilter?.currentView === BoardFilterView.Automations ? (
        <AutomationStatusBoard
          clinicId={currentClinicId}
          unreadChannelIds={unreadChannelIds}
          onEditAppointment={onEditAppointment}
          updateBoardSettings={updateBoardSettings}
        />
      ) : (
        <ChannelStatusBoard
          clinicId={currentClinicId}
          unreadChannelIds={unreadChannelIds}
          onEditAppointment={onEditAppointment}
          updateBoardSettings={updateBoardSettings}
        />
      )}
    </VStack>
  );
};

export default Board;
