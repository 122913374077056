import { Flex, Heading, ModalHeader, Text } from '@televet/kibble-ui';
import { formatMoney } from 'pages/Reporting/utils/formatMoney';
import React from 'react';
import { PetAvatar } from 'shared/components/Avatars';
import CareBadge from 'shared/components/Badges/CareBadge';
import { EnrollmentStatus, SidePanelParentPetDataFragment } from 'shared/types/graphql';
import { UsePetBenefitCareBenefit } from '../../../hooks/usePetBenefits';

interface IPetInfoHeaderProps {
  title: string;
  pet: SidePanelParentPetDataFragment | undefined;
  originalInvoiceCost?: number;
  totalInvoiceSavings?: number;
  paymentDue?: number;
  currentStep: number;
  isWizardPaymentsFFEnabled?: boolean;
  isLastStepAndFFEnabled?: boolean;
  accountCredit: UsePetBenefitCareBenefit | undefined;
  percentDiscount: UsePetBenefitCareBenefit | undefined;
}

const PetInfoHeader = ({
  title,
  pet,
  originalInvoiceCost,
  totalInvoiceSavings,
  paymentDue,
  currentStep,
  isWizardPaymentsFFEnabled,
  isLastStepAndFFEnabled,
  accountCredit,
  percentDiscount,
}: IPetInfoHeaderProps): JSX.Element => {
  const accountCreditDiscountStep = !!accountCredit || !!percentDiscount ? 1 : 0;
  // if there are no accountCredit or percentDiscount, the total steps will be 3, unless there is a payment step (isWizardPaymentsFFEnabled) then add one extra
  const totalSteps = isWizardPaymentsFFEnabled ? 3 + accountCreditDiscountStep : 4 + accountCreditDiscountStep;
  const adjustedCurrentStep = currentStep > totalSteps ? totalSteps : currentStep;
  // the current step cant be greater than the total steps
  // This has always been pretty hard coded, work is needed to make this more dynamic

  const enrollment = pet?.organizationPet?.carePlanEnrollments.find(
    (enrollment) => enrollment.status === EnrollmentStatus.Active,
  );

  return (
    <ModalHeader p={0}>
      <Heading as="p" size="md" p={4}>
        {title}
      </Heading>
      <Flex bgColor="background.subtle" p={4} justify="space-between" fontFamily="body">
        <Flex alignItems="center" columnGap={1}>
          <PetAvatar
            species={pet?.species}
            name={pet?.name}
            photoUrl={pet?.photoUrl}
            isDeceased={pet?.computedIsDeceased}
            isActive={pet?.computedIsActive}
            size="lg"
          />
          <Flex direction="column">
            <Text as="p" size="lg" fontWeight="semibold">
              {!!pet?.name ? pet.name : ''}
            </Text>
            <Flex alignItems="center">
              <CareBadge isCareMember paymentStatus={enrollment?.careStripeSubscription?.paymentStatus} />
              <Text size="sm" ml={1} fontWeight="normal">
                {enrollment?.plan.flowAlias || enrollment?.plan.title}
              </Text>
            </Flex>
          </Flex>
        </Flex>
        {originalInvoiceCost !== undefined && (
          <Flex direction="column">
            <Text size="xs">Original</Text>
            <Text data-testid="wizard-original-amount" size="sm">
              {formatMoney(originalInvoiceCost * 100)}
            </Text>
          </Flex>
        )}
        {totalInvoiceSavings !== undefined && (
          <Flex direction="column">
            <Text size="xs">Savings</Text>
            <Text data-testid="wizard-savings-amount" size="sm">
              {formatMoney(totalInvoiceSavings * 100)}
            </Text>
          </Flex>
        )}
        {paymentDue !== undefined && (
          <Flex direction="column">
            <Text size="xs">Payment Due</Text>
            <Text data-testid="wizard-payment-due" size="sm" fontWeight="bold">
              {formatMoney(paymentDue * 100)}
            </Text>
          </Flex>
        )}
      </Flex>
      {isLastStepAndFFEnabled === false && (
        <Text p={6} fontWeight="bold" size="xs">
          {`${adjustedCurrentStep}/${totalSteps}`}
        </Text>
      )}
    </ModalHeader>
  );
};

export default PetInfoHeader;
