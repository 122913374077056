import React, { ReactNode } from 'react';
import useFeatureFlag from 'shared/hooks/useFeatureFlag';
import { FeatureFlagName } from '../../enums/FeatureFlagName';

interface IHasFeatureProps {
  children: ReactNode;
  name: FeatureFlagName | string;
}
const HasFeature = ({ name, children }: IHasFeatureProps): JSX.Element | null => {
  const { isFeatureEnabled, isFeatureFlagsLoading } = useFeatureFlag();

  if (!isFeatureFlagsLoading && isFeatureEnabled(name)) return <>{children}</>;

  return null;
};

export default HasFeature;
