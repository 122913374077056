import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { useParams } from 'react-router-dom';
import ChannelList from './components/ChannelList';
import ChannelView from './components/ChannelView';
import { useInAppNotificationsProvider } from 'shared/providers/InAppNotificationsProvider';
import { useResolutionProvider } from 'shared/providers/ResolutionProvider';
import removeExpiredConversationDrafts from './utils/removeExpiredConversationDrafts';
import { IPopover } from 'shared/providers/PopoverWindowProvider/interfaces/IPopover';
import { usePopover } from 'shared/providers/PopoverWindowProvider';

interface IConversationsContainerProps {
  isTouchDevice?: boolean;
  popover?: IPopover;
}

const Conversations = (): JSX.Element => {
  const { channelId } = useParams<{ channelId: string }>();
  const { isMobile, isTouchDevice } = useResolutionProvider();
  const { setExcludedChannelIds } = useInAppNotificationsProvider();
  const { popover } = usePopover();

  // On initial page load, check for and remove expired draft messages in local storage
  useEffect(() => {
    removeExpiredConversationDrafts();
  }, []);

  // Ensure user doesn't get notifications for the current channel
  useEffect(() => {
    if (channelId) setExcludedChannelIds([channelId]);
    return (): void => setExcludedChannelIds([]);
  }, [channelId, setExcludedChannelIds]);

  const showChannelList = !isMobile || !channelId;
  const showChannelView = !isMobile || channelId;

  return (
    <ConversationsContainer isTouchDevice={isTouchDevice} popover={popover}>
      {showChannelList ? <ChannelList /> : null}
      {showChannelView && <ChannelView channelId={channelId} />}
    </ConversationsContainer>
  );
};

const ConversationsContainer = styled.div<IConversationsContainerProps>`
  display: flex;
  width: 100%;
  max-width: 100%;
  height: 100%;
  overflow: auto;
`;

export default Conversations;
