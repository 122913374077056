import React, { MouseEvent, useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';
import { useModal } from '@televet/televet-ui';
import {
  ChannelMessageAttachmentType,
  ChannelViewClinicPetFragment,
  ClinicUserClinicFragment,
  GetMessageComposerAttachmentFormTemplateDocument,
} from 'shared/types/graphql';
import { IAttachmentOption } from 'pages/Conversations/interfaces/IAttachmentOption';
import {
  CloseIcon,
  DocumentsOutlineIcon,
  FormsOutlineIcon,
  ImageOutlineIcon,
  PaperClipIcon,
  PdfOutlineIcon,
  VideoCallIcon,
} from 'assets/icons';
import { AvatarGroupSelect } from 'shared/components/Avatars/AvatarGroupSelect';
import { IOption, PopoverPlacement } from 'shared/components/Dropdown';
import { DeviceSizes } from 'shared/enums/DeviceSizes';
import ChatMediaPreview from '../../../Modals/ChatMediaPreview';
import InvoiceAttachmentInput from './Invoice/InvoiceAttachmentInput';
import EditServiceFeeModal from './Invoice/EditServiceFeeModal';
import { ModalNames } from 'shared/enums/ModalNames';
import { Mixpanel, useMixpanelTracking } from 'shared/utils/mixpanel';
import EditFeeItem from './Invoice/EditFeeItem';
import { useApolloClient } from '@apollo/client';
import { GraphQLFetchPolicies } from 'shared/enums/GraphQLFetchPolicies';
import { Tooltip, Text, Icon, Flex, Button } from '@televet/kibble-ui';
import { IChannelDraft } from 'pages/Conversations/interfaces/IChannelDraft';
import useClinicUser from 'shared/hooks/useClinicUser';
import usePaymentWritebackConfig from 'shared/hooks/usePaymentWritebackConfig';
import { useResolutionProvider } from 'shared/providers/ResolutionProvider';
import { RouteBasePaths, SettingsRoutes } from 'routes';
import { useBitwerxData } from 'shared/providers/IntegrationsProvider';
import { FeatureFlagName } from 'shared/enums';
import useFeatureFlag from 'shared/hooks/useFeatureFlag';

interface IAttachmentProps {
  attachment: IAttachmentOption;
  linkedPets: ChannelViewClinicPetFragment[];
  clinic?: ClinicUserClinicFragment | null;
  inputTextareaRef: React.MutableRefObject<HTMLTextAreaElement | null>;
  channelDraft: IChannelDraft;
  setChannelDraft: (channelDraft: Partial<IChannelDraft>) => void;
  invoiceAmountInputRef: React.MutableRefObject<HTMLInputElement | null>;
  onAttachmentChange: (attachment: IAttachmentOption) => void;
  onAttachmentRemove: (attachment: IAttachmentOption) => void;
  setPreviewData: (fileUrl: string, attachmentType: ChannelMessageAttachmentType, fileName: string) => void;
  setCurrentInvoiceId: (invoiceId: string) => void;
  setAttachmentFeePercent: (percent: number) => void;
  setHasPotentialBenefits: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLinkPetToFormModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedFormAttachment: React.Dispatch<React.SetStateAction<IAttachmentOption | undefined>>;
  onOpenFormFieldModalOpen: () => void;
}

const MessageComposerAttachment = ({
  attachment,
  linkedPets,
  clinic,
  inputTextareaRef,
  invoiceAmountInputRef,
  onAttachmentChange,
  onAttachmentRemove,
  setPreviewData,
  setCurrentInvoiceId,
  setAttachmentFeePercent,
  setHasPotentialBenefits,
  setIsLinkPetToFormModalOpen,
  setSelectedFormAttachment,
  onOpenFormFieldModalOpen,
}: IAttachmentProps): JSX.Element => {
  const clinicSettings = clinic?.clinicSetting;
  const { hasAdminRoleAtCurrentClinic } = useClinicUser();
  const { isMobile } = useResolutionProvider();
  const { needsBitwerxForPaymentWritebacks } = useBitwerxData();
  const { track, events } = useMixpanelTracking('Invoicing');
  const { openModal, closeModal } = useModal();
  const {
    file,
    value: { attachmentType, filename, entityId },
    text,
  } = attachment;
  const isMedia =
    attachmentType === ChannelMessageAttachmentType.Image || attachmentType === ChannelMessageAttachmentType.Video;
  const apolloClient = useApolloClient();
  const {
    isComplete: writebackConfigComplete,
    isValid: writebackConfigValid,
    isClinicWritebackEnabled,
    isWritebackManualPaymentEnabled,
  } = usePaymentWritebackConfig();

  const isManualAmount = useMemo(
    () =>
      !attachment.invoiceIds?.length &&
      !attachment.isBalanceSelected &&
      parseFloat(attachment.invoiceAmount || '0') > 0,
    [attachment.isBalanceSelected, attachment.invoiceAmount, attachment.invoiceIds?.length],
  );

  // only show in writeback capable clinic, with PIMS client, and with manual amount (since writeback is assumed for invoice/balance)
  const showWritebackEnabledStatus = useMemo(
    () => isClinicWritebackEnabled && linkedPets.some((pet) => pet.pimsId) && isManualAmount,
    [isClinicWritebackEnabled, isManualAmount, linkedPets],
  );

  const { isFeatureEnabled } = useFeatureFlag();

  const fetchFormAttachment = useCallback(async () => {
    const result = await apolloClient.query({
      query: GetMessageComposerAttachmentFormTemplateDocument,
      variables: {
        where: {
          clinicId: {
            equals: clinic?.id,
          },
          id: {
            equals: entityId,
          },
        },
      },
      fetchPolicy: GraphQLFetchPolicies.NetworkOnly,
    });

    onAttachmentChange({
      ...attachment,
      text: result.data.findFirstFormTemplate.title,
      formTemplateContent: result.data.findFirstFormTemplate.content,
    });
  }, [apolloClient, attachment, clinic?.id, entityId, onAttachmentChange]);

  if (attachmentType === ChannelMessageAttachmentType.FormRequest && !text && clinic?.id) {
    fetchFormAttachment();
  }

  const removePetFromAttachment = (e: MouseEvent, option?: IOption): void => {
    e.stopPropagation();
    e.preventDefault();
    if (attachment.clinicPetIds?.includes(option?.value.id)) {
      attachment.clinicPetIds = attachment.clinicPetIds.filter((id) => id !== option?.value.id);
    }
    onAttachmentChange({
      ...attachment,
    });
  };

  const avatarGroupOptions = useMemo(() => {
    if (attachmentType !== ChannelMessageAttachmentType.FormRequest) return [];
    const petOptions = linkedPets.map((pet) => ({
      text: pet.name,
      value: {
        id: pet.id,
        firstName: pet.name,
      },
      isSelected: attachment.clinicPetIds?.includes(pet.id),
    }));
    return [...petOptions];
  }, [linkedPets, attachment, attachmentType]);

  useEffect(() => {
    if (
      attachment?.clinicPetIds === undefined &&
      linkedPets.length === 1 &&
      attachmentType === ChannelMessageAttachmentType.FormRequest
    ) {
      onAttachmentChange({
        ...attachment,
        clinicPetIds: [linkedPets[0].id],
      });
      setIsLinkPetToFormModalOpen(false);
    }
  }, [linkedPets, attachment, onAttachmentChange, setIsLinkPetToFormModalOpen, attachmentType]);
  /**
   * Set the default clientServiceFeePercent on the attachment if available
   * Dep is ignored to allow updating of attachment once to set the fee
   */
  useEffect(() => {
    if(isFeatureEnabled(FeatureFlagName.TerminalAppOnlySurchargeUpdates)) {
      onAttachmentChange({
        ...attachment,
        clientServiceFeePercent: 0,
      });
      return;
    }
    if (clinicSettings?.paymentFeeConfig && clinicSettings?.hasCustomizableFees) {
      onAttachmentChange({
        ...attachment,
        clientServiceFeePercent: clinicSettings.paymentFeeConfig.onlineClientServiceFeePercent,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinicSettings, onAttachmentChange, isFeatureEnabled]);

  /**
   * Formatted service fee percentage
   */
  const serviceFeePercentage = useMemo(() => {
    let percent = 0;
    if (isFeatureEnabled(FeatureFlagName.TerminalAppOnlySurchargeUpdates)) {
      return 0;
    }
    if (attachment?.clientServiceFeePercent && !isFeatureEnabled(FeatureFlagName.TerminalAppOnlySurchargeUpdates)) {
      percent = attachment?.clientServiceFeePercent * 100;
    }
    if(!isFeatureEnabled(FeatureFlagName.TerminalAppOnlySurchargeUpdates)) {
        setAttachmentFeePercent(percent);
    }

    return percent.toFixed(2);
  }, [attachment, setAttachmentFeePercent, isFeatureEnabled]);

  /**
   * Calculate client service fee
   */
  const clientServiceFee = useMemo(() => {
    if (!attachment?.clientServiceFeePercent || isFeatureEnabled(FeatureFlagName.TerminalAppOnlySurchargeUpdates))
      return 0;
    const invoiceAmount = Number(attachment?.invoiceAmount);
    if (isNaN(invoiceAmount)) return '--.--';

    return (invoiceAmount * attachment.clientServiceFeePercent).toFixed(2);
  }, [attachment, isFeatureEnabled]);

  const showWritebackConfigurationWarning = useMemo(() => {
    // 1. is not on mobile since settings page is not exposed on mobile
    // 2. needs bitwerx OR has bitwerx but writeback config is incomplete OR writeback config is invalid
    // 3. clinic PIMS can support writebacks
    return (
      !isMobile &&
      (needsBitwerxForPaymentWritebacks || !writebackConfigComplete || !writebackConfigValid) &&
      isClinicWritebackEnabled
    );
  }, [
    writebackConfigComplete,
    writebackConfigValid,
    isMobile,
    needsBitwerxForPaymentWritebacks,
    isClinicWritebackEnabled,
  ]);

  const removeAttachment = useCallback(() => {
    onAttachmentRemove(attachment);
    setHasPotentialBenefits(false);
    if (attachment.isFillInTheBlankForm) {
      setSelectedFormAttachment(undefined);
    }
    inputTextareaRef?.current?.focus();
  }, [onAttachmentRemove, attachment, setHasPotentialBenefits, inputTextareaRef, setSelectedFormAttachment]);

  const handleEditFormClick = useCallback((): void => {
    setSelectedFormAttachment(attachment);
    onOpenFormFieldModalOpen();
  }, [attachment, onOpenFormFieldModalOpen, setSelectedFormAttachment]);

  const goToPaymentSettings = useCallback((): void => {
    track(events.PaymentIntegrationInitiatedMessageComposer.name);
    window.location.href = `${RouteBasePaths.Settings}${SettingsRoutes.ClinicPayment}`;
  }, [track, events]);

  const renderTypeIcon = (attachmentType: ChannelMessageAttachmentType): JSX.Element | null => {
    if (attachmentType === ChannelMessageAttachmentType.Image || attachmentType === ChannelMessageAttachmentType.Video)
      return (
        <IconContainer>
          <ImageOutlineIcon />
        </IconContainer>
      );
    if (attachmentType === ChannelMessageAttachmentType.FormRequest)
      return (
        <IconContainer>
          <FormsOutlineIcon />
        </IconContainer>
      );

    if (attachmentType === ChannelMessageAttachmentType.File) {
      const fileType = file?.type;
      if (fileType?.includes('pdf')) {
        return (
          <IconContainer>
            <PdfOutlineIcon />
          </IconContainer>
        );
      } else if (fileType?.includes('word')) {
        return (
          <IconContainer>
            <DocumentsOutlineIcon />
          </IconContainer>
        );
      } else {
        return (
          <IconContainer>
            <PaperClipIcon />
          </IconContainer>
        );
      }
    }
    return null;
  };

  const RemoveAttachment = (): JSX.Element => {
    return (
      <Tooltip label="Remove attachment" placement="top">
        <StyledCloseIcon onClick={removeAttachment} />
      </Tooltip>
    );
  };

  const renderAttachment = (): JSX.Element => {
    const fileURL = !!file?.name ? URL.createObjectURL(file) : '';
    switch (attachmentType) {
      case ChannelMessageAttachmentType.Invoice:
        return (
          <>
            <Flex
              data-testid="invoice-attachment-container"
              position="relative"
              direction="column"
              alignItems="stretch"
              justifyContent="flex-start"
              borderWidth="1px"
              borderRadius="lg"
              borderColor="border.default"
              m={2}
              px={2}
              py={1}
            >
              <Flex>
                <RemoveAttachment />
                <InvoiceAttachmentInput
                  attachment={attachment}
                  onAttachmentChange={onAttachmentChange}
                  onAttachmentRemove={removeAttachment}
                  setCurrentInvoiceId={setCurrentInvoiceId}
                  invoiceAmountInputRef={invoiceAmountInputRef}
                />
              </Flex>
              {showWritebackEnabledStatus && (
                <Flex mt={1} alignItems="center" justifyContent="center" width="100%">
                  {isWritebackManualPaymentEnabled && <Icon size="xs" name="transfer" />}
                  <Text size="xs" ml={2}>{`Writeback to account ${
                    isWritebackManualPaymentEnabled ? 'enabled' : 'disabled'
                  }`}</Text>
                </Flex>
              )}
            </Flex>
            {clinicSettings?.hasCustomizableFees && !isFeatureEnabled(FeatureFlagName.TerminalAppOnlySurchargeUpdates) && (
              <EditFeeItem
                serviceFeePercentage={serviceFeePercentage}
                clientServiceFee={clientServiceFee}
                callToActionFunction={(): void => {
                  Mixpanel.track('Edit invoice fee clicked');
                  openModal(ModalNames.EditServiceFee);
                }}
              />
            )}
            {showWritebackConfigurationWarning && (
              <Flex ml={2} direction="column">
                <Flex direction="row">
                  <Flex alignSelf="center">
                    <Icon size="xs" variant="warning" name="warningSign" />
                  </Flex>
                  <Text size="xs" variant="warning" fontWeight="bold" ml={1}>
                    Payment Configuration Incomplete
                    {hasAdminRoleAtCurrentClinic && (
                      <Text
                        variant="warning"
                        cursor="pointer"
                        onClick={goToPaymentSettings}
                        textDecoration="underline"
                        ml={1}
                      >
                        Go to settings
                      </Text>
                    )}
                  </Text>
                </Flex>
                {!hasAdminRoleAtCurrentClinic && (
                  <Text ml={4} pl={0.5} size="xs" variant="warning">
                    Please contact an administrator to fix invalid payments configuration for writebacks to succeed.
                  </Text>
                )}
              </Flex>
            )}
          </>
        );
      case ChannelMessageAttachmentType.CallRequest:
        return (
          <AttachmentContainer>
            <RemoveAttachment />
            <Attachment>
              <IconContainer>
                <VideoCallIcon />
              </IconContainer>
              <AttachmentNameContainer>{text}</AttachmentNameContainer>
            </Attachment>
          </AttachmentContainer>
        );
      case ChannelMessageAttachmentType.File:
      default:
        return (
          <AttachmentContainer>
            <RemoveAttachment />
            <Attachment>
              <AttachmentNameContainer>
                {file ? (
                  <ChatMediaPreview
                    attachment={attachment}
                    onAttachmentRemove={onAttachmentRemove}
                    key={filename}
                    stagedFile={file}
                    isMedia={isMedia}
                    renderTypeIcon={(): JSX.Element | null => renderTypeIcon(attachmentType)}
                    setPreviewData={setPreviewData}
                  />
                ) : (
                  renderTypeIcon(ChannelMessageAttachmentType.FormRequest)
                )}
                <TruncatedTextContainer
                  hasFile={!!file}
                  onClick={(): void => {
                    if (!!file) setPreviewData(fileURL, attachmentType, file.name);
                  }}
                >
                  <Text noOfLines={2}>{text || filename || ''}</Text>
                </TruncatedTextContainer>
              </AttachmentNameContainer>
              {attachmentType === ChannelMessageAttachmentType.FormRequest && linkedPets.length !== 0 && (
                <PetSelect onClick={(): void => setIsLinkPetToFormModalOpen(true)}>
                  <AvatarGroupSelect
                    isAddingAvatar={true}
                    hideDropdownOption={true}
                    options={avatarGroupOptions}
                    avatarSize="sm"
                    onToggle={removePetFromAttachment}
                    popoverPlacement={PopoverPlacement.TopStart}
                    addButtonTooltip="Link to patient (optional)"
                  />
                </PetSelect>
              )}
              {attachmentType === ChannelMessageAttachmentType.FormRequest && attachment.isFillInTheBlankForm && (
                <Button size="sm" onClick={handleEditFormClick}>
                  Edit
                </Button>
              )}
            </Attachment>
          </AttachmentContainer>
        );
    }
  };

  return (
    <>
      {renderAttachment()}
      <EditServiceFeeModal
        processingFeePercent={clinicSettings?.paymentFeeConfig?.onlineProcessingFeePercent}
        attachment={attachment}
        onCancel={closeModal}
        onAttachmentChange={onAttachmentChange}
      />
    </>
  );
};

export default MessageComposerAttachment;

const AttachmentContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  position: relative;
  height: 64px;
  border: 1px solid #bbb;
  border-radius: 10px;
  margin: 8px;
  padding: 4px 8px;
  background-color: var(--chakra-colors-background-default);
`;

const AttachmentNameContainer = styled.div`
  flex: 0 1 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
`;

interface IStageFileProps {
  hasFile: boolean;
}

const TruncatedTextContainer = styled.div<IStageFileProps>`
  padding: 0 10px 0 5px;
  &:hover {
    text-decoration: ${({ hasFile }): string => (hasFile ? 'underline' : 'inherit')};
    cursor: ${({ hasFile }): string => (hasFile ? 'pointer' : 'inherit')};
  }
  width: 180px;
  max-height: 48px;
  @media (${DeviceSizes.MobileMaxWidth}) {
    width: 120px;
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: -6px;
  right: -6px;
  width: 12px;
  height: 12px;
  padding: 2px;
  cursor: pointer;
  border-radius: 50%;
  background-color: rgb(5, 15, 95);
  z-index: 1;
  opacity: 0;

  @media (${DeviceSizes.TabletMaxWidth}) {
    opacity: 1;
    width: 20px;
    height: 20px;
    top: -7px;
    right: -7px;
  }

  ${AttachmentContainer}:hover &, [data-testid="invoice-attachment-container"]:hover & {
    opacity: 1;
  }

  & * {
    fill: #fff;
  }

  &:hover,
  &:hover svg path {
    transform: scale(1.1);
  }
`;

const IconContainer = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 100%;
  cursor: pointer;
  margin-right: 4px;

  & svg {
    max-width: 20px;
    width: 20px;
    max-height: 20px;
  }

  & svg * {
    fill: #575d7c;
  }
`;

const PetSelect = styled.div``;

const Attachment = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  position: relative;

  & ${PetSelect} {
    margin-right: 8px;
  }
`;
