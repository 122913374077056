import React, { ReactNode, useCallback, useMemo } from 'react';
import { Box, Select, SelectOptionProps } from '@televet/kibble-ui';
import useClinicUser from 'shared/hooks/useClinicUser';
import { ChannelStatusAction, useGetConversationStatusOptionsQuery } from 'shared/types/graphql';
import { FormControlSize } from '@televet/kibble-ui/build/shared/types/formControl';

export enum ConversationStatusSelectValue {
  NoChange = 'noChange',
}

export const channelStatusActionLabels: Record<ChannelStatusAction, string> = {
  [ChannelStatusAction.Active]: 'Active',
  [ChannelStatusAction.Inactive]: 'Closed',
  [ChannelStatusAction.InactivePermanently]: 'Archived',
  [ChannelStatusAction.Migrated]: 'Migrated', // Deprecated; originally used for PPC migration
};

interface ConversationStatusSelectProps {
  label?: ReactNode;
  size?: FormControlSize;
  placeholder?: string;
  menuHeight?: string;
  value?: string | null;
  onStatusChange: (conversationStatusId: string | null) => void;
}

interface SwatchProps {
  bgColor?: string;
  borderColor?: string;
}

const Swatch = ({ bgColor = 'transparent', borderColor = 'transparent' }: SwatchProps): JSX.Element => {
  return <Box borderRadius="base" w={4} h={4} bg={bgColor} border="1px" borderColor={borderColor} />;
};

export const ConversationStatusSelect = ({
  label,
  placeholder,
  size = 'md',
  menuHeight = '220px',
  value = ConversationStatusSelectValue.NoChange,
  onStatusChange,
}: ConversationStatusSelectProps): JSX.Element => {
  const { currentClinicId } = useClinicUser();

  const { data: channelStatusData } = useGetConversationStatusOptionsQuery({
    variables: {
      clinicId: currentClinicId || '',
    },
    skip: !currentClinicId,
  });

  const conversationStatusOptions = useMemo((): SelectOptionProps[] => {
    if (channelStatusData?.findManyChannelStatus) {
      return channelStatusData.findManyChannelStatus.map((channelStatus): SelectOptionProps => {
        return {
          label: channelStatus?.name,
          value: channelStatus?.id,
          leftElement: <Swatch bgColor={channelStatus.color} />,
          groupLabel: channelStatusActionLabels[channelStatus.channelStatusAction],
        };
      });
    }

    return [];
  }, [channelStatusData]);

  const mappedConversationStatusValue = useMemo(() => {
    const availableStatus = conversationStatusOptions.find((option) => option.value === value);

    if (availableStatus) {
      return availableStatus.value;
    }

    return ConversationStatusSelectValue.NoChange;
  }, [value, conversationStatusOptions]);

  const handleStatusChange = useCallback(
    ({ value: conversationStatusId }: SelectOptionProps) => {
      onStatusChange(
        conversationStatusId === ConversationStatusSelectValue.NoChange || !conversationStatusId
          ? null
          : conversationStatusId,
      );
    },
    [onStatusChange],
  );

  return (
    <Select
      className="ConverstationStatus__Select"
      label={label ? label : ''}
      placeholder={placeholder}
      size={size}
      value={mappedConversationStatusValue}
      options={[
        {
          label: 'No change',
          value: ConversationStatusSelectValue.NoChange,
          groupLabel: 'Active',
          leftElement: <Swatch borderColor="border.default" />,
          isSelected: true,
        },
        ...conversationStatusOptions,
      ]}
      listProps={{
        isGrouped: true,
        onSelect: handleStatusChange,
        isSearchable: true,
        height: menuHeight,
        maxW: '100%',
        zIndex: '2',
      }}
    />
  );
};
