import React, { useMemo, useCallback } from 'react';
import {
  Icon,
  Select,
  useDisclosure,
  VStack,
  HStack,
  Text,
  Tooltip,
  Alert,
  Center,
  Box,
  SelectOptionProps,
} from '@televet/kibble-ui';
import {
  AutomationRunActionPromptType,
  useGetAutomationsListQuery,
  useGetGlobalAutomationsListQuery,
  WorkflowEvent,
  WorkflowEventTriggerType,
} from 'shared/types/graphql';
import { AddNewAutomationModal } from 'pages/Automations/components/Modals';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import {
  updateAutomationPromptButtonErrors,
  updateAutomationPromptErrors,
  updateAutomationPromptButton,
  updateAutomationPromptNextStep,
  updateAutomationPromptFollowupStep,
  updateAutomationPromptConfig,
} from 'pages/Automations/state/automationsSlice';
import partial from 'lodash-es/partial';
import useClinicUser from 'shared/hooks/useClinicUser';
import { childAutomationEventTypes } from 'pages/Automations/state/utils/draft';
import { DELETED } from 'pages/Automations/types/AutomationValidation';
import { GraphQLFetchPolicies } from 'shared/enums';
import { NextStepSelectValue } from 'pages/Automations/types/NextStepSelectValue';
import { AutomationButtonConfig } from 'pages/Automations/types/AutomationPrompt';
import { useNextStepValidation } from 'pages/Automations/hooks/useNextStepValidation';
import { useGetNextStepDisplayProps } from 'pages/Automations/hooks/useGetNextStepDisplayProps';
import { getStepValueFromConfig } from 'pages/Automations/utils/getStepValueFromConfig';
import { FeatureFlagName } from 'shared/enums';
import useFeatureFlag from 'shared/hooks/useFeatureFlag';
import { isConditionalChildAutomation } from 'pages/Automations/utils/isConditionalChildAutomation';

interface NextStepSelectProps {
  promptIndex: number;
  buttonIndex?: number;
}

export const NextStepSelect = ({ promptIndex, buttonIndex }: NextStepSelectProps): JSX.Element => {
  const { isFeatureEnabled } = useFeatureFlag();
  const isGlobalEditor = useAppSelector((state) => state.automations.isGlobalEditor);
  const prompt = useAppSelector((state) => state.automations.automationDraft.prompts[promptIndex]);
  const { promptType } = prompt;

  const isStatementPrompt = useMemo(() => {
    return promptType === AutomationRunActionPromptType.Statement;
  }, [promptType]);

  const isButtonPrompt = useMemo(() => {
    return promptType === AutomationRunActionPromptType.Buttons;
  }, [promptType]);

  const dispatch = useAppDispatch();
  const addNewAutomationModal = useDisclosure();
  const { currentClinicId } = useClinicUser();

  const { fieldNextStepIsInvalid, nextStepErrorText, fieldFollowupStepIsInvalid, followupStepErrorText } =
    useNextStepValidation({
      promptIndex,
      buttonIndex,
      isButtonPrompt,
      skipValidation: isButtonPrompt, // For button prompts, validation is already triggered from the Buttons prompt field component
    });

  const button = useMemo(
    () => (typeof buttonIndex === 'number' ? prompt.config.buttons?.[buttonIndex] : undefined),
    [buttonIndex, prompt],
  );
  const sendButtonMessage = button?.doNothing;

  const nextStepTriggerActionNumber =
    isButtonPrompt && button ? button.workflowEventTriggerActionNumber : prompt.triggerActionNumber;
  const nextStepTriggerWorkflowId =
    isButtonPrompt && button
      ? button.nextWorkflowEventTriggerSettingId
      : prompt.config.nextWorkflowEventTriggerSettingId;
  const followupStepTriggerActionNumber =
    isButtonPrompt && button
      ? button.conditionalNextStepConfig?.fallbackNextActionNumber
      : prompt.config.conditionalNextStepConfig?.fallbackNextActionNumber;
  const followupStepTriggerWorkflowId =
    isButtonPrompt && button
      ? button.conditionalNextStepConfig?.fallbackNextWorkflowEventSettingId
      : prompt.config.conditionalNextStepConfig?.fallbackNextWorkflowEventSettingId;

  const eventTypes = useMemo(() => {
    const types = [...childAutomationEventTypes];

    // Add for H2H automations
    if (isGlobalEditor) {
      types.push(WorkflowEvent.InvoiceLineItemCreated);
    }

    return types;
  }, [isGlobalEditor]);

  // TODO: Create seperate lazy query for this list and only call when the menu is opened (see comment here: https://github.com/TeleVet/clinic-web/pull/1663/files/512d1fb1fe2d97be26274238902ed0dd2889505e#r1060984205)
  const { data: childAutomationsData } = useGetAutomationsListQuery({
    variables: {
      clinicId: currentClinicId || '',
      triggerType: WorkflowEventTriggerType.Manual,
      inEventType: eventTypes as WorkflowEvent[],
    },
    skip: !currentClinicId || isGlobalEditor,
    fetchPolicy: GraphQLFetchPolicies.CacheAndNetwork,
  });

  const { data: globalChildAutomationsData } = useGetGlobalAutomationsListQuery({
    variables: {
      triggerType: WorkflowEventTriggerType.Manual,
      inEventType: eventTypes as WorkflowEvent[],
    },
    skip: !isGlobalEditor,
    fetchPolicy: GraphQLFetchPolicies.CacheAndNetwork,
  });

  const childAutomations = useMemo(
    () =>
      isGlobalEditor
        ? globalChildAutomationsData?.findManyWorkflowEventSetting || []
        : childAutomationsData?.findManyWorkflowEventSetting || [],
    [childAutomationsData, globalChildAutomationsData, isGlobalEditor],
  );

  const handleSelect = useCallback(
    ({
      value,
      isConditionalChild,
      clearValidation = true,
      isFollowupStep,
    }: {
      value: NextStepSelectValue | string;
      isConditionalChild?: boolean;
      clearValidation?: boolean;
      isFollowupStep?: boolean;
    }): void => {
      let triggerActionNumber: number | null = null;
      let triggerWorkflowId: string | null = null;

      if (!value) {
        return;
      }

      if (value === NextStepSelectValue.NewAutomation) {
        addNewAutomationModal.onOpen();
        return;
      }

      const isChildAutomationSelected = !Object.values(NextStepSelectValue).includes(value as NextStepSelectValue);
      if (isChildAutomationSelected) {
        triggerWorkflowId = value;
        triggerActionNumber = null;
      } else if (value === NextStepSelectValue.NextPrompt) {
        triggerWorkflowId = null;
        triggerActionNumber = promptIndex + 2;
      }

      if (isButtonPrompt && buttonIndex !== undefined) {
        const buttonConfig: Partial<AutomationButtonConfig> = isFollowupStep
          ? {
              conditionalNextStepConfig: {
                fallbackNextActionNumber: triggerActionNumber,
                fallbackNextWorkflowEventSettingId: triggerWorkflowId,
              },
            }
          : {
              workflowEventTriggerActionNumber: triggerActionNumber,
              nextWorkflowEventTriggerSettingId: triggerWorkflowId,
              doNothing: undefined, // This field is deprecated, so we ensure the user can't set it in the new builder
              conditionalNextStepConfig: isConditionalChild
                ? {
                    fallbackNextActionNumber: nextStepTriggerActionNumber,
                    fallbackNextWorkflowEventSettingId: nextStepTriggerWorkflowId,
                  }
                : undefined,
            };
        dispatch(
          updateAutomationPromptButton({
            promptIndex,
            buttonIndex,
            button: buttonConfig,
          }),
        );

        if ((fieldNextStepIsInvalid || fieldFollowupStepIsInvalid) && clearValidation) {
          dispatch(
            updateAutomationPromptButtonErrors({
              promptIndex,
              buttonIndex,
              button: { [isFollowupStep ? 'followupStep' : 'nextStep']: [] },
            }),
          );
        }
      } else {
        if (isFollowupStep) {
          dispatch(
            updateAutomationPromptFollowupStep({
              promptIndex,
              fallbackNextActionNumber: triggerActionNumber,
              fallbackNextWorkflowEventSettingId: triggerWorkflowId,
            }),
          );
        } else {
          dispatch(
            updateAutomationPromptNextStep({
              promptIndex,
              triggerActionNumber,
              triggerWorkflowId,
            }),
          );
          if (isConditionalChild) {
            dispatch(
              updateAutomationPromptFollowupStep({
                promptIndex,
                // TODO (future PR): Address case when switching from one conditional child to another, though this is not possible today
                fallbackNextActionNumber: nextStepTriggerActionNumber,
                fallbackNextWorkflowEventSettingId: nextStepTriggerWorkflowId,
              }),
            );
          } else {
            dispatch(updateAutomationPromptConfig({ promptIndex, config: { conditionalNextStepConfig: undefined } }));
          }
        }

        if ((fieldNextStepIsInvalid || fieldFollowupStepIsInvalid) && clearValidation) {
          dispatch(
            updateAutomationPromptErrors({
              promptIndex,
              prompt: { [isFollowupStep ? 'followupStep' : 'nextStep']: [] },
            }),
          );
        }
      }
    },
    [
      promptIndex,
      fieldNextStepIsInvalid,
      fieldFollowupStepIsInvalid,
      dispatch,
      buttonIndex,
      isButtonPrompt,
      nextStepTriggerActionNumber,
      nextStepTriggerWorkflowId,
      addNewAutomationModal,
    ],
  );

  const nextStepValue = useMemo(
    () =>
      getStepValueFromConfig({
        actionNumber: nextStepTriggerActionNumber,
        workflowId: nextStepTriggerWorkflowId,
        isStatementPrompt,
        sendButtonMessage,
      }),
    [isStatementPrompt, nextStepTriggerActionNumber, nextStepTriggerWorkflowId, sendButtonMessage],
  );

  const followupStepValue = useMemo(
    () =>
      getStepValueFromConfig({
        actionNumber: followupStepTriggerActionNumber,
        workflowId: followupStepTriggerWorkflowId,
        isStatementPrompt,
      }),
    [followupStepTriggerActionNumber, followupStepTriggerWorkflowId, isStatementPrompt],
  );

  // Show the follow-up step input if the selected automation is set to "Evaluate" and has at least one Condition
  const showFollowupStep = useMemo(() => {
    const nextStepAutomation = childAutomations?.find(
      ({ id }) => id === nextStepTriggerWorkflowId,
    );
    return isConditionalChildAutomation(nextStepAutomation);
  }, [childAutomations, nextStepTriggerWorkflowId]);

  const { options: nextStepDisplayOptions, isLoading: isLoadingNextStepOptions } = useGetNextStepDisplayProps({
    promptType,
  });

  const baseOptions: SelectOptionProps[] = useMemo(() => {
    return nextStepDisplayOptions
      .filter(
        (option) =>
          !(
            option.isConditionalChild && !isFeatureEnabled(FeatureFlagName.AutomationsConditionalChildOptionVisibility)
          ) && !(option.isConditionalChild && !isButtonPrompt),
      )
      .map((option): SelectOptionProps => {
        return {
          ...option,
          leftElement: option.iconName && <Icon size="sm" name={option.iconName} />,
          onSelect: partial(handleSelect, { value: option.value, isConditionalChild: option.isConditionalChild }),
        };
      });
  }, [nextStepDisplayOptions, isButtonPrompt, isFeatureEnabled, handleSelect]);

  const nextStepOptions: SelectOptionProps[] = useMemo(() => {
    const isAutomationDeleted = !baseOptions.some(({ value }) => value === nextStepValue);
    if (isAutomationDeleted && !isLoadingNextStepOptions) {
      if (nextStepValue !== DELETED) {
        handleSelect({ value: DELETED, clearValidation: false });
      }
      return baseOptions.concat({
        label: '[Deleted Automation]',
        value: DELETED,
        isDisabled: true,
        leftElement: <Icon name="chainLink" size="sm" />,
      });
    }
    return baseOptions;
  }, [nextStepValue, isLoadingNextStepOptions, baseOptions, handleSelect]);

  const followupStepOptions: SelectOptionProps[] = useMemo(() => {
    const options = baseOptions
      .filter((option) =>
        [NextStepSelectValue.NextPrompt, NextStepSelectValue.EndAutomation].includes(
          option.value as NextStepSelectValue,
        ),
      )
      .map((option) => {
        return {
          ...option,
          onSelect: partial(handleSelect, { value: option.value || '', isFollowupStep: true }),
        };
      });

    const isAutomationDeleted = !baseOptions.some(({ value }) => value === followupStepValue);
    if (isAutomationDeleted && !isLoadingNextStepOptions) {
      if (followupStepValue !== DELETED) {
        handleSelect({ value: DELETED, clearValidation: false, isFollowupStep: true });
      }
      return options.concat({
        label: '[Deleted Automation]',
        value: DELETED,
        isDisabled: true,
        leftElement: <Icon name="chainLink" size="sm" />,
        onSelect: () => {
          null;
        },
      });
    }

    return options;
  }, [baseOptions, isLoadingNextStepOptions, followupStepValue, handleSelect]);

  return (
    <>
      <VStack spacing={4} align="stretch">
        <Box>
          <Select
            className="Automations__Select-NextStep"
            value={nextStepValue}
            label="Next Step"
            errorText={nextStepErrorText}
            isInvalid={fieldNextStepIsInvalid}
            options={nextStepOptions}
            listProps={{
              isSearchable: !isStatementPrompt,
              maxW: '276px',
              zIndex: '3',
            }}
          />
          {showFollowupStep && (
            <Alert
              mt={2}
              status="info"
              hideIcon={true}
              hideCloseButton={true}
              description="You've selected a conditional automation. Clients will only see it if they meet the conditions set within that automation."
            />
          )}
        </Box>
        {showFollowupStep && (
          <Select
            className="Automations__Select-FollowUpStep"
            value={followupStepValue}
            label={
              <HStack spacing={1}>
                <Text>Follow-up Step</Text>
                <Tooltip
                  label="Follow-up Step will occur after the conditional automation."
                  placement="right"
                  titleCaseLabel={false}
                >
                  <Center>
                    <Icon name="infoCircle" size="xs" />
                  </Center>
                </Tooltip>
              </HStack>
            }
            errorText={followupStepErrorText}
            isInvalid={fieldFollowupStepIsInvalid}
            options={followupStepOptions}
            listProps={{
              isSearchable: !isStatementPrompt,
              maxW: '276px',
              zIndex: '2',
            }}
          />
        )}
      </VStack>
      <AddNewAutomationModal
        handleUpdateSelection={handleSelect}
        isOpen={addNewAutomationModal.isOpen}
        onClose={addNewAutomationModal.onClose}
      />
    </>
  );
};
