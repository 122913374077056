import CampaignStatus from './CampaignStatusTag';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { Icon, IconName } from '@televet/kibble-ui/build/components/Icon';
import { Menu, MenuItemProps } from '@televet/kibble-ui/build/components/Menu';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from '@televet/kibble-ui/build/components/Modal';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { TextInput } from '@televet/kibble-ui/build/components/TextInput';
import { useToast } from '@televet/kibble-ui/build/components/Toast';
import {
  Box,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@televet/kibble-ui/build/chakra';
import format from 'date-fns/format';
import sortBy from 'lodash-es/sortBy';
import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RouteBasePaths } from 'routes';
import useClinicUser from 'shared/hooks/useClinicUser';
import {
  EmailCampaignStatus,
  FindManyEmailCampaignQuery,
  useDuplicateEmailCampaignMutation,
  useUpdateOneEmailCampaignMutation,
} from 'shared/types/graphql';
import { QUICKSIGHT_DASHBOARD_URL } from 'pages/Reporting/QuickSightReports/embedDashboard';
import { quickSightRelativePath } from 'pages/Reporting';

type CampaignListProps = {
  campaigns: FindManyEmailCampaignQuery | undefined;
  refetchEmailCampaigns: () => void;
};

enum SortedBy {
  Name = 'name',
  LastUpdated = 'lastUpdated',
  Status = 'status',
}

enum SortDirection {
  Asc = 'asc',
  Desc = 'desc',
}

const CampaignList = ({ campaigns, refetchEmailCampaigns }: CampaignListProps): JSX.Element => {
  const history = useHistory();

  const [searchByName, setSearchByName] = React.useState('');

  const [sortedBy, setSortedBy] = useState(SortedBy.LastUpdated);
  const [sortDirection, setSortDirection] = useState(SortDirection.Desc);

  const [updateOneEmailCampaign] = useUpdateOneEmailCampaignMutation();

  const [selectedCampaignForDeletion, setSelectedCampaignForDeletion] = useState({
    id: '',
    name: '',
  });
  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();

  const handleDelete = useCallback(
    async (campaignId: string) => {
      const campaign = campaigns?.findManyEmailCampaign?.find((campaign) => campaign.id === campaignId);
      try {
        await updateOneEmailCampaign({
          variables: {
            where: {
              id: campaignId,
            },
            data: {
              deletedAt: new Date().toISOString(),
            },
          },
        });
        toast({
          hideIcon: true,
          title: `'${campaign?.name || 'Campaign'}' deleted.`,
          status: 'info',
        });
      } catch (e) {
        console.error(e);
        toast({
          title: 'Error while deleting campaign',
          status: 'error',
        });
      }
    },
    [campaigns?.findManyEmailCampaign, toast, updateOneEmailCampaign],
  );
  const [duplicateCampaign] = useDuplicateEmailCampaignMutation();
  const { clinicUser } = useClinicUser();
  const userId = clinicUser?.id || '';
  const handleDuplicate = useCallback(
    async (campaignId) => {
      const campaign = campaigns?.findManyEmailCampaign?.find((campaign) => campaign.id === campaignId);
      if (campaign) {
        await duplicateCampaign({
          variables: {
            data:{
              emailCampaignId: campaignId,
              userId
            },
          },
        });
        refetchEmailCampaigns();
      }
    },
    [campaigns?.findManyEmailCampaign, duplicateCampaign, refetchEmailCampaigns, userId],
  );
  const sortedCampaigns = useMemo(() => {
    const campaignList = campaigns?.findManyEmailCampaign;
    if (!campaignList?.length) {
      return [];
    }
    let sortedCampaigns = campaignList;
    switch (sortedBy) {
      case SortedBy.Name:
        sortedCampaigns = sortBy(campaignList, (campaign) => campaign.name.toLowerCase());
        break;
      case SortedBy.LastUpdated:
        sortedCampaigns = sortBy(campaignList, (campaign) => new Date(campaign.updatedAt).valueOf());
        break;
      case SortedBy.Status:
        sortedCampaigns = sortBy(campaignList, (campaign) => campaign.status);
        break;
    }
    if (sortDirection === SortDirection.Desc) {
      return sortedCampaigns.reverse();
    }
    return sortedCampaigns;
  }, [campaigns?.findManyEmailCampaign, sortDirection, sortedBy]);

  const filteredCampaigns = useMemo(() => {
    return sortedCampaigns.filter(
      (campaign) => campaign.name.toLowerCase().includes(searchByName.toLowerCase()) && !campaign.deletedAt,
    );
  }, [searchByName, sortedCampaigns]);

  const getMenuItems = useCallback(
    (campaignId: string, campaignStatus: EmailCampaignStatus) => {
      let items: MenuItemProps[] = [
        {
          label: 'Duplicate',
          leftElement: <Icon size="sm" name="twoSquares" />,
          value: 'duplicate',
          onSelect: (): void => {
            handleDuplicate(campaignId);
          },
        },
      ];
      if (campaignStatus === EmailCampaignStatus.Draft) {
        items.push({
          label: 'Delete',
          leftElement: <Icon size="sm" variant="danger" name="trashcan" />,
          value: 'delete',
          variant: 'danger',
          onSelect: (): void => {
            setSelectedCampaignForDeletion({
              id: campaignId,
              name: campaigns?.findManyEmailCampaign?.find((campaign) => campaign.id === campaignId)?.name || '',
            });
            onOpen();
          },
        });
      }
      if (campaignStatus === EmailCampaignStatus.Sending || campaignStatus === EmailCampaignStatus.Sent) {
        items = [
          {
            label: 'View',
            leftElement: <Icon size="sm" name="eyeOpen" />,
            value: 'send-test-email',
            onSelect: (): void => {
              history.push(`${RouteBasePaths.OttoEmailCampaigns}/${campaignId}/edit-campaign`);
            },
          },
          ...items,
        ];
      }
      return items;
    },
    [campaigns?.findManyEmailCampaign, handleDuplicate, history, onOpen],
  );

  const handleSortByName = useCallback((): void => {
    if (sortedBy === SortedBy.Name) {
      setSortDirection(sortDirection === SortDirection.Desc ? SortDirection.Asc : SortDirection.Desc);
    } else {
      setSortedBy(SortedBy.Name);
      setSortDirection(SortDirection.Asc);
    }
  }, [sortDirection, sortedBy]);
  const handleSortByLastUpdated = useCallback((): void => {
    if (sortedBy === SortedBy.LastUpdated) {
      setSortDirection(sortDirection === SortDirection.Desc ? SortDirection.Asc : SortDirection.Desc);
    } else {
      setSortedBy(SortedBy.LastUpdated);
      setSortDirection(SortDirection.Asc);
    }
  }, [sortDirection, sortedBy]);
  const handleSortByStatus = useCallback((): void => {
    if (sortedBy === SortedBy.Status) {
      setSortDirection(sortDirection === SortDirection.Desc ? SortDirection.Asc : SortDirection.Desc);
    } else {
      setSortedBy(SortedBy.Status);
      setSortDirection(SortDirection.Asc);
    }
  }, [sortDirection, sortedBy]);

  const getSortingIconName = useCallback(
    ({ isActive }: { isActive: boolean }): IconName => {
      if (isActive) {
        if (sortDirection === SortDirection.Desc) {
          return 'arrowDown';
        } else {
          return 'arrowUp';
        }
      }
      return 'sort';
    },
    [sortDirection],
  );

  return (
    <>
      <TableContainer maxHeight="100%" overflowY="scroll">
        {!!campaigns?.findManyEmailCampaign?.filter((campaign) => !campaign.deletedAt).length && (
          <Box width="300px" mb="4">
            <TextInput
              _focus={{ borderColor: 'border.focus', borderWidth: '2px' }}
              onChange={(e): void => {
                setSearchByName(e.target.value);
              }}
              placeholder="Search by name"
              leftElement={<Icon size="sm" name="magnifyingGlass" />}
            />
          </Box>
        )}
        <Table whiteSpace="normal">
          <Thead position="sticky" bgColor="background.default" top="0" zIndex="999">
            <Th width="100px">
              <Button
                ml="-3"
                isActive={sortedBy === SortedBy.Name}
                variant="ghostNeutral"
                size="sm"
                onClick={handleSortByName}
                rightIcon={<Icon size="xs" name={getSortingIconName({ isActive: sortedBy === SortedBy.Name })} />}
              >
                CAMPAIGN NAME
              </Button>
            </Th>
            <Th>
              <Button
                ml="-3"
                isActive={sortedBy === SortedBy.LastUpdated}
                variant="ghostNeutral"
                size="sm"
                onClick={handleSortByLastUpdated}
                rightIcon={
                  <Icon size="xs" name={getSortingIconName({ isActive: sortedBy === SortedBy.LastUpdated })} />
                }
              >
                LAST UPDATED
              </Button>
            </Th>
            <Th>
              <Button
                ml="-3"
                isActive={sortedBy === SortedBy.Status}
                variant="ghostNeutral"
                size="sm"
                onClick={handleSortByStatus}
                rightIcon={<Icon size="xs" name={getSortingIconName({ isActive: sortedBy === SortedBy.Status })} />}
              >
                STATUS
              </Button>
            </Th>
            <Th></Th>
          </Thead>

          <Tbody overflowY="scroll">
            {filteredCampaigns.map((campaign, index) => {
              const updatedAt = format(new Date(campaign.updatedAt), 'M/d/yy, h:mm a');
              const updatedBy =
                campaign.lastUpdatedByUser?.nameDisplay ||
                `${campaign.lastUpdatedByUser?.firstName || ''} ${campaign.lastUpdatedByUser?.lastName || ''}`;
              return (
                <Tr
                  borderBottom="hidden"
                  key={campaign.id}
                  bgColor={index % 2 === 0 ? 'background.subtle' : 'background.default'}
                >
                  <Td width="400px">{campaign.name}</Td>
                  <Td>
                    <Text as="p">{updatedAt}</Text>
                    <Text as="p" size="sm" color="text.subtle">
                      by {updatedBy.trim() || 'Unknown'}
                    </Text>
                  </Td>
                  <Td>
                    <CampaignStatus status={campaign.status} />
                  </Td>
                  <Td>
                    <Flex justifyContent="end">
                      {campaign.status === EmailCampaignStatus.Draft ? (
                        <Button
                          size="sm"
                          variant="tertiary"
                          iconName="pen"
                          mr="4"
                          onClick={(): void => {
                            history.push(`${RouteBasePaths.OttoEmailCampaigns}/${campaign.id}/edit-campaign`);
                          }}
                        >
                          Edit
                        </Button>
                      ) : (
                        <Button
                          size="sm"
                          variant="tertiary"
                          iconName="report"
                          mr="4"
                          onClick={(): void => {
                            history.push(
                              `${RouteBasePaths.Reporting}${quickSightRelativePath}/${QUICKSIGHT_DASHBOARD_URL.EmailCampaigns}`,
                            );
                          }}
                        >
                          View Report
                        </Button>
                      )}
                      <Menu
                        buttonProps={{
                          variant: 'tertiary',
                          leftIconName: 'dotsVertical',
                          size: 'sm',
                          showRightIcon: false,
                        }}
                        listProps={{
                          canSelectAll: false,
                          canSelectGroups: false,
                          isMultiSelect: false,
                          isSearchable: false,

                          menuItems: getMenuItems(campaign.id, campaign.status),
                        }}
                      />
                    </Flex>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <Modal allowEscape isOpen={isOpen} onClose={onClose} size="md">
          <ModalHeader>Delete Campaign</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to delete <Text fontWeight="semibold">{selectedCampaignForDeletion.name}</Text>? This
            action cannot be undone.
          </ModalBody>
          <ModalFooter justifyContent="space-between">
            <Button variant="tertiary" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="primaryDestructive"
              onClick={(): void => {
                handleDelete(selectedCampaignForDeletion.id);
                onClose();
              }}
            >
              Delete
            </Button>
          </ModalFooter>
        </Modal>
      </TableContainer>
      {searchByName && !filteredCampaigns.length && (
        <Flex justifyContent="center" mt="12">
          <Text>No campaigns match your search.</Text>
        </Flex>
      )}
    </>
  );
};

export default CampaignList;
