import { Box, useDisclosure } from '@televet/kibble-ui/build/chakra';
import { Button } from '@televet/kibble-ui/build/components/Button';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from '@televet/kibble-ui/build/components/Modal';
import React from 'react';

type EmailModalPreviewProps = {
  html: string;
};

const EmailModalPreview = ({ html }: EmailModalPreviewProps): JSX.Element => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  const scriptRegex = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
  const doctypeRegex = /<!DOCTYPE[^>]*>/gi;
  const htmlRegex = /<html[^>]*>/gi;
  const htmlCloseRegex = /<\/html>/gi;
  const headRegex = /<head[^>]*>/gi;
  const headCloseRegex = /<\/head>/gi;
  const bodyRegex = /<body[^>]*>/gi;
  const bodyCloseRegex = /<\/body>/gi;

  const sanitizedHtml = html
    .replaceAll(scriptRegex, '')
    .replaceAll(doctypeRegex, '')
    .replaceAll(htmlRegex, '')
    .replaceAll(htmlCloseRegex, '')
    .replaceAll(headRegex, '')
    .replaceAll(headCloseRegex, '')
    .replaceAll(bodyRegex, '')
    .replaceAll(bodyCloseRegex, '');

  return (
    <>
      <Button mt="4" variant="tertiary" onClick={onOpen}>
        Preview
      </Button>
      <Modal allowEscape isOpen={isOpen} onClose={onClose} size="3xl" autoFocus>
        <ModalHeader>Email Preview</ModalHeader>
        <ModalCloseButton />
        <ModalBody maxHeight="80vh" overflow="scroll">
          <Box width="100%" dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
        </ModalBody>
        <ModalFooter />
      </Modal>
    </>
  );
};

export default EmailModalPreview;
