export enum StateSliceName {
  Automations = 'Automations',
  Board = 'Board',
  Conversations = 'Conversations',
  PaymentSettings = 'PaymentSettings',
  MergeProfiles = 'MergeProfiles',
  CareWizardSteps = 'CareWizardSteps',
  User = 'User',
  CurrentClinic = 'CurrentClinic',
}
