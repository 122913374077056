import React, { MouseEvent, ReactElement, useEffect, useContext, useState, useMemo, useCallback } from 'react';
import {
  ClinicFormTemplateFragment,
  FormTemplateType,
  Service,
  useImportFormTemplateMutation,
  WorkflowEventSetting,
} from 'shared/types/graphql';
import FormListItem from './FormListItem';
import { useParams } from 'react-router-dom';
import { FormsContext } from '../context/FormsContext';
import TextInput from 'shared/components/TextInput';
import Dropdown, { IOption, PopoverPlacement } from 'shared/components/Dropdown';
import cloneDeep from 'lodash-es/cloneDeep';
import { fileOpen, directoryOpen, fileSave, supported } from 'browser-fs-access';
import usePersistedState from 'shared/hooks/usePersistedState';
import { removeKeys } from 'shared/utils';
import {
  Heading,
  Button,
  Text,
  HStack,
  Collapse,
  Box,
  Divider,
  Center,
  Flex,
  VStack,
  useMediaQuery,
  useDisclosure,
} from '@televet/kibble-ui';
import { DeviceSizes } from 'shared/enums/DeviceSizes';
import { useQuery } from '@apollo/client/react/hooks';
import { GET_CLINIC_WORKFLOWS_EVENT_SETTINGS } from 'shared/queries/workflows';
import UnsavedFormChangesModal, { PageType } from '../components/UnsavedFormChangesModal';
import KeepNewFormModal from '../components/KeepNewFormModal';
import { Mixpanel } from 'shared/utils/mixpanel';
import { GraphQLFetchPolicies, PersistedStateKeys } from '../../../shared/enums';
import sortBy from 'lodash-es/sortBy';

interface IFormsMenu {
  clinicTemplates: ClinicFormTemplateFragment[];
  getFormTemplates: () => void;
  clinicId: string | undefined;
}

export interface LinkedForm {
  formId: string;
  automationId: string;
  automationName: string;
}

enum MissingTemplateType {
  Automation = 'Automation',
}

type ExtendedFormTemplateType = FormTemplateType | MissingTemplateType;

const filterOptions: IOption[] = [
  {
    text: 'General',
    value: FormTemplateType.General,
    isSelected: false,
  },
  {
    text: 'Website Widget',
    value: FormTemplateType.WidgetRequest,
    isSelected: false,
  },
  {
    text: 'Service Reminder',
    value: FormTemplateType.ServiceReminder,
    isSelected: false,
  },
  {
    text: 'Fill In the Blank',
    value: FormTemplateType.FillInTheBlank,
    isSelected: false,
  },
  {
    text: 'Automation',
    value: MissingTemplateType.Automation,
    isSelected: false,
  },
];

const FormsMenu = ({ clinicId, clinicTemplates, getFormTemplates }: IFormsMenu): ReactElement => {
  const { formTemplateId } = useParams<{ formTemplateId: string }>();
  const { dispatch, isNewForm, hasChanges, formId } = useContext(FormsContext);
  const [isSearching, setIsSearching] = useState(false);
  const [searchTextFormTitle, setSearchTextFormTitle] = useState('');
  const [filters, setFilters] = useState(filterOptions);
  const [archivedExpanded, setArchivedExpanded] = usePersistedState(PersistedStateKeys.ArchivedFormsExpanded, false);
  const [isDesktop] = useMediaQuery('(' + DeviceSizes.TabletMinWidth + ')');
  const { isOpen: isKeepOpen, onOpen: onKeepOpen, onClose: onKeepClose } = useDisclosure();
  const {
    isOpen: isUnsavedChangesOpen,
    onOpen: onUnsavedChangesOpen,
    onClose: onUnsavedChangesClose,
  } = useDisclosure();

  const showTemplateSelector = (): void => {
    if (isNewForm && !hasChanges) onKeepOpen();
    else if (hasChanges) onUnsavedChangesOpen();
    else {
      dispatch({
        type: 'TOGGLE_TEMPLATE_SELECTOR',
        payload: { isTemplateSelectorShown: true },
      });
    }
    Mixpanel.track('Add new form clicked');
  };

  const { data: automations } = useQuery<{
    findManyWorkflowEventSetting: WorkflowEventSetting[];
  }>(GET_CLINIC_WORKFLOWS_EVENT_SETTINGS, {
    variables: {
      where: {
        clinic: {
          id: { equals: clinicId },
        },
      },
    },
    fetchPolicy: GraphQLFetchPolicies.CacheAndNetwork,
  });

  const formsLinkedToAutomations = useMemo(() => {
    const linkedFormsData: LinkedForm[] = [];

    automations?.findManyWorkflowEventSetting?.forEach((setting) => {
      const formIds = setting.actions
        .filter((action) => action?.config?.formTemplateId !== undefined)
        .map((action) => action.config?.formTemplateId);

      if (formIds.length) {
        linkedFormsData.push(
          ...formIds.map((formId) => ({
            formId,
            automationId: setting.id,
            automationName: setting.name,
          })),
        );
      }
    });

    return linkedFormsData;
  }, [automations]);

  const formHasLinkedAutomation = useCallback(
    (formTemplateId: string) => {
      return formsLinkedToAutomations.find(({ formId }) => formId === formTemplateId);
    },
    [formsLinkedToAutomations],
  );

  useEffect(() => {
    const selectTemplate = (): void =>
      clinicTemplates.forEach((template) => {
        if (template?.id === formTemplateId && template?.id) {
          dispatch({
            type: 'SELECT_FORM_TEMPLATE',
            payload: {
              formTemplate: template,
              hasLinkedAutomations: formHasLinkedAutomation(formTemplateId),
            },
          });
        }
      });

    if (isNewForm) {
      setArchivedExpanded(true);
      setTimeout(selectTemplate, 200);
    } else {
      selectTemplate();
    }
  }, [formTemplateId, clinicTemplates, dispatch, isNewForm, formHasLinkedAutomation, setArchivedExpanded]);

  const filteredTemplates = useMemo(() => {
    const activeFilters: ExtendedFormTemplateType[] = [];

    filters.forEach((filter) => {
      if (filter.isSelected) {
        return activeFilters.push(filter.value);
      }
    });

    let filtered: ClinicFormTemplateFragment[] = [];

    if (activeFilters.length) {
      if (activeFilters.includes(FormTemplateType.FillInTheBlank)) {
        filtered = [
          ...filtered,
          ...clinicTemplates.filter((template) => template.formTemplateType === FormTemplateType.FillInTheBlank),
        ];
      }
      if (activeFilters.includes(FormTemplateType.General)) {
        filtered = [
          ...filtered,
          ...clinicTemplates.filter(
            (template) =>
              template.formTemplateType === FormTemplateType.General && !formHasLinkedAutomation(template.id),
          ),
        ];
      }
      if (activeFilters.includes(FormTemplateType.ServiceReminder)) {
        filtered = [
          ...filtered,
          ...clinicTemplates.filter((template) => template.formTemplateType === FormTemplateType.ServiceReminder),
        ];
      }
      if (activeFilters.includes(FormTemplateType.WidgetRequest)) {
        filtered = [...filtered, ...clinicTemplates.filter((template) => template.clinicWidgetRequestTypes.length)];
      }
      if (activeFilters.includes(MissingTemplateType.Automation)) {
        filtered = [
          ...filtered,
          ...clinicTemplates.filter(
            (template) =>
              template.formTemplateType !== FormTemplateType.ServiceReminder && formHasLinkedAutomation(template.id),
          ),
        ];
      }
    } else {
      filtered = clinicTemplates;
    }

    filtered = sortBy(filtered, ['title']);

    // Hide service reminder form template from list when service reminders are not enabled for clinic
    return filtered.filter((template) => {
      const isHiddenRemindersTemplate =
        template.formTemplateType === FormTemplateType.ServiceReminder && !template.clinic?.hasServiceReminders;
      const title = template.title.toLocaleLowerCase();
      return title.includes(searchTextFormTitle.trim().toLowerCase()) && !isHiddenRemindersTemplate;
    });
  }, [clinicTemplates, formHasLinkedAutomation, searchTextFormTitle, filters]);

  const handleSearchClick = (): void => {
    if (isSearching) {
      setSearchTextFormTitle('');
    }

    setIsSearching(!isSearching);
  };

  const handleFilterSelect = (e: MouseEvent, option: IOption): void => {
    const updatedFilters = filters.map((filter) => {
      if (filter.value === option.value) {
        return { ...filter, isSelected: !filter.isSelected };
      }

      return filter;
    });

    setFilters(updatedFilters);
  };

  const areFiltersActive = useMemo(() => filters.some((filter) => filter.isSelected), [filters]);

  interface ExportableFormTemplate {
    __typename?: string;
    clinic?: { connect: { id: string } };
    createdAt?: string;
    draftContent?: unknown;
    id?: string;
    updatedAt?: string;
    autoExportEnabled?: boolean;
    services?: Pick<Service, 'id' | 'name'>[];
    title: string;
    content: { [key: string]: unknown };
    clinicWidgetRequestTypes?: [];
    formTemplateType?: FormTemplateType;
    isActive?: boolean;
  }

  const sanitizeExportableFormTemplate = (formTemplate: ClinicFormTemplateFragment): ExportableFormTemplate => {
    const newTemplate = cloneDeep(formTemplate) as ExportableFormTemplate;
    const formType =
      newTemplate.formTemplateType === FormTemplateType.FillInTheBlank
        ? newTemplate.formTemplateType
        : FormTemplateType.General;
    if (clinicId) {
      // sanitize the template
      delete newTemplate.__typename;
      delete newTemplate.clinic;
      delete newTemplate.createdAt;
      delete newTemplate.draftContent;
      delete newTemplate.id;
      delete newTemplate.updatedAt;
      delete newTemplate.services;
      delete newTemplate.clinicWidgetRequestTypes;
      newTemplate.formTemplateType = formType;
      newTemplate.isActive = false;
    }
    return newTemplate;
  };

  const [isDeveloperModeEnabled] = usePersistedState(PersistedStateKeys.IsDeveloperModeEnabled, false);

  const [importFormTemplate] = useImportFormTemplateMutation();

  const handleExport = async (): Promise<void> => {
    try {
      if (!supported) {
        alert('This feature is not supported on your browser');
        return;
      }
      await directoryOpen();
      const formTemplates = filteredTemplates.map((template) => {
        return sanitizeExportableFormTemplate(template);
      });
      for (const template of formTemplates) {
        await fileSave(new Blob([JSON.stringify(template)], { type: 'text/plain' }), {
          fileName: `${template.title}.json`,
          extensions: ['.json'],
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleImport = async (): Promise<void> => {
    try {
      if (!supported) {
        alert('This feature is not supported on your browser');
        return;
      }
      if (clinicId) {
        const files = await fileOpen({
          mimeTypes: ['application/json'],
          multiple: true,
        });

        for (const file of files) {
          const newTemplate = sanitizeExportableFormTemplate(JSON.parse(await file.text()));

          newTemplate.clinic = { connect: { id: clinicId } };

          await importFormTemplate({ variables: { data: removeKeys(newTemplate, '__typename') } });
        }
      }
      getFormTemplates();
    } catch (error) {
      console.error(error);
    }
  };

  const handleCollapseArchived = (): void => {
    setArchivedExpanded((value) => !value);
  };

  const onConfirm = (): void => {
    if (hasChanges) onUnsavedChangesClose();
    if (isNewForm) onKeepClose();
    dispatch({
      type: 'RESET_NEW_FORM',
      payload: {},
    });
    dispatch({
      type: 'TOGGLE_TEMPLATE_SELECTOR',
      payload: { isTemplateSelectorShown: true },
    });
  };

  return (
    <>
      <VStack
        bg="background.subtle"
        border="1px"
        borderColor="border.default"
        flex="0 0 auto"
        align="stretch"
        overflowY="scroll"
        w={isDesktop ? '365px' : '300px'}
      >
        <Flex h="60px" w="100%" align="center" justify="flex-end" px={5} my={5}>
          <Center gap={3}>
            <HStack>
              <Box position="relative">
                {areFiltersActive && (
                  <Box
                    bgColor="background.accentSubtle"
                    borderRadius={4}
                    h="8px"
                    w="8px"
                    position="absolute"
                    right="6px"
                    top="4px"
                  />
                )}
                <Dropdown
                  onSelect={handleFilterSelect}
                  options={filters}
                  placement={PopoverPlacement.BottomStart}
                  closeOnClick={false}
                  showSelectedFirst={false}
                  isMultiSelect
                >
                  <Button variant="ghostNeutral" iconName="filter" size="md" isActive={areFiltersActive} />
                </Dropdown>
              </Box>
              <Button
                variant="ghostNeutral"
                iconName="magnifyingGlass"
                size="md"
                isActive={isSearching}
                onClick={handleSearchClick}
              />
            </HStack>
            <Button
              data-mixpanel-name="Add new form"
              data-testid="add-form-button"
              size="sm"
              onClick={showTemplateSelector}
              iconName="plus"
            >
              Add New
            </Button>
          </Center>
        </Flex>
        <Box h="100%">
          {isDeveloperModeEnabled && (
            <Flex justify="space-between" align="center" mt="10px">
              Developer Tools:
              <Button _focus={{ border: 'none' }} onClick={handleExport}>
                Export
              </Button>
              <Button _focus={{ border: 'none' }} onClick={handleImport}>
                Import
              </Button>
            </Flex>
          )}

          {isSearching && (
            <Box mb={5} mx={5}>
              <TextInput
                name="form-name"
                label=""
                placeholder="Search by form name"
                value={searchTextFormTitle}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setSearchTextFormTitle(e.target.value)}
                autoFocus
              />
            </Box>
          )}
          {!!filteredTemplates.length && (
            <>
              {filteredTemplates
                ?.filter((template) => template.isActive)
                .map((template) => {
                  return (
                    <FormListItem
                      key={'form-template-' + template.id}
                      formTemplate={template}
                      data-mixpanel-name="Existing form"
                      data-testid="form-list-item"
                      clinicId={clinicId}
                      linkedAutomations={formsLinkedToAutomations.filter(({ formId }) => formId === template.id)}
                    />
                  );
                })}
              <Center>
                <Divider m={3} />
              </Center>
              <HStack role="group" mx={5} justify="space-between" cursor="pointer" onClick={handleCollapseArchived}>
                <Heading as="h3" size="sm">
                  Archived
                </Heading>
                <Button
                  iconName={archivedExpanded ? 'chevronUp' : 'chevronDown'}
                  size="sm"
                  isActive={archivedExpanded}
                  _groupHover={archivedExpanded ? {} : { bgColor: 'background.alpha.hover' }}
                  variant="ghostNeutral"
                />
              </HStack>
              <Collapse in={archivedExpanded}>
                {filteredTemplates
                  ?.filter((template) => !template.isActive)
                  .map((template) => {
                    return (
                      <FormListItem
                        key={'form-template-' + template.id}
                        formTemplate={template}
                        data-mixpanel-name="Existing form"
                        data-testid="form-list-item"
                        clinicId={clinicId}
                        linkedAutomations={formsLinkedToAutomations.filter(({ formId }) => formId === template.id)}
                      />
                    );
                  })}
              </Collapse>
            </>
          )}

          {!filteredTemplates.length && (isSearching || areFiltersActive) && (
            <Center>
              <Text>No forms found with this criteria.</Text>
            </Center>
          )}
          <Box h="30px" />
        </Box>
      </VStack>
      <UnsavedFormChangesModal
        isOpen={isUnsavedChangesOpen}
        onClose={onUnsavedChangesClose}
        onConfirm={onConfirm}
        pageType={PageType.Form}
      />
      <KeepNewFormModal isOpen={isKeepOpen} onClose={onKeepClose} formTemplateId={formId} onConfirm={onConfirm} />
    </>
  );
};

export default FormsMenu;
