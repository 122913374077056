// ***************************************************************************
//  Please keep this file alphabetized for easier maintenance and readability
// ***************************************************************************
export enum GA4Events {
  BOARD_CONVERSATION_OVERLAY = 'board_conversation_overlay',
  BOARD_CUSTOM_DATE_RANGE_APPLIED = 'board_custom_date_range_applied',
  BOARD_QUICK_SELECT_DATE_RANGE_APPLIED = 'board_quick_select_date_range_applied',
  BOARD_VIEW = 'board_view',
  CHURNZERO_SUCCESS_CENTER_ERROR_ON_LOAD = 'ChurnZero Success Hub error on load',
  CHURNZERO_SUCCESS_CENTER_FAILED_TO_LOAD = 'ChurnZero Success Hub failed to load',
  CHURNZERO_SUCCESS_CENTER_LOADED = 'ChurnZero Success Hub loaded',
  CONVERSATION_PIN_TOGGLE = 'pinned_conversation',
  EARN_VIRTUAL_CURRENCY = 'earn_virtual_currency',
  EMAIL_CAMPAIGN_BEST_PRACTICES_CLICK = 'email_campaign_best_practices_click',
  EMAIL_CAMPAIGN_TEMPLATE_SELECTED = 'email_campaign_template_selected',
  EMAIL_CAMPAIGNS_CUSTOM_LIST_DELETE = 'email_campaigns_custom_list_delete',
  EMAIL_CAMPAIGNS_CUSTOM_UPLOAD = 'email_campaigns_custom_upload',
  EMAIL_CAMPAIGNS_CUSTOM_UPLOAD_FAILURE = 'email_campaigns_custom_upload_failure',
  EMAIL_CAMPAIGNS_REJECTED_RECIPIENTS = 'email_campaigns_rejected_recipients',
  FLOW_SETTINGS_ADD_BLOCKED_NUMBER = 'flow_settings_add_blocked_number',
  FLOW_SETTINGS_SUBMIT_BLOCKED_NUMBER = 'flow_settings_submit_blocked_number',
  FLOW_TEXT_MESSAGING_SETTINGS = 'flow_text_messaging_settings',
  INIT = 'ga4_initialized',
  LOYALTY_CLAIM_MODAL_CONFIRM = 'loyalty_claim_modal_confirm',
  LOYALTY_CLAIM_MODAL_DISMISS = 'loyalty_claim_modal_dismiss',
  LOYALTY_ELIGIBLE_ITEMS_CHECKBOX_CLICK = 'loyalty_eligible_items_checkbox_click',
  LOYALTY_FULFILL_MODAL_CONFIRM = 'loyalty_fulfill_modal_confirm',
  LOYALTY_FULFILL_MODAL_DISMISS = 'loyalty_fulfill_modal_dismiss',
  LOYALTY_REWARDS_ADD_NEW_CLICK = 'loyalty_rewards_add_new_click',
  LOYALTY_REWARDS_DELETE_CLICK = 'loyalty_rewards_delete_click',
  LOYALTY_REWARDS_EDIT_CLICK = 'loyalty_rewards_edit_click',
  LOYALTY_REWARDS_TOGGLE = 'loyalty_rewards_toggle',
  LOYALTY_SETTINGS_VIEW = 'loyalty_settings_view',
  LOYALTY_SIDEPANEL_ADD_POINTS_CLICK = 'loyalty_sidepanel_add_points_click',
  LOYALTY_SIDEPANEL_CLAIM_REWARD_CLICK = 'loyalty_sidepanel_claim_reward_click',
  LOYALTY_SIDEPANEL_CURRENT_REWARDS = 'loyalty_sidepanel_current_rewards',
  LOYALTY_SIDEPANEL_FULFILL_REWARD_CLICK = 'loyalty_sidepanel_fulfill_reward_click',
  LOYALTY_SIDEPANEL_REWARDS_HISTORY = 'loyalty_sidepanel_rewards_history',
  LOYALTY_SIDEPANEL_VIEW_REWARDS_LIST = 'loyalty_sidepanel_view_rewards_list',
  MEMBERSHIP_FINANCIAL_REPORT_VIEW = 'membership_financial_report_view',
  MEMBERSHIP_MANAGE_BACK_CLICK = 'membership_manage_back_click',
  MEMBERSHIP_MANAGE_CALCULATOR_SKIPPED = 'membership_manage_calculator_skipped',
  MEMBERSHIP_MANAGE_CLOSE = 'membership_manage_close',
  MEMBERSHIP_MANAGE_DOWNLOAD_SUMMARY_CLICK = 'membership_manage_download_summary_click',
  MEMBERSHIP_MANAGE_GET_HELP_CLICK = 'membership_manage_get_help_click',
  MEMBERSHIP_MANAGE_START = 'membership_manage_start',
  MEMBERSHIP_MANAGE_SUBMIT = 'membership_manage_submit',
  MEMBERSHIP_MANAGE_SUBMIT_CANCELLATION = 'membership_manage_submit_cancellation',
  MEMBERSHIP_MANAGE_SUCCESS_DISMISS = 'membership_manage_success_dismiss',
  MEMBERSHIP_MANAGE_TOGGLE_ADJUSTMENT = 'membership_manage_toggle_adjustment',
  MEMBERSHIP_MANAGE_TOGGLE_FEES = 'membership_manage_toggle_fees',
  MEMBERSHIP_MANAGE_TOGGLE_MEMBER_SAVINGS = 'membership_manage_toggle_member_savings',
  MEMBERSHIP_MANAGE_VIEW_CALCULATOR_STEP = 'membership_manage_view_calculator_step',
  MEMBERSHIP_MANAGE_VIEW_CANCEL_STEP = 'membership_manage_view_cancel_step',
  MEMBERSHIP_MANAGE_VIEW_GET_HELP_STEP = 'membership_manage_view_get_help_step',
  MEMBERSHIP_PAYMENTS_REPORT_EXCLUDE = 'membership_payments_report_exclude',
  MEMBERSHIP_PAYMENTS_REPORT_INCLUDE = 'membership_payments_report_include',
  MEMBERSHIP_TAB_PET_PORTAL_LINK_CTA = 'membership_tab_pet_portal_link_cta',
  MEMBERSHIP_VIEW_BENEFITS = 'membership_view_benefits',
  MEMBERSHIP_WIZARD_CLOSE = 'membership_wizard_close',
  MEMBERSHIP_WIZARD_COMPLETE = 'membership_wizard_complete',
  MEMBERSHIP_WIZARD_START = 'membership_wizard_start',
  PAY_EOD_BATCH_CREATE = 'pay_eod_batch_create',
  PAY_EOD_CANCEL = 'pay_eod_cancel',
  PAY_EOD_START = 'pay_eod_start',
  PET_PROFILE_TAB_PET_PORTAL_LINK_CTA = 'pet_profile_tab_pet_portal_link_cta',
  SPEND_VIRTUAL_CURRENCY = 'spend_virtual_currency',
  SUCCESS_CENTER_CHAT_WITH_US_CLICKED = 'Success Hub - Chat with us clicked',
}
// ***************************************************************************
//  Please keep this file alphabetized for easier maintenance and readability
// ***************************************************************************
