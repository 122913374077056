import React, { createContext, useContext, useState } from 'react';
import { GA4Events } from 'shared/enums/GA4Events';
import useGA from 'shared/hooks/useGA';
import {
  CancelReasonType,
  CareSubscriptionTrueUpType,
  ManageMembershipRequestType,
  PaymentSchedule,
} from 'shared/types/graphql';
import { IFormValues, PaymentMethod } from '../types';

export enum ManageMembershipStep {
  SupportForm,
  CancellationCalculator,
  PaymentStep,
  CancelMembership,
  GetHelp,
}

interface IPaymentData {
  paymentType: CareSubscriptionTrueUpType;
  paymentMethodId?: string | null;
  paymentSchedule: PaymentSchedule;
  billingDate?: Date | null;
}

interface IManageMembershipContext {
  currentStep: ManageMembershipStep;
  previousStep?: ManageMembershipStep;
  handleNext: (step: ManageMembershipStep) => void;
  handleBack: () => void;
  manualAdjustment: string;
  setManualAdjustment: (manualAdjustment: string) => void;
  form: IFormValues;
  setForm: (form: IFormValues) => void;
  balanceDue?: number;
  setBalanceDue: (balanceDue?: number) => void;
  applyDiscounts: boolean;
  setApplyDiscounts: (applyDiscounts: boolean) => void;
  includeFees: boolean;
  setIncludeFees: (includeFees: boolean) => void;
  submitText: string;
  setSubmitText: (submitText: string) => void;
  resetContext: () => void;
  isSubmitting: boolean;
  setIsSubmitting: (isSubmitting: boolean) => void;
  includeManualAdjustment: boolean;
  setIncludeManualAdjustment: (includeManualAdjustment: boolean) => void;
  hasBenefitUsages?: boolean;
  setHasBenefitUsages: (hasBenefitUsages: boolean) => void;
  isAbleToUpgrade: boolean;
  setIsAbleToUpgrade: (isAbleToUpgrade: boolean) => void;
  paymentData: IPaymentData;
  setPaymentData: (paymentData: IPaymentData) => void;
}

interface ManageMembershipProviderProps {
  children: React.ReactNode;
}

const initialState: IManageMembershipContext = {
  currentStep: ManageMembershipStep.SupportForm,
  previousStep: undefined,
  handleNext: () => {
    throw new Error('handleNext not implemented yet');
  },
  handleBack: () => {
    throw new Error('handleBack not implemented yet');
  },
  manualAdjustment: '',
  setManualAdjustment: () => {
    throw new Error('setManualAdjustment not implemented yet');
  },
  form: {
    pointOfContactId: '',
    requestType: ManageMembershipRequestType.RemoveBenefit,
    cancelReason: undefined,
    trueUpAmount: undefined,
    transferPetId: undefined,
    paymentSchedule: PaymentSchedule.Full,
    paymentMethod: PaymentMethod.CardOnFile,
    allowRefund: undefined,
  },
  setForm: () => {
    throw new Error('setForm not implemented yet');
  },
  balanceDue: undefined,
  setBalanceDue: () => {
    throw new Error('setBalanceDue not implemented yet');
  },
  applyDiscounts: false,
  setApplyDiscounts: () => {
    throw new Error('setApplyDiscounts not implemented yet');
  },
  includeFees: false,
  setIncludeFees: () => {
    throw new Error('setIncludeFees not implemented yet');
  },
  submitText: '',
  setSubmitText: () => {
    throw new Error('setSubmitText not implemented yet');
  },
  resetContext: () => {
    throw new Error('resetContext not implemented yet');
  },
  isSubmitting: false,
  setIsSubmitting: () => {
    throw new Error('setIsSubmitting not implemented yet');
  },
  includeManualAdjustment: false,
  setIncludeManualAdjustment: () => {
    throw new Error('setIncludeManualAdjustment not implemented yet');
  },
  hasBenefitUsages: false,
  setHasBenefitUsages: () => {
    throw new Error('setHasBenefitUsages not implemented yet');
  },
  isAbleToUpgrade: true,
  setIsAbleToUpgrade: () => {
    throw new Error('setIsAbleToUpgrade not implemented yet');
  },
  paymentData: {
    paymentType: CareSubscriptionTrueUpType.Internal,
    paymentMethodId: undefined,
    paymentSchedule: PaymentSchedule.Full,
    billingDate: undefined,
  },
  setPaymentData: () => {
    throw new Error('setPaymentData not implemented yet');
  },
};

const ManageMembershipContext = createContext<IManageMembershipContext>(initialState);

const ManageMembershipProvider = ({ children }: ManageMembershipProviderProps): JSX.Element => {
  const { gaTrack } = useGA();
  const [currentStep, setCurrentStep] = useState(initialState.currentStep);
  const [previousStep, setPreviousStep] = useState(initialState.previousStep);
  const [manualAdjustment, setManualAdjustment] = useState(initialState.manualAdjustment);
  const [form, setForm] = useState<IFormValues>(initialState.form);
  const [balanceDue, setBalanceDue] = useState(initialState.balanceDue);
  const [applyDiscounts, setApplyDiscounts] = useState(initialState.applyDiscounts);
  const [includeFees, setIncludeFees] = useState(initialState.includeFees);
  const [submitText, setSubmitText] = useState(initialState.submitText);
  const [isSubmitting, setIsSubmitting] = useState(initialState.isSubmitting);
  const [includeManualAdjustment, setIncludeManualAdjustment] = useState(initialState.includeManualAdjustment);
  const [hasBenefitUsages, setHasBenefitUsages] = useState(initialState.hasBenefitUsages);
  const [isAbleToUpgrade, setIsAbleToUpgrade] = useState(initialState.isAbleToUpgrade);
  const [paymentData, setPaymentData] = useState(initialState.paymentData);

  const handleSetForm = (values: IFormValues): void => {
    setForm(values);

    if (values.cancelReason === CancelReasonType.DeceasedPet) {
      setApplyDiscounts(true);
    } else {
      setApplyDiscounts(false);
    }
  };

  const handleNext = (step: ManageMembershipStep): void => {
    setCurrentStep((curr) => {
      setPreviousStep(curr);
      return step;
    });
  };

  const handleBack = (): void => {
    gaTrack(GA4Events.MEMBERSHIP_MANAGE_BACK_CLICK, { currentStep });
    if (previousStep !== undefined && currentStep === ManageMembershipStep.GetHelp) {
      setCurrentStep(previousStep);
    } else {
      const isCancelMembershipStep = currentStep === ManageMembershipStep.CancelMembership;
      if (isCancelMembershipStep && !hasBenefitUsages) {
        // Skip payment and calc step if no benefits have been used
        setCurrentStep(ManageMembershipStep.SupportForm);
      } else if (isCancelMembershipStep && balanceDue && balanceDue <= 0) {
        // Skip payment step if no balance is owed
        setCurrentStep(ManageMembershipStep.CancellationCalculator);
      } else {
        setCurrentStep(currentStep - 1);
      }
    }

    if (currentStep === ManageMembershipStep.CancellationCalculator && includeManualAdjustment) {
      setIncludeManualAdjustment(false);
      setManualAdjustment('');
    }
  };

  const resetContext = (): void => {
    setCurrentStep(initialState.currentStep);
    setManualAdjustment(initialState.manualAdjustment);
    setForm(initialState.form);
    setBalanceDue(initialState.balanceDue);
    setApplyDiscounts(initialState.applyDiscounts);
    setIncludeFees(initialState.includeFees);
    setSubmitText(initialState.submitText);
    setIncludeManualAdjustment(initialState.includeManualAdjustment);
    setHasBenefitUsages(initialState.hasBenefitUsages);
    setIsAbleToUpgrade(initialState.isAbleToUpgrade);
    setPaymentData(initialState.paymentData);
  };

  return (
    <ManageMembershipContext.Provider
      value={{
        currentStep,
        previousStep,
        handleNext,
        handleBack,
        manualAdjustment,
        setManualAdjustment,
        form,
        setForm: handleSetForm,
        balanceDue,
        setBalanceDue,
        applyDiscounts,
        setApplyDiscounts,
        includeFees,
        setIncludeFees,
        submitText,
        setSubmitText,
        resetContext,
        isSubmitting,
        setIsSubmitting,
        includeManualAdjustment,
        setIncludeManualAdjustment,
        hasBenefitUsages,
        setHasBenefitUsages,
        isAbleToUpgrade,
        setIsAbleToUpgrade,
        paymentData,
        setPaymentData,
      }}
    >
      {children}
    </ManageMembershipContext.Provider>
  );
};

export const useManageMembershipContext = (): IManageMembershipContext =>
  useContext<IManageMembershipContext>(ManageMembershipContext);
export default ManageMembershipProvider;
