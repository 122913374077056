import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalFooterButtons,
  ModalHeader
} from '@televet/kibble-ui';
import React from 'react';

type DeleteConfirmationModalProps = {
  onClose: () => void;
  onConfirm: () => void;
  isDeleting?: boolean;
};

export const DeleteConfirmationModal = ({ onClose, onConfirm, isDeleting }: DeleteConfirmationModalProps): JSX.Element => {
  return (
    <Modal isOpen={true} onClose={onClose} size="sm">
      <ModalCloseButton />
      <ModalHeader>Delete Custom List</ModalHeader>
      <ModalBody>Are you sure you want to delete this custom list? This action cannot be undone.</ModalBody>
      <ModalFooterButtons isDestructive submitText="Yes, Delete" onSubmit={onConfirm} isLoading={isDeleting} />
    </Modal>
  );
};
