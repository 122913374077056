import React, { useCallback, useEffect, useState, useMemo } from 'react';
import styled from 'styled-components/macro';
import { Modal, ModalSizes } from '@televet/televet-ui';
import { ModalNames } from 'shared/enums/ModalNames';
import { addCommasToNumber } from 'shared/utils';
import CurrencyInput from 'shared/components/CurrencyInput';
import PercentageInput from 'shared/components/PercentageInput';
import { Mixpanel } from 'shared/utils/mixpanel';
import { IAttachmentOption } from 'pages/Conversations/interfaces/IAttachmentOption';
import Tooltip from 'shared/components/Tooltip';
import { QuestionCircleIcon } from 'assets/icons';
import { Button } from '@televet/kibble-ui/build/components/Button';

type FormState = {
  invoiceAmount: string;
  clientServiceFeePercent: string;
};

interface IEditServiceFeeModalProps {
  attachment: IAttachmentOption;
  processingFeePercent?: number;
  onAttachmentChange: (attachment: IAttachmentOption) => void;
  onCancel: () => void;
}

// TODO: De-couple this modal from attachments so we can use the same one across the app
const EditServiceFeeModal = ({
  attachment,
  processingFeePercent,
  onAttachmentChange,
  onCancel,
}: IEditServiceFeeModalProps): JSX.Element => {
  const [validationMessage, setValidationMessage] = useState<string | null>(null);
  const [formState, setFormState] = useState<FormState>({
    invoiceAmount: '0',
    clientServiceFeePercent: '0',
  });

  /**
   * Set initial state from attachment
   */
  useEffect(() => {
    if (attachment?.invoiceAmount) {
      setFormState({
        invoiceAmount: attachment.invoiceAmount,
        clientServiceFeePercent: ((attachment.clientServiceFeePercent || 0) * 100).toFixed(2),
      });
    }
  }, [attachment]);

  /**
   * Calculate client service fee
   */
  const clientServiceFee = useMemo(() => {
    const invoiceAmount = parseFloat(formState.invoiceAmount);
    const clientServiceFeePercent = parseFloat(formState.clientServiceFeePercent) / 100;
    if (isNaN(invoiceAmount) || isNaN(clientServiceFeePercent)) return '--.--';

    return (invoiceAmount * clientServiceFeePercent).toFixed(2);
  }, [formState]);

  /**
   * Get the clinic processing fee percent (1.2%)
   */
  const clinicProcessingFeePercent = useMemo(() => {
    if (!processingFeePercent) return 0;
    const clientServiceFee = parseFloat(formState.clientServiceFeePercent) / 100;
    return ((processingFeePercent - clientServiceFee) * 100).toFixed(2);
  }, [processingFeePercent, formState]);

  /**
   * Calculate the clinic processing fee
   */
  const clinicProcessingFee = useMemo(() => {
    if (!processingFeePercent) return 0;
    const clientServiceFee = parseFloat(formState.clientServiceFeePercent) / 100;
    const feePercent = processingFeePercent - clientServiceFee;
    return (parseFloat(formState.invoiceAmount) * feePercent).toFixed(2);
  }, [formState, processingFeePercent]);

  /**
   * Get the maximum allowed fee percentage
   */
  const maximumProcessingFeePercentage = useMemo(() => {
    if (!processingFeePercent) return 0;
    return (processingFeePercent * 100).toFixed(2);
  }, [processingFeePercent]);

  /**
   * Calculate the amount due
   */
  const amountDue = useMemo(() => {
    return (parseFloat(formState.invoiceAmount) + parseFloat(clientServiceFee)).toFixed(2);
  }, [clientServiceFee, formState]);

  const handleInvoiceAmountChange = useCallback((value: string): void => {
    const amount = parseFloat(value);
    if (amount < 0) {
      setFormState((state) => {
        return {
          ...state,
          invoiceAmount: '0',
        };
      });
      return;
    }
    setFormState((state) => {
      return {
        ...state,
        invoiceAmount: value,
      };
    });
  }, []);

  const handleClientServiceFeeChange = useCallback(
    (value: string): void => {
      const clientServiceFeePercent = parseFloat(value);
      if (clientServiceFeePercent > maximumProcessingFeePercentage && !validationMessage) {
        setValidationMessage(
          `Please enter an amount less than your clinic service fee of ${maximumProcessingFeePercentage}%`,
        );
      }

      if (clientServiceFeePercent < maximumProcessingFeePercentage && validationMessage) {
        setValidationMessage(null);
      }

      if (clientServiceFeePercent < 0) {
        setFormState((state) => {
          return {
            ...state,
            clientServiceFeePercent: '0',
          };
        });
        return;
      }

      setFormState((state) => {
        return {
          ...state,
          clientServiceFeePercent: value,
        };
      });
    },
    [maximumProcessingFeePercentage, validationMessage],
  );

  const onSubmit = (): void => {
    const { invoiceAmount, clientServiceFeePercent } = formState;
    const clientFee = parseFloat(clientServiceFeePercent) / 100;
    Mixpanel.track('Invoice fee updated');
    onAttachmentChange({
      ...attachment,
      invoiceAmount,
      clientServiceFeePercent: clientFee,
    });
    onCancel();
  };

  return (
    <Modal name={ModalNames.EditServiceFee} size={ModalSizes.md} overlayOpacity={0.11} includeCloseButton={false}>
      <Container>
        <Header>
          <Headline>
            Configure Service Fee
            <Tooltip
              content={`You can adjust the percentage that you would like your client to pay for this transaction here. The maximum percentage the client can pay is ${maximumProcessingFeePercentage}%.`}
            >
              <HelperIcon />
            </Tooltip>
          </Headline>
          <Button iconName="close" variant="ghostNeutral" onClick={onCancel} />
        </Header>
        <ContentContainer>
          <InputWrapper>
            <Label>Payment Amount</Label>
            <CurrencyInput value={formState.invoiceAmount || ''} onChange={handleInvoiceAmountChange} />
          </InputWrapper>

          <InputWrapper>
            <Label>
              Client Service Fee
              <Tooltip content="This number reflects the additional amount that your client will pay for this transaction and is a percentage of the base payment amount. If the percentage is split then the remainder will be reflected under Clinic Service Fee.">
                <HelperIcon />
              </Tooltip>
            </Label>
            <PercentageInput
              value={formState.clientServiceFeePercent || ''}
              onChange={handleClientServiceFeeChange}
              className={validationMessage ? 'error' : ''}
            />
            <InputError>{validationMessage}</InputError>
          </InputWrapper>
        </ContentContainer>

        <LineItemsContainer>
          <LineItem>
            <div>Subtotal</div>
            <div>{`$${addCommasToNumber(formState.invoiceAmount)}`}</div>
          </LineItem>
          <LineItem>
            <div>Client Service Fee</div>
            <div>{`$${addCommasToNumber(clientServiceFee)}`}</div>
          </LineItem>
          <TotalAmountDue>
            <div>Amount Due</div>
            <div>{`$${addCommasToNumber(amountDue)}`}</div>
          </TotalAmountDue>
        </LineItemsContainer>

        <ServiceFeeDisclaimer>{`A ${
          clinicProcessingFeePercent <= 0 ? '0' : clinicProcessingFeePercent
        }% service fee ($${
          clinicProcessingFee <= 0 ? '0' : addCommasToNumber(clinicProcessingFee)
        })  will be charged to the clinic.`}</ServiceFeeDisclaimer>
        <ActionsContainer>
          <CancelButton type="button" onClick={onCancel}>
            Cancel
          </CancelButton>
          <ConfirmButton type="button" onClick={onSubmit} disabled={Boolean(validationMessage)}>
            Confirm Fee
          </ConfirmButton>
        </ActionsContainer>
      </Container>
    </Modal>
  );
};

export default EditServiceFeeModal;

const Container = styled.div`
  padding: 30px;
`;

const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const Headline = styled.h1`
  color: #000b8e;
  font-size: 18px;
  font-weight: 600;
`;

const ContentContainer = styled.div`
  margin-top: 15px;
`;

const InputWrapper = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  color: #73747d;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 10px;
`;

const InputError = styled.p`
  color: #ff3e28;
  font-size: 14px;
  height: 10px;
  padding-top: 10px;
`;

const LineItemsContainer = styled.div`
  margin-top: 30px;
`;

const LineItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const LineItem = styled(LineItems)`
  font-size: 16px;
`;

const TotalAmountDue = styled(LineItems)`
  font-size: 18px;
  font-weight: bold;
`;

const ServiceFeeDisclaimer = styled.p`
  font-size: 12px;
  margin: 15px 0 15px 0;
`;

const ActionsContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

const CancelButton = styled.button`
  border: none;
  background-color: transparent;
  color: #acacac;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
`;

const ConfirmButton = styled.button`
  border: none;
  border-radius: 10px;
  background-color: #39adc3;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  padding: 8px 16px;

  &:disabled {
    opacity: 0.7;
  }
`;

const HelperIcon = styled(QuestionCircleIcon)`
  width: 10px;
  height: 10px;
  margin: 0 5px 0 5px;
`;
