import useClinicUser from 'shared/hooks/useClinicUser';
import {
  GetClinicFormTemplatesQuery,
  useCreateFormTemplateMutation,
  useGetClinicFormTemplatesQuery,
  useToggleActiveFormTemplateMutation,
} from 'shared/types/graphql';
import { useHistory } from 'react-router-dom';
import { replaceParam, RouteDefinitions } from 'routes';

import { GraphQLFetchPolicies } from 'shared/enums/GraphQLFetchPolicies';
import { useCallback } from 'react';

type UseFormsArgs = {
  onCompleted?: (data: GetClinicFormTemplatesQuery) => void;
};

const useForms = (args?: UseFormsArgs): {
  data: GetClinicFormTemplatesQuery | undefined;
  getFormTemplates: () => void;
  templatesLoading: boolean;
  addForm: (title?: string, content?: unknown) => void;
  toggleActive: (formId: string, isActive: boolean) => void;
  scrollToForm: (formId: string) => void;
} => {
  const { currentClinicId } = useClinicUser();

  const [createFormTemplate] = useCreateFormTemplateMutation();
  const [toggleActiveFormTemplate] = useToggleActiveFormTemplateMutation();
  const history = useHistory();

  const {
    data,
    loading: templatesLoading,
    refetch,
  } = useGetClinicFormTemplatesQuery({
    variables: {
      where: {
        clinicId: { equals: currentClinicId || '' },
        isDeleted: { equals: false },
      },
    },
    skip: !currentClinicId,
    fetchPolicy: GraphQLFetchPolicies.NetworkOnly,
    onCompleted: args?.onCompleted,
  });

  const getFormTemplates = async (): Promise<void> => {
    await refetch();
  };

  const addForm = async (title?: string, content?: unknown): Promise<void> => {
    if (!currentClinicId) {
      return;
    }

    const result = await createFormTemplate({
      variables: {
        clinicId: currentClinicId,
        title: title || 'New Form Template',
        content: content,
      },
    });

    await getFormTemplates();

    const newFormTemplateId = result?.data?.createOneFormTemplate?.id;

    if (newFormTemplateId) {
      history.push(`${replaceParam(RouteDefinitions.Forms, ':formTemplateId?', newFormTemplateId)}`);
    }
  };

  const toggleActive = async (formId: string, isActive: boolean): Promise<void> => {
    await toggleActiveFormTemplate({
      variables: { formId, isActive },
    });
    await getFormTemplates();
    scrollToForm(formId);
  };

  const isInViewport = (element: HTMLElement): boolean => {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  const scrollToForm = useCallback((formId: string): void => {
    const domNode = document.getElementById('form-template-' + formId);
    if (domNode && !isInViewport(domNode)) domNode?.scrollIntoView({ block: 'center' });
  }, []);

  return {
    data,
    getFormTemplates,
    templatesLoading,
    addForm,
    toggleActive,
    scrollToForm,
  };
};

export default useForms;
