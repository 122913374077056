import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { Box, Button, Text, Tag, SelectOptionProps, Collapse, Link } from '@televet/kibble-ui';
import { WildCardInputGroup, WildcardInputTypes } from 'shared/components/WildCardInputGroup';
import { AutomationsFieldDescription, AutomationsFieldLabel } from 'pages/Automations/components';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { WorkflowEvent } from 'shared/types/graphql';
import { AutomationType } from 'pages/Automations/types/AutomationDraft';
import { updateAutomationErrors, updateAutomationDraft } from 'pages/Automations/state/automationsSlice';
import { useGetFieldErrorText } from 'pages/Automations/hooks/useGetFieldErrorText';
import { TimeFormatValue } from 'shared/components/WildCardInputGroup/WildcardTimeFormatSelect';
import { useGetAvailableWildCards } from 'pages/Automations/hooks/useGetAvailableWildCards';
import { ZENDESK_ARTICLE_BASE_URL } from 'shared/hooks/useZendesk';

// TODO: These should be pulled directly from send grid. If these are ever updated in sendgrid those changes would not reflect here.
const defaultNotificationMessages: Partial<Record<AutomationType, string>> = {
  [WorkflowEvent.AppointmentFollowup]:
    'You have a follow up message for {{petName}} from {{clinicName}}. Please click the button below to view messages.',
  [WorkflowEvent.AppointmentPimsSync]:
    'Your appointment for {{petName}} at {{clinicName}} has been scheduled for {{time}}. Please click the button below view messages about your appointment.',
  [WorkflowEvent.AppointmentScheduled]:
    'Your appointment for {{petName}} at {{clinicName}} has been scheduled for {{time}}. Please click the button below view messages about your appointment.',
  [WorkflowEvent.AppointmentConfirmReminder]:
    'Your appointment for {{petName}} at {{clinicName}} is for {{time}}. Please click the button below to view messages and confirm your appointment.',
  [WorkflowEvent.AppointmentClientArrival]:
    'Your appointment for {{petName}} at {{clinicName}} is for {{time}}. Please click the button below to view messages about your appointment.',
  [WorkflowEvent.AppointmentReminder]:
    'Your appointment for {{petName}} at {{clinicName}} is for {{time}}. Please click the button below to view messages about your appointment.',
  [WorkflowEvent.LapsedPetParent]:
    '{{clinicName}} would love to see you and your furry family member again. Reconnect with us today by clicking the button below. We hope to see you soon!',
};

export const NotificationMessage = (): JSX.Element => {
  const [isCustomNotificationVisible, setIsCustomNotificationVisible] = useState(false);
  const automationType = useAppSelector((state) => state.automations.automationDraft.automationType);
  const notificationMessage = useAppSelector((state) => state.automations.automationDraft.notificationMessage);
  const timeFormat = useAppSelector((state) => state.automations.automationDraft.timeFormat);
  const availableWildCardOptions = useGetAvailableWildCards();
  const dispatch = useAppDispatch();
  const fieldErrors = useAppSelector((state) => state.automations.errors.notificationMessage);
  const errorText = useGetFieldErrorText(fieldErrors);

  const fieldIsInvalid = useMemo(() => {
    return Boolean(fieldErrors.length);
  }, [fieldErrors]);

  const defaultMessage = useMemo(() => {
    if (!automationType) return '';
    return defaultNotificationMessages[automationType];
  }, [automationType]);

  const handleToggleCustomizeNotificationBlock = useCallback(() => {
    setIsCustomNotificationVisible((prevState) => !prevState);
  }, []);

  const handleResetToDefaultNotification = useCallback(() => {
    if (!isCustomNotificationVisible) {
      dispatch(updateAutomationDraft({ notificationMessage: null, timeFormat: null }));

      if (fieldIsInvalid) {
        dispatch(updateAutomationErrors({ notificationMessage: [] }));
      }
    }
  }, [dispatch, isCustomNotificationVisible, fieldIsInvalid]);

  const handleCustomNotificationInputChange = useCallback(
    (value: string) => {
      dispatch(updateAutomationDraft({ notificationMessage: value }));

      if (fieldIsInvalid) {
        dispatch(updateAutomationErrors({ notificationMessage: [] }));
      }
    },
    [dispatch, fieldIsInvalid],
  );

  const handleTimeFormatValueChange = (selectedOption: SelectOptionProps): void => {
    dispatch(updateAutomationDraft({ timeFormat: selectedOption.value as TimeFormatValue }));
  };

  const inputValue = useMemo(() => {
    if (notificationMessage === null) {
      return defaultMessage || '';
    }

    return notificationMessage;
  }, [notificationMessage, defaultMessage]);

  useEffect(() => {
    if (notificationMessage || fieldIsInvalid) {
      setIsCustomNotificationVisible(true);
    }
  }, [notificationMessage, fieldIsInvalid]);

  return (
    <Box className="Automations__NotificationMessage-Container">
      <AutomationsFieldLabel>Notification Message</AutomationsFieldLabel>
      <AutomationsFieldDescription>
        Clients will receive both an email and text message notification when this reminder sends. View the default
        messages{' '}
        <Link target="_blank" href={`${ZENDESK_ARTICLE_BASE_URL}/4413045183245`}>
          here
        </Link>
        .
      </AutomationsFieldDescription>
      <Collapse
        in={isCustomNotificationVisible}
        style={{ overflow: 'visible' }}
        animateOpacity
        onAnimationComplete={handleResetToDefaultNotification}
      >
        <Box className="Automations__CustomNotification-Container" py={4}>
          <WildCardInputGroup
            inputProps={{
              inputType: WildcardInputTypes.TextArea,
              inputValue: inputValue,
              isInvalid: fieldIsInvalid,
              errorText: errorText,
              textAreaMinH: '160px',
              onInputValueChange: handleCustomNotificationInputChange,
              inputLabel: (
                <Text as="p" mb={2}>
                  Hi <Tag color="text.default" label="Client Name" />,
                </Text>
              ),
              inputFooter: (
                <Text as="p" mt={2}>
                  (Call to action with link to conversation)
                </Text>
              ),
            }}
            timeFormatProps={{
              onChange: handleTimeFormatValueChange,
              timeFormatValue: timeFormat || TimeFormatValue.DayAndDate,
            }}
            availableWildCards={availableWildCardOptions}
            containerProps={{
              p: 4,
              bg: 'background.alpha',
              borderRadius: 'base',
              border: '1px',
              borderColor: 'border.default',
            }}
          />
        </Box>
      </Collapse>
      {isCustomNotificationVisible ? (
        <Button
          className="Automations__CustomizeNotificationButton"
          variant="ghostDestructive"
          iconName="trashcan"
          onClick={handleToggleCustomizeNotificationBlock}
        >
          Reset to Default
        </Button>
      ) : (
        <Button
          className="Automations__CustomizeNotificationButton"
          variant="ghost"
          iconName="penSquare"
          onClick={handleToggleCustomizeNotificationBlock}
        >
          Customize Message
        </Button>
      )}
    </Box>
  );
};
