import { ApolloQueryResult } from '@apollo/client';
import {
  Modal,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  useToast,
  ModalFooter,
  Button,
} from '@televet/kibble-ui';
import { addDays, differenceInBusinessDays, differenceInCalendarDays } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { formatTimesStringToDateStringWithTimeZone } from 'pages/Settings/pages/AppointmentSettings/components/DirectBookingSettings/utils';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { GraphQLFetchPolicies } from 'shared/enums';
import { GA4Events } from 'shared/enums/GA4Events';
import useGA from 'shared/hooks/useGA';
import {
  Exact,
  FindFirstPayoutBatchingPeriodQuery,
  PayoutBatchingPeriodWhereInput,
  useCreateOnePayoutBatchingPeriodMutation,
  useFindMissingPayoutBatchingPeriodDatesQuery,
} from 'shared/types/graphql';
import PayoutSwitch from './PayoutSwitch';

interface isEODModalProps {
  onClose: () => void;
  isOpen: boolean;
  pimsName: string;
  previousEndsAt: string;
  clinicId?: string;
  clinicUserId?: string;
  refreshUI: (
    variables?:
      | Partial<
          Exact<{
            where?: PayoutBatchingPeriodWhereInput | null | undefined;
          }>
        >
      | undefined,
  ) => Promise<ApolloQueryResult<FindFirstPayoutBatchingPeriodQuery>>;
}

/**
 * This component displays a modal for setting "End of Day" (EOD) for a given clinic.
 *
 * The modal allows the user to select one or more payout dates that are missing an EOD entry.
 * Each date can be toggled on or off, determining whether an EOD entry will be created for that date.
 *
 * When the user submits the form, GraphQL mutations are invoked for each selected date to create the EOD entries.
 * Each entry contains:
 *  - startsAt: The starting date and time for the payout period
 *  - endsAt: The ending date and time for the payout period
 *  - clinic: The ID of the clinic for which the EOD is being set
 *
 * The time for each "endsAt" field is provided by the user and defaults to the current time.
 * The "startsAt" field for the first entry is set to the "previousEndsAt" prop, while for subsequent entries, it is set to the "endsAt" field of the previous entry.
 *
 */

const EODModal = ({
  onClose,
  isOpen,
  pimsName,
  previousEndsAt,
  clinicId,
  clinicUserId,
  refreshUI,
}: isEODModalProps): JSX.Element => {
  const toast = useToast();
  const { gaTrack } = useGA();
  const { data: missingPayoutBatchingPeriodDatesData, refetch: refreshMissingPayoutBatchingPeriodData } =
    useFindMissingPayoutBatchingPeriodDatesQuery({
      fetchPolicy: GraphQLFetchPolicies.CacheAndNetwork,
    });

  // an array of strings that represent the dates that are missing a payout batching period, ie. ['2021-08-01', '2021-08-02', '2021-08-03']
  const dates = useMemo(() => {
    return missingPayoutBatchingPeriodDatesData?.findMissingPayoutBatchingPeriodDates?.dates || [];
  }, [missingPayoutBatchingPeriodDatesData?.findMissingPayoutBatchingPeriodDates]);

  const [selectedDates, setSelectedDates] = useState<{ [date: string]: string }>({});

  useEffect(() => {
    if (dates.length > 0) {
      // set selectedDates to an object with each date from the dates array as a key and the current time as the value
      const current = new Date();
      setSelectedDates(
        dates.reduce((acc, date) => {
          const day = utcToZonedTime(date, 'UTC');
          const dayAtCurrentTime = new Date(day).setHours(current.getHours(), current.getMinutes());
          return {
            ...acc,
            [date]: new Date(dayAtCurrentTime).toISOString(),
          };
        }, {}),
      );
    }
  }, [dates]);

  const updateBatchingPeriod = useCallback((endsAt: string, time: Date, isChecked: boolean) => {
    if (isChecked) {
      setSelectedDates((prev) => ({ ...prev, [endsAt]: time.toISOString() }));
    } else {
      setSelectedDates((prev) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [endsAt]: _, ...rest } = prev;
        return rest;
      });
    }
  }, []);

  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false);

  const [createOnePayoutBatchingPeriod] = useCreateOnePayoutBatchingPeriodMutation();

  const handleClose = useCallback(() => {
    gaTrack(GA4Events.PAY_EOD_CANCEL);

    if (dates.length > 0) {
      // set selectedDates to an object with each date from the dates array as a key and the current time as the value again on close to reset state in the modal
      setSelectedDates(dates.reduce((acc, date) => ({ ...acc, [date]: new Date().toISOString() }), {}));
    }
    onClose();
  }, [dates, gaTrack, onClose]);

  const onSubmit = useCallback(() => {
    //create the data object for the mutation based on the selectedDates object
    setIsSubmitButtonLoading(true);
    let formattedPreviousEndsAt = new Date(previousEndsAt).toISOString(); // Format the date to ISO string
    Object.entries(selectedDates)
      .sort((prev, next) => {
        const prevTime = new Date(prev[0]).getTime();
        const nextTime = new Date(next[0]).getTime();
        return prevTime > nextTime ? 1 : nextTime > prevTime ? -1 : 0;
      })
      .forEach(([date, time], index) => {
        const data = {
          startsAt: formattedPreviousEndsAt,
          endsAt: time,
          createdByUser: { connect: { id: clinicUserId } },
          clinic: { connect: { id: clinicId } },
        };

        formattedPreviousEndsAt = data.endsAt;

        setSelectedDates((prev) => {
          return {
            ...prev,
            [date]: data.endsAt,
          };
        });

        // these methods remove the time from the date, so need to add 1 day to get accurate results since we go end of day to end of day, not start to start
        const numberOfWeekdays = differenceInBusinessDays(
          addDays(new Date(data.endsAt), 1),
          addDays(new Date(data.startsAt), 1),
        );
        const numberOfDays = differenceInCalendarDays(
          addDays(new Date(data.endsAt), 1),
          addDays(new Date(data.startsAt), 1),
        );

        gaTrack(GA4Events.PAY_EOD_BATCH_CREATE, {
          numberOfWeekdays,
          numberOfDays,
          numberOfBatches: Object.keys(selectedDates).length,
        });

        createOnePayoutBatchingPeriod({
          variables: { data },

          onCompleted: (responseData) => {
            toast({
              description: `End of Day successfully set for ${formatTimesStringToDateStringWithTimeZone(
                responseData.createOnePayoutBatchingPeriod.endsAt,
              )}!`,
              status: 'success',
              duration: 5000,
            });

            // if it is the last iteration, close the modal
            if (index === Object.entries(selectedDates).length - 1) {
              setIsSubmitButtonLoading(false);
              refreshMissingPayoutBatchingPeriodData();
              refreshUI();
              onClose();
            }
          },
          onError: (error) => {
            // Handle error scenario
            setIsSubmitButtonLoading(false); // Reset loading state on error
            toast({
              description: `Error: ${error.message}`,
              status: 'error',
              duration: 5000,
            });
          },
        });
      });
  }, [
    selectedDates,
    previousEndsAt,
    clinicUserId,
    clinicId,
    createOnePayoutBatchingPeriod,
    toast,
    refreshUI,
    onClose,
    refreshMissingPayoutBatchingPeriodData,
    gaTrack,
  ]);

  const isSubmitButtonDisabled = useMemo(() => {
    // if selectedDates has no entries
    return Object.keys(selectedDates).length === 0;
  }, [selectedDates]);

  return (
    <Modal onClose={handleClose} isOpen={isOpen} size="md" scrollBehavior="inside">
      <ModalHeader>End Day</ModalHeader>
      <ModalCloseButton />
      <ModalBody pb={5}>
        <Text mb={3}>
          {`Please select the end of day time that matches ${pimsName} for any relevant payout dates.`}
        </Text>
        {dates.map((date, index) => (
          <PayoutSwitch key={index} index={index} endsAt={date} updateBatchingPeriod={updateBatchingPeriod} />
        ))}
      </ModalBody>
      <ModalFooter justifyContent="space-between">
        <Button variant="tertiary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={onSubmit}
          isLoading={isSubmitButtonLoading}
          isDisabled={isSubmitButtonDisabled}
        >
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EODModal;
