import { ListItem, UnorderedList } from '@televet/kibble-ui/build/chakra';
import React from 'react';
import { availableSegmentsValues } from '../ConditionalSegments/constants/conditionalSegments.constants';
import { EmailCampaignSegmentDefinition } from '../ConditionalSegments/types/conditionalSegments.types';

type ConditionalSegmentsSummary = {
  conditionalSegments: EmailCampaignSegmentDefinition;
  additionalSegmentProps?: Record<string, unknown>;
};

const ConditionalSegmentSummary = ({ conditionalSegments, additionalSegmentProps }: ConditionalSegmentsSummary): JSX.Element => {
  return (
    <UnorderedList>
      {conditionalSegments.conditionalSegments?.map(({ segmentId, definitionVariables }) => {
        const segment = availableSegmentsValues.find((availableSegment) => availableSegment.segmentId === segmentId);
        if (segment) {
          const conditionValue = segment.parser
            ? segment.parser(definitionVariables.staticVariables)
            : definitionVariables.staticVariables;
          const Component = segment.component;

          return (
            <ListItem key={segmentId}>
              <Component conditionValue={conditionValue} asSummary={true} {...additionalSegmentProps} />
            </ListItem>
          );
        }

        return <></>;
      })}
    </UnorderedList>
  );
};

export default ConditionalSegmentSummary;
