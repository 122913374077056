import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  TextInput,
  Text,
  Button,
  Flex,
  Select,
  MenuItemProps,
  Center,
  Grid,
  GridItem,
  HStack,
  VStack,
} from '@televet/kibble-ui';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import {
  updateCurrentStep,
  updateTotalInvoiceSavings,
  updatePaymentDue,
  updateExamAmountToConsume,
  updateExamValueToConsume,
  updateIncludedExams,
  updateDraftExamTotals,
} from '../../../state/careWizardStepsSlice';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { CareBenefitType, SidePanelParentPetDataFragment } from 'shared/types/graphql';
import { UsePetBenefitCareBenefit, usePetBenefits } from '../../../hooks/usePetBenefits';
import LoadingSpinner from 'shared/components/LoadingSpinner';
import { useBenefitConnectedServices } from '../../../hooks/useBenefitConnectedServices';
import { formatMoney } from 'pages/Reporting/utils/formatMoney';
import { calculatePaymentDue } from '../utils/paymentCalculations';

interface IIncludedExamsScreenProps {
  currentStep: number;
  pet: SidePanelParentPetDataFragment | undefined;
  accountCredit: UsePetBenefitCareBenefit | undefined;
  percentDiscount: UsePetBenefitCareBenefit | undefined;
}

interface IIncludedExamsFormValues {
  includedExams: {
    type: string;
    value: string;
    benefitId: string;
  }[];
}

const IncludedExamsScreen = ({
  currentStep,
  pet,
  accountCredit,
  percentDiscount,
}: IIncludedExamsScreenProps): JSX.Element => {
  const originalInvoiceCost = useAppSelector((state) => state.CareWizardSteps.careWizardDraft.originalInvoiceCost);
  const includedExams = useAppSelector((state) => state.CareWizardSteps.careWizardDraft.includedExams);
  const draftExamTotals = useAppSelector((state) => state.CareWizardSteps.careWizardDraft.draftExamTotals);
  const [savingsGreaterThanCost, setSavingsGreaterThanCost] = useState(false);
  const { benefits, loading: loadingBenefits } = usePetBenefits(pet?.organizationPet?.id || '');
  const nextStep = !!accountCredit || !!percentDiscount ? currentStep + 1 : currentStep + 2;
  // if no accountCredit or percentDiscount is included in the benefits, skip the next screen

  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    register,
    control,
    setValue,
    errors,
    watch,
    reset,
    formState: { isValid },
  } = useForm<IIncludedExamsFormValues>({
    mode: 'onChange',
    defaultValues: {
      includedExams,
    },
  });

  const {
    fields: includedExamFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'includedExams',
  });

  useEffect(() => {
    reset({ includedExams });
  }, [includedExams, reset]);

  const includedExamsValues = watch('includedExams')?.map((exam) => Number(exam.value));

  useEffect(() => {
    // Calculate savings, whether savings are greater than cost, and payment due
    // function defined in src/shared/components/SidePanelSearch/components/SidePanelBenefits/CareWizardModal/utils/paymentCalculations.ts
    const { savings, savingsGreaterThanCost, paymentDue } = calculatePaymentDue(
      originalInvoiceCost,
      includedExamsValues,
    );

    // Update the savings greater than cost state
    setSavingsGreaterThanCost(savingsGreaterThanCost);

    // If savings are greater than the original invoice cost, exit the effect
    if (savingsGreaterThanCost) {
      return;
    }

    // Update the total invoice savings and payment due in the store
    dispatch(updateTotalInvoiceSavings(savings));
    dispatch(updatePaymentDue(paymentDue));
  }, [dispatch, includedExamsValues, originalInvoiceCost]);

  const onSubmit = useCallback(
    (data: IIncludedExamsFormValues) => {
      const savings = data.includedExams
        ? data.includedExams.map((exam) => Number(exam.value)).reduce((prev, next) => prev + next)
        : 0;
      dispatch(updateCurrentStep(nextStep));
      dispatch(updateExamAmountToConsume(data.includedExams?.length));
      dispatch(updateExamValueToConsume(savings));
    },
    [dispatch, nextStep],
  );

  const handleBack = useCallback(() => {
    dispatch(updateCurrentStep(currentStep - 1));
  }, [currentStep, dispatch]);

  const onTypeSelect = useCallback(
    (controlName: string, selectedItem: MenuItemProps): void => {
      setValue(controlName, selectedItem.value);
    },
    [setValue],
  );

  const examBenefits: UsePetBenefitCareBenefit[] = useMemo(
    // array of all benefits that are of type ProvidedService
    () => benefits.filter((benefit) => benefit.type === CareBenefitType.ProvidedService),
    [benefits],
  );
  const examBenefitIds = useMemo(() => examBenefits.map((benefit) => benefit.id), [examBenefits]);

  const { servicesByBenefitId, loading: loadingServices } = useBenefitConnectedServices(examBenefitIds);

  const examBenefitTypeOptions = useMemo(() => {
    return examBenefits.map((benefit) => {
      const totalUsed = draftExamTotals[benefit.id] || 0;
      return {
        label: benefit.title,
        value: benefit.id,
        tangible: benefit.tangible,
        isDisabled: totalUsed >= benefit.quantity && benefit.tangible,
      };
    });
  }, [examBenefits, draftExamTotals]);

  const handleAddExam = useCallback(() => {
    // Find the first available option that is not disabled.
    const availableOption = examBenefitTypeOptions.find((option) => !option.isDisabled);

    // If there's no available option, return to prevent adding a new exam.
    if (!availableOption) return;

    // Increment the count for the selected benefitId in draftExamTotals.
    const benefitId = availableOption.value;
    const updatedDraftExamTotals = {
      ...draftExamTotals,
      [benefitId]: (draftExamTotals[benefitId] || 0) + 1,
    };

    dispatch(updateDraftExamTotals(updatedDraftExamTotals));

    // Add the new exam with the available option.
    const exam = {
      type: servicesByBenefitId[benefitId]?.[0]?.id || '',
      value: '',
      benefitId: benefitId,
      tangible: availableOption.tangible,
    };

    append(exam);
    setValue(`includedExams[${includedExamFields.length}].type`, availableOption.value);
    dispatch(updateIncludedExams([...includedExams, exam]));
  }, [
    append,
    dispatch,
    includedExams,
    examBenefitTypeOptions,
    draftExamTotals,
    setValue,
    includedExamFields,
    servicesByBenefitId,
  ]);

  const handleRemoveExam = useCallback(
    (index: number) => {
      const benefitId = includedExams[index].benefitId;
      const updatedTotals = {
        ...draftExamTotals,
        [benefitId]: (draftExamTotals[benefitId] || 0) - 1,
      };

      // If the total becomes less than 0, delete the key
      if (updatedTotals[benefitId] < 0) {
        delete updatedTotals[benefitId];
      }

      dispatch(updateDraftExamTotals(updatedTotals));
      remove(index);
      dispatch(updateIncludedExams(includedExams?.filter((_, i) => i !== index)));
    },
    [dispatch, includedExams, remove, draftExamTotals],
  );

  const handleUpdateExamBenefitId = useCallback(
    (index: number, selectedItem: MenuItemProps) => {
      // update draftExamTotals
      // subtract 1 from the old benefitId and add 1 to the new benefitId
      // if new benefitId is not set in draftExamTotals, set it to 1
      // if selectedItem is disabled, return
      if (selectedItem.isDisabled) return;
      const oldBenefitId = includedExams[index].benefitId;
      const newBenefitId = selectedItem.value || '';
      const updatedTotals = {
        ...draftExamTotals,
        [oldBenefitId]: (draftExamTotals[oldBenefitId] || 0) - 1,
        [newBenefitId]: (draftExamTotals[newBenefitId] || 0) + 1,
      };
      dispatch(updateDraftExamTotals(updatedTotals));

      const newIncludedExams = includedExams?.map((exam, i) => {
        return i === index
          ? {
              type: servicesByBenefitId[newBenefitId]?.[0]?.id || '',
              value: exam.value,
              benefitId: selectedItem.value || '',
            }
          : exam;
      });
      dispatch(updateIncludedExams(newIncludedExams));
    },
    [dispatch, includedExams, draftExamTotals, servicesByBenefitId],
  );

  const handleUpdateExamValue = useCallback(
    (index: number, value: string) => {
      const newIncludedExams = includedExams?.map((exam, i) => {
        return i === index
          ? {
              type: exam.type,
              value,
              benefitId: exam.benefitId,
            }
          : exam;
      });
      dispatch(updateIncludedExams(newIncludedExams));
    },
    [dispatch, includedExams],
  );

  const handleUpdateExamType = useCallback(
    (index: number, selectedItem: MenuItemProps) => {
      onTypeSelect(`includedExams.${index}.type`, selectedItem);
      const newIncludedExams = includedExams.map((exam, i) => {
        return i === index
          ? {
              type: selectedItem.value || '',
              value: exam.value,
              benefitId: exam.benefitId,
            }
          : exam;
      });
      dispatch(updateIncludedExams(newIncludedExams));
    },
    [dispatch, includedExams, onTypeSelect],
  );

  const isAddExamButtonDisabled = useMemo(() => {
    // if one of the benefits is !tanigble, return false
    // if all of the benefits are tangible, check if the total used of each benefit is less than the quantity
    return (
      examBenefitTypeOptions.every((option) => option.isDisabled) &&
      !examBenefitTypeOptions.some((option) => !option.tangible)
    );
  }, [examBenefitTypeOptions]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box mb={4}>
        <Text fontWeight="bold">Are there eligible exam benefits included in today&apos;s visit?</Text>
      </Box>
      {loadingBenefits || loadingServices ? (
        <Center>
          <LoadingSpinner message="Loading Benefits..." />
        </Center>
      ) : (
        <>
          {!!includedExamFields.length && (
            <Grid templateColumns={'repeat(10, 1fr)'} gap={3} mb={4}>
              <GridItem />
              {includedExamFields.map((field, index) => (
                <React.Fragment key={field.id}>
                  {/* Exam Benefit Dropdown */}
                  <GridItem colSpan={8} colStart={1}>
                    <VStack alignItems="flex-start">
                      <Text>Exam Benefit</Text>
                      <Controller
                        as={Select}
                        options={examBenefitTypeOptions}
                        listProps={{
                          isSearchable: true,
                          onSelect: (selectedItem: MenuItemProps): void =>
                            handleUpdateExamBenefitId(index, selectedItem),
                        }}
                        control={control}
                        name={`includedExams.${index}.benefitId`}
                      />
                    </VStack>
                  </GridItem>
                  {!!servicesByBenefitId[includedExams[index].benefitId]?.length && (
                    <>
                      {/* Dynamically generate examTypeOptions based on the selected benefitId for this exam */}
                      {((): JSX.Element => {
                        const benefitId = includedExams[index]?.benefitId;
                        const examTypeOptions =
                          servicesByBenefitId[benefitId]?.map((service) => ({
                            label: service.name,
                            value: service.id,
                          })) || [];
                        return (
                          <>
                            {/* Exam Type Dropdown */}
                            <GridItem colSpan={8} colStart={1}>
                              <VStack alignItems="flex-start">
                                <Text>Exam Type</Text>
                                <Controller
                                  as={Select}
                                  options={examTypeOptions}
                                  listProps={{
                                    isSearchable: true,
                                    onSelect: (selectedItem: MenuItemProps): void =>
                                      handleUpdateExamType(index, selectedItem),
                                  }}
                                  defaultValue={examTypeOptions[0]?.value}
                                  control={control}
                                  name={`includedExams.${index}.type`}
                                />
                              </VStack>
                            </GridItem>
                          </>
                        );
                      })()}
                    </>
                  )}
                  <GridItem
                    colSpan={1}
                    colStart={!!servicesByBenefitId[includedExams[index].benefitId]?.length ? 9 : 1}
                  >
                    {/* Exam Value Input */}
                    <VStack alignItems="flex-start">
                      <Text>Exam Value</Text>
                      <HStack>
                        <TextInput
                          data-testid={`exam-cost-input-${index}`}
                          type="number"
                          step={0.01}
                          w={32}
                          placeholder="$0.00"
                          name={`includedExams.${index}.value`}
                          // is invalid if there is an error for this field or if value has more than 2 decimal places
                          isInvalid={
                            !!errors?.includedExams?.[index]?.value ||
                            !!includedExamFields[index]?.value?.match(/(\.\d{3,})/)
                          }
                          errorText={errors?.includedExams?.[index]?.value?.message?.toString()}
                          ref={register({
                            required: 'Please enter an exam value.',
                            min: { value: 0.01, message: 'Value must be greater than 0.' },
                            pattern: {
                              // regex to match 0.00 or 0.0 or 0
                              value: /^\d+(\.\d{1,2})?$/,
                              message: 'You cannot input more than two decimals.',
                            },
                          })}
                          onBlur={({ target }): void => handleUpdateExamValue(index, target.value)}
                        />
                        <GridItem colStart={3}>
                          <Button
                            variant="ghostNeutral"
                            iconName="close"
                            size="md"
                            onClick={(): void => handleRemoveExam(index)}
                          />
                        </GridItem>
                      </HStack>
                    </VStack>
                  </GridItem>
                </React.Fragment>
              ))}
            </Grid>
          )}

          <Button
            size="xs"
            variant="secondary"
            iconName="plus"
            onClick={handleAddExam}
            isDisabled={isAddExamButtonDisabled}
            data-testid="wizard-add-exam-button"
          >
            Add Exam
          </Button>
          {savingsGreaterThanCost && (
            <Box mt={4}>
              <Text variant="danger">{`Total included exams value cannot exceed invoice cost of ${formatMoney(
                originalInvoiceCost * 100,
              )}.`}</Text>
            </Box>
          )}
          <Box my={6}>
            <Text size="sm">
              Available Exams:{' '}
              <VStack alignItems="flex-start">
                {examBenefits.map((benefit) => (
                  <React.Fragment key={benefit.id}>
                    <HStack>
                      <Text size="sm" variant="subtle">
                        • {benefit.title} -
                      </Text>
                      <Text size="sm">
                        {benefit.tangible
                          ? draftExamTotals[benefit.id]
                            ? benefit.quantity - draftExamTotals[benefit.id]
                            : `${benefit.quantity}`
                          : 'Unlimited'}{' '}
                        Remaining
                      </Text>
                    </HStack>
                  </React.Fragment>
                ))}
              </VStack>
            </Text>
          </Box>
        </>
      )}

      <Flex pb={4} mt={4} justify="space-between">
        <Button variant="tertiary" onClick={handleBack} isDisabled={loadingBenefits || loadingServices}>
          Back
        </Button>
        <Button
          data-testid="wizard-exams-next-button"
          type="submit"
          isDisabled={!isValid || loadingBenefits || loadingServices || savingsGreaterThanCost}
        >
          Next
        </Button>
      </Flex>
    </form>
  );
};

export default React.memo(IncludedExamsScreen);
