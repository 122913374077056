import React, { useCallback, useEffect, useMemo } from 'react';
import difference from 'lodash-es/difference';
import PetAvatar from 'shared/components/Avatars/PetAvatar';
import {
  ChannelViewChannelMemberFragment,
  ChannelViewChannelPetFragment,
  useGetChannelPetsLazyQuery,
} from 'shared/types/graphql';
import useLocationState from 'shared/hooks/useLocationState';
import useLinkPatients from 'pages/Conversations/hooks/useLinkPatients';
import { getChannelParticipants } from 'pages/Conversations/utils';
import { useSidePanelSearch } from 'shared/components/SidePanel/components/SearchPanel/state/providers/SearchPanelProvider';
import { useSidePanel } from 'shared/components/SidePanel/state/providers/SidePanelProvider';
import { MenuItemProps, Menu, Text, HStack, Box } from '@televet/kibble-ui';
import { ChannelViewConversationPet } from './ChannelViewConversationPet';
import useClinicUser from 'shared/hooks/useClinicUser';
import sortBy from 'lodash-es/sortBy';
import { GraphQLFetchPolicies } from 'shared/enums';

interface IChannelViewPatientsProps {
  linkedChannelPets?: ChannelViewChannelPetFragment[];
  channelId?: string;
  channelMembers?: ChannelViewChannelMemberFragment[];
  refetchChannel: () => void;
}

const ChannelViewPatients = ({
  linkedChannelPets,
  channelId,
  channelMembers,
  refetchChannel,
}: IChannelViewPatientsProps): JSX.Element => {
  const { currentClinicId } = useClinicUser();
  const { addPatients, removePatients, patientsLoading } = useLinkPatients(channelId);
  const { channelPetParentIds } = getChannelParticipants(channelMembers);

  const handlePatientSelect = useCallback(
    async (selectedItem: MenuItemProps) => {
      if (!selectedItem.value) return;

      if (selectedItem.isSelected) {
        await addPatients([selectedItem.value]);
        return;
      } else {
        await removePatients([selectedItem.value]);
        return;
      }
    },
    [addPatients, removePatients],
  );

  const [getDropdownPetOptions, { data: pets }] = useGetChannelPetsLazyQuery({
    variables: {
      where: {
        id: {
          in: channelPetParentIds,
        },
      },
      petsWhere: {
        clinicId: { equals: currentClinicId || '' },
        isDeleted: { equals: false },
      },
    },
    fetchPolicy: GraphQLFetchPolicies.CacheAndNetwork,
  });

  const allChannelPets = useMemo(() => {
    if (!pets?.findManyClinicPetParent) return [];
    return pets.findManyClinicPetParent.map((petParents) => petParents.pets).flat();
  }, [pets]);

  const patientOptions = useMemo(() => {
    if (!allChannelPets) return [];

    const petList = allChannelPets.map((pet) => ({
      label: pet.name,
      leftElement: <PetAvatar species={pet?.species} isDeceased={pet?.computedIsDeceased} iconOnly />,
      value: pet.id,
      isSelected: !!linkedChannelPets?.find((p) => p.id === pet.id),
      groupLabel: pet.computedIsActive ? 'Active' : 'Inactive',
    }));

    return sortBy(petList, ['groupLabel']);
  }, [allChannelPets, linkedChannelPets]);

  const [{ linkPatientsToConversation }, setLocationState] = useLocationState();

  useEffect(() => {
    if (linkPatientsToConversation && linkPatientsToConversation.length > 0) {
      getDropdownPetOptions();
    }
  }, [getDropdownPetOptions, linkPatientsToConversation]);

  useEffect(() => {
    if (!linkPatientsToConversation?.length || !patientOptions?.length) return;
    const patientIdsToLink = difference(
      linkPatientsToConversation,
      patientOptions.filter((o) => o.isSelected).map((o) => o.value),
    );
    setLocationState({ linkPatientsToConversation: null });
    if (patientIdsToLink.length) {
      addPatients(patientIdsToLink);
    }
  }, [linkPatientsToConversation, patientOptions, addPatients, setLocationState]);

  const { viewClinicPet } = useSidePanelSearch();
  const { setIsOpen } = useSidePanel();

  const goToPet = useCallback(
    async (clinicPetId: string): Promise<void> => {
      viewClinicPet({ clinicPetId });
    },
    [viewClinicPet],
  );

  const handlePetClick = (id: string): void => {
    if (id) {
      setIsOpen(true);
      goToPet(id);
    }
  };

  return (
    <HStack
      className="ChannelView__PatientsContainer"
      align="center"
      px={4}
      py={2}
      bg="background.subtle"
      borderBottom="1px"
      borderBottomColor="border.default"
    >
      <Box>
        <Menu
          buttonProps={{
            variant: 'secondary',
            text: 'Link Patient',
            size: 'sm',
            showRightIcon: false,
            mr: 2,
          }}
          listProps={{
            isMultiSelect: true,
            isSearchable: patientOptions.length > 5,
            menuItems: patientOptions || [],
            isLoading: patientsLoading,
            onSelect: handlePatientSelect,
            isGrouped: true,
          }}
          containerProps={{
            onOpen: getDropdownPetOptions,
          }}
        />
      </Box>
      <Text w="max-content" display={['none', 'none', 'block']}>
        Conversation for
      </Text>
      <HStack wrap="wrap">
        {!!linkedChannelPets?.length &&
          linkedChannelPets.map((pet) => (
            <ChannelViewConversationPet
              key={pet.id}
              pet={pet}
              handlePetClick={handlePetClick}
              refetchChannel={refetchChannel}
            />
          ))}
      </HStack>
    </HStack>
  );
};

export default ChannelViewPatients;
