import { ConditionAttribute } from 'shared/types/graphql';
import { getVisibleAutomationFields } from '../../state/utils/getVisibleAutomationFields';
import { getVisibleSendConditions } from '../../state/utils/getVisibleSendConditions';
import { AutomationDraft } from '../../types/AutomationDraft';
import { Errors } from '../../types/AutomationValidation';
import { getConditionHasValue } from '../getConditionHasValue';
import { getFlattenedAutomationErrors } from './getFlattenedAutomationErrors';
import { validateAutomationPrompts } from './validateAutomationPrompts';

export function validateAutomation({
  automationName,
  automationType,
  triggerType,
  sendConditions,
  forms,
  requestOptions,
  notificationMessage,
  prompts,
}: Pick<
  AutomationDraft,
  | 'automationName'
  | 'automationType'
  | 'triggerType'
  | 'sendConditions'
  | 'forms'
  | 'requestOptions'
  | 'notificationMessage'
  | 'prompts'
>): {
  errors: Errors;
  isValid: boolean;
} {
  const errors: Errors = {
    automationName: [],
    automationType: [],
    sendConditionContainer: [],
    sendConditions: {
      [ConditionAttribute.AppointmentTypeId]: [],
      [ConditionAttribute.ClinicEmployeeId]: [],
      [ConditionAttribute.ClinicRoomId]: [],
      [ConditionAttribute.SpeciesType]: [],
      [ConditionAttribute.PetAgeInYears]: [],
    },
    forms: [],
    requestTypes: [],
    notificationMessage: [],
    promptContainer: [],
    prompts: [],
  };

  const fieldIsVisible = getVisibleAutomationFields({ automationType, triggerType });

  // Name field is empty
  if (!automationName) {
    errors.automationName.push('nameEmpty');
  }

  // Type field is empty
  if (!automationType) {
    errors.automationType.push('typeEmpty');
  }

  if (fieldIsVisible.sendConditions) {
    const visibleSendConditions = getVisibleSendConditions(sendConditions);

    // No send conditions specified
    if (!visibleSendConditions.length) {
      errors.sendConditionContainer.push('sendConditionsEmpty');
    } else {
      // No conditions selected for send condition type
      visibleSendConditions.forEach((condition) => {
        const hasValue = getConditionHasValue(condition);

        if (!hasValue) {
          switch (condition.attribute) {
            case ConditionAttribute.AppointmentTypeId:
              errors.sendConditions.AppointmentTypeId.push('appointmentTypesEmpty');
              break;
            case ConditionAttribute.ClinicEmployeeId:
              errors.sendConditions.ClinicEmployeeId.push('appointmentProvidersEmpty');
              break;
            case ConditionAttribute.ClinicRoomId:
              errors.sendConditions.ClinicRoomId.push('appointmentRoomsEmpty');
              break;
            case ConditionAttribute.SpeciesType:
              errors.sendConditions.SpeciesType.push('patientSpeciesEmpty');
              break;
            case ConditionAttribute.PetAgeInYears:
              errors.sendConditions.PetAgeInYears.push('patientAgeEmpty');
              break;
          }
        }
      });
    }
  }

  // No form selected
  if (fieldIsVisible.forms) {
    if (!forms || forms.length <= 0) {
      errors.forms.push('formsEmpty');
    }
  }

  // No request option selected
  if (fieldIsVisible.requestOptions) {
    if (!requestOptions || requestOptions.length <= 0) {
      errors.requestTypes.push('requestsEmpty');
    }
  }

  // Notification message empty
  if (fieldIsVisible.notificationBody) {
    if (notificationMessage === '') {
      errors.notificationMessage.push('notificationMessageEmpty');
    }
  }

  // No prompts specified
  if (!prompts.length) {
    errors.promptContainer.push('promptsEmpty');
  } else {
    // Validate prompts
    errors.prompts = validateAutomationPrompts(prompts);
  }

  const flattenedErrors = getFlattenedAutomationErrors(errors);
  const isValid = !flattenedErrors?.generalErrors.length && !flattenedErrors?.promptErrors?.length;

  return {
    errors,
    isValid,
  };
}
