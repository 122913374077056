import { Text } from '@televet/kibble-ui/build/components/Text';
import { ModalBody, ModalFooter } from '@televet/kibble-ui/build/components/Modal';
import React, { useEffect, useMemo } from 'react';
import {
  CancelReasonType,
  CareEnrollmentFragment,
  CareSubscriptionTrueUpType,
  ManageMembershipRequestType,
  PaymentSchedule,
  SupportEventStatus,
  useCancelCareSubscriptionMutation,
  useCreateEnrollmentSupportEventMutation,
  useGetCareEnrollmentInfoQuery,
  useUpdateOneClinicPetMutation,
} from 'shared/types/graphql';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { Flex } from '@televet/kibble-ui/build/chakra';
import { useToast } from '@televet/kibble-ui/build/components/Toast';
import { useSidePanelSearch } from 'shared/components/SidePanel/components/SearchPanel/state/providers/SearchPanelProvider';
import { ManageMembershipStep, useManageMembershipContext } from '../context/ManageMembershipContext';
import useGA from 'shared/hooks/useGA';
import { GA4Events } from 'shared/enums/GA4Events';
import { centsToDollars, dollarsToCents } from '../../../utils/currency';
import { getMonthlyAmountDue } from '../utils';
import { RefundOptions } from '../types';

interface CancelMembershipStepProps {
  clinicPetId: string;
  careStripeSubscriptionId: string;
  enrollmentId: string;
  enrollment?: CareEnrollmentFragment;
  requesterId: string;
  handleClose: () => void;
  refetchPetData: () => void;
}

const CancelMembershipStep = ({
  clinicPetId,
  careStripeSubscriptionId,
  enrollmentId,
  enrollment,
  requesterId,
  handleClose,
  refetchPetData,
}: CancelMembershipStepProps): JSX.Element => {
  const { gaTrack } = useGA();
  const { form, submitText, balanceDue, handleNext, handleBack, hasBenefitUsages, paymentData } =
    useManageMembershipContext();
  const cancelReason = form.cancelReason;
  const toast = useToast();
  const { viewClinicPet } = useSidePanelSearch();
  const [cancelCareSubscription, { loading }] = useCancelCareSubscriptionMutation();
  const [createEnrollmentSupportEvent] = useCreateEnrollmentSupportEventMutation();
  const [updateClinicPet] = useUpdateOneClinicPetMutation();

  const { data: enrollmentData } = useGetCareEnrollmentInfoQuery({
    variables: {
      enrollmentId,
    },
    skip: !enrollmentId,
  });

  useEffect(() => {
    gaTrack(GA4Events.MEMBERSHIP_MANAGE_VIEW_CANCEL_STEP, { cancelReason });
  }, [cancelReason, gaTrack]);

  const handleCancel = async (): Promise<void> => {
    try {
      let numberOfPayments = 1;
      let amount = balanceDue || 0;
      let finalMonthAmount = undefined;

      if (
        paymentData.paymentSchedule === PaymentSchedule.OverTime &&
        paymentData.paymentType === CareSubscriptionTrueUpType.Internal
      ) {
        const monthlyAmountDue = getMonthlyAmountDue({
          plan: enrollmentData?.findUniqueCarePlanEnrollment?.plan,
          balanceDue,
        });
        numberOfPayments = monthlyAmountDue.numberOfPayments;
        amount = monthlyAmountDue.monthlyAmountDue;

        if (monthlyAmountDue.remainder > 0) {
          finalMonthAmount = monthlyAmountDue.remainder;
        }
      }

      const startDate =
        paymentData.paymentSchedule === PaymentSchedule.OverTime && paymentData?.billingDate
          ? paymentData.billingDate
          : new Date();

      const res = await cancelCareSubscription({
        variables: {
          data: {
            careSubscriptionId: careStripeSubscriptionId,
            allowRefund: form.allowRefund === RefundOptions.Refund,
            ...(balanceDue &&
              balanceDue > 0 &&
              startDate && {
                trueUpInformation: {
                  monthlyAmount: dollarsToCents(amount),
                  months: numberOfPayments,
                  ...(!!finalMonthAmount && { finalMonthAmount: dollarsToCents(finalMonthAmount) }),
                  firstDueDate: startDate.toISOString(),
                  paymentMethodId: paymentData.paymentMethodId,
                  trueUpType: paymentData.paymentType,
                },
              }),
          },
        },
      });

      if (res.data?.cancelCareSubscription?.success) {
        await createEnrollmentSupportEvent({
          variables: {
            data: {
              requester: { connect: { id: requesterId } },
              pointOfContact: { connect: { id: form.pointOfContactId } },
              manageMembershipRequestType: ManageMembershipRequestType.Cancel,
              paymentSchedule: paymentData.paymentSchedule,
              amount,
              cancelReason: form.cancelReason,
              manageMembershipDescription: submitText,
              carePlanEnrollment: { connect: { id: enrollmentId } },
              status: SupportEventStatus.Completed,
            },
          },
        });

        if (cancelReason === CancelReasonType.DeceasedPet) {
          await updateClinicPet({
            variables: {
              where: {
                id: clinicPetId,
              },
              data: {
                isDeceased: true,
                isActive: false,
                dateOfDeath: new Date().toISOString(),
              },
            },
          });
        }

        gaTrack(GA4Events.MEMBERSHIP_MANAGE_SUBMIT_CANCELLATION);

        toast({
          title: 'Success',
          status: 'success',
          description: 'Care Membership has been cancelled successfully.',
          duration: 5000,
          buttonProps: {
            buttonText: 'Dismiss',
            buttonPosition: 'bottomLeft',
            onClick: (): void => {
              toast.closeAll();
            },
          },
        });

        if (cancelReason === CancelReasonType.DeceasedPet) {
          viewClinicPet({ clinicPetId });
        }

        refetchPetData();
        handleClose();
      } else {
        const message = res.data?.cancelCareSubscription?.messages[0];
        toast({
          title: 'Error Cancelling Care Membership',
          status: 'error',
          description: message,
          duration: 5000,
          buttonProps: {
            buttonText: 'Dismiss',
            buttonPosition: 'bottomLeft',
            onClick: (): void => {
              toast.closeAll();
            },
          },
        });
      }
    } catch (error) {
      console.error(error);
      toast({
        title: 'Error',
        status: 'error',
        description: 'Error cancelling membership. Please try again.',
        duration: 5000,
        buttonProps: {
          buttonText: 'Dismiss',
          buttonPosition: 'bottomLeft',
          onClick: (): void => {
            toast.closeAll();
          },
        },
      });
    }
  };

  const paymentsMade = useMemo(() => {
    const amount = enrollment?.enrollmentPayments.reduce((acc, curr) => acc + curr.amount, 0) || 0;
    return centsToDollars(amount);
  }, [enrollment?.enrollmentPayments]);

  const description = useMemo(() => {
    let text = '';

    if (!hasBenefitUsages) {
      text = `No benefits have been used from this membership and no balance is owed. ${
        form.allowRefund === RefundOptions.Refund
          ? `The client will receive a refund of $${paymentsMade} for payments made. `
          : ''
      }When the membership is cancelled, the client will lose access to benefits starting immediately and will no longer be charged monthly.`;
    }

    if (hasBenefitUsages) {
      if (!balanceDue || balanceDue <= 0) {
        text =
          'When the membership is cancelled, the client will lose access to benefits starting immediately and will no longer be charged monthly.';
      } else {
        text =
          'Once payment is collected, the membership will be cancelled, the client will lose access to benefits starting immediately and will no longer be charged monthly.';
      }
    }

    if (cancelReason === CancelReasonType.DeceasedPet) {
      text += ' The patient will be marked deceased in Flow.';
    }

    return text;
  }, [hasBenefitUsages, cancelReason, form.allowRefund, paymentsMade, balanceDue]);

  return (
    <>
      <ModalBody py={5}>
        <Text as="p">{description}</Text>
        <Text as="p" fontWeight="semibold" mt={5}>
          Are you sure you would like to cancel this membership?
        </Text>
      </ModalBody>
      <ModalFooter justifyContent="space-between">
        <Button variant="tertiary" onClick={handleBack} isDisabled={loading}>
          Back
        </Button>
        <Flex>
          <Button
            variant="ghost"
            iconName="questionMarkCircle"
            mr={3}
            isDisabled={loading}
            onClick={(): void => handleNext(ManageMembershipStep.GetHelp)}
          >
            Get Help
          </Button>
          <Button variant="primaryDestructive" onClick={handleCancel} isLoading={loading}>
            Cancel Membership
          </Button>
        </Flex>
      </ModalFooter>
    </>
  );
};

export default CancelMembershipStep;
