import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, TextInput, Text, Button, Flex, Center, VStack, Divider, Icon } from '@televet/kibble-ui';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import {
  updateCurrentStep,
  updateRemainingCarePassValue,
  updateCareWizardDraft,
} from '../../../state/careWizardStepsSlice';
import { CareBenefitType, SidePanelParentPetDataFragment } from 'shared/types/graphql';
import { usePetBenefits } from '../../../hooks/usePetBenefits';
import LoadingSpinner from 'shared/components/LoadingSpinner';
import { getMonetaryDisplayValue } from 'shared/utils';
import { formatMoney, unFormatMoney } from 'pages/Reporting/utils/formatMoney';
import { usdCurrency } from 'shared/utils/validation';
import { validateNumber } from '../../ManageMembership/utils';

interface ICarePassAndDiscountsScreenProps {
  pet: SidePanelParentPetDataFragment | undefined;
}

const CarePassAndDiscountsScreen = ({ pet }: ICarePassAndDiscountsScreenProps): JSX.Element => {
  const [discountValue, setDiscountValue] = useState('$0.00');
  const showPercentDiscountForm = useAppSelector(
    (state) => state.CareWizardSteps.careWizardDraft.showPercentDiscountForm,
  );
  const totalInvoiceSavings = useAppSelector((state) => state.CareWizardSteps.careWizardDraft.totalInvoiceSavings);
  const currentStep = useAppSelector((state) => state.CareWizardSteps.careWizardDraft.currentStep);
  const paymentDue: number = useAppSelector((state) => state.CareWizardSteps.careWizardDraft.paymentDue);
  const carePassValueToConsume = useAppSelector(
    (state) => state.CareWizardSteps.careWizardDraft.carePassValueToConsume,
  );
  const additionalDiscountValueToConsume = useAppSelector(
    (state) => state.CareWizardSteps.careWizardDraft.additionalDiscountValueToConsume,
  );
  const includedExams = useAppSelector((state) => state.CareWizardSteps.careWizardDraft.examValueToConsume);
  const originalInvoiceCost = useAppSelector((state) => state.CareWizardSteps.careWizardDraft.originalInvoiceCost);
  const { benefits, loading } = usePetBenefits(pet?.organizationPet?.id || '');
  const dispatch = useAppDispatch();
  interface IValidationResponse {
    valid: boolean;
    message: string;
  }

  enum ValidationType {
    CarePass,
    PercentDiscount,
  }

  const accountCredits = useMemo(() => {
    // benefits that are type AccountCredit
    return benefits.filter((benefit) => benefit.type === CareBenefitType.AccountCredit);
  }, [benefits]);

  const isDataLoaded = useMemo(() => {
    // on initial page mount loading is false but benefits are actually not yet loaded, this will return true when benefits are loaded
    return benefits.length > 0 && !loading;
  }, [benefits.length, loading]);

  const initialCarePassValue = useMemo(() => {
    if (!accountCredits.length) {
      return 0;
    }
    return accountCredits
      .map((benefit) => {
        let amount = benefit.quantity;
        if (benefit.carryoverQuantity && benefit.carryoverQuantity > 0) {
          amount += benefit.carryoverQuantity;
        }

        return amount;
      })
      .reduce((prev, next) => prev + next);
  }, [accountCredits]);

  const availableCarePassAmount = useMemo(() => {
    const examAmountToConsume = includedExams ? includedExams : 0;
    const remainder: number = originalInvoiceCost - initialCarePassValue / 100 - examAmountToConsume;
    if (remainder <= 0) {
      return paymentDue * 100;
    } else {
      return initialCarePassValue;
    }
  }, [initialCarePassValue, paymentDue, originalInvoiceCost, includedExams]);

  const percent = useMemo(() => {
    // benefits that are type Discount and if they have a discountPercentage of null or undefined, return 0
    const percentBenefit = benefits.find(
      (benefit) => benefit.type === CareBenefitType.Discount && !!benefit.discountPercentage,
    );
    if (percentBenefit && percentBenefit.discountPercentage) {
      const percent = percentBenefit.discountPercentage;
      return percent;
    }
    return 0;
  }, [benefits]);

  const maxPercentDiscount = useMemo(() => {
    // change to string and remove any characters after the hundredths place
    const maxPercentDiscount = ((paymentDue / 100) * percent).toFixed(2);
    return parseFloat(maxPercentDiscount);
  }, [paymentDue, percent]);

  const [carePassValue, setCarePassValue] = React.useState(formatMoney(availableCarePassAmount));

  useEffect(() => {
    if (carePassValueToConsume <= 0) {
      setCarePassValue(formatMoney(availableCarePassAmount));
    }
  }, [availableCarePassAmount, carePassValueToConsume, maxPercentDiscount]);

  useEffect(() => {
    if (percent && maxPercentDiscount && showPercentDiscountForm) {
      if (additionalDiscountValueToConsume > 0) {
        setDiscountValue(formatMoney(additionalDiscountValueToConsume * 100));
      } else {
        setDiscountValue(formatMoney(maxPercentDiscount * 100));
      }
    }
  }, [percent, maxPercentDiscount, showPercentDiscountForm, additionalDiscountValueToConsume, accountCredits.length]);

  useEffect(() => {
    if (isDataLoaded && (initialCarePassValue === 0 || accountCredits.length === 0)) {
      dispatch(
        updateCareWizardDraft({
          showPercentDiscountForm: true,
        }),
      );
    }
  }, [accountCredits.length, dispatch, initialCarePassValue, isDataLoaded, loading]);

  const onSubmit = useCallback(() => {
    const carePassValueNumber = unFormatMoney(carePassValue);
    const discountValueNumber = unFormatMoney(discountValue);
    if ((carePassValueNumber * 100 <= availableCarePassAmount && !showPercentDiscountForm) || percent === 0) {
      // if the carePassValue is not equal to the availableCarePassAmount or the percent === 0 (meaning there is no percent benefit), skip showPercentDiscount
      dispatch(
        updateCareWizardDraft({
          totalInvoiceSavings: totalInvoiceSavings + carePassValueNumber,
          paymentDue: paymentDue - carePassValueNumber,
          carePassValueToConsume: carePassValueNumber,
          additionalDiscountValueToConsume: 0,
        }),
      );
    } else {
      //if the carePassValue is equal to availableCarePassValue && percent is not 0, then totalInvoiceSavings and paymentDue and carePassValueToConsume will have already been updated to reflect carePassValue
      dispatch(
        updateCareWizardDraft({
          totalInvoiceSavings: totalInvoiceSavings + discountValueNumber,
          paymentDue: paymentDue - discountValueNumber,
          additionalDiscountValueToConsume: discountValueNumber,
          showPercentDiscountForm: true,
        }),
      );
    }
    updateRemainingCarePassValue(initialCarePassValue - carePassValueNumber);
    dispatch(updateCurrentStep(currentStep + 1));
  }, [
    carePassValue,
    discountValue,
    availableCarePassAmount,
    showPercentDiscountForm,
    percent,
    initialCarePassValue,
    dispatch,
    currentStep,
    totalInvoiceSavings,
    paymentDue,
  ]);

  const handleBack = useCallback(() => {
    if (showPercentDiscountForm && initialCarePassValue > 0) {
      dispatch(
        updateCareWizardDraft({
          totalInvoiceSavings: totalInvoiceSavings - additionalDiscountValueToConsume - carePassValueToConsume,
          paymentDue: paymentDue + additionalDiscountValueToConsume + carePassValueToConsume,
          carePassValueToConsume: 0,
          additionalDiscountValueToConsume: 0,
          showPercentDiscountForm: false,
        }),
      );
    } else {
      dispatch(updateCurrentStep(currentStep - 1));
    }
  }, [
    additionalDiscountValueToConsume,
    carePassValueToConsume,
    currentStep,
    dispatch,
    initialCarePassValue,
    paymentDue,
    showPercentDiscountForm,
    totalInvoiceSavings,
  ]);

  const handleNext = (): void => {
    const carePassValueNumber = carePassValueToConsume > 0 ? carePassValueToConsume : unFormatMoney(carePassValue);
    // if carePassValue === availableCarePassValue, (and doesn't equal the total paymentDue) setShowPercent == true and update variables, if carePassValue < availableCarePassValue call onSubmit
    if (
      carePassValueNumber !== paymentDue &&
      carePassValueNumber === availableCarePassAmount / 100 &&
      !showPercentDiscountForm &&
      percent > 0
    ) {
      setDiscountValue(formatMoney(maxPercentDiscount));
      dispatch(
        updateCareWizardDraft({
          totalInvoiceSavings: totalInvoiceSavings + carePassValueNumber,
          paymentDue: paymentDue - carePassValueNumber,
          carePassValueToConsume: availableCarePassAmount / 100,
          showPercentDiscountForm: true,
        }),
      );
    } else {
      onSubmit();
    }
  };

  const handleCarePassChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = Number(e.target.value);
    // if the value is NaN, set it to an empty string to trigger the error message
    if (isNaN(value)) {
      setCarePassValue('');
      return;
    }
    setCarePassValue(formatMoney(value * 100));
    validateValue(value, ValidationType.CarePass);
    updateCareWizardDraft({
      carePassValueToConsume: value * 100,
    });
  };

  const handlePercentDiscountChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = Number(e.target.value);
    // if the value is NaN, set it to an empty string to trigger the error message
    if (isNaN(value)) {
      setDiscountValue('');
      return;
    }
    setDiscountValue(formatMoney(value * 100));
    updateCareWizardDraft({
      additionalDiscountValueToConsume: value * 100,
    });
  };

  const validateValue = (value: number, validationType: ValidationType): IValidationResponse => {
    const numberValidation = validateNumber(value);
    if (!numberValidation.valid) {
      return numberValidation;
    }

    switch (validationType) {
      case ValidationType.CarePass:
        if (value > availableCarePassAmount / 100) {
          return {
            valid: false,
            message: `You cannot input a value above the remaining balance of available credit.`,
          };
        }
        break;
      case ValidationType.PercentDiscount:
        if (value > maxPercentDiscount && maxPercentDiscount > 0) {
          return {
            valid: false,
            message: `You cannot input a number above ${percent}% of the remaining value.`,
          };
        }
        break;
    }

    return {
      valid: true,
      message: ``,
    };
  };

  return (
    <form onSubmit={onSubmit}>
      {accountCredits?.length > 0 && (
        <VStack mb={4} alignItems="flex-start">
          <Text fontWeight="bold">{`How much of ${
            pet ? pet.name : 'your pet'
          }’s Instant Account Credit should be used?`}</Text>
          <Text size="xs">*Instant Account Credit must be fully consumed before bonus discount can be added</Text>
        </VStack>
      )}
      {loading ? (
        <Center>
          <LoadingSpinner message="Loading Benefits..." />
        </Center>
      ) : (
        <>
          {accountCredits?.length > 0 && (
            <>
              <TextInput
                type="number"
                data-testid="apply-account-credit-input"
                pattern={`${usdCurrency}`}
                max={availableCarePassAmount / 100}
                w={32}
                onBlur={(e: React.FocusEvent<HTMLInputElement>): void => {
                  // if the user deletes the value, or if they input an e (only allowed letter in number input) the e.target.value will return '' and we must set it to an empty string to trigger the error message
                  if (e.target.value === '') {
                    setCarePassValue('');
                  }
                  setCarePassValue(e.target.value);
                }}
                label="Amount to Apply"
                defaultValue={(availableCarePassAmount / 100).toFixed(2)}
                isDisabled={showPercentDiscountForm || availableCarePassAmount === 0}
                onChange={handleCarePassChange}
                isInvalid={
                  !validateValue(unFormatMoney(carePassValue), ValidationType.CarePass).valid || carePassValue === ''
                }
                errorText={
                  carePassValue === ''
                    ? 'Please enter a valid number.'
                    : validateValue(unFormatMoney(carePassValue), ValidationType.CarePass).message
                }
                name="carePass"
                leftElement={<Icon name="dollar" size="md" />}
              />
              <Box my={6}>
                <Text>{`Of Available Credit: ${getMonetaryDisplayValue(initialCarePassValue, true)}`}</Text>
              </Box>
            </>
          )}

          {showPercentDiscountForm && percent > 0 && (
            <>
              {accountCredits?.length > 0 && <Divider my={10} />}
              <Text fontWeight="bold">
                {`The remaining ${formatMoney(paymentDue * 100)} on the invoice total for ${
                  pet?.name ? pet.name : 'your pet'
                } qualifies for ${percent}% off`}{' '}
              </Text>
              <Flex pb={4} mt={4}>
                <VStack justifyContent="flex-start" alignItems="baseline">
                  <TextInput
                    type="number"
                    pattern={`${usdCurrency}`}
                    w={32}
                    label="Discount Amount"
                    defaultValue={(additionalDiscountValueToConsume > 0
                      ? additionalDiscountValueToConsume
                      : maxPercentDiscount
                    ).toFixed(2)}
                    onBlur={(e: React.FocusEvent<HTMLInputElement>): void => {
                      // if the user deletes the value, or if they input an e (only allowed letter in number input) the e.target.value will return '' and we must set it to an empty string to trigger the error message
                      if (e.target.value === '') {
                        setDiscountValue('');
                      }
                      setDiscountValue(e.target.value);
                    }}
                    onChange={handlePercentDiscountChange}
                    isDisabled={percent === 0 || maxPercentDiscount === 0}
                    isInvalid={
                      !validateValue(unFormatMoney(discountValue), ValidationType.PercentDiscount).valid ||
                      discountValue === ''
                    }
                    name="discounts"
                    errorText={
                      discountValue === ''
                        ? 'Please enter a valid number.'
                        : validateValue(unFormatMoney(discountValue), ValidationType.PercentDiscount).message
                    }
                    leftElement={<Icon name="dollar" size="md" />}
                  />
                  <VStack justifyContent="flex-start">
                    <Text mt="6" alignSelf="flex-start" color="text.subtle">{`Plan Discount: ${percent}%`}</Text>

                    <Text alignSelf="flex-end" size="xs">{`${percent}% of this remaining balance is ${formatMoney(
                      maxPercentDiscount * 100,
                    )}`}</Text>
                  </VStack>
                </VStack>
              </Flex>
            </>
          )}
        </>
      )}
      <Flex pb={4} mt={4} justify="space-between">
        <Button
          variant="tertiary"
          onClick={handleBack}
          isDisabled={
            loading ||
            !validateValue(unFormatMoney(carePassValue), ValidationType.CarePass).valid ||
            !validateValue(unFormatMoney(discountValue), ValidationType.PercentDiscount).valid ||
            carePassValue === '' ||
            discountValue === ''
          }
        >
          Back
        </Button>
        <Button
          data-testid="wizard-discount-next-button"
          onClick={handleNext}
          isDisabled={
            loading ||
            !validateValue(unFormatMoney(carePassValue), ValidationType.CarePass).valid ||
            !validateValue(unFormatMoney(discountValue), ValidationType.PercentDiscount).valid ||
            carePassValue === '' ||
            discountValue === ''
          }
        >
          Next
        </Button>
      </Flex>
    </form>
  );
};

export default CarePassAndDiscountsScreen;
