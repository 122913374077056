import React, { createContext, ReactNode, useCallback, useContext, useMemo } from 'react';
import {
  ChannelUnreadMessageCountResult,
  useGetUnreadMessageCountQuery,
  UnreadMessageChannel,
  useUpdateChannelMemberMutation,
} from 'shared/types/graphql';
import { GraphQLFetchPolicies } from 'shared/enums/GraphQLFetchPolicies';
import { useAuth } from 'shared/providers/AuthProvider';
import useClinicUser from 'shared/hooks/useClinicUser';

interface IUnreadMessageProvider {
  unreadChannelsData: ChannelUnreadMessageCountResult;
  refetchUnreadChannelsData: () => void;
  updateLastConsumedMessageIndex: (messageIndex: number, channelMemberId: string) => void;
  getCurrentChannelUnreadMessagesData: (channelId: string) => UnreadMessageChannel;
}

const defaultState = {
  unreadChannels: [],
  total: 0,
  client: {
    total: 0,
    unreadChannels: [],
  },
  team: {
    total: 0,
    unreadChannels: [],
  },
};

const UnreadMessageCountContext = createContext<IUnreadMessageProvider>({
  unreadChannelsData: defaultState,
  refetchUnreadChannelsData: (): void => {
    throw new Error('refetchData(): void not implemented');
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateLastConsumedMessageIndex: (messageIndex: number, channelMemberId: string): void => {
    throw new Error('updateLastConsumedMessageIndex(): void not implemented');
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getCurrentChannelUnreadMessagesData: (channelId: string): UnreadMessageChannel => {
    throw new Error('updateLastConsumedMessageIndex(): void not implemented');
  },
});

export const useUnreadMessageCountProvider = (): IUnreadMessageProvider =>
  useContext<IUnreadMessageProvider>(UnreadMessageCountContext);

interface UnreadMessageCountProviderProps {
  children: ReactNode;
}

const UnreadMessageCountProvider = ({ children }: UnreadMessageCountProviderProps): JSX.Element => {
  const { isImpersonating } = useAuth();

  const { currentClinicId } = useClinicUser();
  const { data, refetch, client } = useGetUnreadMessageCountQuery({
    skip: !currentClinicId,
    fetchPolicy: GraphQLFetchPolicies.CacheFirst,
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      /**
       * Explicitly invalidate the cache when results return from the network
       * https://github.com/apollographql/apollo-client/issues/7994
       */
      client.cache.modify({
        id: client.cache.identify({ __typename: 'ChannelUnreadMessageCountResult' }),
        fields(fieldValue, details) {
          return details.INVALIDATE;
        },
      });
    },
  });

  const unreadChannelsData = useMemo(() => {
    if (data && data.channelUnreadMessageCount) {
      return data.channelUnreadMessageCount;
    }

    return defaultState;
  }, [data]);

  const [updateChannelMember] = useUpdateChannelMemberMutation();

  const updateLastConsumedMessageIndex = useCallback(
    async (consumedMessageIndex: number, channelMemberId: string) => {
      if (document.visibilityState !== 'visible' || consumedMessageIndex === null) return;

      if (!isImpersonating) {
        try {
          await updateChannelMember({
            variables: {
              where: {
                id: channelMemberId,
              },
              data: {
                lastConsumedMessageIndex: consumedMessageIndex,
                sendMessageNotification: true,
              },
            },
          });

          refetch();
        } catch {
          console.log('Error updating channel member');
        }
      }
    },
    [refetch, updateChannelMember, isImpersonating],
  );

  const getCurrentChannelData = useCallback(
    (channelId: string) => {
      const channelData = unreadChannelsData?.unreadChannels.find((channel) => channel?.channelId === channelId);
      if (channelData) {
        return channelData;
      }

      return {
        channelId: '',
        lastConsumedMessageIndex: null,
        unreadMessageCount: 0,
      };
    },
    [unreadChannelsData],
  );

  return (
    <UnreadMessageCountContext.Provider
      value={{
        unreadChannelsData,
        refetchUnreadChannelsData: refetch,
        updateLastConsumedMessageIndex,
        getCurrentChannelUnreadMessagesData: getCurrentChannelData,
      }}
    >
      {children}
    </UnreadMessageCountContext.Provider>
  );
};

export default UnreadMessageCountProvider;
