import React, { useState, MouseEvent, useMemo } from 'react';
import styled from 'styled-components/macro';
import { useModal } from '@televet/televet-ui';
import { ModalNames } from 'shared/enums/ModalNames';
import RangeCalendar from 'shared/components/RangeCalendar';
import DayScheduler from './DayScheduler';
import Selection, { ISelectionOption } from 'shared/components/Selection';
import { format, parseISO } from 'date-fns';
import useAppointmentGrouping from 'shared/components/SidePanel/components/SchedulerPanel/hooks/useAppointmentGrouping';
import { ExtendedAppointment } from 'pages/Appointments';
import { UserAppointmentGroupingFullSelectionFragment } from 'shared/types/graphql';
import { CalendarToggleDefault } from 'assets/icons';
import useAppointmentCalendarCurrentDay from 'pages/Appointments/hooks/useAppointmentCalendarCurrentDay';

const SidePanelScheduler = (): JSX.Element => {
  const [userAppointmentGroupingFilter, setUserAppointmentGroupingFilter] = useState<ISelectionOption>({
    label: 'All',
    value: '',
  });
  const { setCurrentDay } = useAppointmentCalendarCurrentDay();
  const [calendarIsCollapsed, setCalendarIsCollapsed] = useState(false);
  const [
    { appointments, userAppointmentGroupings, appointmentFilters },
    { updateAppointmentFilterDateRange },
    { getFilteredAppointmentsInUserAppointmentGroupings },
  ] = useAppointmentGrouping();
  const { openModal, modal } = useModal();

  const userAppointmentGroupingFilterOptions = useMemo(() => {
    const options: ISelectionOption[] = [{ label: 'All', value: '' }];
    userAppointmentGroupings.forEach((userAppointmentGrouping: UserAppointmentGroupingFullSelectionFragment) => {
      options.push({
        label: userAppointmentGrouping.label || 'Untitled',
        value: userAppointmentGrouping.id,
      });
    });
    return options;
  }, [userAppointmentGroupings]);

  const filteredAppointments = useMemo(() => {
    const userAppointmentGrouping = userAppointmentGroupings.find(
      (ug) => ug.id === userAppointmentGroupingFilter.value,
    );
    if (userAppointmentGrouping) {
      const { rooms, appointmentTypes, clinicEmployees } = userAppointmentGrouping;
      return getFilteredAppointmentsInUserAppointmentGroupings(
        rooms || [],
        appointmentTypes || [],
        clinicEmployees || [],
        appointments,
      );
    }
    return appointments;
  }, [
    appointments,
    getFilteredAppointmentsInUserAppointmentGroupings,
    userAppointmentGroupingFilter,
    userAppointmentGroupings,
  ]);

  const onDateChange = (startDate: Date | null, endDate: Date | null): void => {
    if (startDate && endDate) {
      updateAppointmentFilterDateRange(startDate, endDate);
      setCurrentDay(startDate);
    }
  };

  const onAppointmentClicked = (e: MouseEvent, appointment: ExtendedAppointment): void => {
    openModal(ModalNames.Appointment, {
      startDate: parseISO(appointment.startAt),
      durationInMinutes: appointment.durationInMinutes,
      defaultAppointmentDurationInMinutes: appointmentFilters.defaultAppointmentDurationInMinutes,
      appointmentId: appointment.id,
    });
  };

  const onAddAppointment = (e: MouseEvent, startDate: Date, durationInMinutes: number): void => {
    openModal(ModalNames.Appointment, {
      startDate,
      defaultAppointmentDurationInMinutes: appointmentFilters.defaultAppointmentDurationInMinutes,
      durationInMinutes,
    });
  };

  const setFilter = (option: ISelectionOption): void => {
    setUserAppointmentGroupingFilter(option);
  };

  return (
    <ScheduleContainer>
      <CalendarContainer calendarIsCollapsed={calendarIsCollapsed}>
        <RangeCalendar
          startDate={appointmentFilters.startDate}
          endDate={appointmentFilters.endDate}
          currentDay={appointmentFilters.currentDay}
          minBookingDays={appointmentFilters.daysAheadInterval}
          onDateChange={onDateChange}
          exactMinBookingDays={true}
        />
      </CalendarContainer>
      <CenteredCollapse
        data-mixpanel-name="Collapse calendar"
        data-testid="collapse-calendar-button"
        onClick={(): void => setCalendarIsCollapsed(!calendarIsCollapsed)}
      >
        <CalendarToggle />
      </CenteredCollapse>
      <SortButtonContainer>
        {format(appointmentFilters.currentDay, 'PP')}
        <FilterDiv>
          <FilterBy>Filter by:</FilterBy>
          <Selection
            data-mixpanel-name="Filter selected day appointments by"
            data-testid="filter-appointment-select"
            list={userAppointmentGroupingFilterOptions}
            onSelectItem={setFilter}
            selectedItem={userAppointmentGroupingFilter}
          />
        </FilterDiv>
      </SortButtonContainer>
      <TimeIndicatorContainer>
        <TimeIndicatorContent>
          <DayScheduler
            currentDay={appointmentFilters.currentDay}
            appointments={filteredAppointments}
            selectedAppointmentId={
              modal.name === ModalNames.Appointment && modal?.data ? modal.data?.appointmentId : null
            }
            scheduleTimeBlockInMinutes={appointmentFilters.defaultAppointmentDurationInMinutes}
            onAppointmentClicked={onAppointmentClicked}
            onDayClicked={onAddAppointment}
          />
        </TimeIndicatorContent>
      </TimeIndicatorContainer>
    </ScheduleContainer>
  );
};

export default SidePanelScheduler;

interface ICalendarContainerProps {
  calendarIsCollapsed: boolean;
}

const ScheduleContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-basis: 30%;
  flex-direction: column;
  flex-shrink: 0; /* shrinks to 0 to apply 70% width*/
  height: 100%;
  overflow-y: auto;
  overflow: hidden;
`;

const FilterDiv = styled.div`
  display: flex;
  margin-right: 8px;
`;

const FilterBy = styled.div`
  flex: 1;
`;

const SortButtonContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  padding-left: 32px;
  padding: 12px 16px;
  text-align: left;
  width: 100%;
`;

const CalendarContainer = styled.div<ICalendarContainerProps>`
  transition: all 0.7s;
  flex-basis: ${({ calendarIsCollapsed }): string => (calendarIsCollapsed ? '0' : '20%')};
  height: ${({ calendarIsCollapsed }): string => (calendarIsCollapsed ? '0' : 'inherit')};
  opacity: ${({ calendarIsCollapsed }): number => (calendarIsCollapsed ? 0 : 1)};
`;

const TimeIndicatorContainer = styled.div`
  flex-basis: 80%;
  overflow-y: auto;
  scroll-behavior: smooth;

  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    height: 0;
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    margin: 5px 0;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    box-shadow: rgba(255, 255, 255, 0.3) 0 0 0 1px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.35);
  }
`;

const TimeIndicatorContent = styled.div`
  height: 100%;
`;

const CenteredCollapse = styled.div`
  text-align: center;
  cursor: pointer;

  border-bottom: 1px solid hsl(190deg 48% 86%);

  padding: 12px 0;
  z-index: 10;
`;

const CalendarToggle = styled(CalendarToggleDefault)`
  transform: rotate(0deg);
  cursor: pointer;
  &.isCollapsed {
    transform: rotate(180deg);
  }
`;
