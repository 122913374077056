import { ClinicOfficeHoursType, ClinicUserClinicFragment } from 'shared/types/graphql';
import { UnlayerUser, ClinicOfficeHourSelectionFragment } from '../../types/graphql';
import { UnlayerOptions } from 'react-email-editor';
import { officeHoursDisplay } from '../../utils/officeHoursDisplay';
import { ServiceReminderHeader } from './blocks/ServiceReminderHeader';
import { ServiceReminderBookNowButton } from './blocks/ServiceReminderBookNowButton';
import { ClinicAddressAndHoursFooter } from './blocks/ClinicAddressAndHoursFooter';

export const getEmailEditorOptions = (
  currentClinic: ClinicUserClinicFragment | null,
  userGroup: UnlayerUser | null | undefined,
): UnlayerOptions | undefined => {
  if (!currentClinic) return undefined;

  const officeHours = officeHoursDisplay(
    currentClinic?.clinicOfficeHours.filter(
      ({ type }) => type === ClinicOfficeHoursType.BusinessHours,
    ) as ClinicOfficeHourSelectionFragment[],
    currentClinic?.timezoneName,
  );

  // disabling rule because VS Code doesn't recognize it's
  // actually used down below in a Mustache string template
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const singleLineAddress = `{{clinic.addressLine1}}, ${
    currentClinic?.addressLine2 ? '{{clinic.addressLine2}}, ' : ''
  }{{clinic.city}}, {{clinic.state}}, {{clinic.postalCode}}`;
  const sampleSingleLineAddress = `${currentClinic?.addressLine1}, ${
    currentClinic?.addressLine2 ? `${currentClinic.addressLine2}, ` : ''
  }${currentClinic?.city}, ${currentClinic?.state}, ${currentClinic?.postalCode}`;

  return {
    displayMode: 'email',
    user: {
      id: userGroup?.userId,
      // eslint-disable-next-line
      // @ts-ignore
      signature: userGroup?.signature, // TODO: Create PR to update @types/react-email-editor
    },
    appearance: {
      // eslint-disable-next-line
      // @ts-ignore
      loader: {
        html: '<div class="spinner"><span class="spinner-inner">Loading...</span></div>',
        css:
          '@keyframes spin {' +
          '  0%   { transform: rotate(0deg) }' +
          '  100% { transform: rotate(360deg) }' +
          '}' +
          '.spinner {' +
          '    display: inline-block;' +
          '    border-top: 2px solid #3CA0B4;' +
          '    border-right: 2px solid #3CA0B4;' +
          '    border-bottom-style: solid;' +
          '    border-left-style: solid;' +
          '    border-radius: 99999px;' +
          '    border-bottom-width: 2px;' +
          '    border-left-width: 2px;' +
          '    border-bottom-color: transparent;' +
          '    border-left-color: transparent;' +
          '    animation: 0.45s linear 0s infinite normal none running spin; ' +
          '    width: 48px;' +
          '    height: 48px;' +
          '}' +
          '.spinner-inner {' +
          '    border: 0px;' +
          '    clip: rect(0px, 0px, 0px, 0px);' +
          '    width: 1px;' +
          '    height: 1px;' +
          '    margin: -1px;' +
          '    padding: 0px;' +
          '    overflow: hidden;' +
          '    white-space: nowrap;' +
          '    position: absolute;' +
          '}',
      },
    },
    specialLinks: [
      {
        name: 'Otto Home',
        href: 'https://otto.vet',
        target: '_blank',
      },
      {
        name: 'Otto Privacy Policy',
        href: 'https://otto.vet/privacy-policy',
        target: '_blank',
      },
      {
        name: 'Otto Terms of Service',
        href: 'https://otto.vet/terms',
        target: '_blank',
      },
      {
        name: 'Request Appointment Form',
        href: '{{tinyUrl}}',
        target: '_blank',
      },
      {
        name: 'Unsubscribe',
        href: '{{unsubscribeUrl}}',
        target: '_blank',
      },
    ],
    designTags: {
      clinic_name: currentClinic?.name || '',
      clinic_phone: currentClinic?.phone || '',
      clinic_email: currentClinic?.clinicEmail || '',
      clinic_hours_monday: officeHours.monday,
      clinic_hours_tuesday: officeHours.tuesday,
      clinic_hours_wednesday: officeHours.wednesday,
      clinic_hours_thursday: officeHours.thursday,
      clinic_hours_friday: officeHours.friday,
      clinic_hours_saturday: officeHours.saturday,
      clinic_hours_sunday: officeHours.sunday,
      clinic_address_line1: currentClinic?.addressLine1 || '',
      clinic_address_line2: currentClinic?.addressLine2 || '',
      clinic_address_city: currentClinic?.city || '',
      clinic_address_state: currentClinic?.state || '',
      clinic_address_zip: currentClinic?.postalCode || '',
      clinic_website_url: currentClinic?.websiteUrl || '',
      clinic_logo_url: currentClinic?.logo || '',
    },
    mergeTags: [
      {
        name: 'Pet Owner Info',
        mergeTags: [
          { name: 'First Name', value: '{{petParent.firstName}}', sample: 'Alex' },
          { name: 'Last Name', value: '{{petParent.lastName}}', sample: 'Smith' },
        ],
      },
      {
        name: 'Clinic Info',
        mergeTags: [
          { name: 'Name', value: '{{clinic.name}}', sample: currentClinic?.name || '' },
          { name: 'Phone', value: '{{clinic.phone}}', sample: currentClinic?.phone || '' },
          { name: 'Email', value: '{{clinic.clinicEmail}}', sample: currentClinic?.clinicEmail || '' },
          { name: 'Full Address', value: '{{clinic.fullAddress}}', sample: currentClinic?.fullAddress || '' },
          { name: 'Address Line 1', value: '{{clinic.addressLine1}}', sample: currentClinic?.addressLine1 || '' },
          { name: 'Address Line 2', value: '{{clinic.addressLine2}}', sample: currentClinic?.addressLine2 || '' },
          { name: 'City', value: '{{clinic.city}}', sample: currentClinic?.city || '' },
          { name: 'State', value: '{{clinic.state}}', sample: currentClinic?.state || '' },
          { name: 'Zip', value: '{{clinic.postalCode}}', sample: currentClinic?.postalCode || '' },
          { name: 'Single Line Address', value: '{{singleLineAddress}}', sample: sampleSingleLineAddress },
          { name: 'Website URL', value: '{{clinic.websiteUrl}}', sample: currentClinic?.websiteUrl || '' },
          { name: 'Logo URL', value: '{{clinic.logo}}', sample: currentClinic?.logo || '' },
        ],
      },
      {
        name: 'Clinic Hours',
        mergeTags: [
          { name: 'Monday', value: '{{hours.monday}}', sample: officeHours.monday },
          { name: 'Tuesday', value: '{{hours.tuesday}}', sample: officeHours.tuesday },
          { name: 'Wednesday', value: '{{hours.wednesday}}', sample: officeHours.wednesday },
          { name: 'Thursday', value: '{{hours.thursday}}', sample: officeHours.thursday },
          { name: 'Friday', value: '{{hours.friday}}', sample: officeHours.friday },
          { name: 'Saturday', value: '{{hours.saturday}}', sample: officeHours.saturday },
          { name: 'Sunday', value: '{{hours.sunday}}', sample: officeHours.sunday },
        ],
      },
    ],
    customJS: ['https://unlayer-email-template-assets.s3.us-east-2.amazonaws.com/custom-tools/servicesList.js'],
    customCSS: ['https://unlayer-email-template-assets.s3.us-east-2.amazonaws.com/custom-tools/editor.css'],
    tools: {
      button: {
        properties: {
          url: {
            // eslint-disable-next-line
            // @ts-ignore
            enabled: false,
          },
        },
      },
      'custom#service_list': {
        properties: {},
      },
    },
    blocks: [ServiceReminderHeader, ServiceReminderBookNowButton, ClinicAddressAndHoursFooter],
  };
};
