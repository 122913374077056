import React, { ReactElement, useCallback } from 'react';
import styled from 'styled-components/macro';
import partial from 'lodash-es/partial';
import { useModal } from '@televet/televet-ui';
import { Button, Text, VStack, Flex, Box, useToast, HStack } from '@televet/kibble-ui';
import * as Sentry from '@sentry/react';
import Tooltip from 'shared/components/Tooltip';
import { format, formatDistanceToNow } from 'date-fns';
import { ModalNames } from 'shared/enums/ModalNames';
import {
  ClinicSettingsTeamUserFragment,
  PermissionType,
  useGetClinicSettingsTeamUsersQuery,
  useResendUserInvitationMutation,
} from 'shared/types/graphql';
import { getUserFullName } from 'shared/utils';
import useClinicUser from 'shared/hooks/useClinicUser';
import { ClinicAvatar } from 'shared/components/Avatars';
import Dropdown, { PopoverPlacement, IOption } from 'shared/components/Dropdown';
import { Mixpanel } from 'shared/utils/mixpanel';
import EditUserModal from './EditUserModal';
import DeleteUserModal from './DeleteUserModal';
import MoreOptionsButton from 'shared/components/MoreOptionsButton';
import DevModeDisplayJSON from '../../components/DevModeDisplayJSON';
import { GraphQLFetchPolicies } from 'shared/enums/GraphQLFetchPolicies';

enum UserMenuOptions {
  Edit = 'edit',
  Delete = 'delete',
}

const userMenuDropdownOptions = [
  {
    value: UserMenuOptions.Edit,
    text: 'Edit user',
  },
  {
    value: UserMenuOptions.Delete,
    text: 'Delete user',
  },
];

const TeamSettings = (): ReactElement => {
  const { clinicUser, currentClinicId, userHasPermission, hasAdminRoleAtCurrentClinic } = useClinicUser();

  const hasWriteUserPermission = userHasPermission(PermissionType.WriteUser);
  const { data: teamUsersData, refetch: refetchTeamUsersData } = useGetClinicSettingsTeamUsersQuery({
    variables: {
      where: {
        vetInfo: {
          clinics: {
            some: {
              id: {
                equals: currentClinicId,
              },
            },
          },
        },
        isActive: {
          equals: true,
        },
      },
      clinicId: currentClinicId || '',
    },
    skip: !currentClinicId,
    fetchPolicy: GraphQLFetchPolicies.CacheAndNetwork,
  });

  const users = teamUsersData?.findManyUser || [];
  const { openModal } = useModal();

  const toast = useToast();

  const [resendUserInvitation, { loading: isResendingUserInvitation }] = useResendUserInvitationMutation({
    refetchQueries: ['getClinicSettingsTeamUsers'],
    onCompleted: (data) => {
      toast({
        position: 'top',
        status: 'info',
        duration: 3000,
        description: `Invitation sent to ${data.resendUserInvitation.email}`,
      });
    },
    onError: (error) => {
      Sentry.captureException(error);
      toast({
        position: 'top',
        status: 'error',
        description: `Whoops! There was a problem and we were unable to resend the invitation. Please try again.`,
      });
    },
  });

  const handleResendInvitationClick = useCallback(
    (email: string) => {
      if (!currentClinicId) {
        return;
      }
      resendUserInvitation({ variables: { email, clinicId: currentClinicId } });
    },
    [currentClinicId, resendUserInvitation],
  );

  const editUser = (user?: ClinicSettingsTeamUserFragment): void => {
    openModal(ModalNames.EditUser, { user });
    Mixpanel.track('Team Settings Edit User');
  };

  const deleteUser = (user: ClinicSettingsTeamUserFragment): void => {
    openModal(ModalNames.DeleteUser, { user, onConfirmDeleteUser: deleteUser });
    Mixpanel.track('Team Settings Delete User');
  };

  const handleUserMenuOptionClick = (
    user: ClinicSettingsTeamUserFragment,
    e: React.MouseEvent<Element, MouseEvent>,
    option: IOption,
  ): void => {
    if (option.value === UserMenuOptions.Edit) {
      editUser(user);
    }
    if (option.value === UserMenuOptions.Delete) {
      deleteUser(user);
    }
  };

  const onAddUserSuccess = (): void => {
    refetchTeamUsersData();
  };

  return (
    <Box>
      <EditUserModal
        clinicId={currentClinicId}
        onAddUserSuccess={onAddUserSuccess}
        onEditUserSuccess={refetchTeamUsersData}
      />
      <DeleteUserModal onConfirmDeleteUser={refetchTeamUsersData} />
      <Box m="15px 0px 60px">
        <HStack justifyContent="space-between" p="20px 0px 30px">
          <Text size="md" fontWeight="bold">
            Manage Team Members
          </Text>
          {hasWriteUserPermission && (
            <Button iconName="plus" variant="secondary" onClick={(): void => editUser()}>
              Add User
            </Button>
          )}
        </HStack>
        <UserTable>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Role</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => {
              const { id, email, vetInfo, invitedAt, invitationCode } = user;
              const showResendInvitation = !!invitationCode && !!invitedAt;
              return (
                <React.Fragment key={id}>
                  <tr>
                    <td>
                      <Flex alignItems="center" justifyContent="normal">
                        <Box mr="15px">
                          <ClinicAvatar clinicMember={user} size="lg" />
                        </Box>
                        <Flex direction="column" align="flex-start">
                          <Box fontWeight="bold">
                            {getUserFullName(user)}
                            {user.id === clinicUser?.id && ' (you)'}
                          </Box>
                        </Flex>
                      </Flex>
                    </td>
                    <td>
                      <VStack direction="column" align="flex-start" spacing="1">
                        <Box>{email}</Box>
                        {showResendInvitation ? (
                          <Flex>
                            <Tooltip content={format(new Date(invitedAt), "cccc',' PP 'at' p")}>
                              <Box color="text.subtle">
                                Invitation sent{' '}
                                {formatDistanceToNow(new Date(invitedAt), {
                                  addSuffix: true,
                                })}
                              </Box>
                            </Tooltip>
                            {hasAdminRoleAtCurrentClinic && (
                              <Button
                                ml="2"
                                variant="ghost"
                                size="xs"
                                disabled={isResendingUserInvitation}
                                onClick={partial(handleResendInvitationClick, email)}
                              >
                                Resend
                              </Button>
                            )}
                          </Flex>
                        ) : null}
                      </VStack>
                    </td>
                    <td>
                      <Box>{vetInfo?.roles?.length ? vetInfo.roles[0].role : ''}</Box>
                    </td>
                    {hasWriteUserPermission && (
                      <td>
                        <Flex alignItems="center" justifyContent="flex-end">
                          <Dropdown
                            options={userMenuDropdownOptions}
                            placement={PopoverPlacement.BottomEnd}
                            onSelect={partial(handleUserMenuOptionClick, user)}
                          >
                            <MoreOptionsButton
                              onClick={(): void => {
                                Mixpanel.track('Team Settings Team Detail');
                              }}
                            />
                          </Dropdown>
                        </Flex>
                      </td>
                    )}
                  </tr>
                  <DevModeDisplayJSON src={user} />
                </React.Fragment>
              );
            })}
          </tbody>
        </UserTable>
      </Box>
    </Box>
  );
};

export default TeamSettings;

const UserTable = styled.table`
  width: 100%;
  text-align: left;
  vertical-align: middle;
  border-collapse: collapse;
  th {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: normal;
    padding: 5px 10px;
  }
  td {
    padding: 15px 10px;
    font-size: 14px;
  }
  thead tr {
    border-bottom: 1px solid #e8e9e9;
  }
  tbody tr:nth-of-type(2n) {
    background: var(--chakra-colors-background-default);
  }
`;
