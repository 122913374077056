import { createContext } from 'react';
import { ClinicPimsIntegrationFragment } from 'shared/types/graphql';
import { IntegrationSystemDisplayNameType } from '../types';

// TODO: This should be moved to our shared codegen repo once that's complete
export enum SyncStatus {
  WaitingOnPractice = 'WaitingOnPractice',
  PracticeContacted = 'PracticeContacted',
  UpToDate = 'UpToDate',
  SyncProblem = 'SyncProblem',
  ResolutionInProgress = 'ResolutionInProgress',
}

interface SyncStatusDetails {
  hasSyncOutage: boolean;
  currentStatus?: null | SyncStatus;
  lastSyncTime: null | string;
  isSyncPaused: boolean | null;
  primaryIntegration?: ClinicPimsIntegrationFragment | null;
  timezoneName?: string | null;
}

export interface IIntegrationsContext {
  syncStatus: SyncStatusDetails;
  primaryIntegration: ClinicPimsIntegrationFragment | null;
  primaryIntegrationName: IntegrationSystemDisplayNameType | null;
  hasAppointmentCreateWritebackCapability: boolean;
  isInvoicePreviewSupported: boolean;
  isInvoiceDiscoverySupported: boolean;
  isTriggerPimsSyncSupported: boolean;
  isClientIdSupported: boolean;
  isMergeExistingPimsProfileSupported: boolean;
  isExportToPimsSupported: boolean;
  isContactSyncSupported: boolean;
  hasPrescriptionCapabilities: boolean;
  arePostcardsSupported: boolean;
  isBillingCodeSupported: boolean;
  isPatientIdSupported: boolean;
  isServiceCodeSupported: boolean;
  allIntegrations: ClinicPimsIntegrationFragment[];
  allActiveIntegrations: ClinicPimsIntegrationFragment[];
}

export const IntegrationsContext = createContext<IIntegrationsContext>({
  syncStatus: {
    hasSyncOutage: false,
    currentStatus: null,
    lastSyncTime: null,
    isSyncPaused: false,
  },
  primaryIntegration: null,
  primaryIntegrationName: null,
  hasAppointmentCreateWritebackCapability: false,
  isInvoicePreviewSupported: false,
  isInvoiceDiscoverySupported: false,
  isTriggerPimsSyncSupported: false,
  isClientIdSupported: false,
  isMergeExistingPimsProfileSupported: false,
  isExportToPimsSupported: false,
  isContactSyncSupported: false,
  isBillingCodeSupported: false,
  isPatientIdSupported: false,
  isServiceCodeSupported: false,
  arePostcardsSupported: false,
  hasPrescriptionCapabilities: false,
  allIntegrations: [],
  allActiveIntegrations: [],
});
