import { CampaignAction } from '../enums';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { Heading } from '@televet/kibble-ui/build/components/Heading';
import { Icon } from '@televet/kibble-ui/build/components/Icon';
import { Menu } from '@televet/kibble-ui/build/components/Menu';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalFooterButtons,
  ModalHeader,
} from '@televet/kibble-ui/build/components/Modal';
import { useToast } from '@televet/kibble-ui/build/components/Toast';
import { Box, Flex, useDisclosure } from '@televet/kibble-ui/build/chakra';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import EmailEditor, { Design, EmailEditorProps } from 'react-email-editor';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import { RouteBasePaths } from 'routes';
import {
  EmailCampaignStatus,
  FindManyEmailCampaignQuery,
  FindManyEmailCampaignTemplateQuery,
  useUpdateOneEmailCampaignMutation,
} from 'shared/types/graphql';
import { useBlockedNavigation } from 'shared/hooks/useBlockedNavigation';
import useClinicUser from 'shared/hooks/useClinicUser';
import { replaceWildCardsInJSON } from './TemplateList';

type CampaignHTMLEditorProps = {
  campaigns: FindManyEmailCampaignQuery | undefined;
  campaignId: string;
  emailTemplates: FindManyEmailCampaignTemplateQuery | undefined;
};

const CampaignHTMLEditor = ({ campaigns, campaignId, emailTemplates }: CampaignHTMLEditorProps): JSX.Element => {
  // if the campaign is not on draft status we want to get out
  const history = useHistory();
  useEffect(() => {
    const campaign = campaigns?.findManyEmailCampaign?.find((c) => c.id === campaignId);
    if (campaign?.status !== EmailCampaignStatus.Draft) {
      history.push(`${RouteBasePaths.OttoEmailCampaigns}/${campaignId}/${CampaignAction.editCampaign}`);
    }
  }, [campaignId, campaigns?.findManyEmailCampaign, history]);

  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [updateOneEmailCampaign, { loading }] = useUpdateOneEmailCampaignMutation();
  const toast = useToast();
  const location = useLocation();
  const { currentClinic: clinic } = useClinicUser();

  const overWritingTemplateJSON = useMemo(() => {
    const params = new URLSearchParams(location.search);
    const templateId = params.get('templateId');

    const template = emailTemplates?.findManyEmailCampaignTemplate?.find((t) => t.id === templateId);

    return template?.unlayerJson ? replaceWildCardsInJSON({ json: template?.unlayerJson, clinic }) : undefined;
  }, [clinic, emailTemplates?.findManyEmailCampaignTemplate, location.search]);

  const emailEditorRef = useRef<EmailEditor>(null);
  const [isEditorLoading, setIsEditorLoading] = useState(true);
  const { onOpen, onClose, isOpen } = useDisclosure();

  const handleSaveAndContinue = async (): Promise<void> => {
    try {
      setIsNavigatingAway(false);
      setUnsavedChanges(false);
      const unlayer = emailEditorRef.current;

      unlayer?.exportHtml(async (data) => {
        const { design, html } = data;
        await updateOneEmailCampaign({
          variables: {
            where: {
              id: campaignId,
            },
            data: {
              unlayerJson: design,
              htmlContent: html,
            },
          },
        });
        toast({
          title: 'Email saved successfully!',
          status: 'success',
        });
        history.push(`${RouteBasePaths.OttoEmailCampaigns}/${campaignId}/${CampaignAction.editCampaign}`);
      });
    } catch (e) {
      console.error(e);
      toast({
        title: 'Error while saving',
        status: 'error',
      });
    }
  };
  const campaign = campaigns?.findManyEmailCampaign?.find((c) => c.id === campaignId);

  const onReady: EmailEditorProps['onReady'] = () => {
    // editor is ready
    // you can load your template here;
    // the design json can be obtained by calling

    emailEditorRef?.current?.loadDesign(overWritingTemplateJSON || (campaign?.unlayerJson as unknown as Design));

    setIsEditorLoading(false);
    emailEditorRef?.current?.addEventListener('design:updated', () => {
      setUnsavedChanges(true);
    });
  };

  const saveDraft = async (): Promise<void> => {
    try {
      const unlayer = emailEditorRef.current;
      unlayer?.exportHtml(async (data) => {
        const { design, html } = data;
        await updateOneEmailCampaign({
          variables: {
            where: {
              id: campaignId,
            },
            data: {
              unlayerJson: design,
              htmlContent: html,
            },
          },
        });
        setUnsavedChanges(false);
        toast({
          title: 'Email saved successfully!',
          status: 'success',
        });
      });
    } catch (e) {
      console.error(e);
      toast({
        title: 'Error while saving',
        status: 'error',
      });
    }
  };

  const changeTemplate = (): void => {
    history.push(`${RouteBasePaths.OttoEmailCampaigns}/${campaignId}/${CampaignAction.chooseTemplate}`);
  };

  const exitWithoutSaving = (): void => {
    onClose();

    setConfirmedNavigation(true);
    if (!isNavigatingAway) {
      history.push(`${RouteBasePaths.OttoEmailCampaigns}/${campaignId}/${CampaignAction.editCampaign}`);
    }
  };

  const border = '1px';
  const borderColor = 'border.default';
  const [isNavigatingAway, setIsNavigatingAway] = useState(false);

  const { handleBlockedNavigation, setConfirmedNavigation } = useBlockedNavigation({
    onBlockedCallback: () => {
      setIsNavigatingAway(true);
      onOpen();
    },
    shouldBlockNavigation: unsavedChanges,
  });

  const closeModal = (): void => {
    setIsNavigatingAway(false);
    onClose();
  };

  return (
    <Box height="100%" backgroundColor="background.default">
      <Prompt when={unsavedChanges} message={handleBlockedNavigation} />
      <Modal allowEscape isOpen={isOpen} size="md" onClose={closeModal}>
        <ModalHeader>Exit Without Saving</ModalHeader>
        <ModalCloseButton onClick={closeModal} />
        <ModalBody>
          This email has unsaved changes. If you exit now, your changes will be lost. Are you sure you want to exit
          without saving?
        </ModalBody>
        <ModalFooterButtons submitText="Exit Without Saving" onCancel={closeModal} onSubmit={exitWithoutSaving} />
      </Modal>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        backgroundColor="background.light"
        padding="4"
        borderBottom={border}
        borderColor={borderColor}
      >
        <Flex alignItems="center">
          <Heading size="md" marginLeft="1">
            Editing &apos;{campaign?.name}&apos;
          </Heading>
        </Flex>
        <Flex>
          <Button isLoading={loading || isEditorLoading} mr="2" onClick={handleSaveAndContinue}>
            Save & Continue
          </Button>
          <Menu
            buttonProps={{
              leftIconName: 'chevronDown',
              size: 'md',
              showRightIcon: false,
              variant: 'primary',
            }}
            listProps={{
              canSelectAll: false,
              canSelectGroups: false,
              isMultiSelect: false,
              isSearchable: false,
              menuItems: [
                {
                  label: 'Save Draft',
                  leftElement: <Icon size="sm" name="checkmark" />,
                  value: 'saveDraft',
                  onSelect: saveDraft,
                  isDisabled: isEditorLoading,
                },
                {
                  label: 'Change Template',
                  leftElement: <Icon size="sm" name="doubleArrowCircle" />,
                  value: 'changeTemplate',
                  onSelect: changeTemplate,
                },
                {
                  label: 'Exit Without Saving',
                  leftElement: <Icon size="sm" name="close" />,
                  variant: 'danger',
                  value: 'exitWithoutSaving',
                  onSelect: (): void => {
                    if (!unsavedChanges) {
                      exitWithoutSaving();
                    } else {
                      onOpen();
                    }
                  },
                },
              ],
            }}
          />
        </Flex>
      </Flex>

      <EmailEditor
        projectId={191101}
        ref={emailEditorRef}
        onReady={onReady}
        minHeight="calc(100% - 75px)"
        options={{ tabs: { blocks: { enabled: false } } }}
      />
    </Box>
  );
};

export default CampaignHTMLEditor;
