import React, { useEffect, useMemo } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { KibbleThemeProvider } from '@televet/kibble-ui/build/theme';
import { SnackbarProvider, ModalProvider } from '@televet/televet-ui';
import PopoverWindowProvider from 'shared/providers/PopoverWindowProvider';
import SidePanelProvider from 'shared/components/SidePanel/state/providers/SidePanelProvider';
import SidePanelSearchProvider from 'shared/components/SidePanel/components/SearchPanel/state/providers/SearchPanelProvider';
import InAppNotificationsProvider from 'shared/providers/InAppNotificationsProvider';
import UnreadMessageCountProvider from 'shared/providers/UnreadMessageCountProvider';
import Routes from 'routes';
import * as Sentry from '@sentry/react';
import { loadStripeTerminal } from '@stripe/terminal-js';
import useTagManager from 'shared/hooks/useTagManager';
import useMixpanel from 'shared/hooks/useMixpanel';
import useSentry from 'shared/hooks/useSentry';
import useLogRocket from 'shared/hooks/useLogRocket';
import useZendesk from 'shared/hooks/useZendesk';
import ErrorBoundaryFallback from 'shared/components/ErrorBoundaryFallback';
import MobileMenuProvider from 'pages/MobileMenu/MobileMenuProvider';
import SnoozeProvider from 'shared/providers/SnoozeProvider';
import ResolutionProvider from 'shared/providers/ResolutionProvider';
import usePersistedState from 'shared/hooks/usePersistedState';
import { commitInfo } from 'commitInfo';
import { turnOffDarkMode, turnOnDarkMode } from 'pages/Settings/pages/OttoLabsSettings';
import { PersistedStateKeys } from 'shared/enums';
import ChurnZeroAttributes from './ChurnZeroAttributes';
import CaptureScreenshot from './CaptureScreenshot';
import useGA from 'shared/hooks/useGA';
import useClinicUser from 'shared/hooks/useClinicUser';
import { useAuth } from 'shared/providers/AuthProvider';

interface ChromePerformance extends Performance {
  memory: {
    jsHeapSizeLimit: number;
    totalJSHeapSize: number;
    usedJSHeapSize: number;
  };
}

export interface AppInfo extends Record<string, number | string> {
  appVersion: number;
  commitNumber: number;
  commitHash: string;
}

export const appInfo: AppInfo = { appVersion: parseInt(`${commitInfo.commitNumber}`) / 100, ...commitInfo };

const Application = (): JSX.Element | null => {
  const { isInitialized } = useAuth();
  const { clinicUser, currentClinic } = useClinicUser();

  useLogRocket(clinicUser, currentClinic);
  useSentry({ clinicUser, currentClinic });
  useTagManager(clinicUser, currentClinic);
  useMixpanel(clinicUser, currentClinic);
  useZendesk(clinicUser);
  useGA();

  const [isReaderModeEnabled] = usePersistedState(PersistedStateKeys.IsReaderModeEnabled, false);

  if (isReaderModeEnabled) {
    turnOnDarkMode();
  } else {
    turnOffDarkMode();
  }

  useMemo(() => {
    console.log('App Info:', appInfo);
  }, []);

  const loadPolyfills = async (): Promise<void> => {
    if (typeof window.IntersectionObserver === 'undefined') {
      // eslint-disable-next-line
      // @ts-ignore
      await import('intersection-observer');
    }
  };

  useEffect(() => {
    loadPolyfills();
    loadStripeTerminal();

    const TEN_MINUTES = 1000 * 60 * 10;
    const interval = setInterval(() => {
      if ((window.performance as ChromePerformance).memory) {
        const memory = (window.performance as ChromePerformance).memory;
        console.log(`Memory used: ${((memory.usedJSHeapSize / memory.jsHeapSizeLimit) * 100).toFixed(2)}%`);
      }
    }, TEN_MINUTES);
    return (): void => clearInterval(interval);
  }, []);

  // catch and prevent errors coming from embedded zendesk script
  useEffect(() => {
    const suppressZendeskError = (e: ErrorEvent): void => {
      if (e.message.includes('Method webWidget:on.open does not exist')) {
        e.preventDefault();
      }
    };

    window.addEventListener('error', suppressZendeskError);

    return (): void => window.removeEventListener('error', suppressZendeskError);
  }, []);

  if (!isInitialized) {
    return null;
  }

  return (
    <KibbleThemeProvider>
      <CaptureScreenshot />
      <ModalProvider>
        <PopoverWindowProvider>
          <SnackbarProvider>
            <ResolutionProvider>
              <ChurnZeroAttributes>
                <DndProvider backend={HTML5Backend}>
                  <Sentry.ErrorBoundary
                    fallback={({ error }): JSX.Element => <ErrorBoundaryFallback error={error.toString()} />}
                  >
                    <SnoozeProvider>
                      <MobileMenuProvider>
                        <InAppNotificationsProvider>
                          <SidePanelProvider>
                            <SidePanelSearchProvider>
                              <UnreadMessageCountProvider>
                                <Routes />
                              </UnreadMessageCountProvider>
                            </SidePanelSearchProvider>
                          </SidePanelProvider>
                        </InAppNotificationsProvider>
                      </MobileMenuProvider>
                    </SnoozeProvider>
                  </Sentry.ErrorBoundary>
                </DndProvider>
              </ChurnZeroAttributes>
            </ResolutionProvider>
          </SnackbarProvider>
        </PopoverWindowProvider>
      </ModalProvider>
    </KibbleThemeProvider>
  );
};

export default Application;
