import React, { useRef, useState } from 'react';
import ReactEmailEditor, { Design, EmailEditorProps as ReactEmailEditorProps, HtmlExport } from 'react-email-editor';
import { getEmailEditorOptions } from './EmailEditorConfig';
import {
  Modal,
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  ModalBody,
  ModalCloseButton,
  ModalFooterButtons,
  ModalHeader,
  Spacer,
  Text,
  useToast,
  UseToastOptions,
  Collapse,
  useDisclosure,
} from '@televet/kibble-ui';
import { useHistory } from 'react-router-dom';
import usePersistedState from '../../hooks/usePersistedState';
import useClinicUser from 'shared/hooks/useClinicUser';
import Mustache from 'mustache';
import { useEmailTemplates } from './hooks/useEmailTemplates';
import { EmailTemplateType } from 'shared/types/graphql';
import { serviceReminderData } from './data/ServiceReminderData';
import * as BaseServiceReminderDesign from './templates/ServiceReminder.json';
import { useMixpanelTracking } from 'shared/utils/mixpanel';
import { PersistedStateKeys } from '../../enums';
import { DevMode } from '../../utils';
import { TemplateConfigs, TemplateRequirements, TemplateType } from './templates/TemplatesConfig';

interface EmailEditorProps extends ReactEmailEditorProps {
  type: EmailTemplateType;
  backToLocation: string;
}

export const EmailEditor = ({ type, backToLocation, ...rest }: EmailEditorProps): JSX.Element => {
  const { isOpen: isPublishOpen, onOpen: onPublishOpen, onClose: onPublishClose } = useDisclosure();
  const { isOpen: isRevertOpen, onOpen: onRevertOpen, onClose: onRevertClose } = useDisclosure();

  const { events, track } = useMixpanelTracking('EmailEditor');

  const {
    draft,
    published,
    loading,
    saving,
    updating,
    userGroup,
    createEmailTemplate,
    saveEmailTemplate,
    discardDraftEmailTemplate,
  } = useEmailTemplates(type);

  const toast = useToast();
  const emailEditorRef = useRef<ReactEmailEditor>(null);
  const history = useHistory();
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const { currentClinic } = useClinicUser();
  const options = getEmailEditorOptions(currentClinic, userGroup);
  const [scratchDesign, setScratchDesign] = usePersistedState<Design | null>(
    PersistedStateKeys.UnlayerScratchTemplate,
    null,
  );

  // First time user allows for saving default template to profile without any edits.
  const firstTimeUser: boolean = !draft && !published && !scratchDesign;

  // Casting JSON Design as default until default is stored in DB.
  // eslint-disable-next-line
  // @ts-ignore
  const defaultDesign = BaseServiceReminderDesign['default'] as Design;

  // Checks to see if all required blocks are included in design.
  const getMissingRequirements = (data: HtmlExport, templateType: TemplateType): TemplateRequirements => {
    const config = TemplateConfigs[templateType];
    return config.requirements.filter((req) => !data.html.includes(req.include));
  };

  const handleCancel = (): void => {
    loadDesign(draft?.design || published?.design || defaultDesign);
    setScratchDesign(null);
    setUnsavedChanges(false);
  };

  type Action = 'save' | 'publish';

  const handleSuccess = (action: Action): void => {
    const toastOptions: Record<Action, UseToastOptions> = {
      save: { title: 'Draft saved' },
      publish: { title: 'Email published', status: 'success' },
    };

    setUnsavedChanges(false);
    setScratchDesign(null);
    toast(toastOptions[action]);

    const eventName = {
      save: events.DesignSaved.name,
      publish: events.DesignPublished.name,
    }[action];
    track(eventName, { EmailTemplateType: EmailTemplateType.ServiceReminder });
  };

  const handleSave = (): void => {
    const unlayerId = userGroup?.userId;
    if (!unlayerId) return;

    emailEditorRef?.current?.exportHtml(async (data: HtmlExport) => {
      DevMode.consoleLog('saving: ', data);

      const { html, design } = data;
      const emailTemplateVersionId = draft?.id;

      // If a draft does not exist, create one.
      if (!emailTemplateVersionId) {
        await createEmailTemplate({ html, design, unlayerId, type });
        handleSuccess('save');
        return;
      }

      // Draft exists, save changes to the current draft.
      await saveEmailTemplate({ html, design, emailTemplateVersionId });
      handleSuccess('save');
    });
  };

  const handleConfirmPublish = (): void => {
    const unlayerId = userGroup?.userId;
    if (!unlayerId) return;

    emailEditorRef?.current?.exportHtml(async (data: HtmlExport) => {
      DevMode.consoleLog('publishing: ', data);

      const { html, design } = data;
      let emailTemplateVersionId = draft?.id;

      if (!emailTemplateVersionId) {
        emailTemplateVersionId = await createEmailTemplate({ html, design, unlayerId, type });

        if (emailTemplateVersionId) {
          await saveEmailTemplate({ html, design, shouldPublish: true, emailTemplateVersionId });
          handleSuccess('publish');
          onPublishClose();
        } else {
          toast({ title: 'Unable to create email template.', status: 'error' });
        }
      } else {
        await saveEmailTemplate({ html, design, shouldPublish: true, emailTemplateVersionId });
        handleSuccess('publish');
        onPublishClose();
      }
    });
  };

  const handleConfirmRevert = (): void => {
    if (!draft) {
      setUnsavedChanges(false);
      setScratchDesign(null);
      loadDesign(published?.design || defaultDesign);
      onRevertClose();
    } else {
      discardDraftEmailTemplate().then(() => {
        setUnsavedChanges(false);
        setScratchDesign(null);
        loadDesign(published?.design || defaultDesign);
        onRevertClose();
      });
    }
    track(events.DesignReverted.name, { EmailTemplateType: EmailTemplateType.ServiceReminder });
  };

  const handleRevert = (): void => {
    onRevertOpen();
  };

  const handlePublish = (): void => {
    emailEditorRef?.current?.exportHtml((data: HtmlExport) => {
      // TODO: Make TemplateType Dynamic when other types are added.
      const missingRequirements = getMissingRequirements(data, 'ServiceReminder');

      if (missingRequirements.length > 0) {
        missingRequirements.forEach((requirement) => {
          if (!toast.isActive(requirement.label)) {
            toast({
              title: 'Unable to publish template',
              description: `A ${requirement.label} must be added before this template can be published.`,
              status: 'error',
              id: requirement.label,
            });
          }
        });
      } else {
        onPublishOpen();
      }
    });
  };

  const loadDesign = (design: Design): void => {
    emailEditorRef?.current?.loadDesign(design);
  };

  const handleOnReady = (): void => {
    const editor = emailEditorRef?.current;
    if (!editor) return;

    loadDesign(scratchDesign || draft?.design || published?.design || defaultDesign);

    // Adds Event listener for detecting changes to the loaded design.
    // This method is suitable for setting up auto-save or detecting unsaved changes.
    editor.addEventListener('design:updated', () => {
      editor.exportHtml((data: HtmlExport) => {
        setUnsavedChanges(true);
        setScratchDesign(data.design);
      });
    });

    editor.addEventListener('editor:ready', () => {
      track(events.DesignReady.name, { EmailTemplateType: EmailTemplateType.ServiceReminder });
    });

    editor.addEventListener('design:loaded', () => {
      track(events.DesignLoaded.name, { EmailTemplateType: EmailTemplateType.ServiceReminder });
    });

    editor.addEventListener('image:uploaded', () => {
      track(events.ImageUploaded.name);
    });

    editor.registerCallback('displayCondition', (data, done) => {
      // TODO: Register analytic event after conditional element work is complete (Phase 2+)
      DevMode.consoleLog('unlayer displayCondition', data, done);
    });

    // Renders The HTML preview using test data
    // eslint-disable-next-line
    // @ts-ignore
    editor.registerCallback('previewHtml', function (params, done) {
      const html = Mustache.render(params.html, serviceReminderData);
      done({ html });
      track(events.DesignLoaded.name, { EmailTemplateType: EmailTemplateType.ServiceReminder });
    });

    // This is a developer only utility used to capture the JSON needed in the creation of custom blocks.
    // Registering this call back disables saving blocks with Unlayer.
    DevMode.callback(() => {
      // eslint-disable-next-line
      // @ts-ignore
      editor.registerCallback('block:added', function (block, done) {
        DevMode.consoleLog('block:added', block);

        // Save the block to your database here
        // and pass the object to done callback.
        // Each block should have its own unique id

        done(block);
      });
    });
  };

  const StatusBadge = ({ status }: { status: 'Published' | 'Draft' }): JSX.Element => {
    return (
      <Flex
        px={2}
        py="1px"
        backgroundColor={status === 'Published' ? 'background.success' : 'background.warning'}
        borderRadius="md"
        align="center"
      >
        <Text size="xs" fontWeight="bold">
          {status}
        </Text>
      </Flex>
    );
  };

  return (
    <Box h="100%">
      <Flex
        backgroundColor="background.default"
        borderBottomWidth="1px"
        borderBottomColor="border.default"
        pl={2}
        pr={5}
        py={3}
        align="center"
      >
        <Button
          variant="ghostNeutral"
          iconName="chevronLeft"
          size="sm"
          onClick={(): void => history.push(backToLocation)}
        />
        <Heading size="sm" mx={2}>
          Email Delivery Template
        </Heading>
        <StatusBadge
          status={
            (unsavedChanges || !!scratchDesign || !published || !!draft) && !firstTimeUser ? 'Draft' : 'Published'
          }
        />
        <Spacer />
        <Collapse in={unsavedChanges || !!scratchDesign || !!draft}>
          <Button variant="tertiary" onClick={handleRevert} size="sm">
            Revert to Published
          </Button>
        </Collapse>
        <Button variant="secondary" isLoading={saving} onClick={handlePublish} size="sm" ml={3}>
          Publish
        </Button>
      </Flex>

      {!loading && (
        <ReactEmailEditor
          ref={emailEditorRef}
          options={options}
          minHeight="calc(100% - 122px)"
          onReady={handleOnReady}
          // TODO: move project ID to env
          projectId={117583}
          {...rest}
        />
      )}

      <Flex
        backgroundColor="background.default"
        borderTopWidth="1px"
        borderTopColor="border.default"
        justify="right"
        align="center"
        px={5}
        py={3}
      >
        <Collapse in={unsavedChanges || !!scratchDesign}>
          <Text size="sm" variant="subtle">
            Unsaved changes
          </Text>
        </Collapse>
        <Button variant="tertiary" onClick={handleCancel} disabled={!unsavedChanges && !scratchDesign} mx={3} size="md">
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          isLoading={saving}
          disabled={!unsavedChanges && !scratchDesign && !firstTimeUser}
          size="md"
        >
          Save Draft
        </Button>
      </Flex>
      <Modal isOpen={isPublishOpen} onClose={onPublishClose} size="sm">
        <ModalHeader>Publish Template</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>Are you sure you want to publish this email template?</Text>
        </ModalBody>
        <ModalFooterButtons submitText="Publish" isLoading={saving} onSubmit={handleConfirmPublish} />
      </Modal>
      <Modal isOpen={isRevertOpen} onClose={onRevertClose} size="sm">
        <ModalHeader>Revert to Published</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>Are you sure you want to revert to the published version of this email template?</Text>

          <Flex align="center" backgroundColor="background.warning" mt={3} py={2} px={3} borderRadius="base">
            <Icon name="warningSign" variant="warning" />
            <Text fontWeight="bold" ml={2}>
              The existing draft will be deleted
            </Text>
          </Flex>
        </ModalBody>

        <ModalFooterButtons
          submitText="Revert"
          isLoading={updating || loading}
          isDestructive={true}
          onSubmit={handleConfirmRevert}
        />
      </Modal>
    </Box>
  );
};
