export const email = /^([^\s@]+)@([^.\s]+)(\.([^.\s]+))+$/;
export const phone = /^\+?(1|0)?[-. ]?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
export const url = /\b((http|https):\/\/?)[^\s()<>]+(?:\([\w\d]+\)|([^[:punct:]\s]|\/?))/;
export const www = /\b(www)[^\s()<>]+(?:\([\w\d]+\)|([^[:punct:]\s]|\/?))/;
export const wildcard = /({{.+?}})/g;
export const usdCurrency = /^(0|[1-9][0-9]{0,2})*(\.\d{1,2})?$/;

// Otto's outgoing notification numbers (any starting with 844)
export const phoneNumberReserveList = [
  '8449323838',
  '8447581889',
  '8449324141',
  '8447181196',
  '8449324142',
  '8446790399',
];
