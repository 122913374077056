import React, { ReactNode, useEffect, useMemo } from 'react';
import {
  ApolloProvider as ParentProvider,
  ApolloClient,
  createHttpLink,
  split,
  from,
  ApolloLink,
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import { SubscriptionClient, ConnectionParams } from 'subscriptions-transport-ws';
import * as Sentry from '@sentry/react';
import { coreApiUrl } from 'shared/utils/http';
import { cache } from './cache';
import { getQaCoreApiUrl } from 'shared/utils/http';
import { env } from 'env';
import { commitInfo } from 'commitInfo';
import { useAppSelector } from 'state/hooks';
import { useAuth } from '../AuthProvider';

const DO_NOT_RETRY_OPERATIONS = ['refundStripePaymentIntent'];
const RETRY_UP_TO_LIMIT_OPERATIONS: Record<string, number> = {
  getInvoicesForClient: 1,
  getBalanceForClient: 1,
  getSingleInvoiceWithLineItems: 1,
};

const DEFAULT_RETRY_LIMIT = 5;

const createApolloClient = (
  getToken: (source?: string) => Promise<string | null>,
  onConnectionError: () => void,
  uri: string,
  clinicId: string | undefined,
): [ApolloClient<unknown>, SubscriptionClient] => {
  const httpLink = createHttpLink({
    uri,
  });

  const retryLink = new RetryLink({
    attempts: (count, operation, error): boolean => {
      // If there's a statusCode, don't retry (network errors do not have a status code; this also includes those exceeding the 60s timeout)
      if (error.statusCode) {
        return false;
      }

      // If this operation is not permitted to retry, do not allow a retry attempt
      if (DO_NOT_RETRY_OPERATIONS.includes(operation.operationName)) {
        return false;
      }

      // If `count` exceeds the retry limit, do not allow retries (note: the first retry is 1, so this must be >, not >=)
      if (count > RETRY_UP_TO_LIMIT_OPERATIONS[operation.operationName]) {
        return false;
      }

      // Otherwise, allow retry attempts
      return count < DEFAULT_RETRY_LIMIT;
    },
    delay: (count): number => {
      onConnectionError();
      return count * 10000 * Math.random();
    },
  });

  const additiveLink = from([retryLink, httpLink]);

  const coreApiUrl = getQaCoreApiUrl(true);

  const subscriptionClient = new SubscriptionClient(coreApiUrl, {
    reconnect: true,
    lazy: true,
    connectionParams: async (): Promise<ConnectionParams> => {
      const token = await getToken();
      return { authorization: token ? `Bearer ${token}` : '', clinic: clinicId || '' };
    },
    connectionCallback: (error): void => {
      if (error) Sentry.captureException(error);
    },
  });

  const wsLink = new WebSocketLink(subscriptionClient);

  const authLink = setContext(async (_, { headers }: { headers?: Record<string, unknown> }) => {
    const token = await getToken();
    return {
      headers: {
        authorization: token ? `Bearer ${token}` : '',
        clinic: clinicId || '',
        ...headers,
        CommitHash: commitInfo.commitHash,
        CommitNumber: commitInfo.commitNumber,
      },
    };
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    additiveLink,
  );

  // We need to add a middleware to the Apollo client to ensure that the
  // clinic header is set with the exception of the getClinicUser query
  const clinicHeaderMiddleware = new ApolloLink((operation, forward) => {
    const { operationName } = operation;
    const { clinic } = operation.getContext().headers;

    // List of operations that do not require the clinic header
    const operationExceptionList = ['getClinicUser'];

    if (!clinic && !operationExceptionList.includes(operationName)) {
      /**
       * This operation is skipped because a clinic header is required
       * but not yet available within context.
       */
      return null;
    }
    return forward(operation);
  });

  const apolloClient = new ApolloClient({
    link: authLink.concat(clinicHeaderMiddleware).concat(splitLink),
    cache,
    connectToDevTools: env.REACT_APP_DEVELOPMENT === 'true' ? true : false,
    defaultOptions: {
      watchQuery: {
        /**
         * TODO: Evaluate reverting this to default recommended by Apollo Client
         *
         * Apollo Client 3.4 introduced a change to overwrite rather than merge existing data
         * during a refetch.
         *
         * Source: https://github.com/apollographql/apollo-client/blob/main/CHANGELOG.md#bug-fixes-28
         */
        refetchWritePolicy: 'merge',
      },
    },
    name: 'clinic-web',
    version: commitInfo.commitHash,
  });

  return [apolloClient, subscriptionClient];
};

const ApolloProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const clinicId = useAppSelector((state) => state.currentClinic.clinicId);
  const { getToken, isAuthenticated } = useAuth();

  const handleConnectionError = useMemo(() => {
    return (): void => console.error('Oops we lost connection. Attempting to reconnect...');
  }, []);

  const [apolloClient, subscriptionClient] = useMemo(() => {
    return createApolloClient(getToken, handleConnectionError, coreApiUrl, clinicId);
  }, [getToken, handleConnectionError, clinicId]);

  useEffect(() => {
    if (!isAuthenticated) {
      subscriptionClient.close();
    }
  }, [isAuthenticated, subscriptionClient]);

  return <ParentProvider client={apolloClient}>{children}</ParentProvider>;
};

export default ApolloProvider;
