import React from 'react';
import { CampaignSummaryElement, CampaignSummaryElementState } from '../CampaignSummaryElement';
import { SegmentList } from './components/SegmentList';
import { SegmentSummary } from './components/SegmentSummary';
import { SegmentOptions } from './types/recipients.types';
import { useRecipientsReturnType } from './hooks/useRecipients';
import { EmailCampaignSegmentationType, EmailCampaignStatus } from 'shared/types/graphql';
import { Spinner } from '@televet/kibble-ui';

type RecipientsProps = {
  border: string;
  borderColor: string;
  loadingAdditionalSegmentProps: boolean;
  additionalSegmentProps: Record<string, unknown>;
} & useRecipientsReturnType;

const Recipients = ({
  campaign,
  border,
  borderColor,
  loadingCount,
  loadingAdditionalSegmentProps,
  additionalSegmentProps,
  recipientCount,
  isEditing,
  isSaving,
  allActiveSegmentationCount,
  saveRecipientSegmentation,
  setIsEditing,
  onCancel,
}: RecipientsProps): JSX.Element => {
  const showButton = campaign?.status === EmailCampaignStatus.Draft && !isEditing;

  const initiallySelected =
  campaign?.segmentationType === EmailCampaignSegmentationType.CustomList
    ? SegmentOptions.CustomList
    : campaign?.segmentationType === EmailCampaignSegmentationType.ClinicPetParentPrismaWhere
    ? SegmentOptions.Conditional
    : SegmentOptions.AllActive;

  return (
    <CampaignSummaryElement
      border={border}
      borderColor={borderColor}
      state={loadingCount || !!recipientCount ? CampaignSummaryElementState.Valid : CampaignSummaryElementState.Invalid}
      title="Recipients"
      showButton={showButton}
      buttonText="Edit Recipients"
      invalidText="Please add at least one recipient."
      onClick={(): void => setIsEditing(true)}
    >
      {loadingAdditionalSegmentProps ? <>
        <Spinner size="xs" />
      </> : (isEditing ? (
        <SegmentList
          campaign={campaign}
          initiallySelected={initiallySelected}
          recipientCount={recipientCount}
          allActiveSegmentationCount={allActiveSegmentationCount}
          saveRecipientSegmentation={saveRecipientSegmentation}
          onCancel={onCancel}
          isSaving={isSaving}
          additionalSegmentProps={additionalSegmentProps}
          loadingCount={loadingCount}
        />
      ) : (
          <SegmentSummary campaign={campaign} loading={loadingCount} recipientCount={recipientCount} additionalSegmentProps={additionalSegmentProps} />
        ))}
    </CampaignSummaryElement>
  );
};

export default Recipients;
