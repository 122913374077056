import { Button } from '@televet/kibble-ui/build/components/Button';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { Box, UnorderedList, ListItem, Collapse } from '@televet/kibble-ui/build/chakra';
import React, { useMemo, useState } from 'react';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { Appointment } from 'pages/Board';

interface AppointmentCardProps {
  appointmentData: Partial<Appointment>;
}

const AppointmentCardBody = ({ appointmentData }: AppointmentCardProps): JSX.Element => {
  const [isCardCollapsed, setIsCardCollapsed] = useState(true);

  const formattedStartAtTime = useMemo(
    () => format(parseISO(appointmentData.startAt).getTime(), "MMM do', ' h:mmbbb"),
    [appointmentData],
  );
  const formattedEndAtTime = useMemo(() => {
    if (!appointmentData.durationInMinutes) return;
    return format(
      parseISO(appointmentData.startAt).getTime() + appointmentData.durationInMinutes * 60 * 1000,
      ' - h:mmbbb',
    );
  }, [appointmentData]);

  return (
    <Box>
      <UnorderedList className="Board__Card-AppointmentsDetails" spacing={1} my={2}>
        {appointmentData.appointmentType?.name && (
          <ListItem mx={3}>
            <Text>Type: {appointmentData.appointmentType?.name}</Text>
          </ListItem>
        )}
        <ListItem mx={3}>
          <Text>
            Date: {formattedStartAtTime} {formattedEndAtTime}
          </Text>
        </ListItem>
        <ListItem mx={3}>
          <Text>Status: {appointmentData.pimsStatus || appointmentData.status}</Text>
        </ListItem>
      </UnorderedList>
      {appointmentData.description && (
        <Box className="Board__Card-AppointmentsDescription">
          <Collapse in={!isCardCollapsed}>
            <Text as="p" pb={2} mx={3}>
              {appointmentData.description}
            </Text>
          </Collapse>
          <Button
            iconName={isCardCollapsed ? 'chevronDown' : 'chevronUp'}
            iconPosition="right"
            size="xs"
            variant="ghostNeutral"
            onClick={(): void => setIsCardCollapsed((prevState) => !prevState)}
          >
            {isCardCollapsed ? 'Show' : 'Hide'} Notes
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default AppointmentCardBody;
