import { TypePolicy } from '@apollo/client';
import { ClinicPimsIntegrationType, VetDataIntegration } from 'shared/types/graphql';
import { getIsExportToPimsSupported } from '../../IntegrationsProvider/utils/getIsExportToPimsSupported';
import { getIntegrationDisplayName } from '../../IntegrationsProvider/utils/getIntegrationDisplayName';
import { getIsInvoicePreviewSupported } from '../../IntegrationsProvider/utils/getIsInvoicePreviewSupported';
import { getSystemNameFromIntegration } from '../../IntegrationsProvider/utils/getSystemNameFromIntegration';
import { getIsTriggerPimsSyncSupported } from 'shared/providers/IntegrationsProvider/utils/getIsTriggerPimsSyncSupported';
import { getIsMergeExistingPimsProfileSupported } from 'shared/providers/IntegrationsProvider/utils/getIsMergeExistingPimsProfileSupported';
import { getIsClientIdSupported } from 'shared/providers/IntegrationsProvider/utils/getIsClientIdSupported';
import { getIsContactSyncSupported } from 'shared/providers/IntegrationsProvider/utils/getIsContactSyncSupported';
import { getArePostcardsSupported } from 'shared/providers/IntegrationsProvider/utils/getArePostcardsSupported';
import { getIsBillingCodeSupported } from 'shared/providers/IntegrationsProvider/utils/getIsBillingCodeSupported';
import { getIsServiceCodeSupported } from 'shared/providers/IntegrationsProvider/utils/getIsServiceCodeSupported';
import { getIsPatientIdSupported } from 'shared/providers/IntegrationsProvider/utils/getIsPatientIdSupported';
import { getIsInvoiceDiscoverySupported } from 'shared/providers/IntegrationsProvider/utils/getIsInvoiceDiscoverySupported';
import { VetdataIntegrationSystemNameType } from 'shared/providers/IntegrationsProvider';

export const ClinicPimsIntegration: TypePolicy = {
  fields: {
    displayName: {
      read(_, { readField }) {
        const integrationType: ClinicPimsIntegrationType | undefined = readField('type');
        const vetdataSystem: VetdataIntegrationSystemNameType | undefined = readField({
          from: readField('vetdata'),
          fieldName: 'system',
        });
        return getIntegrationDisplayName(integrationType, vetdataSystem);
      },
    },
    isExportToPimsSupported: {
      read(_, { readField }) {
        const integrationType: ClinicPimsIntegrationType | undefined = readField('type');
        const vetdataSystem: VetdataIntegrationSystemNameType | undefined = readField({
          from: readField('vetdata'),
          fieldName: 'system',
        });
        return getIsExportToPimsSupported(integrationType, vetdataSystem);
      },
    },
    isInvoicePreviewSupported: {
      read(_, { readField }) {
        const integrationType: ClinicPimsIntegrationType | undefined = readField('type');
        const vetdataSystem: VetdataIntegrationSystemNameType | undefined = readField({
          from: readField('vetdata'),
          fieldName: 'system',
        });
        return getIsInvoicePreviewSupported(integrationType, vetdataSystem);
      },
    },
    isInvoiceDiscoverySupported: {
      read(_, { readField }) {
        const integrationType: ClinicPimsIntegrationType | undefined = readField('type');
        const vetdataSystem: VetdataIntegrationSystemNameType | undefined = readField({
          from: readField('vetdata'),
          fieldName: 'system',
        });
        return getIsInvoiceDiscoverySupported(integrationType, vetdataSystem);
      },
    },
    isTriggerPimsSyncSupported: {
      read(_, { readField }) {
        const integrationType: ClinicPimsIntegrationType | undefined = readField('type');
        return getIsTriggerPimsSyncSupported(integrationType);
      },
    },
    isMergeExistingPimsProfileSupported: {
      read(_, { readField }) {
        const vetdataSystem: VetdataIntegrationSystemNameType | undefined = readField({
          from: readField('vetdata'),
          fieldName: 'system',
        });
        return getIsMergeExistingPimsProfileSupported(vetdataSystem);
      },
    },
    isClientIdSupported: {
      read(_, { readField }) {
        const integrationType: ClinicPimsIntegrationType | undefined = readField('type');
        const vetdataSystem: VetdataIntegrationSystemNameType | undefined = readField({
          from: readField('vetdata'),
          fieldName: 'system',
        });
        return getIsClientIdSupported(integrationType, vetdataSystem);
      },
    },
    isPatientIdSupported: {
      read(_, { readField }) {
        const integrationType: ClinicPimsIntegrationType | undefined = readField('type');
        const vetdataSystem: VetdataIntegrationSystemNameType | undefined = readField({
          from: readField('vetdata'),
          fieldName: 'system',
        });
        return getIsPatientIdSupported(integrationType, vetdataSystem);
      },
    },
    isServiceCodeSupported: {
      read(_, { readField }) {
        const integrationType: ClinicPimsIntegrationType | undefined = readField('type');
        const vetdataSystem: VetdataIntegrationSystemNameType | undefined = readField({
          from: readField('vetdata'),
          fieldName: 'system',
        });
        return getIsServiceCodeSupported(integrationType, vetdataSystem);
      },
    },
    isContactSyncSupported: {
      read(_, { readField }) {
        const integrationType: ClinicPimsIntegrationType | undefined = readField('type');
        return getIsContactSyncSupported(integrationType);
      },
    },
    isBillingCodeSupported: {
      read(_, { readField }) {
        const vetdata: VetDataIntegration | undefined = readField('vetdata');
        return getIsBillingCodeSupported(vetdata?.system);
      },
    },
    arePostcardsSupported: {
      read(_, { readField }) {
        const integrationType: ClinicPimsIntegrationType | undefined = readField('type');
        return getArePostcardsSupported(integrationType);
      },
    },
    systemName: {
      read(_, { readField }) {
        const integrationType: ClinicPimsIntegrationType | undefined = readField('type');
        const vetdataSystem: VetdataIntegrationSystemNameType | undefined = readField({
          from: readField('vetdata'),
          fieldName: 'system',
        });
        return getSystemNameFromIntegration(integrationType, vetdataSystem);
      },
    },
  },
};
