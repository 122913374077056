import React, { useCallback, useMemo, useState } from 'react';
import { Box, TextInput, Text, Button, Flex, Icon } from '@televet/kibble-ui';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import {
  updateCurrentStep,
  updateOriginalInvoiceCost,
  updatePaymentDue,
  updateSelectedInvoiceId,
} from '../../../state/careWizardStepsSlice';
import { useForm } from 'react-hook-form';
import { usdCurrency } from 'shared/utils/validation';
import { BalanceForClientFragment, InvoiceForClientFragment } from 'shared/types/graphql';
import { StripeTerminalClinicPetParent } from 'shared/providers/StripeTerminalProvider/types/StripeTerminalClinicPetParent';
import InvoiceAndARDisplay from '../InvoiceAndARDisplay';
import HasFeature from 'shared/components/HasFeature';
import { FeatureFlagName } from 'shared/enums';
import useFeatureFlag from 'shared/hooks/useFeatureFlag';

interface IInvoiceTotalProps {
  currentStep: number;
  petName: string;
  clinicPetParent?: StripeTerminalClinicPetParent;
  propInvoiceData?: InvoiceForClientFragment[] | undefined;
  propBalanceData?: BalanceForClientFragment | undefined;
  emptyInvoiceMessage?: string;
}

interface IFormValues {
  invoiceTotal: number;
}

const InvoiceTotalScreen = ({
  currentStep,
  petName,
  clinicPetParent,
  propInvoiceData,
  propBalanceData,
  emptyInvoiceMessage,
}: IInvoiceTotalProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const originalInvoiceCost = useAppSelector((state) => state.CareWizardSteps.careWizardDraft.originalInvoiceCost);
  const isBalanceSelected = useAppSelector((state) => state.CareWizardSteps.careWizardDraft.isBalanceSelected);
  const selectedInvoiceId = useAppSelector((state) => state.CareWizardSteps.careWizardDraft.selectedInvoiceId);
  const setSelectedInvoiceId = useCallback(
    (selectedInvoiceId: string | null) => {
      dispatch(updateSelectedInvoiceId(selectedInvoiceId));
    },
    [dispatch],
  );
  const [selectedInvoiceTotal, setSelectedInvoiceTotal] = useState<number | null>(null);
  const {
    handleSubmit,
    register,
    errors,
    formState: { isValid },
    setValue,
  } = useForm<IFormValues>({
    mode: 'onChange',
  });

  const onSubmit = useCallback(
    (data: IFormValues) => {
      dispatch(updateCurrentStep(currentStep + 1));
      dispatch(updateOriginalInvoiceCost(data.invoiceTotal));
      dispatch(updatePaymentDue(data.invoiceTotal));
    },
    [dispatch, currentStep],
  );

  const defaultValue = useMemo(() => {
    if (!originalInvoiceCost) {
      return undefined;
    }
    return typeof originalInvoiceCost === 'number'
      ? originalInvoiceCost.toFixed(2)
      : Number(originalInvoiceCost)
      ? Number(originalInvoiceCost).toFixed(2)
      : undefined;
  }, [originalInvoiceCost]);

  const pimsId = clinicPetParent?.pimsId;

  const updateInvoiceCost = useCallback(
    (invoiceTotal: number) => {
      setSelectedInvoiceTotal(invoiceTotal);
      dispatch(updateOriginalInvoiceCost(invoiceTotal));
      dispatch(updatePaymentDue(invoiceTotal));
      setValue('invoiceTotal', invoiceTotal);
    },
    [dispatch, setValue],
  );
  const isBalanceOrInvoiceSelected = isBalanceSelected || (!!selectedInvoiceId && selectedInvoiceId.length > 0);

  const isNextButtonDisabled = useMemo(() => {
    // if isValid is false or if isBalanceSelected is false or selectedInvoiceId is null
    return !isValid && !isBalanceOrInvoiceSelected;
  }, [isValid, isBalanceOrInvoiceSelected]);

  const { isFeatureEnabled } = useFeatureFlag();
  const wizardPaymentsFFEnabled = isFeatureEnabled(FeatureFlagName.WizardPaymentIntegrations);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box mb={4}>
        <Text fontWeight="bold">What is the total on the invoice for {!!petName ? petName : 'your pet'}?</Text>
      </Box>
      <HasFeature name={FeatureFlagName.WizardPaymentIntegrations}>
        {!!pimsId && (
          <>
            <Box mb={2}>
              <Text size="sm">Select one</Text>
            </Box>
            <InvoiceAndARDisplay
              clinicPetParent={clinicPetParent}
              updateInvoiceCost={updateInvoiceCost}
              setSelectedInvoiceId={setSelectedInvoiceId}
              propInvoiceData={propInvoiceData}
              propBalanceData={propBalanceData}
              emptyInvoiceMessage={emptyInvoiceMessage}
            />
          </>
        )}
      </HasFeature>
      <TextInput
        data-testid="wizard-modal-invoice-total"
        type="number"
        step={0.01}
        min={0.01}
        w={32}
        label={pimsId && wizardPaymentsFFEnabled ? 'Or type in any amount' : 'Invoice Total'}
        labelStyle={
          pimsId && wizardPaymentsFFEnabled
            ? {
                fontSize: '14px',
              }
            : undefined
        }
        placeholder="0.00"
        defaultValue={selectedInvoiceTotal !== null ? selectedInvoiceTotal.toFixed(2) : defaultValue}
        isInvalid={!!errors.invoiceTotal && !isBalanceOrInvoiceSelected}
        errorText={errors.invoiceTotal?.message?.toString() || 'You cannot input more than two decimals.'}
        name="invoiceTotal"
        isDisabled={isBalanceOrInvoiceSelected}
        ref={register({
          required: 'Please enter an invoice total.',
          min: { value: 0.01, message: 'Value must be greater than 0.' },
          pattern: usdCurrency,
        })}
        leftElement={<Icon name="dollar" size="md" />}
      />
      <Flex pb={4} mt={4} justify="flex-end">
        <Button data-testid="wizard-invoice-next-button" type="submit" isDisabled={isNextButtonDisabled}>
          Next
        </Button>
      </Flex>
    </form>
  );
};

export default InvoiceTotalScreen;
