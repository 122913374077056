import { Box, Flex } from '@televet/kibble-ui/build/chakra';
import { Icon } from '@televet/kibble-ui/build/components/Icon';
import { Text } from '@televet/kibble-ui/build/components/Text';
import React from 'react';
import { ManageMembershipRequestType, PaymentSchedule } from 'shared/types/graphql';
import { formatCurrency } from '../../utils/currency';
import { ReadableCancelReason } from './records/activityLogCard.records';
import { EnrollmentSupportEventData } from './types';
import format from 'date-fns/format';

interface EnrollmentSupportEventCardBodyProps {
  data: EnrollmentSupportEventData;
}

const readablePaymentSchedule = {
  [PaymentSchedule.Full]: 'Pay in Full',
  [PaymentSchedule.OverTime]: 'Pay Over Time',
  PayInClinic: 'Pay In Clinic',
};

export const EnrollmentSupportEventCardBody = ({ data }: EnrollmentSupportEventCardBodyProps): JSX.Element => {
  const {
    manageMembershipRequestType,
    amount,
    paymentSchedule,
    cancelReason,
    currentPlanTitle,
    newPlanTitle,
    expirationDate,
    transferFromPetName,
    transferToPetName,
  } = data;

  return (
    <Box>
      {!!amount && amount > 0 && (
        <Flex alignItems="center" mb={1}>
          <Icon name="invoice" size="sm" variant="subtle" />
          <Text variant="subtle" size="sm" ml={1}>
            {manageMembershipRequestType === ManageMembershipRequestType.Cancel ? 'Amount Owed: ' : 'Upgrade Fee: '}
            {formatCurrency(amount, true)}
          </Text>
        </Flex>
      )}
      {!!paymentSchedule && !!amount && amount > 0 && (
        <Flex alignItems="center" mb={1}>
          <Icon name="creditCard" size="sm" variant="subtle" />
          <Text variant="subtle" size="sm" ml={1}>
            {readablePaymentSchedule[paymentSchedule]}
          </Text>
        </Flex>
      )}
      {!!cancelReason && (
        <Flex alignItems="center" mb={1}>
          <Icon name="questionMarkCircle" size="sm" variant="subtle" />
          <Text variant="subtle" size="sm" ml={1}>
            {ReadableCancelReason[cancelReason]}
          </Text>
        </Flex>
      )}
      {!!newPlanTitle && (
        <Flex alignItems="center" mb={1}>
          <Icon name="starSolid" size="sm" variant="subtle" />
          <Text as="p" size="sm" ml={1} variant="subtle">
            Current Plan: {newPlanTitle}
          </Text>
        </Flex>
      )}
      {!!currentPlanTitle && (
        <Flex alignItems="center" mb={1}>
          <Icon name="star" size="sm" variant="subtle" />
          <Text as="p" size="sm" ml={1} variant="subtle">
            Previous Plan: {currentPlanTitle}
          </Text>
        </Flex>
      )}
      {!!expirationDate && (
        <Flex alignItems="center" mb={1}>
          <Icon name="cancel" size="sm" variant="subtle" />
          <Text as="p" size="sm" ml={1} variant="subtle">
            Plan Ends On: {format(new Date(expirationDate), 'MM/dd/yyyy')}
          </Text>
        </Flex>
      )}
      {!!transferFromPetName && !!transferToPetName && (
        <Flex alignItems="center" mb={1}>
          <Icon name="paw" size="sm" variant="subtle" />
          <Text as="p" size="sm" ml={1} variant="subtle">
            Transferred from {transferFromPetName} to {transferToPetName}
          </Text>
        </Flex>
      )}
    </Box>
  );
};
