import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useModal } from '@televet/televet-ui';
import {
  AppointmentFullSelectionFragment,
  CareSubscriptionPaymentStatus,
  ClinicPimsIntegrationFragment,
  EnrollmentStatus,
  SortOrder,
  useFindManyAppointmentLazyQuery,
  useGetSidePanelPetProfileQuery,
} from 'shared/types/graphql';
import PetParentAvatar from 'shared/components/Avatars/PetParentAvatar';
import PetAvatar from 'shared/components/Avatars/PetAvatar';
import { titleCase } from 'shared/utils';
import useClinicUser from 'shared/hooks/useClinicUser';
import { useSidePanelSearch } from '../../state/providers/SearchPanelProvider';
import { GraphQLFetchPolicies } from 'shared/enums/GraphQLFetchPolicies';
import { ExtendedAppointment } from 'pages/Appointments';
import { ModalNames } from 'shared/enums/ModalNames';
import parseISO from 'date-fns/parseISO';
import isPast from 'date-fns/isPast';
import { DetailViewTabName, Mode, ShowDetailView } from '../../shared/types/enum/Mode';
import DevModeDisplayJSON from 'pages/Settings/components/DevModeDisplayJSON';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { Tabs } from '@televet/kibble-ui/build/components/Tabs';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { Heading } from '@televet/kibble-ui/build/components/Heading';
import { Tag } from '@televet/kibble-ui/build/components/Tag';
import { Icon } from '@televet/kibble-ui/build/components/Icon';
import { Checkbox } from '@televet/kibble-ui/build/components/Checkbox';
import { Tooltip } from '@televet/kibble-ui/build/components/Tooltip';
import { Popover } from '@televet/kibble-ui/build/components/Popover';
import { Box, HStack, Flex, VStack, Grid, GridItem, useDisclosure } from '@televet/kibble-ui/build/chakra';
import EditPatientPopover, { EditablePatientFields } from './components/EditPatientPopover';
import sortBy from 'lodash-es/sortBy';
import SidePanelAppointments from './components/SidePanelAppointments';
import SidePanelPetServices from './components/SidePanelPetServices';
import { useResolutionProvider } from 'shared/providers/ResolutionProvider';
import { PopoverPlacement } from 'shared/components/Popover';
import { useIntegrationsProvider } from 'shared/providers/IntegrationsProvider';
import DeceasedPetModal from './components/DeceasedPetModal';
import InactivePetModal from './components/InactivePetModal';
import useFeatureFlag from 'shared/hooks/useFeatureFlag';
import { FeatureFlagName } from 'shared/enums';
import { DEFAULT_APPOINTMENT_DURATION_IN_MINUTES } from 'pages/Appointments';
import TrupanionProfileCheck from 'shared/components/Trupanion/TrupanionProfileCheck';
import { isEnrollmentConsideredActive, getSubscriptionPaymentStatus } from '../Benefits/utils/careEnrollments';

interface ISidePanelPetProfileProps {
  clinicPetId: string | undefined | null;
  searchPanelMode: Mode;
}

const PetProfileLabelComponent = ({ displayText }: { displayText: string }): JSX.Element => {
  return (
    <Text className="SidePanelPetProfile__PetInfo-FieldLabel" as="p" size="xs" fontWeight="bold">
      {displayText}
    </Text>
  );
};

const SidePanelPetProfile = ({ clinicPetId, searchPanelMode }: ISidePanelPetProfileProps): JSX.Element => {
  const { openModal } = useModal();
  const {
    viewClinicPetParent,
    viewAddClientOrPatient,
    currentClinicPetParentId,
    setCurrentClinicPetParentId,
    setCurrentClinicPetId,
  } = useSidePanelSearch();
  const [showDetailView, setShowDetailView] = useState<ShowDetailView>(ShowDetailView.None);
  const [tabName, setTabName] = useState(DetailViewTabName.None);
  const [currentLoadCount, setCurrentLoadCount] = useState(2);
  const { currentHeight } = useResolutionProvider();
  const { isPatientIdSupported, primaryIntegrationName, allIntegrations } = useIntegrationsProvider();
  const { isFeatureEnabled } = useFeatureFlag();

  const {
    isOpen: isDeceasedPetModalOpen,
    onOpen: openDeceasedPetModal,
    onClose: onCloseDeceasedPetModal,
  } = useDisclosure();
  const {
    isOpen: isInactivePetModalOpen,
    onOpen: openInactivePetModal,
    onClose: onCloseInactivePetModal,
  } = useDisclosure();

  const { data: sidePanelPetProfileData, loading: isSidePanelPetProfileLoading } = useGetSidePanelPetProfileQuery({
    variables: { clinicPetId },
    skip: !clinicPetId,
    onCompleted: (data) => {
      // TODO: Move this logic to a central location so we don't have side effects like this
      // scattered in different places
      if (!currentClinicPetParentId || !data?.clinicPet?.petParents.some(({ id }) => id === currentClinicPetParentId)) {
        const firstPetParentId = data?.clinicPet?.petParents?.[0]?.id;
        if (firstPetParentId) {
          setCurrentClinicPetParentId(firstPetParentId);
        }
      }
    },
  });

  const clinicPet = sidePanelPetProfileData?.clinicPet;
  const { currentClinic } = useClinicUser();

  const patientEditingEnabled = useMemo(() => {
    const isPimsPet = !!clinicPet?.pimsId;
    const clinicHasActivePimsIntegration = !!allIntegrations?.some(
      (integration: ClinicPimsIntegrationFragment) => integration.isActive,
    );

    return !isPimsPet || !clinicHasActivePimsIntegration;
  }, [clinicPet?.pimsId, allIntegrations]);

  const [getPetAppointments, { data: appointmentsData, loading: isAppointmentDataLoading }] =
    useFindManyAppointmentLazyQuery({
      fetchPolicy: GraphQLFetchPolicies.CacheAndNetwork,
      nextFetchPolicy: GraphQLFetchPolicies.CacheFirst,
    });

  useEffect(() => {
    if (clinicPet?.id) {
      getPetAppointments({
        variables: {
          where: { clinicPet: { id: { equals: clinicPet.id } }, isDeleted: { equals: false } },
          orderBy: { startAt: SortOrder.Asc },
        },
      });
    }
  }, [clinicPet, getPetAppointments]);

  useEffect(() => {
    if (clinicPetId) {
      // set persistent clinicPetId for benefit panel
      setCurrentClinicPetId(clinicPetId);
    }
  }, [clinicPetId, setCurrentClinicPetId]);

  const goToPetParent = async (id: string): Promise<void> => {
    viewClinicPetParent({ clinicPetParentId: id });
  };

  const onAppointmentClicked = (appointment: ExtendedAppointment): void => {
    openModal(ModalNames.Appointment, {
      startDate: parseISO(appointment.startAt),
      durationInMinutes: appointment.durationInMinutes,
      appointmentId: appointment.id,
      defaultAppointmentDurationInMinutes:
        currentClinic?.clinicSetting?.appointmentTimeSlotIncrementInMinutes || DEFAULT_APPOINTMENT_DURATION_IN_MINUTES,
    });
  };

  const currentDate = useMemo(() => new Date().valueOf(), []);

  const petParentAppointments = useMemo(
    () => appointmentsData?.findManyAppointment || [],
    [appointmentsData?.findManyAppointment],
  );

  const petServiceReminder = useMemo(
    () => sidePanelPetProfileData?.clinicPet?.serviceReminders,
    [sidePanelPetProfileData?.clinicPet?.serviceReminders],
  );

  const onGetPetParentAppointment = useCallback(
    (passedTabName?: DetailViewTabName): AppointmentFullSelectionFragment[] => {
      const currentTabName = passedTabName || tabName;
      if (petParentAppointments === undefined) return [];
      return currentTabName === DetailViewTabName.Upcoming || currentTabName === DetailViewTabName.None
        ? petParentAppointments?.filter((appointment) => {
            return parseISO(appointment.startAt).valueOf() >= currentDate;
          })
        : petParentAppointments
            ?.filter((appointment) => {
              return parseISO(appointment.startAt).valueOf() < currentDate;
            })
            .reverse();
    },
    [currentDate, petParentAppointments, tabName],
  );

  const isDueDateWithinLimit = (dueDate: string): boolean => {
    const currentYear = new Date().getFullYear();
    const reminderDueDateYear = new Date(dueDate).getFullYear();
    return currentYear - reminderDueDateYear > 3;
  };

  const isReminderActive = (reminderActivationDate: string): boolean => {
    if (!reminderActivationDate) return false;

    return isPast(new Date(reminderActivationDate));
  };

  const getAllServiceReminders = useMemo(() => {
    if (petServiceReminder === undefined) return [];

    const sortedPetServiceReminder = [...petServiceReminder];

    sortedPetServiceReminder.sort((serviceDueA, serviceDueB) => {
      if (parseISO(serviceDueA.dueDate).valueOf() > parseISO(serviceDueB.dueDate).valueOf()) return 1;
      if (parseISO(serviceDueA.dueDate).valueOf() < parseISO(serviceDueB.dueDate).valueOf()) return -1;
      return 0;
    });

    const filteredServiceReminders = sortedPetServiceReminder.filter((reminder) => {
      return isReminderActive(reminder.service.reminderActivationDate) && !isDueDateWithinLimit(reminder.dueDate);
    });

    return filteredServiceReminders;
  }, [petServiceReminder]);

  const {
    gender,
    species,
    weight,
    weightUnit,
    dateOfBirth,
    dateOfDeath,
    petParents,
    alerts,
    pimsId,
    photoUrl,
    breed,
    color,
    organizationPet,
    name,
    computed_rawPimsIsDeceased: pimsIsDeceased,
    computedIsDeceased,
    computed_rawPimsIsActive: pimsIsActive,
    computedIsActive,
  } = clinicPet || {};

  const inactiveTooltipMessage = useMemo(() => {
    if (!computedIsActive) {
      if (computedIsDeceased) {
        return 'This patient is deceased and cannot be marked as active.';
      } else if (!pimsIsActive) {
        return `This patient was marked inactive in ${primaryIntegrationName} and cannot be reactivated here.`;
      }
    }

    return '';
  }, [computedIsActive, computedIsDeceased, pimsIsActive, primaryIntegrationName]);

  const isCareEnabled = useMemo(() => isFeatureEnabled(FeatureFlagName.CarePlans), [isFeatureEnabled]);
  const isCareMembershipActive = useMemo(() => {
    const enrollment = organizationPet?.carePlanEnrollments?.find((enrollment) =>
      isEnrollmentConsideredActive(enrollment),
    );
    return isCareEnabled && enrollment?.status === EnrollmentStatus.Active;
  }, [isCareEnabled, organizationPet?.carePlanEnrollments]);
  const careSubscriptionPaymentStatus = useMemo(() => getSubscriptionPaymentStatus(organizationPet), [organizationPet]);

  if (showDetailView !== ShowDetailView.None) {
    return (
      <Box height="100%">
        <HStack px={2} h="96px" mt={0}>
          <Button
            iconName="chevronLeft"
            variant="ghostNeutral"
            onClick={(): void => {
              setTabName(DetailViewTabName.None);
              setShowDetailView(ShowDetailView.None);
              setCurrentLoadCount(2);
            }}
          />
          <PetAvatar
            species={species}
            isDeceased={computedIsDeceased}
            isActive={computedIsActive}
            photoUrl={photoUrl}
            name={name}
            size="lg"
          />
          <Heading size="md">{clinicPet?.name}</Heading>
        </HStack>
        <Heading size="sm" px={4} mb={3}>
          {showDetailView}
        </Heading>
        {showDetailView === ShowDetailView.Appointments && (
          <Tabs
            align="start"
            defaultIndex={tabName === DetailViewTabName.Upcoming ? 0 : 1}
            onChange={(index: number): void =>
              index === 0 ? setTabName(DetailViewTabName.Upcoming) : setTabName(DetailViewTabName.History)
            }
            size="sm"
            tabList={[
              {
                label: DetailViewTabName.Upcoming,
                width: '50%',
              },
              {
                label: DetailViewTabName.History,
                width: '50%',
              },
            ]}
          />
        )}
        <Box
          px={4}
          pb={3}
          overflowY="scroll"
          height={currentHeight - (showDetailView === ShowDetailView.Appointments ? 324 : 280)}
        >
          {showDetailView === ShowDetailView.Appointments ? (
            <SidePanelAppointments
              onGetPetParentAppointment={onGetPetParentAppointment}
              handleAppointmentClicked={onAppointmentClicked}
              isAppointmentDataLoading={isAppointmentDataLoading}
              onShowDetailView={setShowDetailView}
              showDetailView={showDetailView}
              currentLoadCount={currentLoadCount}
              onSetCurrentLoadCount={setCurrentLoadCount}
              onSetTabName={setTabName}
              currentTabName={tabName}
            />
          ) : (
            <SidePanelPetServices
              onGetPetServiceDue={getAllServiceReminders}
              isSidePanelPetProfileLoading={isSidePanelPetProfileLoading}
              onShowDetailView={setShowDetailView}
              showDetailView={showDetailView}
              currentLoadCount={currentLoadCount}
              onSetCurrentLoadCount={setCurrentLoadCount}
              onSetTabName={setTabName}
            />
          )}
        </Box>
      </Box>
    );
  }

  return (
    <Box className="SidePanelPetProfile" overflowX="hidden" overflowY="auto" w="100%">
      <VStack className="SidePanelPetProfile__PetPhoto" textAlign="center" m="30px 0">
        <Box className="SidePanelPetProfile__PetAvatar" mb={2} pos="relative" width="max-content">
          <PetAvatar
            size="lg"
            species={species}
            photoUrl={photoUrl}
            isDeceased={computedIsDeceased}
            isActive={computedIsActive}
            name={name}
            {...(isCareEnabled && { careStatus: careSubscriptionPaymentStatus })}
          />
        </Box>
        <VStack>
          <EditPatientPopover
            clinicPetId={clinicPetId}
            field={EditablePatientFields.Name}
            currentValue={clinicPet?.name}
            editingEnabled={patientEditingEnabled}
            placement={PopoverPlacement.Bottom}
            anchor={
              <Box className="SidePanelPetProfile__PetName">
                <Text as="span" lineHeight={0} size="xl" fontWeight="bold">
                  {titleCase(clinicPet?.name || '')}
                </Text>
                {isPatientIdSupported && pimsId && <Text as="span" p={1.5} size="sm">{`ID: ${pimsId}`}</Text>}
              </Box>
            }
          />
        </VStack>
        <Flex className="SidePanelPetProfile__Badges" mb={2} flexWrap="wrap" justifyContent="center">
          {clinicPet && (computedIsDeceased || !computedIsActive) && (
            <>
              <Box className="SidePanelPetProfile__InactiveBadge" display="inline-block">
                <Tag label="Inactive" backgroundColor="background.dangerSubtle" m={1} />
              </Box>
              {isCareMembershipActive && (
                <Tag label="Care Member" backgroundColor="background.warning" color="text.warning" m={1} />
              )}
            </>
          )}
          {careSubscriptionPaymentStatus === CareSubscriptionPaymentStatus.PastDue && (
            <Tag label="Care Payment Lapsed" backgroundColor="background.dangerSubtle" color="text.danger" m={1} />
          )}
          {careSubscriptionPaymentStatus === 'CancelledButStillPaying' && (
            <Tag
              label="Paying off Care Membership"
              backgroundColor="background.subtle"
              color="text.placeholder"
              m={1}
            />
          )}
        </Flex>
        <DevModeDisplayJSON src={clinicPet || undefined} />
      </VStack>
      <Grid
        className="SidePanelPetProfile__PetInfo"
        templateColumns="repeat(2, 1fr)"
        gap={2}
        bg="background.subtle"
        p={4}
      >
        <GridItem className="SidePanelPetProfile__PetInfo-Field">
          <PetProfileLabelComponent displayText="DOB" />
          <EditPatientPopover
            clinicPetId={clinicPetId}
            field={EditablePatientFields.Birthday}
            currentValue={dateOfBirth}
            editingEnabled={patientEditingEnabled}
            placement={PopoverPlacement.BottomEnd}
          />
        </GridItem>
        <GridItem className="SidePanelPetProfile__PetInfo-Field">
          <PetProfileLabelComponent displayText="Species" />
          <EditPatientPopover
            clinicPetId={clinicPetId}
            field={EditablePatientFields.Species}
            currentValue={species}
            editingEnabled={patientEditingEnabled}
            placement={PopoverPlacement.BottomStart}
          />
        </GridItem>
        <GridItem className="SidePanelPetProfile__PetInfo-Field">
          <PetProfileLabelComponent displayText="Sex" />
          <EditPatientPopover
            clinicPetId={clinicPetId}
            field={EditablePatientFields.Sex}
            currentValue={gender}
            editingEnabled={patientEditingEnabled}
            placement={PopoverPlacement.BottomEnd}
          />
        </GridItem>
        <GridItem className="SidePanelPetProfile__PetInfo-Field">
          <PetProfileLabelComponent displayText="Breed" />
          <EditPatientPopover
            clinicPetId={clinicPetId}
            field={EditablePatientFields.Breed}
            currentValue={breed}
            editingEnabled={patientEditingEnabled}
            placement={PopoverPlacement.BottomStart}
          />
        </GridItem>
        <GridItem className="SidePanelPetProfile__PetInfo-Field">
          <PetProfileLabelComponent displayText="Weight" />
          <EditPatientPopover
            clinicPetId={clinicPetId}
            field={EditablePatientFields.Weight}
            currentValue={weight}
            editingEnabled={patientEditingEnabled}
            unit={weightUnit}
            placement={PopoverPlacement.BottomEnd}
          />
        </GridItem>
        <GridItem className="SidePanelPetProfile__PetInfo-Field">
          <PetProfileLabelComponent displayText="Color" />
          <EditPatientPopover
            clinicPetId={clinicPetId}
            field={EditablePatientFields.Color}
            currentValue={color}
            editingEnabled={patientEditingEnabled}
            placement={PopoverPlacement.BottomStart}
          />
        </GridItem>
        <GridItem className="SidePanelPetProfile__PetInfo-Field">
          <PetProfileLabelComponent displayText="Deceased" />
          <HStack spacing={1}>
            <Tooltip
              titleCaseLabel={false}
              label={`This patient was marked deceased in ${primaryIntegrationName} and cannot be changed here.`}
              isDisabled={!pimsIsDeceased}
            >
              <Box my="6px">
                <Checkbox
                  onChange={openDeceasedPetModal}
                  isChecked={computedIsDeceased}
                  isDisabled={!!pimsIsDeceased}
                  size="lg"
                />
              </Box>
            </Tooltip>

            {computedIsDeceased && (
              <EditPatientPopover
                clinicPetId={clinicPetId}
                field={EditablePatientFields.DeceasedDate}
                currentValue={dateOfDeath}
                editingEnabled={!pimsIsDeceased}
                placement={PopoverPlacement.BottomEnd}
              />
            )}
          </HStack>
        </GridItem>
        <GridItem className="SidePanelPetProfile__PetInfo-Field">
          <HStack spacing={1} align="center">
            <PetProfileLabelComponent displayText="Inactive" />
            <Popover
              placement="top"
              trigger="hover"
              headerProps={{
                children: 'Inactive Patient',
                hideCloseButton: true,
              }}
              anchor={
                <Box lineHeight={0}>
                  <Icon name="infoCircle" size="xs" variant="subtle" />
                </Box>
              }
            >
              Inactive patients can not be added to Conversations or Appointments. Appointments associated with this
              patient will not trigger automations or follow-up surveys.
            </Popover>
          </HStack>
          <Tooltip titleCaseLabel={false} label={inactiveTooltipMessage} isDisabled={computedIsActive}>
            <Box my="6px" display="inline-block">
              <Checkbox
                onChange={openInactivePetModal}
                isChecked={!computedIsActive || computedIsDeceased}
                isDisabled={(!pimsIsActive && !computedIsActive) || computedIsDeceased}
                size="lg"
                my="6px"
              />
            </Box>
          </Tooltip>
        </GridItem>
      </Grid>

      <Box className="SidePanelPetProfile__PetAlerts" px={4} py={5}>
        <HStack alignItems="center" spacing={1}>
          <Icon name="alertCircle" size="sm" variant="warning" />
          <Text fontWeight="bold">Alerts</Text>
        </HStack>
        <Text size="sm" fontWeight="bold" p={2.5}>
          {alerts && alerts?.length > 0
            ? alerts.map(({ value }) => value).join(', ')
            : 'There are no alerts for this patient'}
        </Text>
      </Box>

      <Box className="SidePanelPetProfile__TrupanionProfileCheck">
        <TrupanionProfileCheck clinicPetParentId={currentClinicPetParentId || ''} />
      </Box>

      <Box className="SidePanelPetProfile__PetParents" py={4}>
        <HStack className="SidePanelPetProfile__PetParentsHeader" align="center" justify="space-between" px={4} mb={2}>
          <Text fontWeight="bold">{petParents && petParents.length > 1 ? 'Clients' : 'Client'}</Text>
          {searchPanelMode !== Mode.LinkToChannel && (
            <Button
              size="xs"
              onClick={(): void => {
                viewAddClientOrPatient({ selectedClinicPet: clinicPet || undefined });
              }}
              data-mixpanel-name="Add new client from patient profile button"
            >
              Add New
            </Button>
          )}
        </HStack>

        {petParents?.length ? (
          sortBy(
            petParents,
            (petParent) => `${petParent?.firstName.toLowerCase()} ${petParent?.lastName.toLowerCase()}`,
          ).map((clinicPetParent) => {
            const { firstName, lastName, id } = clinicPetParent;
            return (
              <HStack
                className="SidePanelPetProfile__PetParentItem"
                onClick={(): Promise<void> => goToPetParent(id)}
                key={id}
                spacing={3}
                justify="flex-start"
                align="center"
                py={2}
                px={4}
                cursor="pointer"
                _hover={{
                  bg: 'background.subtle',
                }}
              >
                <PetParentAvatar clinicPetParent={clinicPetParent} size="lg" />
                <Text fontWeight="bold">{`${titleCase(firstName)} ${titleCase(lastName)}`}</Text>
              </HStack>
            );
          })
        ) : (
          <Box textAlign="center" className="SidePanelPetProfile__PetParentEmptyState">
            <Text variant="subtle">No clients recorded</Text>
          </Box>
        )}
      </Box>
      <Box p={4}>
        <HStack justify="space-between">
          <Heading size="sm">{ShowDetailView.Appointments}</Heading>
          {onGetPetParentAppointment(DetailViewTabName.History).length > 0 && (
            <Button
              variant="ghost"
              size="xs"
              onClick={(): void => {
                setTabName(DetailViewTabName.History);
                setShowDetailView(ShowDetailView.Appointments);
                setCurrentLoadCount(20);
              }}
            >
              View History
            </Button>
          )}
        </HStack>
        <SidePanelAppointments
          onGetPetParentAppointment={onGetPetParentAppointment}
          handleAppointmentClicked={onAppointmentClicked}
          isAppointmentDataLoading={isAppointmentDataLoading}
          onShowDetailView={setShowDetailView}
          showDetailView={showDetailView}
          currentLoadCount={currentLoadCount}
          onSetCurrentLoadCount={setCurrentLoadCount}
          onSetTabName={setTabName}
          currentTabName={tabName}
        />
      </Box>

      {currentClinic?.hasServiceReminders && (
        <Box p={4}>
          <Heading size="sm">{ShowDetailView.ServicesDue}</Heading>
          <SidePanelPetServices
            onGetPetServiceDue={getAllServiceReminders}
            isSidePanelPetProfileLoading={isSidePanelPetProfileLoading}
            onShowDetailView={setShowDetailView}
            showDetailView={showDetailView}
            currentLoadCount={currentLoadCount}
            onSetCurrentLoadCount={setCurrentLoadCount}
            onSetTabName={setTabName}
          />
        </Box>
      )}

      <DeceasedPetModal
        isOpen={isDeceasedPetModalOpen}
        isCurrentlyDeceased={computedIsDeceased}
        petId={clinicPetId}
        handleClose={onCloseDeceasedPetModal}
      />
      <InactivePetModal
        isOpen={isInactivePetModalOpen}
        isCurrentlyActive={computedIsActive}
        petId={clinicPetId}
        handleClose={onCloseInactivePetModal}
      />
    </Box>
  );
};

export default SidePanelPetProfile;
