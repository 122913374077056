import React, { useCallback, useEffect, useState, useMemo } from 'react';
import * as Sentry from '@sentry/react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components/macro';
import { Modal, ModalSizes, useModal } from '@televet/televet-ui';
import {
  ChannelViewChannelMessageAttachmentFragment,
  StripePaymentIntent,
  StripePaymentIntentStatus,
  StripePaymentIntentFragment,
  SubscribeToStripePaymentIntentChangedDocument,
  useCancelStripePaymentIntentMutation,
  useGetStripePaymentIntentQuery,
} from 'shared/types/graphql';
import { ModalNames } from 'shared/enums/ModalNames';
import { GET_INVOICES_FOR_PAYMENT_INTENT } from 'pages/Conversations/graphql/queries';
import { InvoiceOutlineIcon } from 'assets/icons';
import { Mixpanel } from 'shared/utils/mixpanel';
import { GraphQLFetchPolicies } from 'shared/enums/GraphQLFetchPolicies';
import { getPaymentIntentDetails } from 'shared/utils/stripe';
import RefundModal from 'shared/components/RefundModal';
import { getInvoiceIdentifierCopy } from 'shared/utils';
import useClinicUser from 'shared/hooks/useClinicUser';
import usePersistedState from 'shared/hooks/usePersistedState';
import ReceiptModal from '../../../../Modals/InvoicePreview/ReceiptModal';
import { Tooltip } from '@televet/kibble-ui/build/components/Tooltip';
import { useDisclosure } from '@televet/kibble-ui/build/chakra';
import { FeatureFlagName, PersistedStateKeys } from 'shared/enums';
import { useIntegrationsProvider } from 'shared/providers/IntegrationsProvider';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { Icon } from '@televet/kibble-ui';
import useFeatureFlag from 'shared/hooks/useFeatureFlag';

enum ActionTypes {
  Refund = 'refund',
  Cancel = 'cancel',
}

interface IInvoiceAttachmentProps {
  attachment: ChannelViewChannelMessageAttachmentFragment;
  channelId: string;
  setCurrentInvoiceId: (invoiceId: string) => void;
}

const InvoiceAttachment = React.memo(
  ({ attachment, channelId, setCurrentInvoiceId }: IInvoiceAttachmentProps): JSX.Element => {
    const [isDiscoveredInvoiceDemoModeEnabled] = usePersistedState(
      PersistedStateKeys.IsDiscoveredInvoiceDemoModeEnabled,
      false,
    );

    const { openModal, closeModal, modal } = useModal();
    const { currentClinic } = useClinicUser();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [paymentIntent, setPaymentIntent] = useState<StripePaymentIntentFragment | null>(null);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const { url, filename } = attachment || {};
    const { isFeatureEnabled } = useFeatureFlag();
    const [cancelStripePayment] = useCancelStripePaymentIntentMutation();

    const {
      data: stripePaymentIntentData,
      loading: stripePaymentIntentDataLoading,
      subscribeToMore: subscribeToStripePaymentIntent,
      refetch: refetchStripePaymentIntent,
    } = useGetStripePaymentIntentQuery({
      variables: {
        where: {
          id: attachment?.entityId,
        },
      },
      fetchPolicy: GraphQLFetchPolicies.CacheAndNetwork,
      skip: !attachment?.entityId,
    });

    const { data: invoiceData } = useQuery<{
      findUniqueStripePaymentIntent: StripePaymentIntent;
    }>(GET_INVOICES_FOR_PAYMENT_INTENT, {
      variables: {
        where: {
          id: attachment?.entityId,
        },
      },
      fetchPolicy: GraphQLFetchPolicies.CacheAndNetwork,
      skip: !attachment?.entityId,
    });

    useEffect(() => {
      let unsubscribe: () => void;
      if (stripePaymentIntentData) {
        const paymentIntent = stripePaymentIntentData.stripePaymentIntent;
        if (!paymentIntent) return;

        setPaymentIntent(paymentIntent);

        if (paymentIntent.status !== StripePaymentIntentStatus.Succeeded) {
          unsubscribe = subscribeToStripePaymentIntent({
            document: SubscribeToStripePaymentIntentChangedDocument,
            variables: { where: { node: { id: paymentIntent.id } } },
            updateQuery: (
              prev,
              {
                subscriptionData,
              }: { subscriptionData: { data: { stripePaymentIntent: { node: StripePaymentIntentFragment } } } },
            ) => {
              if (!subscriptionData?.data?.stripePaymentIntent?.node) return prev;
              const stripePaymentIntent = subscriptionData.data.stripePaymentIntent.node;
              if (stripePaymentIntent.status === StripePaymentIntentStatus.Succeeded) {
                unsubscribe();
              }
              return Object.assign({}, prev, { stripePaymentIntent });
            },
          });
        }
      }
      return (): void => {
        if (typeof unsubscribe === 'function') unsubscribe();
      };
    }, [stripePaymentIntentData, subscribeToStripePaymentIntent]);

    const hasCustomizableFees = useMemo(
      () =>
        !!currentClinic?.clinicSetting?.hasCustomizableFees,
      [currentClinic?.clinicSetting?.hasCustomizableFees],
    );

    const { isInvoicePreviewSupported } = useIntegrationsProvider();

    const showReceiptPreviewAttachment = useMemo(() => {
      return (
        invoiceData?.findUniqueStripePaymentIntent?.invoices &&
        isInvoicePreviewSupported &&
        invoiceData?.findUniqueStripePaymentIntent?.invoices?.length > 0 &&
        paymentIntent?.status === StripePaymentIntentStatus.Succeeded
      );
    }, [invoiceData, isInvoicePreviewSupported, paymentIntent]);

    const showInvoicePreviewAttachment = useMemo(() => {
      if (isDiscoveredInvoiceDemoModeEnabled) return true;

      return (
        invoiceData?.findUniqueStripePaymentIntent?.invoices &&
        invoiceData?.findUniqueStripePaymentIntent?.invoices?.length > 0 &&
        isInvoicePreviewSupported &&
        paymentIntent?.status !== StripePaymentIntentStatus.Canceled &&
        !showReceiptPreviewAttachment
      );
    }, [
      invoiceData?.findUniqueStripePaymentIntent?.invoices,
      isInvoicePreviewSupported,
      isDiscoveredInvoiceDemoModeEnabled,
      paymentIntent?.status,
      showReceiptPreviewAttachment,
    ]);

    const totalPaid = useMemo(() => {
      return stripePaymentIntentData?.stripePaymentOutput?.payment?.amountCaptured || 0;
    }, [stripePaymentIntentData?.stripePaymentOutput?.payment?.amountCaptured]);

    const stripePaymentMethod = useMemo(() => {
      if (stripePaymentIntentData?.stripePaymentIntent?.stripePaymentMethod) {
        return [
          stripePaymentIntentData?.stripePaymentIntent?.stripePaymentMethod.cardBrand,
          stripePaymentIntentData?.stripePaymentIntent?.stripePaymentMethod.last4,
        ];
      }
      return null;
    }, [stripePaymentIntentData?.stripePaymentIntent?.stripePaymentMethod]);

    const onCancelClicked = async (): Promise<void> => {
      if (!paymentIntent?.stripeId) return;
      closeModal();
      try {
        setIsProcessing(true);
        await cancelStripePayment({
          variables: {
            where: {
              id: paymentIntent.id,
            },
          },
        });
        Mixpanel.track('Cancel invoice clicked');
      } catch (e) {
        Sentry.captureException(e);
        console.error(e);
      } finally {
        setIsProcessing(false);
      }
    };

    const { isRefunded, isRefundedPartially } = useMemo(() => getPaymentIntentDetails(paymentIntent), [paymentIntent]);

    const onCancelOrRefundInvoice = (actionType: ActionTypes): void => {
      if (attachment?.entityId) {
        openModal(`${ModalNames.CancelRefundInvoice}-${attachment.entityId}`, {
          actionType,
        });
      }
    };

    const openReceiptPreview = (): void => {
      Mixpanel.track('View itemized receipt clicked', { source });
      onOpen();
    };

    /**
     * Return total for full total with fees
     * Return - if none are available
     */
    const totalInitialRemainingBalance: string = useMemo(() => {
      const { totalInitialRemainingBalance } = getPaymentIntentDetails(paymentIntent);
      return totalInitialRemainingBalance;
    }, [paymentIntent]);

    const renderPaymentStatus = (paymentIntent: StripePaymentIntentFragment | null): JSX.Element | null => {
      const status = paymentIntent?.status;
      const paymentMethod = paymentIntent?.stripePaymentMethod;
      switch (status) {
        case StripePaymentIntentStatus.Succeeded:
          return (
            <>
              <Status paymentIntentStatus={!isRefunded ? paymentIntent?.status : StripePaymentIntentStatus.Canceled}>
                {stripePaymentIntentDataLoading
                  ? 'Loading...'
                  : isRefunded
                  ? `${isRefundedPartially ? 'Partially ' : ''} Refunded`
                  : `Received!`}
              </Status>

              {paymentMethod?.cardBrand && paymentMethod?.last4 && (
                <CardInformation>
                  {`${paymentMethod.cardBrand} ending in
                       ${paymentMethod.last4}`}
                </CardInformation>
              )}
              {totalInitialRemainingBalance !== '0.00' && (
                <>
                  <IssueRefundButtonDiv
                    onClick={(): void =>
                      attachment?.entityId && openModal(`${ModalNames.Refund}-${attachment?.entityId}`)
                    }
                  >
                    <IssueRefundButton data-testid="invoice-attachment-issue-refund-button">
                      {isProcessing
                        ? 'Issuing Refund...'
                        : `Issue ${isRefundedPartially ? 'Another Partial' : ''} Refund`}
                    </IssueRefundButton>
                  </IssueRefundButtonDiv>
                </>
              )}
            </>
          );
        case StripePaymentIntentStatus.Canceled:
          return (
            <Status paymentIntentStatus={paymentIntent?.status}>
              {stripePaymentIntentDataLoading ? 'Loading...' : 'Canceled'}
            </Status>
          );
        case StripePaymentIntentStatus.RequiresPaymentMethod:
          return (
            <>
              <Status paymentIntentStatus={paymentIntent?.status}>
                {stripePaymentIntentDataLoading ? 'Loading...' : 'Waiting for Payment'}
              </Status>
              <CancelInvoiceButton
                disabled={stripePaymentIntentDataLoading || isProcessing}
                type="button"
                onClick={(): void => onCancelOrRefundInvoice(ActionTypes.Cancel)}
              >
                {isProcessing ? 'Canceling Invoice...' : 'Cancel Invoice'}
              </CancelInvoiceButton>
            </>
          );
        default:
          return null;
      }
    };

    const handlePreviewClick = useCallback(() => {
      Object.assign(document.createElement('a'), { target: '_blank', href: url }).click();
    }, [url]);

    const source = 'ConversationInvoiceAttachment';
    const handleInvoiceClick = useCallback(
      (invoiceId: string): void => {
        setCurrentInvoiceId(invoiceId);
        Mixpanel.track(`View itemized invoice clicked`, { source });
        openModal(ModalNames.InvoicePreview);
      },
      [openModal, setCurrentInvoiceId],
    );

    return (
      <>
        <InvoiceAttachmentContainer>
          <InvoiceContainer>
            <InvoiceHeader>
              <Icon name="invoice" />
              <Text fontWeight="bold" variant="onContrast" mx={2}>
                Invoice
              </Text>
              {invoiceData
                ? invoiceData?.findUniqueStripePaymentIntent?.invoices?.map((invoice) => (
                    <Invoice key={`${attachment?.entityId}-${invoice.id}`}>
                      {getInvoiceIdentifierCopy(invoice.identifier, invoice.date)}
                    </Invoice>
                  ))
                : null}
            </InvoiceHeader>
            <Amount>
              <div>Total:</div>
              <div>$ {totalInitialRemainingBalance}</div>
            </Amount>
            <StatusContainer data-testid="invoice-attachment-status">
              {renderPaymentStatus(paymentIntent)}
            </StatusContainer>
          </InvoiceContainer>
          {(showInvoicePreviewAttachment || showReceiptPreviewAttachment || (!!url && !!filename)) && <HR />}

          {showInvoicePreviewAttachment && !isDiscoveredInvoiceDemoModeEnabled ? (
            invoiceData?.findUniqueStripePaymentIntent?.invoices?.map((invoice) => (
              <Tooltip label="View invoice" key={invoice.id} placement="top">
                <Document onClick={(): void => handleInvoiceClick(invoice.id)}>
                  <Icon name="invoice" />
                  <Text ml={2} size="sm" variant="onContrast">{`Invoice ${getInvoiceIdentifierCopy(
                    invoice.identifier,
                    invoice.date,
                  )}`}</Text>
                </Document>
              </Tooltip>
            ))
          ) : showInvoicePreviewAttachment && isDiscoveredInvoiceDemoModeEnabled ? (
            <Tooltip label="View invoice" placement="top">
              <Document onClick={(): void => handleInvoiceClick('')}>
                <Icon name="invoice" />
                <Text ml={2} size="sm" variant="onContrast">{`Invoice ${getInvoiceIdentifierCopy('3291991')}`}</Text>
              </Document>
            </Tooltip>
          ) : null}
          {showReceiptPreviewAttachment &&
            invoiceData?.findUniqueStripePaymentIntent?.invoices?.map((invoice) => (
              <>
                <Document onClick={openReceiptPreview} key={invoice.id}>
                  <Icon name="invoice" />
                  Receipt {getInvoiceIdentifierCopy(invoice.identifier, invoice.createdAt)}
                </Document>
                <ReceiptModal
                  isOpen={isOpen}
                  onClose={onClose}
                  invoiceId={invoice.id}
                  totalPaid={totalPaid}
                  stripePaymentMethod={stripePaymentMethod}
                  defaultServiceFeePercentage={
                    (!!hasCustomizableFees && !isFeatureEnabled(FeatureFlagName.TerminalAppOnlySurchargeUpdates))
                      ? currentClinic?.clinicSetting?.paymentFeeConfig?.onlineClientServiceFeePercent || 0
                      : 0
                  }
                  clinicPetParentId={invoice.clinicPetParentId}
                />
              </>
            ))}
          {!!url && !!filename && (
            <Tooltip label="View document" placement="top">
              <Document onClick={handlePreviewClick}>
                <Icon name="invoice" />
                <Text variant="onContrast" isTruncated>
                  {filename}
                </Text>
              </Document>
            </Tooltip>
          )}
        </InvoiceAttachmentContainer>

        {paymentIntent && (
          <RefundModal
            setIsProcessing={setIsProcessing}
            elementId={attachment?.entityId || ''}
            paymentIntent={paymentIntent}
            refetchStripePaymentIntent={refetchStripePaymentIntent}
            channelId={channelId}
          />
        )}
        <Modal
          name={`${ModalNames.CancelRefundInvoice}-${attachment?.entityId}`}
          size={ModalSizes.sm}
          overlayOpacity={0.11}
          includeCloseButton={false}
        >
          <ConfirmationContent>
            <ConfirmationHeader>
              Are you sure you want to{' '}
              {modal?.data?.actionType === ActionTypes.Cancel ? 'cancel this invoice' : 'refund this invoice'}?
            </ConfirmationHeader>
            <ActionsContainer>
              <CancelButton type="button" onClick={closeModal}>
                Scratch That
              </CancelButton>
              <ConfirmButton
                type="button"
                onClick={
                  modal?.data?.actionType === ActionTypes.Cancel
                    ? onCancelClicked
                    : (): void => openModal(ModalNames.Refund)
                }
              >
                {modal?.data?.actionType === ActionTypes.Cancel ? 'Cancel Invoice' : 'Refund Invoice'}
              </ConfirmButton>
            </ActionsContainer>
          </ConfirmationContent>
        </Modal>
      </>
    );
  },
);

InvoiceAttachment.displayName = 'InvoiceAttachment';

const InvoiceAttachmentContainer = styled.div`
  background: var(--chakra-colors-background-moreSubtleContrast);
  color: var(--chakra-colors-text-onContrast);
  width: 250px;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 5px;
  margin-bottom: 8px;
`;

const InvoiceContainer = styled.div``;
const InvoiceHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  & h3 {
    font-weight: bold;
    font-size: 16px;
    display: inline-block;
    margin: 5px 0 0 10px;
  }
`;

const StyledInvoiceOutlineIcon = styled(InvoiceOutlineIcon)``;

const Amount = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  font-size: 16px;
  font-weight: bold;
`;

const StatusContainer = styled.div`
  text-align: center;
`;

const Status = styled.div<{ paymentIntentStatus: StripePaymentIntentStatus | undefined }>`
  width: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 10px;
  background: transparent;
  border: 1px solid var(--chakra-colors-border-strongContrast);
  color: var(--chakra-colors-text-onContrast);
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 15px;
`;

const CancelInvoiceButton = styled.button`
  background-color: var(--chakra-colors-background-default);
  border: none;
  color: var(--chakra-colors-text-default);
  border-radius: 8px;

  cursor: pointer;
  font-weight: bold;
  height: 30px;
  padding: 0 10px;
  width: 100%;
`;

const HR = styled.hr`
  border-top: 1px solid #f8f8f8;
  border-right: none;
  border-bottom: none;
  border-left: none;
  margin: 15px -5px;
`;

const Document = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #f8f8f8;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 10px;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  cursor: pointer;

  :not(:last-child) {
    margin-bottom: 10px;
  }

  & ${StyledInvoiceOutlineIcon} {
    flex: 0 0 auto;
    margin-right: 10px;
    width: 22px;
    height: auto;

    & * {
      fill: #fff;
    }
  }
`;

const ConfirmationContent = styled.div`
  padding: 1em;
  text-align: center;
`;

const ConfirmationHeader = styled.h1`
  color: #6b7081;
  font-size: 18px;
  font-weight: 600;
  margin: 1em 0 2em 0;
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Button = styled.button`
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  height: 30px;
  padding: 0 10px;
`;

const ConfirmButton = styled(Button)`
  background-color: #c55245;
  color: #fff;
`;

const CancelButton = styled(Button)`
  background-color: hsl(0deg 4% 50%);
  color: #fff;
`;

const IssueRefundButtonDiv = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin: 12px;
`;

const IssueRefundButton = styled.div`
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

export const RequiredStar = styled.span`
  color: #c33a37;
  margin-left: 2px;
`;

const Invoice = styled.div`
  font-weight: bold;
  font-size: 16px;
`;

const CardInformation = styled.div`
  font-weight: bold;
`;

export default InvoiceAttachment;
