import React, { ReactElement, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import styled from 'styled-components/macro';
import { useForm } from 'react-hook-form';
import { Modal, ModalSizes, useModal, useSnackbar, MessageTypes } from '@televet/televet-ui';
import useClinicUser from 'shared/hooks/useClinicUser';
import { ModalNames } from 'shared/enums/ModalNames';
import {
  User,
  useUpdateClinicSettingsTeamUserMutation,
  Role,
  useUpsertUserToClinicMutation,
} from 'shared/types/graphql';
import { email as emailAddressRegex, phone as phoneNumberRegex } from 'shared/utils/validation';
import Tooltip from 'shared/components/Tooltip';
import { QuestionCircleIcon } from 'assets/icons';
import { Mixpanel } from 'shared/utils/mixpanel';
import { useValidation, ValidationResponseCodes } from '../../../../shared/hooks/useValidation';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { Flex } from '@televet/kibble-ui/build/chakra';

const prefixOptions = ['', 'Ms', 'Mrs', 'Mr', 'Dr'];

interface IUserForm {
  namePrefix: string;
  firstName: string;
  lastName: string;
  nameSuffix: string;
  emailAddress: string;
  phoneNumber: string;
  role: string;
}

interface IEditUserModalProps {
  clinicId?: string;
  onAddUserSuccess: () => void;
  onEditUserSuccess: () => void;
}

const EditUserModal = ({ clinicId, onAddUserSuccess, onEditUserSuccess }: IEditUserModalProps): ReactElement => {
  const { currentClinic } = useClinicUser();
  const { validatePhoneNumber } = useValidation();
  const roles = currentClinic?.roles || [];
  const adminRole = roles.find(({ role }) => role === Role.Admin);
  const { modal, closeModal } = useModal();
  const { addSnackbar } = useSnackbar();
  const [updateUser, { loading: isUpdatingUser }] = useUpdateClinicSettingsTeamUserMutation();
  const [upsertUserToClinic, { loading: isCreatingUser }] = useUpsertUserToClinicMutation();

  const user = modal.data?.user as User;
  const currentUserRoles = user?.vetInfo?.roles;

  const isAddingUser = !user;
  const isSavingUser = isUpdatingUser || isCreatingUser;

  const { handleSubmit, register, reset, getValues, setValue, errors } = useForm<IUserForm>({
    mode: 'onBlur',
    validateCriteriaMode: 'all',
  });

  useEffect(() => {
    if (!!user) {
      const { firstName, lastName, namePrefix, nameSuffix, email, phone, vetInfo } = user;
      const role = vetInfo?.roles?.find((role) => !!role) || adminRole;
      reset({
        firstName,
        lastName,
        namePrefix: namePrefix || '',
        nameSuffix: nameSuffix || '',
        emailAddress: email,
        phoneNumber: (phone !== 'undefined' && phone) || '',
        role: role?.id || '',
      });
    } else {
      reset({
        firstName: '',
        lastName: '',
        namePrefix: '',
        nameSuffix: '',
        emailAddress: '',
        phoneNumber: '',
        role: adminRole?.id || '',
      });
    }
  }, [user, reset, adminRole]);

  const handleCancelClick = (): void => {
    closeModal();

    Mixpanel.track('Team Settings Cancel Clicked');
  };

  const handleSaveUser = async (data: IUserForm): Promise<void> => {
    if (isSavingUser) return;

    const { firstName, lastName, namePrefix, nameSuffix, emailAddress, phoneNumber, role } = data;

    try {
      if (isAddingUser) {
        if (!clinicId) {
          return;
        }

        await upsertUserToClinic({
          variables: {
            data: {
              clinicId,
              email: emailAddress,
              firstName,
              lastName,
              namePrefix,
              nameSuffix,
              phone: phoneNumber,
              roleId: role,
            },
          },
        });
        onAddUserSuccess();
      } else {
        let vetInfo;
        if (currentUserRoles && currentUserRoles[0] && currentUserRoles[0].id) {
          const currentRoleID = currentUserRoles[0]?.id;
          if (currentRoleID !== role) {
            vetInfo = {
              update: {
                roles: {
                  connect: [{ id: role }],
                  disconnect: role ? roles.filter(({ id }) => id !== role).map(({ id }) => ({ id })) : [],
                },
              },
            };
          }
        }

        await updateUser({
          variables: {
            where: { id: user.id },
            data: {
              firstName,
              lastName,
              namePrefix,
              nameSuffix,
              phone: phoneNumber,
              type: user.type,
              vetInfo,
            },
          },
        });
        onEditUserSuccess();
      }
      addSnackbar({
        type: MessageTypes.Success,
        message: `User successfully ${isAddingUser ? 'added' : 'saved'}`,
      });
      closeModal();
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      addSnackbar({
        type: MessageTypes.Error,
        message: `There was a problem ${isAddingUser ? 'adding' : 'saving'} the user`,
      });
    }
  };

  const handlePhoneNumberBlur = (): void => {
    const phone = getValues('phoneNumber');
    if (!!phone) {
      setValue('phoneNumber', phone.replace(phoneNumberRegex, '($2) $3-$4'));
    }

    Mixpanel.track('Team Settings Phone Number Edited');
  };

  return (
    <Modal name={ModalNames.EditUser} size={ModalSizes.md} overlayOpacity={0.11}>
      <StyledForm onSubmit={handleSubmit(handleSaveUser)}>
        <Header>{isAddingUser ? 'Add' : 'Edit'} User</Header>
        <Body>
          <Row>
            <Label flex="0 0 70px">
              <LabelText>Prefix</LabelText>
              <Select
                name="namePrefix"
                ref={register()}
                onChange={(): void => {
                  Mixpanel.track('Team Settings Prefix Selected');
                }}
              >
                {prefixOptions.map((prefix) => (
                  <option value={prefix} key={prefix}>
                    {prefix}
                  </option>
                ))}
              </Select>
            </Label>
            <Label flex="3">
              <LabelText required>First Name</LabelText>
              <Input
                name="firstName"
                ref={register({
                  required: 'Please provide a first name',
                })}
                className={errors.firstName ? 'invalid' : ''}
                onBlur={(): void => {
                  Mixpanel.track('Team Settings First Name Edited');
                }}
              />
              {errors.firstName && <ValidationMessage>{errors.firstName?.message}</ValidationMessage>}
            </Label>
            <Label flex="3">
              <LabelText required>Last Name</LabelText>
              <Input
                name="lastName"
                ref={register({ required: 'Please provide a last name' })}
                className={errors.lastName ? 'invalid' : ''}
                onBlur={(): void => {
                  Mixpanel.track('Team Settings Last Name Edited');
                }}
              />
              {errors.lastName && <ValidationMessage>{errors.lastName?.message}</ValidationMessage>}
            </Label>
            <Label flex="0 0 90px">
              <LabelText>Suffix</LabelText>
              <Input
                name="nameSuffix"
                ref={register()}
                onBlur={(): void => {
                  Mixpanel.track('Team Settings Suffix Edited');
                }}
              />
            </Label>
          </Row>
          <Row>
            <Label flex="1">
              <LabelText required={isAddingUser}>
                Email Address{' '}
                {!isAddingUser && (
                  <Tooltip
                    content={
                      <span>
                        This user may edit their own email address on the <b>My Profile</b> page.
                      </span>
                    }
                  >
                    <StyledQuestionCircleIcon />
                  </Tooltip>
                )}
              </LabelText>
              <Input
                name="emailAddress"
                disabled={!isAddingUser}
                ref={register({
                  required: 'Please provide an email address',
                  pattern: {
                    value: emailAddressRegex,
                    message: 'Please provide a valid email address',
                  },
                })}
                className={errors.emailAddress ? 'invalid' : ''}
              />
              {errors.emailAddress && <ValidationMessage>{errors.emailAddress?.message}</ValidationMessage>}
            </Label>
            <Label flex="1">
              <LabelText>Phone Number</LabelText>
              <Input
                name="phoneNumber"
                ref={register({
                  validate: {
                    isValidPhoneNumber: async (value) => {
                      let customMessage;
                      const { isValid, code, message } = await validatePhoneNumber(value);
                      if (!isValid && code === ValidationResponseCodes.PhoneNumberInvalidFormat) {
                        customMessage = 'Please provide a valid US phone number';
                      }
                      return !value || isValid || customMessage || message;
                    },
                  },
                })}
                className={errors.phoneNumber ? 'invalid' : ''}
                onBlur={handlePhoneNumberBlur}
              />
              {errors.phoneNumber && <ValidationMessage>{errors.phoneNumber?.message}</ValidationMessage>}
            </Label>
          </Row>
          <Row>
            <Label flex="0 0 calc(50% - 15px)">
              <LabelText>Role</LabelText>
              <Select
                name="role"
                ref={register()}
                onChange={(): void => {
                  Mixpanel.track('Team Settings Role Updated');
                }}
              >
                {roles.map((role) => (
                  <option value={role.id} key={role.id}>
                    {role.role}
                  </option>
                ))}
              </Select>
            </Label>
          </Row>
        </Body>
        <Flex justifyContent="space-between">
          <Button variant="tertiary" onClick={handleCancelClick}>
            Cancel
          </Button>
          <Button
            type="submit"
            isLoading={isSavingUser}
            onClick={(): void => {
              Mixpanel.track(`Team Settings ${isAddingUser ? 'Add' : 'Save'} User clicked`);
            }}
          >
            {isAddingUser ? 'Add User' : 'Save'}
          </Button>
        </Flex>
      </StyledForm>
    </Modal>
  );
};

const StyledForm = styled.form`
  padding: 20px;
`;

const Header = styled.div`
  color: #000b8e;
  font-weight: 600;
  font-size: 18px;
  padding-bottom: 10px;
`;

const Body = styled.div``;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 25px 0;
`;

interface ILabelProps {
  flex?: string;
}

const Input = styled.input``;
const Select = styled.select``;

const Label = styled.label<ILabelProps>`
  flex: ${({ flex }): string => (flex ? flex : '1 1 auto')};
  display: flex;
  flex-direction: column;
  margin: 0 15px;
  font-size: 14px;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  input,
  select {
    height: 38px;
    width: 100%;
    border-radius: 7px;
    border: 1px solid hsl(0, 0%, 82%);
    padding: 9px 12px;
    font-size: 14px;
    background-color: var(--chakra-colors-background-default);
    color: hsl(235, 81%, 13%);
    transition: border-color 0.1s ease-in-out;

    &:hover:not([disabled]) {
      border-color: hsl(0, 0%, 70%);
    }

    &.invalid:not([disabled]) {
      border-color: #da4167;
    }

    &:focus:not([disabled]) {
      border-color: #39adc3;
    }
  }

  input[disabled],
  select[disabled] {
    background: #f3f3f3;
    opacity: 0.8;
  }

  select {
    appearance: none;
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjUiIHZpZXdCb3g9IjAgMCA4IDUiPgogICAgPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBmaWxsPSIjMDUwRjVGIiBmaWxsLXJ1bGU9Im5vbnplcm8iPgogICAgICAgICAgICA8cGF0aCBkPSJNMTgwMyAyMjQuMDc1bC0yLjcxNC0yLjg0NGMtLjI5NC0uMzA4LS43NzEtLjMwOC0xLjA2NSAwLS4yOTUuMzA4LS4yOTUuODA4IDAgMS4xMTdsMy4yNzcgMy40MzRjLjI3OC4yOS43MjcuMjkgMS4wMDQgMGwzLjI3Ny0zLjQzNGMuMjk1LS4zMDkuMjk1LS44MDkgMC0xLjExNy0uMjk0LS4zMDgtLjc3LS4zMDgtMS4wNjUgMGwtMi43MTQgMi44NDR6IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTc5OSAtMjIxKSIvPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+Cg==)
      no-repeat calc(100% - 10px) 50%;
    background-color: var(--chakra-colors-background-default);
    padding-right: 28px;

    &:hover {
      cursor: pointer;
    }
  }
`;

interface ILabelTextProps {
  required?: boolean;
}

const LabelText = styled.div<ILabelTextProps>`
  margin-bottom: 10px;

  font-weight: 600;

  &[required]:after {
    content: '*';
    color: var(--chakra-colors-text-danger);
    margin-left: 2px;
  }
`;

const StyledQuestionCircleIcon = styled(QuestionCircleIcon)`
  width: 10px;
  height: auto;
  cursor: pointer;

  & * {
    fill: #39adc3;
  }
`;

const ValidationMessage = styled.div`
  margin-top: 8px;
  color: var(--chakra-colors-text-danger);
`;

export default EditUserModal;
