import { useEffect, useMemo, useState } from 'react';
import { GraphQLFetchPolicies } from 'shared/enums/GraphQLFetchPolicies';
import { useIntegrationsProvider } from 'shared/providers/IntegrationsProvider';
import { useGetConnectedServicesLazyQuery } from 'shared/types/graphql';

export type UseBenefitConnectedService = {
  id: string;
  name: string;
  benefitId: string;
};

interface IUseBenefitConnectedServicesOutput {
  servicesByBenefitId: Record<string, UseBenefitConnectedService[]>;
  loading: boolean;
}

/**
 * Retrieve the services connected to specific benefits
 * @param benefitIds The ids of the benefits we want connected services for
 * @param integrationIds A list of integration ids to filter services by
 * @returns An object mapping benefitIds to a list of services
 */
export const useBenefitConnectedServices = (benefitIds: string[]): IUseBenefitConnectedServicesOutput => {
  const { allIntegrations } = useIntegrationsProvider();
  const integrationIds = useMemo(() => allIntegrations?.map(({ id }) => id) || [], [allIntegrations]);

  const [getConnectedServices, { loading }] = useGetConnectedServicesLazyQuery({
    fetchPolicy: GraphQLFetchPolicies.CacheAndNetwork,
  });

  const [servicesByBenefitId, setServicesByBenefitId] = useState<Record<string, UseBenefitConnectedService[]>>({});

  useEffect(() => {
    const loadServices = async (): Promise<void> => {
      const servicesTemp: Record<string, UseBenefitConnectedService[]> = {};

      for (const id of benefitIds) {
        // Fetch services for each benefitId
        await getConnectedServices({
          variables: {
            where: {
              benefitId: { equals: id },
              service: {
                integrationId: { in: integrationIds },
              },
            },
            take: 100, // We expect less but this is a safegaurd, we've crashed the app trying to load services before
          },
        }).then((response) => {
          const services = response.data?.findManyOrganizationCareBenefitToClinicService?.map(({ service }) => ({
            id: service.id,
            name: service.name,
            benefitId: id,
          })) || [];

          servicesTemp[id] = services;
        });
      }

      setServicesByBenefitId(servicesTemp);
    };

    if (benefitIds.length > 0) {
      loadServices();
    }
  }, [benefitIds, getConnectedServices, integrationIds]);

  return {
    servicesByBenefitId,
    loading,
  };
};
