import React, { ReactElement, useContext, useRef, useMemo, useState, useEffect } from 'react';
import { FormsContext } from 'pages/Forms/context/FormsContext';
import WildCardButton from 'shared/components/WildCardButton';
import { FormTemplateType } from 'shared/types/graphql';
import WildcardTextInput, {
  WildcardInputQuestionTypes,
} from 'pages/Forms/FormsContent/FormsDrafting/WildcardInputs/WildcardTextInput';
import { useIntegrationsProvider } from 'shared/providers/IntegrationsProvider';

export enum ElementType {
  input = 'input',
  textArea = 'textArea',
}

interface Props {
  index: number;
  prompt: string;
  refType?: ElementType;
}

const Prompt = ({ index, prompt, refType = ElementType.input }: Props): ReactElement => {
  const [showPreview, setShowPreview] = useState<boolean | undefined>(true);
  const [inputText, setInputText] = useState<string>(prompt);
  const {
    dispatch,
    formTemplateType,
    hasLinkedAutomations,
    hasChanges,
    hasFormTemplateRecentlyChanged,
    clinicWidgetRequestTypes,
  } = useContext(FormsContext);
  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const { isClientIdSupported, isPatientIdSupported } = useIntegrationsProvider();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const isWidgetRequestForm = !!clinicWidgetRequestTypes?.length;
  const isServiceReminderForm = formTemplateType === FormTemplateType.ServiceReminder;

  const allowFillInTheBlankWildcards = useMemo(() => {
    return !isServiceReminderForm && !isWidgetRequestForm && !hasLinkedAutomations;
  }, [isServiceReminderForm, hasLinkedAutomations, isWidgetRequestForm]);

  const handlePropChange = (newValue: string): void => {
    setInputText(newValue);
    if (!!timer) {
      clearTimeout(timer);
    }

    const newTimer = setTimeout(() => {
      dispatch({
        type: 'CHANGE_PROMPT_AT_INDEX',
        payload: { index, prompt: newValue, allowFillInTheBlankWildcards, formTemplateType },
      });
    }, 500);

    setTimer(newTimer);
  };

  const handleAddWildcard = (value: string): void => {
    setShowPreview(false);
    setInputText(value);
    dispatch({
      type: 'CHANGE_PROMPT_AT_INDEX',
      payload: { index, prompt: value, allowFillInTheBlankWildcards, formTemplateType },
    });
  };

  useEffect(() => {
    // showPreview is undefined when wildcard menu is opened, so let focus go to menu input then
    if (showPreview === false && refType === ElementType.input && inputRef.current) {
      inputRef.current.focus();
    }
    if (showPreview === false && refType === ElementType.textArea && textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [refType, showPreview]);

  useEffect(() => {
    if (!hasChanges) {
      setInputText(prompt);
    }
  }, [hasChanges, prompt]);

  useEffect(() => {
    if (hasFormTemplateRecentlyChanged) {
      setInputText(prompt);
      dispatch({
        type: 'FORM_TEMPLATE_CHANGE',
        payload: {},
      });
    }
  }, [dispatch, hasFormTemplateRecentlyChanged, prompt]);

  return (
    <div>
      <WildcardTextInput
        text={inputText}
        placeholder={`Enter your ${refType === ElementType.input ? 'question' : 'message'}`}
        type={refType === ElementType.input ? WildcardInputQuestionTypes.Question : WildcardInputQuestionTypes.Message}
        showPreview={showPreview}
        inputRef={inputRef}
        textAreaRef={textAreaRef}
        allowFillInTheBlanks={allowFillInTheBlankWildcards}
        handleChange={handlePropChange}
        setShowPreview={setShowPreview}
      />
      <WildCardButton
        textAreaRef={refType === ElementType.input ? inputRef : textAreaRef}
        stateHandler={handleAddWildcard}
        setShowPreview={setShowPreview}
        petName
        clientName
        clientPhone
        clientEmail
        petSpecies
        appointmentType
        fillInTheBlank={allowFillInTheBlankWildcards}
        petId={isPatientIdSupported}
        clientId={isClientIdSupported}
        isDisabled={isWidgetRequestForm}
      />
    </div>
  );
};

export default Prompt;
