import { useEffect, useState } from 'react';
import useClinicUser from 'shared/hooks/useClinicUser';
import { UFClinicsToExclude } from './utils';

export function useHideClinicFormsByClinicId(): boolean {
  const { currentClinicId, clinicUser } = useClinicUser();
  const [formsHidden, setFormsHidden] = useState<boolean>(false);

  useEffect(() => {
    const userEmailsAllowed = [
      'caleb@televet.com',
      'carrerajustiz.s@ufl.edu',
      'shultza@ufl.edu',
      'mmison@ufl.edu',
      'ashley.mitchell@vetmed.ufl.edu',
      'cwilliams25@ufl.edu',
    ];
    if (UFClinicsToExclude.includes(currentClinicId || '') && !userEmailsAllowed.includes(clinicUser?.email || '')) {
      setFormsHidden(true);
    } else {
      setFormsHidden(false);
    }
  }, [currentClinicId, setFormsHidden, clinicUser]);

  return formsHidden;
}
