import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Divider, Flex, useDisclosure } from '@televet/kibble-ui/build/chakra';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { Icon } from '@televet/kibble-ui/build/components/Icon';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { CareGoalTrackerInfoModal } from './CareGoalTrackerInfoModal';
import { CareGoalProgressBar } from './CareGoalProgressBar';
import { env } from 'env';
import {
  EnrollmentStatus,
  useGetEnrollmentsCountQuery,
  useSubscribeToNewCareEnrollmentsSubscription,
} from 'shared/types/graphql';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { endOfDay, endOfQuarter, startOfDay, startOfQuarter } from 'date-fns';
import { useToast } from '@televet/kibble-ui/build/components/Toast';
import useClinicUser from 'shared/hooks/useClinicUser';
import { HeartPlus1 } from 'assets/icons';
import * as Sentry from '@sentry/react';
import { connectToChild } from 'penpal';

const { REACT_APP_PAWBLISHER_URL } = env;

interface Goal {
  goal: string;
  prize: string;
}

interface BaseGoal extends Goal {
  start: string;
  deadline: string;
}

interface StretchGoal extends Goal {
  totalPrice: string;
}

interface Goals {
  base: BaseGoal;
  stretch: StretchGoal;
  daily: string;
  showIncentivesModal: boolean;
}

const BASE_GOAL = 50;
const STRETCH_GOAL = 75;
const TODAY = new Date();

export const CareGoalTracker = (): JSX.Element => {
  const toast = useToast();
  const [goals, setGoals] = useState<Goals>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { currentClinic } = useClinicUser();
  const [dailyEnrollments, setDailyEnrollments] = useState(0);
  const [monthlyEnrollments, setMonthlyEnrollments] = useState(0);
  const remainingDaysRef = useRef<HTMLDivElement | null>(null);
  const [isScrollable, setIsScrollable] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);
  const pawblisherIframeRef = useRef<HTMLIFrameElement | null>(null);

  useEffect(() => {
    if (!!goals) {
      const cachedRef = remainingDaysRef.current;
      const observer = new IntersectionObserver(
        ([e]) => {
          setIsScrollable(!e.isIntersecting);
        },
        { threshold: [1] },
      );

      if (cachedRef) {
        observer.observe(cachedRef);
      }

      return (): void => {
        if (cachedRef) {
          observer.unobserve(cachedRef);
        }
      };
    }
  }, [goals, setIsScrollable]);

  const carePlanProviderGroupId = useMemo(
    () => currentClinic?.carePlanProviderGroupId,
    [currentClinic?.carePlanProviderGroupId],
  );

  // Daily
  useGetEnrollmentsCountQuery({
    variables: {
      where: {
        status: { equals: EnrollmentStatus.Active },
        renewalPredecessorEnrollmentId: { equals: null }, // Don't count renewals
        careStripeSubscription: {
          associatedCarePlanProviderGroupId: { equals: carePlanProviderGroupId },
          isTestSubscription: { equals: false },
        },
        startDate: { gte: startOfDay(TODAY), lte: endOfDay(TODAY) },
      },
    },
    skip: !carePlanProviderGroupId,
    onCompleted: (data) => {
      const { findManyCarePlanEnrollmentCount } = data;
      setDailyEnrollments(findManyCarePlanEnrollmentCount);
    },
  });

  // Total
  const startDate =
    !!goals?.base.start && goals?.base.start !== 'null' ? new Date(goals?.base.start) : startOfQuarter(TODAY);
  const endDate =
    !!goals?.base.deadline && goals?.base.deadline !== 'null' ? new Date(goals?.base.deadline) : endOfQuarter(TODAY);
  const dateQuery = { startDate: { gte: startDate, lte: endDate } };
  const { refetch: refetchTotalEnrollments } = useGetEnrollmentsCountQuery({
    variables: {
      where: {
        status: { equals: EnrollmentStatus.Active },
        renewalPredecessorEnrollmentId: { equals: null }, // Don't count renewals
        careStripeSubscription: {
          associatedCarePlanProviderGroupId: { equals: carePlanProviderGroupId },
          isTestSubscription: { equals: false },
        },
        ...dateQuery,
      },
    },
    skip: !carePlanProviderGroupId && !goals,
    onCompleted: ({ findManyCarePlanEnrollmentCount }) => {
      setMonthlyEnrollments(findManyCarePlanEnrollmentCount);
    },
  });

  const handlePawblisherEvent = useCallback(() => {
    const iframe = pawblisherIframeRef.current;

    if (iframe && !goals) {
      try {
        const connection = connectToChild({
          debug: true,
          iframe,
        });

        connection.promise
          .then(async (child) => {
            const goals = await child.getGoals();
            setGoals(goals);
          })
          .catch((e) => {
            Sentry.captureException(e);
            console.error('Error retrieving goals from Pawblisher', e);
          });
        return (): void => {
          connection.destroy();
        };
      } catch (e) {
        Sentry.captureException(e);
        console.error('Error connecting to Pawblisher iFrame', e);
      }
    }
  }, [goals]);

  const remainingDays = useMemo(() => {
    const deadline = goals?.base.deadline;

    if (!!deadline?.length) {
      const deadlineDate = new Date(deadline);
      const diff = differenceInCalendarDays(deadlineDate, new Date());
      return diff < 0 ? 0 : diff;
    }
  }, [goals?.base.deadline]);

  useEffect(() => {
    if (carePlanProviderGroupId && !goals) {
      handlePawblisherEvent();
    }
  }, [carePlanProviderGroupId, goals, handlePawblisherEvent]);

  useSubscribeToNewCareEnrollmentsSubscription({
    variables: {
      where: {
        carePlanProviderGroupId: carePlanProviderGroupId || '',
      },
    },
    skip: !carePlanProviderGroupId,
    onData: ({ data }) => {
      if (data.data) {
        const { checkPetEnrollmentsByClinic } = data.data;

        if (checkPetEnrollmentsByClinic) {
          const { dailyTotal, monthlyTotal, enrollmentData } = checkPetEnrollmentsByClinic;
          setDailyEnrollments(dailyTotal);

          if (!!goals?.base.start) {
            refetchTotalEnrollments();
          } else {
            setMonthlyEnrollments(monthlyTotal);
          }

          const { isRenewal, clientName, petName, planName } = enrollmentData;

          if (!isRenewal) {
            setShowAnimation(true);
            setTimeout(() => {
              setShowAnimation(false);
            }, 2000);
          }

          toast({
            title: `New Care Membership ${isRenewal ? 'Renewal' : 'Signup'}!`,
            description: isRenewal
              ? `${clientName} has renewed their ${planName} Membership plan for ${petName}.`
              : `${clientName} has signed up ${petName} for ${planName} Membership plan.`,
            status: 'default',
            iconProps: {
              name: 'heartSolid',
              variant: 'dangerOnContrast',
            },
            position: 'bottom-end',
            duration: 30000,
          });
        }
      }
    },
  });

  return (
    <>
      {goals && (
        <Box
          className="CareGoalTracker"
          overflowY="auto"
          overflowX="hidden"
          {...(isScrollable && {
            borderTop: '1px solid',
            borderBottom: '1px solid',
            borderColor: 'border.default',
          })}
          py={3}
          margin="0 auto"
        >
          <Flex className="CareGoalTracker__Container" alignItems="center" flexDirection="column">
            <Text
              className="CareGoalTracker__Title"
              size="sm"
              fontWeight="bold"
              textAlign="center"
              mb={1}
              maxW="50px"
              mx="auto"
            >
              Care Goals
            </Text>
            <Box className="CareGoalTracker__Daily" textAlign="center">
              <Text
                className="CareGoalTracker__DailyTitle"
                size="xs"
                fontWeight="bold"
                textAlign="center"
                variant="subtle"
              >
                Daily
              </Text>
              <Box className="CareGoalTracker__DailyHeart" position="relative">
                <Icon
                  size="2xl"
                  name="heartSolid"
                  {...(dailyEnrollments === 0 ? { color: 'background.lessSubtle' } : { variant: 'dangerOnContrast' })}
                />
                {showAnimation && (
                  <Box
                    position="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                    zIndex={1}
                    sx={{ svg: { overflow: 'visible' } }}
                  >
                    <HeartPlus1 />
                  </Box>
                )}
                <Text
                  size="lg"
                  fontWeight="bold"
                  variant={dailyEnrollments === 0 ? 'placeholder' : 'onContrast'}
                  className="CareGoalTracker__CurrentValue"
                  position="absolute"
                  top="35%"
                  left="50%"
                  transform="translate(-50%, -35%)"
                >
                  {dailyEnrollments}
                </Text>
              </Box>
              <Flex alignItems="center" justifyContent="center">
                {goals?.daily.length < 2 && <Icon name="targetSolid" size="xs" variant="subtle" />}
                <Text size="xs" variant="subtle" ml="2px">
                  {goals?.daily}/day
                </Text>
              </Flex>
            </Box>
            <Divider bgColor="divider.default" my={3} w="70%" borderRadius="full" />
            <Box className="CareGoalTracker__Total" textAlign="center" mb={2}>
              <Text
                as="p"
                className="CareGoalTracker__TotalTitle"
                size="xs"
                fontWeight="bold"
                textAlign="center"
                variant="subtle"
                mb={4}
              >
                Total
              </Text>
              <CareGoalProgressBar
                baseGoal={Number(goals.base.goal) || BASE_GOAL}
                stretchGoal={Number(goals.stretch.goal) || STRETCH_GOAL}
                currentValue={monthlyEnrollments}
              />
              <Flex alignItems="center" justifyContent="center" mt={3} ref={remainingDaysRef}>
                <Icon name="clock" size="xs" variant="subtle" />
                <Text size="xs" variant="subtle" ml="2px">
                  {remainingDays}d
                </Text>
              </Flex>
            </Box>
            {!!carePlanProviderGroupId && !!goals.showIncentivesModal && (
              <>
                <Button size="xs" variant="tertiary" onClick={onOpen}>
                  Info
                </Button>
                <CareGoalTrackerInfoModal
                  isOpen={isOpen}
                  onClose={onClose}
                  carePlanProviderGroupId={carePlanProviderGroupId}
                />
              </>
            )}
          </Flex>
        </Box>
      )}
      {!!carePlanProviderGroupId && (
        <Box
          as="iframe"
          src={`${REACT_APP_PAWBLISHER_URL}/elements/${carePlanProviderGroupId}/goalTrackerData`}
          style={{ width: '0px', height: '0px' }}
          ref={pawblisherIframeRef}
        />
      )}
    </>
  );
};
