import React, { useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import * as Sentry from '@sentry/react';
import { useSnackbar, MessageTypes } from '@televet/televet-ui';
import {
  FullMessageTemplateFragment,
  ChannelMessageAttachmentEntityType,
  ChannelMessageAttachmentType,
  useSaveMessageTemplateMutation,
} from 'shared/types/graphql';
import Button, { ButtonColor } from 'shared/components/Button';
import TextInput from 'shared/components/TextInput';
import TextArea from 'shared/components/TextArea';
import useArchiveMessageTemplate from './hooks/useArchiveMessageTemplate';
import WildCardButton from 'shared/components/WildCardButton';
import FormsButton from 'shared/components/FormsButton';
import useClinicUser from 'shared/hooks/useClinicUser';
import useFeatureFlag from 'shared/hooks/useFeatureFlag';
import { FeatureFlagName } from 'shared/enums';
import { Text } from '@televet/kibble-ui';

interface IMessageTemplateViewProps {
  messageTemplate?: FullMessageTemplateFragment;
}

export interface MessageTemplateAttachment {
  entityId: string;
  attachmentType: ChannelMessageAttachmentType;
  entityType: ChannelMessageAttachmentEntityType;
}

const MessageTemplateView = ({ messageTemplate }: IMessageTemplateViewProps): JSX.Element => {
  const { messageTemplateId } = useParams<{ messageTemplateId?: string }>();
  const { clinicUser } = useClinicUser();
  const textAreaRef = useRef(null);
  // TODO: Refactor these form inputs using react-hook-form
  const [messageTemplateName, setMessageTemplateName] = useState(messageTemplate?.name || '');
  const [messageTemplateBody, setMessageTemplateBody] = useState(messageTemplate?.body || '');
  const [messageTemplateAttachments, setMessageTemplateAttachments] = useState<MessageTemplateAttachment[]>(
    messageTemplate?.attachments || [],
  );
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const { addSnackbar } = useSnackbar();
  const { isFeatureEnabled } = useFeatureFlag();
  const hasCareEnabled = useMemo(() => {
    return isFeatureEnabled(FeatureFlagName.CarePlans);
  }, [isFeatureEnabled]);

  const [updateMessageTemplate, { loading: isSavingMessageTemplate }] = useSaveMessageTemplateMutation();
  const { archiveMessageTemplate, isLoading: isArchivingMessageTemplate } = useArchiveMessageTemplate();

  const handleArchiveClick = (): void => {
    if (!messageTemplate) return;
    archiveMessageTemplate(messageTemplate);
  };

  const areWildCardsValid = useMemo(() => {
    const numberOfOpenCurly = messageTemplateBody?.match(/{/g)?.length;
    const numberOfClosedCurly = messageTemplateBody?.match(/}/g)?.length;
    if (numberOfOpenCurly !== numberOfClosedCurly) {
      return false;
    }
    return true;
  }, [messageTemplateBody]);

  const validateForm = (): boolean => {
    let isValid = true;

    const errorMessages = [];

    if (!messageTemplateName) {
      errorMessages.push('Please provide a name for this template.');
      isValid = false;
    }
    if (!messageTemplateBody) {
      errorMessages.push('Please provide content for this template.');
      isValid = false;
    }

    setErrorMessages(errorMessages);
    return isValid;
  };

  const handleSaveClick = async (): Promise<void> => {
    if (!messageTemplateId || !validateForm()) return;

    try {
      await updateMessageTemplate({
        variables: {
          where: { id: messageTemplateId },
          data: {
            name: messageTemplateName,
            body: messageTemplateBody,
            attachments: messageTemplateAttachments,
            updatedBy: { connect: { id: clinicUser?.id } },
          },
        },
      });
      addSnackbar({
        type: MessageTypes.Success,
        message: 'Success! Message template saved',
        timeout: 3000,
      });
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      addSnackbar({
        type: MessageTypes.Error,
        message: 'Whoops! An error occurred while saving that message template. Please try again.',
        timeout: 7000,
      });
    }
  };

  const messageTemplateFormAttachments = useMemo(
    () =>
      messageTemplateAttachments.filter(
        (attachment: MessageTemplateAttachment) => attachment.entityType === ChannelMessageAttachmentEntityType.Form,
      ) || [],
    [messageTemplateAttachments],
  );

  return (
    <MessageTemplateViewContainer>
      <Header />
      <Divider />
      <FormContainer>
        <Row>
          <TextInput
            name="name"
            label="Name"
            value={messageTemplateName}
            onChange={(e): void => setMessageTemplateName(e.target.value)}
          />
        </Row>
        <Text as="p" mb={5}>
          {`The following wildcards are available on message templates: {{clientName}}, {{myName}}, ${
            hasCareEnabled
              ? '{{petName}}, {{petPortalLinksForClients}}, and {{petPortalLinksForPets}}'
              : 'and {{petName}}'
          }`}
        </Text>

        <Row>
          <TextArea
            ref={textAreaRef}
            name="content"
            label="Content"
            autoFocus={true}
            rows={6}
            value={messageTemplateBody}
            onChange={(e): void => setMessageTemplateBody(e.target.value)}
            errors={areWildCardsValid ? undefined : ['Wildcards format is incorrect']}
          />
          <EditButtons>
            <WildCardButton
              textAreaRef={textAreaRef}
              stateHandler={setMessageTemplateBody}
              petName
              clientName
              myName
              petPortalLinksForPets
              petPortalLinksForClients
            />
            <FormsButton
              messageTemplateFormAttachments={messageTemplateFormAttachments}
              setMessageTemplateAttachments={setMessageTemplateAttachments}
            />
          </EditButtons>
        </Row>
        {Boolean(errorMessages.length) && (
          <ErrorMessageContainer>
            {errorMessages.map((errorMessage) => (
              <div key={errorMessage}>{errorMessage}</div>
            ))}
          </ErrorMessageContainer>
        )}
        <Divider />
        <ActionButtonsContainer>
          <Button onClick={handleArchiveClick} color={ButtonColor.Secondary} isLoading={isArchivingMessageTemplate}>
            {messageTemplate?.isArchived ? 'Unarchive' : 'Archive'}
          </Button>
          <Button onClick={handleSaveClick} isLoading={isSavingMessageTemplate}>
            Save
          </Button>
        </ActionButtonsContainer>
      </FormContainer>
    </MessageTemplateViewContainer>
  );
};

const MessageTemplateViewContainer = styled.div`
  flex: 1 1 auto;
  border-left: 1px solid rgb(232 233 233);
  padding: 0 20px;
  overflow: auto;
`;

const Header = styled.div`
  margin: 0 20px;
  height: 70px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-top: solid 1px #e8e9e9;
  margin-bottom: 20px;
`;

const Row = styled.div`
  margin-bottom: 30px;
`;

const ErrorMessageContainer = styled.div`
  color: var(--chakra-colors-text-danger);
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 30px;
`;

const FormContainer = styled.div`
  margin: 0 50px;
  max-width: 600px;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const EditButtons = styled.div`
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  display: flex;
  margin-top: 5px;
  padding: 5px 7px;
  position: relative;
`;

export default MessageTemplateView;
