import React, { useCallback, useMemo, useState } from 'react';
import { Alert, Text, Box, Collapse } from '@televet/kibble-ui';
import BitwerxSetup from './BitwerxSetup';
import { useStripe } from 'shared/hooks';
import WritebackConfiguration from './WritebackConfiguration';
import WritebackConfigurationAlert from './WritebackConfigurationAlert';
import usePaymentWritebackConfig from 'shared/hooks/usePaymentWritebackConfig';
import {
  integrationSystemDisplayName,
  useBitwerxData,
  useIntegrationsProvider,
} from 'shared/providers/IntegrationsProvider';

interface IReportingConfigurationProps {
  setHasUnsavedChange: React.Dispatch<React.SetStateAction<boolean>>;
}

const ReportingConfiguration = ({ setHasUnsavedChange }: IReportingConfigurationProps): JSX.Element | null => {
  const { primaryIntegrationName } = useIntegrationsProvider();
  const { needsBitwerxForPaymentWritebacks, bitwerxPending, hasBitwerx } = useBitwerxData();
  const { onboardingComplete } = useStripe();
  const { isClinicWritebackEnabled } = usePaymentWritebackConfig();

  const [showAlert, setShowAlert] = useState(needsBitwerxForPaymentWritebacks);

  const handleAlertClose = useCallback(() => setShowAlert(false), []);

  const showWritebackConfig = useMemo(
    () => hasBitwerx && isClinicWritebackEnabled,
    [hasBitwerx, isClinicWritebackEnabled],
  );

  const isFinalStep = useMemo(() => hasBitwerx && !isClinicWritebackEnabled, [hasBitwerx, isClinicWritebackEnabled]);

  const sectionDescription = useMemo(() => {
    return `Set up payment integration with ${primaryIntegrationName} to see ${
      primaryIntegrationName === integrationSystemDisplayName.AVIMARK ? 'balances' : 'invoices'
    } in Flow.${
      isClinicWritebackEnabled
        ? ` Configure how payments collected through Flow write back to ${primaryIntegrationName || 'your PIMS'}.`
        : ``
    }`;
  }, [primaryIntegrationName, isClinicWritebackEnabled]);

  const ConfigComponent = (): JSX.Element | null => {
    if (needsBitwerxForPaymentWritebacks || bitwerxPending || isFinalStep) {
      return <BitwerxSetup isFinalStep={isFinalStep} />;
    }
    if (showWritebackConfig) {
      return <WritebackConfiguration setHasUnsavedChange={setHasUnsavedChange} />;
    }
    return null;
  };

  return onboardingComplete ? (
    <Box maxWidth="445px" py={5}>
      <Collapse in={showAlert} animateOpacity>
        <Box pb={5}>
          <Alert
            title="Stripe account setup complete!"
            description="Up next - set up payment integration."
            w="445px"
            onClose={handleAlertClose}
          />
        </Box>
      </Collapse>
      <Box pb={5}>
        <Text as="p" pb={3} size="sm" variant="subtle">
          {sectionDescription}
        </Text>
        {showWritebackConfig && <WritebackConfigurationAlert />}
      </Box>
      <ConfigComponent />
    </Box>
  ) : null;
};

export default ReportingConfiguration;
