import { DatePicker, DateRange } from '@televet/kibble-ui/build/components/DatePicker';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { Tooltip } from '@televet/kibble-ui/build/components/Tooltip';
import { Icon } from '@televet/kibble-ui/build/components/Icon';
import { Flex, useConst } from '@televet/kibble-ui/build/chakra';
import addYears from 'date-fns/addYears';
import isBefore from 'date-fns/isBefore';
import format from 'date-fns/format';
import subYears from 'date-fns/subYears';
import React, { useMemo } from 'react';
import { ConditionProps } from '../types/conditionalSegments.types';
import { SegmentWrapper } from './SegmentWrapper';
import useClinicUser from 'shared/hooks/useClinicUser';

export type AppointmentDateSegmentConditionValue = {
  appointmentGte: Date | null;
  appointmentLte: Date | null;
};

type AppointmentDateSegmentProps = ConditionProps<AppointmentDateSegmentConditionValue>;

export const AppointmentDateSegment = ({
  conditionValue,
  updateConditionValue,
  asSummary,
  displayValidation,
}: AppointmentDateSegmentProps): JSX.Element => {
  const { appointmentGte, appointmentLte } = conditionValue;
  const { currentClinic } = useClinicUser();
  const maxDate = useConst(addYears(new Date(), 3));

  const minDate = useMemo(() => {
    // Grab earlier of the clinic createdAt and activationDate
    const earliestClinicDate = new Date(
      !currentClinic?.activationDate
        ? currentClinic?.createdAt
        : isBefore(currentClinic.activationDate, currentClinic?.createdAt)
        ? currentClinic.activationDate
        : currentClinic?.createdAt,
    );

    // Subtract 3 years
    return subYears(earliestClinicDate, 3);
  }, [currentClinic?.activationDate, currentClinic?.createdAt]);

  if (asSummary) {
    return (
      <Text fontWeight="light" color="text.subtle">
        Appointment Date: {appointmentGte ? format(appointmentGte, 'MM/dd/yy') : ''} -{' '}
        {appointmentLte ? format(appointmentLte, 'MM/dd/yy') : ''}
      </Text>
    );
  }

  return (
    <SegmentWrapper>
      <Flex gap={3} w="100%">
        <Flex align="center" gap={1}>
          <Text fontWeight="bold">Appointment Date</Text>
          <Tooltip label="By default this is set to the last 18 months up to the next 18 months. You can change this to any date range.">
            <Flex>
              <Icon name="questionMarkCircleSolid" size="sm" />
            </Flex>
          </Tooltip>
        </Flex>

        <DatePicker
          startDate={appointmentGte}
          endDate={appointmentLte}
          isDateRange
          dateFormat="MM/dd/yy"
          minDate={minDate}
          minYear={minDate.getFullYear()}
          maxYear={maxDate.getFullYear()}
          onDateChange={({ startDate, endDate }: DateRange): void => {
            updateConditionValue({ appointmentLte: endDate || startDate, appointmentGte: startDate });
          }}
        />

        {displayValidation && (
          <Text as="p" mt="2" color="text.danger">
            {!appointmentGte && !appointmentLte
              ? 'Please provide valid starting and ending dates.'
              : !appointmentGte
              ? 'Please provide a valid starting date.'
              : 'Please provide a valid ending date.'}
          </Text>
        )}
      </Flex>
    </SegmentWrapper>
  );
};
